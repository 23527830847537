/**
 * Sets field change-listeners, which update the field state with the entered data.
 *
 * Export
 *     setCustomFieldStoreListener
 *     setFieldChangeListeners
 *     storeMultiSelectValue
 *
 * TOC
 *     SET CHANGE-LISTENERS
 *         IF CITATION FORM, REGENERATE CITATION
 *         SET VALUE-STORE METHOD
 *     STORE FIELD-DATA
 *         STANDARD FIELD
 *         DYNAMIC FIELD
 */
import { cloneObj } from '@util';
import { components, form, state } from '@dataentry';
/* ========================= SET CHANGE-LISTENERS =========================== */
export function setFieldChangeListeners( f, input ) {               /*dbug-log*///console.log( '   --setFieldChangeListeners f[%s][%O]', f.name, f );
    ifCitationFormAutoGenerateCitationOnChange( f, input );
    setOnChangeStoreFieldValue( f, input );
}
/* -------------- IF CITATION FORM, REGENERATE CITATION --------------------- */
function ifCitationFormAutoGenerateCitationOnChange( f, input ) {
    const formEntity = state.getFormState( f.group, 'name' );
    const updateCitation = form.onCitationFieldChange.bind( null, f.group );
    if ( formEntity === 'Citation' ) $( input ).change( updateCitation );
}
/* ----------------------- SET VALUE-STORE METHOD --------------------------- */
/** todo2: set multiple times on fields that get rebuilt? */
function setOnChangeStoreFieldValue( f, input ) {
    if ( f.misc?.customValueStore ) return; //Handled elsewhere
    $( input ).on( 'change', storeFieldValue.bind( null, input, f.name, f.group ) );
}
/* ========================= STORE FIELD-DATA =============================== */
/* ------------------------- STANDARD FIELD --------------------------------- */
/**
 * todo2: Combo field value is set to ID everywhere but with edit and review init values...
 * Using the opt obj, {text, value}, makes sense, but it might be complicated with multi field values
 */
function storeFieldValue ( elem, fieldName, fLvl, e ) {             /*dbug-log*///console.log( '   --storeFieldValue [%s][%O]', fieldName, elem );
    let val = getValidFieldValue( elem );                           /*dbug-log*///console.log( '       --val[%O]', val );
    state.setFieldValue( fLvl, fieldName, val );
    components.ifFormValidClearAlertsAndEnableSubmit( fLvl );
}
function getValidFieldValue ( elem ) {
    const v = $( elem ).val();
    return v == 'create' ? null : ( typeof v == 'string' ? getStringValue( elem, v ) : v );
}
/**
 * The value is trimmed of excess spaces. If the result is different from the
 * input's value, the input is updated accordingly.
 */
function getStringValue ( elem, val ) {
    const v = val.trim();
    if ( val !== v ) $( elem ).val( v );
    return v;
}
/* ------------------------- DYNAMIC FIELD ---------------------------------- */
/** [storeMultiSelectValue description] */
export function storeMultiSelectValue( fLvl, ord, fName, v ) {      /*dbug-log*///console.log( '@--storeMultiSelectValue lvl[%s] ord[%s]fName[%s], v[%s]', fLvl, ord, fName, v );
    const vals = state.getFieldValue( fLvl, fName );                /*dbug-log*///console.log( '   --prev[%O] cur[%O]', cloneObj( vals ), vals );
    vals[ ord ] = v;
    if ( !v ) deleteTrailingEmptyValues( ord, v, vals );
    state.setFieldValue( fLvl, fName, vals );                       /*dbug-log*///console.log( '       --after[%O]', cloneObj( vals ) );
}
function deleteTrailingEmptyValues( ord, v, vals ) {                /*dbug-log*///console.log( '       --deleteTrailingEmptyValues ord[%s] v[%O] vals[%O]', ord, v, cloneObj( vals ) );
    for ( let i = Object.keys( vals ).length; i >= 1; i-- ) {
        if ( ord > i || vals[ i ] ) return;
        delete vals[ i ];
    }
}