/**
 * Fills complex interaction-form fields with init-data. (Edit and review forms)
 *
 * Export
 *     finishFieldFill
 */
import { setSelVal } from '@elems/combo';
import { setSubEntityDetails } from '@dataentry/components';
import { getFormState, getRecords } from '@dataentry/state';
import { buildOptAndUpdateCombo, fillCitationCombo } from '../controller';

export function finishFieldFill() {
    const fields = getFormState( 'top', 'fields' );           /*dbug-log*///console.log('--finishFieldFill [%O]', fields);
    setSourceFields( fields.Publication, fields.CitationTitle );
    setLocationDetailPanel( fields.Location );
    setTypeAndTagFields( fields.InteractionType, fields.InteractionTags );
    setTaxonFields( fields.Subject, fields.Object );
    if ( fields.Source.value === 'Secondary' ) { setIntSecondarySource(); }
    if ( fields.Season.value ) { setIntSeasons( fields.Season.value ); }
}
/* ---------------------- LOCATION FIELDS ----------------------------------- */
function setLocationDetailPanel ( locField ) {
    if ( !locField.value ) return;
    const locRcrd = getRecords( 'location', locField.value );
    setSubEntityDetails( 'loc', locRcrd );
}
/* ---------------------- SOURCE FIELDS ------------------------------------- */
function setSourceFields ( pubField, citField ) {                      /*dbug-log*///console.log('--setSourceFields pub[%O] cit[%O]', pubField, citField);
    if ( !pubField.value ) return;
    fillCitationCombo( pubField.value )
    .then( () => setCitationFieldVal( citField ) );
}
function setCitationFieldVal ( citField ) {
    if ( !citField.value ) return;
    setSelVal( 'CitationTitle', citField.value );
}
/* ------------------------ TAGS FIELDS ------------------------------------- */
function setTypeAndTagFields ( typeField, tagsField ) {
    $( '#sel-InteractionType' ).data( 'init-val', typeField.value );
    sortAndSetTagsFields( tagsField );
}
/** Sorts through tags by type and sets their respective fields. */
function sortAndSetTagsFields ( tagsField ) {
    const tags = sortTagsByType( tagsField.value, getRecords( 'tag' ) );
    if ( tags.source ) { setIntSecondarySource(); }
    if ( tags.season ) { setIntSeasons( tags.season ); }                /*dbug-log*///console.log('sortAndSetTagsFields tags[%O]', tags);
    if ( !tags[ 'interaction type' ] ) return;
    /** Note: Must happen before taxon fields filled for auto-type handling. */
    $( '#sel-InteractionTags' ).data( 'init-val', tags[ 'interaction type' ] );
}
function setIntSecondarySource() {
    setSelVal( 'Source', 'Secondary' );
}
function setIntSeasons ( seasonIds ) {
    setSelVal( 'Season', seasonIds );
}
function sortTagsByType ( tagIds, tagRcrds ) {                         /*dbug-log*///console.log('sortTagsByType tagIds[%O] tagRcrds[%O]', tagIds, tagRcrds);
    const sorted = {};
    if ( !tagIds ) { return sorted; }
    tagIds.forEach( sortTag );
    return sorted;

    function sortTag ( id ) {
        const type = tagRcrds[ id ].type;
        if ( !sorted[ type ] ) { sorted[ type ] = []; }
        sorted[ type ].push( id );
    }
}
/* ------------------------ TAXON FIELDS ------------------------------------ */
function setTaxonFields ( subjField, objField ) {
    setTaxonFieldVal( 'Subject', subjField );
    setTaxonFieldVal( 'Object', objField );
}
function setTaxonFieldVal ( fName, field ) {
    if ( !field.value ) return;
    buildOptAndUpdateCombo( fName, field.value );
}