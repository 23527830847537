/**
 * Data-entry form combobox methods.
 * TODO: DOCUMENT
 *
 * Export
 *     initFormCombos
 *     resetFormCombobox
 *     setSilentVal
 *
 * TOC
 *     GET INPUT
 *     COMBO UTIL
 */
import * as combo from '@elems/combo';
import { state } from '@dataentry';
/* ========================== INIT ========================================== */
/**
 * Inits the selectize library to make comoboxes out of the form's select elements.
 */
export function initFormCombos ( fLvl, cConfig ) {
    const cFields = state.getComboFields( fLvl );                   /*dbug-log*///console.log( 'initFormCombos [%s] cConfig[%O] cFields[%O]', fLvl, cConfig, cFields );
    cFields.forEach( f => selectizeElem( f, cConfig[ f.id ] ) );
}
function selectizeElem ( fConfig, confg ) {                         /*dbug-log*///console.log( "   Initializing [%s] selectizeConfig[%O]", fConfig.id, confg );
    const combo = getFormComboConfigName( fConfig, confg );
    initFormCombo( fConfig, confg, combo );
    state.setFieldState( fConfig.group, fConfig.name, { combo } );
}
function getFormComboConfigName ( fConfig, confg ) {
    const name = confg.confgName ? confg.confgName : fConfig.id;
    return appendCountIfMultiInputField( name, fConfig.count );
}
function initFormCombo( fConfig, confg, comboKey ) {
    confg.confgName = comboKey;
    confg.id = getFormComboId( fConfig, confg );
    confg.name = getFormComboDisplayName( fConfig );
    combo.initCombobox( confg );
    if ( !confg.create ) combo.removeOptions( fConfig.id, [ 'create' ] );
}
function getFormComboId ( fConfig, confg ) {
    const id = confg.id ? confg.id : '#sel-' + fConfig.id;
    return appendCountIfMultiInputField( id, fConfig.count );
}
function getFormComboDisplayName ( fConfig ) {
    return fConfig.label ? fConfig.label : fConfig.name;
}
function appendCountIfMultiInputField ( string, count ) {
    return count ? string + count : string;
}
/* =========================== UTILITY ====================================== */
/* ---------------------------- SET ----------------------------------------- */
/**
 * Selects a new value in a combobox without triggering the change-event. The
 * field state is updated with the new value.
 * Note: multi-fields are handled @selectExistingAuthsOrEds.
 */
export function setSilentVal ( fLvl, field, val ) {                /*dbug-log*///console.log( 'setSilentVal [%s] field[%s] val[%s]', fLvl, field, val );
    combo.setSelVal( field, val, 'silent' );
    state.setFieldValue( fLvl, field, val );
}
/**
 * Selects the form's entity in it's parent field. If a new entity has been created,
 * an option is created, added, and then selected in the form's parent field. The
 * combo's change event, wrapped in a promise, is returned to execute synchronously
 * @param {object} entity     Entity data returned from local storage.
 * @param {string} comboField Name of the form's parent-field combobox
 */
export function addAndSelectEntity ( entity, comboField ) {         /*dbug-log*///console.log( '           -- addAndSelectEntity. entity?[%O] comboField[%s]', entity, comboField );
    if ( !entity ) return; //Set during data-review process.
    const optExists = combo.getOptionTextForValue( comboField, entity.id );
    if ( !optExists ) { addNewOptionForEntity( entity, comboField ); }
    combo.enableCombobox( comboField );                               /*dbug-log*///console.log('               -- addAndSelectEntity comboField[%s] optExists?[%s]', comboField, optExists);
    return combo.triggerComboChangeReturnPromise( comboField, entity.id );
}
function addNewOptionForEntity ( entity, comboField ) {
    const name = combo.getComboEntityDisplayName( entity );
    const opt = { text: name, value: entity.id };                   /*dbug-log*///console.log('           -- [%s] New option[%O]', comboField, opt);
    combo.addOpt( comboField, opt );
}

/* -------------------------------- RESET ----------------------------------- */
/**
 * Clears and enables the parent combobox for the exited form. Removes any
 * placeholder options and, optionally, brings it into focus.
 */
export function resetFormCombobox ( comboField, focus ) {              /*dbug-log*///console.log('resetFormCombobox comboField[%s] focus?[%s]', comboField, focus);
    if ( !comboField ) return;
    combo.resetCombobox( comboField );
    combo.enableCombobox( comboField );
    combo.focusCombobox( comboField, focus );
}