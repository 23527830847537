/**
 * todo
 *
 * Export
 *     fillReviewField
 *     setReviewStageOption
 *
 * TOC
 *     SET FIELD-STAGE
 *         MULTI-FIELD COMBOS
 *         SET COMBO STAGE
 *     RELATED FIELDS
 *         RESOLVED
 *         UNRESOLVED
 */
import { form, state } from '@dataentry';
import * as el from '@elems';
import { isManagerUser, lcfirst } from '@util';
/* ========================= SET FIELD-STAGE ================================ */
/** If field is pending review, a generic option is set in the field's combo. */
export function fillReviewField ( field, isDisplayFormOnly ) {      /*dbug-log*///console.log( '       -- fillReviewField field[%s][%O]', field.name, field );
    if ( !field.review ) return;                                    /*dbug-log*///console.log( '       -- fillReviewField field[%s][%O]', field.name, field );
    if ( el.isMultiField( field ) ) return fillMultiReviewField( field, isDisplayFormOnly );
    if ( field.review.entityId ) return handleRelatedFields( field ); // Field has approved data
    if ( field.value ) return; // Field has quarantined data
    setReviewStageOption( field.combo, field.review.stage.name );
    fillRelatedFields( field );
}
/* ---------------------- MULTI-FIELD COMBOS -------------------------------- */
function fillMultiReviewField ( field, isDisplayFormOnly ) {       /*dbug-log*///console.log( '           -- fillMultiReviewField field[%O]', field );
    for ( let ord in field.review ) ifReviewCompleteSetStageOpt( field, ord );
    form.rmvExtraMultiFields( field.name, field.count, field.group, isDisplayFormOnly );
}
/**
 * During contributor confirmation, the resulting stage is added to field's with
 * sub-data that has been reviewed and rejected, for their information.
 */
function ifReviewCompleteSetStageOpt ( field, ord ) {               /*dbug-log*///console.log( '               -- ifReviewCompleteSetStageOpt ord[%s] field[%O]', ord, field );
    if ( el.getSelVal( field.name + ord ) ) return; //Approved-entity set elsewhere
    const stageName = field.review[ ord ].stage.name;
    setReviewStageOption( field.name + ord, stageName );
}
/* ---------------------- SET COMBO STAGE ----------------------------------- */
export function setReviewStageOption ( comboField, stage ) {          /*dbug-log*///console.log( '  +--setReviewStageOption comboField[%s] stage[%s]', comboField, stage );
    const opt = getOptForStage( stage );                            /*dbug-log*///console.log( '       --stage[%s] opt[%O]', stage, opt );
    if ( !opt ) return;
    el.addOpt( comboField, opt );
    el.setSelVal( comboField, opt.value, 'silent' );
    el.enableCombobox( comboField, opt.value !== 'pending' );
}
function getOptForStage ( stage ) {
    const map = {
        pending: 'Pending Review...',
        rejected: 'REJECTED. Click to replace...',
        returned: 'RETURNED. Click to replace...',
        skipped: 'SKIPPED. Click to replace...',
    };
    stage = lcfirst( stage );
    if ( !map[ stage ] ) return; //Set elsewhere
    const opt = { text: map[ stage ], value: stage };
    return opt;
}
/* ======================== RELATED FIELDS ================================== */
/**
 * Set review-fields related to fields with approved data.
 * Note: This is a duplicate of some standard entry-methods to handle various
 * edge-cases that can occur during the review process.
 */
function handleRelatedFields ( field ) {                            /*dbug-log*///console.log( '         -- handleRelatedFields field[%s %O]', field.name, field );
    if ( field.combo === 'Publication' ) return form.fillCitationCombo( field.value );
    if ( field.combo === 'CitationTitle' ) { return selectCitation( field ); }
}
/* ---------------------------- RESOLVED ------------------------------------ */
function selectCitation ( field ) {
    el.setSelVal( field.combo, field.review.entityId, 'silent' );
}
/* -------------------------- UNRESOLVED ------------------------------------ */
/** Set review-fields that are dependent on unresolved parent data. */
function fillRelatedFields ( field ) {                              /*dbug-log*///console.log( '           -- fillRelatedFields field[%s][%O]', field.name, field );
    if ( field.combo === 'Publication' ) { return ifPubUnresolvedSkipCitation( field ); }
    if ( field.entity === 'Taxon' ) { return setTaxonCombo( field ); }
}
/**
 * Adds 'skipped' to Citation combo if Publication field is in an incompatible stage.
 */
function ifPubUnresolvedSkipCitation ( field ) {
    const stage = state.getReviewStage( null, field.review );      /*dbug-log*///console.log( '           -- ifPubUnresolvedSkipCitation field[%s][%O] stage[%s]', field.name, field, stage );
    const validStages = isManagerUser() ? [ 'Approved', 'Pending' ] : [ 'Approved' ];
    if ( validStages.indexOf( stage ) !== -1 ) return;
    setReviewStageOption( 'CitationTitle', 'skipped' );
}
/**
 * Sets the current stage relevant to the taxonomy. Ie, when a parent-taxon has
 * been returned or rejected, the field's selected taxon can not be reviewed
 * until all parent data has been resolved.
 */
function setTaxonCombo ( field ) {                                  /*dbug-log*///console.log( '           -- setTaxonCombo field[%s][%O]', field.name, field );
    const ReviewEntries = state.getRecords( 'reviewEntry' );
    const stage = getStageFromTaxonomy( field.review, ReviewEntries );
    if ( stage ) { setReviewStageOption( field.combo, stage ); }
}
function getStageFromTaxonomy ( rEntry, ReviewEntries ) {
    if ( rEntry.entityId ) return false; // Has approved data
    const stage = state.getReviewStage( null, rEntry );             /*dbug-log*///console.log( '               -- getStageFromTaxonomy stage[%s] rEntry[%O]', stage, rEntry );
    const parentEntry = rEntry.form.fields.Parent.review;
    if ( stage !== 'Pending' || !parentEntry ) return stage;
    return getStageFromTaxonomy( ReviewEntries[ parentEntry.id ], ReviewEntries );
}