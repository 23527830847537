/**
 * Initializes the Citation create and edit forms.
 *
 * TOC
 *     CREATE
 *         PARAMS
 *         BUILD
 *     EDIT
 *     HELPERS
 */
/* UTIL */
import { enableCombobox, getSelVal } from '@elems/combo';
import * as _t from '@types';
import { promiseToTask, taskEitherToPromise } from '@util';
/* DATA-ENTRY */
import { form, model } from '@dataentry';
import { getSourceDetailType, initCombos, showSrcSubmitModal } from '@controller/source';
import { getDefaultType } from '../sub-type/get-default';
import { finishCitationFormInit, finishFieldLoadTE } from './finish-build';
/* FP-TS */
import { pipe } from 'fp-ts/function';
import * as T from 'fp-ts/Task';
import * as TE from 'fp-ts/TaskEither';
/* ========================= CREATE ========================================= */
/** Init form when a new citation title is entered into the combobox. */
export function initCreateForm ( v: string | undefined ): Promise<void> {/*dbug-log*///console.log('           >--Citation CREATE Form v[%s]', v);
    return pipe(
        v,
        getCreateParams,
        TE.chain( form.initEntityForm ),
        finishCreateFormBuild,
    );
}
/* ---------------------------- PARAMS -------------------------------------- */
function getCreateParams( v: string | undefined ): TE.TaskEither<string, model.InitCreateParams> {
    return pipe(
        getDefaultType(),
        TE.map( ( [ typeName, typeId ] ) => buildCreateParams( typeName, typeId, v ) ),
    );
}
export function buildCreateParams(
    typeName: _t.CitationType,
    typeId: number,
    v?: string | undefined
): model.InitCreateParams {
    const createParams = {
        appendForm: appendCitationForm,
        combo: 'CitationTitle',
        onFormClose: enablePubField,
        style: 'med-sub-form',
        type: typeName,
        vals: {
            ParentSource: getSelVal( 'Publication' ),
            DisplayName: ( v === 'create' ? '' : v ),
            CitationType: typeId
        }
    };
    return { ...createParams, ...getFormParams( 'sub', 'create' ) };
}
function appendCitationForm( form: HTMLElement ) {
    $( '#CitationTitle_f' ).parent().after( form );
}
/** When the Citation sub-form is exited, the Publication combo is reenabled. */
function enablePubField() {
    enableCombobox( 'Publication' );
}
/* ---------------------------- BUILD --------------------------------------- */
function finishCreateFormBuild( statusTE: TE.TaskEither<string, 'success'> ): Promise<void> {
    return pipe(
        statusTE,
        TE.chain( () => finishCitationFormInit( 'sub' ) ),
        TE.chain( _ => finishFieldLoadTE( 'sub', false ) ),
        taskEitherToPromise
    );
}
/* ========================= EDIT =========================================== */
/** Shows the Citation  sub-form and disables the publication combobox. */
export function initEditForm ( id: string ) {                       /*dbug-log*///console.log( '           >--Citation EDIT Form id[%s]', id );
    return pipe(
        promiseToTask( getSourceDetailType( 'citation', id ) ),
        T.map( type => getEditParams( id, type as _t.CitationType ) ),
        T.chain( form.initEntityForm ),
        TE.chain( _ => finishCitationFormInit( 'top', 'edit' ) ),
        taskEitherToPromise
    );
}
export function getEditParams ( id: string | number, type: _t.CitationType ): model.InitEditParams {
    const editParams = {
        id: parseInt( id as string ),
        style: 'lrg-form',
        type: type,
    };
    return { ...editParams, ...getFormParams( 'top', 'edit' ) };
}
/* ========================= HELPERS ======================================== */
function getFormParams ( fLvl: model.FormGroup, action: model.FormAction ) {
    return {
        action: action,
        initCombos: initCombos.bind( null, fLvl, 'Citation' ),
        group: fLvl,
        name: 'Citation',
        submit: showSrcSubmitModal.bind( null, fLvl ),
    } as const;
}
