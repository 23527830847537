/**
 * Gets the default Citation-type for the Publication type.
 *
 * TOC
 *    DEFAULT CITATION-TYPE FOR PUBLICATION
 *    GET CITATION-TYPE RESULTS
 *        CITATION-TYPE FROM DB
 */
import { state } from '@dataentry';
import { getSelVal } from '@elems/combo';
import { getData } from '@localdata';
import * as _t from '@types';
/* FP-TS */
import * as O from 'fp-ts/lib/Option';
import * as TE from 'fp-ts/lib/TaskEither';
import { pipe } from 'fp-ts/lib/function';
/** In create forms, gets the default Citation-type for the Publication type. */
export function getDefaultType(): TE.TaskEither<string, [_t.CitationType, number]> {
    return pipe(
        getPubRecords(),
        O.map( ( { pubSrc, pub } ) => getDefaultCitType( pubSrc, pub ) ),
        getCitTypeData
    );
}
/* ================= DEFAULT CITATION-TYPE FOR PUBLICATION ================== */
function getPubRecords() {
    return pipe(
        getSelVal( 'Publication' )!,
        getRcrdObj
    )
}
function getRcrdObj( id: string ): O.Option<{pub: _t.Publication, pubSrc: _t.PublicationSource}> {
    return pipe(
        state.getRecords<_t.PublicationSource>( 'source', id ),
        O.fromNullable,
        O.map( pubSrc => {
            return { pubSrc, pub: state.getRecords<_t.Publication>( 'publication', pubSrc.publication ) };
        } ),
    );
}
function getDefaultCitType( pubSrc: _t.PublicationSource, pub: _t.Publication ): _t.CitationType {
    const pubType: string  = pub.publicationType.displayName;
    return {
        Book: hasPubAuthors( pubSrc ) ? 'Book' : 'Chapter',
        Journal: 'Article',
        Other: 'Other',
        'Thesis/Dissertation': 'Ph.D. Dissertation'
    }[ pubType ] as _t.CitationType;
}
function hasPubAuthors( pubSrc: _t.PublicationSource ): boolean {
    return Object.values( pubSrc.contributors ).some( ( c:_t.Contributor ) => !c.isEditor );
}
/* ================= GET CITATION-TYPE RESULTS ============================== */
function getCitTypeData( type:O.Option<_t.CitationType> ): TE.TaskEither<string, [_t.CitationType, number]> {
    return pipe(
        type,
        TE.fromOption( () => 'No Default Citation Type.' ),
        TE.chain( getFromDb ),
    );
}
/* ------------------- CITATION-TYPE FROM DB -------------------------------- */
function getFromDb( citType:_t.CitationType ): TE.TaskEither<string, [_t.CitationType, number]> {
    return pipe(
        getCitTypeNames(),
        TE.map( types => getTypeTuple( citType, types ) )
    );
}
function getCitTypeNames(): TE.TaskEither<string, ( _t.IdsByName | void )> {
    return TE.tryCatch(
        () => getData<_t.IdsByName>( 'citTypeNames' ),
        ( reason ) => String( reason )
    );
}
function getTypeTuple( citType: _t.CitationType, types: _t.IdsByName | void ): [_t.CitationType, number] {
    return pipe(
        types && types[ citType ],
        O.fromNullable,
        O.map( id => [ citType, id ] as [_t.CitationType, number] ),
        O.getOrElse( () => [ citType, 0 ] )
    );
}
