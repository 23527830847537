/**
 * Adds entity data to local storage.
 *
 * Exports
 *     addCoreEntityData
 *     addDetailEntityData
 *
 * TOC
 *     CORE ENTITY
 *         LOCAL PROPS
 *         RELATIONAL UPDATES
 *         EXECUTE UPDATES
 *     DETAIL ENTITY
 */
import { lcfirst } from '@util';
import * as _t from '@types';
import { updateData } from '../execute-update';
import * as add from './add-funcs';
/* ======================== CORE ENTITY ===================================== */
/** Updates stored-data props related to a core-entity record with new data. */
export function addCoreEntityData (
    entity: _t.CoreEntity,
    rcrd: _t.EntityRecord
): void {                                                           /*dbug-log*///console.log("       --Updating Core entity. %s. %O", entity, rcrd);
    add.addToRcrdProp( entity, rcrd, entity );
    const updateFuncs = getRelDataHndlrs( entity, rcrd );           /*dbug-log*///console.log('updateCoreDataProps = %O', updateFuncs);
    updateDataProps( entity, rcrd, updateFuncs );
}
/* --------------------- RELATIONAL UPDATES --------------------------------- */
/** Returns an object of relational data properties and their update methods. */
function getRelDataHndlrs (
    entity: 'interaction' | 'location' | 'source' | 'taxon',
    rcrd: _t.EntityRecord
): AddLocalDataConfig {
    const type = getSourceType( rcrd );
    const update = {
        interaction: {
            location: add.addInteractionToEntity,
            source: add.addInteractionToEntity,
            subject: add.addInteractionRole,
            object: add.addInteractionRole,
        },
        location: {
            location: add.addToParentRcrd,
        },
        source: {
            author: {
                authSrcs: add.addToRcrdAryProp
            },
            citation: {
                authors: add.addContribData,
                source: add.addToParentRcrd,
            },
            publication: {
                pubSrcs: add.addToRcrdAryProp,
                authors: add.addContribData,
                source: add.addToParentRcrd,
                editors: add.addContribData
            },
            publisher: {
                publSrcs: add.addToRcrdAryProp
            },

        },
        taxon: {
            taxon: add.addToParentRcrd,
            taxonNames: add.addToTaxonNames
        }
    };
    const addDataConfig = update[ entity ];
    const config = !type ? addDataConfig : addDataConfig[ type as keyof typeof addDataConfig ];
    return config as AddLocalDataConfig;
}
function getSourceType ( rcrd: _t.EntityRecord ): string | false {
    return rcrd.sourceType ? lcfirst( rcrd.sourceType.displayName ) : false;
}
/* ----------------------- EXECUTE UPDATES ---------------------------------- */
type AddLocalDataConfig = {
    [key: string]: ( ...args: any[] ) => void;
};
// export type AddMethod = <T extends string, U extends string>(
//     prop: T,
//     rcrd: _t.EntityRecord,
//     entity: U
// ) => void;
/** Sends entity-record data to each specialized storage-property handler. */
function updateDataProps (
    entity: string,
    rcrd: _t.EntityRecord,
    updateFuncs: AddLocalDataConfig
): void {                                                           /*dbug-log*///console.log( "           --updateDataProps [%s]. %O. updateFuncs = %O", entity, rcrd, updateFuncs );
    if ( !updateFuncs ) return;
    const params = { entity: entity, rcrd: rcrd, stage: 'addData' as const };
    _t.objectKeys( updateFuncs ).forEach( prop => {
        updateData( updateFuncs[ prop ]!, prop, params );
    } );
}
/* ======================== DETAIL ENTITY =================================== */
/**
 * Updates stored-data props related to a detail-entity record with new data.
 * Note: Relational-data is handled while adding the core-entity record.
 */
export function addDetailEntityData (
    entity: _t.DetailEntity,
    rcrd: _t.EntityRecord
): void {
    add.addToRcrdProp( entity, rcrd, entity );
}