/**
 * Author review form-build.
 *
 * Export
 *     initReviewForm
 *
 * TOC
 *     REVIEW
 *         PARAMS
 *         BUILD
 */
/* UTIL */
import * as _t from '@types';
import { taskEitherToPromise } from '@util';
/* DATA-ENTRY */
import { form, model, state } from '@dataentry';
import { onAuthAndEdSelection } from '@controller/source/author';
import { finishCreateInit, finishFormInit, getCreateParams, getEditParams } from './entry-forms';
/* FP-TS */
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
/* ============================ REVIEW ====================================== */
export function initReviewForm ( rEntry: model.FormReviewEntry ): Promise<void> {/*dbug-log*///console.log( '           >--Author REVIEW Form rEntry[%O]', rEntry );
    return pipe(
        rEntry,
        getReviewFormParams,
        form.initEntityForm,
        TE.map( _ => finishAuthReviewFormInit( rEntry ) ),
        taskEitherToPromise
    );
}
/* ---------------------------- PARAMS -------------------------------------- */
function getReviewFormParams( rEntry:  model.FormReviewEntry ):  model.InitReviewParams {
    return pipe(
        rEntry,
        getEntryParams,
        form.prepReviewParams( rEntry )
    );
}
function getEntryParams( rEntry:  model.FormReviewEntry ): model.InitEntryParams {
    return state.isEntryAction( rEntry, 'create' )
        ? getReviewCreateParams( rEntry )
        : getEditParams( rEntry.form.editId! );
}
function getReviewCreateParams ( rEntry: model.FormReviewEntry ) {
    const { cnt, aType, fLvl } = getCreateEntryParams( rEntry );
    const p = getCreateParams( cnt, aType, fLvl );
    if ( ifFieldShouldPersistAfterSubmit( rEntry ) ) return p;
    p.afterFormClose = () => onAuthAndEdSelection( cnt, aType, '' ); //Clear author field
    return p;
}
function getCreateEntryParams( rEntry: model.FormReviewEntry ) {
    return {
        cnt: rEntry.form.count!,
        aType: rEntry.inst.field as _t.AuthorType,
        fLvl: rEntry.inst.group
    }
}
/**
 * Field is cleared after rejected data is confirmed to allow the contributor
 * to replace. It is not cleared if the rejected data has already been replaced.
 */
function ifFieldShouldPersistAfterSubmit ( rEntry: model.FormReviewEntry ) {
    return rEntry.stage.name !== 'Rejected' || rEntry.entityId;
}
/* ---------------------------- BUILD --------------------------------------- */
function finishAuthReviewFormInit ( rEntry: model.FormReviewEntry ) {
    return state.isEntryAction( rEntry, 'create' )
        ? finishReviewCreateFormInit( rEntry )
        : finishFormInit( rEntry.inst.group );
}
function finishReviewCreateFormInit( rEntry: model.FormReviewEntry ) {
    const { cnt, aType, fLvl } = getCreateEntryParams( rEntry );
    finishCreateInit( cnt, aType, fLvl );
}