/**
 * Initializes the Publication create and edit forms.
 *
 * Export
 *      buildEditParams
 *      getCreateParams
 *      initCreateForm
 *      initEditForm
 *
 * TOC
 *     CREATE
 *         PARAMS
 *         INIT
 *     EDIT
 *         PARAMS
 *         INIT
 *     HELPERS
 */
/* UTILS */
import * as _t from '@types';
import { promiseToTaskEither, taskEitherToPromise } from '@util';
/* DATA-ENTRY */
import * as sForm from '@controller/source';
import { components, form, model } from '@dataentry';
/* FP-TS */
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
/* ========================= CREATE ========================================= */
export function initCreateForm( v?: string ): Promise<void> {         /*dbug-log*///console.log('           >--Publication CREATE Form v[%s]', v);
    form.clearCitationCombo();
    return pipe(
        getCreateParams( 'Journal', v ),
        form.initEntityForm,
        TE.map( _ => components.focusField( 'sub', 'DisplayName' ) ),
        taskEitherToPromise
    );
}
/* ---------------------------- PARAMS -------------------------------------- */
export function getCreateParams(
    type: _t.PublicationType,
    v?: string
): model.InitCreateParams {
    const params = {
        appendForm: appendPublicationForm,
        combo: 'Publication',
        style: 'med-sub-form',
        type: type,
    };
    return { vals: getVal( v ), ...params, ...getFormParams( 'sub', 'create' ) };
}
function appendPublicationForm( form: HTMLElement ) {
    $( '#CitationTitle_f' ).parent().after( form );
}
function getVal( v?:string ): { DisplayName?: string } {
    return v ? { DisplayName: ( v === 'create' ? '' : v ) } : {};
}
/* ============================= EDIT ======================================= */
export function initEditForm( id: number ): Promise<void> {         /*dbug-log*///console.log('           >--Publisher EDIT Form id[%s]', id);
    return pipe(
        id,
        getEditParams,
        TE.chain( form.initEntityForm ),
        TE.map( _ => components.focusField( 'top', 'DisplayName' ) ),
        taskEitherToPromise
    );
}
/* ---------------------------- PARAMS -------------------------------------- */
function getEditParams( id: number ): TE.TaskEither<string, model.InitEditParams> {
    return pipe(
        id,
        getPubType,
        TE.map( type => buildEditParams( id, type ) )
    );
}
function getPubType( id: number ): TE.TaskEither<string, _t.PublicationType> {
    return pipe(
        sForm.getSourceDetailType( 'publication', id ),
        promiseToTaskEither
    );
}
export function buildEditParams( id: number | string, type: _t.PublicationType ): model.InitEditParams {
    const params = {
        id: id,
        style: 'lrg-form',
        type: type,
    };
    return { ...params, ...getFormParams( 'top', 'edit' ) };
}
/* =========================== HELPERS ======================================= */
function getFormParams ( fLvl: model.FormGroup, action: model.FormAction ) {
    return {
        action: action,
        name: 'Publication',
        group: fLvl,
        submit: sForm.showSrcSubmitModal.bind( null, fLvl ),
        initCombos: sForm.initCombos.bind( null, fLvl, 'Publication' ),
    } as const;
}