/**
 * Handles opening and closing the Data-Review Panel.
 *
 * Export
 *     toggleReviewPanel
 *     toggleDataOptsBarButtons
 *
 * TOC
 *     INIT
 *     TOGGLE
 */
import { table, ui } from '@explore';
/* ========================== TOGGLE ======================================== */
export function toggleReviewPanel() {
    if ( $( '#review-pnl' ).hasClass( 'closed' ) ) {
        buildAndShowReviewPanel();
    } else { hideReviewPanel(); }
}
export function toggleDataOptsBarButtons( enable = true ) {           /*dbug-log*///console.log('--toggleDataOptsBarButtons enable?[%s]', enable);
    const opac = enable ? 1 : .3;
    const cursor = enable ? 'pointer' : 'not-allowed';
    $( '#new-data, #data-help' ).attr( 'disabled', !enable )
        .css( { 'opacity': opac, 'cursor': cursor } );
}
export function getOnCloseHandler () {
    return toggleDataOptsBarButtons;
}
/* ------------------------------- SHOW ------------------------------------- */
function buildAndShowReviewPanel() {
    ui.togglePanel( 'review', 'open' );
    toggleDataOptsBarButtons( false );
    showUpdatedByColumn();
    $( '#data-opts' ).append( getPseudoBorderStyle() )
}
function getPseudoBorderStyle() {
    const panelT = $( '#fltr-int-pnl-cntnr' ).position().top; //This container does not move
    const tabW = $( '#data-opts' ).innerWidth();
    const tabL = $( '#data-opts' ).position().left + 1;             /*dbug-log*///console.log( 'sizePanelTab. Top[%O], Width[%s], Length[%s]', panelT, tabW, tabL );
    return `<style>.hide-rvw-bttm-border:before {
        position: absolute;
        content: '';
        height: 3px;
        z-index: 10;
        width: ${ tabW }px;
        top: ${ panelT }px;
        left: ${ tabL }px;
        background: #d6ebff;
        }</style>`;
}
function showUpdatedByColumn( show = true ) {
    table.getState( 'columnApi' ).setColumnsVisible( [ 'updatedBy' ], show );
}
/* ------------------------------- HIDE ------------------------------------- */
function hideReviewPanel() {
    ui.togglePanel( 'review', 'close' );
    $( '.hide-rvw-bttm-border:before' ).remove();
    toggleDataOptsBarButtons( true );
    showUpdatedByColumn( false );
}