/**
 * Adds a marker to the map for each interaction with any location data, either
 * for all locations or for a single location and it's children.
 *
 * EXPORT
 *     addInteractionMarkersToMap
 *     fillIntCntLegend
 *
 * TOC
 *     PROCESS INTERACTIONS
 *         SINGLE LOCATION
 *         ALL REGIONS
 *     BUILD MARKERS
 *         GET MARKERS FOR CHILDREN
 *         GET MARKERS FOR LOCATION
 */
/* UTILS */
import { getData } from '@localdata';
import { isObj } from '@util';
/* MAP */
import { addMarkersToMap, addPopupToState, getMapRecords } from '@explore/map/manager.js';
import * as mapUtil from '@explore/map/util';
import { fillIntCntLegend } from '../on-init.js';
/**
 * Default Location "Map View":
 *     Adds a marker to the map for each interaction with any location data. Each
 *     marker has a popup with either the location name and the country, just the
 *     country or region name. Locations without gps data are added to markers at
 *     the country level with "Unspecified" as the location name. Inside the app.popups
 *     is a "Location" button that will replace the name popup with a
 *     summary of the interactions at the location.
 * When displaying a single location:
 *     Similar to the default view, but only displays interactions for the location.
 *
 */
export async function addInteractionMarkersToMap( loc ) {
    const { gps, noGps, markers } = await processInteractions( loc );/*dbug-log*///console.log( '+--addInteractionMarkersToMap gpsCnt[%s] noGpsCnt[%s] markers[%O] loc?[%O]', gps, noGps, markers, loc );
    addMarkersToMap( markers.filter( m => m ) );
    fillIntCntLegend( gps, noGps );
}
/* ====================== PROCESS INTERACTIONS ============================== */
async function processInteractions( loc ) {
    return await loc ? processSingleLocation( loc ) : processRegions();
}
/* ----------------------- SINGLE LOCATION ---------------------------------- */
function processSingleLocation( loc ) {
    const gps = loc.totalInts;
    const noGps = 0;
    const markers = getMarkersForLocAndChildren( null, loc );       /*dbug-log*///console.log( '      --processSingleLocation loc[%O] markers[%O]', loc, markers );
    return { gps, noGps, markers };
}
/* ------------------------- ALL REGIONS ------------------------------------ */
async function processRegions() {
    let gps = 0;
    let noGps = 0;
    let markers = await processRegionInteractions();                /*dbug-log*///console.log( '      --processAllRegions gpsCnt[%s] noGpsCnt[%s] markers[%O]', gps, noGps, markers );
    return { gps, noGps, markers };

    async function processRegionInteractions () {
        const regions = await getRegionLocs();
        return regions.reduce( processRegion, [] );
    }
    function processRegion( markers, region ) {
        const isUnspecified = region.displayName === "Unspecified";
        processCounts( region, isUnspecified )
        return processMarkers( markers, region, isUnspecified );
    }
    function processCounts( region, isUnspecified ) {
        if ( isUnspecified ) noGps += region.totalInts;
        else gps += region.totalInts;
    }
}
async function getRegionLocs () {
    const rcrds = getMapRecords( 'location' );
    const regionNames = await getData( 'topRegionNames' );
    return Object.values( regionNames ).map( id => rcrds[ id ] );
}
/* ====================== BUILD MARKERS ===================================== */
function processMarkers( markers, region, isUnspecified ) {
    return isUnspecified
        ? markers
        : markers.concat( getMarkersForLocAndChildren( null, region ) )
}
function getMarkersForLocAndChildren ( pLoc, loc ) {
    if ( !loc.totalInts ) return [];                                  /*dbug-log*///console.log( '  --getMarkersForLocAndChildren for pLoc?[%O] loc[%s][%O]', pLoc, loc.displayName, loc );
    const locMarker = getLocInteractionMarker( pLoc, loc );
    return [ ...buildMarkersForLocChildren( loc ), locMarker ].filter( m => m );
}
/* ---------------- GET MARKERS FOR CHILDREN -------------------------------- */
/**
 * Note: When displaying a user-made set "list" of interactions focused on locations in
 * "Map Data" view, the locations displayed on the map are only those in the set
 * and their popup data reflects the data of the set.
 */
function buildMarkersForLocChildren ( pLoc ) {
    return pLoc.children.flatMap( c => getChildLocMarkers( pLoc, c ) );
}
function getChildLocMarkers ( pLoc, c ) {
    const loc = isObj( c ) ? c : getMapRecords( 'location', c );
    return isCountry( loc ) || loc.children.length
        ? getMarkersForLocAndChildren( pLoc, loc )
        : getLocInteractionMarker( pLoc, loc );
}
function isCountry( loc ) {
    return loc.locationType.displayName == 'Country';
}
/* ----------------- GET MARKER FOR LOCATION -------------------------------- */
function getLocInteractionMarker ( pLoc, loc ) {                       /*dbug-log*///console.log( '              --getLocInteractionMarker pLoc[%O] loc[%s][%O]', pLoc, loc.displayName, loc );
    if ( !loc.interactions.length ) return;
    const latlng = mapUtil.getCenterCoords( loc, loc.geoJsonId );   /*dbug-log*///console.log( '                --latlng[%O]', latlng )
    if ( !latlng ) return logNoGeoJsonError( pLoc, loc );
    return getMarkerAndHandlePopupState( latlng, loc );
}
function logNoGeoJsonError ( pLoc, loc ) {
    if ( !loc.interactions.length ) return;
    if ( pLoc && isHabitatofParentLoc( pLoc, loc ) ) return;
    console.log( '###### No geoJson for [%s] %O', loc.displayName, loc )
}
function isHabitatofParentLoc ( pLoc, loc ) {                       /*dbug-log*///console.log( '              --isHabitatofParentLoc pLoc[%O] loc[%O]', pLoc, loc );
    const subName = loc.displayName.split( '-' )[ 0 ];
    const topName = pLoc.displayName;
    return topName.indexOf( subName ) !== -1;
}
function getMarkerAndHandlePopupState ( latlng, loc ) {              /*dbug-log*///console.log( '              --adding [%s] markers at coords[%O]', loc.interactions.length, latlng );
    const marker = mapUtil.buildLocMarker( latlng, loc.interactions.length, loc );
    addPopupToState( loc, marker.popup );
    return marker;
}