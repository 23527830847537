/**
 * Either displays coordinates at click location; or drops a new map pin and updates
 * the form.
 *
 * EXPORT
 *     onMapClick
 *
 * TOC
 *     PREVENT POPUP ON MAP TOOL CLICK
 *     SHOW POPUP
 */
import { geocodeOnFormMapClick } from '@explore/map/actions'
import { getMapState } from '@explore/map/manager.js'
/**
 * Either displays coordinates at click location; or drops a new map pin and updates
 * the form.
 */
export function onMapClick ( type, e ) {                            /*dbug-log*///console.log( '+--onMapClick. type[%s] e[%O]', type, e )
    if ( ifClickOnMapTool( e ) ) return;
    showLatLngPopup( type, e );
}
/* ================= PREVENT POPUP ON MAP TOOL CLICK ======================== */
/** Catches clicks on map buttons or tools. */
function ifClickOnMapTool ( e ) {                                   /*dbug-log*///console.log( '  -- ifClickOnMapTool e[%O]', e )
    let elemClass = getClickedElemClass( e.originalEvent.target );
    return typeof elemClass === 'string' && elemClass.includes( 'leaflet-control' );
}
function getClickedElemClass ( elem ) {
    return elem.className ? elem.className :
        elem._container ? elem._container.className : '';
}
/* ===================== SHOW POPUP ========================================= */
function showLatLngPopup ( type, e ) {
    return isExploreMap( type ) ? showCoordPopup( e ) : geocodeOnFormMapClick( type, e );
}
function isExploreMap( type ) {
    return [ 'create', 'edit', 'int' ].indexOf( type ) === -1;
}
function showCoordPopup ( e ) {
    const latLngTxt = getCoordText( e.latlng );
    new L.Popup()
        .setLatLng( e.latlng )
        .setContent( latLngTxt )
        .openOn( getMapState( 'map' ) );
}
function getCoordText( coords ) {
    return `Lat, Lon: ${ coords.lat.toFixed( 5 ) }, ${ coords.lng.toFixed( 5 ) }`;
}