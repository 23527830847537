/**
 * Prepares the ReviewEntry record for the data-review process, updating all
 * fields and adding data for the current instance.
 *
 * Export
 *     prepareRecordForReview
 *     updateReviewEntryFields
 *
 * TOC
 *     INSTANCE DATA
 *     UPDATE PENDING SUB-DATA
 *         UPDATE FIELD VALUE
 */
import { state } from '@dataentry';
import { isMultiField } from '@elems';
import { cloneObj } from '@util';
/**
 * Prepares the ReviewEntry for review, updating all fields with ReviewEntry records
 * and adding data for the current instance.
 * @param  {object} ReviewEntries  All ReviewEntry records
 * @param  {object} rcrd   The ReviewEntry record to be reviewed
 * @param  {string} fLvl   Form level
 * @param  {string} key  Field with ReviewEntry being reviewed, or null
 * @return {object}        The ReviewEntry record to be reviewed
 */
export default function prepareRecordForReview ( ReviewEntries, rcrd, fLvl, key ) {
    const rEntry = cloneObj( rcrd );
    setReviewEntityInstanceProps( rEntry, key, fLvl );
    updateReviewEntryFields( rEntry.form.fields, ReviewEntries );
    return rEntry;
}
/* ====================== INSTANCE DATA ===================================== */
/** Sets data specific to the current review instance. */
function setReviewEntityInstanceProps ( rEntry, key, fLvl ) {
    const inst = {  // properties for the current instance
        group: fLvl,
        initStage: rEntry.stage.name,
    }
    if ( key ) inst.field = key; //Parent-form field name
    rEntry.inst = inst;
}
/* ================= UPDATE PENDING SUB-DATA ================================ */
/**
 * Updates the fields with the most recent versions of their ReviewEntry.
 * Note: referenced fields may loop through multiple times, eg parent and contrib fields.
 * @param  {object} fields  Form fields: review-data only (init) or field-config
 * @param  {object} ReviewEntries   ReviewEntry records, recently pulled from server
 */
export function updateReviewEntryFields ( fields, ReviewEntries ) { /*dbug-log*///console.log( ' +--updateReviewEntryFields fields[%O] ReviewEntries[%O]', cloneObj( fields ), ReviewEntries );
    ReviewEntries = ReviewEntries || state.getRecords( 'reviewEntry' );
    for ( let key in fields ) updatePendingField( key, fields[ key ], ReviewEntries );
}
/**
 * Before form-init: Field ReviewEntry property is set with the ReviewEntry record.
 * After sub-form submit: Field ReviewEntry is synced with the latest version.
 * Note: The review property acts as a flag for additional init and submit processing.
 * @param  {string} key    Field key (Necessary before form-field init for debugging)
 * @param  {object} field  After init: field confg, Before init: base data for review
 * @param  {object} ReviewEntries  ReviewEntry records, recently pulled from server
 */
function updatePendingField ( key, field, ReviewEntries ) {       /*dbug-log*///console.log( '       --updatePendingField field[%s][%O]', key, cloneObj( field ) );
    if ( !field.review ) return;                                    /*dbug-log*///console.log( '       --updatePendingField field[%s][%O]', key, cloneObj( field ) );
    if ( isMultiField( field ) ) return processPendingMulti( field, ReviewEntries );
    updateReviewEntryFieldState( field.review.id, field, ReviewEntries );/*dbug-log*///console.log( '       --AFTER updatePendingField field[%s][%O]', key, cloneObj( field ) );
}
function processPendingMulti ( field, ReviewEntries ) {            /*dbug-log*///console.log( '               --processPendingMulti field[%O] ReviewEntries[%O]', cloneObj( field ), ReviewEntries );
    for ( let ord in field.review ) updateMultiField( ord );

    function updateMultiField ( ord ) {
        if ( !field.review[ ord ] ) return;                        /*dbug-log*///console.log( '               --updateMultiField ord[%s] pendingField[%O]', ord, field.review[ ord ] );
        updateReviewEntryFieldState( field.review[ ord ].id, field, ReviewEntries, ord );
    }
}
function updateReviewEntryFieldState ( id, field, ReviewEntries, ord ) {
    const rEntry = ReviewEntries[ id ];                             /*dbug-log*///console.log( '                       -- updateReviewEntryFieldState rEntry[%s %O] field[%O] ord?[%s]', id, rEntry, field, ord );
    updateFieldValue( rEntry, field, ord );
    setFieldModelReviewEntry( field, rEntry, ord );
}
function setFieldModelReviewEntry( field, rEntry, ord ) {
    if ( ord ) field.review[ ord ] = rEntry;
    else field.review = rEntry;
}
/** --------------------- UPDATE FIELD VALUE -------------------------------- */
/**
 * Contributors have access to their data during the review process. Entity data
 * are quarantined with temporary IDs. During manager review, these are removed.
 * Note: They are reset during submit if the quarantined ids remain relevant.
 * @param  {object} rEntry  ReviewEntry entity
 * @param  {object} field  After init: field confg, Before init: base data for review
 * @param  {string} ord    Multi-field ordinal
 */
function updateFieldValue ( rEntry, field, ord ) {
    if ( state.isValueQuarantined( rEntry ) ) return; // Quarantined-data ID persists for contributor
    const newValue = rEntry.entityId ? rEntry.entityId : null;      /*dbug-log*///console.log( '               -- updateFieldValue rEntry[%O] field[%O] ord[%s] newValue[%s]', rEntry, field, ord, newValue );
    state.setFieldValueProp( field, newValue, ord );
}