/**
 * Updates the form with the geocode results. Draws available polygon on map, shows
 * all locations in containing country/region, adds a map pin for the coodinates,
 * and updates relevant form-fields with the geocoded data.
 *
 * EXPORT
 *     geocodeOnFormMapClick
 *     geocodeInForm
 *     removePreviousMapPin
 *
 * TOC
 *     GEOCODE IN FORM
 *     UPDATE UI AFTER GEOCODE
 *         BUILD LOC DATA
 *         SYNC FORM UI
 *     UPDATE MAP PIN
 *         BUILD MARKER
 *         REPLACE PIN
 */
import { addMapLayer, getMapState, removeMapLayer, setMapState, setMapView } from '@explore/map/manager.js';
import { addParentLocDataToMap, buildLocMarker, getCountryId } from '@explore/map/util';
import { setSilentVal } from '@dataentry/components';
/* ======================== GEOCODE IN FORM ================================= */
export function geocodeOnFormMapClick ( type, e ) {                 /*dbug-log*///console.log( '--geocodeOnFormMapClick. type?[%s] e[%O]', type, e );
    $( '#form-map' ).css( 'cursor', 'progress' );
    geocodeInForm( e.latlng, type );
}
export function geocodeInForm( latlng, type ) {                     /*dbug-log*///console.log( '--geocodeInForm. type?[%s] latlng[%O]', type, latlng );
    const geoCoder = getMapState( 'geoCoder' );
    geoCoder.reverse( latlng, 1, afterMapGeocode( latlng, type ), null );
}
/* =================== UPDATE UI AFTER GEOCODE ============================== */
/** Returns a function to be called after a geocode request is completed. */
function afterMapGeocode( latlng, type = false, skipZoom = false ) {
    return results => updateUiAfterFormGeocode( latlng, type, skipZoom, results[ 0 ] )
}
/**
 * Updates the map with the geocode results. Draws containing polygon on map, shows
 * all locations in containing country, and adds a map pin for the entered coodinates.
 */
function updateUiAfterFormGeocode ( latlng, type, skipZoom, results ) {/*dbug-log*///console.log( '--updateUiAfterFormGeocode type?[%s] skipZoom?[%s] point[%O] results[%O]', type, skipZoom, latlng, results );
    if ( !getMapState( 'map' ) ) return; /* Form closed */      /*dbug-log*///console.log( '--updateUiAfterFormGeocode type?[%s] skipZoom?[%s] point[%O] results[%O]', type, skipZoom, latlng, results );
    if ( hasNoResults( results ) ) return removePreviousMapPin( null );
    addMapPinAndShowSummary( latlng, type, skipZoom, results );
}
function hasNoResults( results ) {
    return !results  || !Object.keys( results ).length;
}
async function addMapPinAndShowSummary( latlng, type, skipZoom, results ) {/*dbug-log*///console.log( '--addMapPinAndShowSummary type?[%s] skipZoom?[%s] point[%O] results[%O]', type, skipZoom, latlng, results );
    const loc = results ? await buildLocData( latlng, results ) : null;
    updateMapPin( latlng, type, skipZoom, loc );
    syncFormUi( type, loc );
}
/* --------------------- BUILD LOC DATA ------------------------------------- */
/** Builds location data used in the Marker popup from geocode results. */
async function buildLocData ( latlng, results ) {                   /*dbug-log*///console.log( '--buildLocData. latlng[%O] results[%O]', latlng, results );
    const query = results.target?._lastGeocode;
    return {
        cntryId: await getCountryId( results.properties, query ),
        lat: latlng.lat,
        lng: latlng.lng,
        name: results.name,
    };
}
/* --------------------- SYNC FORM UI --------------------------------------- */
function syncFormUi( type, loc ) {
    selectCountryInForm( loc.cntryId, type );
    updateMapElemAttrs( type );
}
function updateMapElemAttrs( type ) {
    const $map = $( '#' + getMapState( 'map' )._container.id );
    $map.css( 'cursor', 'default' );
    if ( type === 'edit' ) $map.data( 'loaded' );
}
function selectCountryInForm ( id, formType ) {
    if ( !id ) return;
    const fLvl = $( '#form-map' ).data( 'fLvl' ) || 'top';
    const field = formType === 'int' ? 'Country-Region' : 'Country';
    setSilentVal( fLvl, field, id );
}
/* ==================== UPDATE MAP PIN ====================================== */
function updateMapPin ( latlng, type, skipZoom, loc ) {             /*dbug-log*///console.log( '--updateMapPin. type?[%s] skipZoom?[%s] latlng[%O] loc[%O]', type, skipZoom, latlng, loc );
    const pinType = getPinType( type );
    const marker = buildLocMarker( latlng, 1, loc, pinType );
    replaceMapPin( latlng, loc, type, skipZoom, marker );
    marker.openPopup();
}
function getPinType( type ) {
    return type === 'int'
        ? 'form-int'
        : type === 'edit'
            ? 'editform-loc'
            : 'form-loc'; //create form
}
/* -------------------- BUILD MARKER ---------------------------------------- */
function replaceMapPin ( latlng, loc, type, skipZoom, marker ) {    /*dbug-log*///console.log( '--replaceMapPin. type?[%s] skipZoom?[%s] latlng[%O] loc[%O] marker[%O]', type, skipZoom, latlng, loc, marker );
    removePreviousMapPin( loc );
    if ( loc?.cntryId && type !== 'edit' ) addParentLocDataToMap( loc.cntryId, type, skipZoom );
    addPinToMap( latlng, marker, type, skipZoom );
}
function addPinToMap ( latlng, marker, type, skipZoom ) {
    addVolatileMarkerState( marker )
    addMapLayer( marker.layer );
    if ( !skipZoom ) zoomMapToPin( latlng, type );
}
function addVolatileMarkerState( marker ) {
    const volatile = getMapState( 'volatile' );
    volatile.marker = marker;
    volatile.pin = marker.layer;
    setMapState( 'volatile', volatile );
}
function zoomMapToPin( latlng, type ) {
    const zoom = type ? getMapState( 'map' ).getZoom() : 8;
    setMapView( latlng, zoom );
}
/* ------------------------- REPLACE PIN ------------------------------------ */
export function removePreviousMapPin ( loc ) {                    /*dbug-log*///console.log( '--removePreviousMapPin. loc?[%O]', loc );
    const prevPin = getMapState( 'volatile' ).pin;
    if ( !prevPin ) return addVolatileMapPinState( loc );
    removeMapLayer( prevPin );
}
function addVolatileMapPinState( loc ) {
    const volatile = getMapState( 'volatile' );
    volatile.pin = loc;
    setMapState( 'volatile', volatile );
}