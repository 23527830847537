/**
 * Handlers taxonym verification using the Global Name Verifier and displays the results.
 *
 * Export
 *     verifyTaxonymAndDisplayResults
 *
 * TOC
 *     BUILD CONTAINER
 *     VERIFICATION
 *         FETCH
 *         DISPLAY
 */
import * as model from '@dataentry/model'; //todo2: decouple
import { ApiResponse } from './global-names-verifier-types';
import { getResultDisplayElems, getResultsContainer, getTaxonymElem } from './result-elems';
/* FP-TS */
import * as O from 'fp-ts/lib/Option';
import * as RA from 'fp-ts/lib/ReadonlyArray';
import * as TE from 'fp-ts/lib/TaskEither';
import { pipe } from 'fp-ts/lib/function';
import * as S from 'fp-ts/lib/string';

export function verifyTaxonymAndDisplayResults ( fLvl: model.FormGroup ): void {/*dbug-log*///console.log( '  --verifyTaxonymAndDisplayResults fLvl[%s]', fLvl );
    initVerificationResultsContainer( fLvl );
    pipe(
        checkOnlineStatus(),
        TE.chain( () => verifyTaxonym( fLvl ) ),
        TE.map( results => displayVerificationResults( fLvl, results ) )
    )();
}
function checkOnlineStatus(): TE.TaskEither<string, boolean> {
    return navigator.onLine ? TE.right( true ) : TE.left( 'Alert: Offline' );
}
/* ========================== INIT CONTAINER ================================ */
function initVerificationResultsContainer ( fLvl: model.FormGroup ): void {
    pipe(
        getTaxonymElem( fLvl, 'result' ).length ? O.none : O.some( null ),
        O.map( getResultsContainer ),
        O.map( cntnr => appendContainer( fLvl, cntnr ) ),
    );
}
function appendContainer ( fLvl: model.FormGroup, container: HTMLDivElement ): void {
    getTaxonymElem( fLvl, 'field' )
        .append( container )
        .addClass( 'active-verification' );
}
/* ========================== VERIFICATION ================================== */
/* ------------------------------ FETCH ------------------------------------- */
/**
 * @return {prm}         Promise: verification-response
 */
function verifyTaxonym ( fLvl: model.FormGroup ): TE.TaskEither<string, ApiResponse> {      /*dbug-log*///console.log( '  --verifyTaxonym' );
    return pipe(
        fetchTaxonymVerification( fLvl ),
        TE.chain( getResponseJson )
    );
}
function fetchTaxonymVerification( fLvl: model.FormGroup ): TE.TaskEither<string, Response> {
    return TE.tryCatch(
        () => fetch( buildTaxonymVerificationUrl( fLvl ) ),
        e => String( e )
    );
}
function getResponseJson( response: Response ): TE.TaskEither<string, ApiResponse> {
    return TE.tryCatch( () => response.json(), e => String( e ) );
}
function buildTaxonymVerificationUrl ( fLvl: model.FormGroup ): string {
    let url = 'https://verifier.globalnames.org/api/v1/verifications/';
    url += addEscapedTaxonym( fLvl );                                     /*dbug-log*///console.log( '  -- buildTaxonymVerificationUrl url[%s]', url );
    return url;
}
function addEscapedTaxonym ( fLvl: model.FormGroup ): string {
    const $input = getTaxonymElem( fLvl, 'input' );
    return pipe(
        $input.val() as string,
        S.trim,
        S.split( ' ' ),
        RA.reduce( '', ( acc, cur ) => acc + ( acc ? '+' : '' ) + cur  ),
    );
}
/* ----------------------------- DISPLAY ------------------------------------ */
/**
 * Handles the taxonym verification results.
 * Results schema: https://apidoc.globalnames.org/gnames-beta#/default/get_verifications__names_
 * @param  {json}  results  JSON results returned from the global names verifier
 */
function displayVerificationResults ( fLvl: model.FormGroup, results: ApiResponse ): void {
    const { matchType, bestResult } = results.names[ 0 ];           /*dbug-log*///console.log( '  -- displayVerificationResults type[%s] bestResult?[%O] results[%O]', matchType, bestResult, results );
    pipe(
        getResultDisplayElems( fLvl, matchType, bestResult ),
        O.map( elems => displayResults( fLvl, elems ) ),
        O.getOrElse( () => console.log( 'No verification match data found.' ) )
    );
}
function displayResults ( fLvl: model.FormGroup, elems: string[] ): void {
    getTaxonymElem( fLvl, 'result' )
        .empty()
        .html( elems as any )
        .append( getApiLink() );
}
function getApiLink () {
    return `<p id="api-verification">
        <a href="https://verifier.globalnames.org" target="_blank">Global Names Verifier</a></p>`;
}