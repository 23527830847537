/**
 * Builds an object sorted by geoJsonId with all interaction data at that location.
 * -> geoJsonId: {locs: [{loc}], ints: [{name: [intRcrds]}], ttl: ## }
 * Note: Chaotic, in need of refactor.
 *
 * Export
 *     buildMapDataObj
 */
import { table } from '@explore';
import { getDetachedRcrd } from '@localdata';

let curBaseNodeName; //used for Source rows
let mapData;
let rcrds;
let tblState;
let viewRcrds;

function initMapData() {
    return { 'none': { ttl: 0, ints: {}, locs: null } };
}
export default function buildMapDataObj( viewRecords, records ) {
    initModuleParams( viewRecords, records )
    tblState.api.forEachNodeAfterFilter( getIntMapData );
    return mapData;
}
function initModuleParams( viewRecords, records ) {
    mapData = initMapData();
    rcrds = records;                                               //console.log( 'rcrds = %O', rcrds );
    tblState = table.getState( [ 'api', 'curFocus', 'curView', 'rowData' ] );
    viewRcrds = viewRecords;
}
function getIntMapData( row ) {
    if ( row.data.treeLvl === 0 ) { curBaseNodeName = row.data.name; }
    if ( !row.data.interactions || hasUnspecifiedRow( row.data ) ) return;
    const rcrd = getDetachedRcrd( row.data.id, viewRcrds );
    buildInteractionMapData( row, row.data, rcrd );
}
function buildInteractionMapData( row, rowData, rcrd ) {                       //console.log( 'buildIntMapData row[%O] rowData[%O]', row, rowData )
    const locs = {/*locId: { loc: loc, ints: [rcrd]*/};
    let noLocCnt = 0;
    const data = {
        intCnt: 0,
        name: getRowRcrdName( rowData, rcrd, curBaseNodeName ),
        rcrd: rcrd
    };
    row.childrenAfterFilter.forEach( addRowData ); //interactions
    addToMapDataObj( data, locs, noLocCnt );
    /** Adds to mapData obj by geoJsonId, or tracks if no location data. */
    function addRowData( intRow ) {                                        //console.log( '----intRow = %O', intRow )
        if ( !intRow.data.location ) { return ++noLocCnt; }
        const intRcrd = getDetachedRcrd( intRow.data.id, rcrds.interaction );         //console.log( '----intRow = %O, intRcd = %O, rcrds.location = %O', intRow, intRcrd, rcrds.location );
        const loc = getDetachedRcrd( intRcrd.location, rcrds.location );
        addLocAndIntData( loc, intRcrd );
        ++data.intCnt;
    }
    function addLocAndIntData( newLoc, intRcrd ) {
        if ( !locs[ newLoc.id ] ) { initLocObj() }
        locs[ newLoc.id ].ints.push( intRcrd );

        function initLocObj() {
            locs[ newLoc.id ] = { loc: newLoc, ints: [] };
        }
    }
} /* End buildInteractionMapData */
function addToMapDataObj( entData, locs, noLocCnt ) {
    mapData.none.ttl += noLocCnt;
    for ( let id in locs ) {
        addData( locs[ id ], entData );
    }
}
function addData( locObj, entData ) {
    const geoId = locObj.loc.geoJsonId;
    if ( !geoId ) { return mapData.none.ttl += locObj.ints.length; }
    if ( !mapData[ geoId ] ) { initDataObj( geoId, locObj.loc ); }
    mapData[ geoId ].ttl += locObj.ints.length;
    addIfNewLoc( locObj.loc, geoId );
    addIntData( locObj, entData, geoId );
}
function addIntData( locObj, entData, geoId ) {
    if ( !mapData[ geoId ].ints[ entData.name ] ) { initIntDataObj( entData, geoId ); }
    if ( tblState.curView == 'auths' ) { return sanitizeAndAddInt(); }
    addToIntObj( entData.name )

    function addToIntObj( key ) {
        mapData[ geoId ].ints[ key ] = mapData[ geoId ].ints[ key ].concat( locObj.ints );
    }
    /**
     * When author interactions are displayed, they often duplicate if two
     * authors attrbuted to the same work are shown. This combines the author
     * names in that case, thus showing the interaction once.
     */
    function sanitizeAndAddInt() {
        const keyStr = entData.name.split( ' - (' )[ 1 ];
        const curAuth = entData.name.split( ' - (' )[ 0 ];
        const toCombine = Object.keys( mapData[ geoId ].ints ).find( key => key.includes( keyStr ) && !key.includes( curAuth ) );
        if ( !toCombine ) { addToIntObj( entData.name );
        } else { modifyAndCombineInt( toCombine, keyStr, curAuth ); }
    }
    function modifyAndCombineInt( keyName, work, curAuth ) {
        let auths = keyName.split( ' - (' )[ 0 ];
        auths += `, ${ curAuth } - (${ work }`;
        mapData[ geoId ].ints[ auths ] = mapData[ geoId ].ints[ keyName ];
        delete mapData[ geoId ].ints[ keyName ];
    }
}
function initIntDataObj( entData, geoId ) {
    mapData[ geoId ].ints[ entData.name ] = [];
}
/** Some locations share geoJson with their parent, eg habitats. */
function addIfNewLoc( newLoc, geoId ) {
    const alreadyAdded = mapData[ geoId ].locs.find( loc => loc.displayName === newLoc.displayName );
    if ( alreadyAdded ) return;
    mapData[ geoId ].locs.push( newLoc );
}
function initDataObj( geoId, loc ) {
    mapData[ geoId ] = { ints: {/* name: [rcrds] */}, locs: [ loc ], ttl: 0 };
}
function hasUnspecifiedRow( rowData ) {
    return rowData.children[ 0 ].name.indexOf( 'Unspecified' ) !== -1;
}
function getRowRcrdName( rowData, rcrd, baseNode ) {
    if ( tblState.curFocus === 'srcs' ) { return getSrcRowName( rowData, rcrd, baseNode )}
    return getRcrdDisplayName( rowData.name, rcrd );
}
/** Adds the base entity name before the name of the work, eg Author (work) */
function getSrcRowName( rowData, rcrd, baseNode ) {
    const work = getRcrdDisplayName( rowData.name, rcrd );
    if ( work == baseNode ) { return baseNode; }
    return `${ baseNode } - (${ work })`;
}
function getRcrdDisplayName( displayName, rcrd ) {
    return displayName === 'Whole work cited.' ? rcrd.name : displayName;
}
