/**
 * Returns the column-confg object for the agGrid table.
 *
 * Export
 *     getInteractionRowIcons
 *     getShowIcon
 *
 * TOC
 *     TAXON-GROUP ICONS
 *         GROUP NAME
 *         GROUP STYLE
 *         INTERACTINO ARROW
 *     SHOW INTERACTION-PAGE ICON
 *     BUILD ICON
 */
/* --- ICONS --- */
import searchIcon from '@images/icons/search.svg';
import arrowRight from '@images/icons/arrow-right.svg';
import arrowsAltH from '@images/icons/arrows-alt-h.svg';
/* TAXONOMY */
import amphibianIcon from '@images/icons/amphibian.svg';
import bacteriaIcon from '@images/icons/bacteria.svg';
import batIcon from '@images/icons/bat.svg';
import birdIcon from '@images/icons/bird.svg';
import bugIcon from '@images/icons/bug.svg';
import chromistaIcon from '@images/icons/chromista.svg';
import fishIcon from '@images/icons/fish.svg';
import fungiIcon from '@images/icons/fungi.svg';
import mammalIcon from '@images/icons/mammal.svg';
import plantIcon from '@images/icons/plant.svg';
import protozoaIcon from '@images/icons/protozoa.svg';
import reptileIcon from '@images/icons/reptile.svg';
import virusIcon from '@images/icons/virus.svg';
import wormIcon from '@images/icons/worm.svg';

import { ucfirst } from "@util";

let tblState;

const taxaIcon = {
    Amphibian: amphibianIcon,
    Arthropod: bugIcon,
    Bacteria: bacteriaIcon,
    Bat: batIcon,
    Bird: birdIcon,
    Chromista: chromistaIcon,
    Fish: fishIcon,
    Fungi: fungiIcon,
    Plant: plantIcon,
    Protozoa: protozoaIcon,
    Mammal: mammalIcon,
    Reptile: reptileIcon,
    Virus: virusIcon,
    Worm: wormIcon,
};

export function getInteractionRowTreeIcons( data, tState ) {          /*dbug-log*///console.log('--getInteractionRowIcons row[%O] tState[%O]', data, tState);
    if ( !data.subjGroupId ) { return ''; }  // local database is not fully downloaded
    tblState = tState;
    const treeIcons = [
        getTaxonGroupIcon( 'subject', data ),
        getInteractionTypeArrow( data ),
        getTaxonGroupIcon( 'object', data ),
        getShowIcon( 'interaction', data.id, data.isQuarantined )
    ]; //elems are added in reverse into the parent, agGrid's row-span
    const html = treeIcons.reverse().join( ' ' );                     /*dbug-log*///console.log('--icon html [%s]', html);
    return html;
}
/* ===================== TAXON-GROUP ICONS ================================== */
function getTaxonGroupIcon( role, data ) {
    const group = getTxnGroupName( role, data );
    const style = getGroupIconStyle( group );
    const src = taxaIcon[ group ];
    return buildIcon( src, style, group, `tree-icon ${ role }-icon` );
}
/* --------------------- GROUP NAME ----------------------------------------- */
function getTxnGroupName( role, data ) {
    const prop = ( role === 'object' ? 'o' : 'su' ) +'bjGroupId';
    const id = data[ prop ];
    const group = tblState.data.group[ id ];
    return group.displayName;
}
/* -------------------- GROUP STYLE ----------------------------------------- */
function getGroupIconStyle( group ) {
    const map = {
        Bat: '1.5',
        Reptile: '1.5'
    };
    const width = ( map[ group ] ? map[ group ] : '1' ) + 'em';
    return `width:${ width };`;
}
/* ----------------- INTERACTION ARROW -------------------------------------- */
function getInteractionTypeArrow( data ) {
    const isUniDirectional = data.interactionType !== 'Cohabitation';
    const icon = isUniDirectional ? arrowRight: arrowsAltH;
    return buildIcon( icon, '', data.interactionType, 'tree-arrow-icon' );
}
/* ================= SHOW INTERACTION-PAGE ICON ============================= */
export function getShowIcon ( entity, id, isQuarantined, tState = tblState ) {       /*dbug-log*///console.log('--getShowIcon [%O]', arguments);
    if ( isQuarantined ) { return getQuarantinedAsterik() }
    tblState = tState;
    const icon = getShowIconHtml( ucfirst( entity ) );
    return `<a href="${ getShowLink( entity, id ) }" target="_blank"
        rel="noopener noreferrer">${ icon }</a>`;
}
function getQuarantinedAsterik() {
    const style = "font-size:1.5em;font-weight:600;";
    const title = 'Data is pending review.';
    return `<span class="tree-icon" style="${ style }" title="${ title }">*</span>`;
}
function getShowIconHtml () {
    const title = "Show Interaction Details";
    const style = 'opacity:'+ ( tblState.flags.allDataAvailable ? 1 : 0 );
    return buildIcon( searchIcon, style, title );
}
function getShowLink( entity, id ) {
    const link =  `${ $( 'body' ).data( 'base-url' ) }${ entity }/${ id }`;   /*dbug-log*///console.log('--getShowLink [%s]', link);
    return link;
}
/* ====================== BUILD ICON ======================================== */
function buildIcon( src, style = '', title = '', clss = 'tree-show' ) {/*dbug-log*///console.log('-- builIcon src[%s] style[%s] title[%s]', src, style, title);
    const source = src ? `src="${ src }"` : '';
    return`<img ${ source } class="${ clss }" title="${ title }" alt="${ title }" style="${ style }">`;
}
