/**
 * On Explore page-load for a Contributors, their ReviewEntry records are synced
 * with any changes that have happened during the data-review process.
 *
 * Export
 *     syncReviewEntry
 *
 * TOC
 *     PROCESS REVIEW-ENTRY
 *     HANDLE QUARANTINED DATA
 */
import { getEntities } from '@localdata/util';
import * as _t from '@types';
import { getParsedReviewEntry, updateReviewEntryStorage } from '../util/util';
import { updateQuarantined } from './update-quarantined';
/* ===================== PROCESS REVIEW-ENTRY =============================== */
/**
 * On Explore page-load for a Contributors, their ReviewEntry records are synced
 * with any changes that have happened during the data-review process.
 */
export function syncReviewEntry ( data: _t.ReviewEntry ): Promise<void> {/*dbug-log*///console.log( '       +--syncReviewEntry data[%O]', data );
    return processReviewEntryRecord( getParsedReviewEntry( data ) );
}
function processReviewEntryRecord ( rEntry: _t.ReviewEntry ): Promise<void> {
    const ReviewEntries = getEntities<_t.ReviewEntry>( 'reviewEntry' );/*dbug-log*///console.log( '           --processReviewEntryRecord rEntry[%s][%O] qId?[%s] ReviewEntries[%O]', rEntry.stage.name, rEntry, rEntry.payload.coreId, ReviewEntries );
    updateReviewEntryStorage( rEntry, ReviewEntries, false );
    return handleStoreReviewEntry( rEntry, ReviewEntries );
}
/* ======================= HANDLE QUARANTINED DATA ========================== */
function handleStoreReviewEntry (
    rEntry: _t.ReviewEntry,
    ReviewEntries: _t.EntityRecords<_t.ReviewEntry>
): Promise<void> {
    const stage = getStageToHandle( rEntry );
    const handler = getHandler( stage );                            /*dbug-log*///console.log( '                  -- handleStoreReviewEntry stage[%s] handler?[%O]', stage, handler );
    return Promise.resolve( handler && handler( rEntry, ReviewEntries ) )
        .then();
}
function getStageToHandle( rEntry: _t.ReviewEntry ): _t.ReviewStage['passiveForm' | 'activeForm'] {
    return rEntry.stage.name === 'Locked'
        ? rEntry.form.stage.name
        : rEntry.stage.name;
}
type StageHandler = (
    data: _t.ReviewEntry,
    ReviewEntries: _t.EntityRecords<_t.ReviewEntry>,
) => Promise<void> | void;

/** Note: "Approved" has no handler because it is no longer quarantined. */
function getHandler( stage: _t.ReviewStage['passiveForm' | 'activeForm'] ): StageHandler | void{
    const handlers = {
        Pending: updateQuarantined,
        Returned: updateQuarantined,
        Rejected: onUpdateRemoveQuarantined
    } as const;
    return handlers[ stage as keyof typeof handlers ];
}
function onUpdateRemoveQuarantined (
    _1: _t.ReviewEntry,
    _3: _t.EntityRecords
) {
    //TODO2 -> build delete functionality and utilize that
}