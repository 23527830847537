/**
 * Handles exiting the root|sub forms and calling the related event-handlers. .
 *
 * Export
 *     handleFormClose
 *     exitRootForm
 *     exitFormElemAndClearState
 *
 * TOC
 *     ROOT-FORM
 *     SUB-FORM
 *     CLOSE HANDLERS
 *         EVENT CHAIN
 *         RAPID EXIT
 */
import { hidePagePopup } from '@elems';
import { cloneObj } from '@util';
import { reloadTableWithCurrentFilters, showTodaysUpdates } from '@explore/table';
import { loadReviewEntryStats } from '@explore/ui/panels/review';
import { components, state } from '@dataentry';
import { boldLeafFormHeader } from './init.js';
/**
 * @param  {string}  fLvl  Form-group string
 * @param  {boolean} param Flag used during form-group close
 * @param  {object}  [data]  Entity-data from the submitted sub-form
 * @param  {boolean} [skipClose]
 * @return {promise}       Form-close promise-chain
 */
export function handleFormClose ( fLvl, param, data, skipClose ) {  /*dbug-log*///console.log( '           --handleFormClose fLvl[%s] skipClose?[%O] param?[%O] data?[%O]', fLvl, skipClose, param, data && cloneObj( data ) );
    exitFormAndUpdateUi( fLvl, param, data );
    return Promise.resolve( !skipClose ? handleOnCloseChain( fLvl ) : null )
        .then( () => components.toggleWaitOverlay( false ) );
}
function exitFormAndUpdateUi( fLvl, param, data ) {
    if ( fLvl === 'top' ) { return exitRootForm( null, param === 'skipTableReset' ); }
    exitSubForm( fLvl, param, data );
}
/* ======================== ROOT-FORM ======================================= */
/**
 * Returns popup and overlay to their original/default state.
 * Note: called directly during form-cancel
 */
export function exitRootForm ( e, skipReset ) {                     /*dbug-log*///console.log( '               --exitRootForm skipReset?[%s]', skipReset );
    if ( !skipReset ) refocusTableIfFormWasSubmitted();
    hidePagePopup();
}
/**
 * If the form was not submitted the table does not reload. Otherwise, if exiting
 * the edit-forms, the table will reload with the current focus; or, after creating
 * an interaction, the table will refocus into source-view. Exiting the interaction
 * forms also sets the 'int-updated-at' filter to 'today'.
 */
function refocusTableIfFormWasSubmitted() {
    const fState = state.getFormState( 'top' );                      /*dbug-log*///console.log( '--refocusTableIfFormWasSubmitted fState[%O]', fState );
    if ( !fState?.submitted ) return;
    updateExplorePageAfterFormSubmit( fState );
}
function updateExplorePageAfterFormSubmit( fState ) {
    if ( fState.review ) loadReviewEntryStats(); //async
    if ( ifInteractionEntered( fState ) ) return refocusAndShowUpdates();
    reloadTableWithCurrentFilters();
}
function ifInteractionEntered( fState ) {
    return fState.name === 'Interaction' && fState.action !== 'delete';
}
function refocusAndShowUpdates() {                                  /*dbug-log*///console.log('refocusAndShowUpdates.')
    if ( state.getFormState( 'top', 'action' ) === 'create' ) {
        showTodaysUpdates( 'srcs' );
    } else {
        reloadTableWithCurrentFilters();
    }
}
/* ========================= SUB-FORM ======================================= */
/**
 * Removes the sub-form elements. Updates the parent-form: the field-combobox,
 * cancel and submit buttons, and field formatting during a data-review process.
 */
function exitSubForm( fLvl, focus, data ) {
    $( `#${ fLvl }-form` ).remove();                                /*dbug-log*///console.log("               --exitSubForm fLvl[%s] data?[%O]", fLvl, data);
    resetParentFormUi( fLvl );
    const fState = state.getFormState( fLvl );
    resetComboIfNeeded( fState, focus );
    if ( !data ) return; //form-canceled
    addSubFormCloseHandlers( data.coreEntity, fState );
}
function resetComboIfNeeded ( fState, focus ) {
    if ( fState.action === 'review' ) return; // handled elsewhere
    components.resetFormCombobox( fState.combo, !!focus );
}
function resetParentFormUi( fLvl ) {                                 /*dbug-log*///console.log("               --handleParentFormUi fLvl[%s]", fLvl);
    const pLvl = state.getFormLevel( 'parent', fLvl );
    boldLeafFormHeader( pLvl );
    handleParentCancelAndSubmitButtons( pLvl );
}
function handleParentCancelAndSubmitButtons( pLvl ) {
    components.ifFormValidClearAlertsAndEnableSubmit( pLvl );
    components.toggleCancelBttn( pLvl, true );
}
/* --------------------- ADD CLOSE HANDLERS --------------------------------- */
/** Note: order is important here. */
function addSubFormCloseHandlers( coreEntity, fState ) {                  /*dbug-log*///console.log("-- addSubFormCloseHandlers fLvl[%s] coreEntity[%O] fState[%O]", fState.group, coreEntity, fState);
    const entity = coreEntity || fState.misc?.duplicateEntity;        /*dbug-log*///console.log( '       -- entity?[%O]', entity );
    updateFieldValueOnFormClosed( entity, fState );
    updateComboOnFormClosed( entity, fState );
    updateParentFormUiOnFormClosed( fState.group );
}
/* ------------ FIELD VALUE ------------ */
function updateFieldValueOnFormClosed( entity, fState ) {          /*dbug-log*///console.log( "   -- updateFieldValueOnFormClosed entity[%O] fState[%O]", entity, fState );
    if ( fState.count || fState.name === 'Taxon' ) return; // Edge cases. Value stored in the combobox change event.
    const pLvl = state.getFormLevel( 'parent', fState.group );
    const setField = setEntityInParentField.bind( null, pLvl, fState.combo, entity );
    state.addFormHandler( fState.group, 'onFormClose', setField );
}
/** Sets field-state value. (The combobox change event is triggered later.) */
function setEntityInParentField( pLvl, combo, entity ) {
    state.setFieldValue( pLvl, combo, ( entity ? entity.id : null ) );
}
/* ------------ FIELD COMBO ------------ */
function updateComboOnFormClosed( entity, fState ) {             /*dbug-log*///console.log( "   -- updateComboOnFormClosed fLvl[%s] comboField[%s] entity[%O] fState[%O]", fState.group, fState.combo, entity, cloneObj( fState ) );
    const setEntity = components.addAndSelectEntity.bind( null, entity, fState.combo );
    state.addFormHandler( fState.group, 'onFormClose', setEntity );
}
/* ------------ FORM UI ------------ */
function updateParentFormUiOnFormClosed( fLvl ) {
    const pLvl = state.getFormLevel( 'parent', fLvl );
    const updateParentForm = handleFieldFormatDuringDataReview( pLvl );
    if ( !updateParentForm ) return; //Not a data-review form
    state.addFormHandler( fLvl, 'onFormClose', updateParentForm );
}
function handleFieldFormatDuringDataReview( pLvl ) {
    if ( state.getFormState( pLvl, 'action' ) !== 'review' ) return;
    components.reinitReviewFormatting( pLvl );
}
/* ======================== CLOSE HANDLERS ================================== */
/* --------------------------- STANDARD ------------------------------------- */
function handleOnCloseChain ( fLvl ) {                               /*dbug-log*///console.log('   -- handleOnCloseChain fLvl[%s]', fLvl)
    let afterClose;
    return Promise.resolve( state.getFormHandler( fLvl, 'beforeFormClose', true )() )
        .then( handleClose )
        .then( () => afterClose() )
        .then( () => handleParentForm( fLvl ) );

    function handleClose() {
        afterClose = state.getFormHandler( fLvl, 'afterFormClose', true );
        const onClose = state.getFormHandler( fLvl, 'onFormClose', true );
        state.removeFormGroupState( fLvl );                      /*dbug-log*///console.log('       -- handleClose handlers[%O]', onClose)
        return onClose();
    }
}
function handleParentForm( fLvl ) {
    return fLvl !== 'top' && components.ifParentFormValidEnableSubmit( fLvl );
}
/* ------------------------- RAPID EXIT ------------------------------------- */
/** Skips standard exit: removes form container and clears state. */
export function exitFormElemAndClearState( fLvl ) {
    if ( !$( `#${ fLvl }-form` ).length ) return; //FOrm-group not open
    $( `#${ fLvl }-form` ).remove();
    state.removeFormGroupState( fLvl );
    components.ifParentFormValidEnableSubmit( fLvl );
    components.toggleCancelBttn( state.getFormLevel( 'parent', fLvl ), true );
}