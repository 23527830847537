/**
 * Handles data-entry form map-elements.
 *
 * EXPORT
 *     addLocCountLegend
 *     addCountToLegend
 *     addNewLocBttn
 *
 * TOC
 *     LOCATION COUNT LEGEND
 *         ADD COUNT TO LEGEND
 *     CREATE NEW LOCATION BUTTON
 */
import { form } from '@dataentry';
import { getElem } from '@elems';
import { getMapState } from '@explore/map/manager.js';
/* ======================= LOCATION COUNT LEGEND ============================ */
export function addLocCountLegend () {
    const legend = L.control( { position: 'topright' } );
    legend.onAdd = addLocCountHtml;
    legend.addTo( getMapState( 'map' ) );
}
function addLocCountHtml () {
    return getElem( 'div', { id: 'cnt-legend', class: 'info legend flex-col' } );
}
/* ------------------- ADD COUNT TO LEGEND ---------------------------------- */
export function addCountToLegend ( ttlLocs, noGpsDataCnt, loc ) {
    const noGpsText = getNoGpsHtml( noGpsDataCnt );
    const gpsText = getGpsHtml( ttlLocs, loc );
    $( '#cnt-legend' ).html( gpsText + noGpsText );
}
function getNoGpsHtml( noGpsDataCnt ) {
    return noGpsDataCnt === 0 ? '' :
    `<span style="align-self: flex-end;">${ noGpsDataCnt } without GPS data</span>`;
}
function getGpsHtml( ttlLocs, loc ) {
    const plural = ttlLocs === 1 ? '' : 's';
    let name = getLocName( loc.displayName );
    return `<h3 title='${ loc.displayName }'>${ ttlLocs } location${ plural } in ${ name }</h3>`
}
function getLocName ( name ) {
    name = name.split( '[' )[ 0 ];
    return name.length < 22 ? name : name.substring( 0, 19 ) + '...';
}
/* ====================== CREATE NEW LOCATION BUTTON ======================== */
/*--- Create New Location Button ---*/
export function addNewLocBttn () {
    addNewLocControl();
    L.control
        .create( { position: 'topleft' } )
        .addTo( getMapState( 'map' ) );
}
function addNewLocControl () {
    L.Control.Create = L.Control.extend( {
        onAdd: map => {
            const bttn = createNewLocBttn();
            $( bttn ).on( 'click', form.createEntity.bind( null, 'location', null ) );
            return bttn;
        },
        onRemove: map => {}
    } );
    L.control.create = opts => new L.Control.Create( opts );
}
function createNewLocBttn () {
    const className = 'custom-icon leaflet-control-create',
        container = L.DomUtil.create( 'div', className ),
        button = L.DomUtil.create( 'input', className + '-icon', container );
    button.type = 'button';
    $( container ).attr( 'title', "Create New Location" ).append( button );
    return container;
}