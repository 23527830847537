/**
 * Location table-build methods.
 *
 * Export
 *     buildLocTable
 *     onLocViewChange
 *     rebuildLocTable
 *
 * TOC
 *     LOCATION TABLE
 *     LOCATION VIEW
 */
import { map, table, ui } from '@explore';
import { getSelVal, setSelVal, updateComboChangeEvent } from '@elems/combo';
import { setData } from '@localdata';
import * as build from '..';
/** =============== LOCATION TABLE ========================================== */
export function buildLocTable ( v ) {                                  /*dbug-log*///console.log( "       --Building Location Table. View ? [%s]", v );
    const view = v || 'tree';
    updateComboChangeEvent( 'View', onLocViewChange );
    return table.initTableData( [ 'location', 'topRegionNames' ] )
        .then( beginLocationLoad );

    function beginLocationLoad() {
        ui.initLocViewOpts( view );
        return updateLocView( view );
    }
}
/* --------------------------- BUILD CHAIN ---------------------------------- */
/**
 * Rebuilds loc tree with passed location, or the default top regions, as the
 * base node(s) of the new tree with all sub-locations nested beneath @buildLocTree.
 * Resets 'openRows' and clears tree. Continues @buildLocTableTree.
 * Note: This is also the entry point for filter-related table rebuilds.
 */
export function rebuildLocTable ( topLoc ) {                            /*dbug-log*///console.log( "       --rebuilding loc tree. topLoc = %O", topLoc );
    const topLocs = topLoc || getTopRegionIds();
    table.resetCurTreeStorageProps();
    table.setState( { openRows: topLocs.length === 1 ? topLocs : [] } );
    ui.fadeTable();
    return startLocTableBuildChain( topLocs );
}
function getTopRegionIds() {
    const ids = [];
    const regions = table.getState( 'data' ).topRegionNames;
    for ( let name in regions ) {ids.push( regions[ name ] );}
    return ids;
}
function startLocTableBuildChain ( topLocs ) {
    const tS = table.getState();
    return build.buildLocTree( topLocs, tS.data.rcrds )
        .then( tree => build.buildLocRowData( tree, tS ) )
        .then( rowData => table.getRowDataForCurrentFilters( rowData ) )
        .then( rowData => build.initTable( 'Location Tree', rowData, tS ) )
        .then( () => table.loadLocFilters( tS ) );
}

/** Reloads the data-table with the location selected from the map view. */
export function showLocInDataTable ( loc ) {                           /*dbug-log*///console.log( "       --Showing Location in Table" );
    ui.updateUiForTableView();
    setSelVal( 'View', 'tree', 'silent' );
    rebuildLocTable( [ loc.id ] )
        .then( () => ui.updateFilterStatusMsg() )
        .then( () => ui.enableClearFiltersButton() );
}
/** ================ LOCATION VIEW ========================================== */
export function onLocViewChange ( val ) {
    if ( !val ) return;
    updateLocView( val );
}
/**
 * Event fired when the source view select box has been changed.
 */
function updateLocView ( v ) {
    const val = v || getSelVal( 'View' );                  /*dbug-log*///console.log( '           --updateLocView. view = [%s]', val );
    resetLocUi( val );
    table.resetTableState();
    table.setTreeToggleData( false );
    return showLocInteractionData( val );
}
function resetLocUi ( view ) {
    ui.fadeTable();
    if ( view === 'tree' ) { ui.updateUiForTableView(); }
}
function showLocInteractionData ( view ) {                                         //console.log('showLocInteractionData. view = ', view);
    setData( 'curView', view );
    return view === 'tree' ? rebuildLocTable() : map.showAllLocsOnMap();
}