/**
 * Handles building and removing the dynamically added author|editor combos.
 * When an author is selected, a new author combobox is initialized underneath
 * the last author combobox, unless the last is empty.
 *
 * Export
 *     buildNewAuthorSelect
 *     removeAuthField
 *
 * TOC
 *     BUILD FIELD
 *         APPEND AND FINISH
 *     REMOVE FIELD
 */
/* UTILS */
import { ComboboxConfig, destroySelectizeInstance, initCombobox, removeOptions } from '@elems/combo';
import * as _t from '@types';
import { cloneObj, promiseToTaskEither, taskEitherToPromise } from '@util';
/* DATA-ENTRY */
import { components, model, state } from '@dataentry';
import { initCreateForm, onAuthAndEdSelection } from '@controller/source/author';
/* FP-TS */
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
/* ====================== BUILD FIELD ======================================= */
type AFieldParams = { fLvl: model.FormGroup, type: _t.AuthorType, ord: number };

/** Handles building and removing the dynamically added author|editor combobox fields. */
export function buildNewAuthorSelect(
    fLvl: model.FormGroup,
    type: _t.AuthorType,
    ord: number
) {                                                                 /*dbug-log*///console.log( '+--buildNewAuthorSelect[%s][%s]', type, ord );
    const a: AFieldParams = { fLvl, type, ord };
    return pipe(
        a,
        updateFieldState,
        getNextFieldModel,
        components.buildDynamicFormField,
        promiseToTaskEither,
        TE.map( field => appendNewAuthSelect( a, field ) ),
        taskEitherToPromise
    );
}
/** Builds the next author|editor field-model. */
function getNextFieldModel( a: AFieldParams ): model.MultiFieldModel {
    const fieldModel = state.getFieldState( a.fLvl, a.type );
    fieldModel.count = a.ord;
    fieldModel.required = false;
    return fieldModel;
}
function updateFieldState( a: AFieldParams ): AFieldParams {        /*dbug-log*///console.log( '--updateFieldState [%s][%s][%s]', a.fLvl, a.type, a.ord );
    state.setFieldState( a.fLvl, a.type, { count: a.ord } );
    $( `#${ a.type }_f-cntnr` ).data( 'cnt', a.ord ); //Used in testing
    return a;
}
/* ----------------------- APPEND AND FINISH -------------------------------- */
/**
 * When an author|editor is selected, a new author combobox is initialized
 * underneath the last author|editor combobox.
 */
function appendNewAuthSelect( a: AFieldParams, field: HTMLElement ) {/*dbug-log*///console.log( '--appendNewAuthSelect aType[%s] field[%O]', a.type, field );
    $( `#${ a.type }_f-cntnr .cntnr` ).append( field );
    const confg = getAuthSelConfig( a );
    initCombobox( confg );
    removeSelectedOptions( a, confg.confgName! );
}
function getAuthSelConfig( a: AFieldParams ): ComboboxConfig {
    return {
        create: initCreateForm.bind( null, a.ord, a.type ),
        onChange: onAuthAndEdSelection.bind( null, a.ord, a.type ),
        id: '#sel-'+a.type+a.ord,
        confgName: a.type+a.ord,
        name: a.type
    };
}
/** Removes already selected options from the new combobox. */
function removeSelectedOptions( a: AFieldParams, fName: string ) {
    const vals = state.getFieldValue( a.fLvl, a.type );             /*dbug-log*///console.log( '--removeSelectedOptions field[%s] vals[%O]', fName, vals );
    if ( hasNoOptionsToRemove( a, vals ) ) return;
    removeOptions( fName, Object.values( vals ) );
}
// Todo: Remove newly selected from existing combos as well...
function hasNoOptionsToRemove( a: AFieldParams, vals: model.MultiFieldModel['value']  ) {
    return state.isEditForm( a.fLvl ) || a.ord == 1 || Object.keys( vals ).length >= a.ord;
}
/* ======================= REMOVE FIELD ===================================== */
export function removeAuthField(
    fLvl: model.FormGroup,
    type: _t.AuthorType,
    ord: number
): void {                                                           /*dbug-log*///console.log( '+--removeAuthField[%s][%s]', type, ord );
    const fieldName = type + ord;
    destroySelectizeInstance( fieldName );
    window.setTimeout( () => $( `#${ fieldName }_f` ).remove(), 250 );
    updateFieldState( { fLvl, type, ord: --ord } );
}