/**
 * Returns the entity-form-model with the current layout (all|simple).
 * { *: default model-properties
 *    core: EntityName (ucfirst)
 *    *data: { *edit: [dataProp, ..., create: [dataProp, ..., ] }
 *    *display: 'simple' | all, //Defaults to 'simple' display, if defined.
 *    *fields: {
 *         //CORE.FIELDS AND TYPE.FIELDS WILL BE MERGED IN.
 *        FieldName: { //DisplayName
 *            class: "",
 *            entity: "" //Server classname for the field data
 *            combo: "", //Combobox field-key ("true" before combo init)
 *            id: '' //set using 'name' otherwise
 *            info: {
 *                intro: "",
 *                *tooltip: ""(req) },
 *            label: Field label text (Name-prop used if absent)
 *            *name: FieldName,  [REQUIRED]
 *            prep: { //prep data for server
 *                dataClasses: {
 *                    core: "" (When not the entity/classname)
 *                    detail: "" (When not the field-name) }
 *                funcNameString: [arguments]
 *            prop: { core||detail: serverPropString } //used to set edit data
 *            required: true, //Set if true
 *            *type: "", null if field-data auto-derived [REQUIRED]
 *        }, ...
 *    },
 *    *group: top|sub|sub2, //SET DURING CONFG BUILD
 *    misc: {
 *        entityProp: value
 *    },
 *    *name: formName (entity or su|object) ucfirst
 *    onInvalidInput: Fired when an input fails HTML validation  //TODO
 *    onValidInput: Fired after invalid input validates (perhaps merge with all checkReqFieldsAndToggleSubmitBttn calls?)  //TODO
 *    prep: [], //server-data handled before form-submit
 *    *style: '', //CSS style classes: [lrg|med|sml]-form
 *    type: Type name, once selected. Only for entities with subTypes
 *    types: { //ENTITY SUB-TYPES
 *         Type name: {
 *              name: (req)
 *              [confg prop with type-data]
 *         }
 *    },
 *    layout: [] //layouts[display] model.
 * }
 *
 * TOC
 *     GET BASE-MODEL
 *     INIT BASE-MODEL
 *         MERGE INIT-PARAMS
 *         FORM ACTIONS
 */
import { cloneObj } from '@util';
import { model as m } from '@dataentry';
import { mergeCoreConfigIntoFormModel, mergeTypeConfigIntoFormModel } from './merge-models.js';
import { prepareLayoutModel } from './prepare-layout.js';

export function initFormModel( initParams ) {                       /*dbug-log*///console.log( '   --initFormModel initParams[%O]', initParams );
    const iAction = getConfigAction( initParams.action, initParams.review );
    const base = getConfigModel( iAction, initParams.group, initParams.name, initParams.type );
    if ( base.records ) base.records = base.records[ iAction ];
    const model = mergeInitParams( base, initParams );
    handleLayoutInit( model );
    return model
}
/** Note: Edit-form models are used to review deletes.  */
export function getConfigAction( pAction, rEntry ) {                  /*dbug-log*///console.log( '   --getConfigAction pAction[%s] rEntry[%O]', pAction, rEntry );
    const formAction = rEntry?.form.action || pAction;
    return formAction === 'delete' ? 'edit' : formAction;
}
function handleLayoutInit( model ) {
    model.layout = prepareLayoutModel( model );                    /*dbug-log*///console.log( '   --model after init[%O]', cloneObj( model ) );
    delete model.layouts;
}
/* ======================== GET BASE-MODEL ================================== */
export function getBaseMergeModel( FS ) {
    const iAction = getConfigAction( FS.action, FS.review );
    return getConfigModel( iAction, FS.group, FS.name, FS.type );
}
export function getConfigModel( iAction, group, entity, type ) {   /*dbug-log*///console.log( '   --getConfigModel iAction[%s] entity[%s], type?[%s]', iAction, entity,  type );
    const model = m.getEntityFormConfig( entity );                    /*dbug-log*///console.log( '          --config[%O]', cloneObj( model ) );
    if ( model.core ) mergeCoreConfigIntoFormModel( model );
    if ( model.types ) mergeTypeConfigIntoFormModel( model, type, iAction );/*dbug-log*///console.log( '   --getConfigModel [%s] = [%O]', model.name, cloneObj( model ) );
    initFieldStateData( model.fields, group );
    return model;
}
/** Flags fields for server preprocessing. */
function initFieldStateData( modelFields, group ) {
    Object.values( modelFields ).forEach( setFieldData );

    function setFieldData( field ) {
        field.active = true;
        field.group = group;
    }
}
/* ======================= MERGE INIT-PARAMS ================================ */
function mergeInitParams( model, initParams ) {                     /*dbug-log*///console.log( '-- mergeInitParams model[%O] initParams[%O]', cloneObj( model ), initParams );
    setInitDisplayKey( initParams.action, model );
    groupFormHandlers( model, initParams );
    handleFormActionInit( model, initParams );
    deleteEmptyConfigProps( initParams );
    return { ...initParams, ...model };
}
function setInitDisplayKey( action, model ) {
    const isSimple = action === 'create' && model.layouts.simple;
    model.display = isSimple ? 'simple' : 'all';
}
function deleteEmptyConfigProps( model ) {
    if ( !model.records ) delete model.records;
}
/* ------------------------ EVENT HANDLERS ---------------------------------- */
/** Adds the param init-methods to an event-handlers object. */
function groupFormHandlers ( model, initParams ) {
    const funcs = {};
    for ( let key in initParams ) sortOutHandlers( key );
    model.handlers = funcs;                                         /*dbug-log*///console.log( '-- groupFormHandlers initParams[%O] handlers[%O]', cloneObj( initParams ), funcs );

    function sortOutHandlers ( key ) {
        if ( typeof initParams[ key ] !== 'function' ) return;      /*dbug-log*///console.log( '-- sortOutHandlers handler[%O]', initParams[ key ] );
        funcs[ key ] = initParams[ key ];
        delete initParams[ key ];
    }
}
/* ------------------------- FORM ACTIONS ----------------------------------- */
function handleFormActionInit( model, initParams ) {
    if ( initParams.id ) modifyForEdit( model, initParams );
    if ( initParams.review ) modifyForReview( model, initParams.review );
}
function modifyForEdit( model, initParams ) {
    model.editing = { core: initParams.id, detail: null };
    delete initParams.id;
}
function modifyForReview ( model, rEntry ) {                         /*dbug-log*///console.log( '-- modifyForReview model[%O]', cloneObj( model ), rEntry );
    model.action = 'review';
    if ( model.records ) model.records.push( 'reviewEntry', 'reviewStages' );
    if ( rEntry.form.action !== 'edit' ) return;
    model.editing = {  //Necessary to handle detail entity id.
        core: rEntry.payload.coreId,
        detail: rEntry.payload.detailId || null
    };
}
