/**
 * Manager: a review-actions combo is added next to the submit button.
 * Contributor: Submit-button text is updated to be more descriptive.
 *
 * Export
 *     handleReviewSubmitElemsForRole
 *
 * TOC
 *     BUILD CHAIN
 *         DATA-MANAGER
 *         DATA-CONTRIBUTOR
 *     COMBOBOX
 *         INIT
 *         ON CHANGE
 *     VALIDATE
 *         HAS SUB REVIEW-ENTRY
 *         ALERT SUB REVIEW-ENTRY
 */
/* UTILS */
import { getElem, getOptsFromStoredData, getSelTxt, initCombobox } from '@elems';
import { cloneObj, isManagerUser } from '@util';
/* DATA-ENTRY */
import { components, state } from '@dataentry';
/* ===================== BUILD CHAINS ======================================= */
export function handleReviewSubmitElemsForRole ( rEntry ) {  /*dbug-log*///console.log( "   --handleReviewSubmitElemsForRole rEntry[%O]", rEntry );
    const initSubmit = isManagerUser() ? initManagerCombo : handleContributorSubmit;
    return Promise.resolve( initSubmit( rEntry ) );
}
/* --------------------- DATA-MANAGER --------------------------------------- */
function initManagerCombo ( rEntry ) {
    return getOptsFromStoredData( 'activeStageNames' )
        .then( opts => buildElemAndInitCombo( opts, rEntry.inst.group ) );
}
/* --------------------- DATA-CONTRIBUTOR ----------------------------------- */
/** Note: This is the only method called during contributor data-review. */
function handleContributorSubmit ( rEntry ) {                       /*dbug-log*///console.log( "   --updateSubmitText rEntry[%O]", cloneObj( rEntry ) );
    const stage = rEntry.form.stage.name === 'Return' ? 'Quarantine' : 'Complete';
    updateSubmitText( rEntry, stage );
    updateFormReviewEntry( rEntry, stage );
    components.ifFormValidClearAlertsAndEnableSubmit( rEntry.inst.group );
}
function updateSubmitText( rEntry, stage ) {
    const text = stage === 'Quarantine' ? 'Submit' : 'Confirm';
    $( `#${ rEntry.inst.group }-submit` )[ 0 ].value = text;
}
function updateFormReviewEntry( rEntry, stage ) {
    rEntry.inst.rvwAction = { name: stage };
    state.setFormState( rEntry.inst.group, { review: rEntry } );
}
/* ======================== COMBOBOX ======================================== */
/** Builds a combobox with the review-actions and appends next to the submit button. */
function buildElemAndInitCombo ( opts, fLvl ) {                     /*dbug-log*///console.log( "   --buildElemAndInitCombo opts[%O] fLvl[%s]", opts, fLvl );
    const el = buildActionsSelect( opts, fLvl );
    appendReviewActionsSelect( el, fLvl );
    initRvwActionsCombo( fLvl );
}
function buildActionsSelect ( opts, fLvl ) {                        /*dbug-log*///console.log( '--buildActionsSelect opts[%O] fLvl[%s]', opts, fLvl );
    const attr = { class: 'rvw-action-cmbx', id: `sel-${ fLvl }_rvw-actions` };
    return getElem( 'select', attr, opts );
}
function appendReviewActionsSelect ( el, fLvl ) {
    $( `#${ fLvl }-submit` ).before( el );
}
/* --------------------- INIT ----------------------------------------------- */
function initRvwActionsCombo ( fLvl ) {                             /*dbug-log*///console.log( ' initRvwActionsCombo [%s]', fLvl );
    const confg = {
        onChange: onReviewActionSelect.bind( null, fLvl ),
        id: `#sel-${ fLvl }_rvw-actions`,
        confgName: fLvl + 'Action',
        name: 'Action'
    };
    initCombobox( confg );
}
/* --------------------- ON CHANGE ------------------------------------------ */
function onReviewActionSelect ( fLvl, val ) {
    const action = getFormReviewAction( fLvl, val );                /*dbug-log*///console.log( ' -- onReviewActionSelect [%s] id[%s] action[%O]', fLvl, val, action );
    const isValidAction = action ? checkIfActionValid( fLvl, action ) : false;
    if ( isValidAction ) setFormReviewAction( fLvl, action );
    components.toggleSubmitBttn( fLvl, isValidAction );
}
function getFormReviewAction ( fLvl, val ) {
    return !val ? false : {
        id: val,
        name: getSelTxt( `${ fLvl }Action` )
    };
}
function setFormReviewAction ( fLvl, action ) {
    const rEntry = state.getFormState( fLvl, 'review' );            /*dbug-log*///console.log( ' -- setFormReviewAction rEntry[%O] action[%O]', rEntry, action )
    rEntry.inst.rvwAction = action;
    state.setFormState( fLvl, { review: rEntry } );
}
/* ===================== VALIDATE =========================================== */
function checkIfActionValid ( fLvl, action ) {
    const map = {
        Approve: checkIfAllFieldDataValid,
    };
    if ( map[ action.name ] ) return map[ action.name ]( fLvl );
    toggleSubDataAlert( fLvl, false );
    return true;
}
/* ------------------- HAS SUB REVIEW-ENTRY --------------------------------- */
function checkIfAllFieldDataValid ( fLvl ) {
    if ( !isAllFieldDataApproved( fLvl ) ) return toggleSubDataAlert( fLvl );
    return components.areRequiredFieldsFilled( fLvl );
}
function isAllFieldDataApproved ( fLvl ) {
    const fields = state.getFormState( fLvl, 'fields' );            /*dbug-log*///console.log( "   --isAllFieldDataApproved [%s][%O]", fLvl, fields );
    return Object.values( fields ).every( isFieldDataApproved );

    function isFieldDataApproved ( field ) {                         /*dbug-log*///console.log( "       --isFieldDataApproved field [%s][%O]", field.name, field );
        return !field.active || !state.isValueQuarantined( field.review );
    }
}
/* ------------------- ALERT SUB REVIEW-ENTRY ------------------------------- */
function toggleSubDataAlert ( fLvl, show = true ) {
    const alertId = 'temp-sub-data-alert';
    if ( show ) return alertInvalidSubmitState( fLvl, alertId );
    $( `#${ alertId }` ).fadeOut( 'slow', () => $( `#${ alertId }` ).remove() );
}
function alertInvalidSubmitState ( fLvl, alertId ) {
    const sClass = 'warn-msg warn';
    const txt = 'All sub-data must be fully approved.';
    const el = `<p id="${ alertId }" class="${ sClass }">${ txt }</p>`;
    $( `#sel-${ fLvl }_rvw-actions` ).before( el );
    return false;
}