/**
 * When an author is selected, a new author combobox is initialized underneath
 * the last author combobox, unless the last is empty. The total count of
 * authors is added to the new id.
 *
 * Export
 *     onAuthAndEdSelection
 *     rmvExtraMultiFields
 *
 * TOC
 *     ON AUTHOR|EDITOR SELECTION
 *         UPDATE STATE
 *         UPDATE UI
 */
/* UTIL */
import { getSelVal } from '@elems/combo/';
import * as _t from '@types';
import { isNotNumber } from '@util';
/* DATA-ENTRY */
import { components, model, state } from '@dataentry';
import { buildNewAuthorSelect, initCreateForm } from '@controller/source/author';
import { handleBlanks } from './val-order';
import { toggleOtherAuthorType } from './toggle-other-type';
import { onAuthFieldClear } from './on-clear-field';

export type ASelectParams = {
    fLvl: model.FormGroup,
    type: _t.AuthorType,
    ord: number,
    ttl: number,
    v: string
};
/**
 * When an author|editor is selected, a new combobox added, unless the last is
 * already empty. When cleared, the author|editor selections are validated to
 * ensure there are no blanks in the order.
 *
 * @param  {num} ord      Order count Bound to input on init
 * @param  {str} type     Author|Editor
 * @param  {num} v        ID of selected entity
 */
export function onAuthAndEdSelection(
    ord: number,
    type: _t.AuthorType,
    v: string
): Promise<void> | void {                                           /*dbug-log*///console.log( '+--onAuthAndEdSelection [%s][%s] = [%s]', ord, type, v );
    const a = getAuthSelectParams( ord, type, v );
    return v === 'create'
        ? initCreateForm( a.ord, a.type )
        : ( v === '' || isNotNumber( v ) )
            ? onAuthFieldClear( a )
            : handleAuthorSelected( a );
}
function getAuthSelectParams(
    ord: number,
    type: _t.AuthorType,
    v: string
): ASelectParams {
    const fLvl = state.getSubFormLvl( 'sub' );
    const ttl = state.getFieldState( fLvl, type, 'count' );         /*dbug-log*///console.log( '       --ttl[%s]', ttl );
    return { fLvl, ttl, ord, type, v };
}
/* ======================= ON AUTHOR|EDITOR SELECTION ======================= */
function handleAuthorSelected( a: ASelectParams ): void | Promise<void> {                                           /*dbug-log*///console.log( '       --handleAuthorSelected [%s][%s] = [%s] (fLvl[%s] ttl[%s])', a.ord, a.type, a.v, a.fLvl, a.ttl );
    if ( a.ttl === 1 ) toggleOtherAuthorType( a.fLvl, a.type, false );
    onSelectionUpdateState( a.fLvl, a.type, a.ord, a.v );
    return onSelectionUpdateUi( a.fLvl, a.type, a.ttl )
}
/* ---------------------- UPDATE STATE -------------------------------------- */
function onSelectionUpdateState(
    fLvl: model.FormGroup,
    type: _t.AuthorType,
    ord: number,
    v: string
): void {
    components.storeMultiSelectValue( fLvl, ord, type, v );
}
/* ------------------------ UPDATE UI --------------------------------------- */
function onSelectionUpdateUi(
    fLvl: model.FormGroup,
    type: _t.AuthorType,
    ttl: number
): void | Promise<void> {
    handleBlanks( fLvl, type );
    if ( ifNoNewSelectNeeded( fLvl, type, ttl ) ) return
    return buildNewAuthorSelect( fLvl, type, ttl+1 );
}
function ifNoNewSelectNeeded(
    fLvl: model.FormGroup,
    type: _t.AuthorType,
    ttl: number
): boolean {
    return isDynamicFieldEmpty( type, ttl ) || isDisplayForm( fLvl );
}
export function isDynamicFieldEmpty (
    type: _t.AuthorType,
    ord: number
): boolean {                                                        /*dbug-log*///console.log( 'isDynamicFieldEmpty [%s][%s]', type, ord );
    return !getSelVal( type + ord );
}
function isDisplayForm( fLvl: model.FormGroup ): boolean {          /*dbug-log*///console.log( 'isDisplayForm [%s]', fLvl );
    // removed for review forms... not sure why this was here...
    // const pLvl = state.getFormLevel( 'parent', fLvl );
    return state.isDisplayFormOnly( fLvl );
}