/**
 * Builds a tree of the Location data to display in the agGrid table format.
 *
 * Export
 *     buildLocRowData
 */
import { isManagerUser } from "@util";
import { getIntRowData } from '..';

export function buildLocRowData( tree, tblState ) {
    const finalRowData = [];
    for ( let topNode in tree ) {
        finalRowData.push( getLocRowData( tree[ topNode ], 0, tblState ) );
    }
    return removeLocsWithoutInteractions( finalRowData );
}
/** Returns a row data object for the passed location and it's children.  */
function getLocRowData( rcrd, treeLvl, tblState ) {                /*dbug-log*///console.log("--getLocRowData called for %s = %O, tblState = %O", rcrd.displayName, rcrd, tblState);
    return {
        id: rcrd.id,
        entity: "Location",
        name: getLocDisplayName(),  /* Interaction rows have no name to display. */
        locHasGeoData: !!rcrd.geoJsonId,
        isParent: rcrd.interactionType === undefined,  /* Only interaction records return false. */
        isQuarantined: rcrd.review?.stage === 'Pending',
        open: tblState.openRows.indexOf( rcrd.id ) !== -1,
        children: getLocRowDataForRowChildren( rcrd, treeLvl, tblState ),
        treeLvl: treeLvl,
        interactions: rcrd.interactions.length > 0,     /* Location objects have collections of interactions as children. */
        locGroupedInts: hasGroupedInteractionsRow( rcrd ),
        type: rcrd.locationType.displayName,
        reviewedAt: rcrd.reviewedAt ? '' : 'U',
        updatedBy: rcrd.updatedBy,
    };
    function getLocDisplayName() {
        const trans = { Unspecified: 'Unspecified / Habitat Only' };
        return trans[ rcrd.displayName ] || rcrd.displayName;
    }
}
/* ===================== PARENT AND CHILD LOCATIONS ========================= */
/**
 * Returns rowData for interactions at this location and for any children.
 * If there are both interactions and children, the interactions rows are
 * grouped under the first child row as "Unspecified [locName] Interactions",
 * otherwise interaction rows are added directly beneath the taxon.
 */
function getLocRowDataForRowChildren( rcrd, pTreeLvl, tblState ) {       /*dbug-log*///console.log("getLocRowDataForChildren called. rcrd = %O", rcrd)
    const rowData = isParentLocation( rcrd )
        ? buildRowsForLocAndChildren( rcrd, pTreeLvl, tblState )
        : getIntRowData( rcrd.interactions, pTreeLvl );

    return rowData.filter( r => r );
}
function isParentLocation( rcrd ) {
    const locType = rcrd.locationType.displayName;
    return locType === "Region" || locType === "Country"
}
function buildRowsForLocAndChildren( rcrd, pTreeLvl, tblState ) {
    return [ getUnspecified( rcrd, pTreeLvl ), ...getChildLocRows( rcrd, pTreeLvl, tblState ) ];
}
function getChildLocRows( rcrd, pTreeLvl, tblState ) {
    return rcrd.children.map( getChildLocData ).filter( r => r );

    function getChildLocData( childLoc ) {
        if ( childLoc.locationType.displayName === 'Habitat' ) return;
        return getLocRowData( childLoc, pTreeLvl + 1, tblState );
    }
}
/* ------------------------ UNSPECIFIED INTERACTIONS ------------------------ */
/**
 * Groups interactions attributed directly to a location with child-locations
 * and adds them as it's first child row.
 */
function getUnspecified( rcrd, pTreeLvl ) {
    if ( rcrd.failedFltr ) return;
    const locName = rcrd.displayName === "Unspecified" ?
        "Location" : rcrd.displayName;
    const rowData = {
        id: rcrd.id,
        entity: "Location",
        name: 'Unspecified ' + locName + ' Interactions',
        isParent: true,
        open: false,
        children: getUnspecifiedInts( rcrd, pTreeLvl ),
        interactions: rcrd.interactions.length > 0,
        treeLvl: pTreeLvl,
        groupedInts: true,
        type: rcrd.locationType.displayName,
    };
    if ( !rowData.children.length ) return;
    return rowData;
}
function getUnspecifiedInts( rcrd, pTreeLvl ) {
    const locs = rcrd.children;
    const ints = rcrd.interactions;
    const ids = locs.map( getHabInts ).reduce( concatInts, ints );
    return getIntRowData( ids, pTreeLvl )

    function concatInts( all, habInts ) {
        return all.concat( habInts );
    }
}
function getHabInts( loc ) {
    if ( loc.locationType.displayName !== 'Habitat' ) { return []; }
    return loc.interactions;
}
function hasGroupedInteractionsRow( rcrd ) {
    return rcrd.children.length > 0 && rcrd.interactions.length > 0;
}
/* ================== REMOVE LOCS WITHOUT INTERACTIONS ====================== */
/** Filters out all locations with no interactions below them in the tree. */
function removeLocsWithoutInteractions( rows ) {
    if ( isManagerUser() ) { return rows; } //All locations shown
    return rows.filter( function( row ){
        if ( row.children ) {
            row.children = removeLocsWithoutInteractions( row.children );
        }
        return row.interactions || hasChildInteractions( row );
    } );
}
function hasChildInteractions( row ) {
    if ( !row.children ) { return true; }
    return row.children.some( function( childRow ) {
        return childRow.interactions || hasChildInteractions( childRow );
    } );
}