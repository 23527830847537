/**
 * Builds a form to select a taxon with a combobox for the Group and Group-Root,
 * when selecting the taxon group and/or Group-Root is available, and one for each
 * rank present in the taxon group, (eg: Bat - Family, Genus, and Species), filled
 * with the taxa at that rank in the group. When one is selected, the remaining
 * boxes are repopulated with related taxa and the 'select' button is enabled.
 * A 'Select Unspecified' button allows selection of a (sub)group's root taxon.
 * @since Refactored 11/2020
 *
 * TOC
 *     INIT
 *         GROUP IDS
 *             GROUP
 *             GROUP-ROOT
 *         COMBOS
 *     SELECTED
 */
import { components, form, state } from '@dataentry';
import * as fields from '../field';
import { initTaxonSelectForm } from './build-form.js';
/* ========================= INIT =========================================== */
/**
 * //todo2
 * @param  {string} field    Field to open select-form for (ie, Parent, Subject, Object)
 * @param  {string} gId      Group id
 * @param  {string} rId      Group-Root id
 * @param  {function} onSubmit Custom onSubmit handler
 * @param  {string} fLvl     todo: When is this anything other than sub?
 * @return {promise}
 */
export function initFieldTaxonSelect( field, gId, rId, onSubmit, fLvl = 'sub' ) {/*dbug-log*///console.log( '--initFieldTaxonSelect field[%s] gId?[%s rId?[%s] onSubmit?[%O]', field, gId, rId, onSubmit );
    const ids = getInitGroupIds( field, gId, rId );
    return initTaxonSelectForm( field, ...ids, onSubmit, fLvl )
        .then( () => fields.ifParentSelectRemoveSpecies( field ) );
}
/* ----------------------- GROUP-IDS ---------------------------------------- */
function getInitGroupIds( field, gId, rId ) {
    const prevTxn = getPreviousTxn( field );                          /*dbug-log*///console.log( '    -- getInitGroupIds prevTxn?[%O]', prevTxn )
    const group = gId || getInitGroupId( field, prevTxn );
    const root = rId || getInitRootId( prevTxn );
    return [ group, root ];
}
function getPreviousTxn( field ) {
    const id = $( '#sel-'+field ).data( 'selTaxon' );
    return id ? state.getRecords( 'taxon', id ) : false;
}
/* _____________________ GROUP ______________________________________________ */
function getInitGroupId( field, prevTxn ) {
    return prevTxn ? prevTxn.group.id : getGroupDefault( field );
}
function getGroupDefault( field ) {
    return field === 'Subject' ? getGroupId( 'Bat' ) : getObjectDefault();
}
function getGroupId( type ) {
    const groups = state.getRecords( 'group' );                     /*dbug-log*///console.log( '    -- getGroupId type[%s] groups[%O]', type, groups );
    return Object.keys( groups ).find( g => groups[ g ].displayName === type );
}
/**
 * Object defaults to plants when the subject is a bat, and to bats otherwise.
 * @return {int}    Default Object taxon-group ID
 */
function getObjectDefault() {
    const groupRoot = state.getFieldState( 'top', 'Subject', 'misc' );
    const batGroup = getGroupId( 'Bat' );                             /*dbug-log*///console.log( '    -- getObjectDefault groupRoot?[%O] batGroup[%s]', groupRoot, batGroup );
    return !groupRoot || groupRoot.id == batGroup ? getGroupId( 'Plant' ) : batGroup;
}
/* _____________________ GROUP-ROOT _________________________________________ */
function getInitRootId( prevTxn ) {
    return prevTxn ? prevTxn.group.root.id : null;
}
/* ------------------------ COMBOS ------------------------------------------ */
export function initSelectFormCombos( fLvl = 'sub' ) {
    const events = getSelectComboEvents();
    components.initFormCombos( fLvl, events );
}
function getSelectComboEvents() {
    return {
        Class: { onChange: onRankChange( 'Class' ), create: create.bind( null, 'Class' ) },
        Family: { onChange: onRankChange( 'Family' ), create: create.bind( null, 'Family' ) },
        Genus: { onChange: onRankChange( 'Genus' ), create: create.bind( null, 'Genus' ) },
        Order: { onChange: onRankChange( 'Order' ), create: create.bind( null, 'Order' ) },
        Group: { onChange: fields.onGroupSelection },
        'Group-Root': { onChange: fields.onRootSelection },
        Species: { onChange: onRankChange( 'Species' ), create: create.bind( null, 'Species' ) },
    };
}
function onRankChange( rank ) {
    return fields.onRankSelection.bind( null, rank );
}
function create( rank, val ) {
    return form.createEntityType( rank, 'taxon', val );
}
/* ======================== SELECTED ======================================== */
/** Finds the most specific rank with a selection and returns that taxon record. */
export function getSelectedTaxon( aboveRank ) {
    let cmbxs = $( '#sub-form .selectized' ).toArray();
    if ( !cmbxs[ 0 ].id.includes( 'Species' ) ) { cmbxs = cmbxs.reverse(); }/*dbug-log*///console.log( "--getSelectedTaxon above [%s]. cmbxs[%O]", aboveRank, cmbxs );
    const selected = cmbxs.find( el => isSelectedTaxon( aboveRank, el ) );
    const id = !selected ? getRoot() : $( selected ).val();           /*dbug-log*///console.log( "     --selected[%s][%O]", !!selected, state.getRecords( 'taxon', id ) );
    return state.getRecords( 'taxon', id );
}
/** Note: On combo reset, the most specific taxon above the resetRank is selected. */
function isSelectedTaxon( resetRank, elem ) {                         /*dbug-log*///console.log( '--isSelectedTaxon above?[%s] [%s][%s]', resetRank, $( elem )[ 0 ].id, $( elem ).val() )
    if ( !ifIsRankComboElem( elem ) ) { return false; }
    if ( resetRank && isRankChildOfResetRank( resetRank, elem ) ) { return false; }
    return $( elem ).val();
}
function isRankChildOfResetRank( resetRank, elem ) {
    const rank = elem.id.split( 'sel-' )[ 1 ];                          /*dbug-log*///console.log( '--rank[%O]', rank );
    const fLvl = rank === 'Parent' ? 'sub' : state.getSubFormLvl( 'sub' );
    const allRanks = state.getFieldState( fLvl, 'Group-Root', 'misc' ).rcrd.subRanks;
    const isChild = allRanks.indexOf( rank ) <= allRanks.indexOf( resetRank );/*dbug-log*///console.log( '     is [%s] sub-rank to [%s]? [%s]', rank, resetRank, isChild );
    return isChild;
}
function ifIsRankComboElem( elem ) {
    return elem.id.includes( 'sel' ) && !elem.id.includes( 'Group' );
 }
function getRoot() {
    const rootRcrd = state.getFieldState( 'sub', 'Group-Root', 'misc' ).rcrd;/*dbug-log*///console.log( '     getRoot rootRcrd[%O]', rootRcrd );
    return rootRcrd.taxon;
}