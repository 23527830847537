/**
 * Returns the next valid ReviewEntry prepared for the data-review process.
 *
 * Export
 *     clearSkippedRecordsMemory
 *     getPendingRootReviewEntry
 *     getNextSubReviewEntry
 *     isSkipped
 *
 * TOC
 *     FIND ROOT REVIEW-ENTRY
 *     FIND SUB REVIEW-ENTRY
 *     PREPARE NEXT RECORD
 */
// import { cloneObj } from '@util';
import { getData, syncReviewEntries } from '@localdata';
import { state, submit } from '@dataentry';
import getFirstFieldWithReviewEntry from './get-sub-review.js';
import prepareRecordForReview from './sync-record.js';
/**
 * Data used within module to identify and prepare ReviewEntry for review.
 * {array}   skipped    List of ReviewEntry IDs processed or skipped in review session
 * {object}  user       Current user data object (Only set during data-review)
 */
let d = {
    skipped: [],
    user: null
};
export function clearSkippedRecordsMemory() {                       /*dbug-log*///console.log( 'clearSkippedRecordsMemory' );
    d.skipped = [];
}
export function isSkipped( pId ) {                                  /*dbug-log*///console.log( '   -- isSkipped id[%s] skipped[%O]', pId, cloneObj( d.skipped ) );
    return d.skipped.indexOf( pId ) !== -1;
}
/* ================= FIND ROOT REVIEW-ENTRY SET ============================= */
/**
 * Returns the first valid ReviewEntry for a root-record.
 * @return {promise}  ReviewEntry
 */
export function getPendingRootReviewEntry( skipId ) {               /*dbug-log*///console.log( ' +--getPendingRootReviewEntry skipId?[%s] skipped?[%O]', skipId, d.skipped );
    if ( skipId ) d.skipped.push( skipId );
    getData( 'user' ).then( user => d.user = user );
    return syncReviewEntries( [ 'availableReview', 'reviewEntry' ] )
        .then( data => getFirstDataSetToReview( data.availableReview, data.reviewEntry ) );
}
function getFirstDataSetToReview( available, ReviewEntries ) {      /*dbug-log*///console.log( ' --getFirstDataSetToReview available[%O] ReviewEntries[%O]', available, ReviewEntries );
    const rEntry = getFirstValidDataSet( available );
    if ( !rEntry ) return false;
    submit.pushReviewEntry( rEntry.id, 'Lock' );
    return prepareRecordForReview( ReviewEntries, rEntry, 'top' );
}
function getFirstValidDataSet( available ) {
    return Object.values( available ).find( ifValidMainDataSet );
}
function ifValidMainDataSet( rEntry ) {
    const isMain = rEntry.form.group === 'top';
    const isReady = state.isReadyToReview( rEntry, getUser() )
    return !isSkipped( rEntry.id ) && isMain && isReady;
}
function getUser() {
    if ( !d.user ) { d.user = state.getRecords( 'user' ); }
    return d.user;
}
/* ===================== FIND SUB REVIEW-ENTRY ============================== */
/**
 * Called both during form-init to open the first branch of ReviewEntry out to leaf,
 * and after form submit to either continue up branch or to look for next branch.
 * @param  {string}  prevLvl   Previously opened/submitted form-group
 * @param  {string}  skipId    ID to skip for remainder of current review session
 * @param  {boolean} leafOnly  During form-init when opening to leaf data-set
 * @return {promise}
 */
export function getNextSubReviewEntry( prevLvl, skipId, leafOnly = false ) {
    if ( skipId ) d.skipped.push( skipId );                         /*dbug-log*///console.log( '  +-- getNextSubReviewEntry prevLvl[%s] leafOnly?[%s]', prevLvl, leafOnly );
    const ReviewEntries = state.getRecords( 'reviewEntry' );
    return getSubReviewEntry( prevLvl, leafOnly, ReviewEntries );
}
function getSubReviewEntry( fLvl, leafOnly, ReviewEntries ) {       /*dbug-log*///console.log( '       -- getSubReviewEntry fLvl[%s] leafOnly?[%s] ReviewEntries[%O]', fLvl, leafOnly, ReviewEntries );
    const reviewField = getFirstFieldWithReviewEntry( fLvl, ReviewEntries );
    if ( !reviewField ) return searchParentForm( fLvl, leafOnly, ReviewEntries );
    const subLvl = state.getFormLevel( 'child', fLvl );
    return prepareNextRecord( reviewField, subLvl, ReviewEntries );
}
/** Check the next form level for valid ReviewEntry. */
function searchParentForm( fLvl, leafOnly, ReviewEntries ) {        /*dbug-log*///console.log( '           -- searchParentForm fLvl[%s] leafOnly?[%s] ReviewEntries[%O]', fLvl, leafOnly, ReviewEntries );
    if ( fLvl === 'top' || leafOnly ) return false;
    const nextLvl = state.getFormLevel( 'parent', fLvl );
    return getSubReviewEntry( nextLvl, leafOnly, ReviewEntries );
}
/* ===================== PREPARE NEXT RECORD ================================ */
function prepareNextRecord( f, fLvl, ReviewEntries ) {              /*dbug-log*///console.log( '               -- prepareNextRecord fLvl[%s] field[%s][%O]', fLvl, f.name, cloneObj( f ) );
    const rEntry = prepareRecordForReview( ReviewEntries, f.record, fLvl, f.name )
    if ( f.entity === 'Contributor' ) prepMultiFieldReviewEntry( f, rEntry );
    state.replaceFieldState( f.group, f );
    return rEntry;
}
function prepMultiFieldReviewEntry( f, rEntry ) {
    if ( !f.review ) return; // Replaced review is next
    f.review[ f.ord ] = rEntry;
    state.setFieldState( f.group, f.name, { review: f.review } );
}