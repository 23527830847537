/**
 * Handles showing the locations displayed in the data-table on the map with the
 * marker summaries focused on the location data.
 *
 * EXPORT
 *     showAllLocsOnMap
 *     showLocOnMap
 *
 * TOC
 *     SHOW ALL LOCATIONS
 *     SHOW SINGLE LOCATION
 *         ADD LOCATION POPUP
 */
import { setSelVal } from '@elems';
/* EXPLORE */
import { addPolygonToMap, addPopupToState, getMapRecords, getMapState, setMapView } from '@explore/map/manager.js';
import * as mapUtil from '@explore/map/util';
import { initExploreMapView } from '../on-init.js';
import { addInteractionMarkersToMap } from './add-ints.js';
/* =================== SHOW ALL LOCATIONS ================================== */
/** Initializes the google map in the data table. */
export function showAllLocsOnMap () {
    initExploreMapView( addInteractionMarkersToMap );
}
/* ======================== SHOW SINGLE LOCATION ============================ */
/** Switches to map view and centeres map on selected location. */
export function showLocOnMap ( locId ) {                            /*dbug-log*///console.log( "       +--Showing Location on Map locId[%s]", locId );
    if ( $( '#shw-map' ).prop( 'loading' ) ) return; // Prevents multiple clicks
    updateExplorePageForMapView();
    initExploreMapView( () => showLocInMap( locId ) );
}
function updateExplorePageForMapView () {
    setSelVal( 'View', 'map', 'silent' );
    $( '#filter-status' ).html( 'No Active Filters.' );
}
function showLocInMap ( id ) {
    const loc = getMapRecords( 'location', id );
    const latlng = mapUtil.getCenterCoords( loc, loc.geoJsonId );
    if ( !latlng ) return noGeoDataErr( id );
    if ( loc.children.length ) addLocPolygonToMap( loc );
    addLocationPopupSummary( loc, latlng );
    addInteractionMarkersToMap( loc );
}
function addLocPolygonToMap ( loc ) {
    const { polygon, type } = mapUtil.getGeoJsonPolygon( loc );
    if ( type.includes( 'Polygon' ) ) addPolygonToMap( polygon );
}
function noGeoDataErr ( id ) {
    console.error( '###### No geoJson found for geoJson [%s] ###########', id );
}
/* -------------------- ADD LOCATION POPUP ---------------------------------- */
function addLocationPopupSummary ( loc, latlng ) {                  /*dbug-log*///console.log( '              --zoomToLocAndShowPopup loc[%O] latlng[%O]', loc, latlng );
    const popup = getLocationSummaryPopup( loc, latlng );
    getMapState( 'map' ).openPopup( popup );
    handleMapZoom( latlng, loc.locationType.displayName );
}
function getLocationSummaryPopup( loc, latlng ) {
    const popup = getPopup( loc, latlng );
    popup.setContent( mapUtil.getLocationSummaryHtml( loc, getMapRecords() ) );
    popup.options.autoClose = false;
    return popup;
}
function getPopup( loc, latlng ) {
    const existing = getMapState( 'popups' )?.[ loc.displayName ];
    return existing || buildLocPopup( loc, latlng );
}
function buildLocPopup ( loc, latlng ) {
    const popup = L.popup().setLatLng( latlng ).setContent( '' );
    addPopupToState( loc, popup );
    return popup;
}
function handleMapZoom( latlng, locType ) {
    const zoomLvl = locType === 'Region' ? 4 : locType === 'Country' ? 5 : 7;
    setMapView( latlng, zoomLvl );
}