/**
 * Initiates and returns the data-entry form's core-components.
 */
import { components } from '@dataentry';
import { cancelForm, getExitButton, getFormHelpElems } from './button';
import { getFormFooter } from './footer';

export function getFormPieces( fState ) {
    const elems = {
        footer: getFormFooter( fState.name, fState.group, fState.action ),
        tutBttn: getFormHelpElems( fState.group, fState.fields ),
        exitBttn: getFormExitButton( fState.group )
    };
    return components.getFormRows( fState.name, fState.group )
        .then( addRowsAndReturnPieces );

    function addRowsAndReturnPieces( rows ) {
        elems.rows = rows;
        return elems;
    }
}
function getFormExitButton( fLvl ) {
    return fLvl == 'top' && getExitButton( cancelForm.bind( null, 'top' ) );
}