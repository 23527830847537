/**
 * Adds core elements to the map, including the map tiles, geocoder, and tips.
 *
 * EXPORT
 *     addMapElems
 *
 * TOC
 *     TILES
 *     TIPS LEGEND
 *     INIT GEOCODER
 */
import { getElem } from '@elems';
import { setMapState } from '@explore/map/manager.js'
import { onGeocode } from '@explore/map/util'

export function addMapElems ( map, mapId ) {
    addMapTiles( map, mapId );
    addTipsLegend( map );
    addGeoCoderToMap( map, mapId );
    L.control.scale( { position: 'bottomright' } ).addTo( map );
}
/* =========================== TILES ======================================== */
function addMapTiles ( map, mapId ) {
    const tileLayer = getTileLayer( mapId );
    tileLayer.addTo( map );
}
function getTileLayer( mapId ) {
    return L.tileLayer( 'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
        attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        minZoom: mapId === 'form-map' ? 1 : 3, //Don't zoom out passed
        maxZoom: 16,
        tileSize: 512,
        id: 'mapbox/satellite-v9',
        zoomOffset: -1,
        accessToken: 'pk.eyJ1IjoieXF1ZXNlcmFzYXJhaCIsImEiOiJja2ZteWVxMXAxazZ3MnNvMGluZ283aWp3In0.X0eZqTze66E9PI5y8FBmmg',
        // accessToken: 'pk.eyJ1IjoiYmF0cGxhbnQiLCJhIjoiY2poNmw5ZGVsMDAxZzJ4cnpxY3V0bGprYSJ9.pbszY5VsvzGjHeNMx0Jokw'
    } )
}
/* ======================== TIPS LEGEND ===================================== */
/** A Map Tips legend in the bottom left of the map. Tips toggle open on click. */
function addTipsLegend ( map ) {
    const legend = L.control( { position: 'bottomleft' } );
    legend.onAdd = addViewTips;
    legend.addTo( map );
}
function addViewTips () {
    const attr = { id: 'tips-legend', class: 'info legend flex-col' };
    const div = getElem( 'div', attr );
    div.innerHTML = getDefaultTipTxt();
    $( div ).on( 'click', toggleTips );
    return div;
}
function getDefaultTipTxt () {
    return `<b>- (Click to Expand Map Tips) -</b>`;
}
function setExpandedTipText () {
    $( '#tips-legend' ).html( `
        <b><center>- (Click to Collapse Map Tips) -</center>
        - Click on a marker to keep its popup open.<br>
        - Hover over truncated(...) text to show full text.` );
    $( '#tips-legend' ).data( 'expanded', true );
}
function setDefaultTipText () {
    $( '#tips-legend' ).html( getDefaultTipTxt() );
    $( '#tips-legend' ).data( 'expanded', false );
}
function toggleTips () {
    return $( '#tips-legend' ).data( 'expanded' ) ?
        setDefaultTipText() : setExpandedTipText();
}
/* ======================== INIT GEOCODER ==================================== */
/**
 * Initialize the geocoder and add it to the map. The geocoder is used to search
 * for locations by name or coordinates. On geocode, a polygon is drawn on the map.
 */
 function addGeoCoderToMap ( map, mapId ) {
    addGeocoderToMap( map, mapId );
    $( '.leaflet-control-geocoder' ).attr( 'title', `Search by name or coordinates` );
}
function addGeocoderToMap ( map, mapId ) {
    L.Control.geocoder( getGeocoderOptions() )
        .on( 'markgeocode', ( r ) => onGeocode( mapId, r ) )
        .addTo( map );
}
function getGeocoderOptions () {
    return {
        defaultMarkGeocode: false,
        position: 'topleft',
        geocoder: initGeocoder()
    };
}
function initGeocoder() {
    const geocoder = L.Control.Geocoder.nominatim();
    setMapState( 'geoCoder', geocoder );
    return geocoder;
}