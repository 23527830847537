/** ,
 * TOC
 *     ROW
 *     BLOCK
 *     FIELD
 */
import { components } from '@dataentry';
import { getElem } from '@elems';
import { cloneObj } from '@util';
/**
 * Returns rows for the entity form fields. If the form is a source-type,
 * the type-entity form config is used.
 */
/** @return {ary} Rows for each field in the entity field obj. */
// eslint-disable-next-line require-await
export async function getFormFieldRows( layout ) {                  /*dbug-log*///console.log( "+--getFormFieldRows [%O]", layout );
    return layout.reduce( async ( accP, f ) => {
        const acc = await accP;
        const row = await buildFormRow( f );                        /*dbug-log*///console.log( 'row [%O]', row );
        return [ ...acc, row ];
    }, Promise.resolve( [] ) );
}
/* ========================== ROW =========================================== */
async function buildFormRow( config ) {
    const cntnr = buildRowContainer( config );
    const fieldBlocks = await getFieldBlocks( config );
    return appendAndReturnContainer( cntnr, fieldBlocks );
}
function buildRowContainer( config ) {
    const cntnr = getElem( 'div', { class: 'row' } );
    $( cntnr ).data( 'field-cnt', config.length ); // Used for styling
    return cntnr;
}
function appendAndReturnContainer( cntnr, elems ) {
    $( cntnr ).append( ...elems );
    return cntnr;
}
/* ========================== BLOCK ========================================= */
function getFieldBlocks( config ) {                                 /*dbug-log*///console.log( 'getFieldBlocks config[%O]', config );
    return Promise.all( config.map( getFieldBlock ) )
}
function getFieldBlock( g ) {
    return g.dir ? buildBlock( g ) : getFormField( g );
}
async function buildBlock( config ) {
    const cntnr = getBlockContainer( config );
    const block = await getBlockFields( config );
    return appendAndReturnContainer( cntnr, block );
}
function getBlockContainer( config ) {
    const blockClasses = `flex-${ config.dir } g-cntnr ${ config.class || '' }`;
    return getElem( 'div', { class: blockClasses } );
}
function getBlockFields( config ) {
    return Promise.all( config.configs.map( getFieldBlock ) )
        .then( blocks => blocks.filter( b => b ) );
}
/* ========================== FIELD ========================================= */
function getFormField( config ) {                                   /*dbug-log*///console.log( 'getFormField [%s][%O]', config.name, config );
    if ( config.shown === false ) return;
    return config.emptyField
        ? getElem( 'div', { class: 'empty' } )
        : components.buildFormField( config );
}