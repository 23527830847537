/**
 * Shows a sub-form to 'Select <Field>' of the interaction with a combobox for
 * the taxon group, group root (if group has multiple taxon roots), and one for
 * each rank present in the group, (eg: Bat - Family, Genus, and Species), filled
 * with the taxa at that rank. When one is selected, the remaining boxes
 * are repopulated with related taxa and the 'select' button is enabled. A 'Select
 * Unspecified' button allows selection of a (sub)group's root taxon.
 *
 * Export
 *     initTaxonSelectForm
 *     finishRankComboInit
 *
 * TOC
 *     INIT SELECT-FORM
 *     UPDATE FORM ELEMS
 *         CUSTOMIZE FORM-TEXT
 *         ADD SELECT-UNSPECIFIED BUTTON
 *     HANDLE INIT SELECTION
 *         FOCUS FIRST RANK-COMBO
 *         SET CURRENT TAXON-FIELD SELECTION
 *     EXIT SELECT-FORM
 *         RESET FIELD-COMBO
 *         RESET SELECT-FORM
 */
import { alert, components, form, state } from '@dataentry';
import * as el from '@elems';
import { populateAllRankCombos } from '../field'
import { initFieldTaxonSelect, initSelectFormCombos } from './init-main.js';
import { fillGroupAndRankCombos } from '../field/group-fields';
/* ======================= INIT SELECT-FORM ================================= */
/**
 * Taxon select-form init.
 * @param  {string} field     Form-field name to select a taxon for (Object, Parent, Subject)
 * @param  {string} gId       Taxon group ID
 * @param  {string} rId       Taxon Group-Root ID
 * @param  {function} onSubmit  Alternative submit method: validate when editing Parent taxon (except during review)
 * @return {promise}
 */
export function initTaxonSelectForm ( field, gId, rId, onSubmit ) {/*temp-log*///console.log( '       +--init[%s]Select (selected ? [%s])', field, $( `#sel-${ field }` ).val() );
    $( '#sel-' + field ).data( 'loading', true );
    const params = getTxnSelectParams( field, gId, rId, onSubmit );
    return components.initSubForm( params )
        .then( status => finishTaxonSelectBuild( status, field ) );
}
function getTxnSelectParams ( field, gId, rId, onSubmit ) {        /*dbug-log*///console.log( '--getTxnSelectParams field[%s] gId?[%s sId?[%s] onSubmit?[%O]', field, gId, rId, onSubmit );
    return {
        action: 'select',
        appendForm: form => $( `#${ field }_f` ).append( form ),
        cancel: resetTaxonSelectForm.bind( null, field, gId, rId ),
        combo: field,
        group: 'sub',
        initCombos: initSelectFormCombos,
        name: field,
        onFormClose: exitSelectForm.bind( null, field ),
        style: 'sml-sub-form',
        submit: onSubmit || form.selectFieldTaxon,
        vals: { Group: gId, 'Group-Root': rId }
    };
}
/**
 * Customizes the taxon-select form ui. Either re-sets the existing taxon selection
 * or brings the first rank-combo into focus. Clears [field]'s combobox.
 */
function finishTaxonSelectBuild ( status, field ) {
    if ( !status ) return $( '#sel-' + field ).data( 'loading', false ); //Error handled elsewhere
    updateFormElems( field );                                       /*dbug-log*///console.log( '   +--finishTaxonSelectBuild. field[%s] selId?[%s]', field, $( '#sel-' + field ).val() )
    return fillGroupAndRankCombos( field );
}
/**
 * Reset will trigger selecting a previously selected taxon. Skip reset allows for
 * the group and Group-Root to be set without triggering further selection.
 * @param {*} field
 * @param {*} skipReset
 * @returns
 */
export function finishRankComboInit( field, skipReset ) {        /*dbug-log*///console.log( "--finishRankComboInit field[%s] skipReset?[%s]", field, skipReset );
    return populateAllRankCombos()
        .then( () => selectInitTaxonOrFocusFirstCombo( field, skipReset ) );
}
/* ===================== UPDATE FORM ELEMS ================================== */
function updateFormElems ( field ) {
    indentFieldContainer( field );
    clearFieldCombobox( field );
    updateSelectFormText( field );
    addSelectRootTaxonBttn( field );
    addSelectFormExitButton( field );
}
/** Allows for the Group combos to extend above the rank combos. */
function indentFieldContainer( field ) {
    $( `#${ field }_fields` ).css( 'margin-left', '1rem' );
}
function clearFieldCombobox ( field ) {                             /*dbug-log*///console.log( '--clearFieldCombobox [%O]', field )
    el.replaceSelOpts( field, [] );
    $( '#sel-' + field ).data( 'loading', false );
}
/* --------------------- CUSTOMIZE FORM-TEXT -------------------------------- */
/** Adds a close button. Updates the Header and the submit/cancel buttons. */
function updateSelectFormText ( field ) {
    $( '#sub-hdr span' )[ 0 ].innerHTML = `Select ${ field } Taxon`;
    $( '#sub-submit' )[ 0 ].value = "Select Taxon";
    $( '#sub-cancel' )[ 0 ].value = "Reset";
}
/* -------------------- ADD SELECT-UNSPECIFIED BUTTON ----------------------- */
/** Adds a button that selects the group's root-taxon. */
function addSelectRootTaxonBttn ( field ) {
    const bttn = buildSelectUnspecifedBttn( field );
    $( '#sub-form .bttn-cntnr' ).prepend( bttn );
}
function buildSelectUnspecifedBttn ( field ) {
    const bttn = el.getElem( 'input', getUnspecifiedBttnAttrs() );
    $( bttn ).on( 'click', form.selectFieldTaxon.bind( null, null, 'root' ) );
    $( bttn ).data( 'field', field );
    return bttn;
}
function getUnspecifiedBttnAttrs () {
    return {
        id: 'select-group',
        type: 'button',
        value: 'Select Unspecified'
    };
}
/* =================== HANDLE INIT SELECTION ================================ */
/**
 * Restores a previously selected taxon on initial load, or when reseting the select
 * form. When the select form loads without a previous selection or when the group
 * is changed by the user, the first combobox of the group is brought into focus.
 */
function selectInitTaxonOrFocusFirstCombo ( field, skipReset ) {
    const selId = !skipReset && getPrevSelId( field );              /*dbug-log*///console.log( "--selectInitTaxonOrFocusFirstCombo field[%s] id?[%s] skipReset?[%s]", field, selId, skipReset );
    if ( selId ) {
        resetPrevTaxonSelection( selId, field );
    } else { focusFirstRankCombo(); }
}
function getPrevSelId ( field ) {
    return $( '#sel-' + field ).data( 'selTaxon' );
    // return $( '#sel-' + field ).val() || $( '#sel-' + field ).data( 'reset' ) ?
    //     $( '#sel-' + field ).data( 'selTaxon' ) : null;
}
/* ----------------- FOCUS FIRST RANK-COMBO --------------------------------- */
function focusFirstRankCombo () {
    const ranks = state.getFieldState( 'sub', 'Group-Root', 'misc' ).rcrd.subRanks;
    const rank = ranks.slice().pop();
    el.focusCombobox( rank );
}
/* ----------------- SET CURRENT TAXON-FIELD SELECTION ---------------------- */
/** Resets the taxon to the one previously selected in the interaction form.  */
function resetPrevTaxonSelection ( id, field ) {
    const taxon = state.getRecords( 'taxon', id );
    if ( taxon.isRoot ) return;                                 /*dbug-log*///console.log( '           --resetPrevTaxonSelection [%s] [%s] = %O', field, id, taxon );
    selectPrevTaxon( taxon, field );
}
function selectPrevTaxon ( taxon, field ) {                         /*dbug-log*///console.log( '  -- selectPrevTaxon field[%s] txn[%O]', field, taxon );
    if ( ifDifferent( 'Group', taxon.group.id ) ) return setGroup( taxon.group.id );
    el.setSelVal( taxon.rank.displayName, taxon.id );
    window.setTimeout( () => { deleteResetFlag( field ); }, 1000 );
}
function ifDifferent ( field, id ) {
    return $( '#sel-' + field ).val() != id;
}
function setGroup ( id ) {
    el.setSelVal( 'Group', id );
}
function deleteResetFlag ( field ) {
    $( '#sel-' + field ).removeData( 'reset' );
}
/* =================== EXIT SELECT-FORM ===================================== */
/**
 * Exits select-form completely. The cancel button, typically used for sub-form
 * exits is repurposed elsewhere to allow select-form reset.
 * @param  {string} field Taxon-field the select-form is for.
 */
function addSelectFormExitButton ( field ) {
    const bttn = components.getExitButton( exitTaxonSelectForm.bind( null, field ) );
    $( '#sub-hdr span+div' ).append( bttn );
}
/** Exits sub form and restores any previous taxon selection. */
function exitTaxonSelectForm ( field ) {
    components.handleFormClose( 'sub', false );
    alert.clearAlert( $( `#${ field }_alert` )[ 0 ], 'top' );
    handleComboReset( field, $( '#sel-' + field ).data( 'selTaxon' ) );
}
/* -------------------- RESET FIELD-COMBO ----------------------------------- */
/** Resets previous field value in combobox. */
function handleComboReset ( field, prevTaxonId ) {
    if ( !prevTaxonId ) return;
    resetTaxonCombobox( field, prevTaxonId );
}
function exitSelectForm ( field ) {
    return form.enableTaxonFieldCombos( field );
}
function resetTaxonCombobox ( field, prevTaxonId ) {
    const opt = { text: getTaxonym( prevTaxonId ), value: prevTaxonId };
    el.replaceSelOpts( field, opt );
    el.setSelVal( field, prevTaxonId, 'silent' );
}
function getTaxonym ( id ) {
    return state.getRecords( 'taxon', id ).displayName;
}
/* -------------------- RESET SELECT-FORM ----------------------------------- */
/**
 * Re-initializes the taxon select-form to the field-taxon previously selected or
 * to the default taxon-group for the field. Repurposes the sub-form cancel button.
 */
function resetTaxonSelectForm ( field, gId, rId ) {                /*dbug-log*///console.log( 'resetTaxonSelectForm field[%s] gId[%s] rId[%s]', field, gId, rId );
    $( '#sel-' + field ).data( 'reset', true );
    components.exitFormElemAndClearState( 'sub' );
    initFieldTaxonSelect( field, gId, rId );
}