/**
 * Handles toggling the Filter and List Panels. When both are open, the List panel
 * is on the left and the Filter panel is on the right. When only one is open,
 * they span the width of the options area.
 *
 * Export
 *     closeOpenPanels
 *     togglePanel
 *
 * TOC
 *     OPEN/CLOSE PANELS
 *         Open Panel(s)
 *         Close Panel(s)
 *     UTIL
 */
import * as panels from '..';
import { getPanelConfigs } from './panel-confgs';

const configs = getPanelConfigs();
/* ==================== OPEN/CLOSE PANELS =================================== */
export function closeOpenPanels () {
    const opened = getOpenPanels();
    opened.forEach( key => togglePanel( key, 'close' ) );
}
export function togglePanel ( key, state ) {                                       //console.log('togglePanel [%s] [%s]', key, state);
    const panel = configs[ key ];
    if ( state === 'open' ) { openPanel( panel ); }
    else { closePanel( panel ); }
}
/* -------------------------- Open Panel(s) --------------------------------- */
function openPanel ( panel ) {                                                     //console.log('openPanel = %O', panel);
    const opened = getOpenPanels();
    if ( !opened.length ) { return cssOpenPanel( panel ); }
    if ( isCompatiblePanelOpened( opened, panel ) ) {
        openVerticalPanels( panel );
    } else {
        closeOpenedPanelThenOpenNewPanel( opened, panel );
    }
}
function cssOpenPanel ( panel ) {
    $( panel.bttn ).addClass( 'panel-open-toggle' );
    $( panel.id ).removeClass( 'closed' );
    $( panel.tab ).addClass( 'shw-col-borders ' + panel.tabClass );
    window.setTimeout( () => $( panel.id ).css( 'overflow-y', 'visible' ), 1000 );
}
function isCompatiblePanelOpened ( opened, panel ) {
    return panel.key === 'review' ?
        false : opened.every( k => [ 'filter', 'lists' ].indexOf( k ) >= 0 );
}
function openVerticalPanels ( panel ) {
    $( '#fltr-int-pnl-cntnr' ).attr( 'class', 'flex-row' );
    $( '#filter-pnl, #list-pnl' ).removeClass( 'flex-row' ).addClass( 'flex-col' );
    cssOpenPanel( panel );
    panels.toggleListPanelOrientation( 'vert' );
    panels.toggleFilterPanelOrientation( 'vert' );
}
function closeOpenedPanelThenOpenNewPanel ( opened, panel ) {                      //console.log('closeOpenedPanelThenOpenNewPanel. toClose = %O, newPanel = %O', opened, panel)
    opened.forEach( key => closePanel( configs[ key ] ) );
    window.setTimeout( () => cssOpenPanel( panel ), 500 );
}
/* ------------------------ Close Panel(s) ---------------------------------- */
function closePanel ( panel ) {
    const opened = getOpenPanels();
    if ( opened.length === 1 ) {
        cssClosePanel( panel );
    } else { closeVerticalPanel( panel ); }
    panel.onClose();
}
function cssClosePanel ( panel ) {
    $( panel.id ).css( 'overflow-y', 'hidden' );
    $( panel.bttn ).removeClass( 'panel-open-toggle' );
    $( panel.tab ).removeClass( 'shw-col-borders ' + panel.tabClass );
    $( panel.id ).addClass( 'closed' );
    window.setTimeout( () => $( panel.id ).css( 'overflow-y', 'hidden' ), 500 );  //Handles potential overlap with opening and closing rapidly due to rapid clicking.
}
function closeVerticalPanel ( panel ) {
    cssClosePanel( panel );
    window.setTimeout( () => {
        panels.toggleFilterPanelOrientation( 'horz', panel.id.includes( 'filter' ) );
        panels.toggleListPanelOrientation( 'horz' );
        $( '#fltr-int-pnl-cntnr' ).attr( 'class', 'flex-col' );
        $( '#filter-pnl, #list-pnl' ).removeClass( 'flex-col' ).addClass( 'flex-row' );
    }, 500 );
}
/* =========================== UTIL ========================================= */
function getOpenPanels () {
    return Object.keys( configs ).filter( key => isPanelOpen( configs[ key ] ) );
}
function isPanelOpen ( config ) {
    return $( config.id ).length && !$( config.id ).hasClass( 'closed' );
}