/**
 * Data review panel will allow editors to view the data they've edited, for
 * admin-editors to view all edited data and to send edits back to the editors
 * when needed.
 *
 * Export
 *     initReviewPanel
 *     initDataInReviewPanel
 *
 * TOC
 *     INIT
 *         REVIEW PANEL
 *     COMBOBOXES
 *         EDITOR NAME
 *         REVIEW STATUS
 *         DATE-RANGE
 */
import * as el from '@elems';
import { getData } from '@localdata';
import { components } from '@dataentry';
import * as util from '../util';
const app = { cal: false };
/* ============================ INIT ======================================== */
export function initReviewPanel ( userRole ) {                      /*dbug-log*///console.log( 'initReviewPanel role[%s]', userRole );
    if ( [ 'visitor', 'user' ].indexOf( userRole ) !== -1 ) return;
    handleReviewPanelInit( userRole );
    util.initReviewPanelUtil();
    $( '#rvw-data' ).on( 'click', updateDataAndTogglePanel );
}
function updateDataAndTogglePanel () {
    util.loadReviewEntryStats(); //async
    initNamesCombobox(); //async
    util.toggleReviewPanel();
}
function handleReviewPanelInit ( userRole ) {                   /*dbug-log*///console.log('-- handleReviewPanelInit userRole[%s]', userRole)
    initComboboxes();
    $( '#rvw-cntrl-bttn' ).off( 'click' ).on( 'click', components.initDataReviewWizard.bind( null, null ) );
    disableFilterButton();
}
function disableFilterButton () {
    $( '#rvw-fltr-bttn' ).attr( { disabled: 'disabled' } ).fadeTo( 'fast', '.3' );
}
/* ------------------------ REVIEW DATA ------------------------------------- */
export function initDataInReviewPanel ( userRole ) {
    if ( [ 'visitor', 'user' ].indexOf( userRole ) !== -1 ) return;
    waitForReviewDataThenLoadStats();
}
function waitForReviewDataThenLoadStats() {
    window.setTimeout( getReviewDataThenLoad, 1000 );
}
function getReviewDataThenLoad() {
    getData( 'review', true )
    .then( data => data ? util.loadReviewEntryStats() : waitForReviewDataThenLoadStats() );
}
/* ============================ COMBOBOXES ================================== */
function initComboboxes () {
    /** TODO: UPDATE TO WORK WITH CONTRIBUTORS AS WELL. */
    initNamesCombobox();
    initDateRangeCalendar();
    initStatusCombobox();
}
/* ---------------------------- EDITOR NAME --------------------------------- */
function initNamesCombobox () {
    el.getOptsFromStoredData( 'rvwContribNames', true )
        .then( loadNamesCombobox );
}
function loadNamesCombobox ( opts ) {                                  /*dbug-log*///console.log('--loadNamesCombobox opts[%O]', opts)
    const confg = {
        id: '#sel-rvw-contrib',
        name: 'Data Contributor',
        onChange: Function.prototype,
        placeholder: 'Select Editor (ALL BY DEFAULT)'
    };
    if ( opts.length ) {
        confg.maxItems = null;
        confg.options = opts;
    }                                                               /*dbug-log*///console.log('   -- confg[%O]', confg)
    el.initCombobox( confg );
    el.enableCombobox( confg.name, false );
}
/* ------------------------ REVIEW STATUS ---------------------------------- */
function initStatusCombobox () {
    el.getOptsFromStoredData( 'rvwStages' )
        .then( loadStatusCombobox );
}
function loadStatusCombobox ( opts ) {
    const confg = {
        id: '#sel-rvw-status',
        maxItems: null,
        name: 'Status',
        onChange: Function.prototype,
        options: opts,
        placeholder: 'Select Status (DEFAULT: PENDING)'
    };                                                              /*dbug-log*///console.log('initStatusCombobox confg[%O]', confg);
    el.initCombobox( confg );
    el.enableCombobox( confg.name, false );
}
/* --------------------------- DATE-RANGE ----------------------------------- */
function initDateRangeCalendar () {                                  /*dbug-log*///console.log('--initDateRangeCalendar');
    app.cal = el.getNewCalendar( getDateRangeCalConfg() );
    app.cal._input.disabled = true;
    $( '#rvw-date-end' ).attr( { disabled: 'disabled' } ).fadeTo( 'fast', '.6' );
}
/** TODO: ONLY ALLOW AVAILABLE DATE-RANGE */
function getDateRangeCalConfg () {
    return {
        elemId: '#rvw-date-start',
        enableTime: false,
        plugins: { range: { input: '#rvw-date-end' } },
    };
}