/**
 * Handles preparing ReviewEntry payload for local-storage. The payload entry is
 * quarantined, available only to the contributor who created it. Updates to the
 * entry during the review process are synced with the quarantined entry in storage.
 * When the ReviewEntry is completely finished with the review process, the entry
 * is flagged as completed and the stage is set to either Approved or Rejected.
 *
 * Export
 *     prepPayloadForClient
 *
 * TOC
 *     PROCESS REVIEW-ENTRY
 *     HANDLE COMPLETED
 *     PREPARE ENTRY
 *         STAGE HANDLER
 *         APPROVED
 *         PENDING
 */
import * as _t from '@types';
import * as sync from '@localdata/sync';
import { getData, setData } from '@localdata/util';
import { getFormState } from '@dataentry/state';
import { FormGroup } from '@dataentry/model';
import { getSubmittedStage } from '@dataentry/submit';
import { addReviewEntryToEntity, prepareQuarantined } from './init';

let fLvl: FormGroup
let ReviewEntries: _t.EntityRecords<_t.ReviewEntry>;
/* ===================== PROCESS REVIEW-ENTRY =============================== */
/**
 * Handles preparing ReviewEntry payload for local-storage. The payload entry is
 * quarantined, available only to the contributor who created it. Updates to the
 * entry during the review process are synced with the quarantined entry in storage.
 * When the ReviewEntry is completely finished with the review process, the entry
 * is flagged as completed and the stage is set to either Approved or Rejected.
 */
export function prepPayloadForClient(
    lvl: FormGroup,
    data: sync.DataEntryResults,
): Promise<sync.DataEntryResults> {
    fLvl = lvl;                                                     /*dbug-log*///console.log( '       +--prepareEntryPayload entryResults[%O]', data );
    data.review = sync.getParsedReviewEntry( data );
    return getReviewEntries()
        .then( () => processReviewEntryRecord( data.review as _t.ReviewEntry, data ) );
}
function getReviewEntries() {
    return getData<_t.EntityRecords<_t.ReviewEntry>>( 'reviewEntry' )
        .then( records => ReviewEntries = records! );
}
function processReviewEntryRecord (
    rEntry: _t.ReviewEntry,
    data: sync.DataEntryResults,
): Promise<sync.DataEntryResults> {
    if ( rEntry.stage.name === 'Completed' ) return handleReviewComplete( rEntry, data );
    return updateEntryStorage( rEntry )
        .then( () => handlePrepPayload( data ) );
}
function updateEntryStorage( rEntry: _t.ReviewEntry ): Promise<void> {/*dbug-log*///console.log( '--updateEntryStorage rEntry[%O]', rEntry );
    return sync.updateReviewEntryStorage( rEntry, ReviewEntries )
        .then( entries => { ReviewEntries = entries; } );
}
/* ======================== HANDLE COMPLETED ================================ */
function handleReviewComplete(
    rEntry: _t.ReviewEntry,
    data: sync.DataEntryResults,
): Promise<sync.DataEntryResults> {
    return handleCompletedRecord( rEntry )
        .then( completed => updateWithCompletedEntry( data, completed ) );
}
/**
 * Flags record completed and replaces record stage with the final review stage:
 * either Approved or Rejected.
 */
function handleCompletedRecord ( rEntry: _t.ReviewEntry ): Promise<_t.ReviewEntry> {/*dbug-log*///console.log( '   --handleCompletedRecord rEntry[%O]', rEntry );
    let { form, stage, ...rest } = rEntry;
    return getFinalStageBones( form )
        .then( stage => { return { completed: true, form, stage, ...rest }; } )
}
function getFinalStageBones( form: _t.ReviewEntry['form'] ) {
    return getFinalReviewStage( form.stage.name )
        .then( stage => { return { id: stage.id, name: stage.passiveForm }; } )
}
function getFinalReviewStage ( stageName: _t.ReviewStage['activeForm'] ): Promise<_t.ReviewStage> {
    return getData<_t.EntityRecords<_t.ReviewStage>>( 'reviewStages' )
        .then( stages => _t.objectValues( stages! ).find( s => s.activeForm === stageName )! );
}
function updateWithCompletedEntry(
    data: sync.DataEntryResults,
    completed: _t.ReviewEntry
): Promise<sync.DataEntryResults> {
    data.review = completed;
    return updateEntryStorage( completed )
        .then( () => data );
}
/* ========================== PREPARE ENTRY ================================= */
/** Handles stage-specific processing. */
function handlePrepPayload ( data: sync.DataEntryResults ): Promise<sync.DataEntryResults> {/*dbug-log*///console.log( '           --handlePrepPayload data[%O]', data );
    const handler = getStageHandler( data.review as _t.ReviewEntry );/*dbug-log*///console.log( '                  --handler?[%O] data[%O]', handler, data );
    return handler ? handler( data ) : Promise.resolve( data );
}
/* ------------------------ STAGE HANDLER ----------------------------------- */
type StageHandler = ( data: sync.DataEntryResults ) => Promise<sync.DataEntryResults>;

function getStageHandler( rEntry: _t.ReviewEntry, ): StageHandler | null {
    const stage = getSubmittedStage( rEntry.stage.name, getFormState( fLvl ) );
    return getHandler( stage );
}
function getHandler( stage: _t.ReviewStage['passiveForm'] ): StageHandler | null {
    const handlers = {
        Approved: prepareApprovedEntry,
        Pending: prepQuarantinedPayload,
    } as const;
    return handlers[ stage as keyof typeof handlers ] || null
}
/* ------------------------ APPROVED ---------------------------------------- */
function prepareApprovedEntry ( data: sync.DataEntryResults ): Promise<sync.DataEntryResults> {
    return Promise.resolve( addReviewEntryToEntity( data ) );
}
/* ------------------------ PENDING ----------------------------------------- */
/**
* ReviewEntry payload is quarantined locally for the contributor.
*     - Quarantined IDs are randomly altered to ensure unique values
*     - Relationships with quarantined-entities are set in the serialized
*         entity-data returned from the server.
*/
function prepQuarantinedPayload ( data: sync.DataEntryResults ): Promise<sync.DataEntryResults> {/*dbug-log*///console.log( '--handleQuarantine rEntry[%O]', data );
    return Promise.resolve( prepareQuarantined( data, fLvl, ReviewEntries ) );
}