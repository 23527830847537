/**
 * This module manages the map state for the explore page and data-entry forms.
 *
 * EXPORT
 *     clearMemory
 *     getMapState
 *     getMapRecords
 *     setMapState
 *     addPopupToState
 *     setMapView
 *     addMapLayer
 *     removeMapLayer
 *     addMarkersToMap
 *     clearAllMapMarkers
 *     addPolygonToMap
 *     zoomMapToPoly
 */
import { handleMapFeatureInit } from './init';

let app;

handleMapFeatureInit();
initMapState();
/* ============================= STATE ====================================== */
/* --------------------------- INIT STATE ----------------------------------- */
/**
 * Map Types:
 *     map -> Table focus: Location, View: map
 *     table-map -> Table interactions loaded on the map
 *     int -> interaction form
 *     create -> location form
 *     edit -> location form
 *
 * Map State:
 *     records - (k) entity, (v) all entity data
 *         location, interaction, geoJson, taxon
 *     geoCoder - used for reverse geocode
 *     map - leaflet map instance
 *     popups - (k) location name, (v) popup api
 *     volatile - container for volatile map objects
 *         markers - all markers (aka pins) added to map
 *         pin - dropped on-click in location forms
 *         poly - country/region ouline on the map
 */
function initMapState () {
    app = {
        records: {},
        geocoder: null,
        map: null,
        popups: {},
        volatile: {}
    };
}
export function clearMemory () {                                    /*dbug-log*///console.log( 'clearing map memory' )
    if ( app.map ) app.map.remove();
    initMapState();
}
/* --------------------------- GET STATE ------------------------------------ */
export function getMapState ( k ) {
    return k ? app[ k ] : app;
}
export function getMapRecords( entity, id ) {                       /*dbug-log*///console.log( '    --getMapRecords entity[%s] id[%s] app.records[%O]', entity, id, app.records )
    if ( !entity ) return app.records;
    return id ? app.records[ entity ][ id ] : app.records[ entity ];
}
/* --------------------------- SET STATE ------------------------------------ */
export function setMapState ( k, data ) {
    if ( k ) app[ k ] = data;
    else app = data;
    // console.log( 'MapState updated key[%s] data[%O] newState[%O]', k, data, app );
}
export function addPopupToState ( loc, popup ) {
    app.popups[ loc.displayName ] = popup;
}
/* ======================= MAP STATE UTILS ================================== */
export function setMapView( latlng, zoom ) {
    app.map.setView( latlng, zoom, { animate: true } );
}
/* --------------------------- LAYER -------------------------------------- */
export function addMapLayer( layer ) {
    app.map.addLayer( layer );
}
export function removeMapLayer( layer ) {
    app.map.removeLayer( layer );
}
/* --------------------------- MARKERS -------------------------------------- */
export function addMarkersToMap( markers ) {
    if ( !app.map ) return; // Map closed
    markers.forEach( M => addMapLayer( M.layer ) );
    addMarkersToState( markers );
}
function addMarkersToState( markers ) {
    if ( !app.volatile.markers ) app.volatile.markers = [];
    app.volatile.markers = app.volatile.markers.concat( markers );
}
export function clearAllMapMarkers() {
    const markers = app.volatile.markers || [];                     /*dbug-log*///console.log( 'clearAllMapMarkers markers[%O]', markers );
    markers.forEach( m => app.map.removeLayer( m ) );
    app.volatile.markers = [];
}
/* --------------------------- POLYGON -------------------------------------- */
export function addPolygonToMap( polygon, skipZoom = false ) {
    clearMapPolygon();
    polygon.addTo( app.map );
    app.volatile.poly = polygon;
    zoomMapToPoly( polygon, skipZoom )
}
export function zoomMapToPoly( poly, skipZoom ) {
    if ( skipZoom ) return;
    app.map.fitBounds( poly.getBounds(), { padding: [ 10, 10 ] } );
}
function clearMapPolygon () {
    if ( !app.volatile.poly ) return;
    app.map.removeLayer( app.volatile.poly );
    app.volatile.poly = null;
}