/**
 * Inits the interaction form with all fields displayed and the first field,
 * publication, in focus. From within many of the fields the user can create
 * new entities of the field-type by selecting the 'add...' option from the
 * field's combobox and completing the appended sub-form.
 *
 * Export
 *     initCreateForm
 *     initEditForm
 *     finishInteractionFormBuild
 *
 * TOC
 *     INIT FORM
 *         CREATE
 *         EDIT
 *         REVIEW
 *         SHARED
 *     FINISH BUILD
 *         FORM COMBOBOXES
 *         LOCATION INIT
 */
import { enableCombobox, focusCombobox } from '@elems/combo';
import { components, state } from '@dataentry';
import * as iField from '../field';
import { resetInteractionForm } from './reset-form.js';
/* ======================= INIT FORM ======================================== */
/* --------------------------- CREATE --------------------------------------- */
export function initCreateForm () {                                 /*temp-log*///console.log( '   //Building New Interaction Form' );
    if ( state.isFormActive() ) return; //Form is already opened.
    return components.initForm( getCreateFormParams() )
        .then( finishInteractionFormBuild );
}
function getCreateFormParams () {
    const params = getIntFormParams( 'create' );
    params.afterSubmitAndPreventClose = resetInteractionForm;
    params.pinnable = true; //If checked, field value will persist through reset.
    return params;
}
/* ---------------------------- EDIT ---------------------------------------- */
export function initEditForm ( entity, id ) {                       /*temp-log*///console.log( '   //Building EDIT Interaction Form id[%s]', id );
    return components.initForm( getEditFormParams( id ) )
        .then( finishInteractionFormBuild )
        .then( iField.finishFieldFill );
}
function getEditFormParams ( id ) {
    const params = getIntFormParams( 'edit' );
    params.id = id;
    return params;
}
/* ---------------------------- REVIEW -------------------------------------- */
export function initReviewForm ( rEntry ) {                          /*temp-log*///console.log( '   //Building REVIEW Interaction Form rEntry[%O]', rEntry );
    const params = getReviewParams( rEntry );
    return components.initForm( params )
        .then( finishInteractionFormBuild )
        .then( () => iField.finishFieldFill( rEntry ) );
}
function getReviewParams ( rEntry ) {
    const params = getIntFormParams( rEntry.form.action );
    params.review = rEntry;
    params.id = rEntry.form.editId;
    return params;
}
/* --------------------------- SHARED --------------------------------------- */
function getIntFormParams ( action ) {                              /*dbug-log*///console.log('   --getIntFormParams action[%s]', action);
    return {
        action: action,
        initCombos: iField.initCombos,
        name: 'Interaction'
    };
}
/* ======================= FINISH BUILD ===================================== */
/**
 * Inits the selectize comboboxes, adds/modifies event listeners, and adds
 * required field elems to the form's config object.
 */
export function finishInteractionFormBuild () {                     /*dbug-log*///console.log('           --finishIntFormBuild');
    modifyFormDisplay();
    finishLocationInit();
    finishComboboxInit();
    iField.initMiscFields();
}
function modifyFormDisplay () {
    components.setDynamicFieldStyles( 'Interaction' );
}
/* -------------------------- FORM COMBOBOXES ------------------------------- */
function finishComboboxInit () {
    enableCombobox( 'CitationTitle', false );
    iField.addRoleTaxonFocusListeners();
    enableCombobox( 'InteractionType', false );
    focusPubFieldIfNewRecord();
}
function focusPubFieldIfNewRecord () {
    const action = state.getFormState( 'top', 'action' );
    focusCombobox( 'Publication', action === 'create' );
}
/* ------------------------ LOCATION INIT ----------------------------------- */
function finishLocationInit () {
    iField.addLocationSelectionMethodsNote();
    setDefaultLocationValue();
}
function setDefaultLocationValue () {
    const locField = state.getFieldState( 'top', 'Location' );/*dbug-log*///console.log('   -- setDefaultLocationValue locField[%O]', locField)
    if ( locField.value ) return;
    iField.setUnspecifiedLocation( locField );
}