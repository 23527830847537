/**
 * Manages the TableRowSelect instance for the review panel.
 *
 * Export
 *     initReviewPanelUtil
 *
 * TOC
 *     INIT
 */
import * as panels from '../..';
/**
 * tableRowSelect - Feature class handling table-row selection
 */
let app = {};
/**
 * Inits the new TableRowSelect instance for the review panel. It is disabled
 * until needed.
 */
export function initReviewPanelUtil() {                              /*dbug-log*///console.log('--initReviewPanelUtil [%O]', app);
    app.tableRowSelect = panels.newTableRowSelect( 'review' );
    app.tableRowSelect.disable();
}