/**
 * Builds form-specific side-panel UI with a data-review log and a message container.
 *
 * EXPORT
 *     getReviewFormSidePanelElems
 *
 * TOC
 *     BUILD FORM-ELEMS
 *         ASSEMBLE
 *         HEADER
 *         CONTENT
 *         STYLE
 *     REVIEW LOG/MESSAGES
 *         HISTORY|LOG
 *         NEW MESSAGES
 *         HELPERS
 */
import { state } from '@dataentry';
import { getElem } from '@elems';
import { isManagerUser, ucfirst } from '@util';

let isReviewComplete;
/* ===================== BUILD FORM-ELEMS =================================== */
export default function getReviewFormSidePanelElems( fState, stage ) {/*dbug-log*///console.log("-- getReviewFormSidePanelElems fState[%O] stage[%s]", fState, stage);
    isReviewComplete = state.isApprovedOrRejected( fState.review );
    return assembleElemsAndReturnContainer( fState, fState.review, stage );
}
/* ---------------------------- ASSEMBLE ------------------------------------ */
function assembleElemsAndReturnContainer( fState, rEntry, stage ) {
    const cntnr = buildEntityReviewContainer( fState.group );
    const fElems = [
        getEntityReviewHeader( fState.group, fState.name, fState ),
        buildReviewContent( fState.group, fState.action, rEntry, stage )
    ];
    $( cntnr ).append( fElems );
    indentSubFormContainer( cntnr, fState.group );
    return cntnr;
}
function buildEntityReviewContainer( fLvl ) {
    const attr = { class: 'expandable', id: fLvl+'-rvw' };
    return  getElem( 'div', attr );
}
/* ------------------------------ HEADER ------------------------------------ */
function getEntityReviewHeader( fLvl, entity, fState ) {
    const action = fState.review?.form.action || fState.action;
    const txt = `${ entity } ${ ucfirst( action ) }`;
    const attr = { class: `toggle-trigger ${ fLvl }-rvw`, text: txt };
    const hdr = getElem( 'h4', attr );
    $( hdr ).data( 'expanded', false ).data( 'collapsed', false );
    return hdr;
}
/* ------------------------------ CONTENT ----------------------------------- */
function buildReviewContent( fLvl, action, rEntry, stage ) {
    const cntnr = getContentContainer();
    $( cntnr ).append( getStageDescription( stage ) );
    $( cntnr ).append( buildDataReviewNotesElems( fLvl, action, rEntry ) );
    return cntnr;
}
function getContentContainer() {
    const attr = { class: 'expandable-content' };
    return getElem( 'div', attr );
}
function getStageDescription( stage ) {
    const txt = getStageTxt( stage );                                 /*dbug-log*///console.log("-- getStageDescription txt[%s]", txt);
    return txt ? getElem( 'p', { class: 'stage-dec', text: txt } ) : null;
}
/** Shown to contributors after data-manager review. */
function getStageTxt( stage ) {
    const map = {
        Approved: 'Data entered into BatBase.org.',
        Rejected: 'Data will not be entered into BatBase.org.',
        Returned: 'Data have been returned for further action.'
    };                                                              /*dbug-log*///console.log("-- getDataReviewIntroTxt stage[%s] txt?[%s]", stage, map[stage]);
    return stage in map ? map[ stage ] : null;
}
/* ------------------------------ STYLE ------------------------------------- */
function indentSubFormContainer( cntnr, fLvl ) {
    const indent = { sub: 1, sub2: 2, top: 0 };
    $( cntnr ).css( { 'margin-left': `${ indent[ fLvl ] }em` } );
}
/* ==================== REVIEW LOG/MESSAGES ================================= */
function buildDataReviewNotesElems( fLvl, action, rEntry ) {
    const cntnr = getMessageContainer( fLvl );
    const log = getReviewLogElems( fLvl, action, rEntry );
    const msg = getReviewMessageElems( fLvl );
    $( cntnr ).append( [ ...log, ...msg ] );
    return cntnr;
}
function getMessageContainer( fLvl ) {
    const attr = { id: `${ fLvl }_rvw-msg-cntnr` };
    return getElem( 'div', attr );
}
function getHeader( txt ) {
    const attr = { text:  txt }
    return getElem( 'h4', attr );
}
/* ------------------------ HISTORY|LOG ------------------------------------- */
function getReviewLogElems( fLvl, action, rEntry ) {
    const log = getReviewHistory( fLvl, action, rEntry );
    return [ log ];
}
function getReviewHistory( fLvl, action, rEntry ) {
    const cntnr = buildHistoryContainer( fLvl );
    if ( isReviewComplete ) { $( cntnr ).css( 'height', '12em' ); }
    const log = buildHistoryLogHtml( fLvl, action, rEntry );
    $( cntnr ).append( ...log );
    return cntnr;
}
function  buildHistoryContainer( fLvl ) {
    const attr = {
        class: 'rvw-msgs',
        disabled: true,
        id:  `${ fLvl }_rvw-msg-hstry`
    };
    return getElem( 'div', attr );
}
function buildHistoryLogHtml( fLvl, action, rEntry ) {
    const history = getLog( rEntry )
    const userSection = getUserReviewLogElems( fLvl );
    return [ history, userSection ];
}
function getLog( rEntry ) {
    return rEntry ? rEntry.log : initLog();
}
function initLog() {
    return `<p class="g">Messages and actions are logged below.</p>`;
}
function getUserReviewLogElems( fLvl ) {
    const role = getReviewRoleName();
    const attr = {
        class: `${ role }_rvw-log`,
        id: `${ fLvl }_rvw-log`
    };
    const section = getElem( 'div', attr );
    if ( isReviewComplete ) { return section; }
    $( section ).append( `<p><i><u>${ role }:</u></i></p>` );
    return section;
}
/* ------------------------ NEW MESSAGES ------------------------------------ */
function getReviewMessageElems( fLvl ) {
    return isReviewComplete ? [] : getNewMessageElems( fLvl );
}
function getNewMessageElems( fLvl ) {
    const hdr = getHeader( `Message:` );
    const log = getNewMessageInput( fLvl );
    return [ hdr, log ];
}
function getNewMessageInput( fLvl ) {
    const attr = {
        class: 'rvw-msgs',
        id:  `${ fLvl }_rvw-msg`,
        placeholder: 'Add a message to the review...'
    };
    const input = getElem( 'textarea', attr );
    return input;
}
/* -------------------------- HELPERS --------------------------------------- */
function getReviewRoleName() {
    return isManagerUser() ? 'Manager' : 'Contributor';
}