/**
 * Loads fields for the selected [Publication|Citation]-type.
 * Eg, Pubs have Book, Journal, Dissertation and 'Other' sub-type configs.
 *
 * Export
 *     loadSrcTypeFields
 *
 * TOC
 *     LOAD SOURCE-TYPE ROWS
 *     SET SOURCE DETAIL-TYPE
 */
import { getSelTxt, getSelVal } from '@elems/combo';
import * as _t from '@types';
import { components, model, state } from '@dataentry';
/* ----------------- LOAD SOURCE-TYPE ROWS ---------------------------------- */
type SourceDetailType = _t.CitationType | _t.PublicationType;

export function loadSrcTypeFields (
    entity: 'Citation' | 'Publication',
    typeId?: number,
    type?: SourceDetailType
): Promise<void> {                                                  /*dbug-log*///console.log( '+--loadSrcTypeFields [%s] id?[%s] type[%s]', entity, typeId, type );
    const fLvl = state.getEntityFormLevel( entity );
    setSourceDetailType( entity, fLvl, typeId, type );
    return components.onFormConfigChanged( fLvl, entity )
        .then( () => finishSrcTypeFormBuild( fLvl ) );
}
function finishSrcTypeFormBuild ( fLvl: model.FormGroup ) {
    components.ifFormValidClearAlertsAndEnableSubmit( fLvl );
    $( '#DisplayName_f input' ).focus();
}
/* ----------------- SET SOURCE DETAIL-TYPE --------------------------------- */
/** Update form-state for the selected source type. */
function setSourceDetailType (
    entity: 'Citation' | 'Publication',
    fLvl: model.FormGroup,
    typeId?: number,
    type?: SourceDetailType
) {
    const typeField = entity+'Type';
    const val = {
        text: type ||  getSelTxt( typeField ),
        value: typeId || getSelVal( typeField )
    };                                                              /*dbug-log*///console.log('--setSourceType[%s] = %O', typeField, val);
    state.setFieldValue( fLvl, typeField, val );
    state.setFormState( fLvl, { type: val.text } );
    model.onLayoutChangeUpdateModel( fLvl );
}