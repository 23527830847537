import { DetailEntityFormConfig, ModelFormFields } from '../types';
/* ============================ TYPES ======================================= */
interface AuthorFormConfig extends DetailEntityFormConfig {
    layouts: {
        all: string[][];
        simple: string[][];
    };
    records: {
        edit: string[];
    };
}
/* ============================ CONFIG ====================================== */
/**
 * Author/editor form configuration.
 */
export default function( entity: string ): AuthorFormConfig {
	return {
        core: 'Source',
        records: {
            edit: [ 'source', 'author' ]
        },
        fields: getFieldConfig(),
        name: entity,
        layouts: {
            all: [
                [ 'LastName' ],
                [ 'FirstName' ],
                [ 'MiddleName', 'Suffix' ],
                [ 'Website' ],
                [ 'Description' ],
            ],
            simple: [
                [ 'LastName' ],
                [ 'FirstName' ],
                [ 'MiddleName', 'Suffix' ]
            ]
        }
    };
}
function getFieldConfig(): ModelFormFields {
    return {
        Description: {
            server: {
                prop: {
                    core: 'description'
                },
            }
        },
        DisplayName: {
            server: {
                prep: {},
            },
            type: null,
        },
        FirstName: {
            label: 'First',
            name: 'FirstName',
            server: {
                prop: {
                    detail: 'firstName'
                },
            },
            type: 'text',
        },
        MiddleName: {
            label: 'Middle',
            name: 'MiddleName',
            server: {
                prop: {
                    detail: 'middleName'
                },
            },
            type: 'text',
        },
        LastName: {
            type: 'text',
            name: 'LastName',
            required: true,
            server: {
                prep: {
                    setDetailData: [],
                    handleAuthorNames: [],  //set on this field, as it is the only one required
                },
                prop: {
                    detail: 'lastName'
                },
            },
        },
        Suffix: {
            class: 'w-4',
            type: 'text',
            name: 'Suffix',
            server: {
                prep: {
                    setSuffix: []
                },
                prop: {
                    detail: 'suffix'
                },
            },
        },
        SourceType: {
            value: 'Author'
        }
    };
}