/**
 * Inits the side-panel section that will contain misc data-review UI and info.
 *
 * EXPORT
 *     initReviewSidePanel
 *
 * TOC
 *     BUILD PANEL SECTION
 *         HEADER
 *     INFO
 *         INTRO
 *         ACTIONS
 */
import { state } from '@dataentry';
import { getElem } from '@elems';
import { isManagerUser } from '@util';
/* ==================== BUILD PANEL SECTION ================================= */
export default function initReviewSidePanel( fConfig, stage ) {
    const hdr = getReviewHeader();
    const info = getDataReviewInfoElems( fConfig, stage );
    return [ hdr, ...info ];
}
/* --------------------------- HEADER --------------------------------------- */
function getReviewHeader() {
    const attr = { class: 'b c', text: 'Data-Review' };
    return getElem( 'h5', attr );
}
/* ============================ INFO ======================================== */
function getDataReviewInfoElems( fConfig, stage ) {
    const intro = getDataReviewIntro( stage );
    const actions = getReviewActionsInfo( fConfig.review );
    return [ intro,  actions ];
}
/* ---------------------------- INTRO --------------------------------------- */
function getDataReviewIntro( stage ) {
    const txt = getDataReviewIntroTxt( stage );
    const attr = { text: txt };                                     /*dbug-log*///console.log("-- getDataReviewIntro stage?[%s] txt[%s]", stage, txt);
    return getElem( 'p', attr );
}
function getDataReviewIntroTxt( stage ) {
    if ( stage === 'Pending' ) return; //Not needed during data-manager review
    const map = {
        Approved: 'Result: Data entered into BatBase.org.',
        Rejected: 'Result: Data will not be entered into BatBase.org.',
        Returned: 'Result: Data have been returned for further action.',
        Pending: `Data are reviewed and either approved, rejected, or returned for
            further action. Until approved, only the contributor can use the data.`
    };                                                              /*dbug-log*///console.log("-- getDataReviewIntroTxt stage[%s] txt?[%s]", stage, map[stage]);
    return stage in map ? map[ stage ] : map.Pending;
}
/* --------------------------- ACTIONS -------------------------------------- */
function getReviewActionsInfo( rEntry ) {
    return isManagerUser() ? getManagerIntro() : getContributorIntro( rEntry );
}
function getManagerIntro() {
    const div = getElem( 'pre' );
    $( div ).text( getManagerIntroTxt() );
    return div;
}
function getManagerIntroTxt() {
    return `-------------- ACTIONS --------------
APPROVE: Modifies BatBase data.
PAUSE: Holds data until you continue.
REJECT:  Entity is deleted, data-set is returned.
RETURN:  Data is returned for further action.
SKIP: Ends your review of the data set.`;
}
function getContributorIntro( rEntry ) {
    if ( rEntry && state.isApprovedOrRejected( rEntry ) ) return;
    const attr = { text: getContributorIntroTxt() };
    return getElem( 'p', attr );
}
function getContributorIntroTxt() {
    return `Check notes below for further details.`;
    // return `Review results load in "Review" panel.`;
}