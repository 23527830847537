/**
 * Handles en/disabling the various Explore page features based on user role and
 * the state of local storage.
 *
 * The explore-options bar: Tutorial & Tips | Custom Data Lists | Table-Data
 *    Focus/View | Filters | Map & CSV | Data-Entry (New, Review, Help)
 * The table-status bar: Table Row Toggles | Table-Data Status (total shown,
 *    active filters, filter set/data list) | Table Column Toggle (future feature)
 *
 * Export
 *     initFeatureButtons
 *     onTableInitEnableButtons
 *     toggleTableButtons
 *
 * TOC
 *     AUTH-DEPENDENT FEATURES
 *         VISITOR
 *         USER
 *         EDITOR|CONTRIBUTOR
 *         MANAGER|SUPER
 *     DATABASE INIT UI
 *         UTIL
 *     TOGGLE TABLE BUTTONS
 *         ENABLE
 *         DISABLE
 *         UTIL
 */
/* UTILS */
import { enableCombobox, isComboActive } from '@elems/combo';
import * as _t from '@types';
/* DATA-ENTRY */
import { form } from '@dataentry';
/* EXPLORE */
import { map, table, ui } from '@explore';
import showEditorHelpModal from './editor-help-modal';

type ModuleData = {
    userRole: _t.Role;
    validFeatures: string;
    dbInitializing: boolean;
}
const app: ModuleData = {
    userRole: "visitor",
    validFeatures: '',
    dbInitializing: false
};
/* ================= AUTH-DEPENDENT FEATURES ================================ */
export function initFeatureButtons ( userRole: _t.Role ): void {
    initBaseFeatures();
    authDependentInit( userRole );
}
function initBaseFeatures (): void {
    $( "#show-tips" ).on( 'click', ui.showTips );
    $( "#shw-map" ).on( 'click', map.showIntsOnMap );
}
/* Inits the UI for features based on current user role. */
function authDependentInit ( userRole: _t.Role ): void {
    app.userRole = userRole;
    const initFeatures = {
        admin: initEditorFeatures,
        contributor: initEditorFeatures,
        editor: initEditorFeatures,
        manager: initSuperFeatures,
        super: initSuperFeatures,
        user: initUserFeatures,
        visitor: initVisitorFeatures,
    };
    const featureKey = app.userRole as keyof typeof initFeatures;
    app.validFeatures = initFeatures[ featureKey ]();
}
/* --------------------- VISITOR -------------------------------------------- */
function initVisitorFeatures (): string {
    disableUserFeatures();
    disableEditorFeatures();
    disableFilterSetOnceLoaded();
    return getVisitorFeatures();
}
function disableUserFeatures() {
    $( `button[name="csv"], #list-opts button, #data-opts button,
        .fltr-desc, #apply-filter, #save-filter, #delete-filter,
        #stored-filters input, #stored-filters textarea` )
        .css( 'cursor', 'not-allowed' ).prop( 'disabled', true ).fadeTo( 'fast', .5 )
        .prop( 'title', 'Please register to use these features.' );
}
function disableFilterSetOnceLoaded (): void {
    if ( isComboActive( 'FilterSet', true ) ) {
        return enableCombobox( 'FilterSet', false );
    }
    window.setTimeout( disableFilterSetOnceLoaded, 1000 ); //Needs time to finish initializing first.
}
function getVisitorFeatures() {
    return '.tbl-tools button, #help-opts button';
}
/* --------------------- USER ----------------------------------------------- */
function initUserFeatures (): string {
    initUserButtons();
    disableEditorFeatures();
    return getUserFeatures();
}
function initUserButtons (): void {
    $( 'button[name="csv"]' ).on( 'click', table.exportCsvData );
}
function disableEditorFeatures() {
    $( '#data-help, #new-data, #rvw-data' ).css( 'cursor', 'not-allowed' )
        .prop( 'title', 'This feature is only available to editors.' ).fadeTo( 'fast', .5 );
}
function getUserFeatures() {
    return `button[name="csv"], #lists, .map-disable, ${ getVisitorFeatures() }` ;
}
/* --------------------- EDITOR|CONTRIBUTOR --------------------------------- */
function initEditorFeatures (): string {
    initUserButtons();
    initEditorButtons();
    return getEditorFeatures();
}
function initEditorButtons (): void {
    $( '#data-help' ).addClass( 'adminbttn' ).on( 'click', showEditorHelpModal );
    $( '#new-data' ).addClass( 'adminbttn' ).on( 'click', () => form.createEntity( 'Interaction' ) );
    $( '#rvw-data' ).addClass( 'adminbttn' );
}
function getEditorFeatures() {
    return `#data-opts button, ${ getUserFeatures() }`;
}
/* --------------------- MANAGER|SUPER -------------------------------------- */
function initSuperFeatures (): string {
    $( '#data-help' ).css( { 'z-index': 1001 } ); //Above db-loading overlay
    return initEditorFeatures();
}
/* ===================== DATABASE INIT UI =================================== */
/** While the database is being initialized, all options are disabled. */
export function updateUiForDatabaseInit (): void {
    app.dbInitializing = true;
    showDataInitLoadingStatus();
    toggleExploreOptions( false );
    toggleMapButton( false );
}
function showDataInitLoadingStatus (): void {
    toggleFilterStatusUi();
    ui.showExplorePopupMsg();
}
/**
 * Once db init complete, the page features are enabled after a delay so the table
 * finishes reloading before the feature buttons fades in.
 */
function updateUiAfterBaseDatabaseInit (): void {
    toggleExploreOptions();
    toggleMapButton();
    toggleFilterStatusUi( true );
    app.dbInitializing = false;
}
function toggleMapButton ( enable = true  ): void {
    setToggleValues( '#shw-map', enable );
    $( '#shw-map' ).data( 'loading', !enable );
}
/* -------------------------- UTIL ------------------------------------------ */
function toggleFilterStatusUi( ready = false ) {
    const color = ready ? 'black' : 'teal';
    $( '#filter-status' ).css( 'color', color ).data( 'loading', !ready );
    if ( ready ) return;
    const status = '[ Data initializing... Table will reset once complete, ~45 seconds. ]';
    $( '#filter-status' ).text( status );
}
/* ================= TOGGLE TABLE BUTTONS  ================================== */
export function toggleTableButtons( enable = true ) {
    if ( enable ) enableTableButtons();
    else disableTableButtons();
}
/* -------------------------- ENABLE ---------------------------------------- */
export function onTableInitEnableButtons ( allDataAvailable: boolean ): void {
    if ( !allDataAvailable ) return enableFeaturesValidWhileLoading();
    enableTableButtons();
}
function enableFeaturesValidWhileLoading() {
    setToggleValues( '#focus-opts, #help-opts button', true )
}
/** On local-storage init and after switching from map-view to table-view. */
function enableTableButtons (): void {
    if ( app.dbInitializing || testingDbInit() ) updateUiAfterBaseDatabaseInit();
    toggleExploreOptions();
    ui.enableListResetBttn();
    ui.enableClearFiltersButton();
}
function testingDbInit (): boolean {
    return app.dbInitializing && $( 'body' ).data( 'env' ) === 'test';
}
/* -------------------------- DISABLE --------------------------------------- */
/** When in map view, disable buttons relevant to table view. */
function disableTableButtons (): void {
    setToggleValues( app.validFeatures, false );
    toggleMainExploreCombos( false );
}
/* ----------------------------- UTIL --------------------------------------- */
function toggleExploreOptions ( enable = true ): void {              /*dbug-log*///console.log('toggleExploreOptions enable?[%s]', enable);
    setToggleValues( app.validFeatures, enable );
    toggleMainExploreCombos( enable )
}
function toggleMainExploreCombos( enable: boolean ) {
    enableCombobox( 'Focus', enable );
    enableCombobox( 'View', enable );
}
function setToggleValues( selector: string, enable: boolean ) {
    const { opac, disabled, cursor } = getToggleValues( enable );
    $( selector )
        .css( 'cursor', cursor )
        .prop( 'disabled', disabled )
        .fadeTo( 'fast', opac );
}
function getToggleValues( enable: boolean ) {
    const opac = enable ? 1 : .5;
    const disabled = enable ? null : 'disabled';
    const cursor = enable ? 'pointer' : 'wait';
    return { opac, disabled, cursor };
}