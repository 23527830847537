/**
 * Builds the parameters for the review form by modifying the parameters for the
 * entry form.
 *
 * TOC
 *     ENTRY PARAMS
 *     REVIEW PARAMS
 *         MODIFY CREATE PARAMS
 */
import { form, model } from '@dataentry';
import { getSelVal } from '@elems/combo';
import * as _t from '@types';
import { buildCreateParams, getEditParams } from '../entry-forms';
/* FP-TS */
import { pipe } from 'fp-ts/function';

export function getReviewFormParams( rEntry: model.FormReviewEntry ): model.InitReviewParams {
    return pipe(
        rEntry,
        getBaseParams,
        params => finishReviewParams( params, rEntry )
    );
}
/* ======================== ENTRY PARAMS ==================================== */
function getBaseParams( rEntry: model.FormReviewEntry ): model.InitEntryParams {
    return pipe(
        rEntry,
        getQuarantinedCitationType,
        ( { id, displayName } ) => rEntry.form.action === 'create'
                ? buildCreateParams( displayName, id )
                : getEditParams( rEntry.form.editId!, displayName )
    );
}
function getQuarantinedCitationType( rEntry: model.FormReviewEntry ): _t.EntityBones<_t.CitationType> {
    const citation = rEntry.payload.detailEntity as any as _t.Citation;
    return citation.citationType as _t.EntityBones<_t.CitationType>;
}
/* ======================== REVIEW PARAMS =================================== */
function finishReviewParams(
    params: model.InitEntryParams,
    rEntry: model.FormReviewEntry
): model.InitReviewParams {
    return pipe(
        params,
        form.prepReviewParams( rEntry ),
        modifyEntryParamsForReview,
    );
}
/* ---------------------- MODIFY CREATE PARAMS ------------------------------ */
function modifyEntryParamsForReview( params: model.InitReviewParams ): model.InitReviewParams { /*dbug-log*///console.log( 'modifyEntryParamsForReview params[%O]', params );
    return params.action === 'create'
        ? modifyCreateParamsForReview( params as model.InitCreateParams )
        : params as model.InitReviewParams;
}
function modifyCreateParamsForReview( params: model.InitCreateParams ): model.InitReviewParams {
    const { vals, ...rest } = params;
    return updateReviewEntryParentValue( { ...rest as model.InitReviewParams } )
}
function updateReviewEntryParentValue( params: model.InitReviewParams ): model.InitReviewParams {
    const { review, ...rest } = params;
    const rEntry = { ...review };
    rEntry.form.fields.ParentSource!.value = getSelVal( 'Publication' );
    return { ...rest, review: rEntry };
}