/**
 * After ReviewEntry has been submitted, form-state is updated accordingly.
 *
 * Export
 *     getSubmittedStage
 *     ifReviewSubmittedHandleUpdates
 *
 * TOC
 *     AFTER SUBMIT
 *         IF REVIEW SUBMITTED
 *         POST-SUBMIT CHAIN
 *     UPDATE FORM-STATE
 *     UPDATE PARENT FORM
 *         UPDATE PARENT FIELDS
 *         SET PARENT FIELD-COMBO
 *         LOG SUB-STAGE
 *     STAGE-SPECIFIC UPDATES
 */
import { getSelVal, setSelVal } from '@elems/combo';
// import { syncReviewEntryRecords } from '@localdata';
import { cloneObj } from '@util';
import { components, state, submit, trans } from '@dataentry';
/* ======================== AFTER SUBMIT ==================================== */
/* --------------------- IF REVIEW SUBMITTED -------------------------------- */
/**
 * @returns True when closing data-set from a sub-form. (Root-form will handle close.)
 */
export function ifReviewSubmittedHandleUpdates ( fLvl, data ) {     /*dbug-log*///console.log( '           -- ifReviewSubmittedHandleUpdates fLvl[%s] data[%O]', fLvl, cloneObj( data ) );
    if ( !data?.review || fLvl === 'top' ) return;  // continue to the form's close-chain
    return state.syncEntityRecords( 'reviewEntry' )
        .then( () => afterReviewEntrySubmitted( fLvl, data ) );
}
/* --------------------- POST-SUBMIT CHAIN ---------------------------------- */
/**
 * After ReviewEntry created, the parent-form is updated. If data-review process
 * is active, it is continued.
 * @param  {object} fLvl    Submitted form-group
 * @param  {object} eRcrd   Entity-data after submit
 * @return {string}         'success' will continue the form's close-chain
 */
function afterReviewEntrySubmitted ( fLvl, eRcrd ) {
    const fState = state.getFormState( fLvl );
    addReviewEntryToRecord( eRcrd );                                /*temp-log*///console.log( '+-- afterReviewEntrySubmitted. fState[%O] eRcrd[%O]', cloneObj( fState ), cloneObj( eRcrd ) );
    // syncReviewEntryRecords(); // Updates displayed ReviewEntry counts
    return updateReviewEntryFormState( fState, eRcrd );
}
function addReviewEntryToRecord( eRcrd ) {
    eRcrd.review = state.getRecords( 'reviewEntry', eRcrd.review.id );
}
/* ====================== UPDATE FORM-STATE ================================= */
function updateReviewEntryFormState( fState, eRcrd ) {
    updateFormReviewEntry( fState, eRcrd );
    const stage = getSubmittedStage( eRcrd.review.stage.name, fState );
    updateParentForm( fState, eRcrd, stage );
    return handleStageSpecificUpdates( fState, stage );
}
/**
 * Skipped data-review sets are reset to their stage before the data-review began.
 * This is detected here in order to properly process the ReviewEntry.
 */
export function getSubmittedStage( stage, fConfig ) {
    if ( !fConfig.review?.inst ) return 'Pending';
    const submitStage = fConfig.review.inst.rvwAction.name;
    return [ 'Pause', 'Skip' ].indexOf( submitStage ) !== -1 ? submitStage : stage;
}
/** Adds current ReviewEntry record to the form state to be used during form close. */
function updateFormReviewEntry( fState, eRcrd ) {
    const inst = fState.review?.inst;
    if ( !inst ) return;
    const review = { ...eRcrd.review };
    review.inst = { ...inst };
    state.setFormState( fState.group, { review } );
}
/* ===================== UPDATE PARENT-FORM ================================= */
function updateParentForm( fState, eRcrd, stage ) {
    const pState = getParentReviewFormState( fState.group );
    updateParentReviewEntryFields( pState, fState.combo, eRcrd );
    logToParentFormNotes( stage, fState.combo, pState.group, eRcrd.review );
    if ( !pState.review ) return; //Initial Contributor entry
    setParentFieldCombo( stage, fState.combo, eRcrd.review.completed );
}
/** Note: Taxa are created within select forms, so their field is 2 levels up. */
function getParentReviewFormState( fLvl ) {
    const pState = state.getParentFormState( fLvl );                /*dbug-log*///console.log( '--getParentReviewFormState fLvl[%s] pState[%O]', fLvl, pState );
    return pState.action === 'select' ? state.getParentFormState( pState.group ) : pState;
}
/** ---------------- UPDATE PARENT FIELDS --------------------------------- */
/**
 * Updates parent-form fields' ReviewEntry data. If approved, selects the entity.
 * @param  {object} pState     Parent-form state
 * @param  {string} comboField Submitted-form's entity combo-field
 * @param  {object} eRcrd      Entity-data after submit
 */
function updateParentReviewEntryFields( pState, comboField, eRcrd ) {
    const ReviewEntries = state.getRecords( 'reviewEntry' );        /*dbug-log*///console.log( '--updateParentReviewEntryFields pState[%O] combo[%s] ReviewEntries[%O]', pState, comboField, ReviewEntries );
    trans.updateReviewEntryFields( pState.fields, ReviewEntries );      /*dbug-log*///console.log( '   --storing after update', pState );
    state.replaceFormState( pState );
    setSelVal( comboField, eRcrd.review.entityId, 'silent' );
}
/** ---------------- SET PARENT FIELD-COMBO --------------------------------- */
function setParentFieldCombo ( stage, comboField, reviewComplete ) {/*dbug-log*///console.log( 'setParentFieldCombo stage[%s] comboField[%s] reviewComplete?[%s] currentValue[%s]', stage, comboField, reviewComplete, getSelVal( comboField ) );
    if ( !reviewComplete ) components.setReviewStageOption( comboField, stage );
}
/** ------------------- LOG SUB-STAGE --------------------------------------- */
function logToParentFormNotes( stage, comboField, fLvl, rEntry ) {   /*dbug-log*///console.log( '--logToParentFormNotes fLvl[%s] comboField[%s] stage[%s] rEntry[%O]', fLvl, comboField, stage, rEntry );
    trans.addToReviewLog( fLvl, getLogTxt( stage, comboField, rEntry ) );
    if ( stage === 'Pending' || fLvl === 'top' ) return;
    trans.pushUpdatedLogs( fLvl );
}
function getLogTxt( stage, comboField, rEntry ) {
    stage = getLogStageText( stage, rEntry );
    return `<p class="g i">--- ${ stage } ${ comboField } (ID: ${ rEntry.id })</p>`;
}
function getLogStageText( stage, rEntry ) {
    if ( rEntry.completed ) { return `${ stage } Completed`; }
    return stage === 'Pending' ? 'Created' : stage;
}
/** ================== STAGE-SPECIFIC UPDATES =============================== */
function handleStageSpecificUpdates ( fState, stage ) {             /*dbug-log*///console.log( '--handleStageSpecificUpdates stage[%s] fState[%O]', stage, fState );
    const hndlr = {
        Held: () => closeSetAndContinueReview( stage, fState ),
        Pause: () => closeSetAndContinueReview( stage, fState ), // Stage: Pause (active), Held (passive)
        Skip: () => closeSetAndContinueReview( stage, fState ),
        Pending: () => reenableParentForm( fState.group )
    };
    return hndlr[ stage ] ? hndlr[ stage ]() : null;
}
/**
 * When sub-data is paused, skipped, or held, the root ReviewEntry is set to the
 * same stage. The sub-form's close-chain is prevented to allow for the review
 * process to continue, using the root-form's close-chain.
 */
function closeSetAndContinueReview ( stage, fState ) {               /*dbug-log*///console.log( '--closeSetAndContinueReview stage[%s] fState[%O]', stage, fState );
    if ( fState.group === 'top' ) return;
    const rootForm = state.getFormState( 'top' );
    rootForm.review.inst.rvwAction = { name: stage };
    state.replaceFormState( rootForm );
    submit.submitDataReview( rootForm );
    return 'skipClose';  // Closing data-set from nested sub-form. Sub-chain will disrupt root-form close
}
function reenableParentForm( fLvl ) {
    const pLvl = getParentReviewFormState( fLvl ).group;
    components.ifFormValidClearAlertsAndEnableSubmit( pLvl );
}