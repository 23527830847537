/**
 * Handles modifying the form state.
 *
 * TOC
 *     STATE|PROP
 *     HANDLERS
 */
import { FM } from '../core/state-manager.ts';
import { getFormState } from '../get/get-form-state.js';
/* ======================== STATE|PROP ====================================== */
export function setFormState ( lvl, addState ) {
    const FS = getFormState( lvl );                                 /*dbug-log*///console.log( '   --setFormState addState[%O] FS[%O]', addState, FS );
    const newState = { ...FS, ...addState };
    return replaceFormState( newState );
}
export function replaceFormState( FS ) {                        /*dbug-log*///console.log( '   --updateFormGroupState FS[%O]', FS );
    FM.setLevelState( FS.group, FS );
    return FS;
}
/* ======================== HANDLERS ======================================== */
/** Sets form event-handler, overwritting any previously set. Eg, submit/cancel. */
export function setFormHandler ( lvl, type, handler ) {
    const FS = getFormState( lvl );                                 /*dbug-log*///console.log( '   --setFormHandler type[%s] handler[%O] FS[%O]' , type, handler, FS );
    FS.handlers[ type ] = handler;
    replaceFormState( FS );
}
/** Adds handler to call after previously set handlers */
export function addFormHandler ( lvl, type, newHandler ) {
    const FS = getFormState( lvl );                                 /*dbug-log*///console.log( '   --addFormHandler type[%s] newHandler[%O] FS[%O]' , type, newHandler, FS );
    const handler = FS.handlers[ type ] ? FS.handlers[ type ] : () => {};
    FS.handlers[ type ] = callHandler.bind( null, type, handler, newHandler );
    replaceFormState( FS );
}
/**
 * Ideally, all handlers of a given type should not be dependent on call order.
 * Handlers will be called in the order they were set.
 * @param  {string} type  Handler type (eg, onFormClose, afterFormClose, etc.)
 * @param  {function} leafHandler Current handler
 * @param  {function} branch      Previously set handlers of this type
 * @return {promise}
 */
function callHandler ( type, leafHandler, nextHandler = Function.prototype ) {/*dbug-log*///console.log( '   --callHandler type[%s] leafHandler[%O] nextHandler[%O]' , type, leafHandler, nextHandler );
    return Promise.resolve( leafHandler() ).then( nextHandler );
}