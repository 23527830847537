/**
 * Miscellaneous helper functions for the entity form.
 *
 * TOC
 *     INIT ENTITY-FORM
 */
import { promiseToTaskEither } from '@util';
import { components, model, state } from '@dataentry';
import { alertInUse, ifFormInUse } from '@dataentry/components';
/* FP-TS */
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
/* ======================= INIT ENTITY-FORM ================================= */
export function initEntityForm( p: model.InitFormParams ): TE.TaskEither<string, 'success'> {
    if ( ifFormInUse( p.group ) ) return handleInUseAlert( p );
    return pipe(
        p,
        state.initFormState,
        promiseToTaskEither,
        TE.chain( handleInitFormComponents ),
    );
}
function handleInUseAlert( p: model.InitFormParams ): TE.TaskEither<string, 'success'> {
    const name = 'combo' in p ? p.combo : p.name;
    alertInUse( p.group, name );
    return TE.left( `Alert: Sub-form already in use.` );
}
//todo2: change init methods back to the simple, short versions after refactor
function handleInitFormComponents( FS: model.FormState ): TE.TaskEither<string, 'success'> {
    const init = FS.group === 'top' ? 'handleRootFormInit' : 'handleSubFormInit';
    return pipe(
        FS,
        components[ init ],
        promiseToTaskEither
    );
}