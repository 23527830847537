/**
 * Helper methods for entity review-form init.
 *
 * Export
 *      prepReviewParams
 *
 * TOC
 *      PREP REVIEW-PARAMS
 *      INIT REVIEW-FORM
 */
import * as model from '@dataentry/model'; //todo2: decouple
/* FP-TS */
import { pipe } from 'fp-ts/function';
/* ======================= PREP REVIEW-PARAMS =============================== */
export function prepReviewParams ( rEntry: model.FormReviewEntry ): ( params: model.InitEntryParams ) => model.InitReviewParams {
    return ( params ) => pipe(
        params,
        addReviteEntry( rEntry ),
        paramsWithoutSubmit
    );
}
function addReviteEntry( rEntry: model.FormReviewEntry ): ( params: model.InitEntryParams ) => model.InitReviewParams {
    return ( params ) => {
        return { ...params, review: rEntry };
    }
}
function paramsWithoutSubmit( params: model.InitReviewParams ): model.InitReviewParams {
    const { submit, ...rest } = params;
    return rest;
}
