/**
 * Step configuration for the Explore page tutorials utilizing the intro.js library.
 *
 * TOC
 *     TUTORIAL STEP CONFIG
 *         INTRO AND TABLE
 *         FILTER
 *         CSV
 *         MAP
 *         LIST
 *         CONCLUSION
 *     '? ICON STEPS
 *         CUSTOM FILTER SETS
 *         CUSTOM DATA LISTS
 */
import * as tutorial from './tutorial-main.js';
/* =================== TUTORIAL STEP CONFIG ================================= */
export function getSteps () {
    const introAndTable = getIntroAndTableSteps();
    const filter = getFilterSteps();
    const list = getListStep();
    const csv = getCsvStep();
    const map = getMapSteps();
    const last = getConclusionStep();
    return [ ...introAndTable, ...filter, ...list, ...csv, ...map, ...last ];
}
/* -------------------------- INTRO AND TABLE ------------------------------- */
function getIntroAndTableSteps() {
    return [
        {
            element: '#help-opts',
            intro: `Learn about the features and tools on this page.<br><br>
                - "Tips" can help you refine your search.<br><br>
                - "?" icons open the tutorial for that feature.<br><br>
                Move to the next slide or click an area to start.<br><br>
                <div style="text-align:center;">
                    <button class="intro-bttn" title="Table Data">Table Data</button>
                    <button class="intro-bttn" title="Filters">Filters</button>
                    <button class="intro-bttn" title="Map View">Map View</button>
                </div>`, //Note: button text must match the title of the step it links to.
            position: 'right',
            setUpFunc: tutorial.addBttnEvents,
            title: 'Explore Interactions',
        },
        {
            element: '#focus-opts',
            intro: `<b>Location</b>
                - View by region/country or on a map.
                <br><br><b>Source</b> - View by publication, publisher, or author.
                <br><br><b>Taxon</b> - View by a taxonomy group (bat, worm, etc).
                <br><br>(Tutorial starts with the Taxon focus and Plant view.)`,
            position: 'top',
            setUpFunc: () => tutorial.setTableView( 'Plants' ),
            title: 'Focus and View Interactions'
        },
        {
            element: '#explore-table',
            intro: `The first column is a tree with interactions sorted by the selected focus and view.<br><br>
                Columns can be resized and rearranged.<br><br>
                Hover over a column header to reveal its filter menu.`,
            position: 'top',
            title: 'Table Data',
        },
        {
            element: '#explore-table',
            intro: `<b>Subject Taxon</b>: the active taxon in the interaction. (e.g.,
                Predation: A [subject] preys upon an [object])<br><br>
                <b>Cnt</b>: the count of interactions for each row.<br><br>
                <b>Type</b>: the type of interaction (e.g., pollination, host, roost, etc).<br><br>
                <b>Tags</b>: to further clarify the interaction.<br><br>
                (See our <a href="definitions" target="_blank">Definitions</a>
                to read about the types, tags, and habitats used.)`,
            position: 'top',
            title: 'Table Columns',
        }
    ];
}
/* ---------------------------- FILTER -------------------------------------- */
export function getFilterSteps() {
    return [
        {
            element: '#filter',
            intro: `Click here to toggle the filter panel.`,
            position: 'bottom',
            setUpFunc: () => {
                tutorial.toggleFilterPanelInTutorial();
                tutorial.setTableView( 'Mammals' );
            },
            title: 'Filters',
        },
        {
            element: '#default-filters',
            intro: `<b>Group</b>: Filter on specific taxonomic groups that interact with bats (“Bat” is always included)<br><br>
                <b>Special</b>: Filter on complex concepts: such as 'Bats as Prey'.<br><br>
                <b>Show Interactions After</b>: Filter on date published, or entered in our database.`,
            position: 'top',
            setUpFunc: () => {
                tutorial.toggleFilterPanelInTutorial();
                tutorial.setTableView( 'Mammals' );
            },
            title: 'Standard Filters',
        },
        {
            element: '#focus-filters',
            intro: `<b>Group-Root</b>: Groups with multiple root-taxa, such as mammals and parasites,
                can be filtered on roots.<br><br>
                <b>Ranks</b>: When a selection is made, higher and lower level taxa
                    automatically populate the rest.<br><br>
                <b>Name</b>: Filters the tree column text.`,
            position: 'top',
            setUpFunc: () => {
                tutorial.toggleFilterPanelInTutorial();
                tutorial.setTableView( 'Mammals' );
            },
            title: 'Focus Filters - Taxon',
        },
        {
            element: '#focus-filters',
            intro: `<b>Locations</b> can be filtered by region, country, and site name.<br><br>
                <b>Sources</b> can be filtered by author, publication, publisher, and type of
                publication (article, dissertation, etc).`,
            position: 'top',
            setUpFunc: tutorial.toggleFilterPanelInTutorial,
            title: 'Focus Filters',
        },
        {
            element: '#stored-filters',
            intro: `After filtering data using the Filter menu and table columns, save all currently
                applied filters as a set to run the same search later.<br><br>For example, a set
                could show all journal articles tagged with "flower" in a "forest" habitat or all
                African "consumption" interactions in a "desert" habitat.`,
            position: 'left',
            setUpFunc: tutorial.toggleFilterPanelInTutorial,
            title: 'Custom Filter Set - User Feature',
        },
        {
            element: 'button[name="reset-tbl"]',
            intro: `Click here to clear all filters and reset the table.`,
            position: 'right',
            setUpFunc: tutorial.clearFilters,
            title: 'Clear Filters',
        },
    ];
}
/* -------------------------------- LIST ------------------------------------ */
function getListStep() {
    return [
        {
            element: '#list-opts',
            intro: `Are you studying a few taxa in specific countries or
                habitats? Interested in just a few publishers or authors? <br><br>
                To explore with even more control, you can save interactions from
                the table, resulting from any combination of applied filters, to named
                lists and explore them as a custom data-set.<br><br>
                Click the "?" in the panel for a deeper dive into this feature.`,
            position: 'right',
            setUpFunc: tutorial.toggleListPanelInTutorial,
            title: 'Interaction Lists - User Feature',
        }
    ];
}
/* -------------------------------- CSV ------------------------------------- */
function getCsvStep() {
    return [
        {
            element: 'button[name="csv"]',
            intro: `<b>The filtered table-data can be exported in csv format.</b><br><br>The
                columns are exported in the displayed order, with additional columns to capture
                the entire interaction and full taxonomies.<br><br>For an explanation
                of the csv format and how to use the file, see the note at the bottom of "Tips".`,
            position: 'left',
            setUpFunc: () => {
                tutorial.toggleFilterPanelInTutorial( true );
                tutorial.toggleListPanelInTutorial( true );
            },
            title: 'Download CSV Data - User Feature',
        }
    ];
}
/* -------------------------------- MAP ------------------------------------- */
function getMapSteps() {
    return [
        {
            element: '#shw-map',
            intro: `After filtering the interactions, click here to display them geographically.`,
            position: 'left',
            title: 'Map View',
        },
        {
            element: '#explore-view',
            intro: `<b>Hover over or click a marker to see a summary of the
                interactions.</b><br><br>The summary shows the name of the Location,
                number of interactions, and the top 3 bat species reported in the
                interactions.`,
            position: 'top',
            setUpFunc: tutorial.loadIntsOnMap,
            title: 'Location Summaries',
        },
        {
            element: '#explore-view',
            intro: `Click the magnifying glass on the left side and enter your search criteria.<br><br>
                The map will refocus and show interactions nearby.`,
            position: 'top',
            setUpFunc: () => tutorial.loadLocView( 'map' ),
            title: 'Map Search',
        },
        {
            element: '#shw-map',
            intro: `Return to table view to filter the interactions further.`,
            position: 'top',
            title: 'Return to the Table',
        }
    ];
}
/* ---------------------------- CONCLUSION ---------------------------------- */
function getConclusionStep() {
    return [
        {
            element: '#help-opts',
            intro: `<div style="text-align:center;"><b>Thank you for taking the time to learn about the
                <br>BatBase.org Explore Page!</b><br><br>
                You can start this tutorial again at anytime.<br>
                <br><b>Register and log in to leave us feedback.<br>
                We'd love to hear from you!</b></div>` ,
            position: 'right',
        }
    ];
}
/* ======================== ? ICON STEPS ==================================== */
/* --------------------- CUSTOM FILTER SETS --------------------------------- */
export function getSavedFilterSteps () {
    const selector = '#stored-filters';
    const filterSteps = getFilterSteps();
    const step = filterSteps.findIndex( s => s.element === selector );
    return [ filterSteps[ step ] ];
}
/* ---------------------- CUSTOM DATA LISTS --------------------------------- */
export function getSavedListSteps () {
    return [
        {
            element: '#list-pnl',
            intro: `Studying specific countries in Africa, data from specific publishers or authors,
                or a perhaps few habitats in particular? <br><br>Save interactions as a list and
                use the filters and features to explore them as a group.<br><br>`,
            position: 'top',
            title: 'Create Custom Lists of Interactions',
        },
        {
            element: '#sel-list-cntnr',
            intro: `Select a list to manage or enter a new name to create.<br>
                New users have preloaded examples to help demonstrate this feature.`,
            position: 'right',
            title: 'Create and Manage Lists',
        },
        {
            element: '#load-list',
            intro: `After selecting an existing list with interactions, show the
                interactions in the table by clicking
                "Load Interaction List in Table".<br><br>Once loaded, sort and view
                the interactions using the various filters and features of the page.<br><br>`,
            position: 'right',
            title: 'Interact with Your Data List',
        },
        {
            element: '#mod-list-pnl',
            intro: `<b>Add:</b> Select/create a list. Select interaction rows in the table,
                or the "All Shown" option, and click "Save List" to update the list.<br><br>
                <b>Remove:</b> Select a list and click "Load Interaction List in Table". Select
                interaction rows in the table or the "All Shown" option and click
                "Save List" to remove the selected interactions from the list.`,
            position: 'top',
            title: 'Add/Remove List Interactions',
        },
        {
            element: 'button[name="clear-list"]',
            intro: `Click here to reset table to all interactions.`,
            position: 'bottom',
            title: 'Reset Custom Data Set',
        }
    ];
}