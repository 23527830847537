import { FormConfig,ModelFormFields } from '../types';
/* ============================ TYPES ======================================= */

/* ============================ CONFIG ====================================== */
/**
 * Taxon form configuration.
 */
export default function( entity: string ): FormConfig {
	return {
        records: {
            edit: [ 'group', 'orderedRanks', 'rank', 'rankNames', 'taxon' ]
        },
        fields: getFieldConfig(),
        name: entity,
        types: { //Two form "types": create, edit
            create: {
                layouts: {
                    all: [
                        [ 'Name' ]
                    ]
                }
            },
            edit: {
                layouts: {
                    all: [
                        [ 'Name' ],
                        [ 'Rank' ],
                        [ 'Parent' ],
                    ]
                }
            }
        },
        layouts: {
            all: []
        }
    };
}

function getFieldConfig(): ModelFormFields {
    return {
        DisplayName: {
            info: {
                tooltip: `Taxon names are verified using the Global Names Verifier.
Results can include the taxonomic hierarchy, synonyms, rank, and the source.
Partial matches also include the matched name.`,
                intro: `Taxon  names are verified using the <a href="https://verifier.globalnames.org" target="_blank">Global Names Verifier</a>.
                    <br><br>The results can include the taxonomic hierarchy,
                    synonyms, rank, and the source. <br><br>Partial matches also include the
                    matched name.`
            },
            label: 'Name',
            name: 'DisplayName',
            required: true,
            server: {
                prep: {
                    renameField: [ 'Name' ],
                    buildTaxonDisplayName: [],
                },
                prop: {
                    core: 'name'
                },
            },
            type: 'text',
        },
        Parent: {
            entity: 'Taxon',
            name: 'Parent',
            required: true,
            server: {
                prep: {
                    setParent: [ 'Taxon' ]
                },
                prop: {
                    core: 'parent'
                },
            },
            type: 'select'
        },
        Group: {
            name: 'Group',
            server: {
                prep: {},
                prop: {
                    core: 'group'
                },
            },
        },
        'Group-Root': {
            name: 'Group-Root',
            server: {
                prep: {}
            },
        },
        Rank: {
            entity: 'Rank',
            name: 'Rank',
            required: true,
            server: {
                prop: {
                    core: 'rank'
                },
            },
            type: 'select'
        }
    }
}