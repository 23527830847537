/**
 * Mangages the Interaction Type and Interaction Tag fields in the interaction form.
 *
 * Exports:
 *     initTypeField
 *     onTypeSelection
 *     resetTypeData
 *
 * TOC
 *     FIELD STATE
 *     BUILD VALID OPTIONS
 *     LOAD OPTIONS
 *         FIELD INIT-VAL
 *     ON TYPE SELECTION
 */
import { clearAnyGroupAlerts, showFormValAlert } from '@dataentry/alerts';
import { getRecords, setFieldValue } from '@dataentry/state';
import * as el from '@elems';
import { focusPinAndEnableSubmitIfFormValid } from '../util/focus-pin.js';
import { clearTypeTagData, loadInteractionTypeTags } from './tag.js';

/* ========================= FIELD STATE ==================================== */
/**
 * Module-data.
 * object:          Object-taxon group-root id
 * subject:         Subject-taxon group-root id
 * validInts:       Valid interaction ids for the selected subject and object groups
 */
let md = getTypeDefaultState();

export function resetTypeData() {
    md = getTypeDefaultState();
}
function getTypeDefaultState() {
    return {
        object: null,
        subject: null,
        validInts: {}
    };
}
/**
 * Once both a subject and object taxon have been selected, all valid interaction
 * types are loaded in the field combobox,
 * @param  {array} ids  Taxon Group-Root ids
 */
export function initTypeField ( ids ) {                                /*temp-log*///console.log(        '+--initTypeField subjRoot[%s] -> objRoot[%s]', ids[0], ids[1]);
    md.subject = ids[ 0 ];
    md.object = ids[ 1 ];
    loadIntTypeOptions();
}
/* ====================== BUILD VALID OPTIONS =============================== */
function loadIntTypeOptions() {
    const vIntTypeOpts = buildValidInteractionTypeOptions();
    loadTypeOptions( vIntTypeOpts );
    if ( !vIntTypeOpts.length ) return alertNoValidInteractions();
    clearAnyGroupAlerts();
    el.enableCombobox( 'InteractionType', true );
}
function buildValidInteractionTypeOptions() {
    const data = getRecords( [ 'interactionType', 'validInteraction' ] );/*dbug-log*///console.log( 'buildInteractionTypeOptions for validInts = %O data = %O', md.validInts, data );
    return getAllValidInteractionTypes( data.validInteraction ).map( buildIntTypeOpt )

    function buildIntTypeOpt ( vData ) {
        const type = data.interactionType[ vData.type ];
        const txt = `${ type.displayName } (${ type.activeForm })`;
        return { text: txt, value: vData.valId };
    }
}
/**
 * Valid Interaction entties describe the valid combinations of subject & object
 * groupRoots, interaction types, and tags. The ValidInteraction data is added to the
 * form's state.
 * @return {ary} Objects with each valid InteractionType id and it's ValidInteraction id
 */
function getAllValidInteractionTypes ( validInteractions ) {           /*dbug-log*///console.log( '-getAllValidInteractionTypes = %O', validInteractions );
    const types = [];
    for ( let id in validInteractions ) ifValidAddData( id );
    return types;

    function ifValidAddData ( id ) {
        const vInt = validInteractions[ id ];                         /*dbug-log*///console.log( '--ifValidAddData = %O', vInt );
        if ( !ifGroupsMatchState( vInt.subjectGroupRoot, vInt.objectGroupRoot ) ) return;
        md.validInts[ id ] = vInt;                                  /*dbug-log*///console.log( '---adding ValidInteraction[%O]', vInt );
        types.push( { type: vInt.interactionType, valId: id } );
    }
}
function ifGroupsMatchState ( subjRoot, objRoot ) {                  /*dbug-log*///console.log( 'ifGroupsMatchState subj[%s][%s] obj[%s][%s]', subjRoot, md.subject, objRoot, md.object );
    return md.subject == subjRoot && md.object == objRoot;
}
/* ----------------- ALERT NO VALID INTERACTION-TYPES ----------------------- */
function alertNoValidInteractions() {
    clearTypeTagState();
    showFormValAlert( 'InteractionType', 'noValidInts', 'top' );
    el.focusCombobox( 'InteractionTags', false );
}
/* ====================== LOAD OPTIONS ====================================== */
function loadTypeOptions ( opts ) {                                    /*dbug-log*///console.log( '--loadTypeOptions[%O] md[%O]', opts, md );
    const prevType = el.getSelVal( `InteractionType` );
    el.replaceSelOpts( 'InteractionType', opts );
    selectTypeInitVal( prevType, opts );
}
/* -------------------- FIELD INIT-VAL -------------------------------------- */
/**
 * Init-val is set when type data is persistsed into a new interaction, and during
 * edit-form build to fill the field with record data.
 */
function selectTypeInitVal ( prevType, typeOpts ) {
    const preVal = $( '#sel-InteractionType' ).data( 'init-val' ) || prevType;
    const validType = Object.keys( md.validInts ).find( i => ifInitTypeValid( i, preVal ) );/*dbug-log*///console.log( 'selectInitValIfValidType initVal?[%s] validInts[%O] validType?[%s]', preVal, md.validInts, validType );
    const initVal = validType || ( typeOpts.length === 1 ? typeOpts[ 0 ].value : null );
    if ( initVal ) {
        el.setSelVal( 'InteractionType', initVal );
    } else {
        clearTypeTagData();
        el.focusCombobox( 'InteractionType' );
    }
}
function ifInitTypeValid ( i, typeId ) {
    const validInt = md.validInts[ i ];
    return validInt.interactionType == typeId;
}
/* ======================== ON TYPE SELECTION =============================== */
export function onTypeSelection ( val ) {
    if ( !val ) return onTypeClear();
    const validInt = md.validInts[ val ];                             /*temp-log*///console.log( '--onTypeSelection validInt[%O]', validInt )
    setInteractionTypeFieldData( validInt.interactionType );
    focusPinAndEnableSubmitIfFormValid( 'InteractionType' );
    if ( !Object.keys( validInt.tags ).length ) return clearTypeTagData();
    loadInteractionTypeTags( validInt.tags, validInt.tagRequired );
}
function onTypeClear() {
    clearTypeTagState();
    $( '#sel-InteractionType' ).data( 'init-val', null );
    loadIntTypeOptions();
}
function clearTypeTagState() {
    clearTypeTagData();
    setInteractionTypeFieldData( null );
}
function setInteractionTypeFieldData ( val ) {
    setFieldValue( 'top', 'InteractionType', val );
}