/**
 * Publisher-form code.
 *
 * Export
 *     initCreateForm
 *     initEditForm
 *     initReviewForm
 *     onPublSelection
 *
 * TOC
 *     ON SELECTION
 *     INIT FORM
 *         CREATE
 *         EDIT
 *         REVIEW
 *     HELPERS
 *         PARAMS
 *         FINISH
 */
import * as sForm from '@controller/source';
import { components, form, model, state } from '@dataentry';
import { taskEitherToPromise } from '@util';
/* FP-TS */
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
/* ======================= ON SELECTION ===================================== */
export function onPublSelection( fLvl: model.FormGroup, val?: string ): Promise<void> | void {
    if ( val === 'create' ) return initCreateForm( val );
    state.setFieldValue( fLvl, 'Publisher', val );
    state.setFieldValue( fLvl, 'ParentSource', val );
}
/* ======================= INIT FORM ======================================== */
/* --------------------------- CREATE --------------------------------------- */
/**
 * When a user enters a new publisher into the combobox, the create form is built
 * and appended to the field's row.
 */
export function initCreateForm( v: string ): Promise<void> {        /*dbug-log*///console.log('           >--Publisher CREATE Form v[%s]', v);
    return pipe(
        state.getSubFormLvl( 'sub2' ),
        fLvl => getCreateParams( fLvl, v ),
        form.initEntityForm,
        finishPublisherForm
    );
}
function getCreateParams( fLvl: model.FormGroup, v?: string ): model.InitCreateParams {
    const cParams = {
        appendForm: appendPublisherForm,
        combo: 'Publisher',
        style: 'sml-sub-form',
        vals: { DisplayName: v === 'create' ? '' : v }
    };
    return { ...cParams, ...getFormParams( fLvl, 'create' ) };
}
function appendPublisherForm( form: HTMLElement ): void {
    $( '#Publisher_f' ).append( form );
}
/* ---------------------------- EDIT ---------------------------------------- */
export function initEditForm( id: number | string ): Promise<void> {/*dbug-log*///console.log('           >--Publisher EDIT Form id[%s]', id);
    return pipe(
        id,
        getEditParams,
        form.initEntityForm,
        finishPublisherForm
    );
}
function getEditParams( id: number | string ): model.InitEditParams {
    const eParams = {
        id: id,
        style: 'sml-form',
    };
    return { ...eParams, ...getFormParams( 'top', 'edit' ) };
}
/* --------------------------- REVIEW --------------------------------------- */
export function initReviewForm( rEntry: model.FormReviewEntry ): Promise<string | void> {/*dbug-log*///console.log('           >--Publisher REVIEW Form rEntry[%O]', rEntry);
    return pipe(
        rEntry,
        getReviewFormParams,
        form.initEntityForm,
        finishPublisherForm
    );
}
function getReviewFormParams( rEntry: model.FormReviewEntry ): model.InitReviewParams {
    return pipe(
        rEntry,
        getEntryParams,
        form.prepReviewParams( rEntry )
    );
}
function getEntryParams( rEntry: model.FormReviewEntry ): model.InitEntryParams {
    return rEntry.form.action === 'create'
        ? getCreateParams( rEntry.inst.group )
        : getEditParams( rEntry.form.editId! );
}
/* =========================== HELPERS ====================================== */
/* ---------------------------- PARAMS -------------------------------------- */
function getFormParams( fLvl: model.FormGroup, action: model.FormAction ) {
    return {
        action: action,
        name: 'Publisher',
        group: fLvl,
        submit: sForm.showSrcSubmitModal.bind( null, fLvl ),
    } as const;
}
/* ---------------------------- FINISH -------------------------------------- */
function finishPublisherForm( statusTE: TE.TaskEither<string, 'success'> ): Promise<void> {
    return pipe(
        statusTE,
        TE.map( _ => components.focusField( 'sub2', 'DisplayName' ) ),
        taskEitherToPromise
    )
}