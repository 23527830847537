/**
 * Pure methods that build the elements that display the taxonym verification results.
 *
 * Export
 *     getResultsContainer
 *     getResultDisplayElems
 *     getTaxonymElem
 *     selector
 *
 * TOC
 *     RESULTS CONTAINER
 *     BUILD ELEMS
 *         SIMPLE
 *         MATCH SOURCE
 *         MATCH TAXA
 */
import { form, model, state } from '@dataentry';
import { getElem } from '@elems';
import { ucfirst } from '@util';
import { MatchType, VerifierResult } from './global-names-verifier-types';
/* FP-TS */
import * as A from 'fp-ts/lib/Array';
import * as O from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/function';

export const selector = {
    field: '#DisplayName_f',
    input: '#DisplayName_f input',
    result: '#taxonym-verification',
}
export function getTaxonymElem(
    fLvl: model.FormGroup,
    type: keyof typeof selector
): JQuery<HTMLElement> {
    const elemSelector = `#${ fLvl }-form ${ selector[ type ] }`;
    return $( elemSelector );
}
/* ======================= RESULTS CONTAINER ================================ */
export function getResultsContainer (): HTMLDivElement {
    const container = buildContainer();
    $( container ).html( getInitText() );
    return container;
}
function buildContainer (): HTMLDivElement {
    const attrs = { class: 'flex-col', id: selector[ 'result' ].slice( 1 ) };
    return getElem<HTMLDivElement>( 'div', attrs );
}
function getInitText (): string {
    return navigator.onLine ? 'Verifying...' : 'Can not verify taxonym while offline.';
}
/* ======================= BUILD ELEMS ====================================== */
/**
 * Match Types:
    NoMatch (no matched name found)
    PartialFuzzy (fuzzy partial match after removing some parts)
    PartialExact (match after removing last or middle epithets)
    Fuzzy (fuzzy match to a canonical form)
    Exact (exact match to a canonical form or a verbatim string)
    Virus (literal match of Viruses, plasmids, prions and other non-cellular entities)
    // Not being used until advanced species verification is implemented:
    // FacetedSearch (match by a faceted search: advanced query results) https://github.com/gnames/gnverifier#advanced-search
 */
export function getResultDisplayElems(
    fLvl: model.FormGroup,
    matchType: MatchType,
    match: VerifierResult | undefined
): O.Option<string[]> {
    if ( matchType === 'NoMatch' ) return getNoMatchResultDisplay();
    return pipe(
        match,
        O.fromNullable,
        O.map( match => buildResultElems( fLvl, matchType, match ) ),
    );
}
function getNoMatchResultDisplay(): O.Option<string[]> {
    return O.some( [
        getMatchType( 'NO MATCH' ),
        '<p class="b">Please recheck spelling.</p>',
    ] );
}
function buildResultElems(
    fLvl: model.FormGroup,
    matchType: MatchType,
    match: VerifierResult
): string[] {
    return [
        getMatchType( matchType ),
        getMatchedNamed( matchType, match ),
        getMatchSourceLink( match ),
        getMatchRankAndFlagIfNotSameAsFormRank( fLvl, match ),
        getMatchTaxaHeir( fLvl, match ),
        match.isSynonym ? getMatchSynonym( match ) : ''
    ];
}
/* ---------------------------- SIMPLE -------------------------------------- */
function getMatchType ( type: string ): string {
    const name = type.includes( 'Partial' ) ? 'Partial' : type === 'Virus' ? 'Exact' : type;
    return `<p>Match: &nbsp;&emsp;${ name }</p>`;
}
/** Returns an element with the name of the partially/fuzzily-matched taxon. */
function getMatchedNamed ( matchType: MatchType, match: VerifierResult ): string {
    if ( [ 'Virus' ].includes( matchType ) ) return '';
    return `<p>Matched: &nbsp;${ match.matchedCanonicalFull }</p>`;
}
/** Returns an element with the final rank in the match's classificationRanks. */
function getMatchRankAndFlagIfNotSameAsFormRank (
    fLvl: model.FormGroup,
    match: VerifierResult
): string {
    const verifiedRank = getMatchRankName( match.classificationRanks );
    const alertClass = getAlertClassIfRankMisMatched( fLvl, verifiedRank );
    return `<p${ alertClass }>Rank:&emsp;&emsp;${ verifiedRank }</p>`;
}
function getMatchRankName ( matchRanks: string | undefined ): string {
    const rank = matchRanks?.split( '|' ).slice( -1 )[ 0 ];
    return rank ? ucfirst( rank ) : 'Not Available';
}
function getAlertClassIfRankMisMatched ( fLvl: model.FormGroup, verifiedRank: string ): string {
    if ( verifiedRank === 'Not Available' ) return '';
    const formRank = form.getRankName( fLvl );
    return verifiedRank === formRank ? '' : ' class="r"';
}
/* ------------------------- MATCH SOURCE ----------------------------------- */
/** Returns the match's source-link html. */
function getMatchSourceLink ( match: VerifierResult ): string {
    let txt = '<p>Source: &emsp;';
    txt += match.outlink ?
        `<a href="${ match.outlink ?? '#' }" target="_blank">${ match.dataSourceTitleShort }</a></p>`
        : `${ match.dataSourceTitleShort }</p>`;
    return txt;
}
function getMatchSynonym ( match: VerifierResult ): string {
    return `<p>(Synonym of "${ match.currentCanonicalFull }")</p>`;
}
/* -------------------------- MATCH TAXA ------------------------------------ */
function getMatchTaxaHeir (
    fLvl: model.FormGroup,
    match: VerifierResult
): string {
    return `<p>Taxa: &emsp;&emsp;${ getMatchTaxaClassificationPath( fLvl, match ) }</p>`;
}
/**
 * Returns the pipe-separated names of taxa in the match's classification path
 * that are at ranks used in our database for the current Group-Root.
 */
function getMatchTaxaClassificationPath (
    fLvl: model.FormGroup,
    match: VerifierResult
): string {
    return pipe(
        explodeResultString( match.classificationPath ),
        O.chain( path => stringifyClassificationPath( path, fLvl, match ) ),
        O.getOrElse( () => 'Not Available' )
    );
}
function explodeResultString ( string: string | undefined ): O.Option<string[]> {
    return pipe(
        string,
        O.fromNullable,
        O.map( string => string.split( '|' ).reverse() ),
    );
}
function stringifyClassificationPath(
    pathPieces:string[],
    fLvl: model.FormGroup,
    match: VerifierResult
): O.Option<string> {
    return  pipe(
        explodeResultString( match.classificationRanks ),
        O.chain( rankPieces => getRelevantClassificationPath( fLvl, pathPieces, rankPieces ) ),
    );
}
function getRelevantClassificationPath (
    fLvl: model.FormGroup,
    pathPieces: string[],
    rankPieces: string[]
): O.Option<string> {
    return pipe(
        getGroupRootInfo( fLvl ),
        O.map( ( [ groupName, groupRanks ] ) => {
            return `${ groupName } | ${ buildClassificationPath( groupRanks, pathPieces, rankPieces ) }`
        } )
    );
}
function getGroupRootInfo ( fLvl: model.FormGroup ): O.Option<[string, string[]]> {
    return pipe(
        getGroupRootData( fLvl ),
        O.fromNullable,
        O.map( ( { rcrd } ) => [ rcrd.name, rcrd.subRanks ] )
        );
    }
function getGroupRootData( fLvl: model.FormGroup ): any | null {
    return state.getFieldState( fLvl, 'Group-Root', 'misc' );
}
function buildClassificationPath(
    groupRanks: string[],
    pathPieces: string[],
    rankPieces: string[]
): string {
    const relevantNames = pipe(
        pathPieces,
        A.zip( rankPieces ),
        A.filter( ( [ _, rank ] ) => groupRanks.includes( ucfirst( rank ) ) ),
        A.reverse,
    );
    return relevantNames.reduce( addValidTaxoyms, [] as string[] ).join( ' | ' );
}
function addValidTaxoyms ( acc: string[], [ pathPiece, rankPiece ]: [string, string] ): string[] {
    return [ ...acc, getTaxonNameAtRank( pathPiece, rankPiece ) ];
}
/** Note: Virus results do not always include the match species name. */
function getTaxonNameAtRank( taxonName: string, rank: string ): string {
    if ( taxonName ) return taxonName;
    return rank === 'species' ? $( selector[ 'input' ] ).text() : 'Not Available';
}