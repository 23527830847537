/**
 * Manages the taxon-rank comboboxes in the taxon select-form, used when editing
 * a taxon's parent and in the interaction form to select Subject and Object taxa.
 *
 * When a taxon in a rank combo is selected, all child-rank comboboxes are
 * repopulated with related taxa, ancestor taxa are selected automatically, and
 * the 'select' button is enabled. When a rank combo is cleared, child-rank combos
 * are reset with children of the next selected ancestor, or the root taxon. If
 * the 'create' option is selected, the taxon create-form is opened for the rank if
 * all required ancestor-rank taxa are selected in the form or shows an alert.
 * @since  Refactored 202010
 *
 * Export
 *     onRankSelection
 *     getRankName
 *     populateAllRankCombos
 *
 * TOC
 *     VALIDATE THEN OPEN CREATE FORM
 *     RESET CHILD-RANK COMBOS
 *     FILL RANK COMBOS WITH RELATED TAXA
 */
import { getSelectedTaxon, ifMissingParentTaxon } from '@controller/taxon';
import { getSelTxt, replaceSelOpts, updatePlaceholderText } from '@elems/combo';
import { isNotNumber, isOptionObject, isStringAndNaN } from '@util';
import { components, state } from '@dataentry';
import { getAllRankAndSelectedOpts, getChildRankOpts } from './get-opts.js';

export function getRankName ( fLvl ) {
    const rankField = state.getFieldState( fLvl, 'Rank' );    /*dbug-log*///console.log( "   +--getRankName fLvl[%s] rankField[%O]", fLvl, rankField );
    return rankField ? getRankText( rankField.value ) : false;
}
function getRankText( val ) {
    if ( isStringAndNaN( val ) ) return val;
    return isOptionObject( val ) ? val.text : getSelTxt( 'Rank' );
}
export function onRankSelection ( rank, val, fLvl = 'sub' ) {          /*dbug-log*///console.log( "   +--onRankSelection rank[%s] val[%s]", rank, val );
    if ( val === 'new' ) return; // New taxon being created.
    if ( val === 'create' ) { return openTaxonCreateForm( rank, fLvl ); }
    if ( val === '' || isNotNumber( val ) ) { return syncTaxonCombos( rank ); }
    components.toggleSubmitBttn( fLvl, true );
    return repopulateCombosWithRelatedTaxa( val, fLvl );
}
 /* ----------------------- VALIDATE AND CREATE ----------------------------- */
function openTaxonCreateForm ( rank, fLvl ) {
    if ( ifMissingParentTaxon( rank, fLvl ) ) return; //alert handled
    $( `#sel-${ rank }` )[ 0 ].selectize.createItem( 'create' );
}
 /* ----------------------- RESET CHILD-RANK COMBOS ------------------------- */
function syncTaxonCombos ( rank ) {
    return populateAllRankCombos( getSelectedTaxon( rank ) );
}
/**
 * On form-init, fills all rank combos with all taxa at each rank.
 * On taxon selection, populates combos with child-taxa and the direct ancestors.
 */
export function populateAllRankCombos ( txn, fLvl = 'sub' ) {
    return getOptsForSelectedChildren( txn )
        .then( optData => repopulateRankCombos( optData.opts, optData.selected, fLvl ) );
}
function getOptsForSelectedChildren ( txn ) {
    if ( !txn ) { return getAllGroupRankOpts(); }
    return Promise.resolve( getChildOpts( txn ) );
}
function getAllGroupRankOpts() {
    const rootTxn = state.getFieldState( 'sub', 'Group-Root', 'misc' ).taxon;/*dbug-log*///console.log( '--getAllGroupRankOpts rootTxn[%O]', rootTxn );
    return getAllRankAndSelectedOpts( null, rootTxn );
}
function getChildOpts ( txn ) {
    if ( txn.rank.displayName === 'Species' ) { return false; }
    return getChildRankOpts( txn.rank.displayName, txn.children, state.getRecords( 'taxon' ) );
}
 /* ------------- FILL RANK COMBOS WITH RELATED TAXA ------------------------ */
/**
 * Repopulates the comboboxes of child ranks when a taxon is selected. Selected
 * and ancestor ranks are populated with all taxa at the rank and the direct
 * ancestors selected. Child ranks populate with only decendant taxa and
 * have no initial selection.
 */
function repopulateCombosWithRelatedTaxa ( selId, fLvl ) {
    return getAllRankAndSelectedOpts( selId )
        .then( optData => repopulateRankCombos( optData.opts, optData.selected, fLvl ) );

}
function repopulateRankCombos ( optsObj, selected, fLvl ) {         /*dbug-log*///console.log( 'repopulateRankCombos fLvl[%s] optsObj[%O] selected[%O]', fLvl, optsObj, selected );
    if ( !$( `#${ fLvl }-form` ).length ) return;  //Select-form is closed early in review forms
    for ( let rank in optsObj ) {
        repopulateRankCombo( optsObj[ rank ], rank, selected, fLvl );
    }
}
/**
 * Replaces the options for the rank combo. Selects the selected taxon and
 * its direct ancestors.
 */
function repopulateRankCombo ( opts, rank, selected, fLvl ) {       /*dbug-log*///console.log( "repopulateRankCombo [%s] = %O", rank, opts );
    replaceSelOpts( rank, opts );
    if ( !selected[ rank ] ) return;
    if ( selected[ rank ] == 'none' ) { return resetPlaceholer( rank ); }
    components.setSilentVal( fLvl, rank, selected[ rank ] );
}
function resetPlaceholer ( rank ) {
    updatePlaceholderText( rank, 0 );
}