/**
 * Handles accessing miscellaneous data from model configuration.
 *
 * TOC
 *     FIELD
 */
import { isNotNumber, lcfirst } from "@util";
/* ============================ FIELD ======================================= */
export function getFieldKey( name ) {
    const last = name.length - 1; //Multi-select field names end with their count
    return isNotNumber( name.charAt( last ) ) ? name : name.substring( 0, last );
}
/**
 * Gets the data-classes of the current field.
 *
 * @param  {obj} fConfig         Form field configuration
 * @param  {obj} fConfig.entity  Server classname for the field data - default "core" name
 * @param  {obj} fConfig.name    Form-field display-name - default "detail" name
 * @param  {obj} fConfig.server.entity.core    Record-data core name, when different than fConfig.entity
 * @param  {obj} fConfig.server.entity.detail  Record-data detail name, when different than fConfig.name
 *
 * @return {ary}        ["<CORE>", "<DETAIL>" || false]
 */
export function getFieldClassData( field ) {
    const core = getCoreEntityName( field );
    const detail = field.server?.entity?.detail || false;           /*dbug-log*///console.log( '--getDataEntities core[%s] detail[%s]', core, detail );
    return [ core, detail ].map( name => name && lcfirst( name ) );
}
function getCoreEntityName( field ) {
    return !field.entity ? field.name
        : !field.server?.entity ? field.entity
        : ( field.server.entity.core || field.entity );
}