/**
 * Stores data temporarily while updating to reduce async db calls.
 */
import * as _t from '@types';
import { DataStorage, StoredData, StoredDataValue, getAllStoredData, setData } from './idb-util';
import { clearFailedMemory } from '../sync';
import { cloneObj } from '@util';

let active: string[] = []; //processIds using temp-memory
let tempStore: DataStorage = {};
/* ======================== INIT/RESET ====================================== */
export function initMemoryDataObj ( processId = 'initLocalStorage' ) {
    active.push( processId );
    if ( getValue( 'taxon' ) ) return Promise.resolve();
    return getAllStoredData().then( initTempMemory );
}
function initTempMemory ( obj: DataStorage ): void {
    tempStore = obj;
}
export function clearTempMemory ( processId = 'initLocalStorage' ) {/*dbug-log*///console.log( '               --clearTempMemory [%s]', processId );
    active.splice( active.indexOf( processId ), 1 );
    if ( active.length > 0 ) return;
    clearFailedMemory();
    tempStore = {};
}
function isTempMemoryActive() {
    return Object.keys( tempStore ).length > 0;
}
/* ========================= GET/SET ======================================== */
/* ------------------------ GET --------------------------------------------- */
export function getValue ( key: string ): StoredDataValue {
    const data = tempStore[ key ]?.value;
    return data ?? null;
}
export function getEntityData (
    entity: string,
    id: number
): [_t.EntityRecords, _t.EntityRecord] {
    const entities = getEntities( entity );
    return [ entities, getEntity( entities, id ) ];
}
export function getEntities <T extends _t.EntityRecord>( classname: string ) {
    const entities = getValue( classname ) as _t.EntityRecords<T>;
    return entities;
}
export function getEntity <T extends _t.EntityRecord>(
    entities: _t.EntityRecords,
    id: number,
    entity?: string
): T {
    const rcrd = entities[ id ] as T;
    return rcrd;
}
/* ------------------------ SET --------------------------------------------- */
export function storeData ( key: string, data: StoredDataValue ): void { /*dbug-log*///console.log( 'Adding to tempStore [%s] = [%O]', key, data );
    if ( !isTempMemoryActive() ) handleTempStorageAlert( key );
    const mmryObj: StoredData = tempStore[ key ] ?? { changed: true, value: null };
    mmryObj.value = data;
    mmryObj.changed = true;
    tempStore[ key ] = mmryObj;
}
function handleTempStorageAlert( key:string ) {
    if ( key === 'lclDataUpdtdAt' ) return; //Local storage is initializing.
    const msg = `!!!!! Storing [${ key }] in temp memory without initializing first.
        ENSURE SET IN LOCAL STORAGE SEPARATELY.`;
    console.info( msg )
}
export function removeData ( key: string ): void {
    delete tempStore[ key ];
}
/* ========================= LOCAL STORAGE ================================== */
export function setNewDataInLocalStorage (): Promise<void> {        /*dbug-log*///console.log( '               --setting data = [%O]', cloneObj( tempStore ) );
    return Object.keys( tempStore ).reduce( ( p, key ) => {
        return !tempStore[ key ]?.changed ? p : resetChangeFlagAndStoreValue( p, key, tempStore[ key ]! );
    }, Promise.resolve() );
}
function resetChangeFlagAndStoreValue (
    p: Promise<void>,
    key: string,
    data: StoredData// | undefined
): Promise<void> {                                                  /*dbug-log*///console.log( '               --setting [%s] data = [%O]', key, cloneObj( tempStore[ key ]! ) );
    data.changed = false;
    return p.then( setData.bind( null, key, data.value ) );
}
export function setUpdatedDataInLocalDbAndClearTempMemory ( processId: string ) {
    return setNewDataInLocalStorage()
        .then( () => clearTempMemory( processId ) );
}