/**
 * A combobox with various 'special' filters that are complicated enough to
 * warrant special handling: such as 'Bats as Prey'. These filters can be applied
 * regardless of the table's current data view.
 *
 * Export
 *      initSpecialFilterCombobox
 *
 * TOC
 *      INIT COMBOBOX
 *      APPLY FILTER
 *      CLEAR FILTER
 */
import { initCombobox, resetCombobox, ComboboxConfig, isComboActive } from "@elems/combo";
import { fadeTable, showTable } from '@explore/ui'
import { getState } from '@explore/table/state';
import { getFilterField, newSel, onFilterChangeUpdateRowData, setFilterState } from '@explore/table/filter';
/* ====================== INIT COMBOBOX ===================================== */
export function initSpecialFilterCombobox() {
    if ( $( '#sel-SpecialFilter' ).length ) return; //already initialized
    $( '#top-default-filters' ).append( getSpecialFilter() );
    finishSpecialComboboxInit();
}
function getSpecialFilter() {
    const opts = buildRoleOptions();                                /*dbug-log*///console.log( 'initSpecialFilterCombobox' )
    const sel = newSel( opts, 'field-input', 'sel-SpecialFilter' );
    const filter = getFilterField( 'Special', sel );
    filter.id = 'specialFilterCntnr';
    return filter;
}
function buildRoleOptions() {
    return [
        { text: 'Bats as Prey', value: 'isPreyBat' },
        { text: '- None -', value: 'none' },
    ]
}
function finishSpecialComboboxInit() {                             /*dbug-log*///console.log( '-- finishSpecialComboboxInit' )
    const confg = {
        name: 'Special Filter',
        onChange: applySpecialFilter,
    } as ComboboxConfig;
    initCombobox( confg );
}
/* ====================== APPLY FILTER ====================================== */
function applySpecialFilter ( val: string ) {                       /*dbug-log*///console.log( 'applySpecialFilter [%s]', val );
    fadeTable();
    updateRoleFilterState( val );
    clearTypeAndTagsColumnFilters();
    onFilterChangeUpdateRowData();
    if ( val === 'none' ) resetCombobox( 'SpecialFilter' );
    showTable();
}
function clearTypeAndTagsColumnFilters() {
    const tblApi = getState( 'api' );
    tblApi.getFilterApi( 'interactionType' ).setModel( null )
    tblApi.getFilterApi( 'tags' ).setModel( null )
}
function updateRoleFilterState ( val: string ) {
    const state = { 'Special': val === 'none' ? false : val };
    setFilterState( 'combo', state, 'direct' );
}
/* ====================== CLEAR FILTER ====================================== */
export function resetSpecialFilter() {
    if ( !isComboActive( 'SpecialFilter', true ) ) return; //Table init before filter init complete
    resetCombobox( 'SpecialFilter' );
}