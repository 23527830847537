/**
 * Initializes form-state data.
 *
 * TOC
 *     STATE MANAGER
 *     FORM STATE
 *         CACHE RECORDS
 *         FORM VALUES
 *         ENTITY FORM
 *             TAXON
 *             SOURCE
 */
import { model as m, trans } from '@dataentry';
import { FM } from '../state-manager.ts';
import { getData } from '@localdata';
import { cloneObj } from '@util';
import { setRecordDependentEntityState } from '@dataentry/state/set/set-entity-state.js';
/* ======================= FORM STATE ====================================== */
/**
 * @param  {obj} p
 * @param  {str} p.action
 *
 * @param  {str} *p.name        Entity class
 * @param  {fnc} p.initCombos
 * @param  {fnc} p.onFormClose
 * @param  {fnc} p.beforeFormClose
 * @param  {fnc} p.submit
 *         ... MANY MORE POSSIBLE
 *
 * @return {obj}     FormState
 */
export function initFormState ( p ) {                               /*temp-log*///console.log( "    #--initFormState initParams[%O]", cloneObj( p ) );
    const model = m.initFormModel( p );                             /*dbug-log*///console.log( '         --initModel org[%O] cur[%O]', cloneObj( model ), model );
    return cacheRecords( model.records )
        .then( records => initNewFormState( records, model ) )
}
/* ----------------------- CACHE RECORDS ------------------------------------ */
/** Note: Root-form model has all potentially necessary record keys for sub-forms. */
function cacheRecords( records ) {
    if ( !records ) return Promise.resolve();
    return getData( records )
        .then( storeAndReturnRecords );
}
function storeAndReturnRecords( records ) {
    FM.setRecordsCache( records );
    return records;
}
/* ----------------------- ENTITY FORM -------------------------------------- */
function initNewFormState( records, model ) {                       /*dbug-log*///console.log( '-- initNewFormState model[%O]', cloneObj( model ) );
    const modelWithValues = trans.setFieldModelInitValues( records, model );    //console.log( '         --FS[%O]', modelWithValues  )
    return Promise.resolve( modelWithValues )
        .then( FM.initLevelState )
        .then( setRecordDependentEntityState )
        .then( () => FM.getLevelState( model.group ) )
        // .then( FS => {
        //     console.log( '  FORM INIT COMPLETE[%O]', cloneObj( FS ) );
        //     return FS;
        // } );
}
/**
 * Adds the properties and confg that will be used throughout the code for
 * generating, validating, and submitting entity sub-forms.
 *
 * -- Property descriptions: TODO2: UPDATE
 * > action - create || edit || review
 * > combo - The name of the form parent-combo.
 * > handlers - Custom functions for the entity-form (can be chained)
 *     afterFormClose - Final handler type in close-chain (after onFormClose)
 *         - Review-forms: ifSubReviewEntryLoadForm
 *     afterSubmitAndPreventClose - Called after submitted data is processed locally
 *         - Interaction (create): Resets form-data, rather than closing form
 *     appendForm - Deleted after form-elems appended
 *     beforeFormClose - Handles preparation before closiing the form (before form-state cleared)
 *         - Taxon (create-review): set select-form close handler
 *     cancel - Click handler for the cancel button
 *         - Author (create): reset parent-form elems
 *         - Review-forms: confirm modal and/or unlock data-set and load next
 *         - Taxon: name validation suggestions removed (TODO)
 *     initCombos - Inits the form's comboboxes
 *     onFormFieldChange - Custom handler(s) called after default change-handler
 *         - Alerts: cleared on field changes (TODO)
 *     onFormClose - Handles actions that happen after form-state cleared
 *         - Citation (sub): enable publication form-field
 *         - Location (sub): enable country field
 *         - Taxon select-forms (and taxon create form within taxon edit forms during review)
 *         - DUPLICATE ENTITY ERRORS: resolved by selecting existing entity
 *     submit - Click handler for the submit button
 *         - Location (create): checks if location data is unique and alrts if existing entity
 *         - Source: If url data present, shows modal prompting user to check links and then submit
 *         - Taxon
 *             (create/edit): name validation (TODO)
 *             (select): select in parent-form
 *             (create within edit): validate parent-change
 *         - Review-forms: show submit modal and/or continue data-review
 *         - DELETE ENTITY (edit): triggers removal of the entity being edited
 * > misc - Obj to hold the various special-case props
 * > name - Name of this form's entity.
 * > review - Container for the data-review process
 * > simple - All fields are shown unless simple default-display confg present
 *
 * --- Entity-specific properties
 * > Citation forms:
 *         rcrds - { src: pubSrc, pub: pub } (parent publication)
 * > Interaction create form:
 *         unchanged - exists after form submit and before any changes
 *         valData - ValidInteraction data for the selected subject and object groups
 *             [{id, subjectGroupRoot(id), objectGroupRoot(id), interactionType(id), tags(id array)}]
 * > Location forms:
 *         geoJson - geoJson entity for this location, if it exists.
 * > Taxon forms:
 *     todo2...
 */
// function fillModelWithEntityState ( records, model ) {              /*dbug-log*///console.log( "    #--fillModelWithEntityState entity[%s] model[%O] records?[%O]", model.name, cloneObj( model ), records );
//     return initEntityState( records, model );
// }
// function initEntityState ( records, model ) {
//     const map = {
//         // Citation: storeSourceData,
//         Subject: initTaxonState,
//         Object: initTaxonState,
//         Parent: initTaxonState,
//         Taxon: initTaxonState
//     };
//     if ( !map[ model.name ] ) { return Promise.resolve( model ); }
//     return Promise.resolve( map[ model.name ]( records, model ) );
// }
// /* ___________________________ TAXON ________________________________________ */
// function initTaxonState ( rcrds, model ) {                          /*dbug-log*///console.log( '   --initTaxonState rcrds[%O] model[%O]', rcrds, model );
//     return state.setTaxonGroupState( state.getRecords(), model );
// }