/**
 * Handles building Options objects for comboboxes throughout the site.
 *
 * TOC
 *    GET OPTIONS
 *        SIMPLE OPTIONS
 *        GROUP OPTIONS
 *    BUILD OPTIONS
 *        STORED DATA
 *        FIELD DATA
 *        BASIC ENTITY-OPTIONS
 *        SOURCE
 *        TAXON
 *        LOCATION
 *        INTERACTION
 *    HELPERS
 */
import { getData } from '@localdata';
import * as _t from '@types';
import { lcfirst, ucfirst } from '@util';
/* =========================== GET OPTIONS ================================== */
type EntityIdsByName = {
    [name: string]: number | string;
};
/** Options for comboboxes with grouped, ie categorized, dropdown menus. */
type GroupedOptionData = {
    [name: string]: _t.OptionObject;
};
type EntityOptionData = GroupedOptionData | EntityIdsByName;
export function getOptions (
    entityObj: EntityOptionData,
    sortedKeys: string[]    //Only returns options for keys in this array
): _t.OptionObject[] | [] {
    return isSimpleOpts( entityObj ) ?
        getSimpleOpts( entityObj, sortedKeys ) : getOptGroups( entityObj );
}
function isSimpleOpts ( obj: EntityOptionData ): obj is EntityIdsByName {
    const entityValueType = typeof _t.objectValues( obj )[ 0 ];
    return [ 'number', 'string' ].indexOf( entityValueType ) >= 0;
}
/** --------------------- SIMPLE OPTIONS ------------------------------------ */
function getSimpleOpts ( entityObj: EntityIdsByName, sortedKeys: string[] ): _t.OptionObject[] {
    return sortedKeys.map( name => getEntityOpt( name, entityObj[ name ] ) );
}
function getEntityOpt ( name: string, id: number | string | undefined ): _t.OptionObject {
    return { text: ucfirst( name ), value: String( id ) };
}
/** --------------------- GROUP OPTIONS ------------------------------------- */
function getOptGroups ( entityObj: GroupedOptionData ): _t.OptionObject[] {
    return _t.objectKeys( entityObj ).map( k => getGroupOpt( k, entityObj ) );
}
function getGroupOpt ( name: string, entityObj: GroupedOptionData ): _t.OptionObject {
    const opt = entityObj[ name ] || { text: '', value: '' };
    opt.text = name;
    return opt;
}
/* ========================== BUILD OPTIONS ================================= */
/** --------------------- STORED DATA --------------------------------------- */
/** Returns an array of options from stored name-data: { [key:string]: string | number }. */
export function getOptsFromStoredData (
    prop: string,
    emptyOk = false
): Promise<_t.OptionObject[] | []> {
    return getData( prop, true )
        .then( data => buildOpts( data, prop, emptyOk ) );
}
function buildOpts<T> (
    data: T,
    prop: string,
    emptyOk = false,
): _t.OptionObject[] | [] {
    if ( !data ) return handleNullData( prop, emptyOk );
    return getOptions( data, Object.keys( data ).sort() );
}
function handleNullData ( prop: string, emptyOk: boolean ): [] {
    if ( !emptyOk ) console.log( 'NO STORED DATA for [%s]', prop );
    return [];
}
/** Returns an array of options from stored name-data: { [key:string]: string | number }. */
function getStoredOpts ( _: string | null, key: string ): Promise<_t.OptionObject[]> {
    return getOptsFromStoredData( key );
}
/** --------------------- FIELD DATA ---------------------------------------- */
type OptReturn = _t.OptionObject[] | [] | Promise<_t.OptionObject[]>;
/**
 * Returns an array of options for the passed field type.
 * Note: Only stateless fields are handled here.
 * @param field - The name of the field.
 * @returns An array of options or an empty array if no options are available.
 */
export async function getFieldOptions( field: string ): Promise<OptReturn> {
    const config: OptionBuilder | [] = getOptionBuildConfig( field );
    if ( config instanceof Array ) return Promise.resolve( [] );
    const opts = isBuilderParamsWithProp( config )
        ? await config.builder( field, config.prop )
        : await config.builder( field );                            /*dbug-log*///console.log( 'getFieldOptions field[%s] opts[%O]', field, opts );
    return opts;
}
function isBuilderParamsWithProp( config: OptionBuilder ): config is BuilderParamsWithProp {
    return !!( config as BuilderParamsWithProp ).prop;
  }
type BuilderParamsWithProp = {
  builder: ( name: string, prop: string ) => OptReturn;
  prop: string;
};
type BuilderParamsWithoutProp = {
    builder: ( name: string ) => OptReturn;
};
type OptionBuilder = BuilderParamsWithProp | BuilderParamsWithoutProp;
type OptionBuilderMap = Record<string, OptionBuilder>;
/**
 * @param field - The name of the field.
 * @returns The configuration to build options for the specified field, a builder
 *          function and an optional property name, or an empty array if no match is found.
 */
function getOptionBuildConfig( field: string ): OptionBuilder | [] {
    const map: OptionBuilderMap = {
        Author: { builder: getSrcOpts, prop: 'authSrcs' },
        Country: { builder: getStoredOpts, prop: 'countryNames' },
        'Country-Region': { builder: getCntryRegOpts },
        Editor: { builder: getSrcOpts, prop: 'authSrcs' },
        HabitatType: { builder: getStoredOpts, prop: 'habTypeNames' },
        Location: { builder: getRcrdOpts },
        Publication: { builder: getSrcOpts, prop: 'pubSrcs' },
        PublicationType: { builder: getStoredOpts, prop: 'pubTypeNames' },
        Publisher: { builder: getSrcOpts, prop: 'publSrcs' },
        Rank: { builder: getRankOpts },
        Region: { builder: getStoredOpts, prop: 'regionNames' },
        Season: { builder: getTagTypeOpts, prop: 'season' },
    };
    return map[ field ] || [];
}
/* ----------------------- BASIC ENTITY-OPTIONS ----------------------------- */
export function initOptsWithCreate ( entity: string ): _t.OptionObject[] {
    return [ { text: `Add a new ${ ucfirst( entity ) }...`, value: 'create' } ];
}
export function getCreateEntityOpt ( entity: string ): _t.OptionObject {
    return { text: `Add a new ${ ucfirst( entity ) }...`, value: 'create' };
}
export function getRcrdOpts (
    entity: string,
    ids?: number[]
): Promise<_t.OptionObject[]> {
    const opts = initOptsWithCreate( entity );
    return getRecordsAndBuildOptions( entity, ids )
        .then( o => opts.concat( o ) );
}
function getEntityRecords( entity: string ): Promise<void | _t.EntityRecords> {
    return getData<_t.EntityRecords>( lcfirst( entity ) );
}
function getRecordsAndBuildOptions( entity: string, ids?: number[] ): Promise<_t.OptionObject[]> {
    return getEntityRecords( entity )
        .then( rcrds => buildRecordOptions( rcrds!, ids ) );
}
function buildRecordOptions( rcrds: _t.EntityRecords, ids?: number[] ): _t.OptionObject[] {
    ids = ids || _t.objectKeys( rcrds );
    const opts = buildEntityOptions( ids, rcrds );
    return [ ...alphabetizeOpts( opts ) ];
}
function buildEntityOptions ( ids: number[], rcrds: _t.EntityRecords ): _t.OptionObject[] {
    return ids.map( id => {
        const displayName = getComboEntityDisplayName( rcrds[ id ] as _t.EntityRecord, id );
        return { text: displayName, value: `${ id }` };
    } );
}
/* -------------------------- SOURCE ---------------------------------------- */
export function getDetailRecordOpts( core:string, detail:string, ids?: number[] ): Promise<_t.OptionObject[]> {
    const opts = initOptsWithCreate( detail );
    return getRecordsAndBuildOptions( core, ids )
        .then( o => opts.concat( o ) );
}
/* Removes text used to distinguish the names of citations for an entire publication. */
export function getComboEntityDisplayName ( rcrd: _t.EntityRecord, id: number | string ): string {
    return rcrd.displayName!.split( '(citation)' )[ 0 ] || String( id );
}
/** Returns an array of source-type (prop) options objects. */
function getSrcOpts ( field: string, prop: string ): OptReturn {
    return getData( prop )
        .then( ids => getDetailRecordOpts( 'source', field, ids as number[] ) );
}
/**
 * Returns the options for the citation-type combobox for the specified publication-type.
 */
export function getCitationTypeOptsForPublicationType( pubType: _t.PublicationType ): Promise<_t.OptionObject[]> {
    const validTypes = getCitTypeNames( pubType );
    return getData<EntityIdsByName>( 'citTypeNames' )
        .then( nameObj => getOptions( nameObj!, validTypes.sort() ) );
}
function getCitTypeNames ( pubType: _t.PublicationType ): string[] {
    const opts = {
        Book: [ 'Book', 'Chapter' ],
        Journal: [ 'Article' ],
        Other: [ 'Museum Record', 'Other', 'Report' ],
        'Thesis/Dissertation': [ "Master's Thesis", 'Ph.D. Dissertation' ]
    };
    return opts[ pubType ];
}
/* -------------------------- TAXON ----------------------------------------- */
function getRankOpts ( _: string ): OptReturn {
    return getData( [ 'orderedRanks', 'rankNames' ] )
        .then( data => buildRankOpts( data.orderedRanks as string[], data.rankNames as RankDataByName ) );
}
//todo: static types for all local data-storage properties
type RankDataByName = {
    [name: string]: RankData;
};
type RankData = { id: string; ord: number; };
function buildRankOpts ( order: string[], ranks: RankDataByName ): _t.OptionObject[] {
    order.splice( order.indexOf( 'Phylum' ) ); //Removes levels unused in UI
    return order.map( r => buildRankOpt( r, ranks[ r ]! ) );
}
function buildRankOpt ( name: string, rank: RankData ): _t.OptionObject {
    return { text: name, value: rank.id };
}
/** Returns the options for the taxa in the group/root and rank. */
export function getTaxonOpts ( rank: string, g: string, sg: string ): OptReturn {   /*dbug-log*///console.log( 'getTaxonOpts. rank[%s] g[%s] sg[%s]', rank, g, sg ); console.trace()
    const optKey = getTxnOptDataKey( rank, g, sg );
    return getStoredOpts( null, optKey )
        .then( o => buildTaxonOpts( rank, o ) );
}
function buildTaxonOpts ( rank: string, optData: _t.OptionObject[] ): _t.OptionObject[] {
    const opts = initOptsWithCreate( rank );
    opts.push( ...alphabetizeOpts( optData ) );
    return opts;
}
function getTxnOptDataKey ( rank: string, group: string, root: string ): string {
    return group + root + rank + 'Names';
}
/** Builds opts for the GroupRoot combobox. */
export function getGroupRootOpts ( group: string ): OptReturn {
    return getStoredOpts( null, `${ group }GroupRootNames` );
}
export function getTaxonGroupFieldOpts ( field: 'Object' | 'Parent' | 'Subject' ): OptReturn {
    const prop = field === 'Subject' ? 'subjectNames' : 'groupNames';
    return getStoredOpts( null, prop );
}
/* -------------------------- LOCATION -------------------------------------- */
/** Returns options for each country and region. */
function getCntryRegOpts ( _: string ): OptReturn {
    const proms = [ 'Country', 'Region' ].map( getFieldOptions );
    return Promise.all( proms ).then( concatOpts );
}
function concatOpts ( data: _t.OptionObject[][] ): _t.OptionObject[] {
    const [ countries, regions ] = data;
    return countries!.concat( regions! );
}
/* ------------------------ INTERACTION ------------------------------------- */
/** Builds opts for the specified tag-type: ie, seasons. */
function getTagTypeOpts ( _: string, tagType: string ): OptReturn {
    return getData( 'tag' )
        .then( tags => buildTagTypeOpts( tags as _t.EntityRecords, tagType ) );
}
function buildTagTypeOpts ( tags: _t.EntityRecords, tagType: string ): _t.OptionObject[] {
    const opts: _t.OptionObject[] = [];
    _t.objectValues( tags ).forEach( ifTypeTagBuildOpt );
    return alphabetizeOpts( opts );

    function ifTypeTagBuildOpt ( tag: _t.EntityRecord ): void {
        if ( tag.type !== tagType ) return;
        opts.push( { text: tag.displayName!, value: tag.id.toString() } );
    }
}
/** ==================== HELPERS ============================================ */
export function getOptsFromStringArray ( strings: string[] ): _t.OptionObject[] {
    return strings.map( s => { return { text: s, value: s }; } );
}
export function alphabetizeOpts ( opts: _t.OptionObject[] ): _t.OptionObject[] {
    return opts.sort( alphaOptionObjs );
}
function alphaOptionObjs ( a: _t.OptionObject, b: _t.OptionObject ): -1 | 0 | 1 {
    const x = a.text.toLowerCase();
    const y = b.text.toLowerCase();
    return x < y ? -1 : x > y ? 1 : 0;
}