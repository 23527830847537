/**
 * Basic configuration for the pop-out panels.
 */

export function getPanelConfigs() {
    return  {
        filter: {
            bttn: '#filter',
            key: 'filter',
            id: '#filter-pnl',
            onClose: Function.prototype,
            tab: '#filter-opts',
            tabClass: 'hide-fltr-bttm-border'
        },
        lists: {
            bttn: '#lists',
            key: 'lists',
            id: '#list-pnl',
            onClose: Function.prototype,
            tab: '#list-opts',
            tabClass: 'hide-int-bttm-border'
        },
        review: {
            bttn: '#rvw-data',
            key: 'review',
            id: '#review-pnl',
            onClose: Function.prototype, // Added later
            tab: '#data-opts',
            tabClass: 'hide-rvw-bttm-border'

        }
    };
}