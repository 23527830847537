/**
 * On Explore page load, all server-data updated since the last local-data update
 * is downloaded.
 *
 * Export
 *     syncLocalDbWithServer
 *
 * TOC
 *     INIT SYNC
 *     SYNC LOCAL STORAGE
 *         SYNC USER DATA
 *         SYNC ENTITY DATA
 *     ON SYNC COMPLETE
 */
import { getAndSetData } from '@localdata/init';
import * as util from '@localdata/util';
import { logInDevEnv } from '@util';
import * as _t from '@types';
import { ifFailuresSendReport } from '../update';
import { downloadAndStoreUpdatedData } from './sync-updated-data';

let debugSync = false; // Allows sync process to repeat for debugging
/* ======================== INIT SYNC ======================================= */
export function syncLocalDbWithServer ( lclState: _t.EntityStateDates ): Promise<void> {
    /*perm-log*/logInDevEnv( "   /--syncLocalDbWithServer. lclState[%O]", lclState );
    return util.getData( ['hasQuarantined', 'user'], true )
        .then( d => syncLocalDbForCurrentUser( d.hasQuarantined as boolean, d.user as _t.User ) );

    function syncLocalDbForCurrentUser ( hasQ: boolean, dbUser: _t.User ): Promise<void> {
        /*dbug-log*///console.log(' -- syncLocalDbForCurrentUser dbUser[%O] hasQuarantined?[%s]', dbUser, qData);
        return util.initMemoryDataObj( 'pageLoadSync' )
            .then( () => updateLocalDataStorage( lclState, hasQ, dbUser ) )
            .then( () => util.clearTempMemory( 'pageLoadSync' ) );
    }
}
/* ======================== SYNC LOCAL STORAGE ============================== */
/**
 * If the user changes, user data is updated, and if the database had quarantined
 * data, local storage is completely reset. Otherwise, local-storage is synced
 * to the server data.
 */
function updateLocalDataStorage (
    lclState: _t.EntityStateDates,
    hasQ: boolean,
    dbUser: _t.User
): Promise<void|boolean> | void {
    const userChanged = !isExpectedUser( dbUser.username );
    if ( userChanged && hasQ ) return util.resetStoredData();
    return syncWithServer( lclState, userChanged );
}
function syncWithServer (
    lclState: _t.EntityStateDates,
    userChanged: boolean
): Promise<void> {
    return updateLocalUserData( userChanged )
        .then( () => syncLocalData( lclState ) );
}
/* -------------------------- SYNC USER DATA -------------------------------- */
function isExpectedUser ( dbUsername: string | null ): boolean {
    const curName = $( 'body' ).data( 'user-name' );
    const isExpected = curName ? curName === dbUsername : !dbUsername;
    return isExpected;
}
function updateLocalUserData ( userChanged: boolean ): Promise<void> {
    if ( !userChanged ) { return Promise.resolve(); }
    return fetchAndStoreUserData()
        .then( util.setNewDataInLocalStorage );
}
function fetchAndStoreUserData () {
    return ['user', 'review'].reduce( ( p, url ) => {
        return p.then( p => getAndSetData( url ) );
    }, Promise.resolve() );
}
function syncLocalData ( lclState: _t.EntityStateDates ) {             /*dbug-log*///console.log(' -- syncLocalData  lclState[%O]', lclState);
    return util.fetchDataForLocalStorage( 'data-state' )
        .then( handleSync );

    function handleSync ( srvrStateResults: { state: _t.EntityStateDates; } ) {
        return syncLocalDatabase( srvrStateResults.state, lclState )
            .then( success => { // If an error occurred during sync, an alert is shown to the user.
                if (success && !debugSync) util.setData( 'lclDataUpdtdAt', srvrStateResults.state )
            });
    }
}
function syncLocalDatabase (
    srvrState: _t.EntityStateDates,
    lclState: _t.EntityStateDates
): Promise<boolean> {                                                  /*dbug-log*///console.log('syncLocalDatabase. srvrState = %O, lcl = %O', srvrState, lclState);
    if ( ifTesting( srvrState ) ) return util.resetStoredData();
    const entities = getEntitiesWithUpdates( srvrState, lclState );
    return entities.length ? syncDb( entities ) : Promise.resolve(true);
}
/** Db is reset unless testing suite did not reload database. */
function ifTesting ( systemUpdateAt: _t.EntityStateDates ): boolean {
    if ( systemUpdateAt.System ) return systemUpdateAt.System == "2020-05-20 11:11:11";
    console.error( 'System update time is not found.' );
    return false;
}
/* ------------------------ SYNC ENTITY DATA -------------------------------- */
export type EntitySyncData = { name: string, updated: string; };
function getEntitiesWithUpdates (
    srvrState: _t.EntityStateDates,
    lclState: _t.EntityStateDates
): EntitySyncData[] {                                               /*dbug-log*///console.log('getEntitiesWithUpdates. srvrState = %O, lcl = %O', srvrState, lclState);
    return _t.objectKeys( srvrState )
        .map( entity => ifUpdatesBuildSyncData( entity, srvrState[entity], lclState[entity] ) )
        .filter( _t.isTruthy );
}
function ifUpdatesBuildSyncData (
    entity: string,
    srvrDatetime: string | undefined,
    lclDatetime: string | undefined
): false | EntitySyncData {                                         /*dbug-log*///console.log('   --[%s] updates ? ', entity, entityHasUpdates(srvrState[entity], lclState[entity]));
    return hasUpdates( entity, srvrDatetime, lclDatetime ) ?
        { name: entity, updated: lclDatetime! }
        : false;
}
function hasUpdates (
    entity: string,
    srvrDatetime: string | undefined,
    lclDatetime: string | undefined
): boolean {
    if ( srvrDatetime && lclDatetime ) {
        return entity !== 'System' && entityHasUpdates( srvrDatetime, lclDatetime );
    } else {
        console.error( 'Entity update time not found' );
        return false;
    }
}
/**
 * Returns true if the first datetime is more recent than the second.
 * Note: for cross-browser date comparison, dashes are be replaced with slashes.
 */
function entityHasUpdates (
    timeOne: string,
    timeTwo: string
): boolean {
    const time1 = timeOne.replace( /-/g, '/' );
    const time2 = timeTwo.replace( /-/g, '/' );                     /*dbug-log*///console.log("firstTimeMoreRecent? ", Date.parse(time1) > Date.parse(time2))
    return Date.parse( time1 ) > Date.parse( time2 );
}
/** Note: Sub-entity data is downloaded first, then interactions, then review-entries. */
function syncDb ( entities: EntitySyncData[] ): Promise<boolean> {
    return downloadAndStoreUpdatedData( entities )
        .then( ifFailuresSendReport )
        .then( hasFailures => !hasFailures );
}