import { components, model, state } from '@dataentry';
/* ================ HIGHLIGHT EMPTY-FIELDS ================================== */
/** Highlights remaining empty fields once all required fields are filled. */
export function ifReqFieldsFilledHighlightEmptyAndPrompt( fLvl: model.FormGroup ): void {
    if ( isInvalidFormState( fLvl ) ) return hideEmptyFieldMessage();
    const empty = toggleHightlightAndReturnEmptyFieldCount( fLvl );
    toggleFormEmptyFieldMessage( fLvl, empty );
}
function isInvalidFormState( fLvl: model.FormGroup ): boolean {
    return !components.areRequiredFieldsFilled( fLvl ) || state.isReviewForm( fLvl );
}
/* ---------------------- TOGGLE FIELD-HIGHLIGHT ---------------------------- */
/** Adds hightlight style class to empty fields. Removes highlight when filled. */
function toggleHightlightAndReturnEmptyFieldCount( fLvl: model.FormGroup ): number {
    const $cntnr = $( `#Citation_fields div.${ fLvl }_f` );
    return $cntnr.toArray().reduce( toggleHightlightAndTrackEmptyCount, 0 );
}
function toggleHightlightAndTrackEmptyCount( count: number, fieldContainer: HTMLElement ): number {
    const field = fieldContainer.children[ 1 ] as HTMLElement;
    if ( ifSkipField( field ) ) return count;
    const hasValue = ( field.children[ 1 ] as HTMLInputElement ).value;
    toggleClass( !hasValue, field );
    return hasValue ? count : ++count;
}
function ifSkipField( field: HTMLElement ): boolean {
    const $label =  $( field.children[ 0 ] as HTMLElement );
    return $label.hasClass( 'required' ) || [ 'Citation' ].indexOf( $label.text() ) !== -1;
}
function toggleClass( add: boolean, el: HTMLElement ) {
    if ( add ) $( el ).addClass( 'warn' );
    else $( el ).removeClass( 'warn' );
}
/* -------------------- TOGGLE EMPTY-FIELD MESSAGE -------------------------- */
function toggleFormEmptyFieldMessage( fLvl: model.FormGroup, empty: number ): void {
    return empty ? showEmptyFieldMessage( fLvl ) : hideEmptyFieldMessage();
}
function hideEmptyFieldMessage() {
    if ( $( '.warn-msg' ).length ) $( '.warn-msg' ).remove()
}
function showEmptyFieldMessage( fLvl: model.FormGroup ): void {
    if ( $( '.warn-msg' ).length ) return;
    const msg = '<div class="warn-msg warn">Please add highlighted data if available.</div>';
    $( `#${ fLvl }-submit` ).before( msg );
}