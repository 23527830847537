/**
 * Returns the first taxon ReviewEntry that is not dependant on a parent ReviewEntry.
 * Parents must be reviewed and approved before pending child-taxa can be.
 *
 * Export
 *     getFirstValidRevewEntry
 */
import { components, state, trans } from '@dataentry';

let fieldEntry;
/**
 * Returns the first taxon ReviewEntry that is not dependant on a parent ReviewEntry
 * with additional instance properties .
 */
export function getFirstValidRevewEntry( fEntry ) {                 /*dbug-log*///console.log( ' -- getFirstValidRevewEntry fieldEntry[%O]', fEntry );
    fieldEntry = fEntry;
    return getNextTaxonReviewEntry( fieldEntry );
}
function getNextTaxonReviewEntry( txnEntry, hasPendingChild = false ) {
    const prnt = getPendingParent( txnEntry );                      /*dbug-log*///console.log( '       -- getNextTaxonReviewEntry txnEntry[%O] prnt[%O]', txnEntry, prnt );
    if ( prnt ) return handleParent( prnt );
    const rEntry = !hasPendingChild ? fieldEntry : prepareParentEntry( txnEntry );
    return updateSubFields( rEntry );
}
/** --------------------- GET PARENT RCRD ----------------------------------- */
function getPendingParent( txnEntry ) {
    const review = txnEntry.form.fields.Parent.review;
    if ( !review ) return false;
    const rEntry = state.getRecords( 'reviewEntry', review.id );     /*dbug-log*///console.log( '       -- getPendingParent? [%O]', rEntry )
    return state.isReadyToReview( rEntry ) ? rEntry : handleReviewed( rEntry );
}
/** --------------------- HANDLE REVIEWED ----------------------------------- */
/**
 * When a parent taxon is in an incompatible review stage, the field combo is set
 * and the review process continues.
 */
function handleReviewed( rEntry ) {                                 /*dbug-log*///console.log( '       -- handleReviewed? rEntry[%O]', rEntry )
    return rEntry.entityId ? false : setComboAndSkipFieldReview( rEntry );
}
function setComboAndSkipFieldReview( rEntry ) {
    components.setReviewStageOption( fieldEntry.inst.field, rEntry.stage.name );
    const fPend = state.getFieldState( 'top', fieldEntry.inst.field, 'review' );
    fPend.inst.rvwAction = { name: rEntry.stage.name };
    rEntry.skip = true;
    return rEntry;
}
/** ----------------------- HANDLE PARENT ----------------------------------- */
function handleParent( prnt ) {
    return prnt.skip ? prnt : getNextTaxonReviewEntry( prnt, true );
}
function prepareParentEntry( txnEntry ) {
    const prntEntry = { ...txnEntry, inst: { ...fieldEntry.inst } };
    prntEntry.inst.pChild = true;                                   /*dbug-log*///console.log( '      -- prntRcrd [%O]', prntEntry );
    return prntEntry;
}
/** ----------------------- UPDATE FIELDS ----------------------------------- */
/** Updates ReviewEntry fields with the latest data. */
function updateSubFields( rEntry ) {                                /*dbug-log*///console.log( ' -- updateSubFields rEntry[%O]', rEntry );
    trans.updateReviewEntryFields( rEntry.form.fields );
    return rEntry;
}
