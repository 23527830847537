/**
 * The table interactions can be filtered by any/all taxon groups.
 *
 * Export
 *      initGroupFilterCombobox
 *      resetGroupFilter
 *
 * TOC
 *      INIT COMBOBOX
 *      APPLY FILTER
 *      CLEAR FILTER
 */
import { getOptions, getSelVal, initCombobox, isComboActive, resetCombobox, setSelVal } from '@elems/combo';
import { getState } from '@explore/table';
import { getFilterField, newSel, onFilterChangeUpdateRowData, setFilterState } from '@explore/table/filter';
import { fadeTable, showTable } from '@explore/ui';

let allOpts;
let timeout;
/* ====================== INIT COMBOBOX ===================================== */
export function initGroupFilterCombobox() {                         /*dbug-log*///console.log('-- initGroupFilterCombobox data[%O] allGroupOpts[%O]', t, allOpts);
    if ( $( '#sel-TaxonGroupFilter' ).length ) return; //already initialized
    const groups = getState( 'data' ).groupNames;
    allOpts = getOptions( groups, Object.keys( groups ).sort() );/*dbug-log*///console.log('-- initGroupFilterCombobox data[%O] allGroupOpts[%O]', t, allOpts);
    $( '#top-default-filters' ).append( getGroupFilter() );
    finishGroupComboInit();
}
function getGroupFilter () {
    const sel = newSel( allOpts, 'fill-w group-filter', 'sel-TaxonGroupFilter' );
    const filter = getFilterField( 'Group', sel );
    filter.id = 'groupFilterCntnr'; //necessary for tutorial
    return filter;
}
function finishGroupComboInit() {
    const confg = {
        name: 'Taxon Group Filter',
        maxItems: null,
        onChange: filterTableByGroups,
    };
    initCombobox( confg );
}
/* ====================== APPLY FILTER ====================================== */
function filterTableByGroups ( groupIds ) {                            /*dbug-log*///console.log('filterTableByGroups args = %O', arguments);
    fadeTable();
    if ( !timeout ) timeout = setTimeout( filterByGroups, 1000 );
}
function filterByGroups() {
    timeout = null;
    const groupIds = getFilterGroupIds()
    ifAllGroupsSelectedClearFilterCombo( groupIds.length );
    updateGroupFilterState( groupIds );
    onFilterChangeUpdateRowData();
    showTable();
}
function getFilterGroupIds() {
    const selected = getSelVal( 'TaxonGroupFilter' );
    if ( !selected.length ) return [];
    const filterOn = [ ...selected, ...getBaseGroupId() ]; //Potential duplicates allow for bat->bat searches
    setSelVal( 'TaxonGroupFilter', filterOn, true );
    return filterOn;
}
function getBaseGroupId() {
    const tblFocus = getState( 'curFocus' );
    const base = [ getState( 'data' ).groupNames.Bat ];
    if ( tblFocus === 'taxa' ) base.push( getSelVal( 'View' ) );
    return base;
}
function ifAllGroupsSelectedClearFilterCombo ( selectedGroupCnt ) {
    const selApi = $( '#sel-TaxonGroupFilter' )[ 0 ].selectize;
    const total = selApi.currentResults.total;                      /*dbug-log*///console.log('selectedGroupCnt [%s] !== total [%s]', selectedGroupCnt, total, selectedGroupCnt !== total)
    if ( selectedGroupCnt && selectedGroupCnt !== total ) return;
    selApi.clear();
}
function updateGroupFilterState ( groupIds ) {
    const state = { 'TaxonGroup': groupIds.length ? groupIds : false };
    setFilterState( 'combo', state, 'direct' );
}
/* ====================== CLEAR FILTER ====================================== */
export function resetGroupFilter() {
    if ( !isComboActive( 'TaxonGroupFilter', true ) ) return; //Table init before filter init complete
    resetCombobox( 'TaxonGroupFilter' );
}