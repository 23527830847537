/**
 * Initializes the create, edit, or review form for an entity.
 * Note - required form methods: initCreateForm, initEditForm, initReviewForm
 */
import * as components from '@dataentry/components';
import { showPagePopup } from '@elems';
import { lcfirst } from '@util';
import * as controller from '../controller';
/* =========================== INIT ========================================= */
/* ------------------------ CREATE ------------------------------------------ */
export function createEntity( entity, val ) {                       /*dbug-log*///console.log( '--createEntity  entity[%s] text?[%s]', entity, val );
    if ( entity === 'Interaction' ) showOverlay();
    return createEntityType( entity, entity, val );
}
/**
 * Handles create init for simple entities and those with specific types: new
 * taxa at specified ranks and sources with their detail types (eg, citation and author).
 */
export function createEntityType( type, entity, val ) {
    const createFunc = `${ lcfirst( entity ) }CreateForm`;
    return Promise.resolve( controller[ createFunc ]( type, val ) )
        .then( () => components.toggleWaitOverlay( false ) );
}
/* ------------------------- EDIT ------------------------------------------- */
export function editEntity( entity, val ) {
    showOverlay();
    return editEntityType( entity, entity, val );
}
export function editEntityType( type, entity, val ) {
    const editFunc = `${ lcfirst( entity ) }EditForm`;
    return controller[ editFunc ]( type, val )
        .then( () => fillEntitySidePanel( entity, val ) )
        .then( () => components.fillComplexFormFields( 'top' ) )
        .then( () => components.ifFormValidClearAlertsAndEnableSubmit( 'top' ) )
        .then( () => components.toggleWaitOverlay( false ) );

}
function fillEntitySidePanel( entity, id ) {
    if ( entity === 'Interaction' ) return;
    components.fillEditEntitySidePanel( id );
}
/* ------------------------- REVIEW ----------------------------------------- */
export function reviewEntity( rEntry ) {                             /*dbug-log*///console.log( '--reviewEntity  core[%s] data[%O]', rEntry.payload.core, rEntry );
    const reviewFunc = `${ rEntry.payload.core }ReviewForm`;
    return controller[ reviewFunc ]( rEntry );
}
/* =========================== UTIL ========================================= */
function showOverlay() {
    showPagePopup();
    components.toggleWaitOverlay( true );
}
/* ----------------------- CLEAR MEMORY ------------------------------------- */
export function clearEntityFormMemory( entity ) {
    const map = {
        interaction: controller.resetTypeData
    };
    if ( !map[ entity ] ) return;
    map[ entity ]();
}