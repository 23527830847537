/**
 * Miscellaneous util methods.
 *
 * TOC
 *    INIT
 *    LOG
 *    NUMBER
 *    OBJECT
 *    STRING
 *    USER ROLE
*/
import * as _t from '@types';
import extendPrototypes from './extend';
/* ========================= INIT =========================================== */
export function initPrototypeExtensions (): void {
    extendPrototypes();
}
/* ========================= LOG ============================================ */
export function logInDevEnv ( message: string, ...args: any[] ): void {
    const env = $( 'body' ).data( 'env' ) === 'test' ? 'test' : 'dev';
    log( env, message, ...args );
}
export function logInProdEnv ( message: string, ...args: any[] ): void {
    log( 'prod', message, ...args );
}
function log ( env: 'dev' | 'prod' | 'test', message: string, ...args: any[] ) {
    if ( $( 'body' ).data( 'env' ) !== env ) return;
    console.log( message, ...args );
}
/* ========================= NUMBER ========================================= */
/** Returns a random number between min (inclusive) and max (exclusive) */
export function getRandomInt ( min: number, max: number ): number {
    return Math.round( Math.random() * ( max - min ) + min );
}
/* ========================= OBJECT ========================================= */
export function cloneObj<T extends object> ( obj: T ): T {
    return JSON.parse( JSON.stringify( obj ) );
}
//todo: when refactoring into TS, fix all the janky typing where this is used
export function isObj ( v: any ): v is object {
    return v && v.constructor && v.constructor.name === 'Object';
}
export function isOptionObject ( obj: any ): obj is _t.OptionObject {
    if ( !isObj( obj ) ) return false;
    return ( 'text' in obj && obj.text && 'value' in obj && obj.value ) as boolean;
}
/* ========================= STRINGS ======================================== */
export function ucfirst ( str: string ): string {
    return str.charAt( 0 ).toUpperCase() + str.slice( 1 );
}
export function lcfirst <T extends string>( str: string ): T {
    const f = str.charAt( 0 ).toLowerCase();
    return ( f + str.substr( 1 ) ) as T;
}
/** Removes white space at beginning and end, and any ending period. */
export function stripString ( text: string, preservePeriod = false ): string {
    const str = text?.trim();
    if ( preservePeriod || !str ) return str;
    return str.charAt( str.length - 1 ) === '.' ? str.slice( 0, -1 ) : str;
}
export function addSpaceBetweenCamelCaseUnlessHyphen ( str: string ): string {
    if ( str.includes( '-' ) ) { return str; }
    return str.replace( /([A-Z])/g, ' $1' ).trim();
}
export function isStringAndNaN ( val: any ): val is string {
    return typeof val === 'string' && isNotNumber( val );
}
export function isNotNumber( val: any ): boolean {
    return Number.isNaN( parseInt( val ) )
}
/**
 * Removes the asterisk, indicating the quarantined state of the entity, from the
 * name.
 */
export function getEntityName( name: string ): string {
    return name.startsWith( '*' ) ? name.slice( 1 ) : name
}
/* ========================= USER ROLE ====================================== */
export function getUserRole (): string {
    return $( 'body' ).data( 'user-role' );
}
export function isAdminUser (): boolean {
    const admin = [ 'super', 'admin' ];
    return admin.indexOf( getUserRole() ) !== -1;
}
export function isManagerUser (): boolean {
    const canManageData = [ 'super', 'admin', 'manager' ];
    return canManageData.indexOf( getUserRole() ) !== -1;
}
export function isEditorUser (): boolean {
    const canEdit = [ 'super', 'admin', 'editor', 'contributor', 'manager' ];
    return canEdit.indexOf( getUserRole() ) !== -1;
}
export function isContributorUser (): boolean {
    return getUserRole() === 'contributor';
}