/**
 * Resets the interactions form leaving only the pinned values. Displays a
 * success message. Disables submit button until any field is changed.
 *
 * Export
 *     resetInteractionForm
 *
 * TOC
 *     HANDLE INTERACTION-FORM RESET
 *         HANDLE PERSISTED FIELDS
 *     CLEAR FIELD DATA
 *         CLEAR FIELD-TYPE
 *         FIELD-SPECIFIC CLEAR
 *     RESET FORM UI
 */
import * as combo from '@elems/combo';
import { cloneObj } from '@util';
import { components, state } from '@dataentry';
import * as iField from '../field';
import { clearCitationCombo } from '../field/controller';

let fields;
/* ==================== HANDLE INTERACTION-FORM RESET ============================= */
export function resetInteractionForm() {
    components.toggleFormStatusMsg( 'New Interaction successfully created.' );
    resetIntFields();
    resetFormUi();
    components.resetReviewSidePanel();
    components.toggleWaitOverlay( false );
}
/**
 * Resets the top-form in preparation for another entry. Pinned field values are
 * persisted. All other fields will be reset.
 */
function resetIntFields() {
    fields = state.getFormState( 'top', 'fields' );                 /*dbug-log*///console.log( '--resetInteractionForm fields[%O]', fields );
    Object.values( fields ).map( handleFieldDataReset );
}
function handleFieldDataReset( field ) {
    if ( !field.shown ) return;
    if ( !$( `#${ field.name }_pin` ).prop( 'checked' ) ) {
        clearField( field );
    } else {
        handePersistedField( field );
    }
}
/* --------------------- HANDLE PERSISTED FIELDS ---------------------------- */
function handePersistedField( field ) {                               /*dbug-log*///console.log( '--handePersistedField field[%s][%O]', field.name, cloneObj( field ) );
    const map = {
        InteractionType: ensureTypeTagsSelected
    }
    if ( !map[ field.name ] ) return;
    map[ field.name ]( field );
}
function ensureTypeTagsSelected( field ) {
    iField.onTypeSelection( combo.getSelVal( field.name ) );
}
/* ==================== CLEAR FIELD DATA ==================================== */
function clearField( field ) {                                        /*dbug-log*///console.log( '--clearField field[%s][%O]', field.name, cloneObj( field ) );
    clearFieldValue( field );
    handleFieldTypeClear( field );
    handleClearedField( field );
    state.replaceFieldState( 'top', field );
}
/* ------------------- CLEAR FIELD-TYPE ------------------------------------- */
function handleFieldTypeClear( field ) {
    const map = {
        page: clearTextareaField,
        select: clearComboField,
        tags: clearComboField,
        fullTextArea: clearTextareaField
    };
    if ( !map[ field.type ] ) return;
    map[ field.type ]( field );
}
function clearFieldValue( field ) {
    $( `#${ field.name }_pin` ).prop( 'checked', false );
    field.value = null;
}
function clearComboField( field ) {
    const persistOpts = [ 'Publication', 'Country-Region', 'Source', 'Season' ];
    combo.resetCombobox( field.name, persistOpts.indexOf( field.name ) === -1 );
}
function clearTextareaField( field ) {
    $( `#${ field.name }_f .f-input` ).val( "" );
}
/* -------------------- FIELD-SPECIFIC CLEAR -------------------------------- */
function handleClearedField( field ) {
    const map = {
        CitationTitle: clearSidePanelCitationDetails,
        InteractionType: clearTypeAndTags,
        Location: syncWithCountryField,
        Object: clearTaxonField,
        Source: ensureQuoteIsNotRequiredAndPrimarySelected,
        Date: iField.clearInteractionDate,
        Publication: clearCitationCombo,
        Subject: clearTaxonField,
    }
    if ( !map[ field.name ] ) return;
    map[ field.name ]( field );
}
function clearSidePanelCitationDetails( field ) {
    if ( !combo.getSelVal( 'Publication' ) ) return; //already cleared
    components.clearSidePanelDetails( 'cit' );
}
function clearTypeAndTags( field ) {
    iField.onTypeSelection( null );
}
function clearTaxonField( field ) {
    if ( [ 'Subject', 'Object' ].indexOf( field.name ) === -1 ) return;
    combo.replaceSelOpts( field.name, [] );
    combo.enableCombobox( 'InteractionType', false );
    $( '#sel-'+field.name ).data( 'selTaxon', false );
}
function syncWithCountryField( field ) {
    const cntryId = fields[ 'Country-Region' ].value;
    const cntry = cntryId ? state.getRecords( 'location', cntryId ) : null;
    iField.resetLocCombo( cntry );
    components.clearSidePanelDetails( 'loc' );
}
function ensureQuoteIsNotRequiredAndPrimarySelected( field ) {
    state.setFieldState( 'top', 'Quote', { required: false } );
    combo.setSelVal( 'Source', 'Primary' );
    field.value = 'Primary';
}
/* ==================== RESET FORM UI ======================================= */
function resetFormUi() {
    $( '#top-cancel' ).val( ' Close ' );
    components.toggleSubmitBttn( 'top', false );
    state.setFormState( 'top', { unchanged: true } );
}