/**
 * Handles initlization of the Explore page.
 *
 * EXPORT
 *     initPageUi
 *
 * TOC
 *     EXPLORE-PAGE INIT
 *         REQUIRES
 *         ON PAGE LOAD
 */
import * as combo from '@elems/combo';
import { getUserRole } from '@util';
import { table, ui } from '@explore';
/* ====================== EXPLORE-PAGE INIT ================================ */
/** Shows a loading popup message for the inital data-download wait. */
export function initPageUi () {
    requireScriptsAndStyles();
    ui.showExplorePopupMsg( 'Loading...' );
    initExplorePageUi();
}
/* ------------------------- REQUIRES --------------------------------------- */
function requireScriptsAndStyles() {
    requireCss();
    requireJs();
}
function requireCss() {
    require( 'styles/css/lib/ag-grid.css' );
    require( 'styles/css/lib/theme-fresh.css' );
    require( 'styles/pages/explore/explore.styl' );
}
function requireJs() {
    require( 'leaflet-control-geocoder' );
}
/* ------------------------- ON PAGE LOAD ----------------------------------- */
function initExplorePageUi() {
    const userRole = getUserRole();
    table.initRowToggleEvents();
    ui.addPanelEventsAndStyles( userRole );
    ui.initFeatureButtons( userRole );
    initExploreFocusAndViewCombos();
}
function initExploreFocusAndViewCombos () {                         /*dbug-log*///console.log( 'initExploreFocusAndViewCombos' )
    combo.initCombobox( { name: 'Focus', onBlur: true, onChange: table.buildTable } );
    combo.initCombobox( { name: 'View', onBlur: true, onChange: Function.prototype } );
}