/**
 * Shows alerts related to general features/validation in the data-entry forms.
 *
 * TOC
 *     FORM-INIT ALERT
 *         SUB-FORM ALREADY OPEN
 *     FORM SUBMIT
 *         DATA-PREPARATION
 *     DUPLICATE ENTITY
 *         GET EXISTING-ENTITY
 *         HANDLE REVIEW-REJECT
 *     DATA-STORAGE ALERT
 */
/* UTILS */
import { getElem, resetCombobox, triggerComboChangeReturnPromise } from '@elems';
import { resetStoredData } from '@localdata';
/* PAGE */
import { showIntroAndLoadingMsg } from '@explore/ui';
/* DATA-ENTRY */
import { handleFormClose } from '@dataentry/components';
import { addFormHandler, getFormState, replaceFormState } from '@dataentry/state';
import { submitDataReview } from '@dataentry/submit';
import * as alert from './alerts-main.js';
/* ===================== FORM-INIT ALERT ==================================== */
/**
 * When an issue prevents a form init, an alert is shown to the editor and the
 * combobox that triggered the form is reset.
 */
export function formInitAlert ( field, tag, fLvl ) {                   /*perm-log*/console.log( "       --[%s]formInitAlert [%s][%s]", fLvl, field, tag );
    const el = alert.getAlertElem( field, fLvl );
    alert.showAlert( fLvl, el, getInitAlertMsg( tag, fLvl, el ) );
}
function getInitAlertMsg ( tag, fLvl, el ) {
    const map = {
        forensicError: handleForensicErrorAndReturnAlertMsg,
        openSubForm: handleOpenSubFormAndReturnAlertMsg,
    };
    return map[ tag ]( el, fLvl );
}
function handleForensicErrorAndReturnAlertMsg ( el, fLvl ) {
    return `<p>There was an issue retrieving forensic data for this record. A
        report has been submitted.</p>`;
}
/* ----------------- SUB-FORM ALREADY OPEN ---------------------------------- */
/** A sub-form is already open. */
export function alertFormOpen ( fLvl ) {
    const field = getFormState( fLvl, 'combo' );                   /*dbug-log*///console.log( "       --open[%s]FormAlert [%s]", fLvl, field );
    window.setTimeout( () => resetCombobox( field ), 10 );
    return formInitAlert( field, 'openSubForm', fLvl );
}
function handleOpenSubFormAndReturnAlertMsg ( el, fLvl ) {
    window.setTimeout( () => alert.clearAlert( el, fLvl ), 2000 );
    return '<p>Please finish the open sub-form.</p>';
}
/* ========================= FORM SUBMIT ==================================== */
/** Alerts the editor to isues with the submit process. */
export function formSubmitError ( fLvl, tag = 'genSubmitErr' ) {       /*dbug-log*///console.log('    --formSubmitError fLvl[%s] tag[%s]', fLvl, tag);
    const el = alert.getAlertElem( null, fLvl );
    const handlers = getSubmitAlertHandlers( tag );
    alert.showAlert( fLvl, el, handlers.show( el, fLvl ), handlers.clear );
}
function getSubmitAlertHandlers ( tag ) {
    const map = {
        duplicateEntity: {
            show: handleDuplicateEntityndReturnAlertMsg,
            clear: clearDuplicateEntityAlert
        },
        duplicateName: {
            show: () => '<span>A record with this name already exists.</span>',
            clear: alert.clrFormLvlAlert
        },
        genSubmitErr: {
            show: () => '<span>An Error occured and the developer has been notified.</span>',
            clear: alert.clrFormLvlAlert
        },
        noChanges: {
            show: () => '<span>No changes detected.</span>',
            clear: alert.clrFormLvlAlert
        },
        unableToDelete: {
            show: () => '<span>Entity has dependent-data and can not be deleted.</span>'
        }
    };
    return map[ tag ];
}
/* ----------------------- DATA-PREPARATION --------------------------------- */
export function dataPrepFailure ( fails ) {                            /*perm-log*/console.log( '           !!!dataPrepFailure [%s]', JSON.stringify( fails ) );
    diableFormButtons();
    const cntnr = getElem( 'div', { class: 'flex-col', id: 'data_alert' } );
    $( cntnr ).append( [ getDataPrepFailAlert( fails ), buildResetButton( reloadPage ) ] );
    $( '#top-hdr' ).after( cntnr );
}
function getDataPrepFailAlert ( fails ) {
    return `<span>An error occured and the developer has been notified.
        <br>The page will be reloaded. If this error persists, please create a new
        Bug Report and include the following info:</span><br>
        <span><center>[${ JSON.stringify( fails ) }]</center></span>`;
}
function reloadPage () {
    location.reload( true );
}
export function getRequiredFieldsEmptyAleryMsg ( elem, fLvl ) {
    return `<span>Please fill required fields and submit again.</span>`;
}
export function handleInvalidRangeAndReturnAlertMsg ( elem, fLvl ) {
    alert.setOnChangeClearAlert( elem.id.split( '_alert' )[ 0 ], elem, fLvl );
    return '<span>Invalid range entered.</span>';
}
/* ======================= DUPLICATE ENTITY ================================= */
//todo1: If sources share a name but are of different entity types, the alert should just say the name is in use, not say 'select existing' and end up selecting a publication source instead of a citation
/**
 * Returns a message prompting the user to select existing entity. The alert is
 * cleared when any field changes, via the exit button, or when the user clicks
 * the text to select toe existing entity.
 */
function handleDuplicateEntityndReturnAlertMsg ( elem, fLvl ) {
    const fState = getFormState( fLvl );
    const entity = fState.misc.duplicateEntity;
    addTempHandlerToWindow();
    setOnFieldChangeClearAlert();                                   /*dbug-log*///console.log( '-- handleDuplicateEntityndReturnAlertMsg fState[%O]', fState );
    return getDuplicateEntityAlertMsg();
    /** Note: removed from window @clearDuplicateEntityAlert */
    function addTempHandlerToWindow () {
        const params = [ fLvl, entity.id, fState.combo, fState.review ];
        window.selectDuplicateEntity = selectDuplicateEntity.bind( null, ...params );
    }
    function setOnFieldChangeClearAlert () {
        const handleClear = clearDuplicateEntityAlert.bind( null, elem, fLvl );
        alert.setOnChangeClearAlert( fState.combo, elem, fLvl, handleClear );
    }
}
function getDuplicateEntityAlertMsg () {
    return `<p>Duplicate data detected.</p><p onClick="selectDuplicateEntity()"
        style="cursor:pointer;"> &nbsp;&nbsp; CANCEL CREATE AND <BR><u>SELECT EXISTING</u></p>`;
}
/* -------------------- GET EXISTING-ENTITY --------------------------------- */
function selectDuplicateEntity ( fLvl, id, field, rEntry ) {        /*dbug-log*///console.log( '-- selectDuplicateEntity [%s] field[%s] id[%s]', fLvl, field, id );
    const fState = getFormState( fLvl );
    addFormHandler( fLvl, 'beforeFormClose', getSelectFunc( fState ) );
    if ( rEntry ) {
        handleDuplicateRejected( fState );
    } else {
        handleFormClose( fLvl, false );
    }
    $( `#${ field }_pin` ).focus();
    delete window.selectDuplicateEntity;
}
function getSelectFunc ( fState ) {
    return setEntityInField.bind( null, fState.group, fState.combo, fState.misc.duplicateEntity.id );
}
/** Selects existing entity in the field's combobox. */
function setEntityInField ( fLvl, comboField, id ) {                   /*dbug-log*///console.log( "   --setEntityInField fLvl[%s] combo[%s] id[%s]", fLvl, comboField, id );
    return triggerComboChangeReturnPromise( comboField, id );
}
function clearDuplicateEntityAlert ( elem, fLvl ) {                 /*dbug-log*///console.log( "   --clearDuplicateEntityAlert fLvl[%s] elem[%O]", fLvl, elem );
    delete window.selectDuplicateEntity;
    alert.clrFormLvlAlert( elem, fLvl, true );
}
/* -------------------- HANDLE REVIEW-REJECT ------------------------------- */
/**
 * Handles rejecting the ReviewEntry and selecting the existing entity.
 * TODO2: Handle showing the rejected data to the contributor...
 */
function handleDuplicateRejected ( fState ) {                       /*dbug-log*///console.log( "   --handleDuplicateRejected fState[%O]", fState );
    logDataRejected( fState.group, fState.combo );
    fState.review.inst.rvwAction = { name: 'Reject' };
    fState.review.existingId = fState.misc.duplicateEntity.id;
    replaceFormState( fState );
    submitDataReview( fState );
}
function logDataRejected ( fLvl, comboField ) {
    const logMsg = 'DUPLICATE DATA DETECTED. REPLACED WITH EXISTING ENTITY.<br>';
    $( `#${ fLvl }_rvw-log` ).append( '>> ' + logMsg );
    if ( fLvl === 'top' ) return;
    $( `#${ fLvl }_rvw-log` ).append( `>> ${ comboField }: ${ logMsg }` );
}
/* ===================== DATA-STORAGE ALERT ================================= */
export function errUpdatingData ( errTag, errObj ) {                /*perm-log*/console.error( 'Error updating local storage [%s][%O]', errTag, errObj );
    diableFormButtons();
    const cntnr = getElem( 'div', { class: 'flex-col', id: 'data_alert' } );
    $( cntnr ).append( [ buildAlertMsg(), buildResetButton( reloadAndRedownloadData ) ] );
    $( '#top-hdr' ).after( cntnr );
}
function buildAlertMsg () {
    return `<span>An error occured and the developer has been notified.
        <br>All stored data will be redownloaded.</span>`;
}
function buildResetButton () {
    const confirm = getElem( 'span', {
        class: 'flex-row',
        'text': `Please click "OK" to continue.`
    } );
    const bttn = getElem( 'input', { type: 'button', value: 'OK', class: 'exit-bttn' } );
    $( bttn ).on( 'click', reloadAndRedownloadData );
    $( confirm ).append( bttn );
    return confirm;
}
function diableFormButtons () {
    $( '#top-submit, #top-cancel, #exit-form' ).off( 'click' ).css( 'disabled', 'disabled' )
        .fadeTo( '400', 0.5 );
}
function reloadAndRedownloadData () {
    handleFormClose( 'top', 'skipTableReset' );
    resetStoredData();
    showIntroAndLoadingMsg( 'taxa', true );
}