/**
 * Initializes the taxon forms.
 *
 * Export
 *     initCreateForm
 *     initEditForm
 *     initReviewForm
 *
 * TOC
 *     CREATE
 *         PARAMS
 *         FINISH BUILD
 *     EDIT
 *         PARAMS
 *         PARENT-TAXON SELECT
 *     REVIEW
 */
import { components, form, state } from '@dataentry';
import { cloneObj } from '@util';
import * as validate from '../validate';
import { reviewCreate, reviewEdit } from './review';
/* ========================= CREATE ========================================= */
export function initCreateForm ( rank, v ) {                        /*temp-log*///console.log( '           /--Taxon CREATE [%s]Form [%s]', rank, v );
    const val = v === 'create' ? '' : v;
    if ( validate.ifMissingParentTaxon( rank, 'sub2' ) ) return;
    return components.initSubForm( getCreateFormParams( rank, val, 'create' ) )
        .then( status => finishTxnCreateFormInit( status, rank ) );
}
/* -------------------------- PARAMS ---------------------------------------- */
function getCreateFormParams( rank, v, action ) {
    const params = {
        action: 'create',
        appendForm: form => $( `#${ rank }_f` ).append( form ),
        combo: rank,
        group: 'sub2',
        name: 'Taxon',
        style: 'sml-sub-form',
    };
    if ( action === 'create' ) params.vals = getTaxonCreateStateVals( v, rank );
    return params;
}
function getTaxonCreateStateVals( val, rank ) {
    return {
        DisplayName: val,
        Group: state.getFieldValue( 'sub', 'Group' ),
        Rank: rank,
        Parent: form.getSelectedTaxon( rank ).id,
        'Group-Root': state.getFieldValue( 'sub', 'Group-Root' ),
    };
}
/* ----------------------- FINISH BUILD ------------------------------------- */
function finishTxnCreateFormInit ( status, _rank ) {
    if ( !status ) return  //Error handled elsewhere
    initDisplayNameField( 'sub2' );
}
function initDisplayNameField ( fLvl ) {                            /*dbug-log*///console.log( '   --initDisplayNameField fLvl[%s]', fLvl );
    const $field = $( `#${ fLvl }-form #DisplayName_f input` );
    $field
        .change( validate.validateAndVerifyTaxonym.bind( null, fLvl ) )
        .focus();
    triggerOnChangeIfNameEntered( $field );
}
/**
 * Triggers the change event on the display name field if the field has a value.
 * This triggers the validation and verification of the taxon name before
 * enabling the submit button.
 */
function triggerOnChangeIfNameEntered ( $field ) {
    if ( $field.val() ) $field.change();
}
/* =========================== EDIT ========================================= */
export function initEditForm ( entity, id ) {                       /*temp-log*///console.log( '           >--Taxon EDIT [%s][%s]', entity, id );
    const p = getEditFormParams( id );                              /*dbug-log*///console.log( '   --params[%O]', p );
    return components.initForm( p )
        .then( finishEditFormBuild );
}
/* -------------------------- PARAMS ---------------------------------------- */
function getEditFormParams( id ) {                                  /*dbug-log*///console.log('--getEditFormParams id[%s]', id);
    return {
        action: 'edit',
        group: 'top',
        id: id,
        initCombos: initCombos,
        name: 'Taxon',
        style: 'sml-form',
    };
}
function initCombos() {
    const events = {
        Rank: { onChange: validate.onRankChangeValidate },
        Parent: { onChange: validate.onParentChange },
    };
    components.initFormCombos( 'top', events );
}
/* -------------------- PARENT-TAXON SELECT --------------------------------- */
function finishEditFormBuild ( status ) {                           /*dbug-log*///console.log( '--finishEditFormBuild status[%s]', status );
    if ( !status ) return; //Error handled elsewhere
    initDisplayNameField( 'top' );
    setSelectFormListener();
    handleParentTaxonInit();
}
function setSelectFormListener() {
    $( `#sel-Parent` )[ 0 ].selectize.on( 'focus', loadParentSelectForm );
}
function handleParentTaxonInit() {
    const parentId = state.getFieldValue( 'top', 'Parent' );        /*dbug-log*///console.log( '--handleParentTaxonInit parentId[%O]', parentId );
    if ( !parentId ) return;  //Null during review with pending-parent
    $( '#sel-Parent' ).data( 'selTaxon', parentId );
    form.buildOptAndUpdateCombo( 'Parent', parentId, 'silent' );
}
function loadParentSelectForm() {
    const gId = state.getFieldValue( 'top', 'Group' );
    const rId = state.getFieldValue( 'top', 'Group-Root' );         /*dbug-log*///console.log('--loadParentTaonxSelectForm g[%s] sg[%s]', gId, rId);
    form.initFieldTaxonSelect( 'Parent', gId, rId, validate.onParentChange );
}
/* ========================= REVIEW ========================================= */
export function initReviewForm( rEntry ) {
    const isCreate = rEntry.form.action === 'create';               /*temp-log*///console.log( '           >--Taxon REVIEW [%s] rEntry[%O]', ( isCreate ? 'CREATE' : 'EDIT' ), cloneObj( rEntry ) );
    const formHandlers = getTxnReviewFormHandlers( isCreate );
    const initTxnReview = isCreate ? reviewCreate : reviewEdit;
    return initTxnReview( rEntry, formHandlers );
}
function getTxnReviewFormHandlers( isCreate ) {
    return {
        getParams: isCreate ? getCreateFormParams : getEditFormParams,
        finishBuild: isCreate ? finishTxnCreateFormInit : finishEditFormBuild
    };
}