/**
 * Removes data invalidated by edits to the entity.
 *
 * Export
 *     removeInvalidatedData
 *     hasEdits
 *
 * TOC
 *     REMOVE ENTITY DATA
 *     REMOVE HANDLERS
 */
import * as _t from '@types';
import { DataEntryResults, EditObj } from '@localdata/sync/data-entry';
import { updateData } from '../execute-update';
import * as rmv from './rmv-funcs';

/* ======================== REMOVE ENTITY-DATA ============================== */
export function removeInvalidatedData ( data: DataEntryResults ): void {
    if ( !hasEdits( data.coreEdits ) ) return;
    const updateFuncs = getRmvDataPropHndlrs( data.core );
    updateDataProps( data.core, data.coreEntity, data.coreEdits, updateFuncs );
}
export function hasEdits ( edits: EditObj | undefined ): edits is EditObj {
    return edits ? Object.keys( edits ).length > 0 : false;
}
/* ------------------------ REMOVE HANDLERS --------------------------------- */
/** Returns an object with relational properties and their removal handlers. */
function getRmvDataPropHndlrs ( entity: _t.CoreEntity ): RemoveLocalDataConfig {
    return {
        interaction: {  //todo: lcfirst edited props? Why ucfirst?
            Location:  rmv.rmvIntAndAdjustTotalCnts,
            Source:  rmv.rmvIntFromEntity,
            Subject:  rmv.rmvIntFromTaxon,
            Object:  rmv.rmvIntFromTaxon,
        },
        location: {
            ParentLocation:  rmv.rmvFromParent,
        },
        source: {
            Contributor:  rmv.rmvContrib,
            ParentSource:  rmv.rmvFromParent,
        },
        taxon: {
            DisplayName:  rmv.rmvFromNameProp,
            Group:  rmv.rmvFromNameProp,
            ParentTaxon:  rmv.rmvFromParent,
            GroupRoot:  rmv.rmvFromNameProp,
            Rank:  rmv.rmvFromNameProp,
        }
    }[ entity ];
}
/* ======================== EXECUTE UPDATES ================================= */
type RemoveLocalDataConfig = {
    [key: string]: ( ...args: any[] ) => void;
};
// export type RemoveMethod = <T extends string, U extends string>(
//     prop: T,
//     rcrd: _t.EntityRecord,
//     entity: U,
//     edits: EditObj
// ) => void;
/** Updates relational storage props for the entity. */
function updateDataProps (
    entity: string,
    rcrd: _t.EntityRecord,
    edits: EditObj,
    updateFuncs: RemoveLocalDataConfig
): void {
    const params = { edits: edits, entity: entity, rcrd: rcrd, stage: 'rmvData' as const };
    _t.objectKeys( updateFuncs ).forEach( prop => {
        if ( !edits[ prop ] ) return;
        updateData( updateFuncs[ prop ]!, prop, params );
    } );
}