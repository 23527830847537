/**
 * The role-taxon select-form for the interaction fields, Subject and Object, and
 * to select a new parent taxon in the taxon edit-form.
 *
 * TOC
 *     ENABLE FIELDS
 *     FIELD FOCUS-LISTENERS
 *     SELECT ROLE-TAXON
 *     ON ROLE SELECTION
 */
import { alert, components, form, state } from '@dataentry';
import * as el from '@elems';
import { isNotNumber } from '@util';
import { focusPinAndEnableSubmitIfFormValid, initTypeField } from '..';
/* ======================= ENABLE FIELDS ==================================== */
export function enableTaxonFieldCombos ( fName ) {                     /*dbug-log*///console.log( '--enableTaxonFieldCombos fName?[%s]', fName );
    const fields = fName === 'Parent' ? [ fName ] : [ 'Subject', 'Object' ];
    fields.forEach( f => el.enableCombobox( f ) );
}
/* ====================== FIELD FOCUS-LISTENERS ============================= */
/** Displays the [Role] Taxon select form when the field gains focus. */
export function addRoleTaxonFocusListeners() {
    [ 'Subject', 'Object' ].forEach( addRoleFocusListener );
}
function addRoleFocusListener ( role ) {
    $( `#sel-${ role }` )[ 0 ].selectize.on( 'focus', loadFieldTaxonSelect.bind( null, role ) );
}
function loadFieldTaxonSelect ( role ) {
    if ( ifOppositeRoleFormLoading( role ) ) return alert.alertFormOpen( 'sub' );
    form.initFieldTaxonSelect( role );
}
function getOppositeRole ( role ) {
    return role === 'Subject' ? 'Object' : 'Subject';
}
/* ----------------- IF OPEN SUB-FORM ISSUE --------------------------------- */
function ifOppositeRoleFormLoading ( role ) {
    return $( '#sel-' + getOppositeRole( role ) ).data( 'loading' );
}
/* =================== SELECT FIELD-TAXON =================================== */
/** Adds the selected taxon to the interaction-form's [role]-taxon combobox. */
export function selectFieldTaxon ( e, selectRoot = false ) {           /*dbug-log*///console.log( '@--selectFieldTaxon selectRoot?[%s]', selectRoot );
    const taxon = selectRoot ? getRoot().taxon : form.getSelectedTaxon();/*dbug-log*///console.log( "--getSelectedTaxonOption taxon[%O]", taxon );
    const fieldKey = $( '#select-group' ).data( 'field' );
    updateCombo( fieldKey, getSelectedTaxonOption( taxon ) );
}
/** Returns an option object for the most specific taxon selected. */
function getSelectedTaxonOption ( taxon ) {
    if ( !taxon ) return; //issue alerted to developer and editor
    return buildTxnOpt( taxon );
}
function buildTxnOpt ( taxon ) {
    return { text: taxon.displayName, value: taxon.id };
}
function getRoot() {
    return state.getFieldState( 'sub', 'Group-Root', 'misc' );
}
export function buildOptAndUpdateCombo ( fieldKey, id, silent = false ) {/*dbug-log*///console.log( "--buildOptAndUpdateCombo fieldKey[%s] id[%s]", fieldKey, id );
    const taxon = state.getRecords( 'taxon' )[ id ];
    updateCombo( fieldKey, buildTxnOpt( taxon ), silent );
    el.enableCombobox( fieldKey );
}
function updateCombo ( fieldKey, opt, silent = false ) {
    el.replaceSelOpts( fieldKey, opt );
    el.setSelVal( fieldKey, opt.value, silent );

}
/* =================== ON ROLE SELECTION ==================================== */
/**
 * When complete, the select form is removed and the most specific taxon is displayed
 * in the form <field> combobox.
 * In the interaction form, when both roles are selected, the valid interaction
 * types for the taxon groups, in their respective roles, load.
 */
export function onTaxonFieldSelection ( fName, val ) {              /*temp-log*///console.log( "       +--onTaxon[%s]Selection [%s]", fName, val );
    if ( val === "" || isNotNumber( val ) ) return;
    components.exitFormElemAndClearState( 'sub' );
    storeFieldSelection( fName, val );
    enableTaxonFieldCombos( fName );
    // state.setFieldReviewEntry( 'top', fName, state.getRecords( 'taxon', val ) );
    if ( fName === 'Parent' ) return; //taxon edit-form
    focusPinAndEnableSubmitIfFormValid( fName );
    initTypeFieldIfBothTaxonRolesFilled();
}
function storeFieldSelection ( fName, val ) {
    $( '#sel-' + fName ).data( 'selTaxon', val );
    if ( fName === 'Parent' ) return; //taxon edit-form
    const root = state.getTaxonGroupRoot( val );
    state.setFieldState( 'top', fName, { misc: root } );        /*dbug-log*///console.log( '   --storeFieldSelection fName[%s] root[%O]', fName, root );
}
/* ---------------------- INTERACTION FORMS ONLY ---------------------------- */
function initTypeFieldIfBothTaxonRolesFilled() {
    const roleGroups = [ 'Su', 'O' ].map( getRoleRootId );
    if ( !roleGroups.every( i => i ) ) return;
    initTypeField( roleGroups );
}
function getRoleRootId ( pref ) {
    const root = state.getFieldState( 'top', `${ pref }bject`, 'misc' );/*dbug-log*///console.log( '--getRoleRootId root[%O]', root );
    return root ? root.id : false;
}