/**
 * Handles the map for the data-entry form.
 *
 * EXPORT
 *     initFormMap
 *     addFormMapPin
 *
 * TOC
 *     INIT FORM MAP
 *         FINISH FORM MAP
 *     VOLATILE PINS
 *         EDIT FORM
 *         CREATE FORM
 */
import { setMapState, setMapView } from '@explore/map/manager.js';
import * as mapUtil from '@explore/map/util';
import { handleMapInit } from '@explore/map/init';
import * as mapEl from './map-elems.js';
import { geocodeInForm, removePreviousMapPin } from './geocode-form.js';
/* ====================== INIT FORM MAP ===================================== */
/** Types: create, edit, int */
export function initFormMap ( data ) {                              /*dbug-log*///console.log( '+--initFormMap data[%O]', data );
    setFormMapState( data );
    const finishMap = finishFormMap.bind( null, data.pId, data.type );
    handleMapInit( finishMap, 'form-map', data.type );
}
function setFormMapState( data ) {
    setMapState( 'formConfig', data.formConfig );
}
/* --------------------------- FINISH FORM MAP ------------------------------ */
function finishFormMap ( parentId, type ) {                         /*dbug-log*///console.log( '--finishFormMap. pId [%s], type [%s]', parentId, type );
    mapEl.addLocCountLegend();
    if ( type === 'int' ) mapEl.addNewLocBttn();
    if ( !parentId ) return;
    mapUtil.addParentLocDataToMap( parentId, type );
    $( '#form-map' ).data( 'loaded', true );
    $( '#form-map' ).data( 'type', type );
}
/* ======================= VOLATILE PINS ==================================== */
export function addFormMapPin ( val, type, cntryId ) {              /*dbug-log*///console.log( '--addFormMapPin' )
    if (!navigator.onLine) return;
    if ( !val ) return removePreviousMapPin();
    const latlng = getMapPinCoords();                               /*dbug-log*///console.log( 'latlng[%O]', latlng );
    if ( type === 'edit' ) addEditFormMapData( latlng, val, cntryId );
    else addNewLocPinAndFillCoordFields( latlng ); //CREATE-FORM
    $( '#cnt-legend' ).html( '' );
}
function getMapPinCoords () {                                        /*dbug-log*///console.log( 'Lat: [%s], Lng: [%s]', $( '#Latitude_f input' ).val(), $( '#Longitude_f input' ).val() )
    return L.latLng( $( '#Latitude_f input' ).val(), $( '#Longitude_f input' ).val() );
}
/* ------------------------ EDIT FORM --------------------------------------- */
function addEditFormMapData ( latlng, locId, cntryId ) {
    if ( latlng ) geocodeInForm( latlng, 'edit' );
    if ( !cntryId ) return;
    mapUtil.addParentLocDataToMap( cntryId, 'edit', 'skipZoom', locId );
    setMapView( latlng, 10 );
}
/* ------------------------ CREATE FORM ------------------------------------- */
function addNewLocPinAndFillCoordFields ( latlng ) {
    geocodeInForm( latlng );
    fillCoordFields( latlng );
}
function fillCoordFields ( latlng ) {                               /*dbug-log*///console.log( 'fillCoordFields latlng = %O', latlng );
    $( '#Latitude_f input' ).val( latlng.lat.toFixed( 5 ) );
    $( '#Longitude_f input' ).val( latlng.lng.toFixed( 5 ) );
}