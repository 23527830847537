import { FormConfig,ModelFormFields } from '../types';
/* ============================ TYPES ======================================= */
interface LocationFormConfig extends FormConfig {
    misc: {
        territories: Record<string, string[]>;
    };
}
/* ============================ CONFIG ====================================== */
/**
 * Location form configuration.
 */
export default function( entity: string ): LocationFormConfig {
	return {
        records: {
            edit: [ 'geoJson', 'location' ]
        },
        fields: getLocationFieldConfig(),
        name: entity,
        misc: {
            territories: {
                Australia: [
                    "Christmas Island",
                    "Cocos (Keeling) Islands",
                    "Norfolk Island"    ],
                France: [
                    "French Polynesia [includes the island groups of the Marquesas, Society, Tuamotu and Tubai]",
                    "French Southern and Antarctic Lands",
                    "Guadeloupe",
                    "Martinique",
                    "Mayotte",
                    "New Caledonia",
                    "Réunion",
                    "Saint Pierre and Miquelon",
                    "Wallis and Futuna" ],
                Netherlands: [
                    "Aruba",
                    "Curacao",
                    "Sint Maarten"  ],
                Norway: [
                    "Jan Mayen Island",
                    "Svalbard"  ],
                "United States": [
                    "American Samoa",
                    "Guam",
                    "U.S. Minor Outlying Islands [includes the Howland-Baker, Johnston, Midway, Navassa, Line and Wake island groups, etc.]",
                    "Northern Mariana Islands",
                    "Puerto Rico",
                    "Virgin Islands, U.S."
                ]
            }
        },
        layouts: {
            all: [
                [ 'DisplayName', 'Country' ],
                [
                    {   class: 'w-half',
                        fields: [
                        [ 'Latitude', 'Longitude' ],
                        [ 'Elevation', 'ElevationMax' ] ]
                    },
                    {   class: 'w-half',
                        fields: [ 'HabitatType','Description' ] }
                ]
            ]
        }
    };
}
function getLocationFieldConfig(): ModelFormFields {
    return {
        Country: {
            entity: 'Location',
            name: 'Country',
            required: true,
            server: {
                prep: {
                    setParent: [ 'Location' ]
                },
                prop: {
                    core: 'parent'
                },
            },
            type: 'select',
        },
        DisplayName: {
            info: {
                intro: `Use the formal name of the location. If it doesn’t have a formal
                    name, use the following format to create a unique name using as
                    many descriptors as applicable: <br> [Habitat type], [Landmark, or
                    “Near” Landmark], [Town/City, or “Near” Town/City], [Province or State] `,
                tooltip: 'Use the formal name of the location. If it doesn’t ' +
                    'have a formal name, use the following format to create a unique ' +
                    'name using as many descriptors as applicable: [Habitat type], ' +
                    '[Landmark, or “Near” Landmark], [Town/City, or “Near” Town/City], ' +
                    '[Province or State]',
            },
            label: 'Name',
            name: 'DisplayName',
            required: true,
            server: {
                prop: {
                    core: 'displayName'
                },
            },
            type: 'text',
        },
        Description: {
            name: 'Description',
            server: {
                prop: {
                    core: 'description'
                },
            },
            type: 'textArea',
        },
        Elevation: {
            class: 'w-half',
            info: {
                tooltip: 'If an elevation range is provided, put the uppermost ' +
                    'elevation here.',
            },
            label: 'Elev (m)',
            name: 'Elevation',
            server: {
                prop: {
                    core: 'elevation'
                },
            },
            type: 'num',
        },
        ElevationMax: {
            class: 'w-half',
            label: 'Elev Max',
            name: 'ElevationMax',
            server: {
                prep: {
                    setElevationRange: []
                },
                prop: {
                    core: 'elevationMax'
                },
            },
            type: 'num',
        },
        GeoJson: {
            misc: {}, // rcrd: geoJsonRcrd used in edit form
            name: 'GeoJson'
        },
        HabitatType: {
            entity: 'HabitatType',
            info: {
                intro: 'See Habitat Type Definitions <a href="/definitions" ' +
                    'target="_blank">here</a>.',
                tooltip: 'See Habitat Type Definitions under About in the site menu.'
            },
            name: 'HabitatType',
            server: {
                prop: {
                    core: 'habitatType'
                },
            },
            type: 'select',
        },
        Latitude: {
            class: 'w-half',
            info: {
                intro: `Coordinates need to be entered in decimal degrees. Convert
                    using the <a href="https://www.fcc.gov/media/radio/dms-decimal"
                    target="_blank">FCC converter</a>. <br> Then see the green pin’s
                    popup for name suggestions`,
                tooltip: 'Coordinates need to be entered in decimal degrees. Convert ' +
                   'using the FCC converter at https://www.fcc.gov/media/radio/dms-decimal. ' +
                   'Then see the green pin’s popup for name suggestions',
            },
            name: 'Latitude',
            server: {
                prep: {
                    setGeoJsonData: [],
                    setCoreData: []
                },
                prop: {
                    core: 'latitude'
                },
            },
            type: 'lat',  //merge with lng type?
        },
        LocationType: {
            entity: 'LocationType',
            name: 'LocationType',
            required: true,
            server: {
                prep: {
                    setCoreType: []
                }
            },
            value: 'Point'
        },
        Longitude: {
            class: 'w-half',
            info: {
                intro: `Coordinates need to be entered in decimal degrees. Convert
                    using the <a href="https://www.fcc.gov/media/radio/dms-decimal"
                    target="_blank">FCC converter</a>. <br> Then see the green pin’s
                    popup for name suggestions`,
                tooltip: 'Coordinates need to be entered in decimal degrees. Convert ' +
                   'using the FCC converter at https://www.fcc.gov/media/radio/dms-decimal. ' +
                   'Then see the green pin’s popup for name suggestions',
            },
            name: 'Longitude',
            server: {
                prop: {
                    core: 'longitude'
                },
            },
            type: 'lng',
        }
    };
}