/**
 * Validates taxon names.
 * - Species must have a binomial nomenclature that matches it's genus parent
 *
 * Once validated, the Global Name Verifier results for the name are displayed.
 *
 * Export
 *     validateAndVerifyTaxonym
 *
 * TOC
 *     VALIDATE
 *         IS NOT SPECIES
 *         BINOMIAL NOMENCLATURE
 */
import { getEntityName } from '@util';
import { alert, components, model, state } from '@dataentry';
import { getTaxonymElem } from './result-elems';
import { verifyTaxonymAndDisplayResults } from './verify';
/* FP-TS */
import { pipe } from 'fp-ts/lib/function';
import * as O from 'fp-ts/lib/Option';
import * as RA from 'fp-ts/lib/ReadonlyArray';
import * as S from 'fp-ts/lib/string';

export function validateAndVerifyTaxonym ( fLvl: model.FormGroup ): void {
    pipe(
        isTaxonymValid( fLvl ),
        O.map( () => {
            components.ifFormValidClearAlertsAndEnableSubmit( fLvl );
            verifyTaxonymAndDisplayResults( fLvl );
        } )
    );
}
/* ======================== VALIDATE TAXONYM ================================ */
function isTaxonymValid ( fLvl: model.FormGroup ): O.Option<boolean | void> {
    return isNotSpecies( fLvl ) ? O.some( true ) : validateSpeciesName( fLvl );
}
/* ------------------------ IS NOT SPECIES ---------------------------------- */
function isNotSpecies ( fLvl: model.FormGroup ): boolean {
    return getTaxonRank( fLvl ) !== 'Species';
}
function getTaxonRank ( fLvl: model.FormGroup ): string {
    const rankValue = state.getFieldValue( fLvl, 'Rank' );
    return typeof rankValue === 'string' ? rankValue : rankValue.text;
}
/* --------------------- BINOMIAL NOMENCLATURE ------------------------------ */
function validateSpeciesName ( fLvl: model.FormGroup ): O.Option<void> {
    return pipe(
        hasCorrectBinomialNomenclature( fLvl ),
        O.map( clearBinomailAlert( fLvl ) ),
        O.orElse( () => alertBinomialInvalid( fLvl ) ),
    );
}
function clearBinomailAlert( fLvl: model.FormGroup ) {
    return () => alert.clearActiveAlert( fLvl, 'DisplayName' );
}
function alertBinomialInvalid ( fLvl: model.FormGroup ) {
    alert.showFormValAlert( 'DisplayName', 'needsGenusName', fLvl );
    return O.none;
}
function hasCorrectBinomialNomenclature ( fLvl: model.FormGroup ): O.Option<boolean| string> {
    return pipe(
        getTaxonymElem( fLvl, 'input' ).val() as string,
        S.split( ' ' ),
        RA.head,
        O.chain( O.fromPredicate( hasCorrectBinomial( fLvl ) ) ),
    );
}
function hasCorrectBinomial ( fLvl: model.FormGroup ) {
    return ( s: string ) => s === getGenusName( fLvl );
}
function getGenusName ( fLvl: model.FormGroup ): string {
    const parentId = state.getFieldValue( fLvl, 'Parent' );
    const name = state.getRecords( 'taxon', parentId ).name;
    return getEntityName( name );
}