/**
 * The intro.js walkthrough contains the tutorial for the explore page.
 * It is loaded on the first visit to the page and prompts the user to go through
 * the entire tutorial. The tutorial can later be accessed by clicking on the
 * tutorial button and selecting the a smaller focus of the tutorial or the entire
 * set.
 * The explore tips available by clicking on "Show Tips".
 *
 * Export
 *     addBttnEvents
 *     clearFilters
 *     loadIntsOnMap
 *     loadLocView
 *     setTableView
 *     startWalkthrough
 *     toggleFilterPanelInTutorial
 *
 * TOC
 *     INIT
 *         SET UP
 *         TEAR DOWN
 *     ON STEP CHANGE
 *         EVENT LISTENER
 *         SHORTCUT BUTTONS
 *         LOAD INTS ON MAP
 *         SET VIEW
 *         TOGGLE PANELS
 */
import { table, ui } from '@explore';
import * as el from '@elems';
import { getData } from '@localdata';
import { getSteps } from './tutorial-steps';

let intro, focus, mapStep, isMapDataAvailable = false;

addWalkthroughEventListener();
/* ============================== INIT ====================================== */
function addWalkthroughEventListener () {
    $( "#strt-tut" ).on( 'click', startWalkthrough.bind( null, null ) );
}
export function startWalkthrough ( curFocus ) {
    if ( intro ) return;
    if ( curFocus ) focus = curFocus;
    setTableState();
    el.showIntroTutorial( getIntroOptions(), getIntroEvents() )
}
function getIntroEvents() {
    return {
        onafterchange: onAfterStepChange,
        onexit: resetTableState,
    };
}
function getIntroOptions () {
    return {
        doneLabel: "I'm done.",
        showBullets: true,
        steps: getSteps()
    };
}
/* --------------------------- SET UP --------------------------------------- */
function setTableState () {
    $( '#show-tips' ).off( "click" );
    $( '#explore-view' ).css( "height", "444px" );
    el.enableCombobox( 'Focus', false );
    el.enableCombobox( 'View', false );
    setDbLoadDependentState();
}
function setDbLoadDependentState () {
    if ( !$( '#sel-Focus' ).val() ) {
        return window.setTimeout( setDbLoadDependentState, 200 );
    }
    $( '#sel-Focus' )[ 0 ].selectize.addItem( 'taxa' );
    $( '#sel-View' )[ 0 ].selectize.addItem( '1' );
}
/* --------------------------- TEAR DOWN ------------------------------------ */
function resetTableState () {
    resetUi();
    if ( !$( '#sel-View' ).val() ) return;
    updateUiIfAllTableDataAvailable();
}
function updateUiIfAllTableDataAvailable () {
    getData( 'interaction', true ).then( interactions => {
        if ( !interactions ) return;
        $( '#sel-Focus' )[ 0 ].selectize.enable();
        table.resetDataTable( focus );
    } );
}
function resetUi () {
    focus = focus || "taxa";
    intro = null;
    $( '#explore-view' ).css( "height", "888px" );
    $( '#show-tips' ).on( 'click', ui.showTips );
    $( '#sel-Focus' )[ 0 ].selectize.addItem( focus, 'silent' );
    $( '#sel-View' )[ 0 ].selectize.enable();
}
/* ====================== ON STEP CHANGE ==================================== */
/* ----------------- EVENT LISTENER ----------------------------------------- */
function onAfterStepChange ( instance ) {
    intro = instance;
    const stepConfg = intro._introItems[ intro._currentStep ];
    if ( !$( '#sel-View' ).val() && intro._currentStep > 1 ) return waitForDbLoad( 'tbl', intro._currentStep );
    if ( intro._currentStep >= mapStep ) return showAlertIfGeoJsonNotAvailable( stepConfg );
    if ( stepConfg.setUpFunc ) stepConfg.setUpFunc();
}
function waitForDbLoad ( tag, stepIdx ) {
    window.setTimeout( () => showDbStillLoadingAlert( tag ), 500 );
    if ( tag == 'map' || stepIdx >= mapStep ) {
        intro._currentStep = mapStep;
    } else {
        window.setTimeout( () => goToStepAfterDbLoad( stepIdx ), 500 );
    }
}
function showDbStillLoadingAlert ( tag ) {
    const elem = tag == 'map' ? 'Database' : 'Table';
    $( '.introjs-tooltiptext' ).html( `
        <br><b>Please wait for the ${ elem } to load.<br><br>` );
}
function goToStepAfterDbLoad ( stepIdx ) {
    if ( !$( '#sel-View' ).val() ) return window.setTimeout( () => goToStepAfterDbLoad( stepIdx ), 500 );
    if ( !intro ) return; //Tutorial closed
    intro.goToStep( ++stepIdx );
}
function showAlertIfGeoJsonNotAvailable ( stepConfg ) {
    isAllDataAvailablePromise().then( dataAvailable => {
        if ( !dataAvailable ) return waitForDbLoad( 'map' );
        if ( stepConfg.setUpFunc ) stepConfg.setUpFunc();
    } );
}
function isAllDataAvailablePromise () {
    return isMapDataAvailable ?
        Promise.resolve( isMapDataAvailable ) :
        getData( 'geoJson', true ).then( updateFlagAndReturn );
}
function updateFlagAndReturn ( geoJson ) {
    isMapDataAvailable = !!geoJson;
    return Promise.resolve( !!geoJson );
}
/* --------------------------- SHORTCUT BUTTONS ----------------------------- */
export function addBttnEvents () {
    const steps = getSteps();
    $( '.intro-bttn' ).each( ( i, elem ) => setBttnClick( elem, steps ) );
}
function setBttnClick( elem, steps ) {
    const bttnText = elem.innerText;
    const stepNumber = ( steps.findIndex( step => step.title == bttnText ) ) + 1;
    if ( bttnText === 'Map View' ) mapStep = stepNumber
    $( elem ).on( 'click', () => intro.goToStep( stepNumber ) );
}
/* --------------------------- LOAD INTS ON MAP ----------------------------- */
export function loadIntsOnMap () {
    if ( $( '#map' )[ 0 ].style.display === 'none' ) { $( '#shw-map' ).trigger( 'click' ); }
}
/* -------------------------------- SET VIEW -------------------------------- */
export function setTableView ( view ) {
    const val = el.getOptionValueForText( 'View', view );
    el.setSelVal( 'View', val );
    window.setTimeout( () => ( intro ? intro.refresh() : null ), 2000 );
}
export function loadLocView ( view ) {
    if ( $( '#sel-Focus' )[ 0 ].selectize.getValue() !== 'locs' ) {
        $( '#sel-Focus' )[ 0 ].selectize.addItem( 'locs' );
    }
    window.setTimeout( () => setLocView( view ), 400 );
}
function setLocView ( view ) {
    if ( $( '#sel-View' )[ 0 ].selectize.getValue() !== view ) {
        $( '#sel-View' )[ 0 ].selectize.addItem( view );
    }
}
/* --------------------------- TOGGLE PANELS -------------------------------- */
export function toggleFilterPanelInTutorial ( isClosing = false ) {
    if ( ifClosedOrOpenedAlready( isClosing, '#filter-pnl' ) ) return;
    $( '#filter' ).trigger( 'click' );
}
export function toggleListPanelInTutorial ( isClosing = false ) {
    if ( ifClosedOrOpenedAlready( isClosing, '#list-pnl' ) ) return;
    $( '#lists' ).trigger( 'click' );
}
function ifClosedOrOpenedAlready( isClosing, selector ) {
    const isClosed = $( selector ).hasClass( 'closed' );
    return ( isClosing && isClosed ) || !isClosing && !isClosed ;
}
export function clearFilters () {
    $( 'button[name="reset-tbl"]' ).trigger( 'click' );
    toggleFilterPanelInTutorial( true );
}