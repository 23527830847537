/**
 * Taxon table-build methods.
 *
 * Export
 *     buildTxnTable
 *     onTxnViewChange
 *     rebuildTxnTable
 *
 * TOC
 *     TAXON TABLE
 *     TAXON VIEW
 */
import { table, ui } from '@explore';
import { getSelVal, updateComboChangeEvent } from '@elems/combo';
import { getData, getDetachedRcrd, setData } from '@localdata';
import { isNotNumber } from '@util';
import * as init from '..';
/** =================== TAXON TABLE ========================================= */
/**
 * Get all data needed for the Taxon-focused table from data storage and send
 * to @initTxnViewOpts to begin the data-table build.
 */
export function buildTxnTable ( v ) {
    if ( v ) return getTxnDataAndBuildTable( v );
    return getData( 'curView', true ).then( storedView => {
        const view = storedView || getSelValOrDefault( getSelVal( 'View' ) );/*dbug-log*///console.log( "       --Building [%s] Taxon Table", view );
        return getTxnDataAndBuildTable( view );
    } );
}
function getTxnDataAndBuildTable ( view ) {
    if ( isNotNumber( view ) ) { view = 1; } //Default Bat
    updateComboChangeEvent( 'View', onTxnViewChange );
    return table.initTableData( 'taxon' )
        .then( beginTaxonLoad.bind( null, view ) );
}
function beginTaxonLoad ( groupId ) {                                  /*dbug-log*///console.log('--Building Taxon Table groupId[%s]', groupId);
    const groupRoots = storeGroupAndReturnRootTaxa( groupId );
    ui.initTxnViewOpts( groupId );
    return startTxnTableBuildChain( groupRoots, true );
}
/**
 * Builds a taxon data-tree for the passed taxon. The taxon ranks present in
 * the tree are stored or updated before continuing @getInteractionsAndFillTable.
 * Note: This is the entry point for filter-related taxon-table rebuilds.
 */
export function rebuildTxnTable ( taxa ) {
    const tS = table.getState( [ 'api', 'flags' ] );
    if ( !tS.api || tS.flags.allDataAvailable ) { ui.fadeTable(); }
    return startTxnTableBuildChain( taxa );
}
/**
 * Builds a family tree of taxon data with passed taxa as the roots of the tree,
 * Transforms that data into the format used for ag-grid and loads the grid, aka table.
 * The root ids are added to the global focus storage obj's 'openRows'  and will
 * be expanded on table load.
 */
function startTxnTableBuildChain ( taxa ) {                            /*dbug-log*///console.log( '       --startTxnTableBuildChain taxa = %O', taxa );
    setTaxonOpenRows( taxa );
    const tS = table.getState();
    return init.buildTxnTree( taxa, tS )
        .then( tree => init.buildTxnRowData( tree, tS ) )
        .then( rowData => table.getRowDataForCurrentFilters( rowData ) )
        .then( rowData => init.initTable( 'Taxon Tree', rowData, tS ) )
        .then( () => table.loadTxnFilters( tS, tS.groupName ) );
}
function setTaxonOpenRows ( taxa ) {
    if ( taxa.length > 1 ) return;
    table.setState( { openRows: [ taxa[ 0 ].id.toString() ] } );
}
/** =================== TAXON VIEW ========================================== */
/** Event fired when the taxon view select box has been changed. */
export function onTxnViewChange ( val ) {                              /*dbug-log*///console.log( '       --onTxnViewChange. [%s]', val );
    if ( !val ) return;
    $( '#focus-filters' ).empty();
    buildTaxonTable( val );
}
function buildTaxonTable ( val ) {
    const groupRoots = storeGroupAndReturnRootTaxa( val );
    table.resetTableState();
    return startTxnTableBuildChain( groupRoots );
}
/**
 * Gets the currently selected taxon group/view's id, gets the record for the taxon,
 * stores both it's id and rank in the global focusStorage, and returns
 * the taxon's record.
 */
function storeGroupAndReturnRootTaxa ( val ) {
    const groupId = val || getSelValOrDefault( getSelVal( 'View' ) );/*dbug-log*///console.log( 'storeAndReturnView. val [%s], groupId [%s]', val, groupId );
    const group = getDetachedRcrd( groupId, table.getState( 'data' ).group, 'group' );/*dbug-log*///console.log( "groupTaxon = %O", group );
    updateGroupTableState( groupId, group );
    const taxa = table.getState( 'data' ).rcrds;
    return Object.values( group.roots ).map( root => getRootTaxonRcrd( root, taxa ) );
}
function getRootTaxonRcrd ( root, taxa ) {                          /*dbug-log*///console.log( '--getRootTaxonRcrd root[%O]', root );
    return getDetachedRcrd( root.taxon, taxa, 'taxon' );
}
function updateGroupTableState ( groupId, group ) {
    setData( 'curView', groupId );
    table.setState( {
        curView: groupId,
        groupName: group.displayName,
        groupPluralName: group.pluralName,
        groupRoots: group.roots,
        allGroupRanks: getAllSubRanks( group.roots ),
    } );
}
function getAllSubRanks ( roots ) {
    return Object.values( roots ).reduce( ( ranks, root ) => {
        return ranks.length > root.subRanks.length ? ranks : root.subRanks;
    }, [] );
}
/** This catches errors in group value caused by exiting mid-tutorial. TODO2 */
function getSelValOrDefault ( val ) {
    return !val ? 1 : isNotNumber( val ) ? 1 : val;
}