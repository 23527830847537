/**
 * Small, simple button build-methods.
 *
 * Export
 *     getExitButton
 *
 * TOC
 *     MAIN EXIT
 */
import { getElem } from '@elems';

/* ===================== MAIN EXIT ========================================== */
export function getExitButton ( onExit ) {
    const attr = { id: 'exit-form', class: 'exit-bttn', type: 'button', value: 'X' };
    const bttn = getElem( 'input', attr );
    $( bttn ).on( 'click', onExit );
    return bttn;
}