/**
 * Updates the form layout and field state for the selected citation-type.
 *
 * TOC
 *     UPDATE LAYOUT
 *     UPDATE FIELDS
 *     SYNC STATE AND UI
 *         TITLE
 *         YEAR
 *         AUTHORS
 *         HELPERS
 */
import * as sForm from '@controller/source';
import { form, model, state } from "@dataentry";
import { enableComboboxes, getDomEl, getSelTxt } from '@elems';
import * as _t from '@types';
import { getEntityName } from '@util';

let FS: model.FormState;
let copyPubValues: boolean;
/* =================== UPDATE LAYOUT ======================================== */
/** Updates the form layout to match the selected citation-type. */
export function loadCitTypeFields( typeId: number, typeName?: string ): Promise<void> {
    const fLvl = state.getEntityFormLevel( 'citation' );
    const type = ( typeName || getSelTxt( 'CitationType' ) ) as _t.CitationType;
    return sForm.loadSrcTypeFields( 'Citation' as const, typeId, type )
        .then( () => sForm.onCitationFieldChange( fLvl ) );
}
/* =================== UPDATE FIELDS ======================================== */
/**
 * Depending on the selected citation-type, Citation fields are enabled/disabled
 * in the UI and updated with the corresponding values from the Publication record
 * as needed.
 */
export function onTypeChangeUpdateFieldStateAndUi(
    fState: model.FormState,
    isRebuild: boolean
): Promise<void> {
    if ( !isCreateForm() ) return Promise.resolve();
    FS = fState;
    copyPubValues = getFlagToCopyPublicationValues();
    return syncStateAndUi( isRebuild );
}
function isCreateForm() {
    return state.getFormState( 'top', 'action' ) === 'create';
}
function getFlagToCopyPublicationValues(): boolean {
    const copyTypes: string[] = [
        'Book', "Master's Thesis", 'Museum Record', 'Other',
        'Ph.D. Dissertation', 'Report', 'Chapter'
    ];
    return copyTypes.includes( FS.type! );
}
/* ========================== SYNC STATE AND UI ============================= */
function syncStateAndUi( isRebuild: boolean ): Promise<void> {
    const pubSrc: _t.PublicationSource = state.getRecords( 'source', FS.fields.ParentSource!.value );
    handleTitle( pubSrc );
    handleYear( pubSrc );
    return Promise.resolve( handleAuthors( pubSrc, isRebuild ) );
}
function isCopyValid( skip: string[] ): boolean {
    return copyPubValues && !skip.includes( FS.type! );
}
/* --------------------------- TITLE ---------------------------------------- */
function handleTitle( pubSrc: _t.PublicationSource ): void {
    const skip: string[] = [ 'Chapter', 'Article' ];
    toggleInput( 'DisplayName', !skip.includes( FS.type! ) );
    updateFieldStateAndInputValue( 'DisplayName', getCitationTitle( pubSrc, isCopyValid( skip ) ) );
}
function getCitationTitle( pubSrc: _t.PublicationSource, isEntireWork: boolean ): string {
    const title = isEntireWork ? pubSrc.displayName : FS.fields.DisplayName!.value;
    return title ? getEntityName( title ) : '';
}
/* ------------------------------ YEAR -------------------------------------- */
function handleYear( pubSrc: _t.PublicationSource ): void {
    const year = getCitationYear( pubSrc );
    if ( isFieldHidden( 'Year' ) ) return updateFieldState( 'Year', year );
    toggleInput( 'Year', copyPubValues )
    updateFieldStateAndInputValue( 'Year', year );
}
function getCitationYear( pubSrc: _t.PublicationSource ): string {
    return copyPubValues ? pubSrc.year! : '';
}
/* ---------------------------- AUTHORS ------------------------------------- */
function handleAuthors( pubSrc: _t.PublicationSource, isRebuild: boolean ): Promise<void> | void {
    if ( hasNoAuthorsToFill( pubSrc ) ) return;
    if ( isRebuild ) return toggleAuthorCombos(); //Fill handled during the rebuild process
    return form.selectExistingAuthsOrEds( 'Author', pubSrc.authors, FS.group )
        .then( () => toggleAuthorCombos() );
}
function hasNoAuthorsToFill( pubSrc:_t.PublicationSource ): boolean {
    return !pubSrc.authors || !copyPubValues || isFieldHidden( 'Author' );
}
function toggleAuthorCombos(): void {
    disableAuthorCombos();
    const count = state.getFieldState(FS.group, 'Author', 'count');
    form.rmvExtraMultiFields( 'Author', count, FS.group, false );
}
function disableAuthorCombos() {
    enableComboboxes( $( `#Author_f-cntnr select` ), false );
}
/* ------------------------- HELPERS ---------------------------------------- */
/** Note: Every field's alert-elem is always the same selector, unlike field IDs. */
function isFieldHidden( field: string ): boolean {
    return !state.isFieldShown( FS.group, field )
}
function setInput( field:string, value:string ):void {
    getDomEl<HTMLInputElement>( `#${ field }_f input` ).value = value;
}
/** Disables input filled with data from the publication. */
function toggleInput( field: string, disable: boolean ): void {
    getDomEl<HTMLInputElement>( `#${ field }_f input` ).disabled = disable;
}
function updateFieldState( field: string, value: any ): void {
    state.setFieldValue( FS.group, field, value );
}
function updateFieldStateAndInputValue( field: string, value: any ) {
    updateFieldState( field, value );
    setInput( field, value );
}

