/**
 * Handles initializing the map feature for the explore page and data-entry forms.
 *
 * EXPORT
 *     handleMapInit
 *
 * TOC
 *     INIT MAP FEATURE
 *     INIT MAP INSTANCE
 *         SET MAP INITIAL STATE
 */
/* UTILS */
import { getData } from '@localdata';
/* MAP */
import { getMapState, setMapState } from '../manager.js'
import { addMapElems } from './add-elems.js';
import { onMapClick } from './on-map-click.js';
/** =================== INIT MAP FEATURE ==================================== */
export function handleMapFeatureInit() {
    requireCss();
    fixLeafletBug();
}
function requireCss () {
    require( 'leaflet/dist/leaflet.css' );
    require( 'leaflet.markercluster/dist/MarkerCluster.Default.css' );
    require( 'leaflet.markercluster/dist/MarkerCluster.css' );
    require( 'leaflet-control-geocoder/dist/Control.Geocoder.css' );
}
/** For more information on this fix: github.com/PaulLeCam/react-leaflet/issues/255 */
function fixLeafletBug () {
    delete L.Icon.Default.prototype._getIconUrl;
    L.Icon.Default.mergeOptions( {
        iconRetinaUrl: require( 'leaflet/dist/images/marker-icon-2x.png' ),
        iconUrl: require( 'leaflet/dist/images/marker-icon.png' ),
        shadowUrl: require( 'leaflet/dist/images/marker-shadow.png' ),
    } );
}
/* ==================== INIT MAP INSTANCE =================================== */
/**
 * Starts a new map instance.
 * @param {function} onLoad Function to run after the map is loaded.
 * @param {string} mapId  The id of the map container.
 * @param {string|null} type
 */
export async function handleMapInit ( onLoad, mapId, type ) {
    await initMapRecordData();
    buildAndShowMap( onLoad, mapId, type );
}
async function initMapRecordData() {
    const entities = [ 'geoJson', 'interaction', 'location', 'taxon' ];
    const records = await getData( entities )
    setMapState( 'records', { ...records } );
}
/** Initializes the map using leaflet and mapbox. */
function buildAndShowMap ( onLoad, mapId, type ) {                  /*dbug-log*///console.log( '--buildAndShowMap. onLoad[%O] mapId[%s] type[%s]', onLoad, mapId, type );
    const map = getMapInstance( mapId );                            /*dbug-log*///console.log( '      --map instance created[%O]', map )
    addMapElems( map, mapId );
    setMapInitialState( map, onLoad, mapId, type );
}
/* ------------------- SET MAP INITIAL STATE -------------------------------- */
function getMapInstance ( mapId ) {
    clearPreviousInstance( mapId );
    const map = L.map( mapId );
    setMapState( 'map', map );                                      /*dbug-log*///console.log( '      --map built.' )
    return map;
}
function clearPreviousInstance ( mapId ) {
    const map = getMapState( 'map' );
    if ( map ) map.remove();
    $( mapId ).empty();
    setMapState( 'popups', {} );
}
function setMapInitialState( map, onLoad, mapId, type ) {
    map.on( 'click', onMapClick.bind( null, type ) );
    map.on( 'load', onLoad.bind( null, mapId ) );
    map.setMaxBounds( getMapBounds() );
    map.setView( [ 22, 22 ], 2 ); // onLoad called after view is set
}
function getMapBounds () {
    const southWest = L.latLng( -100, 200 );
    const northEast = L.latLng( 100, -200 );
    return L.latLngBounds( southWest, northEast );
}