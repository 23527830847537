/**
 * Manages form review-log. Messages can be added for form intercommunication.
 * (Notes can also be attached to each field, but the originating user is not saved.)
 */
import { sendAjaxQuery } from "@util";
import { state } from '@dataentry';
/* ------------------------ ADD TO LOG -------------------------------------- */
/** Adds text to the form's review-log. */
export function addToReviewLog( fLvl, txt ) {                       /*dbug-log*///console.log('   -- addToReviewLog fLvl[%s] txt[%s]', fLvl, txt);
    const curLogs = $( `#${ fLvl }_rvw-msg-hstry` )[ 0 ].lastChild;
    $( curLogs ).append( txt );
}
/* ------------------------ TRANSFORM --------------------------------------- */
/** Adds new user messages to the log and returns the log-history html. */
export function prepareAndReturnReviewLog( fLvl, stage ) {
    addNewMessagesToLog( fLvl, stage );
    return $( `#${ fLvl }_rvw-msg-hstry` ).html();
}
function addNewMessagesToLog( fLvl, stage ) {
    const $msg = $( `#${ fLvl }_rvw-msg` ).val()
    const newMsgs = $msg ? $msg.trim() : null;                      /*dbug-log*///console.log('   -- addNewMessagesToLog [%s] newMsgs?[%s]', fLvl, newMsgs);
    if ( newMsgs ) { addToReviewLog( fLvl, `<p>>>> ${ newMsgs } </p>` ); }
    addToReviewLog( fLvl, `<p class="g i">--- Data: ${ stage }.</p>` );
}
/* ---------------------- PUSH UPDATED LOGS --------------------------------- */
export function pushUpdatedLogs( fLvl ) {
    const data = {
        id: state.getFormState( fLvl, 'review' ).id,
        logs: $( `#${ fLvl }_rvw-msg-hstry` )[ 0 ].innerHTML
    }
    sendAjaxQuery( data, 'crud/review/log' );
}