/**
 * Sets field values with entity data for edit forms.
 *
 * Export
 *     setEditFieldValues
 *
 * TOC
 *     INTERACTION
 *     LOCATION
 *     SOURCE
 *     TAXON
 *     HELPERS
 */
import { cloneObj, lcfirst } from "@util";

export function setEditFieldValues( data, model ) {                /*dbug-log*///console.log( '--setEditFieldValues data[%O] model[%O]', data, model );
    const entity = model.core ? model.core : model.name;
    setFieldValues( data, model, entity );
    return model;
}
function setFieldValues( data, model, entity ) {
    const map = {
        Interaction: setIntData,
        Location: setLocData,
        Source: setSrcData,
        Taxon: setTxnData
    };
    return map[ entity ]( data, model );
}
/* ========================== INTERACTION =================================== */
function setIntData( data, model ) {                               /*dbug-log*///console.log( '--setIntData data[%O] model[%O]', data, model );
    const int = data.interaction[ model.editing.core ];            /*dbug-log*///console.log( '  --int[%O]', cloneObj( int ) );
    Object.values( model.fields ).forEach( setIntFieldValue );
    setComplexIntValues();

    function setIntFieldValue( fConfig ) {                          /*dbug-log*///console.log( '  --setIntFieldValue fConfig[%O]', fConfig );
        if ( !fConfig.server?.prop ) return;
        const v = getFieldValue( fConfig.server.prop, int );
        if ( !v ) return;                                       /*dbug-log*///console.log( '  --field[%s] v[%O]', fConfig.name, v );
        fConfig.value =  v.id ? v.id : v;                           /*dbug-log*///console.log( 'fConfig after [%O]', cloneObj( fConfig ) );
    }
    /** Note: Interaction type handled after form load. */
    function setComplexIntValues() {
        setSourceFields( model.fields.CitationTitle.value );
        setLocationFields( model.fields.Location.value );
        setTaxonFieldData( model.fields.Subject, model.fields.Object );
        const tags = model.fields.InteractionTags.value;
        setTagsField( model.fields.InteractionTags, tags, 'interaction type' );
        setTagsField( model.fields.Season, tags, 'season' );
        setSourceTags( model.fields.Source, tags );
    }
    function setSourceFields( citId ) {
        const cSrc = data.source[ citId ];
        const pSrc = data.source[ cSrc.parent ];
        model.fields.Publication.value = pSrc.id;
    }
    function setLocationFields( locId ) {
        const loc = data.location[ locId ];
        const parentId = loc.country ? loc.country.id : loc.region.id;
        model.fields[ 'Country-Region' ].value = parentId;
    }
    function setTagsField( field, tags, type ) {
        if ( !tags ) return;
        field.value = tags.map( getIntTypeTags ).filter( t => t );

        function getIntTypeTags( t ) {
            return t.type === type ? String( t.id ) : null;
        }
    }
    function setSourceTags( field, tags ) {
        const secondary = tags.find( t => t.displayName === 'Secondary' );
        field.value = secondary ? 'Secondary' : false;              /*dbug-log*///console.log( '--setSourceTags secondary?[%O] field[%O]', secondary, cloneObj( field ) );
    }
    function setTaxonFieldData( subjField, objField ) {
        setTaxonData( subjField, data.taxon[ subjField.value ] );
        setTaxonData( objField, data.taxon[ objField.value ] );
    }
    function setTaxonData( field, taxon ) {
        field.misc = { id: taxon.group.root.id };
    }
}
/* ============================ LOCATION ==================================== */
function setLocData( data, model ) {                               /*dbug-log*///console.log( '--setLocData data[%O] model[%O]', data, model );
    const loc = data.location[ model.editing.core ];               /*dbug-log*///console.log( '  --loc[%O]', loc );
    Object.values( model.fields ).forEach( setLocFieldValue );
    setGeoJsonData( model.fields.GeoJson, data.geoJson[ loc.geoJsonId ] )
    model.editing.detail = loc.geoJsonId ? loc.geoJsonId : null;

    function setLocFieldValue( fConfig ) {                          /*dbug-log*///console.log( '  --setLocFieldValue fConfig[%O]', fConfig );
        if ( !fConfig.server?.prop ) return;
        const v = getFieldValue( fConfig.server.prop, loc );
        if ( !v ) return;                                       /*dbug-log*///console.log( '  --field[%s] v[%O]', fConfig.name, v );
        setEditFieldValue( fConfig, v );
    }
}
function setGeoJsonData( geoJsonField, geoJsonRcrd ) {              /*dbug-log*///console.log( '  --setGeoJsonData geoJsonField[%O] geoJsonRcrd[%O]', geoJsonField, geoJsonRcrd );
    geoJsonField.misc.rcrd = geoJsonRcrd;
}
/* ============================ SOURCE ====================================== */
function setSrcData( data, model ) {                               /*dbug-log*///console.log( '--setSrcData data[%O] model[%O]', data, model );
    const e = {
        core: data.source[ model.editing.core ],
        detail: getSrcEntity( data, data.source[ model.editing.core ] )
    };                                                              /*dbug-log*///console.log( '  --entities[%O]', e );
    Object.values( model.fields ).forEach( setSrcFieldValue );
    model.editing.detail = e.detail.id;

    function setSrcFieldValue( fConfig ) {                          /*dbug-log*///console.log( '       --setSrcFieldValue fConfig[%s][%O]', fConfig.name, fConfig );
        if ( !fConfig.server?.prop ) return;
        const v = getSrcFieldValue( fConfig.server.prop );
        if ( !v ) return;                                       /*dbug-log*///console.log( '           --field[%s] v[%O]', fConfig.name, v );
        setEditFieldValue( fConfig, v );
        ifSourceTypeFieldSetFormType( fConfig, model );
    }
    function getSrcFieldValue( prop ) {
        const ent = Object.keys( prop )[ 0 ];
        return e[ ent ][ prop[ ent ] ];
    }
}
function ifSourceTypeFieldSetFormType( fConfig, model ) {          /*dbug-log*///console.log( '               --ifSourceTypeFieldSetFormType field[%s][%O]', fConfig.name, fConfig );
    if ( fConfig.name !== model.name+'Type' ) return;          /*dbug-log*///console.log( '                   --setting source type' );
    model.type = fConfig.value.text;
}
function getSrcEntity( data, src ) {
    const name = lcfirst( src.sourceType.displayName );          /*dbug-log*///console.log( '  --getSrcEntity src[%O] name[%s]', src, name );
    return data[ name ][ src[ name ] ];
}
/* ========================== TAXON =================================== */
function setTxnData( data, model ) {                               /*dbug-log*///console.log( '--setTxnData data[%O] model[%O]', data, model );
    const txn = data.taxon[ model.editing.core ];                  /*dbug-log*///console.log( '  --txn[%O]', txn );
    Object.values( model.fields ).forEach( setTxnFieldValue );
    model.fields[ 'Group-Root' ].value = txn.group.root.id;
    model.fields.Group.value = txn.group.id;
    model.rcrd = txn;

    function setTxnFieldValue( fConfig ) {                          /*dbug-log*///console.log( '  --setTxnFieldValue fConfig[%O]', fConfig );
        if ( !fConfig.server?.prop ) return;
        const v = getFieldValue( fConfig.server.prop, txn );
        if ( !v ) return;                                       /*dbug-log*///console.log( '  --field[%s] v[%O]', fConfig.name, v );
        setEditFieldValue( fConfig, v );
    }
}
/* ============================ HELPERS ===================================== */
function getFieldValue( prop, entity ) {                         /*dbug-log*///console.log( '  --getFieldValue fConfig[%O]', fConfig );
    return entity[ prop.core ];
}
function setEditFieldValue( fConfig, v ) {                              /*dbug-log*///console.log( '  --setEditFieldValue fConfig[%s][%O] v[%O]', fConfig.name, cloneObj( fConfig ), v );
    fConfig.value =  v.id ? { text: v.displayName, value: v.id } : v;/*dbug-log*///console.log( '       --fConfig after [%O]', cloneObj( fConfig ) );
}