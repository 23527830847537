/**
 * The first time a browser visits the Explore page all entity data is downloaded
 * from the server and stored locally using IndexedIdb.
 *
 * Entities downloaded with each ajax call:
 *   /geojson - GeoJson
 *   /interaction - Interaction, InteractionType, Tag *
 *   /location - HabitatType, Location, LocationType
 *   /source - Author, Citation, CitationType, Publication, PublicationType,
 *       Source, SourceType
 *   /taxon - Taxon, Group, Rank
 *   /user - UserNamed, User
 *
 * Export
 *     initLocalDatabase
 *     getAndSetData
 *     getFilterListGroupString
 *
 * TOC
 *     INIT FULL DATABASE
 *         INIT BASE TABLE
 *         DOWNLOAD REMAINING TABLE DATA
 *         DOWNLOAD REMAINING DATA
 *         DOWNLOAD REVIEW-ENTRY DATA
 *         HELPERS
 */
/* EXPLORE */
import { initExploreStateAndTable } from '@explore/ui';
/* UTILS */
import { logInDevEnv, logInProdEnv } from '@util';
import * as _t from '@types';
/* LOCAL-DATA */
import * as util from '@localdata/util';
import * as init from './entity';
/* ====================== INIT FULL DATABASE ================================ */
type DataState = {
    state: {
        [name: string]: string;
    };
};
/**
 * The first time a browser visits the Explore page all entity data is downloaded
 * from the server and stored locally @initLocalData. A data-loading popup message
 * and feature walk-through are shown on the Explore page.
 */
export function initLocalDatabase (): Promise<void> {               /*perm-log*/console.log( "   *--initLocalDatabase" );
    return util.fetchDataForLocalStorage( 'data-state' )
        .then( ( data: DataState ) => util.storeData( 'lclDataUpdtdAt', data.state ) )
        .then( initTaxonDataAndLoadTable )
        .then( downloadRemainingTableData )
        .then( downloadRemainingEntityDataAndFullyEnableDb )
        .then( downloadReviewEntryDataForUser )
        .then( () => util.clearTempMemory() );
}
/* ---------------- INIT BASE TABLE ----------------------------------------- */
function initTaxonDataAndLoadTable (): Promise<void> {
    return getAndSetData( 'taxon' )
        .then( util.setNewDataInLocalStorage )
        .then( () => initExploreStateAndTable( 'taxa', false ) );
}
/* -------------- DOWNLOAD REMAINING TABLE DATA ----------------------------- */
function downloadRemainingTableData (): Promise<void> {
    return getAndSetData( 'source' )
        .then( () => getAndSetData( 'location' ) )
        .then( () => getAndSetData( 'interaction' ) )
        .then( util.setNewDataInLocalStorage );
}
/* --------------- DOWNLOAD REMAINING ENTITY DATA --------------------------- */
function downloadRemainingEntityDataAndFullyEnableDb (): Promise<void> {
    return getAndSetData( 'geoJson' )
        .then( () => getAndSetData( 'user' ) )
        .then( util.setNewDataInLocalStorage );
}
/* --------------- DOWNLOAD REVIEW-ENTRY DATA ------------------------------- */
/** All for DMgr or contributor data */
function downloadReviewEntryDataForUser (): Promise<void> {
    return getAndSetData( 'review' )
        .then( util.setNewDataInLocalStorage );
}
/* -------------------------- HELPERS --------------------------------------- */
export function getAndSetData ( url: string ): Promise<void> {
    return util.fetchDataForLocalStorage( url )
        .then( ( data: _t.EntityRecordsMap ) => setData( url, data ) );
}
function setData ( url: string, data: _t.EntityRecordsMap ): Promise<void> {
    logInDevEnv( '           *-storing [%s] data = %O', url, data );
    logInProdEnv( '           *-storing [%s] data[%s]', url, data ? Object.keys( data ).length : 'undefined' );
    const setDataFunc = {
        geoJson: Function.prototype,
        interaction: init.modifyIntDataForLocalDb,
        location: init.modifyLocDataForLocalDb,
        review: init.modifyRvwDataForLocalDb,
        source: init.modifySrcDataForLocalDb,
        taxon: init.modifyTxnDataForLocalDb,
        user: init.modifyUsrDataForLocalDb,
    } as const;
    return storeServerData( data )
        .then( () => setDataFunc[url as keyof typeof setDataFunc]( data as any ) );
}
/**
 * Loops through the data object returned from the server, parsing and storing
 * the entity data.
 */
function storeServerData ( data: _t.EntityRecordsMap ): Promise<void> {/*dbug-log*///console.log("storeServerData = %O", data);
    const ents = _t.objectKeys( data );
    return ents.reduce( ( p, entity ) => {                          /*dbug-log*///console.log("     entity = %s, data = %O", entity, data[entity]);
        return p.then( p => util.storeData( entity, util.parseData( data[entity]! ) ) );
    }, Promise.resolve() );
}