/**
 * Returns side-panel assembled with passed form-entity and data-review elems.
 */
import { getElem } from '@elems';
import { lcfirst } from '@util';
import { getEntityDetailElems } from '../entity/entity-details-main.js';
import { getDataReviewElems } from '../review/review-panel-main.js';
/* ===================== INIT SIDE-PANEL ================================== */
export function getSidePanelElems( fConfig ) {                       /*dbug-log*///console.log("--getSidePanelElems fConfig[%O]", fConfig);
    const fElems = getEntityDetailElems( fConfig );
    const rElems = getDataReviewElems( fConfig );
    return buildSidePanel( fConfig, fElems, rElems );
}
function buildSidePanel( fConfig, fElems, rElems ) {                 /*dbug-log*///console.log("--buildSidePanel fConfig[%O] fElems[%O], rElems[%O]", fConfig, fElems, rElems);
    const cntnr = buildPanelContainer( fConfig.name, !!rElems.length );
    $( cntnr ).append( buildPanelHeader( fConfig.name ) );
    $( cntnr ).append( [ ...fElems, '<hr>', ...rElems ] );
    return cntnr;
}
function buildPanelContainer( entity, rvwEls ) {
    const attr = { id: 'form-panel', class: lcfirst( entity ) };
    if ( rvwEls ) { attr.class += ' rvw-panel'; }
    return getElem( 'div', attr );
}
function buildPanelHeader( entity ) {
    const attr = { text:  `${ entity } Record` }
    return getElem( 'h3', attr );
}