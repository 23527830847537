/**
 * Initializes the location forms.
 *
 * Export
 *     initCreateForm
 *     initEditForm
 *     initReviewForm
 *
 * TOC
 *     CREATE FORM
 *         FINISH BUILD
 *     EDIT FORM
 *         FINISH BUILD
 *     REVIEW FORM
 *         PARAMS
 *         BUILD
 *     SHARED
 *         FINISH BUILD
 */
import { enableCountryRegionField } from '@controller/interaction/field';
import { enableCombobox } from '@elems/combo';
import { components } from '@dataentry';
import * as map from './form-map.js';
import checkIfLocExists from './prevent-duplicate.js';
/* ======================= CREATE FORM ====================================== */
export function initCreateForm ( entity, val ) {                    /*temp-log*///console.log( "       --Location CREATE [%s]", val );
    if ( $( '#form-map' ).length !== 0 ) { $( '#form-map' ).remove(); }
    const p = getCreateParams( val );
    return components.initSubForm( p )
        .then( status => finishCreateInit( status, 'sub', 'create' ) );
}
function getCreateParams ( val ) {
    const createParams = {
        appendForm: form => $( '#Location_f' )[ 0 ].parentNode.after( form ),
        onFormClose: enableCountryRegionField,
        combo: 'Location',
        style: 'med-sub-form',
        submit: checkIfLocExists.bind( null, 'sub' ),
        vals: {
            DisplayName: val === 'create' ? '' : val, //clears form trigger value
            Country: $( '#sel-Country-Region' ).val()
        }
    };
    return { ...createParams, ...getFormParams( 'sub', 'create' ) };
}
/* ---------------------------- FINISH BUILD -------------------------------- */
function finishCreateInit ( status, fLvl, action ) {
    finishFormInit( status, fLvl, action );
    enableCombobox( 'Country-Region', false );
    disableTopFormLocNote();
    map.addListenerToGpsFields( 'sub' );
    scrollToLocFormWindow();
}
function disableTopFormLocNote () {
    $( '#loc-note' ).fadeTo( 400, .3 );
}
function scrollToLocFormWindow () {
    $( '#top-form' )[ 0 ].scrollTo( 0, 150 );
}
/* ========================= EDIT FORM ====================================== */
export function initEditForm ( entity, id ) {                       /*temp-log*///console.log( '           >--Location EDIT entity[%s] id[%s]', entity, id );
    const p = getEditParams( id );
    return components.initForm( p )
        .then( status => finishEditInit( status, p, 'edit' ) );
}
function getEditParams ( id ) {
    const editParams = {
        id: id,
        style: 'lrg-form',
    };
    return { ...editParams, ...getFormParams( 'top', 'edit' ) };
}
/* ---------------------------- FINISH BUILD -------------------------------- */
function finishEditInit ( status, p, action ) {
    finishFormInit( status, p.group, action );
    map.addListenerToGpsFields( 'top', [ p.id, 'edit', false ] );
    map.afterMapLoads( finishEditForm, p.id );
}
function finishEditForm ( id ) {
    $( 'input.leaflet-control-create-icon' ).on( 'click', initCreateForm );
    map.ifCoordsAddMapPin( id );
}
/* ========================= REVIEW FORM ==================================== */
export function initReviewForm ( rEntry ) {                          /*temp-log*///console.log( '           >--Location REVIEW [%O]', rEntry );
    const params = getReviewFormParams( rEntry.form.action , rEntry );
    return initReviewFormElems( rEntry, params )
        .then( status => finishReviewFormElems( status, params ) );
}
/* ---------------------------- PARAMS -------------------------------------- */
function getReviewFormParams ( action, rEntry ) {
    const params = action === 'create' ? getCreateParams() : getEditParams( rEntry.form.editId );
    delete params.vals;
    params.review = rEntry;
    return { ...params, ...getFormParams( rEntry.inst.group, action ) };
}
/* ---------------------------- BUILD --------------------------------------- */
function initReviewFormElems ( rEntry, params ) {
    const init = rEntry.inst.group === 'top' ? 'initForm' : 'initSubForm';
    return components[ init ]( params );
}
function finishReviewFormElems ( status, p, action ) {              /*dbug-log*///console.log('  --finishReviewFormElems status[%s] p[%O]', status, p);
    const finish = p.group === 'top' ? finishEditInit : finishReviewCreate;
    return finish( status, p, p.action );
}
function finishReviewCreate ( status, p, action ) {
    finishCreateInit( status, p.group, action );
    map.afterMapLoads( map.ifCoordsAddMapPin, true );
}
/* ============================ SHARED ====================================== */
function getFormParams ( fLvl, action ) {
    return {
        action: action,
        name: 'Location',
        group: fLvl,
        initCombos: initCombos.bind( null, fLvl, action ),
    };
}
function initCombos ( fLvl, action ) {
    const events = {
        Country: { onChange: map.focusParentAndShowChildLocs.bind( null, action ) },
        HabitatType: { onChange: false },
    };
    components.initFormCombos( fLvl, events );
}
/* ---------------------------- FINISH BUILD -------------------------------- */
function finishFormInit ( status, fLvl, action ) {                  /*dbug-log*///console.log('           --finishFormInit status[%s] params[%O]', status, p);
    if ( !status ) return; //Error handled elsewhere
    components.ifFormValidClearAlertsAndEnableSubmit( fLvl );
    $( '#DisplayName_f input' ).focus();
    map.addMapToLocForm( action );
    addNotesToForm();
}
function addNotesToForm () {
    addHowToCreateWithGpsNote();
    addSelectSimilarLocationNote();
}
function addHowToCreateWithGpsNote () {
    const note = `<div class="loc-gps-note">Enter
        decimal data (<a href="https://www.fcc.gov/media/radio/dms-decimal"
        target="_blank">convert</a>)<br>See the green pin’s popup for name suggestions.</div>`;
    $( $( '#Latitude_f' )[ 0 ].parentNode ).before( note );
}
function addSelectSimilarLocationNote () {
    const note = `<p class="loc-gps-note" style="margin: auto;">
        Select an existing location by clicking inside its pin's popup.</p>`;
    $( '#form-map' ).before( note );
}