/**
 * Handles modifying form-field state.
 *
 * TOC
 *     STATE
 *         ON CHANGE
 *     PROP
 *         VALUE
 */
import { components } from '@dataentry';
import { getFieldState } from '../get/get-field-state.js';
import { getFormState } from '../get/get-form-state.js';
import { setFormState } from './set-form-state.js';
import { ifReviewEntryAddToField } from './set-review-state.js';
/* ========================= STATE ========================================== */
/** 
 * Updates the entire field state with passed state. Handles any updates triggered by
 * property changes. Returns the updated field state.
 */
export function replaceFieldState( lvl, newState ) {                /*dbug-log*///console.log( '   --replaceFieldState lvl[%s] newState[%s][%O]', lvl, newState.name, newState );
    handleStateUpdates( lvl, { ...newState }, getFieldState( lvl, newState.name ) );
    return getFieldState( lvl, newState.name );
}
function handleStateUpdates( lvl, newState, prevState ) {
    updateFormFieldState( lvl, newState  ); // must happen before onFieldStateChange
    onFieldStateChange( newState, prevState );
}
function updateFormFieldState( lvl, field ) {
    const fields = getFormState( lvl, 'fields' );
    const newFieldState = { ...fields, [ field.name ]: field };     /*dbug-log*///console.log( '    --updateFormFieldState lvl[%s] field[%O] newFieldState[%O]', lvl, field, newFieldState );
    setFormState( lvl, { fields: newFieldState } );
}
/* ---------------------- ON CHANGE ----------------------------------------- */
function onFieldStateChange ( newState, prevState ) {               /*dbug-log*///console.log( '    --onFieldStateChange newState[%O], prevState[%O]', newState, prevState );
    const map = {
        required: toggleRequiredField,
        value: ifReviewEntryAddToField
    };
    for ( let prop in map ) {                                       /*dbug-log*///console.log( '    --onFieldStateChange prop[%s] newState[%O] === prevState[%O] ? [%s]', prop, newState[ prop ], prevState[ prop ], hasNoChanges( newState[ prop ], prevState[ prop ] ) );
        if ( hasNoChanges( newState[ prop ], prevState[ prop ] ) ) continue;
        map[ prop ]( newState );
    }
}
function hasNoChanges( newData, oldData ) {
    if (!newData && !oldData) return true; // eg, null && undefined
    return JSON.stringify( newData ) === JSON.stringify( oldData );
}
function toggleRequiredField ( field ) {                            /*dbug-log*///console.log( '    --toggleRequiredField key[%s] field[%O]', field.name, field );
    if ( field.required ) {
        $( `#${ field.name }_lbl` ).addClass( 'required' );
        components.ifFormValidClearAlertsAndEnableSubmit( field.group );
    } else {
        $( `#${ field.name }_lbl` ).removeClass( 'required' );
    }
}
/* ========================== PROP ========================================== */
/** Adds the given state to the field state, overwriting previous properties. */
export function setFieldState ( lvl, key, addState ) {
    const prevState = getFieldState( lvl, key );                    /*dbug-log*///console.log( '    --setFieldState key[%s] addState[%O] prevState[%O]', key, addState, prevState );
    const newState = { ...prevState, ...addState };                 /*dbug-log*///console.log( '         -- newState[%O]', newState );
    return replaceFieldState( lvl, newState );
}
/* ----------------------- VALUE -------------------------------------------- */
export function setFieldValue( lvl, key, val ) {
    const field = getFieldState( lvl, key );                        /*dbug-log*///console.log( '    --setFieldValue key[%s] val?[%O] field[%O]', key, val, field );
    if ( !field ) return; // form closed
    setFieldValueProp( field, val );
    return replaceFieldState( lvl, field );
}
/**
 * Sets field value directly. Handles setting within standard form-processes and during
 * data-review form initialization.
 * Note: Ensure saved to state after calling this.
 */
export function setFieldValueProp ( field, val, ord ) {             /*dbug-log*///console.log( '    -- setFieldValue key?[%s] field[%O] val[%O] ord?[%s]', field.key, field, val, ord );
    if ( ord ) field.value[ ord ] = val;
    else field.value = val;
}