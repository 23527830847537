/**
 * Handles accessing and modifying the form record-cache.
 *
 * EXPORT
 *     getRecords
 *     getTaxonGroupRoot
 *     syncEntityRecords
 *
 * TOC
 *     GET
 *     SET
 */
import { alertIssue, cloneObj, lcfirst } from "@util";
import { getData } from '@localdata';
import { FM } from "./state-manager";
import * as _t from '@types';
/* ============================ GET ========================================= */
export function getRecords(): _t.EntityRecordsMap;
export function getRecords( entity: string ): _t.EntityRecords;
export function getRecords( entity: string[] ): _t.EntityRecordsMap;
export function getRecords( entity: readonly string[] ): _t.EntityRecordsMap;
export function getRecords<T extends _t.EntityRecord>( entity: string, id: string | number ): T;
export function getRecords<T extends _t.EntityRecord>( entity: string, id: string | number, undefinedOk: true ): T | null;
export function getRecords<T extends string | string[] | readonly string[]>(
    entity?: T,
    id?: number | string,
    undefinedOk?: true
): _t.EntityRecordsMap | _t.EntityRecords | _t.EntityRecord | null{
    const rcrds = FM.getRecordCache();
    if ( id ) return getRecord( entity as string, id as number, undefinedOk, rcrds );
    return entity ? getRecordMap( entity, rcrds ) : rcrds;
}
function getRecord (
    entity: string,
    id: number,
    undefinedOk = false,
    rcrds: _t.EntityRecordsMap
): _t.EntityRecord | null {
    const ent = lcfirst( entity ) as _t.EntityRecordsMapKey;
    const r = rcrds[ ent ]?.[ id ];                                 /*dbug-log*///console.log( '   --getRecord  rcrd[%s][%O]', id, r );
    return r ? cloneObj( r ) : alertMissing( ent, id, !undefinedOk );
}
function alertMissing(
    entity: _t.EntityRecordsMapKey,
    id: number,
    undefinedOk = false
): null {
    if ( !undefinedOk ) alertIssue( 'noRcrdFound', { id: id, entity: String( entity ) } );
    return null;
}
function getRecordMap<T extends string | string[] | readonly string[]>(
    entity: T,
    rcrds: _t.EntityRecordsMap
): _t.EntityRecordsMap {
    return Array.isArray( entity )
        ? buildRcrdsObj( entity, rcrds )
        : cloneObj( rcrds[ entity as string ]! );
}
function buildRcrdsObj<T extends readonly string[]>(
    entities: [...T],
    rcrds: _t.EntityRecordsMap
): _t.EntityRecordsMap {
    const records: _t.EntityRecordsMap = {};
    entities.forEach( ent => {
        records[ ent ] = rcrds[ ent ]!
    } );
    return cloneObj( records );
}
// todo2: move to entity-form-state
export function getTaxonGroupRoot( id : number ) {
    const rcrds = FM.getRecordCache();
    const taxon = rcrds[ 'taxon' ]![ id ];
    return taxon?.group.root;
}
/* ============================ SET ========================================= */
export function syncEntityRecords ( entity: string ) {
    return getData<_t.EntityRecords>( lcfirst( entity ) )
        .then( rcrds => FM.setRecordsCache( { [ entity ]: rcrds! } ) );
}