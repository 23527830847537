/**
 * For basic data-entry forms, the form-data is prepared and pushed to the server.
 * For data-review forms, data relevant to the review process is added as well.
 * Afterward, Local Storage and form state are updated and the form is closed.
 *
 * Export
 *     submitForm
 *
 * TOC
 *     SUBMIT FORM
 *         DATA PREPARATION
 *     PUSH DATA
 */
import { alert, components, form, state, trans } from '@dataentry';
import { cloneObj, isContributorUser, logInProdEnv, sendAjaxQuery } from '@util';
import { submitDataReview } from '../review/submit-review.js';
import { onSubmitError, onSubmitSuccess } from './post-submit';
/** ======================= SUBMIT FORM ================================ */
export function submitForm( fLvl ) {                                /*dbug-log*///console.log("   --submitForm fLvl[%s]", fLvl);
    components.toggleWaitOverlay( true );
    components.toggleSubmitBttn( fLvl, false );
    handleSubmitOnceFormDataReady( state.getFormState( fLvl ) );
}
function handleSubmitOnceFormDataReady( confg ) {
    if ( confg.name === 'Citation' ) { //Ensure citation-text has been generated
        return window.setTimeout( () => prepCitationDataAndSubmit( confg ), 1000 );
    }
    prepDataAndHandleSubmit( confg );
}
function prepCitationDataAndSubmit( confg ) {
    form.setFinalCitation( confg.group );
    prepDataAndHandleSubmit( confg );
}
/** ----------------------- DATA PREPARATION -------------------------------- */
function prepDataAndHandleSubmit( confg ) {
    trans.prepareFormData( confg )
    .then( data => handleFormSubmit( data, confg ) );
}
function handleFormSubmit( fData, confg ) {
    if ( fData.fails ) return handleDataPrepFailure( fData.fails ); /*dbug-log*///console.log("   --submitFormData fData[%O] confg[%O]", fData, confg);
    const pushData =  ifDataQuarantined( confg ) ? submitDataReview : pushDataEntry;
    pushData( confg, fData );
}
function handleDataPrepFailure( fails ) {
    if ( fails === 'handled' ) return components.toggleWaitOverlay( false );
    alert.errUpdatingData( 'dataPrepFail', fails );
}
/**
 * All data created by contributors is quarantined until review is resolved.
 * @param  {object} confg Form configuration
 * @return {boolean}
 */
function ifDataQuarantined( confg ) {
    return confg.review || isContributorUser();
}
/** ========================== PUSH DATA ==================================== */
function pushDataEntry( confg, data ) {
    const url = `crud/${ confg.action }`;
    pushData( data, url, confg.group );
}
function pushData( data, url, fLvl ) {                              /*dbug-log*///console.log("   --pushData data[%O]", data);
    sendAjaxQuery( data, url, entrySuccess, entryError );                       logInProdEnv( 'data = ', cloneObj( data ) );
    // Shows alerts from server errors
    function entryError() { //Send more accurate error response codes...
        onSubmitError( fLvl, ...arguments );
    }
    function entrySuccess( data ) {
        onSubmitSuccess( fLvl, data );
    }
}