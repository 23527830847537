/**
 * Create-taxon review-form.
 *
 * Export
 *     reviewCreate
 *
 * TOC
 *     CREATE FORM
 *         INIT
 *         CLOSE
 *     SELECT FORM
 *         INIT
 *         SELECT PARENT
 *         CLOSE
 */
import { components, form, state } from '@dataentry';
import { getSelVal, triggerComboChangeReturnPromise } from '@elems/combo';
import { getFirstValidRevewEntry } from './get-next-entry.js';

let handlers;

export function reviewCreate ( rEntry, hndlrs ) {
    handlers = hndlrs;
    rEntry.inst.group = 'sub2'; // the submit-form is an extra form level
    return reviewTxnCreate( rEntry );
}
/* ========================= CREATE FORM ==================================== */
function reviewTxnCreate ( rEntry ) {
    const nextRcrd = getFirstValidRevewEntry( rEntry );          /*dbug-log*///console.log( ' +-- reviewTxnCreate nextRcrd[%O]', nextRcrd );
    if ( nextRcrd.skip ) return Promise.resolve( 'skipReview' ); //SKIPPING TAXON REVIEW
    return initTaxonSelect( nextRcrd )
        .then( () => setFormReviewEntry( rEntry ) )
        .then( () => selectParentAndInitCreate( nextRcrd ) );
}
/**
 * Stores the ReviewEntry record associated with the select form's field.
 * This keeps track of the selected ReviewEntry while ReviewEntry parents are
 * processed. Ie, when a new species was created under a genus ReviewEntry, the
 * genus is reviewed first, and the family ReviewEntry before that... etc.
 */
function setFormReviewEntry ( rEntry ) {                            /*dbug-log*///console.log('       -- setFormReviewEntry rEntry[%O]', rEntry);
    state.setFormState( 'sub', { review: rEntry } );
}
/* ----------------------------- INIT --------------------------------------- */
function initReviewCreateForm ( rEntry ) {                           /*dbug-log*///console.log('       -- initReviewCreateForm rEntry[%O]', rEntry);
    const rank = getEntryRank( rEntry );
    const params = getReviewCreateFormParams( rEntry, rank );
    return components.initSubForm( params )
        .then( status => handlers.finishBuild( status, rank ) );
}
function getEntryRank ( rEntry ) {
    return rEntry.payload.coreEntity.rank.displayName;
}
function getReviewCreateFormParams ( rEntry, rank ) {
    const params = handlers.getParams( rank, null );
    params.beforeFormClose = handleCreateFormClose.bind( null, rEntry.inst.group );
    params.review = rEntry;
    delete params.vals;
    return params;
}
/* ---------------------------- CLOSE --------------------------------------- */
/**
 * After create-form review submitted, the select-form handler is set relative to
 * the submitted stage (set after successful submit and called after create-form closed)
 * @param  {object} rEntry    Reviewed and closing ReviewEntry instance props.
 * @param  {string}     .group   The closing form's group-key
 * @param  {object}     .pChild  The next ReviewEntry to load, child taxon
 */
function handleCreateFormClose ( fLvl ) {
    const rEntry = state.getFormState( fLvl, 'review' );
    const onCreateClose = getCloseSelectHandler( rEntry );       /*dbug-log*///console.log( '       -- handleCreateFormClose fLvl[%s] rEntry[%s][%O] onCreateClose[%O]', fLvl, rEntry.inst.rvwAction.name, rEntry, onCreateClose );
    state.addFormHandler( 'sub2', 'onFormClose', onCreateClose );
}
/**
 * Create-form close is based on the ReviewEntry stage of the closing record,
 * the parent form's stage, and the user role.
 *
 * Contributor:
 *     Approved Complete:
 *         (all parent stages): Autoruns the standard select-form close method,
 *     Rejected Complete:
 *         Approved parent: Autoruns the standard select-form close method,
 *         Returned parent: Select-form elem is removed. (Combo later filled if approved value, ie duplicate identified.)
 *         Rejected parent: Select-form elem is removed. ('Rejected...' later added to the field's combo)
 *     Returned:
 *         Approved parent: Autoruns the standard select-form close method,
 *         Returned parent: Select-form elem is removed. (Combo later filled with the quarantined value.)
 *         Rejected parent: Select-form elem is removed. (Combo later filled with the quarantined value.)
 *
 * Manager: (parent-form stage is always 'Pending')
 *     Approved: Autoruns the standard select-form close method, if taxonomy reviewed.
 *     Rejected: Select-form elem is removed. 'Rejected...' is added to the field's combo
 *     Returned: Select-form elem is removed. 'Returned...' is added to the field's combo
 */
function getCloseSelectHandler ( rEntry ) {                          /*dbug-log*///console.log('       -- getCloseSelectHandler rEntry[%O]', rEntry);
    if ( isApprovedWithChildReviewEntry( rEntry ) ) return continueCreateReview;
    const map = {
        Approve: submitSelectForm,
        Complete: handleBasedOnRootForm.bind( null, rEntry ),
        Quarantine: submitSelectForm,
        Reject: handleBasedOnRootForm.bind( null, rEntry ),
        Return: afterReturnCloseForm.bind( null, rEntry.inst.field, 'Returned' )
    };
    if ( rEntry.entityId && !rEntry.completed ) return map.Approve; // Necessary when data is rejected and replaced
    return map[ rEntry.inst.rvwAction.name ];
}
function isApprovedWithChildReviewEntry ( rEntry ) {
    return rEntry.inst.pChild && rEntry.entityId;
}
/**
 * After ReviewEntry is approved, the next ReviewEntry child is loaded and/or the
 * review process is continued.
 */
function continueCreateReview () {
    const rEntry = state.getFormState( 'sub', 'review' );
    const nextRcrd = getFirstValidRevewEntry( rEntry );          /*dbug-log*///console.log( ' +-- continueCreateReview nextRcrd[%O]', nextRcrd );
    return initReviewCreateForm( nextRcrd )
        .then( () => components.finishReviewFormInit( nextRcrd ) );
}
/* ========================= SELECT FORM ==================================== */
/* ----------------------------- INIT --------------------------------------- */
function initTaxonSelect ( rEntry ) {
    const params = [
        rEntry.inst.field,
        rEntry.form.fields.Group.value,
        rEntry.form.fields[ 'Group-Root' ].value,
        null, //submit
        rEntry.inst.group
    ];
    return form.initFieldTaxonSelect( ...params );
}
function selectParentAndInitCreate ( rEntry ) {
    return selectParentTaxa( rEntry )
        .then( () => initReviewCreateForm( rEntry ) );
}
/* ------------------------- SELECT PARENT ---------------------------------- */
function selectParentTaxa ( rEntry ) {
    const pTxn = getParentTaxon( rEntry );                           /*dbug-log*///console.log(' -- selectParentTaxa rEntry[%O] pTxn[%O]', rEntry, pTxn);
    return selectGroupTaxon( pTxn )
        .then( () => selectRootTaxon( pTxn ) )
        .then( () => selectParentTaxon( pTxn ) );
}
function getParentTaxon ( rEntry ) {
    const prntField = rEntry.form.fields.Parent;              /*dbug-log*///console.log(' -- getParentTaxon prntField[%O]', prntField);
    const id = prntField.review ? getApprovedPrntId( prntField ) : prntField.value;
    return state.getRecords( 'taxon', id );
}
function getApprovedPrntId ( prntField ) {
    return state.getRecords( 'reviewEntry', prntField.review.id ).entityId;
}
function selectGroupTaxon ( txn ) {
    const curGroup = getSelVal( 'Group' );
    if ( curGroup == txn.group.id ) { return Promise.resolve(); }
    return triggerComboChangeReturnPromise( 'Group', txn.group.id );
}
function selectRootTaxon ( txn ) {                               /*dbug-log*///console.log('       -- selectRootTaxon txn[%O]', txn);
    if ( !$( '#sel-Group-Root' ).length ) return;
    const curRoot = getSelVal( 'Group-Root' );
    if ( curRoot == txn.group.root.id ) return;
    return triggerComboChangeReturnPromise( 'Group-Root', txn.group.root.id );
}
function selectParentTaxon ( txn ) {                                /*dbug-log*///console.log('       -- selectParentTaxon txn[%O]', txn);
    if ( txn.isRoot ) return;
    return triggerComboChangeReturnPromise( txn.rank.displayName, txn.id );
}
/* ---------------------------- CLOSE --------------------------------------- */
/** A taxon is selected in the form for these stages, so standard submit works. */
function submitSelectForm () {                                      /*dbug-log*///console.log( '           -- submitSelectForm' );
    $( `#sub-submit` ).trigger( 'click' );
}
/**
 * Closes the select form and adds the review stage to the field's combo
 * @param  {string} field The name of the field with the select form
 */
function afterReturnCloseForm ( field, stage ) {
    closeSelectForm();                                              /*dbug-log*///console.log('       -- afterReturnCloseForm field[%s] stage[%s]', field, stage);
    if ( getSelVal( field ) ) return; //set elsewhere. ie, duplicate entity identified an replaced
    components.setReviewStageOption( field, stage );
}
function closeSelectForm () {
    components.exitFormElemAndClearState( 'sub' );
}
function handleBasedOnRootForm ( rEntry ) {
    const rootStage = state.getReviewStage( 'top' );                 /*dbug-log*///console.log( '       -- handleBasedOnRootForm closingStage[%s] rootStage[%s] field[%O]', rEntry.stage.name, rootStage, rEntry.inst.field );
    if ( rootStage === 'Approved' ) return submitSelectForm();
    if ( rEntry.completed ) return closeSelectForm(); // Rejected record. Continue review handles combo
    afterReturnCloseForm( rEntry.inst.field, rEntry.stage.name );
}
