import * as model from '@dataentry/model'; //todo2: decouple
import { DetailEntityFormConfig, ViewTypeConfig } from '../types';
import { SourceFieldKey } from './source-config';
/* ============================ TYPES ======================================= */
export interface CitationFormConfig extends DetailEntityFormConfig {
    fields: Partial<CitationFormFields>,
    records: {
        edit: string[];
    };
}
export type CitationFormFields = model.EntityFormFields<CitationFormField>;
type CitationFormField = CitationFieldKey | SourceFieldKey;
type CitationFieldKey =
    | 'Abstract'
    | 'CitationType'
    | 'Edition'
    | 'Issue'
    | 'Pages'
    | 'Volume';
/* ============================ CONFIG ====================================== */
export default function( entity: string ): CitationFormConfig {
    return {
        core: 'Source',
        records: {
            edit: [ 'author', 'citation', 'citationType', 'publication', 'publisher', 'source' ]
        },
        fields: getCitationFieldConfig(),
        name: entity,
        type: null,
        types: getCitationTypeConfig(),
        layouts: {
            all: [
                [ 'Description' ],
                [ 'Abstract' ],
                [ 'Title', 'CitationType' ],
            ],
        },
    };
}

function getCitationFieldConfig(): Partial<model.EntityFormFields<CitationFormField>> {
    return {
        Abstract: {
            name: 'Abstract',
            server: {
                prop: {
                    detail: 'abstract'
                }
            },
            type: 'fullTextArea',
        },
        Description: {
            name: 'Description',
            label: 'Citation',
            server: {
                prop: {
                    detail: 'fullText'
                },
            },
            type: 'fullTextArea',
        },
        CitationType: {
            class: 'w-12',
            entity: 'CitationType',
            label: 'Type',
            name: 'CitationType',
            required: true,
            server: {
                prop: {
                    detail: 'citationType'
                },
            },
            type: 'select',
        },
        DisplayName: {
            label: 'Title',
            required: true,
            server: {
                prep: {
                    setCitationTitle: []
                }
            },
        },
        Doi: {
            info: {
                tooltip: 'Digital Object Identifier provided by the Publisher',
            }
        },
        Edition: {
            name: 'Edition',
            class: 'w-4 no-grow',
            server: {
                prop: {
                    detail: 'publicationVolume'
                },
            },
            type: 'num',
        },
        Issue: {
            class: 'w-4 no-grow',
            name: 'Issue',
            server: {
                prop: {
                    detail: 'publicationIssue'
                },
            },
            type: 'num',
        },
        Pages: {
            class: 'w-6 no-grow',
            name: 'Pages',
            server: {
                prep: {
                    setCitationPages: [],
                },
                prop: {
                    detail: 'publicationPages'
                },
            },
            type: 'page',
        },
        ParentSource: {
            required: true,
            server: {
                entity: {
                    detail: 'publication'
                },
                prep: {
                    setParent: [ 'Source' ]
                },
                prop: {
                    core: 'parent'
                },
            },
        },
        SourceType: {
            value: 'Citation'
        },
        Website: {
            info: {
                tooltip: 'Copy and paste link to article, if available',
            }
        },
        Volume: {
            class: 'w-4 no-grow',
            name: 'Volume',
            server: {
                prop: {
                    detail: 'publicationVolume'
                },
            },
            type: 'num',
        },
        Year: {
            required: true
        }
    };
}
function getCitationTypeConfig(): ViewTypeConfig {
    return {
        Article: {
            fields: {
                Author: {
                    required: true
                },
                Year: {
                    required: true
                }
            },
            layouts: {
                all: [
                    [
                        'Author',
                        {
                            class: 'flex-grow',
                            fields: [
                                [ 'Year', 'Volume', 'Issue', 'Pages' ],
                                'Doi',
                                'Website' ]
                        }
                    ]
                ]
            }
        },
        Book: {
            fields: {
                Pages: {
                    required: false
                },
                Author: {
                    required: true
                }
            },
            layouts: {
                all: [
                    [ 'Edition', 'Doi' ],
                    [ 'Author', 'Website' ] ],
            }
        },
        Chapter: {
            fields: {
                Pages: {
                    required: true
                },
                Author: {
                    required: true
                }
            },
            layouts: {
                all: [
                    [ 'Pages', 'Doi' ],
                    [ 'Author', 'Website' ] ],
            }
        },
        "Master's Thesis": {
            layouts: {
                all: [
                    [
                        'Author',
                        {
                            class: 'flex-grow',
                            fields: [
                                'Doi',
                                'Website' ]
                        }
                    ]
                ]
            }
        },
        'Museum Record': {
            layouts: {
                all: [
                    [
                        'Author',
                        {
                            class: 'flex-grow',
                            fields: [
                                [ 'Year', 'Pages' ],
                                'Doi',
                                'Website' ]
                        }
                    ]
                ]
            }
        },
        Other: {
            fields: {
                'Edition': {
                    label: 'Edition/Volume'
                }
            },
            layouts: {
                all: [
                    [
                        'Author',
                        {
                            class: 'flex-grow',
                            fields: [
                                [ 'Year', 'Edition', 'Issue', 'Pages' ],
                                'Doi',
                                'Website' ]
                        }
                    ]
                ]
            }
        },
        'Ph.D. Dissertation': {
            layouts: {
                all: [
                    [
                        'Author',
                        {
                            class: 'flex-grow',
                            fields: [
                                'Doi',
                                'Website' ]
                        }
                    ]
                ]
            }
        },
        Report: {
            layouts: {
                all: [
                    [
                        'Author',
                        {
                            class: 'flex-grow',
                            fields: [
                                [ 'Year', 'Pages' ],
                                'Doi',
                                'Website' ]
                        }
                    ]
                ]
            }
        }
    };
}