
/* ======================= GET ============================================== */
export const getDomEl = <T>( selector: string ): T => {
    return document.querySelector( selector ) as T;
}
export function getElemHeight( selector: string ): number {
    return $( selector ).is( ':visible' ) && $( selector ).outerHeight() || 0;
}
export function getInnerWidthInEm ( pxW: number ): number {
    return pxW / parseFloat( $( 'html' ).css( 'font-size' ) );
}
/* ======================= SET ============================================== */
export function addEnterKeypressClick ( elem: HTMLElement ): void {
    $( elem ).keypress( function ( e ) { //Enter
        if ( ( e.keyCode || e.which ) == 13 ) { $( this ).trigger( 'click' ); }
    } );
}
