/**
 * Relinks the field's ReviewEntity quarantined payload IDs, if present. Entry was
 * processed on the server with temporary, fully approved, entity relationships
 * and must be reconnected to the quarantined entities in the contrbutors database.
 *
 *
 */
import * as _t from '@types';
import * as model from '@dataentry/model';
import { getFormState, setFormState } from '@dataentry/state';
/**
 * Relinks the field's ReviewEntity quarantined payload IDs, if present. Entry was
 * processed on the server with temporary, fully approved, entity relationships
 * and must be reconnected to the quarantined entities in the contrbutors database.
 */
export function relinkQuarantinedRelationships (
    fLvl: model.FormGroup,
    entity: _t.EntityRecord
): _t.EntityRecord {
    const fields = getFormState( fLvl, 'fields' ) as model.FormConfig['fields'];
    Object.values( fields ).forEach( f => ifQuarantinedRelink( f, entity, fields ) );
    setFormState( fLvl, { fields } );
    return entity;
}
function ifQuarantinedRelink (
    field: model.FormField,
    entity: _t.EntityRecord,
    fields: model.FormConfig["fields"]
): void {                                                           /*dbug-log*///console.log( '--ifQuarantinedRelink field[%s][%O] entity[%O] fields[%O]', field.name, field, entity, fields );
    if ( !field.review ) return;                                    /*dbug-log*///console.log( '--ifQuarantinedRelink field[%s][%O] entity[%O] fields[%O]', field.name, field, entity, fields );
    return hasQuarantinedContributors( field, entity )
        ? relinkContributors( entity, fields )
        : relinkCoreProperty( field, entity );
}
function hasQuarantinedContributors( field: model.FormField, entity: _t.EntityRecord ) {
    return field.entity === 'Contributor' && entity.contributors
}
function relinkCoreProperty(
    field: model.FormField,
    entity: _t.EntityRecord,
    ord?: number
): void {
    const prop = getCorePropertyName( field );                      /*dbug-log*///console.log( '--relinkCoreProperty prop[%s] ord?[%s] field.value[%O]', prop, ord, field.value );
    if ( !ord ) entity[ prop ] = parseInt( field.value );
    else entity[ prop ][ ord ] = parseInt( field.value[ ord ] );
}
function getCorePropertyName( field: model.FormField ): string {
    return field.server?.prop?.core || field.name!;
}
function relinkContributors (
    entity: _t.EntityRecord,
    fields: model.FormConfig["fields"]
): void {
    const contributors = entity.contributors as _t.Source['contributors'];
    const field = getContributorField( entity, fields )!;           /*dbug-log*///console.log( '--relinkContributors contributors[%O] field[%O] fields[%O]', entity.contributors, field, fields );
    handleRelink( entity, contributors, field )
}
function handleRelink(
    entity: _t.EntityRecord,
    contributors: _t.Source['contributors'],
    field: model.FormField
): void {
    _t.objectKeys( contributors ).forEach( ifQuarantinedRelink );

    function ifQuarantinedRelink ( id: number ): void {
        const contrib = contributors[ id ]!;
        const fVal = field.value[ contrib.ord ];                    /*dbug-log*///console.log( '--ifQuarantinedRelink contrib[%O] id[%s] fVal[%s]', contrib, id, fVal );
        if ( fVal == id ) return;    //Not quarantined
        relinkInContributorObj( fVal, id, contributors, contrib );
        relinkCoreProperty( field, entity, contrib.ord );
    }
}
function getContributorField ( entity: _t.EntityRecord, fields: model.FormConfig["fields"] ): model.FormField | undefined {
    return entity.authors ? fields.Author : fields.Editor;
}
function relinkInContributorObj (
    fVal: number,
    id: number,
    contributors: _t.Source['contributors'],
    contrib: _t.Contributor,
): void {
    delete contributors[ id ];
    contributors[ fVal ] = contrib;
}