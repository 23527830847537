/**
 * Formats interaction data for the agGrid table.
 *
 * Export
 *     getIntRowData
 *     buildIntRowData
 */
/**
 * Returns an array with table-row objects for each interaction record.
 * Note: var idx is used for row coloring.
 */
export function getIntRowData( intRcrdAry, treeLvl, idx ) {
    if ( intRcrdAry ) {
        return intRcrdAry.map( intRcrd => {
            return buildIntRowData( intRcrd, treeLvl, idx );
        } );
    }
    return [];
}
/** Returns an interaction rowData object with flat data in table-ready format. */
export function buildIntRowData( intRcrd, treeLvl, idx ){             /*dbug-log*///console.log('buildIntRowData. int = %O', intRcrd);
    const rowData = {
        citation: getEntityData( 'source', 'description' ),
        entity: 'Interaction',       //Not sure what this is all used for...
        id: intRcrd.id,
        interactionType: intRcrd.interactionType.displayName,   //Table data
        isParent: false,        //Flags that there are no sub-nodes
        isQuarantined: intRcrd.review?.stage === 'Pending',
        name: '',               // Blank tree cell
        note: intRcrd.note,     //Table data
        object: getEntityData( 'taxon', 'displayName', 'object' ),
        objGroupId: intRcrd.objGroupId.toString(),//Used for filters and interaction-row tree-icons
        subjGroupId: intRcrd.subjGroupId.toString(),//Used for filters and interaction-row tree-icons
        rowColorIdx: idx,       //Not sure what all this is used for...
        subject: getEntityData( 'taxon', 'displayName', 'subject' ),
        tags: intRcrd.tags,     //Table data
        treeLvl: treeLvl,       //Influences row coloring
        type: 'intRcrd',        //Not sure what this is all used for...
        updatedAt: intRcrd.serverUpdatedAt,  //When filtering interactions by time updated
        reviewedAt: intRcrd.reviewedAt ? '' : 'U',
        updatedBy: intRcrd.updatedBy,
        year: getYear( getEntityData( 'source', 'year' ) ),
    };
    setLocationData( intRcrd.location, rowData );
    return rowData;

    function getEntityData( entity, prop, intProp ) {
        const rcrdKey = intProp || entity;
        return prop in intRcrd[ rcrdKey ]
            ? intRcrd[ rcrdKey ][ prop ]
            : !Object.keys( intRcrd[ rcrdKey ] ).length ? '[ Loading... ]' : '';
    }
}
/** Adds to 'rowData' any location properties present in the intRcrd. */
function setLocationData( locObj, rowData ) {
    getSimpleLocData();
    getLocRelationalData();
    /** Add any present scalar location data. */
    function getSimpleLocData() {
        const props = {
            location: 'displayName',    gps: 'gpsData',
            elev: 'elevation',          elevMax: 'elevationMax',
            lat: 'latitude',            lng: 'longitude',
        };
        Object.keys( props ).forEach( p => setFlatLocData( p, props[ p ] ) );
    }
    function setFlatLocData( key, prop ) {
        rowData[ key ] = !Object.keys( locObj ).length ? '[ Loading... ]' : locObj[ prop ];
    }
    /** Adds relational location data. Skips 'unspecified' regions. */
    function getLocRelationalData() {
        const props = {
            country: 'country', region: 'region', habitat: 'habitatType'
        };
        Object.keys( props ).forEach( p => setRelationalLocData( p, props[ p ] ) );
    }
    function setRelationalLocData( key, prop ) {
        rowData[ key ] = !Object.keys( locObj ).length ? '[ Loading... ]' :
            ( ifDataAvailable( prop ) ? locObj[ prop ].displayName : '' );

        function ifDataAvailable( prop ) {
            return locObj[ prop ] && !ifUnspecifiedRegion( prop );
        }
        function ifUnspecifiedRegion( prop ) {
            return prop === 'region' && locObj[ prop ].displayName === 'Unspecified';
        }
    }
}

/** 
 * Returns the year from the given date string. 
 * Note: used when filtering interactions by publication date
 */
function getYear( dateStr ) {
    return dateStr? dateStr.replace( /\D/g,'' ) : null;
}