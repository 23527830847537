/**
 * Sync the quarantined data with any changes made during review or to related
 * quarantined data.
 *
 * Export
 *     updateQuarantined
 *
 * TOC
 *     SYNC FIELD-CHANGES
 *         STANDARD FIELD
 *         MULTI-FIELD
 *         GET UNQUARANTINED
 *     SET CURRENT FIELD-DATA
 *         COMPLEX UPDATE HANDLERS
 *         AUTHOR\EDITOR
 *         LOCATION
 *         SOURCE
 *         TAXON
 *     HELPERS
 */
import { isMultiField } from '@elems';
import { updateLocalData, DataEntryResults } from '@localdata/sync/data-entry';
import { getEntities, getEntity, getValue } from '@localdata/util';
import * as _t from '@types';
import { cloneObj } from '@util';

let ReviewEntries: _t.EntityRecords<_t.ReviewEntry>;
/**
 * Reviewed related-data is updated and quarantined data is readded to local storage.
 * - Approved: it is stored in local storage without it's quarantined temp ID
 * - Rejected: will not be stored and needs to be removed/replaced (TODO2)
 *         options: replace here, or repush through server entity processing
 *      replace here. otherwise, there would be issues with lots of form validation
 *          that only really matters during approval and contributor submission
 */
export function updateQuarantined (
    rEntry: _t.ReviewEntry,
    rcrds: _t.EntityRecords<_t.ReviewEntry>
): Promise<void> {                                                  /*dbug-log*///console.log( '   +-- updateQuarantined entity name?[%s] id[%s] rEntry[%O] ReviewEntries[%O]', rEntry.payload.coreEntity.displayName, rEntry.payload.coreId, rEntry, rcrds );
    ReviewEntries = rcrds;
    const payload = cloneObj( rEntry.payload );
    updateQuarantinedFieldData( rEntry, payload );
    return updateLocalData( payload as DataEntryResults )
        .then();
}
/* ====================== SYNC FIELD-CHANGES ================================ */
function updateQuarantinedFieldData (
    rEntry: _t.ReviewEntry,
    payload: _t.ReviewEntryPayload
): void {
    const fields = rEntry.form.fields;
    _t.objectKeys( fields ).forEach( key => syncQuarantinedData( key, fields[ key ]!, payload ) );
}
/** Updates the field's data in the quarantined record. */
function syncQuarantinedData (
    fName: string,
    field: _t.ReviewField,
    payload: _t.ReviewEntryPayload
): void {
    /*dbug-log*///console.log( '       +-syncQuarantinedData field[%s][%O] payload[%O]', fName, cloneObj( field ), payload );
    if ( hasNoReviewEntry( field ) ) return;
    return isMultiField<_t.MultiReviewField>( field )
        ? syncMultiField( field, payload )
        : syncField( fName, field, payload );
}
function hasNoReviewEntry ( field: _t.ReviewField ): boolean {
    return !field.review && !field.replacedReview;
}
/* ---------------------- STANDARD FIELD ------------------------------------ */
/**
 * Syncs changes made during review to quarantined field-values. If a ReviewEntry
 * was approved or rejected, the quarantined IDs are replaced with the approved data
 * or with temporary data when necessary to prevent local storage errors.
 */
function syncField (
    fName: string,
    field: _t.ReviewField,
    payload: _t.ReviewEntryPayload
): void {
    const rEntry = ifResultGetReviewEntry( field );
    const val = rEntry ? rEntry.entityId : field.value;             /*dbug-log*///console.log( '           --syncField rEntry?[%s][%O] field[%O] val[%O]', rEntry?.payload.coreId , field.review, field, val );
    syncEntityData( payload, rEntry, field.prop, val );
}
function ifResultGetReviewEntry ( field: _t.ReviewField ): _t.ReviewEntry | null {
    return field.review?.id ? getRecordIfReviewHasResult( field.review.id ) : null;
}
/* ----------------------- MULTI-FIELD -------------------------------------- */
/** Handles fields with multiple possible entities. */
function syncMultiField ( field: _t.MultiReviewField, payload: _t.ReviewEntryPayload ): void { /*dbug-log*///console.log( '           --syncMultiField field[%O] payload[%O]', field, payload );
    if ( field.review ) processMultiReviewEntry( field, payload );
    if ( field.replacedReview ) processMultiReplacedReviewEntry( field, payload );
}
function processMultiReviewEntry ( field: _t.MultiReviewField, payload: _t.ReviewEntryPayload ) {
    _t.objectKeys( field.review ).forEach( k => handleMultiUpdate( k, field.review[ k ]! ) );

    function handleMultiUpdate ( ord: number, bones: _t.QuarantinedReviewEntryBones ): void {
        let rEntry = getRecordIfReviewHasResult( bones.id );        /*dbug-log*///console.log( '           --handleMultiUpdate ord[%s] rEntry[%s][%O] field[%O]', ord, ( rEntry ? rEntry.payload.coreId : null ), rEntry, field );
        const val = rEntry ? rEntry.entityId : field.value[ ord ];
        rEntry = getRecordNeededForUpdate( rEntry, ord, field.replacedReview! );
        syncEntityData( payload, rEntry, field.prop, val, ord );
    }
}
/** The previous record is needed to get the correct contributor ID to update. */
function getRecordNeededForUpdate (
    rEntry: _t.ReviewEntry | null,
    ord: number,
    replaced: _t.MultiReviewField['review']
): _t.ReviewEntry | null {
    return replaced?.[ ord ] ? getReviewEntry( replaced[ ord ]!.id ) : rEntry;
}
function processMultiReplacedReviewEntry (
    field: _t.MultiReviewField,
    payload: _t.ReviewEntryPayload
) {
    const replaced = field.replacedReview;
    const review = field.review;
    _t.objectKeys( replaced! ).forEach( handleReplacedReviewEntry );

    function handleReplacedReviewEntry ( ord: number ) {
        if ( review && ord in review ) return; //processed already
        const rEntry = getReviewEntry( replaced![ ord ]!.id );
        syncEntityData( payload, rEntry, field.prop, field.value[ ord ], ord );
    }
}
/* --------------------- GET UNQUARANTINED ---------------------------------- */
/** If data is no longer quarantined, the reviewed record is returned. */
function getRecordIfReviewHasResult ( id: number ): _t.ReviewEntry | null {
    const rEntry = getReviewEntry( id );
    /*dbug-log*///console.log( '               --getRecordIfReviewHasResult rEntry[%s][%O] ReviewEntries[%O]', id, rEntry, ReviewEntries );
    return ifReviewHasResult( rEntry.stage.name ) ? rEntry : null;
}
function ifReviewHasResult ( stage: string ): boolean {
    return [ 'Approved', 'Completed', 'Rejected' ].indexOf( stage ) !== -1;
}
/* ====================== SET CURRENT FIELD-DATA ============================ */
/**
 * Updates the quarantined data with any changes made during review or to related
 * quarantined data.
 * TODO2: Ensure that, if this entity gets deleted, it is removed from storage: parent rcrds, etc.
 */
function syncEntityData (
    payload: _t.ReviewEntryPayload,
    rEntry: _t.ReviewEntry | null,
    props: _t.ReviewField['prop'],
    val: any,
    ord?: number
): void {                                                           /*dbug-log*///console.log( '               --syncEntityData payload[%O] rEntry?[%O] props[%O] ord?[%s] newVal?[%s]', payload, rEntry, props, ord, val );
    if ( !props ) return;  //Synced via a related property (eg, publication is synced via citation)
    _t.objectKeys( props ).forEach( setEachProperty );

    function setEachProperty ( type: 'core' | 'detail' ) {
        setProperty( props![ type ]!, type, payload, rEntry, val, ord );
    }
}
/** Sync value in simple quarantined-fields or call complex-field's handler. */
function setProperty (
    prop: string | false,
    type: 'core' | 'detail',
    payload: _t.ReviewEntryPayload,
    rEntry: _t.ReviewEntry | null,
    val: any,
    ord?: number
) {
    if ( !prop ) return;                                            /*dbug-log*///console.log( '                   --setEntityDataProp type[%s] prop[%s] value[%s]', type, prop, val );
    const eData = getPropertyEntityRecord( payload, type );
    const handler = getQuarantinedPropertyUpdateHandler( payload.core, prop, ord );
    if ( handler ) return handler( prop, eData, rEntry, val );
    eData[ prop ] = val;
}
function getPropertyEntityRecord( payload: _t.ReviewEntryPayload, type: 'core' | 'detail' ): _t.EntityRecord {
    return payload[ type + 'Entity' as keyof DataEntryResults ] as _t.EntityRecord;
}
/* ------------------ COMPLEX UPDATE HANDLERS ------------------------------- */
/** Returns the handler for data with complex updates. */
function getQuarantinedPropertyUpdateHandler (
    entity: string,
    prop: string,
    ord?: number
): Function | null {
    const map: { [key: string]: { [key: string]: Function; }; } = {
        interaction: {
            object: updateTaxonFieldData,
            subject: updateTaxonFieldData,
            location: handleUnspecifiedLocation,
            source: handleChangedSource
        },
        source: {
            authors: updateAuthorFieldData.bind( null, ord ),
            editors: updateAuthorFieldData.bind( null, ord ),
            // todo1: check source required parents when cleared...
        },
        taxon: {
            group: Function.prototype, //Group is set during local storage update
            parent: updateTaxonFieldData,
        }
    };
    return entity in map ? map[ entity as keyof typeof map ]![ prop ]! : null;
}
/* ---------------------- AUTHOR\EDITOR ------------------------------------- */
/** Sync author/editor data: Update ordinal value and update contributor data. */
function updateAuthorFieldData (
    ord: number | undefined,
    prop: string,
    eData: _t.EntityRecord,
    rEntry: _t.ReviewEntry | null,
    v: any
): void {                                                           /*dbug-log*///console.log( '               --updateAuthorFieldData prop[%s][%s] eData[%O] rEntry?[%O] v[%s]', prop, ord, eData, rEntry, v );
    if ( !ord ) return console.error( 'Contributor ordinal not found.' ); //todo1
    const value = v || getFirstSourceTypeId( 'authSrcs' );
    eData[ prop ][ ord ] = value;
    handleContributorPropertyUpdate( eData, rEntry, value );
}
function getFirstSourceTypeId ( prop: string ): number {
    const ids = getValue( prop ) as number[];
    return ids[ 0 ]!;
}
/** TODO: Handle when authors have been deleted */
function handleContributorPropertyUpdate (
    eData: _t.EntityRecord,
    rEntry: _t.ReviewEntry | null,
    value: number
): void {                                                           /*dbug-log*///console.log( '                   --handleContributorPropertyUpdate eData[%O] rEntry?[%O] value[%s]', eData, rEntry, value );
    const prevId = rEntry?.payload.coreId;
    if ( !prevId ) return;
    const contrib = eData.contributors[ prevId ];
    eData.contributors[ value ] = contrib;
    delete eData.contributors[ prevId ];
}
/* -------------------------- LOCATION -------------------------------------- */
/** Returns the default location record: unspecified. */
function handleUnspecifiedLocation (
    prop: string,
    eData: _t.EntityRecord,
    _1: _t.ReviewEntry | null,
    val: any
): void {
    eData[ prop ] = val || getUnspecifiedLoc();
}
function getUnspecifiedLoc () {
    const regions = getEntities( 'topRegionNames' );            /*dbug-log*///console.log('               --handleUnspecifiedLocation regions[%O]', regions);
    const unspecifiedLoc = _t.objectKeys( regions ).find( r => r === 'Unspecified' );
    if ( unspecifiedLoc ) return regions[ unspecifiedLoc ];
    console.error( 'Unspecified location entity not found' );
    return false;
}
/* --------------------------- SOURCE --------------------------------------- */
function handleChangedSource (
    prop: string,
    eData: _t.EntityRecord,
    _1: _t.ReviewEntry | null,
    val: any
): void {
    const value = val || getFirstSourceTypeId( 'citSrcs' );         /*dbug-log*///console.log('               --handleChangedSource value[%s]', value);
    eData[ prop ] = value;
}
/* -------------------------- TAXON ----------------------------------------- */
/** Sync taxon data. If quarantined parent was rejected, replace with temp parent. */
function updateTaxonFieldData (
    prop: string,
    eData: _t.EntityRecord,
    rEntry: _t.ReviewEntry | null,
    val: any
): void {                                                           /*dbug-log*///console.log( '               --updateTaxonFieldData prop[%s] eData[%O] rEntry?[%O] val[%s]', prop, eData, rEntry, val );
    eData[ prop ] = val || getTempParentId( rEntry );               /*dbug-log*///console.log( '                       -- value[%s]', eData[ prop ] );
}
/** Returns the ID for the first valid parent in the taxonomy. */
function getTempParentId ( rEntry: _t.ReviewEntry | null ): number | undefined {
    if ( !rEntry ) { console.error( 'ReviewEntry record not found' ); return; }
    const parentField = rEntry.form.fields.Parent;                  /*dbug-log*///console.log( '               --getTempParentId parentField[%O]', parentField );
    if ( !parentField!.review ) return parentField!.value;
    return findNextReviewEntryParent( parentField!.review.id );
}
function findNextReviewEntryParent ( id: number ): number | undefined {
    const reviewEntryParent = getReviewEntry( id );                 /*dbug-log*///console.log( '               --findNextReviewEntryParent reviewEntryParent[%O]', reviewEntryParent );
    return reviewEntryParent.entityId || findValidParent( reviewEntryParent );
}
function findValidParent ( reviewEntryParent: _t.ReviewEntry ): number | undefined {
    return reviewEntryParent.stage.name === 'Rejected' ?
        getTempParentId( reviewEntryParent ) : reviewEntryParent.payload.coreId;
}
/* ======================= HELPERS ========================================== */
function getReviewEntry ( id: number ): _t.ReviewEntry {
    return getEntity<_t.ReviewEntry>( ReviewEntries, id, 'review' );
}