/**
 * Handles the initialization of the comboboxes (selectize.js) in the interaction form.
 */
import { initFormCombos } from '@dataentry/components';
import { createEntity, createEntityType } from '@dataentry/form';
import * as controller from '../controller';
/** ------------------ FORM COMBOBOXES -------------------------------------- */
export function initCombos() {
    const events = getComboConfig();
    initFormCombos( 'top', events );
}
function getComboConfig() {
    return {
        'CitationTitle': {
            onChange: controller.onCitSelection,
            create: ( val: string ) => createEntityType( 'Citation', 'source', val )
        },
        'Country-Region': {
            onChange: controller.onCntryRegSelection
        },
        'Date': {
            elemId: '#int-date-start',
            enableTime: false,
            onClose: Function.prototype,
            plugins: {
                range: {
                    input: '#int-date-end'
                }
            }
        },
        'Source': {
            onChange: controller.onSourceTypeChange
        },
        'InteractionType': {
            onChange: controller.onTypeSelection
        },
        'InteractionTags': {
            onChange: controller.onTagSelection,
            delimiter: ",",
            maxItems: null
        },
        'Location': {
            onChange: controller.onLocSelection,
            create: ( val: string ) => createEntity( 'Location', val )
        },
        'Publication': {
            onChange: controller.onPubSelection,
            create: ( val: string ) => createEntityType( 'Publication', 'source', val )
        },
        'Season': {
            delimiter: ",",
            maxItems: null
        },
        'Subject': {
            onChange: controller.onTaxonFieldSelection.bind( null, 'Subject' )
        },
        'Object': {
            onChange: controller.onTaxonFieldSelection.bind( null, 'Object' )
        },
    };
}