/**
 * MANAGES A POPUP WINDOW OVER A FULL-PAGE, NONE-SCROLLABLE, OPAQUE OVERLAY.
 *
 *  Export
 *      hidePagePopup
 *      initPopupOverlay
 *      showPagePopup
 */
const overlay = '#b-overlay';
const popup = '#b-overlay-popup';

/** fadeIn() sets elems back to their previous non-hidden display value, ie flex. */
export function initPopupOverlay (): void {
    $( `${ overlay }, ${ popup }` ).css( "display", "flex" ).hide();
}
/* ========================= SHOW ========================================== */
type PopupInnerHtml = string | HTMLElement | ( string | HTMLElement )[];
export function showPagePopup (
    classes: string,
    text: string | null,
    html: PopupInnerHtml,
    afterShow: () => void
): void {
    setPopupContent( classes, text, html );
    $( `${ overlay }, ${ popup }` ).fadeTo( 'fast', 1, afterShow );
    togglePagePopupOverlay();
}
function setPopupContent ( classes: string, text: string | null, html: PopupInnerHtml ): void {
    $( popup ).addClass( 'loading ' + classes ? classes : '' );
    if ( html ) return setPopupHtml( html );
    const msg = text || 'Loading...';
    $( popup ).text( msg );
}
function setPopupHtml ( html: PopupInnerHtml ): void {
    if ( typeof html === 'string' ) html = html.replace( /\n\s+/g, '' );  //trims whitespace
    if ( Array.isArray( html ) ) {
        html.forEach( el => $( popup ).append( el ) );
    } else {
        $( popup ).html( html );
    }
}
/* ========================= HIDE ========================================== */
export function hidePagePopup (): void {
    afterPopupHidden();
    togglePagePopupOverlay( false );
}
function afterPopupHidden (): void {
    $( overlay ).removeClass().addClass( 'overlay' ).hide();
    $( popup ).empty().removeClass().addClass( 'popup' );
}
/* ======================== SHARED ========================================== */
/** Prevents the background from scrolling. */
function togglePagePopupOverlay ( show = true ): void {
    const h = show ? '100vh' : 'unset';
    const o = show ? 'hidden' : 'unset';
    $( 'body' ).css( { height: h, 'overflow-y': o } );
}