/**
 * When an author|editor is first selected, if the other author type field is
 * shown, it is disabled. It will be reenabled if all selections are cleared.
 *
 * Export
 *     ifNoneStillSelectedEnableOtherType
 *     toggleOtherAuthorType
 *
 * TOC
 *     IF TYPE HAS SELECTION
 *     UPDATE OTHER AUTH-TYPE UI
 *         FIELD WIDTH
 *         FIELD LABEL
 */
/* UTIL */
import { enableFirstCombobox } from '@elems/combo/';
import * as _t from '@types';
import { cloneObj } from '@util';
/* DATA-ENTRY */
import { model, state } from '@dataentry';
/* ====================== IF TYPE HAS SELECTION ============================= */
/**
 * When the last author|editor is cleared, the other author|editor type field is
 * reenabled, if it is shown.
 */
export function ifNoneStillSelectedEnableOtherType(
    fLvl: model.FormGroup,
    type: _t.AuthorType,
    clearedOrd: number
) {                                                                 /*dbug-log*///console.log( '--ifNoneStillSelectedEnableOtherType lvl[%s] type[%s] cleared[%s]', fLvl, type, clearedOrd );
    if ( ifTypeStillSelected( fLvl, type, clearedOrd ) ) return;
    toggleOtherAuthorType( fLvl, type, true );
}
function ifTypeStillSelected(
    fLvl: model.FormGroup,
    type: _t.AuthorType,
    clearedOrd: number
): boolean {
    const fVals = state.getFieldValue( fLvl, type );               /*dbug-log*///console.log( '--ifTypeStillSelected lvl[%s] type[%s] vals[%O]', fLvl, type, cloneObj( fVals ) );
    if ( fVals[ clearedOrd ] ) fVals[ clearedOrd ] = null; //val store change event could happen after this check
    return _t.objectValues( fVals ).find( v => v );
}
/* ==================== UPDATE OTHER AUTH-TYPE UI =========================== */
export function toggleOtherAuthorType(
    fLvl: model.FormGroup,
    type: _t.AuthorType,
    enable: boolean
): void {                                                           /*dbug-log*///console.log( '--toggleOtherAuthorType [%s][%s][%s]', type, fLvl, enable );
    const other = type === 'Author' ? 'Editor' : 'Author';
    if ( !state.isFieldShown( fLvl, other ) ) return;
    enableFirstCombobox( other, enable );
    updateOtherTypeUi( other, enable );
    state.setFieldState( fLvl, other, { required: enable } );
}
function updateOtherTypeUi( oType: _t.AuthorType, enable: boolean ) {                       /*dbug-log*///console.log( '--updateOtherTypeUi [%s][%s]', oType, enable );
    setOtherFieldWidth( oType, enable );
    showOtherLabelElem( oType, enable );
}
/* ----------------------- FIELD WIDTH -------------------------------------- */
/**
 * When an author|editor is first selected, if the other author type field is
 * disabled, shrunk, and the label hidden. It will be reenabled if all selections
 * are cleared.
 */
function setOtherFieldWidth( oType: _t.AuthorType, enable: boolean ) {
    if ( isOtherFieldAlreadySized( oType, enable ) ) return;
    const orgW = getCurrentFlexWidth( oType );
    const newW = enable ? orgW * 3 : orgW / 3;                      /*dbug-log*///console.log( '--showOtherLabel [%s]enable?[%s] orgW[%s] newW[%s]', oType, enable, orgW, newW );
    $( `#${ oType }_f-cntnr` ).data( 'enabled', enable );
    $( `#${ oType }_f-cntnr` ).css( { 'flex-basis': newW+'%' } );
}
function getCurrentFlexWidth( oType: _t.AuthorType ): number {
    const $cntnr = $( `#${ oType }_f-cntnr` )
    return parseInt( $cntnr.css( 'flex-basis' ).slice( 0, -1 ) );
}
/** Prevents repeated calls shrinking field container too far. */
function isOtherFieldAlreadySized( oType: _t.AuthorType, enable: boolean ) {
    return $( `#${ oType }_f-cntnr` ).data( 'enabled' ) === enable;
}
/* ----------------------- FIELD LABEL -------------------------------------- */
function showOtherLabelElem( oType: _t.AuthorType, enable: boolean ) {
    const val = enable ? 'block' : 'none';
    $( `#${ oType }_f-cntnr label` ).css( { display: val } );
}