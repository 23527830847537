/**
 * Misc component utilities.
 */
import { alert, components, state } from '@dataentry';
import * as fields from '../core/field';
/* ========================= PREDICATES ===================================== */
/** Returns true if the next sub-form exists in the dom. */
export function hasOpenSubForm( fLvl ) {
    const childFormLvl = state.getFormLevel( 'child', fLvl );
    return $( '#'+childFormLvl+'-form' ).length > 0;
}
export function isFormValidForSubmit( fLvl ) {
    if ( $( `.${ fLvl }-active-alert` ).length ) return false;
    const requiredFieldsFilled = fields.areRequiredFieldsFilled( fLvl );
    const noOpenSubForm = !hasOpenSubForm( fLvl );                  /*dbug-log*///console.log( '          --isFormValidForSubmit [%s] requiredFieldsFilled?[%s] noOpenSubForm?[%s]', fLvl, requiredFieldsFilled, noOpenSubForm );
    return requiredFieldsFilled && noOpenSubForm;
}
/* ============================= SUBMIT ===================================== */
/** Enables the parent form's submit button if all required fields have values. */
export function ifParentFormValidEnableSubmit( fLvl ) {
    const parentLvl = state.getFormLevel( 'parent', fLvl );
    ifFormValidClearAlertsAndEnableSubmit( parentLvl );
}
/**  Note: Execution order can happen before field-alert is fully validated.  */
export function ifFormValidClearAlertsAndEnableSubmit( fLvl, entity = false ) {/*dbug-log*///console.log('--ifFormValidClearAlertsAndEnableSubmit fLvl?[%s] entity?[%s]', fLvl, entity);
    if ( !fLvl ) { fLvl = state.getEntityFormLevel( entity ); }
    const isValidForm = isFormValidForSubmit( fLvl );
    components.toggleSubmitBttn( fLvl, isValidForm );
    alert.clearActiveAlert( fLvl, null, isValidForm );
}
/* ============================= UPDATES ==================================== */
export function onFormConfigChanged( fLvl, entity ) {
    components.toggleSubmitBttn( fLvl, false );
    return fields.rebuildFieldsOnFormConfigChanged( fLvl, entity );
}
