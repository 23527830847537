/**
 * Handles setting and updating state of entity-forms where the model is dependent
 * on external state.
 * Note: currently only necessary for taxon forms.
 *
 * TOC
 *     TAXON
 *         GROUP
 *         Group-Root
 *     UPDATE
 */
import { onLayoutChangeUpdateModel } from '@dataentry/model';
import { getRecords } from '../core';
import { getFormState } from '../get/get-form-state.js';
import { setFieldState } from './set-field-state.js';

export function setRecordDependentEntityState ( FS ) {             /*dbug-log*///console.log( '   --setRecordDependentEntityState FS[%O]', FS );
    const map = {
        // Citation: storeSourceData,
        Subject: initTaxonState,
        Object: initTaxonState,
        Parent: initTaxonState,
        Taxon: initTaxonState
    };
    if ( map[ FS.name ] ) return map[ FS.name ]( FS );
}
/* ========================= TAXON ========================================== */
function initTaxonState( FS ) {
    const rcrds = getRecords();
    setTaxonGroupState( rcrds, FS );
}
function setTaxonGroupState( records, FS ) {                        /*dbug-log*///console.log( '      --setTaxonGroupState FS[%O] records[%O]', FS, records );
    updateGroupFieldsState( records, FS );
    return onLayoutChangeUpdateModel( FS.group );
}
/** Note: Group init-value required. */
function updateGroupFieldsState ( rcrds, FS ) {                     /*dbug-log*///console.log( '          --updateGroupFieldsState rcrds[%O] FS[%O]', rcrds, FS );
    const group = getGroupRecord( rcrds, FS );
    setGroupState( FS, group );
    setGroupRootState( rcrds.taxon, FS, group );
}
/* ------------------------ GROUP ------------------------------------------- */
function getGroupRecord ( rcrds, FS ) {
    return rcrds.group[ FS.fields.Group.value ];
}
/** [setGroupState description] */
function setGroupState ( FS, group ) {                              /*dbug-log*///console.log( '               --setGroupState group[%O]', group );
    const newState = {
        misc: getGroupMiscData( FS, group ),
        value: group.id,
    };                                                              /*dbug-log*///console.log( '                  -- newState[%O]', newState );
    setFieldState( FS.group, 'Group', newState );
}
function getGroupMiscData( FS, group ) {
    const newMisc = {
        rcrd: group,
        roots: group.roots
    };
    return { ...FS.fields.Group.misc, ...newMisc };
}
/* ------------------------ Group-Root --------------------------------------- */
function setGroupRootState ( taxa, FS, group ) {                     /*dbug-log*///console.log( '              --setGroupRootState taxa[%O] FS[%O] group[%O]', taxa, FS, group );
    const root = group.roots[ getRootId( FS, group ) ]; /*dbug-log*///console.log( '                  --root[%O]', root );
    const newState = {
        misc: getRootMiscData( taxa, FS, root ),
        shown: Object.keys( group.roots ).length > 1,
        value: root.id
    };                                                              /*dbug-log*///console.log( '                  --newState[%O]', newState );
    setFieldState( FS.group, 'Group-Root', newState );
}
function getRootId ( FS, group ) {
    const rootId = FS.fields[ 'Group-Root' ].value;              /*dbug-log*///console.log( '                      --getRootId group[%O] rootId[%s]', group, rootId );
    return rootId || Object.keys( group.roots )[ 0 ];
}
function getRootMiscData( taxa, FS, root ) {
    const newMisc = {
        rcrd: root,
        taxon: taxa[ root.taxon ]
    };
    return { ...FS.fields.Group.misc, ...newMisc };
}
/* ========================= UPDATE ========================================= */
export function updateTaxonGroupState( fLvl ) {
    const FS = getFormState( fLvl );
    setTaxonGroupState( getRecords(), FS );
}