/**
 * Manages access to the state of the data-entry forms.
 *
 * EXPORT DEFAULT
 *     FM
 *
 * TOC
 *     FORM MANAGER
 *     LEVEL KEY
 *     LEVEL STATE
 *         GET
 *         SET
 *         UTIL
 *     RECORDS
 *     TYPES
 */
import { cloneObj , lcfirst } from '@util';
import * as _t from '@types';
import * as _m from '@dataentry/model';
/* ======================== FORM MANAGER ==================================== */
const levels = [ 'top', 'sub', 'sub2' ] as const;

const _state = {
    _forms: {} as FormStates, // Stores the state of active forms, ie: { top: {}, sub: {}, sub2: {} }
    _levels: levels,
    _records: {} as _t.EntityRecordsMap // Cached record-data used for the top and all its possible sub-forms.
}

export const FM = ( () => {
    return {
        clear,
        clearLevelState,
        getRecordCache,
        getEntityLevel,
        getLevelKeys,
        getLevelState,
        initLevelState,
        setLevelState,
        setRecordsCache
    }
} )();

function clear () {
    _state._forms = {};
    _state._records = {};
}
/* ====================== LEVEL KEY ========================================= */
function getEntityLevel ( entity: _m.FormEntity ): _m.FormGroup {               //console.log( 'getEntityLevel[%s] %O', entity, cloneObj( _state ) );
    return _state._forms[ entity ]!;
}
function getLevelKeys (): _m.FormGroup[] {
    return [ ..._state._levels ];
}
/* ====================== LEVEL STATE ======================================== */
/* --------------------------- GET ------------------------------------------ */
function getLevelState ( level: _m.FormGroup ): _m.FormState | null {
    return level in _state._forms ? copyFormLevel( level ) : null;
}
function copyFormLevel( lvl: _m.FormGroup ): _m.FormState | null {
    const FS = _state._forms[ lvl ];
    return FS ? deepCopyState( FS ) : null;
}
/* --------------------------- SET ------------------------------------------ */
function initLevelState ( newState: _m.FormState ) {                            //console.log( 'initLevelState[%s] %O', newState.group, newState );
    _state._forms[ lcfirst( newState.name ) as _m.FormEntity ] = newState.group;
    setLevelState( newState.group, newState );
    return newState;
}
function setLevelState ( level: _m.FormGroup, newState: _m.FormState ) {
    _state._forms[ level ] = { ..._state._forms[ level ], ...deepCopyState( newState ) };
}
function clearLevelState ( level: _m.FormGroup ) {
    const name = _state._forms[ level ]?.name;
    delete _state._forms[ lcfirst<_m.FormEntity>( name! ) ];
    delete _state._forms[ level ];
}
/* --------------------------- UTIL ----------------------------------------- */
function deepCopyState( FS: _m.FormState ): _m.FormState {
    const copy = cloneObj( FS );
    if ( FS.handlers ) copy.handlers = { ...FS.handlers };
    return copy;
}
/* ======================== RECORDS ========================================= */
function getRecordCache(): _t.EntityRecordsMap {
    return _state._records;
}
function setRecordsCache ( records: _t.EntityRecordsMap ) {
    _state._records = { ..._state._records, ...records };
}
/* =========================== TYPES ======================================== */
type FormStates = {
        [level in _m.FormGroup]?: _m.FormState;
    } &  {
        [entity in _m.FormEntity]?: _m.FormGroup;
    };