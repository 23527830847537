/**
 * Handles various edge-cases that require special handling.
 *
 * Export
 *     handleEdgeCaseProcessing
 *
 * TOC
 *     TRACK LOCAL-PROP EDITS
 */
import { DataEntryResults, EditObj, EditedIds } from '@localdata/sync/data-entry';
import { getEntities } from '@localdata/util';
import * as _t from '@types';

export function handleEdgeCaseProcessing( data: DataEntryResults ): void {
    trackEditsToLocalProps( data );
}
/* ================== TRACK LOCAL-PROP EDITS ================================ */
/**
 * Some data properties only exist in local storage. Edits that affect this data
 * are tracked here and will be updated properly during the record entry process.
 */
export function trackEditsToLocalProps ( data: DataEntryResults ): void { /*dbug-log*///console.log('   -- trackEditsToLocalProps data[%O]', data);
    if ( !data.coreEdits ) return;
    trackLocalEdits( data, data.coreEdits );
    // trackLocalEdits(data, data.detailEdits); //Not used yet.
}
function trackLocalEdits ( data: DataEntryResults, edits: EditObj ): void {
    const map = {
        ParentTaxon: ifTaxonGroupChanged
    } as const;
    _t.objectKeys( map ).forEach( trackEditsToRelatedData );

    function trackEditsToRelatedData ( prop: keyof typeof map ): void {
        if ( !( prop in edits ) ) return;
        map[ prop ]( data, edits, edits[ prop ]! );
    }
}
function ifTaxonGroupChanged ( data: DataEntryResults, edits: EditObj, pTxnId: EditedIds ): void {
    const taxa = getEntities( 'taxon' );
    const newPrnt = taxa[ pTxnId.new ];
    const oldPrnt = taxa[ pTxnId.old ];                             /*dbug-log*///console.log('   -- ifTaxonGroupChanged newPrnt[%O] old[%O]', newPrnt, oldPrnt);
    if ( newPrnt && oldPrnt ) {
        trackEditsToData( 'Group', newPrnt.group.displayName, oldPrnt.group.displayName, edits );
        trackEditsToData( 'GroupRoot', newPrnt.group.root.name, oldPrnt.group.root.name, edits );
    } else {
        console.error( 'Edited Taxon-parent not found' )
    }
}
function trackEditsToData ( field: string, newVal: any, oldVal: any, edits: EditObj ): void {
    if ( newVal === oldVal ) return;
    edits[ field ] = { new: newVal, old: oldVal };
}