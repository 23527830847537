/**
 * Updates local data after a user-named list is updated: interaction lists or
 * filter sets.
 *
 * Export
 *     updateUserNamedList
 */
import { EditObj } from './data-entry-main';
import { getFilterListGroupString } from '@localdata/init';
import { getData, setData } from '@localdata/util';
import * as _t from '@types';
/* ============================ TYPES ======================================= */
type UserNamedEntryResults = {
    name: string;
    entity: _t.UserNamedList | string;
    edits: EditObj;
};
type Action = 'create' | 'delete' | 'edit';
/* ======================== UPDATE STORED-LIST ============================== */
export function updateUserNamedList (
    data: _t.UserNamedList | UserNamedEntryResults,
    action: Action
): Promise<void> {                                                  /*dbug-log*///console.log( '   --Updating [%s] stored list data[%O]', action, data );
    const params = initUpdateParams( data, action );
    return getAndUpdateStoredList( params )
        .then( trackTimeUpdated.bind( null, 'UserNamed', params.list ) );
}
/* ------------------------ GET LIST PIECES --------------------------------- */
type ListUpdateParams = {
    action: Action;
    edits?: EditObj | null;
    list: _t.UserNamedList;
    nameKey: 'savedFilterNames' | 'dataListNames';
    names: _t.IdsByName | { [key: string]: { group: string, value: number; }; };
    rcrdKey: 'savedFilters' | 'dataLists';
    rcrds: _t.EntityRecords;
};
type PartialParams = Omit<ListUpdateParams, 'rcrds' | 'names'>;
function initUpdateParams (
    data: _t.UserNamedList | UserNamedEntryResults,
    action: Action
): PartialParams {
    const list = getListEntity( data, action );
    return {
        action: action,
        edits: ( 'edits' in data ) ? data.edits : null,
        list: list,
        rcrdKey: list.type == 'filter' ? 'savedFilters' : 'dataLists',
        nameKey: list.type == 'filter' ? 'savedFilterNames' : 'dataListNames',
    };
}
function getListEntity (
    data: _t.UserNamedList | UserNamedEntryResults,
    action: Action
): _t.UserNamedList {
    if ( action === 'delete' ) return data as unknown as _t.UserNamedList;
    return JSON.parse( ( data as UserNamedEntryResults ).entity as string );
}
/* ---------------------- GET AND UPDATE LIST ------------------------------- */
//todo: use compose pattern to init params
function getAndUpdateStoredList ( params: PartialParams ): Promise<void> {
    return getStoredDataParams( params )
        .then( syncListData );
}
function getStoredDataParams ( params: PartialParams ): Promise<ListUpdateParams> {
    return getData( [ params.rcrdKey, params.nameKey ] )
        .then( addStoredDataToParams );

    function addStoredDataToParams ( stored: any ): ListUpdateParams {
        const dataParams = {
            rcrds: stored[ params.rcrdKey ] as _t.EntityRecords,
            names: stored[ params.nameKey ] as _t.IdsByName,
        };
        return Object.assign( params, dataParams ) as ListUpdateParams;
    }
}
/* ---------------------- UPDATE AND STORE ---------------------------------- */
function syncListData ( p: ListUpdateParams ): void {               /*dbug-log*///console.log('syncListData params[%O]', p);
    const syncHander = p.action == 'delete' ? removeListData : updateListData;
    syncHander( p );

    setData( p.rcrdKey, p.rcrds );
    setData( p.nameKey, p.names );
}
function removeListData ( p: ListUpdateParams ): void {
    delete p.rcrds[ p.list.id ];
    delete p.names[ p.list.displayName ];
}
function updateListData ( p: ListUpdateParams ): void {
    p.rcrds[ p.list.id ] = p.list;
    p.names[ p.list.displayName ] = getUpdatedListData( p );          /*dbug-log*///console.log('--updateListData [%O]', listData);
    if ( p.edits?.displayName ) delete p.names[ p.edits.displayName.old ];
}
function getUpdatedListData ( p: ListUpdateParams ): number | { group: string, value: number; } {
    if ( p.list.type === 'interaction' ) return p.list.id;
    return { value: p.list.id, group: getFilterListGroupString( p.list ) };
}
/* ----------------------- TRACK TIME-UPDATED ------------------------------- */
function trackTimeUpdated ( entity: string, rcrd: _t.EntityRecord ): void {
    getData( 'lclDataUpdtdAt' )
        .then( stateObj => {
            ( stateObj as _t.EntityStateDates )[ entity ] = rcrd.serverUpdatedAt;
            setData( 'lclDataUpdtdAt', stateObj! );
        } );
}