/**
 * Exports a csv of the interaction records displayed in the table, removing
 * tree rows and flattening tree data where possible: currently only taxon.
 * For taxon csv export: The relevant tree columns are shown and also exported.
 *
 * Export
 *     exportCsvData
 *
 * TOC
 *     FILL EXPORT DATA
 *         INTERACTION DETAILS
 *         TAXONOMY
 *     UI
 *         SET UP
 *         RESET
 *         TOGGLE COLUMNS
 */
import { table, ui } from '@explore';
import { getData } from '@localdata';
import { isEditorUser, isManagerUser } from '@util';

let tblState;

export function exportCsvData () {                                   /*perm-log*/console.log( '       /--exportCsvData' );
    tblState = table.getState();
    prepForExport();
    fillTableWithExportOnlyData()
        .then( exportCsv )
        .then( returnTableState );
}
function exportCsv () {
    const params = {
        onlySelected: true,
        fileName: 'Bat Eco-Interaction Records.csv',
        // customHeader: "This is a custom header.\n\n",
        // customFooter: "This is a custom footer."
    };
    tblState.api.exportDataAsCsv( params );
}
/* ========================= FILL EXPORT DATA =============================== */
/**
 * Adds export-only data to the interaction data:
 *     Taxon - A data-point is added for each group-rank in both roles, and
 *         stores the ancestry for the taxa in each interaction.
 *     Location - Elevation, Elevation Max, Latitude, and Longitude
 */
function fillTableWithExportOnlyData () {
    return getData( [ 'interaction', 'taxon' ] )
        .then( fillInteractionsWithExportData );
}
function fillInteractionsWithExportData ( rcrds ) {
    tblState.api.forEachNodeAfterFilter( fillRowExportData );
    tblState.api.refreshView();

    function fillRowExportData ( row ) {
        if ( row.data.entity !== 'Interaction' ) return;
        const intRcrd = rcrds.interaction[ row.data.id ];
        fillInteractionData( intRcrd, row.data );
        fillRowTaxonomy( 'subject', intRcrd, row.data, rcrds.taxon );
        fillRowTaxonomy( 'object', intRcrd, row.data, rcrds.taxon );
    }
}
/* ----------------------- INTERACTION DETAILS ------------------------------ */
/**
 * Fills in all interaction data hidden from the table.
 * @param  {object} rowData All data for the table-row.
 * @param  {object} intRcrd The interaction-record for the current row.
 */
function fillInteractionData ( intRcrd, rowData ) {                    /*dbug-log*///console.log('           --fillInteractionData rowData[%O] intRcrd[%O]', rowData, intRcrd)
    rowData.date = intRcrd.date;
    rowData.note = intRcrd.note;
    rowData.pages = intRcrd.pages;
    rowData.quote = intRcrd.quote;
}
/* ---------------------------- TAXONOMY ------------------------------------ */
/** Adds the taxonomy of the Subject|Object taxon to the row data. */
function fillRowTaxonomy ( role, intRcrd, rowData, taxaRcrds ) {
    const txn = taxaRcrds[ intRcrd[ role ] ];
    fillTaxonomy( txn );
    /** Adds the taxonym to the role+rank column. Recursively adds taxonomy. */
    function fillTaxonomy ( txn ) {
        rowData[ getColName( role, txn ) ] = txn.name;
        if ( !txn.isRoot ) { fillTaxonomy( taxaRcrds[ txn.parent ] ); }
    }
}
function getColName ( role, txn ) {
    const prefix = role == 'subject' ? 'subj' : 'obj';
    return prefix + txn.rank.displayName;
}
/* ========================= UI ============================================= */
/* ------------------------- SET UP ----------------------------------------- */
function prepForExport () {
    toggleCsvButton( false );
    ui.fadeTable();
    setUpTableForExport();
}
function setUpTableForExport () {
    toggleExportData();
    toggleUiTableColumns( false );
    selectInteractionDataRowsForExport( tblState.api );
}
function toggleExportData ( show = true ) {
    toggleIntExportData( show );
    toggleLocExportData( show );
    toggleTxnExportData( show );
    toggleEditorData( !show );
}
function selectInteractionDataRowsForExport ( tblApi ) {
    tblApi.expandAll();
    tblApi.forEachNodeAfterFilter( selectInteractions );
}
/** An interaction row has 'interactionType' data. */
function selectInteractions ( row ) {
    if ( row.data.entity !== 'Interaction' ) return;
    row.setSelected( true );
}
/* ------------------------------ RESET ------------------------------------- */
function returnTableState () {
    table.toggleTreeRows( false );
    toggleExportData( false );
    toggleUiTableColumns( true );
    table.hideUnusedColFilterMenus();
    ui.showTable();
    toggleCsvButton();
    if ( tblState.curFocus === 'taxa' ) table.toggleRowsOneLevel();
}
function toggleCsvButton ( enable = true ) {
    const onClick = enable ? exportCsvData : Function.prototype;
    $( 'button[name="csv"' ).attr( 'disabled', !enable ).on( 'click', onClick );
}
/* ---------------------- TOGGLE COLUMNS ------------------------------------ */
function toggleEditorData ( showing ) {
    toggleTableColumns( [ 'edit' ], showing ? isEditorUser() : false );
    toggleTableColumns( [ 'reviewedAt' ], showing ? isManagerUser() : false );
    toggleTableColumns( [ 'updatedBy' ], false );
}
function toggleIntExportData ( showing ) {
    const cols = [ 'date', 'note', 'pages', 'quote' ];
    toggleTableColumns( cols, showing );
}
function toggleLocExportData ( showing ) {
    if ( !showing && tblState.curFocus === 'locs' ) return; //Cols already shown
    const cols = [ 'lat', 'lng', 'elev', 'elevMax' ];
    toggleTableColumns( cols, showing );
}
function toggleTxnExportData ( showing ) {
    const ranks = Object.keys( tblState.data.rankNames );
    const subjCols = ranks.map( r => 'subj' + r );
    const objCols = ranks.map( r => 'obj' + r );
    const cols = subjCols.concat( objCols );
    toggleTableColumns( cols, showing );
}
function toggleTableColumns ( cols, showing ) {
    tblState.columnApi.setColumnsVisible( cols, showing );
}
function toggleUiTableColumns ( showing ) {
    toggleTableColumns( [ 'name', 'intCnt', 'map', 'subject', 'object' ], showing );
}