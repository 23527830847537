/**
 * Updates the state and UI when an author|editor field is cleared. If the field
 * following is not empty, the blank in the order is validated. All excess empty
 * fields will be removed.
 *
 * Export
 *     onAuthFieldClear
 *     rmvExtraMultiFields
 *
 * TOC
 *     REMOVE DYNAMIC FIELD
 *         REMOVE EXTRA FIELDS
 */
/* UTIL */
import * as _t from '@types';
/* DATA-ENTRY */
import { onCitationFieldChange } from '@controller/source';
import { components, model, state } from '@dataentry';
import { removeAuthField } from '@controller/source/author';
import { handleBlanks } from './val-order';
import { ifNoneStillSelectedEnableOtherType } from './toggle-other-type';
import { ASelectParams, isDynamicFieldEmpty } from './on-change';
/**
 * Updates the state and UI when an author|editor field is cleared. If the field
 * following is not empty, the blank in the order is validated. All excess empty
 * fields will be removed.
 */
export function onAuthFieldClear( a: ASelectParams ): void {               /*dbug-log*///console.log( '--onAuthFieldClear [%s] cleared[%s] ttl[%s]', a.type, a.ord, a.ttl );
    onClearHandleSimpleUpdates( a.fLvl, a.type, a.ord );
    if ( !isDynamicFieldEmpty( a.type, a.ttl ) ) return handleBlanks( a.fLvl, a.type, a.ord );
    handleRemoveDynamicField( a );
}
function onClearHandleSimpleUpdates(
    fLvl: model.FormGroup,
    type: _t.AuthorType,
    ord: number
): void {
    components.storeMultiSelectValue( fLvl, ord, type, null );
    onCitationFieldChange( fLvl );
}
/* ===================== REMOVE DYNAMIC FIELD =============================== */
function handleRemoveDynamicField( a: ASelectParams ): void {
    removeAuthField( a.fLvl, a.type, a.ttl-- );
    rmvExtraMultiFields( a.type, a.ttl, a.fLvl )
    ifNoneStillSelectedEnableOtherType( a.fLvl, a.type, a.ord );
}
/* ----------------------- REMOVE EXTRA FIELDS ------------------------------ */
/**
 * Ensure that only one empty combo is at the end of the field by removing extras.
 * @param  {string}  type  Author field: Authors or Editors
 * @param  {number}  ttl    Current count of combos in the field
 * @param  {string}  fLvl   Form group level
 * @param  {boolean} leaveOne  True: one empty combo is left at the end of field
 */
export function rmvExtraMultiFields(
    type: _t.AuthorType,
    ttl: number,
    fLvl: model.FormGroup,
    leaveOne = true
): void {                                                           /*dbug-log*///console.log( '--rmvExtraMultiFields type[%s] ttl[%s] leaveOne?[%s]', type, ttl, leaveOne );
    const newCnt = removeExtraCombos( type, ttl, fLvl, leaveOne );  /*dbug-log*///console.log( '       --newCnt?[%s]', newCnt );
    state.setFieldState( fLvl, type, { count: newCnt } );
    handleBlanks( fLvl, type );
}
function removeExtraCombos(
    type: _t.AuthorType,
    ttl: number,
    fLvl: model.FormGroup,
    leaveOne = true
): number {
    while ( ifMoreThanOneComboAndLastIsEmpty( type, ttl ) ) {
        if ( isLastValidBlank( type, ttl, leaveOne ) ) return ttl;
        removeFieldAndUpdateState( type, ttl, fLvl );
        --ttl;
    }
    return ttl;
}
function ifMoreThanOneComboAndLastIsEmpty( type: _t.AuthorType, ttl: number ): boolean {
    return ttl > 1 && isDynamicFieldEmpty( type, ttl );
}
function isLastValidBlank( type: _t.AuthorType, ttl: number, leaveOne: boolean ): boolean {
    return leaveOne && ifNextComboFilled( type, ttl );
}
function removeFieldAndUpdateState( type: _t.AuthorType, ttl: number, fLvl: model.FormGroup ): void {
    removeAuthField( fLvl, type, ttl );                             /*dbug-log*///console.log( '  --Removing [%s]', ttl );
    components.storeMultiSelectValue( fLvl, ttl, type, null );
}
function ifNextComboFilled( type: _t.AuthorType, ttl: number ): boolean {
    return !isDynamicFieldEmpty( type, ttl - 1 );
}