/**
 * When Source data is changed, all related citation fullText is regenerated.
 * For data-cleanup: Regenerates all citations for passed ids.
 *
 * Export
 *     ifSourceDataEditedUpdatedCitations
 *     updateCitations
 *
 * TOC
 *     SYNC RELATED CITATIONS
 *         CHECK FOR RELEVANT DATA-UPDATES
 *     UPDATE RELATED CITATIONS
 *         REGENERATE CITATIONS
 *         SERVER-DATA UPDATE
 *         LOCAL-DATA UPDATE
 */
import * as _t from '@types';
import { generateCitationText, lcfirst, sendAjaxQuery } from '@util';
import { clearTempMemory, getEntities, getEntity, initMemoryDataObj } from '@localdata/util';
import { DataEntryResults, FormReturnData, hasEdits, handleLocalDataUpdate } from '@localdata/sync';
import { getEntityFormLevel } from '@dataentry/state';
import { errUpdatingData } from '@dataentry/alerts';
import { onSubmitError } from './on-error.js';

let rcrds: {
    author: _t.EntityRecords,
    citation: _t.EntityRecords,
    source: _t.EntityRecords,
    publisher: _t.EntityRecords,
};
/* ==================== SYNC RELATED CITATIONS ============================== */
// window.setTimeout(updateAllCitations, 1000);  //Used for data-cleanup
export function ifSourceDataEditedUpdatedCitations ( data: DataEntryResults ): Promise<DataEntryResults> {
    if ( hasNoChangesToCitations( data ) ) return Promise.resolve( data );/*dbug-log*///console.log( 'ifSourceDataEditedUpdatedCitations data = %O', data );
    return handleSourceDataUpdate( data )
        .then( () => data );
}
/* -------------------- CHECK FOR RELEVANT DATA-UPDATES --------------------- */
function hasNoChangesToCitations ( data: DataEntryResults ): boolean {
    return hasNoEditedSourceData( data ) || isReviewEntry( data );
}
function hasNoEditedSourceData ( data: DataEntryResults ): boolean {
    return data.core !== 'source' ||
        data.detail === 'citation' ||
        !( hasEdits( data.coreEdits ) || hasEdits( data.detailEdits ) );
}
function isReviewEntry ( data: DataEntryResults ): boolean {
    const stage = data.coreEntity.review?.stage;
    return stage && stage !== 'Approved';
}
/* =================== HANDLE SOURCE DATA UPDATE ============================ */
function handleSourceDataUpdate( data: DataEntryResults ): Promise<void> {
    const processId = 'citationSync';
    return initMemoryDataObj( processId )
        .then( initEntityRecordData )
        .then( () => updateRelatedCitations( data ) )
        .then( () => clearTempMemory( processId ) );
}
function initEntityRecordData (): void {
    rcrds = {
        author: getEntities( 'author' ),
        citation: getEntities( 'citation' ),
        source: getEntities( 'source' ),
        publisher: getEntities( 'publisher' )
    };
}
/* ==================== UPDATE RELATED CITATIONS ============================ */
/** Updates the citations for edited Authors, Publications or Publishers. */
function updateRelatedCitations ( data: DataEntryResults ): Promise<void> {
    const [ srcType, citIds ] = getCitationUpdateParams( data );      /*dbug-log*///console.log( 'updateRelatedCitations. srcType[%s] citType[%S] data[%O]', srcType, citIds, data );
    if ( !citIds.length ) return Promise.resolve();
    return updateCitations( citIds, srcType );
}
function getCitationUpdateParams ( data: DataEntryResults ): [string, number[]] {
    const srcType = data.coreEntity.sourceType.displayName;
    const citIds = getCitationIds( srcType, data );
    return [ srcType, citIds ];
}
function getCitationIds ( srcType: string, data: DataEntryResults ): number[] {
    switch ( srcType ) {
        case "Author":
            return getChildCites( data.coreEntity.contributions );
        case "Publication":
            return data.coreEntity.children;
        case "Publisher":
            return getChildCites( data.coreEntity.children );
        default:
            console.error( `Unknown source-type ${ srcType }` );
            return [];
    }
}
function getChildCites ( publications: number[] ): number[] {
    const citIds: number[] = [];
    publications.forEach( addSrcCitations );
    return citIds;

    function addSrcCitations ( id: number ): void {
        const src = getEntity( rcrds.source, id, 'source' );
        if ( src?.citation ) {
            citIds.push( src.id );
        } else {
            src?.children.forEach( ( cId: number ) => citIds.push( cId ) );
        }
    }
}
/* -------------------- REGENERATE CITATIONS -------------------------------- */
function updateCitations (
    citIds: number[],
    srcType: string
): Promise<void> {                                                  /*dbug-log*///console.log('updateCitations. citIds = %O, rcrds = %O', citIds, rcrds);
    const updateCitations = citIds.map( id => updateCitText( id, srcType ) );
    return Promise.all( updateCitations )
        .then( onUpdateSuccess );
}
function updateCitText ( id: number, srcType: string ): JQuery.Promise<DataEntryResults> {
    const citSrc = getEntity<_t.CitationSource>( rcrds.source, id );
    const params = {
        cit: getEntity<_t.Citation>( rcrds.citation, citSrc.citation ),
        citSrc: citSrc,
        pubSrc: getEntity<_t.PublicationSource>( rcrds.source, citSrc.parent ),
        rcrds: rcrds
    };
    const citText: string = generateCitationText( params );     /*dbug-log*///console.log('citText = %O', citText)
    return updateCitationData( citSrc, citText, srcType );
}
/** Regenerates all citation data in server data. Used for data cleanup. */
// function updateAllCitations() {                                     /*dbug-log*///console.log('updateAllCitations');
//     _db('getData', [['author', 'citSrcs', 'citation', 'publisher', 'source']])
//     .then(data => {
//         srcRcrds = data.source;
//         updateCitations(data.citSrcs, data)
//     });
// }
/* -------------------- SERVER-DATA UPDATE ---------------------------------- */
/** Sends ajax data to update citation and source entities. */
function updateCitationData (
    citSrc: _t.CitationSource,
    text: string,
    srcType: string
): JQuery.Promise<DataEntryResults> {
    const data = { srcId: citSrc.id, text: text };
    return sendAjaxQuery( data, 'crud/edit/citation', Function.prototype, citUpdateErr );

    function citUpdateErr ( _1: Error ): void {
        const fLvl = getEntityFormLevel( lcfirst( srcType ) );
        onSubmitError( fLvl, ...arguments );
    }
}
/* --------------------- LOCAL-DATA UPDATE ---------------------------------- */
function onUpdateSuccess ( ajaxData: DataEntryResults[] ): Promise<void> {
    return Promise.all( ajaxData.map( storeUpdatedCitation ) )
        .then();
}
function storeUpdatedCitation( data: DataEntryResults ): Promise<void> {
    return handleLocalDataUpdate( data )
        .then( handleFails );
}
function handleFails( data: FormReturnData ): void {
    if ( data.fails ) errUpdatingData( 'dataSyncFailures', data.fails );
}