/**
 * Finalizes the publication UI during init and when rebuilding the form.
 *
 * Export
 *      finishFieldLoad
 *
 * TOC
 *      TYPE FIELDS
 */
import { model, state } from '@dataentry';
/* ===================== TYPE FIELDS ======================================== */
export function finishFieldLoad( fLvl: model.FormGroup ) {
    showNoteIfBothEditorAndAuthorFieldsAvailable( fLvl );
}
/** Shows the user a note above the author and editor elems. */
function showNoteIfBothEditorAndAuthorFieldsAvailable( fLvl: model.FormGroup ) {
    if ( !areEditorAndAuthorFieldsAvailable( fLvl ) ) return;
    const note =`<div class="i g center-self";>Note: This publication type can have either authors OR editors.</div>`;
    $( '#Author_f-cntnr' ).parent().before( note );
}
function areEditorAndAuthorFieldsAvailable( fLvl: model.FormGroup ): boolean {
    return state.areFieldsShown( fLvl, [ 'Author', 'Editor' ] );
}