/**
 * Handles event handlers for data-review forms.
 * + Submit
 *     - Enable on review-action combo change
 *     - actions
 *         - approve
 *             - push data to server
 *             - return notes to contributor
 *         - pause
 *             - show submit modal to give option to release all data or hold for later
 *         - skip
 *             - ends review of data-set, unlocking data
 *         - reject
 *             - return notes to contributor
 *             - show submit modal to confirm rejection of current and option to reject all
 *         - return
 *             - return notes to contributor
 *             - show submit modal to confirm return of current and option to return all
 * + Cancel
 *     - Modal:
 *         Quit data set and load next
 *
 * Export
 *     updateSubmitElemsForReview
 *
 * TOC
 *     SUBMIT
 *         CONFIRMATION MODAL
 *     CANCEL
 *         CONFIRMATION MODAL
 */
import { getSelTxt, isComboActive, showSaveModal } from '@elems';
import { components, state, submit } from '@dataentry';
import { handleReviewSubmitElemsForRole } from './submit-for-role.js';

export function updateSubmitElemsForReview ( fLvl, rEntry ) {
    modifyCancel( fLvl );
    modifySubmit( fLvl );
    setAfterFormCloseContinueReviewHandler( fLvl, rEntry.id );
    if ( fLvl === 'top' ) resetMainFormExitButtonToEndReview();
    handleReviewSubmitElemsForRole( rEntry );
}
function setAfterFormCloseContinueReviewHandler ( fLvl, pId ) {     /*dbug-log*///console.log('       -- setAfterFormCloseContinueReviewHandler fLvl[%s] pId[%s]', fLvl, pId);
    const continueReview = getContinueReviewHandler( fLvl, pId );
    state.addFormHandler( fLvl, 'afterFormClose', continueReview );
}
function getContinueReviewHandler ( fLvl, pId ) {
    return fLvl === 'top' ?
        components.initDataReviewWizard.bind( null, pId ) : //Review process is fully closed between sets
        components.continueReview.bind( null, fLvl, pId );
}
/* ========================== SUBMIT ======================================== */
function modifySubmit ( fLvl ) {
    const submit = getReviewSubmitFunc( fLvl );                     /*dbug-log*///console.log(' -- modifySubmit fLvl[%s] submit[%O]', fLvl, submit);
    const modalFunc = showSubmitReviewModal.bind( null, submit, fLvl );
    state.setFormHandler( fLvl, 'submit', modalFunc );
}
function getReviewSubmitFunc ( fLvl ) {
    const custom = state.getFormHandler( fLvl, 'submit' );
    return custom || submit.submitForm.bind( null, fLvl );
}
/* -------------------- CONFIRMATION MODAL ---------------------------------- */
/** Shows additional information relevant to the data-review submit action. */
function showSubmitReviewModal ( submit, fLvl ) {
    const stage = getReviewSubmitStage( fLvl );
    const infoTxt = getDataReviewInfo( fLvl, stage );               /*dbug-log*///console.log('-- showSubmitReviewModal stage[%O] txt[%s] submit[%O]', stage, infoTxt, submit)
    loadModalMessageAndDisableSubmit( fLvl, infoTxt, stage, submit );
}
function getReviewSubmitStage ( fLvl ) {
    return isComboActive( fLvl + 'Action', true ) ?
        getSelTxt( fLvl + 'Action' ) :
        state.getReviewStage( fLvl );
}
function getDataReviewInfo ( fLvl, stage ) {
    const info = getReviewInfoTextMap( fLvl, stage );
    if ( !info ) {return;}
    const main = `<b>${ info.self }</b>`;
    const general = info.related ? `<br>(${ info.related })` : '';
    return `<div>${ main }${ general }</div>`;
}
function getReviewInfoTextMap ( fLvl, stage ) {
    const map = {
        Approve: {  /* Data-manager */
            self: 'Data-set will be entered into the BatBase database.',
            related: 'All sub-data must be approved before set is valid.'
        },
        Approved: { /* Data-contributor */
            self: 'Thank you for your contribution!'
        },
        Pause: {    /* Data-manager */
            self: 'Data-set will close and be held until you continue the review.',
            related: 'Referencing data will be held until sub-data are fully approved.'
        },
        Reject: {   /* Data-manager */
            self: 'Data will be deleted after the contributor reviews the notes.',
            related: 'Referencing data will be returned to the contributor.'
        },
        Rejected: { /* Data-contributor */
            self: 'Thank you for participating in our project!'
        },
        Return: {   /* Data-manager */
            self: 'Data will be returned to the contributor for further review.',
            related: 'Referencing data will be held until sub-data are fully approved.'
        },
        Returned: {   /* Data-contributor */
            self: 'Data will be resubmitted for further action.',
        },
        Skip: {     /* Data-manager */
            self: 'Ends the current review of this data-set.',
            related: 'Data is available for future review.'
        },
    };
    return map[ stage ];
}
function loadModalMessageAndDisableSubmit ( fLvl, infoTxt, stage, submit ) {
    const config = getSubmitModalConfig( fLvl, infoTxt );
    const button = getButtonConfig( stage, submit, fLvl );
    const events = { onexit: () => enableSubmit( fLvl ) };
    showSaveModal( config, button, events );
    $( `#${ fLvl }-submit` ).css( { opacity: .3, cursor: 'not-allowed' } );
}
function getSubmitModalConfig ( fLvl, infoTxt ) {
    return {
        html: infoTxt,
        position: 'left',
        selector: `#${ fLvl }-submit`,
        title: 'Confirm Review Action'
    };
}
function enableSubmit ( fLvl ) {
    $( `#${ fLvl }-submit` ).css( { opacity: 1, cursor: 'pointer' } );
}
function getButtonConfig ( stage, submit, fLvl ) {
    return {
        onConfirm: () => handleDataReviewSubmit( stage, submit, fLvl )
    };
}
function handleDataReviewSubmit ( stage, submit, fLvl ) {           /*dbug-log*///console.log(' -- handleDataReviewSubmit fLvl[%s] stage[%s] submit?[%O] ', fLvl, stage, submit);
    return [ 'Approve', 'Returned' ].indexOf( stage ) !== -1 ?
        submit() : closeSetAndContinueReview( fLvl );
}
function closeSetAndContinueReview ( fLvl ) {                       /*dbug-log*///console.log(' -- closeSetAndContinueReview fLvl[%s] ', fLvl);
    components.toggleWaitOverlay( true );
    submit.submitDataReview( null, {}, fLvl );
}
/* ========================== CANCEL ======================================== */
/**
 * Shows modal that prompts user to specify whether to cancel the current data-set
 * and continue the review process or to exit the review process entirely.
 */
function modifyCancel ( fLvl ) {                                    /*dbug-log*///console.log(' -- modifyCancel fLvl[%s]', fLvl);
    const onCancel = showCancelConfirmationModal.bind( null, fLvl );
    state.setFormHandler( fLvl, 'cancel', onCancel );
}
/* -------------------- CONFIRMATION MODAL ---------------------------------- */
function showCancelConfirmationModal ( fLvl ) {                     /*dbug-log*///console.log(' -- showCancelConfirmationModal fLvl[%s]', fLvl);
    const button = { onConfirm: components.endReview };
    showSaveModal( getCancelModalConfig( fLvl ), button );
}
function getCancelModalConfig ( fLvl ) {
    return {
        position: 'left',
        selector: `#${ fLvl }-cancel`,
        title: 'Exit Data-Review?'
    };
}
/** The exit button in the top-right of the form closes the review process. */
function resetMainFormExitButtonToEndReview () {                    /*dbug-log*///console.log(' -- resetMainFormExitButtonToEndReview');
    $( '#exit-form' ).off( 'click' ).on( 'click', components.endReview );
}