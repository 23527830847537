/**
 * Adds the new sub-form's data-review elems to the form's side-panel and closes
 * all parent sections in the side panel. When the sub-form closes, the immediate
 * parent section is reopened.
 *
 * EXPORT
 *     addSubFormDataReviewElems
 *
 * TOC
 *     SUB-FORM
 *         APPEND
 *         ON FORM CLOSE
 *     TOGGLE PARENT-SECTIONS
 */
import { state } from '@dataentry';
import { setElemExpandableListeners, toggleContent } from '@elems';
/* ====================== SUB-FORM SECTION ================================== */
export default function addSubFormDataReviewElems( fState, elems ) {  /*dbug-log*///console.log("+-- addSubFormDataReviewElems fState[%O] elems[%O]", fState, elems);
    buildAndAppendReviewElems( fState, elems );
    toggleParentReviewSection( fState.group, false );
}
/* --------------------------- APPEND --------------------------------------- */
function buildAndAppendReviewElems( fState, elems ) {
    $( '#form-panel' ).append( elems );
    setElemExpandableListeners( $( `#${ fState.group }-rvw` ) );
    addCloseSidePanelHandler( fState.group );
}
function addCloseSidePanelHandler( fLvl ) {
    const closePanel = onSubFormCloseUpdateReviewPanel.bind( null, fLvl );
    state.addFormHandler( fLvl, 'onFormClose', closePanel );
}
/* ------------------------- ON FORM CLOSE ---------------------------------- */
function onSubFormCloseUpdateReviewPanel( fLvl ) {                    /*dbug-log*///console.log("-- onSubFormCloseUpdateReviewPanel fLvl[%s]", fLvl);
    toggleParentReviewSection( fLvl );
    fadeAndRemoveSection( fLvl );
    fadeAndRemoveSection( state.getFormLevel( 'child', fLvl ) );
}
function fadeAndRemoveSection( fLvl ) {
    if ( !$( `#${ fLvl }-rvw` )[ 0 ] ) return;
    $( `#${ fLvl }-rvw` ).fadeTo( 500, 0, () => $( `#${ fLvl }-rvw` ).remove() );
}
/* ===================== TOGGLE PARENT-SECTIONS ============================= */
/**
 * On sub-form init: all parent-form review-sections are closed.
 * On sub-form close: the next parent-form review-section is expanded.
 */
function toggleParentReviewSection( fLvl, expand = true ) {
    const prntLvl = state.getFormLevel( 'parent', fLvl );      /*dbug-log*///console.log("-- toggleParentReviewSection fLvl[%s] prntLvl[%s] expand?[%s]", fLvl, prntLvl, expand);
    if ( !prntLvl ) return; //form-closed
    if ( isSelectFormParent( prntLvl, expand ) ) { return toggleParentReviewSection( prntLvl ); }
    if ( prntLvl !== 'top' && !expand ) { toggleParentReviewSection( prntLvl, false ); }
    toggleContent( `.${ prntLvl }-rvw`, null, expand );
}
/** Select-forms don't have review elems, so the next parent-section will be expanded. */
function isSelectFormParent( prntLvl, expand ) {
    return !$( `.${ prntLvl }-rvw` ).length && expand;
}