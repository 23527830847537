/**
 * Loads the formatted data using the ag-grid library and handles table styling.
 *
 * TOC
 *     INIT-TABLE CHAIN
 *     INTERNAL FACADE
 */
import * as agGrid from 'libs/grid/ag-grid.js';
import { table, ui } from '@explore';
import { onModelUpdated } from './on-model-update.js';
import { getBaseTableConfg } from './table-init-confg.js';

let tblState;
/** ====================== INIT-TABLE CHAIN ================================= */
/**
 * Builds the table options object and passes everyting into agGrid, which
 * creates and shows the table.
 */
export function initTable( view, rowData, state ) {                   /*perm-log*/initTableEnvLog( ...arguments );
    tblState = state;
    destroyPreviousTable( state.api );
    return init( view, rowData )
        .then( () => onTableInitComplete( rowData ) );
}
function initTableEnvLog( view, rowData, state ) {
    if ( $( 'body' ).data( 'env' ) === 'prod' ) {
        console.log( '           //--initTable [%s] rowData[%s]', view, Object.keys( rowData ).length );
    } else {
        console.log( '           //--initTable [%s] rowData[%O] tblState[%O]', view, rowData, state );
    }
}
function destroyPreviousTable( tblApi ) {
    if ( tblApi ) { tblApi.destroy(); }
}
function init( view, rowData ) {
    return getBaseTableConfg( view, tblState ).then( tblOpts => {
        tblOpts.rowData = rowData;
        new agGrid.Grid( $( '#explore-table' )[ 0 ], tblOpts );
        updateTableState( tblOpts, rowData );
        sortTreeColumnIfTaxonFocused();
        onModelUpdated();
    } );
}
/** If the table is Taxon focused, sort the tree column by taxon-rank and name. */
function sortTreeColumnIfTaxonFocused() {
    if ( tblState.curFocus === 'taxa' ) {
        tblState.api.setSortModel( [ { colId: "name", sort: "asc" } ] );
    }
}
/* -------------------------------------------------------------------------- */
function updateTableState( tblOpts, rowData ) {
    tblState.api = tblOpts.api;
    table.setState( { 'api': tblOpts.api, 'columnApi': tblOpts.columnApi, 'rowData': rowData } );
}
function onTableInitComplete( rowData ) {
    ui.hideExplorePopupMsg();
    ui.onTableInitEnableButtons( tblState.flags.allDataAvailable );
    hideUnusedColFilterMenus();
    if ( rowData.length == 0 ) { return tblState.api.showNoRowsOverlay(); }
    ui.updateFilterStatusMsg();
    table.onTableLoadCompleteClearFilterUi();
}
export function hideUnusedColFilterMenus() {
    [ 'map', 'edit', 'elevMax', 'intCnt', 'lat', 'lng', 'reviewedAt' ].forEach( hideFilterAndSort );
    [ 'updatedBy' ].forEach( hideSortIcon );
    [ 'elev', 'name' ].forEach( hideFilterButton );
}
function hideFilterAndSort( colId ) {
    hideSortIcon( colId );
    hideFilterButton( colId );
}
function hideSortIcon( colId ) {
    const types = [ 'none', 'ascending', 'descending' ];
    types.forEach( type => hideIcon( colId, type ) );
}
function hideIcon( colId, type ) {
    $( `div[colId="${ colId }"] .ag-sort-${ type }-icon` ).hide();
}
function hideFilterButton( colId ) {
    $( `.ag-header-cell-menu-button.${ colId }` ).hide();
}