/**
 * App utility methods for the Flatpickr calendar used for date/time selection.
 *
 * Export
 *     getNewCalendar
 *
 * TOC
 *     INIT
 *     PLUG-INS
 */
import * as _t from '@types';
/* --- FLATPICKR --- */
import flatpickr from "flatpickr";
import confirmDatePlugin, * as ConfirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate.js';
import rangePlugin, * as DateRangePlugin from 'flatpickr/dist/plugins/rangePlugin.js';
import { Hook, Options, Plugin } from 'flatpickr/dist/types/options';
/* ========================== INIT ========================================== */
type FlatpickrConfig = {
    elemId: string;
    enableTime: boolean;
    onClose?: Hook;
    onChange?: Hook;
    plugins: {
        confirm?: ConfirmDatePlugin.Config,
        range?: DateRangePlugin.Config;
    };
};
/** Instantiates the flatpickr calendar and returns the flatpickr calendar. */
export function getNewCalendar ( config: FlatpickrConfig ): flatpickr.Instance {
    const cal = flatpickr( config.elemId, getCalOptions( config ) );
    return Array.isArray( cal ) ? cal[ 0 ] as flatpickr.Instance : cal;
}
function getCalOptions ( config: FlatpickrConfig ): Options {
    const opts: Options = {
        altInput: true,
        defaultDate: getDefaultTimeIfTesting( config.elemId ),
        disableMobile: true,
        enableTime: config.enableTime,
        maxDate: 'today',
    };
    if ( config.onChange ) opts.onChange = config.onChange;
    if ( config.onClose ) opts.onClose = config.onClose;
    if ( opts.enableTime ) opts.onReady = setAmPmContext;
    if ( config.plugins ) opts.plugins = getCalPlugins( config.plugins );
    return opts;
}
/** If time can be selected, sets the default time to be 1200 AM. */
function setAmPmContext ( selectedDates: Date[], dateStr: string, instance: flatpickr.Instance ): void {
    const timeConfig = instance.amPM;
    if ( timeConfig ) timeConfig.textContent = "AM";
}
/**
 * There doesn't seem to be a way to set the date on the flatpickr calendar
 * from the selenium/behat tests. A data property is added to the calendar elem
 * and that date is set as the default for the calendar.
 */
function getDefaultTimeIfTesting ( id: string ): string | undefined {
    return $( id ).data( 'defaultDate' );
}
/* ========================== PLUG-INS ====================================== */
function getCalPlugins ( plugins: FlatpickrConfig['plugins'] ): Plugin[] | [] {
    if ( !plugins ) return [];
    return _t.objectKeys( plugins ).map( k => getPlugin( k, plugins[ k ] ) );
}
function getPlugin<T extends keyof FlatpickrConfig['plugins']> ( name: T, opts: FlatpickrConfig['plugins'][T] ): Plugin<{}> {
    return {
        'confirm': getConfirmDatePlugin,
        'range': getDateRangePlugin
    }[ name ]( opts! );
}
function getConfirmDatePlugin ( opts: ConfirmDatePlugin.Config ): Plugin {
    return confirmDatePlugin( opts );
}
function getDateRangePlugin ( opts: DateRangePlugin.Config ): Plugin {
    return rangePlugin( opts );
}