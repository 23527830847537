/**
 * Shared handlers for the source-forms.
 *
 * TOC
 *     ACTIONS
 *     INIT COMBOS
 *     UTIL
 *         GET DETAIL-ENTITY TYPE
 *         LOAD SUB-TYPE FIELDS
 */
import { components, state } from '@dataentry';
import { getData } from '@localdata';
import { isNotNumber } from '@util';
import * as author from './author';
import * as citation from './citation';
import * as publication from './publication';
import * as publisher from './publisher';
import { loadSrcTypeFields } from './util';
/* ======================== ACTIONS ========================================= */
export function initCreateForm( entity, name ) {                    /*perm-log*/console.log( '           >--[%s] CREATE text?[%s]', entity, name )
    const funcs = {
        Author: author.initCreateForm.bind( null, 1, 'Author' ),
        Citation: citation.initCreateForm,
        Editor: author.initCreateForm.bind( null, 1, 'Editor' ),
        Publication: publication.initCreateForm,
        Publisher: publisher.initCreateForm
    };
    return funcs[ entity ]( name );
}
export function initEditForm( entity, id ) {                        /*perm-log*/console.log( '           >--[%s] EDIT id[%s]', entity, id );
    const funcs = {
        Author: author.initEditForm,
        Citation: citation.initEditForm,
        Publication: publication.initEditForm,
        Publisher: publisher.initEditForm
    };
    return funcs[ entity ]( id );
}
export function initReviewForm( rEntry ) {                           /*perm-log*/console.log( '           >--[%s] REVIEW rEntry[%O]', rEntry.payload.detail, rEntry );
    const funcs = {
        author: author.initReviewForm,
        citation: citation.initReviewForm,
        publication: publication.initReviewForm,
        publisher: publisher.initReviewForm
    };
    return funcs[ rEntry.payload.detail ]( rEntry );
}
/* ======================== INIT COMBOS ===================================== */
/**  .
 * Handles the initialization of the comboboxes (selectize.js) in the source forms.
 */
export function initCombos( fLvl, entity ) {
    const events = getEntityComboEvents( fLvl, entity );            /*dbug-log*///console.log( "initCombos. [%s] formLvl = [%s], events = %O", entity, fLvl, events );
    if ( !events ) return;
    components.initFormCombos( fLvl, events );
}
function getEntityComboEvents( fLvl, entity ) {
    return  {
        'Citation': {
            'CitationType': {
                onChange: citation.loadCitTypeFields },
            'Author': {
                create: author.initCreateForm.bind( null, 1, 'Author' ),
                onChange: author.onAuthAndEdSelection.bind( null, 1, 'Author' )
            },
        },
        'Publication': {
            'PublicationType': {
                onChange: loadSrcTypeFields.bind( null, 'Publication' ) },
            'Publisher': {
                create: publisher.initCreateForm,
                onChange: publisher.onPublSelection.bind( null, fLvl ) },
            'Author': {
                create: author.initCreateForm.bind( null, 1, 'Author' ),
                onChange: author.onAuthAndEdSelection.bind( null, 1, 'Author' )
            },
            'Editor': {
                create: author.initCreateForm.bind( null, 1, 'Editor' ),
                onChange: author.onAuthAndEdSelection.bind( null, 1, 'Editor' )
            }
        }
    }[ entity ];
}
/* ============================ UTIL ======================================== */
/* --------------------- GET DETAIL-ENTITY TYPE ----------------------------- */
/** Used for Publication and Citation edit-forms. */
export function getSourceDetailType( entity, id ) {
    return getData( [ 'source', entity ] )
        .then( rcrds => getDetailType( rcrds, entity, id ) );
}
function getDetailType( rcrds, entity, id ) {
    const src = rcrds.source[ id ];
    const detail = rcrds[ entity ][ src[ entity ] ];                /*dbug-log*///console.log( '--getDetailType. rcrds[%O] src[%O] detail[%O]', rcrds, src, detail )
    return detail[ entity + 'Type' ].displayName;
}
/* --------------------- LOAD SUB-TYPE FIELDS ------------------------------- */
export function finishSrcFieldLoad( entity, fLvl ) {                /*dbug-log*///console.log( 'finishSrcFieldLoad [%s] entity[%s]', fLvl, entity );
    if ( !entity ) entity = state.getFormState( fLvl, 'name' );
    if ( entity === 'Citation' ) citation.finishFieldLoad( fLvl );
    if ( entity === 'Publication' ) publication.finishFieldLoad( fLvl );
}
