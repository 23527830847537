/**
 * Merges a config-model into the form-model, overwriting with its values.
 *
 * Export
 *     mergeIntoFormModel
 *     mergeFieldModels
 *
 * TOC
 *     MERGE FIELDS
 *     MERGE LAYOUTS
 */
import { cloneObj, isObj } from "@util";
import { model as m } from '@dataentry';
/* ========================== MERGE HANDLERS ================================ */
export function mergeCoreConfigIntoFormModel( formModel ) {
    const mergeModel = m.getEntityFormConfig( formModel.core );       /*dbug-log*///console.log( '   --mergeCoreConfigIntoFormModel formModel[%O], mergeModel[%O]', cloneObj( formModel ), cloneObj( mergeModel ) );
    mergeIntoFormModel( formModel, mergeModel );
}
export function mergeTypeConfigIntoFormModel( formModel, type, iAction ) {/*dbug-log*///console.log( '   -- mergeTypeConfigIntoFormModel formModel[%O] type[%s] iAction[%s]', cloneObj( formModel ), type, iAction );
    if ( type ) {
        mergeIntoFormModel( formModel, formModel.types[ type ] );
        formModel.type = type;
    } else {
        mergeIntoFormModel( formModel, formModel.types[ iAction ] );
    }
    delete formModel.types;
}
/**
 * Merges a config-model into the form-model, overwriting the form with its values.
 * @param  {obj} formModel  Form Model to add the other model into
 * @param  {obj} mergeModel Model to merge into the form model
 * @return {obj}            The completed model
 */
function mergeIntoFormModel( formModel, mergeModel ) {
    /*dbug-log*///console.group();console.log( '  /--mergeIntoFormModel formModel[%O] mergeModel[%O]', cloneObj( formModel ), mergeModel );
    for ( let prop in mergeModel ) mergeModelProp( prop, mergeModel[ prop ], formModel );
    /*dbug-log*///console.groupEnd();console.log( '       >>>-- formModel[%O]', cloneObj( formModel ) );
    return formModel ;
}
function mergeModelProp( prop, mData, formModel ) {                     /*dbug-log*///console.log( '      --mergeModelProp mData[%s][%O] formModel[%O]', prop, mData, formModel );
    const map = {
        fields: mergeFieldModels,
        layouts: mergeLayouts
    };
    if ( !map[ prop ] ) return formModel[ prop ] = mData;
    map[ prop ]( formModel[ prop ], mData );
}
/* ========================== MERGE FIELDS ================================== */
/**
 * Form-fields properties are overwritten with the merging-field's data.
 * @param {array} formFields
 * @param {array} mergeFields
 */
export function mergeFieldModels( formFields, mergeFields ) {       /*dbug-log*///console.log( '           >>--mergeFieldModels formFields[%O] mergeFields[%O]', cloneObj( formFields ), cloneObj( mergeFields ) );
    for ( let key in mergeFields ) mergeFieldModels( key );

    function mergeFieldModels( key ) {
        if ( !formFields[ key ] ) formFields[ key ] = {};
        const formField = formFields[ key ];
        const mergeField = mergeFields[ key ];                    /*dbug-log*///console.log( '                    --mergeFieldModel mergeField[%s][%O] formField[%O]', key, mergeField, formField );
        mergeFieldProps( formField, mergeField );
        if ( 'required' in mergeField ) formField.required = mergeField.required;
    }
}
function mergeFieldProps( formField, mergeField ) {
    for ( let prop in mergeField ) mergeProp( formField, prop, mergeField[ prop ] );
}
/** [mergeProp description] */
function mergeProp( formField, prop, val ) {                        /*dbug-log*///console.log( '                      --mergeProp prop[%s] = [%O] fieldBefore[%O]', prop, val, cloneObj( formField ) );
    const edge = {
        misc: mergePropObjects,
        server: mergePropObjects,
    };
    formField[ prop ] = getMergedProp();

    function getMergedProp() {
        return prop in edge ? edge[ prop ]( prop, formField, val ) : val;
    }
}
function mergePropObjects( prop, formField, mergeObj ) {            /*dbug-log*///console.log( '                          --mergePropObjects prop[%s] mergeObj[%O] formObj[%O]', prop, mergeObj, formField );
    if ( !formField[ prop ] ) formField[ prop ] = {};
    const merged = Object.keys( mergeObj ).reduce( mergePropData , {} );
    return { ...formField[ prop ], ...merged }

    function mergePropData ( merged, k ) {
        merged[ k ] = isObj( mergeObj[ k ] )
            ? mergePropObjects( k, formField[ prop ], mergeObj[ k ] )
            : cloneObj( mergeObj[ k ] );
        return merged;
    }
}
/* ======================== MERGE LAYOUTS =================================== */
function mergeLayouts( fLayouts, mLayouts ) {                        /*dbug-log*///console.log( '                 >>--mergeLayouts mLayouts[%O]', mLayouts );
    mergeSimpleLayout( fLayouts, mLayouts );
    mergeLayoutDisplayType( 'all', fLayouts, mLayouts );
}
function mergeSimpleLayout( fLayouts, mLayouts ) {
    if ( mLayouts.simple ) return mergeLayoutDisplayType( 'simple', fLayouts, mLayouts );
    if ( fLayouts.simple && mLayouts.all ) fLayouts.simple = fLayouts.simple.concat( mLayouts.all );
}
/**
 * @param  {string} display    Layout display-type: simple or all
 * @param  {array}  fLayouts   Values represent form rows. Strings are full-width
 *                             fields and arrays of strings are multi-field rows.
 * @param  {array}  mLayouts   Entity-type layout to merge in
 */
function mergeLayoutDisplayType( display, fLayouts, mLayouts ) {    /*dbug-log*///console.log( '                      --mergeLayoutDisplayType display[%s] form[%O] merging[%O]', display, cloneObj( fLayouts ), mLayouts );
    if ( !fLayouts[ display ] ) initLayoutDisplayType( display, fLayouts )
    fLayouts[ display ] = fLayouts[ display ].concat( mLayouts[ display ] );
}
/** If missing, create a 'simple' layout from the 'all' layout. */
function initLayoutDisplayType( display, fLayouts ) {
    fLayouts[ display ] = fLayouts.all.map( v => v );               /*dbug-log*///console.log( '                          --initLayoutDisplayType display[%s] current?[%O]', display, fLayouts[ display ] );
}
