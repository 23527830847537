/**
 * Edit-taxon review-form.
 */
import { components, state } from '@dataentry';

export function reviewEdit( rEntry, hndlrs ) {                       /*dbug-log*///console.log('           >--Taxon review EDIT [%O]', rEntry);
   const params = getReviewEditParams( rEntry, hndlrs.getParams );   /*dbug-log*///console.log('   --params[%O]', params);
    return components.initForm( params )
        .then( hndlrs.finishBuild )
        .then( () => storeTxnRcrd( rEntry ) );
}
function getReviewEditParams( rEntry, getParams ) {
    const p = getParams( rEntry.form.editId );
    p.review = rEntry;
    delete p.vals;
    return p;
}
function storeTxnRcrd( rEntry ) {
    const txn = state.getRecords( 'taxon', rEntry.form.editId );/*dbug-log*///console.log('   -- storeTxnRcrd[%O]', txn);
    state.setFormState( 'top', { rcrd: txn } );
}