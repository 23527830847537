/**
 * Handles submitting form-data relevant to the review process and continuing the
 * form-process based on the response from the server and the review process.
 *
 * Export
 *     pushReviewEntry
 *     submitDataReview
 *
 * TOC
 *     SUBMIT DATA-REVIEW
 *     PUSH TO SERVER
 *        BUILD SUBMIT DATA
 */
import { state, trans } from '@dataentry';
import { parseData } from '@localdata';
import { cloneObj, isManagerUser, sendAjaxQuery } from '@util';
import { onDataSynced, onSubmitError, onSubmitSuccess, onViolation } from '../entry/post-submit';
/* ======================= SUBMIT DATA-REVIEW =============================== */
/**
 * Adds data necessary for the quarantine and review process and pushes to server.
 * @param  {obj} confg  Form-configuration
 * @param  {obj} data   Form-data prepared for data-entry. (Not set when just pushing ReviewEntry.)
 * @param  {obj} fLvl   When just pushing ReviewEntry.
 */
export function submitDataReview( confg, data = {}, fLvl ) {        /*dbug-log*///console.log("   --submitDataReview data[%O] confg[%O]", data, confg);
    confg = confg || state.getFormState( fLvl );
    data.review = trans.buildFormReviewData( confg );               /*temp-log*///console.log( '   -- submitDataReview data[%O] fConfig[%O]', cloneObj( data ), confg );
    state.setFormState( confg.group, { submitted: true } );
    return pushReviewEntry( null, null, data );
}
/* ======================== PUSH TO SERVER ================================== */
export function pushReviewEntry ( id, stage, data, onCancel = false ) {
    data = getReviewEntryData( ...arguments );                      /*temp-log*///console.log( '   -- pushReviewEntry id[%s] stage[%s] formClosed?[%s] data[%O]', id, stage, onCancel, data );
    const fLvl = data.review?.form?.group;
    sendAjaxQuery( data, 'crud/review', getOnSuccess(), getOnError() );

    function getOnSuccess() {
        return ( results ) => handleReviewEntrySuccess( fLvl, onCancel, parseData( results ) );
    }
    function getOnError () {
        return ( results ) => onSubmitError( fLvl, results );
    }
}
function handleReviewEntrySuccess( fLvl, onCancel, results ) {      /*temp-log*///console.log( '       --[R] handleReviewEntrySuccess fLvl?[%s] results[%O]', fLvl, parseData( results ) );
    if ( !fLvl || onCancel ) return; // Review-form opened or cancelled
    return results.fail ? onViolation( fLvl, results ) : processSuccess( fLvl, results );
}
function processSuccess( fLvl, result ) {
    trans.prepPayloadForClient( fLvl, result )
    .then( data => handleContinue( fLvl, data ) );
}
function handleContinue( fLvl, data ) {
    return isSkippingStorage( data ) ? onDataSynced( fLvl, data ) : onSubmitSuccess( fLvl, data );
}
/**
 * When the ReviewEntry is rejected, the local storage process is skipped.
 * When the ReviewEntry is completed, local storage happened during page-load sync.
 */
function isSkippingStorage( data ) {
    return !data.coreEntity || data.review.completed;
}
/* ---------------------- BUILD SUBMIT DATA --------------------------------- */
function getReviewEntryData ( id, stage, data ) {
    data = data ? data : {};
    data.manager = isManagerUser();
    return data.review ? data : getBaseDataParams( id, stage, data );
}
function getBaseDataParams ( id, stage, data ) {
    data.review = {
        id: id,
        stage: {
            name: stage
        }
    };
    return data;
}
