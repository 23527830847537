/**
 * Sub-entity data must be reviewed before the referencing entity, root form.
 * Sub-data is reviewed in the order of the fields in the current form view.
 *
 * Export
 *     initRvwForm
 */
import { components, form } from '@dataentry';
import { enableCombobox } from '@elems/combo';
import { isManagerUser } from '@util';
import { continueReview, ifSubReviewEntryLoadForm } from '.';
import { finishReviewFieldInit } from '../fields';
import { updateSubmitElemsForReview } from './submit';
/* ======================= INIT FORM ======================================== */
export function initRvwForm ( rEntry ) {                            /*temp-log*///console.log( '   +--initRvwForm rEntrys[%O]', rEntry );
    return form.reviewEntity( rEntry )
        .then( ifFormReviewValidContinueInit );

    function ifFormReviewValidContinueInit ( flag ) {               /*dbug-log*///console.log( 'ifFormReviewValidContinueInit rEntry[%O]', rEntry );
        return flag === 'skipReview' ? // SKIPPING THIS DATA-SET
            continueReview( rEntry.inst.group, rEntry.id ) :
            finishReviewFormInit( rEntry );
    }
}
export function finishReviewFormInit ( rEntry ) {
    return components.fillComplexFormFields( rEntry.inst.group )
        .then( () => finishReviewFieldInit( rEntry ) )
        .then( () => updateSubmitElemsForReview( rEntry.inst.group, rEntry ) )
        .then( () => ifSubReviewEntryLoadForm( rEntry.inst.group ) );
}
/* ======================== SUB-FORM ======================================== */
/**
 * Handles loading a form-field's ReviewEtry. Parent-form review-elems are disabled
 * when sub-data is open for review and reenabled when the last sub-set is reviewed.
 * @param  {object} subEntry  A field's ReviewEntry
 * @param  {string} pLvl      The parent-form level
 */
export function loadRvwSubForm ( subEntry, pLvl ) {                  /*temp-log*///console.log( '   +--loadRvwSubForm pLvl[%s] subEntry[%O]', pLvl, subEntry );
    if ( !subEntry ) return toggleFormReviewElems( pLvl );
    toggleFormReviewElems( pLvl, false );
    return initRvwForm( subEntry );
}
function toggleFormReviewElems ( fLvl, enable = true ) {            /*dbug-log*///console.log( 'toggleFormReviewElems fLvl[%s] enable?[%s]', fLvl, enable );
    if ( !isManagerUser() || hasNoReviewElems( fLvl ) ) return;
    components.toggleFormReviewNotes( fLvl, enable );
    enableCombobox( fLvl + 'Action', enable );
}
/** Taxon select-form (not directly reviewed) */
function hasNoReviewElems ( fLvl ) {
    return !$( `#${ fLvl }-form .rvw-action-cmbx` ).length;
}