/**
 * Inits the combobox, using 'selectize', according to the passed config.
 * Note: The 'selectize' library turns select dropdowns into input comboboxes
 * that allow users to search by typing and, when configured, add new options
 * not in the list by triggering a sub-form for that entity.
 */
import { reportErr } from "@util";
import { Combobox, ComboboxConfig } from './Combobox';

type ComboboxValues = {
    configName: string;
    element: HTMLSelectElement;
    id: string;
    placeholder: string;
};
export function initCombobox ( config: ComboboxConfig, combo: Combobox ): ComboboxValues {
    const values = initComboboxPropertyValues( config );
    const options = initComboboxOptions( config, String( values.placeholder ), combo );
    $( String( values.id ) ).selectize( options );
    return values as ComboboxValues;
}
/* ========================= COMBOBOX CONFIG ================================ */
function initComboboxPropertyValues ( config: ComboboxConfig, values: Partial<ComboboxValues> = {} ): Partial<ComboboxValues> {
    const field = config.name.split( ' ' ).join( '' );
    values.configName = config.confgName ?? field;
    values.id = config.id ?? `#sel-${ field }`;
    values.element = getDomElement( values.id.slice( 1 ) );
    values.placeholder = config.placeholder ?? initPlaceholder( config.name );
    return values;
}
function getDomElement ( id: string ): HTMLSelectElement {
    const el = document.getElementById( id );
    if ( !el ) reportErr( new Error( 'Combobox DOM element not found' ) );
    return el as HTMLSelectElement;
}
function initPlaceholder ( name: string ): string {
    return 'Select ' + name.split( ' Filter' )[ 0 ]!;
}
/* ======================== SELECTIZE OPTIONS =============================== */
function initComboboxOptions ( config: ComboboxConfig, placeholder: string, combo: Combobox ): Selectize.IOptions<any, any> {
    const opts = { ...config };
    opts.placeholder = placeholder;
    if ( config.onChange ) opts.onChange = combo.onChange;
    if ( config.onBlur ) opts.onBlur = combo.ensureValueSelected;
    return opts;
}