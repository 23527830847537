/**
 * For taxon groups with more than one root taxon, a multi-select combobox filter
 * is added with the display name of each root taxon.
 *
 * Export
 *     initGroupRootFilter
 *
 * TOC
 *     INIT COMBOBOX
 *     APPLY FILTER
 */
import { getSelVal, initCombobox } from '@elems/combo';
import { getData, getDetachedRcrd } from '@localdata';
import { getState, rebuildTxnTable, resetDataTable, setState } from '@explore/table';
import { getFilterField, newSel, setFilterState } from '@explore/table/filter';
import { fadeTable } from '@explore/ui';

let timeout;
/* ---------------------- INIT COMBOBOX ------------------------------------- */
export function initGroupRootFilter ( tblState ) {                      /*dbug-log*///console.log("       --Loading taxon GroupRoot filters.");
    return getGroupRootOpts( tblState )
        .then( getGroupRootFilter )
        .then( finishGroupRootComboInit );
}
/** @todo2 Move opts builders to app-util and replace this. */
function getGroupRootOpts ( tblState ) {
    return getData( tblState.groupName + 'GroupRootNames' )
        .then( buildGroupRootOpts );
}
function buildGroupRootOpts ( groupRoots ) {
    return Object.keys( groupRoots ).map( name => {
        return { text: name, value: groupRoots[ name ] };
    } );
}
function getGroupRootFilter ( opts ) {
    const sel = newSel( opts, '', 'sel-Group-RootFilter' );
    return getFilterField( null, sel );
}
function finishGroupRootComboInit ( filterElem ) {
    const confg = {
        name: 'Group-Root Filter',
        maxItems: null,
        onChange: filterTableByGroupRoot,
    };
    $( '#focus-filters' ).prepend( filterElem );
    initCombobox( confg );
}
/* ----------------------- APPLY FILTER ------------------------------------- */
function filterTableByGroupRoot ( vals ) {
    fadeTable();
    // if ( !vals.length ) return clearFilterAndResetTableToAllGroupTaxa();
    if ( !timeout ) timeout = setTimeout( filterByGroupRoots, 500 );
}
function filterByGroupRoots() {                                      /*dbug-log*///console.log("       filterByGroupRoots");
    timeout = null;
    const rootNames = getSelectedRootNames( getSelVal( 'Group-RootFilter' ) );
    if ( !rootNames ) return clearFilterAndResetTableToAllGroupTaxa();
    const newRoots = getTxnRootRcrds( rootNames );                    /*dbug-log*///console.log( 'filterTableByGroupRoots = %O', newRoots );
    updateGroupRootFilterState( rootNames );
    rebuildTxnTable( newRoots );
}
function getSelectedRootNames ( names ) {
    const total = $( '#sel-Group-RootFilter' )[ 0 ].selectize.currentResults.total;
    const selected = !names.length || names.length === total ? {} : names;
    setState( { 'selectedOpts': { 'Group-Root': selected } } );
    return Object.keys( selected ).length ? names : false;
}
function clearFilterAndResetTableToAllGroupTaxa () {
    $( '#sel-Group-RootFilter' )[ 0 ].selectize.clear();
    resetDataTable( 'taxa' );
}
function getTxnRootRcrds ( rootNames ) {                            /*dbug-log*///console.log( 'getTxnRootRcrds roots[%O]', rootNames );
    const taxa = getState( 'data' ).rcrds;
    const groupRoots = getState( 'groupRoots' );
    return rootNames.map( getRootRcrd );

    function getRootRcrd ( rootName ) {
        return getDetachedRcrd( groupRoots[ rootName ].taxon, taxa, 'taxon' );
    }
}
function updateGroupRootFilterState ( rootNames ) {
    const filter = { 'Group-Root': { text: 'Group-Root', value: rootNames } };
    setFilterState( 'combo', filter, 'rebuild' );
}