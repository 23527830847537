/**
 * Helper methods for the entity-data init process.
 */
import * as _t from '@types';
/** Returns an object with a record (value) for each id (key) in passed array.*/
export function getRcrds ( ids: number[], rcrds: _t.EntityRecords ): _t.EntityRecords {
    const data: _t.EntityRecords = {};
    ids.forEach( id => {
        const rcrd = rcrds[ id ] as _t.EntityRecord;
        if ( !rcrd ) console.error( `Record not found for ${ id }` );
        data[ id ] = rcrd;
    } );
    return data;
}
/** Returns an object with each entity record's displayName (key) and id. */
export function getNameObj ( ids: number[], rcrds: _t.EntityRecords ): _t.IdsByName {
    const data: _t.IdsByName = {};
    ids.forEach( id => {
        data[ getDisplayName( rcrds[ id ] ) ] = id;
    } );
    return data;
}
/** Returns an object with each entity types's displayName (key) and id. */
export function getTypeObj ( entityTypes: _t.EntityRecords ): _t.IdsByName {
    const data: _t.IdsByName = {};
    for ( let id in entityTypes ) {
        data[ getDisplayName( entityTypes[ id ] ) ] = id;
    }
    return data;
}
function getDisplayName ( entity: _t.EntityRecord | undefined ): string {
    const name = entity?.displayName;
    if ( !name ) console.error( 'No name found in record [%O]', entity );
    return String( name );
}
/** Returns the ids for an entity sub-type: eg, citation sources or country locations. */
export function getType ( types: _t.EntityRecords, type: string, collection: string ): number[] {
    for ( let t in types ) {
        const record = types[ t ];
        if ( !record ) console.error( 'Invalid entity-type [%s] [%O]', t, types );
        if ( record?.slug === type ) return record[ collection ];
    }
    return [];
}