import { FormConfig, ModelFormFields, FormFieldModel, MultiFieldModel } from '../types';

/* ============================ TYPES ======================================= */
type SourceFieldConfig = MultiFieldModel | FormFieldModel

export type SourceFieldKey =
    | 'Author'
    | 'Description'
    | 'DisplayName'
    | 'Doi'
    | 'Editor'
    | 'ParentSource'
    | 'SourceType'
    | 'Website'
    | 'Year';
/* ============================ CONFIG ====================================== */
/**
 * Source-core configuration. Will be merged with the source detail-entity confg
 */
export default function(): Partial<FormConfig> {
    return {
        fields: getSourceFieldConfig(),
    };
}
function getSourceFieldConfig(): ModelFormFields<SourceFieldConfig> {
    return {   //MERGED AND OVERWRITTEN WITH DETAIL.FIELDS
        Author: {
            count: 1,
            entity: 'Contributor',
            label: false,
            misc: {
                customValueStore: true
            },
            name: 'Author',
            server: {
                entity: {
                    core: 'source',
                    detail: 'author'
                },
                prep: {
                    setContributors: []
                },
                prop: {
                    core: 'authors',
                },
            },
            type: 'multiSelect',
        },
        Description: {
            name: 'Description',
            type: 'textArea'
        },
        DisplayName: {
            name: 'DisplayName',
            server: {
                prop: {
                    core: 'displayName'
                },
            },
            type: 'text',
        },
        Doi: {
            label: 'DOI',
            name: 'Doi',
            server: {
                prop: {
                    core: 'doi'
                },
            },
            type: 'doi',
        },
        Editor: {
            count: 1,
            entity: 'Contributor',
            label: false,
            misc: {
                customValueStore: true
            },
            name: 'Editor',
            server: {
                entity: {
                    core: 'source',
                    detail: 'author'
                },
                prep: {
                    setContributors: []
                },
                prop: {
                    core: 'editors',
                },
            },
            type: 'multiSelect',
        },
        ParentSource: {
            entity: 'Source',
            name: 'ParentSource',
            server: {
                prep: {},
                prop: {
                    core: 'parent'
                },
            },
        },
        SourceType: {
            entity: 'SourceType',
            name: 'SourceType',
            required: true,
            server: {
                prep: {
                    setCoreType: []
                },
            },
            type: null
        },
        Website: {
            name: 'Website',
            server: {
                prop: {
                    core: 'linkUrl'
                },
            },
            type: 'url',
        },
        Year: {
            class: 'w-4 no-grow',
            name: 'Year',
            server: {
                prop: {
                    core: 'year'
                },
            },
            type: 'year',
        },
    }
}