/**
 * Displays the action counts of the ReviewEntry records available to the user.
 *
 * Export
 *     loadReviewEntryStats
 *
 * TOC
 *     DISPLAY COUNTS
 *         DISPLAY NONE
 *         BUILD COUNT ELEMS
 */
import { getElem } from '@elems';
import { syncReviewEntries } from '@localdata';
import { isContributorUser, isManagerUser, ucfirst } from '@util';
/* ======================== DISPLAY COUNTS ================================== */
export function loadReviewEntryStats() {
    if ( !( isContributorUser() || isManagerUser() ) ) return;
    syncReviewEntries( [ 'reviewCounts', 'availableReview' ] )
    .then( initReviewPanelUi );
}
function initReviewPanelUi( data ) {
    displayReviewEntryCounts( data.reviewCounts );
    toggleStartReviewBttn( data.availableReview );
}
function displayReviewEntryCounts( countObj ) {                     /*dbug-log*///console.log('-- displayReviewEntryCounts counts[%O]', countObj);
    const elems = ifCountData( countObj ) ? getCounts( countObj ) : getNoReviewData();
    const countsId = '#rvw-pnl-col2-content';
    $( countsId ).empty();
    $( countsId ).append( elems );
}
function ifCountData( countObj ) {
    return countObj && Object.keys( countObj ).length;
}
function toggleStartReviewBttn( availableReview ) {
    const enable = ifDataReadyToReview( availableReview );
    const opac = enable ? 1 : .3;
    const cursor = enable ? 'pointer' : 'not-allowed';              /*dbug-log*///console.log( '-- toggleStartReviewBttn opacity[%s] enable[%s]', opac, enable );
    $( '#rvw-cntrl-bttn' ).css( { opacity: opac, cursor: cursor } ).attr( { disabled: !enable } );
    setNoDataNotice( enable );
}
function ifDataReadyToReview( availableReview ) {                   /*dbug-log*///console.log( '   -- ifDataReadyToReview availableReview[%O]', availableReview );
    if ( !availableReview ) return false;
    return Object.values( availableReview ).some( entry => entry.form.group === 'top' );
}
function setNoDataNotice( activate = true ) {
    const text = !activate ? getNoReviewData() : '';
    $( $( '#rvw-cntrl-bttn' )[ 0 ].nextElementSibling ).html( text );
}
/* ----------------------- DISPLAY NONE ------------------------------------- */
function getNoReviewData() {
    return '<span>No Data Available to Review</span>';
}
/* ---------------------- BUILD COUNT ELEMS --------------------------------- */
function getCounts( countObj ) {                                    /*dbug-log*///console.log('-- getCounts [%O]', countObj);
    const elems = [];
    Object.keys( countObj ).forEach( addCountElems );
    return elems;
    /** Creates count elems, with Interaction counts, if presenmt, displayed first. */
    function addCountElems( ent ) {
        const counts = getCountElems( ent, countObj[ ent ] );
        return ent === 'interaction' ? elems.unshift( counts ) : elems.push( counts );
    }
}
function getCountElems( entity, counts ) {
    const lbl = getElem( 'label', { class: `rvw-cnt-cntnr flex-row` } );
    const name = getElem( 'span', { class: 'rvw-entity', text: ucfirst( entity ) } );
    $( lbl ).append( [ name, ...getActionCountElems( counts ) ] );
    return lbl;
}
function getActionCountElems( counts ) {
    const elems = [];
    Object.keys( counts ).forEach( buildActionCountElem );
    return elems;

    function buildActionCountElem( action ) {
        const attr = { class: `${ action }_rvw-cnt`, text: counts[ action ] };
        elems.push( getElem( 'span', attr ) );
    }
}