/**
 * Author create|edit form-build.
 *
 * Export
 *     finishCreateInit
 *     finishFormInit
 *     getCreateParams
 *     getEditParams
 *     initCreateForm
 *     initEditForm
 *
 * TOC
 *     CREATE
 *         PARAMS
 *         FINISH
 *     EDIT
 *         PARAMS
 *     FINISH BUILD
 */
/* UTIL */
import { resetCombobox } from '@elems/combo';
import * as _t from '@types';
import { taskEitherToPromise } from '@util';
/* DATA-ENTRY */
import { components, form, model, state } from '@dataentry';
import { showSrcSubmitModal } from '@controller/source';
import { ifNoneStillSelectedEnableOtherType, toggleOtherAuthorType } from '../field/on-change';
/* FP-TS */
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
/* ============================ CREATE ====================================== */
/**
 * When a user enters a new author|editor into the combobox, the create form is built
 * and appended to the field's row.
 */
export function initCreateForm (
    cnt: number,
    aType: _t.AuthorType,
    v?: string
): Promise<void> {                                                  /*dbug-log*///console.log( '           >--[%s][%s] CREATE Form - [%s]', aType, cnt, v );
    const fLvl = state.getSubFormLvl( 'sub2' );
    return pipe(
        getCreateParams( cnt, aType, fLvl, v ),
        form.initEntityForm,
        TE.map( _ => finishCreateInit( cnt, aType, fLvl ) ),
        taskEitherToPromise
    );
}
/* ---------------------------- PARAMS -------------------------------------- */
export function getCreateParams (
    cnt: number,
    aType: _t.AuthorType,
    fLvl: model.FormGroup,
    v?: string
): model.InitCreateParams {
    return {
        action: 'create',
        appendForm: appendCreateForm( aType, cnt ),
        combo: aType+cnt,
        count: cnt,
        name: aType,
        group: fLvl,
        style: 'sml-sub-form',
        submit: showSrcSubmitModal.bind( null, fLvl ),
        vals: { 'LastName': ( v === 'create' ? '' : v ) }
    };
}
function appendCreateForm( aType: _t.AuthorType, cnt: number ) {
    return ( form: HTMLElement ) => $( '#' + aType + cnt + '_f' )[ 0 ]!.append( form );
}
/* ---------------------------- FINISH -------------------------------------- */
export function finishCreateInit (
    cnt: number,
    aType: _t.AuthorType,
    fLvl: model.FormGroup
) {
    disableOtherAuthorTypeField( aType, fLvl );
    addOnCancelHandler( cnt, aType, fLvl );
    finishFormInit( fLvl );
}
/** If this is the first author|editor, disable the other author|editor type field. */
function disableOtherAuthorTypeField ( aType: _t.AuthorType, fLvl: model.FormGroup ) {
    const pLvl = state.getFormLevel( 'parent', fLvl )!;
    toggleOtherAuthorType( pLvl, aType, false );
}
/**
 * If the create form is cancelled, the combobox is reset and the other author|editor
 * type field is re-enabled if there are no selections of this author type remaining.
 */
function addOnCancelHandler (
    cnt: number,
    aType: _t.AuthorType,
    fLvl: model.FormGroup
) {
    const onCancel = () => resetOnCreateFormCancel( cnt, aType, fLvl );
    state.addFormHandler( fLvl, 'cancel', onCancel );
}
function resetOnCreateFormCancel (
    cnt: number,
    aType: _t.AuthorType,
    fLvl: model.FormGroup
) {                                                                 /*dbug-log*///console.log( '--resetOnCreateFormCancel [%s][%s][%s]', fLvl, aType, cnt );
    components.handleFormClose( fLvl, true );
    components.ifParentFormValidEnableSubmit( fLvl );
    ifNoneStillSelectedEnableOtherType( state.getEntityFormLevel( aType ), aType, cnt );
    resetCombobox( aType + cnt );
}
/* ============================= EDIT ======================================= */
export function initEditForm ( id: number ): Promise<void> {        /*dbug-log*///console.log( '           >--Author EDIT Form id[%s]', id );
    return pipe(
        id,
        getEditParams,
        form.initEntityForm,
        TE.map( _ => finishFormInit( 'top' ) ),
        taskEitherToPromise
    );
}
/* ---------------------------- PARAMS -------------------------------------- */
export function getEditParams ( id: number ): model.InitEditParams {
    return {
        action: 'edit',
        group: 'top',
        id: id,
        name: 'Author',
        style: 'sml-form',
        submit: showSrcSubmitModal.bind( null, 'top' )
    };
}
/* ======================= FINISH BUILD ===================================== */
export function finishFormInit ( fLvl: model.FormGroup ) {          /*dbug-log*///console.log( '-finishFormInit fLvl[%s]', fLvl );
    components.toggleSubmitBttn( fLvl );
    $( '#LastName_f .f-input' ).focus();
}