/**
 * Handles displaying the 'help' modal for editors and above with options to either
 * reset the local database or submit a bug report.
 *
 * TOC
 *    HELP MODAL
 *    BUG REPORT POPUP
 *        REPORT HTML
 *            PROMPTS
 *            BUTTONS
 *        SUBMIT REPORT
 *            SERVER
 *            SENTRY
 *        CLOSE REPORT POPUP
 */
import * as el from '@elems';
import { resetStoredData } from '@localdata';
import * as _t from '@types';
import { submitEditorIssue } from '@util';
import { initExploreStateAndTable, showIntroAndLoadingMsg } from '@explore/ui';
/* ===================== HELP MODAL ========================================= */
export default function showEditorHelpModal (): void {
    const confg = {
        html: getHelpHtml(),
        position: 'left',
        selector: '#data-help',
        title: 'Experiencing issues?'
    } as const;
    const events = { onafterchange: setBttnEvents };
    el.showSaveModal( confg, null, events );
}
function getHelpHtml (): string {
    return `<div style="text-align:center;">${ getModalBttn( 'Reset Local Data' ) }
        ${ getModalBttn( 'Report A Bug' ) }</div>`;
}
function getModalBttn ( text: string ): string {
    return `<button class="intro-bttn">${ text }</button>`;
}
function setBttnEvents (): void {
    const map = {
        'Reset Local Data': handleDataReset,
        'Report A Bug': showBugReportPopup
    } as const;
    $( '.intro-bttn' ).each( ( i, elem ) => {
        $( elem ).on( 'click', () => { el.exitModal( map[ elem.innerText as keyof typeof map ] ); } );
    } );
}
function handleDataReset (): void {
    showIntroAndLoadingMsg( 'taxa', true );
    resetStoredData()
    .then( () => initExploreStateAndTable() );
}
/* ===================== BUG REPORT POPUP =================================== */
function showBugReportPopup (): void {
    el.showPagePopup( 'bugs-popup', null, getBugHtml(), afterBugReportShow );
}
function afterBugReportShow (): void {
    getPromptElem( 0 ).focus();
}
/* ---------------------------- REPORT HTML --------------------------------- */
function getBugHtml (): ( string | HTMLElement )[] {
    const hdr = '<h3><center>New Issue Report</center></h3><br>';
    const prompts = getReportPrompts();
    const upload = buildPrompt( 'Upload screenshots if relevant:', 'file' );
    const bttns = getReportBttns();
    return [ hdr, ...prompts, upload, bttns ];
}
/* ------ PROMPTS ----------------- */
function getReportPrompts (): HTMLElement[] {
    const p1 = buildPrompt( 'Briefly summarize the issue you are experiencing:', 'text', true );
    const p2 = buildPrompt( 'Describe the steps necessary to reproduce the issue:', null, true );
    const p3 = buildPrompt( 'Please provide any additional helpful information:' );
    return [ p1, p2, p3 ];
}
function buildPrompt (
    text: string,
    inputType?: 'file' | 'text' | null,
    isRequired?: true
): HTMLElement {
    const lbl = buildFieldContainer( isRequired );
    const span = el.getElem( 'span', { text: text, class: 'bug-span' } );
    const input = buildFieldInput( inputType );
    $( lbl ).append( [ span, input ] );
    return lbl;
}
function buildFieldInput ( type?: 'file' | 'text' | null ): HTMLElement {
    if ( !type ) return el.getElem( 'textarea', { class: 'bug-rprt-input' } );
    const input = el.getElem( 'input', { class: 'bug-rprt-input', type: type } );
    if ( type === 'file' ) $( input ).attr( 'multiple', 'multiple' );
    return input;
}
function buildFieldContainer ( isRequired?: true ): HTMLElement {
    const classes = 'bug-prompt' + ( isRequired ? ' required' : '' );
    return el.getElem( 'label', { class: classes } );
}
/* ------ BUTTONS ----------------- */
function getReportBttns (): HTMLElement {
    const cntnr = el.getElem( 'div', { class: 'flex-row' } );
    const spacer = el.getElem( 'div', { class: 'flex-grow' } );
    const sub = buildFormButton( 'Submit', submitBugRprt );
    const cncl = buildFormButton( 'Cancel', el.hidePagePopup );
    $( cntnr ).append( [ spacer, sub, cncl ] );
    return cntnr;
}
/** Returns a (submit or cancel) button */
function buildFormButton (
    action: 'Submit' | 'Cancel',
    onClick: () => void
): HTMLElement {
    const attr = { id: 'rprt-' + action, type: 'button', value: action } as const;
    const bttn = el.getElem( 'input', attr );
    $( bttn ).on( 'click', onClick );
    return bttn;
}
/* -------------------------- SUBMIT REPORT --------------------------------- */
function submitBugRprt (): void {
    const ready = checkRequiredBugReportFields( $( '.bug-prompt.required' ) );
    if ( !ready ) {
        showReportStatus( 'Please fill all required fields.', 'red' );
    } else { createNewIssueReport(); }
}
function checkRequiredBugReportFields ( $fields: JQuery<HTMLElement> ): boolean {
    let ready = true;
    $fields.each( ( i, f ) => ifRequiredPromptEmpty( f ) );
    return ready;

    function ifRequiredPromptEmpty ( field: HTMLElement ): void {
        const input = field.children[ 1 ]!;
        if ( !$( input ).val() ) ready = false;
    }
}
/* ----------------- SERVER ------------------ */
function createNewIssueReport (): void {
    const formData = new FormData();
    formData.append( 'description', getPromptElem( 0 ).value );
    formData.append( 'stepsToReproduce', getPromptElem( 1 ).value );
    formData.append( 'miscInfo', getPromptElem( 2 ).value );
    attachFilesToFormData( formData, getPromptElem( 3 ).files! );
    sendAjaxFormData( formData );
}
function attachFilesToFormData ( formData: FormData, files: FileList ): void {/*dbug-log*///console.log(' --attachFilesToFormData files[%O]', files)
    _t.objectKeys( files ).forEach( appendImageData );

    function appendImageData( i:string ): void {
        const filename = files[ parseInt( i ) ]!;
        formData.append( 'image' + ( i + 1 ), filename )
    }
}
function getPromptElem( index: number ): HTMLInputElement {
    return $( '.bug-rprt-input' )[ index ]! as HTMLInputElement;
}
function sendAjaxFormData ( formData: FormData ): void {
    $.ajax( {
        url: 'issue/report',
        type: 'POST',
        data: formData,
        processData: false,
        contentType: false,
        success: reportSubmitSuccess,
        error: reportSubmitError
    } );
}
function reportSubmitSuccess (
    data: { filenames: string[] },
    _: JQuery.Ajax.SuccessTextStatus,
    _2: JQuery.jqXHR
): void {                                                           /*dbug-log*///console.log( 'reportSubmitSuccess. args[%O]', arguments );
    submitNewSentryIssue( data.filenames );
}
function reportSubmitError (
    _jqXHR: JQuery.jqXHR,
    _textStatus: JQuery.Ajax.ErrorTextStatus,
    errorMessage: string
): void {                                                           /*dbug-log*///console.log( 'IssueReportSubmitError = [%s]', errorMessage );
    showReportStatus( 'An error occurred during submission.', 'red' );
}
/* ----------------- SENTRY ------------------ */
function submitNewSentryIssue ( fileNames: string[] ): void {       /*dbug-log*///console.log('   -- submitNewSentryIssue files?[%O]', fileNames);
    const data = {
        summary: getPromptElem( 0 ).value,
        steps: getPromptElem( 1 ).value,
        etc: getPromptElem( 2 ).value,
        screenshots: JSON.stringify( fileNames.map( f => buildScreenshotUrl( f ) ) )
    };
    submitEditorIssue( data );
    updateBugReportUiAfterSubmit();
}
function buildScreenshotUrl ( fileName: string ): string {
    const date = new Date().today().split( '-' ).join( '/' );
    return '/uploads/issue_screenshots/' + date + '/' + fileName;
}
function updateBugReportUiAfterSubmit (): void {
    $( '#rprt-Cancel' ).val( 'Close' );
    $( '#rprt-Submit, .bug-rprt-input' ).css( { 'opacity': .5, 'pointer': 'not-allowed' } ).attr( 'disabled', null );
    showReportStatus( 'Thank you for helping improve the database!', 'green' );
}
function showReportStatus ( msg: string, color: 'red' | 'green' ): void {
    if ( $( '.rprt-status' )[ 0 ] ) { $( '.rprt-status' ).remove(); }
    $( '.bugs-popup h3' ).after( buildReportStatus( msg, color ) );
}
function buildReportStatus ( text: string, color: 'red' | 'green' ): HTMLElement {
    const msg = el.getElem( 'div', { class: 'rprt-status', text: text } );
    $( msg ).css( { 'color': color, 'margin-top': '1em' } );
    return msg;
}