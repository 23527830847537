/**
 * TODO: DOCUMENT
 *
 * Export
 *     rebuildFieldsOnFormConfigChanged
 */
import { components, form, state } from '@dataentry';
import { finishFormBuild } from '../../form/init.js';

export function rebuildFieldsOnFormConfigChanged( fLvl, entity ) {   /*dbug-log*///console.log( '+--rebuildFieldsOnFormConfigChanged [%s][%s]', fLvl, entity );
    $( `#${ entity }_fields` ).remove();
    return components.getFormRows( entity, fLvl )
        .then( rows => appendAndFinishRebuild( entity, fLvl, rows ) )
        .then( () => components.ifFormValidClearAlertsAndEnableSubmit( fLvl ) );
}
function appendAndFinishRebuild( entity, fLvl, rows ) {             /*dbug-log*///console.log('   --appendAndFinishRebuild rows[%O]', rows);
    $( `#${ fLvl }_alert` ).after( rows );
    finishFormBuild( state.getFormState( fLvl ) );
    return components.fillComplexFormFields( fLvl )
        .then( () => finishComplexForms( entity, fLvl ) );
}
function finishComplexForms( entity, fLvl ) {
    const complex = [ 'Citation', 'Publication' ];
    if ( complex.indexOf( entity ) === -1 ) return;
    return form.finishSrcFieldLoad( entity, fLvl );
}
