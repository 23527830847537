/**
 * Initialize data-entry form.
 *
 * Export
 *     alertInUse
 *     finishFormBuild
 *     ifFormInUse
 *     initForm
 *     initSubForm
 *     toggleWaitOverlay
 *
 * TOC
 *     TOP FORM
 *     SUB FORM
 *         IF OPEN SUB-FORM ISSUE
 *         UPDATE TOP-FORM
 *     BUILD CHAIN
 *         BUILD FORM-ELEMS
 *         PANEL ELEMS
 *         FINISH BUILD
 *     ASSEMBLE FORM
 *         CONTAINER
 *         HEADER
 *         STATUS/ALERTS
 */
import { enableCombobox, getElem, resetCombobox, setElemExpandableListeners } from '@elems';
import { cloneObj, ucfirst } from '@util';
import { alert, components, state } from '@dataentry';
import { initSubEntityDataReviewElems } from '../side-panel';
import { finishAndAppendRootForm } from './root-form.js';

let fState;

/** Waiting overlay shown during load-processes. */
export function toggleWaitOverlay( show = true ) {
    if ( show ) { appendWaitingOverlay();
    } else { $( '#c-overlay' ).remove(); }
}
function appendWaitingOverlay() {
    const attr = { class: 'overlay', id: 'c-overlay' };
    $( '#b-overlay' ).append( getElem( 'div', attr ) );
    $( '#c-overlay' ).css( { cursor: 'wait', 'z-index': '10000' } );
}
/* ======================== TOP FORM ======================================== */
/**
 * @export
 * @param {object} p
 * @return {Promise<'success'>}
 */
export function initForm( p ) {                                     /*temp-log*///console.log( '+--initForm params[%O]', ( p ) );
    p.group = 'top';
    return state.initFormState( p )
        .then( handleRootFormInit  )
}
/**
 * @export
 * @param {object} FS
 * @return {Promise<'success'>}
 */
export function handleRootFormInit( FS ) {
    return initFormElems( FS, finishAndAppendRootForm )
        .then( () => finishFormBuild( FS ) )
        .then( () => setElemExpandableListeners( $( '#form-panel' ) ) )
        .then( () => 'success' );
}
/* ======================== SUB FORM ======================================== */
/**
 * @export
 * @param {object} p
 * @return {Promise<'success'>}
 */
export function initSubForm( p ) {                                  /*temp-log*///console.log( '+--initSubForm params[%O]', p );
    if ( ifFormInUse( p.group ) ) return alertInUse( p.group, p.combo );
    return state.initFormState( p )
        .then( handleSubFormInit );
}
/**
 * @export
 * @param {object} FS
 * @return {Promise<'success'>}
 */
export function handleSubFormInit( FS ) {                           /*dbug-log*///console.log( '--handleSubFormInit FS[%O]', FS );
    return initFormElems( FS, FS.handlers.appendForm )
        .then( () => initSubEntityDataReviewElems( FS.group ) )
        .then( () => finishFormBuild( FS ) )
        .then( () => updateParentForm( FS ) )
        .then( () => 'success' );
}
/* ----------------- IF OPEN SUB-FORM ISSUE --------------------------------- */
export function ifFormInUse( fLvl ) {                                 /*dbug-log*///console.log( '--ifFormInUse fLvl[%s]', fLvl );
    return fLvl ? $( `#${ fLvl }-form` ).length !== 0 : false;
}
export function alertInUse( fLvl, name ) {                            /*dbug-log*///console.log( '--alertInUse name[%s] fLvl[%O]', name, fLvl );
    alert.alertFormOpen( fLvl );
    resetCombobox( name );
    return Promise.resolve();
}
/* -------------------- UPDATE TOP-FORM ------------------------------------- */
function updateParentForm( FS ) {                                    /*dbug-log*///console.log( '--updateParentForm' );
    const pLvl = state.getFormLevel( 'parent', FS.group );
    components.toggleSubmitBttn( pLvl, false );
    components.toggleCancelBttn( pLvl, false );
    enableCombobox( FS.combo, false );
    boldLeafFormHeader( pLvl, false );
}
/* ======================== BUILD CHAIN ===================================== */
function initFormElems( FS, appendForm ) {                          /*dbug-log*///console.log( '--initFormElems FS[%O] appendForm[%O]', FS, appendForm );
    return components.getFormPieces( FS )
        .then( elems => buildAndAppendForm( elems, FS, appendForm ) );
}
/* ----------------------- BUILD FORM-ELEMS --------------------------------- */
function buildAndAppendForm( elems, FS, appendForm ) {             /*dbug-log*///console.log( '--assembleAndAppend elems[%O] FS[%O] appendForm[%O]', elems, FS, appendForm );
    const form = assembleFormElems( elems, FS );
    if ( FS.group !== 'top' ) return appendForm( form );
    $( '#b-overlay-popup' ).html( '' ); //Clears "Loading..." text
    appendForm( form, FS, elems );
}
/* -------------------- FINISH BUILD ---------------------------------------- */
export function finishFormBuild( FS ) {                             /*dbug-log*///console.log( '--finishFormBuild FS[%O]', FS );
    components.setDynamicFieldStyles( FS.name );
    boldLeafFormHeader( FS.group );
    if ( FS.handlers.initCombos ) return FS.handlers.initCombos();
}
/* ======================== ASSEMBLE FORM =================================== */
function assembleFormElems( el, FS ) {                              /*dbug-log*///console.log( '+--assembleFormElems elems[%O] FS[%O]', el, cloneObj( FS ) );
    fState = FS;
    const cntnr = buildFormCntnr();
    const hdr = buildFormHdr( el.tutBttn );
    const valMsg = getValMsgCntnr();
    $( cntnr ).append( [ hdr, valMsg, el.rows, el.footer ].filter( e=>e ) );
    $( cntnr ).submit( e => e.preventDefault() );
    return cntnr;
}
/* --------------------------- CONTAINER ------------------------------------ */
function buildFormCntnr() {
    const attr = { id: fState.group+'-form', class: fState.style };
    return getElem( 'form', attr );
}
/* ------------------------------ HEADER ------------------------------------ */
function buildFormHdr( tutBttn ) {
    const cntnr = buildHeaderCntnr();
    const hdr = getElem( 'span', { text: getHeaderTitle() } );
    $( cntnr ).append( [ tutBttn, hdr, $( '<div>' )[ 0 ] ] );
    return cntnr;
}
function buildHeaderCntnr() {
    return getElem( 'div', { id: fState.group+'-hdr', class: 'flex-row' } );
}
function getHeaderTitle() {
    let hdr = fState.name + ' ';
    hdr += fState.action === 'review' ? getRvwHdr() : ucfirst( fState.action );
    return hdr;
}
function getRvwHdr() {
    const action = ucfirst( fState.review.form.action );
    const stage = state.getReviewStage( fState.group );
    const hdr = `${ action } - ${ stage }`;
    return isRejectedAsDuplicate( stage, fState ) ? `${ hdr } (Non-Unique)` : hdr;
}
function isRejectedAsDuplicate( stage, fState ) {
    return stage === 'Rejected' && fState.review.entityId;
}
/** Note: On sub-form exit, parent-form header is rebolded. */
export function boldLeafFormHeader( fLvl, enable = true ) {
    const weight = enable ? 600 : 400;
    $( `#${ fLvl }-hdr span` ).css( { 'font-weight': weight } );
}
/* ------------------------- STATUS/ALERTS ---------------------------------- */
/** Container for custom form-validation messages. */
function getValMsgCntnr() {
    return getElem( 'div', { id: fState.group+'_alert' } );
}