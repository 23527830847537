/**
 * Source table-build methods.
 *
 * Export
 *     buildSrcTable
 *     onSrcViewChange
 *
 * TOC
 *     SOURCE TABLE
 *     SOURCE VIEW
 */
import { table, ui } from '@explore';
import { getSelVal, updateComboChangeEvent } from '@elems/combo';
import { getData, setData } from '@localdata';
import { buildSrcRowData, buildSrcTree, initTable } from '..';
/** ================= SOURCE TABLE ========================================== */
/**
 * Get all data needed for the Source-focused table from data storage and send
 * to @initSrcViewOpts to begin the data-table build.
 */
export function buildSrcTable ( v ) {                                  /*dbug-log*///console.log( "       --Building Source Table. view ? [%s]", v );
    if ( v ) { return getSrcDataAndBuildTable( v ); }
    return getData( 'curView', true ).then( storedView => {
        const view = typeof storedView == 'string' ? storedView : 'pubs';
        return getSrcDataAndBuildTable( view );
    } );
}
function getSrcDataAndBuildTable ( view ) {
    updateComboChangeEvent( 'View', onSrcViewChange );
    return table.initTableData( 'source' )
        .then( () => ui.initSrcViewOpts( view ) )
        .then( () => startSrcTableBuildChain( view ) );
}
/** ================== SOURCE VIEW ========================================== */
/** Event fired when the source view select box has been changed. */
export function onSrcViewChange ( val ) {                              /*dbug-log*///console.log( '       --onSrcViewChange. view ? [%s]', val );
    if ( !val ) return;
    $( '#focus-filters' ).empty();
    return rebuildSrcTable( val );
}
function rebuildSrcTable ( val ) {                                     /*dbug-log*///console.log( '       --rebuildSrcTable. view ? [%s]', val );
    ui.fadeTable();
    table.resetTableState();
    table.setTreeToggleData( false );
    return startSrcTableBuildChain( val );
}
function startSrcTableBuildChain ( val ) {
    const view = getAndStoreSrcView( val );
    const tS = table.getState();
    return buildSrcTree( view, tS.data.rcrds )
        .then( tree => buildSrcRowData( tree, tS ) )
        .then( rowData => table.getRowDataForCurrentFilters( rowData ) )
        .then( rowData => initTable( 'Source Tree', rowData, tS ) )
        .then( () => table.loadSrcFilters( view ) );
}
function getAndStoreSrcView ( val ) {
    const viewVal = val || getSelVal( 'View' );            /*dbug-log*///console.log("getAndStoreSrcView [%s]", viewVal)
    setData( 'curView', viewVal );
    table.setState( { curView: viewVal } );
    return viewVal;
}