/**
 * Form-row builders.
 */
import { getElem } from '@elems';
import { cloneObj } from '@util';
import { state } from '@dataentry';
import * as build from './build-rows.js';
/** Returns completed form rows in the Entity_fields container. */
export function getFormRows( entity, fLvl ) {                       /*dbug-log*///console.log( '+--getFormRows [%s] entity[%s] ', fLvl, entity );
    return getFormFieldRows( fLvl )
        .then( finishFormRowBuild.bind( null, entity, fLvl ) );
}
function finishFormRowBuild( entity, fLvl, rows ) {                 /*dbug-log*///console.log( '   --finishFormRowBuild cntnr[%O] rows[%O]', cntnr, rows );
    const cntnr = getRowContainer( entity, fLvl );
    $( cntnr ).append( rows );
    return cntnr;
}
/** Returns completed form rows. */
export function getFormFieldRows( fLvl ) {
    const fS = state.getFormState( fLvl );                     /*dbug-log*///console.log( '   --getFormFieldRows FS[%O]', cloneObj( fS ) );
    return build.getFormFieldRows( fS.layout );
}
/* ========================== ROW CONTAINER ================================= */
function getRowContainer( entity, fLvl ) {
    const attr = { id: getCntnrId( entity, fLvl ), class: 'flex-col' };
    return getElem( 'div', attr );
}
function getCntnrId( entity, fLvl ) {
    const baseId = entity+'_fields';
    return $( '#'+baseId ).length ? baseId+'_'+fLvl : baseId;
}