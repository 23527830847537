/**
 * Expands the table tree column's interaction-row groupings.
 *
 * EXPORT
 *    expandAllTableRows
 *    setTreeToggleData
 *    toggleRowsOneLevel
 *    toggleTreeRows
 *    toggleAllRows
 */
import { table } from '@explore';
/* ========================= INIT TOGGLES =================================== */
export function initRowToggleEvents () {
    $( 'button[name="xpand-all"]' ).on( 'click', table.toggleAllRows );
    $( 'button[name="xpand-1"]' ).on( 'click', toggleByOne );
    $( 'button[name="collapse-1"]' ).on( 'click', () => toggleByOne( false ) );
}
function toggleByOne( expand = true ) {
    table.toggleRowsOneLevel( expand );
}
/* ========================== TOGGLE ROWS =================================== */
export function expandAllTableRows () {
    table.getState( 'api' ).expandAll();
    setTreeToggleData( true );
}
export function toggleRowsOneLevel( expand = true ) {
    toggleTreeRows( expand, true );
}
export function toggleTreeRows ( xpand, byOne = false ) {
    const tApi = table.getState( 'api' );
    byOne ? toggleTreeByOneLvl( tApi, xpand ) : toggleAll( tApi, xpand );
}
export function toggleAllRows () {
    toggleTreeRows( !$( "#xpand-all" ).data( 'xpanded' ) );
}
/**
 * Resets button based on passed boolean xpanded state. True for fully
 * expanded and false when collapsed.
 */
export function setTreeToggleData( xpanded ) {
    const bttnText = xpanded ? "Collapse All" : "Expand All";
    $( '#xpand-all' ).html( bttnText );
    $( '#xpand-all' ).data( "xpanded", xpanded );
}
function toggleAll( tApi, xpand ) {
    setTreeToggleData( xpand );
    xpand ? tApi.expandAll() : tApi.collapseAll();
}
/**
 * Opens/closes one level of the displayed data tree. If there are no closed
 * rows left after updating, the toggle tree button is updated to 'Collapse All'.
 */
function toggleTreeByOneLvl( tApi, opening ) {
    const tblModel = tApi.getModel();
    const bttXpandedAll = $( "#xpand-all" ).data( 'xpanded' );
    if ( opening && bttXpandedAll === true ) {return;}

    tblModel.rowsToDisplay.forEach( row => {
        if ( !opening && !isNextOpenLeafRow( row ) ) return;
        row.expanded = opening;
        row.data.open = opening;
    } );
    tApi.onGroupExpandedOrCollapsed();
    updateToggleTreeButton();
    /**
     * Checks displayed rows against total rows after filters to determine
     * if there are any closed rows remaining. The toggle tree button is updated
     * if necessary.
     */
    function updateToggleTreeButton() {
        const shownRows = tblModel.rowsToDisplay.length;
        const allRows = getCurTreeRowCount( tApi );
        const closedRows = shownRows < allRows;                     /*dbug-log*///console.log("%s < %s ? %s... treeBttn = %s ", shownRows, allRows, closedRows, bttXpandedAll);

        if ( !closedRows ) { setTreeToggleData( true );
        } else if ( bttXpandedAll === true ) { setTreeToggleData( false ); }
    }
} /* End toggleTreeByOneLvl */
function getCurTreeRowCount( tblApi ) {
    let cnt = 0;
    tblApi.forEachNodeAfterFilter( node => cnt += 1 );
    return cnt;
}
/**
 * If there are no child rows, or if the child rows are closed, this is the open leaf.
 */
function isNextOpenLeafRow( node ) {
    if ( node.childrenAfterFilter ) {
        return node.childrenAfterFilter.every( childNode => !childNode.expanded );
    }
    return true;
}
