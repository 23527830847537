/**
 * Miscellaneous utility methods.
 */
import { alertIssue, cloneObj, logInDevEnv, fetchServerData } from '@util';
import * as _t from '@types';
/* ============================ FETCH DATA ================================== */
export function fetchDataForLocalStorage ( url: string, options: RequestInit = {}, n = 9 ): Promise<any> {
    /*dbug-log*///console.log('       *-fetchDataForLocalStorage [%s] with params = %O', url, options);
    return fetchServerData( `fetch/${url}`, options, n );
}
/* ============================ PARSE DATA ================================== */
/**
 * Loops through the passed data object to parse the nested objects. This is
 * because the data comes back from the server having been double JSON-encoded,
 * due to the 'serialize' library and the JSONResponse object.
 */
export function parseData<T> ( data: T ): T {                       /*dbug-log*///console.log('*-parseData [%O]', data);
    for ( let k in data ) {
        try {
            data[ k ] = JSON.parse( data[ k ]! as string );
        } catch ( e ) { /* Fails on string values */
            //console.log('error');
        }
    }
    return data;
}
/** ==================== GET RECORD ========================================= */
/**  Returns a copy of the record detached from the original. */
export function getDetachedRcrd<T extends _t.EntityRecord> (
    rcrdKey: number,
    rcrds: _t.EntityRecords,
    entity: string
): T | false {                                                      /*dbug-log*///console.log( "getDetachedRcrd. key = %s, rcrds = %O", rcrdKey, rcrds );
    const rcrd = rcrds[ rcrdKey ];
    if ( !rcrd ) return alertNoRecord( rcrdKey, entity, rcrds );
    if ( ifRecordSoftDeleted( rcrd ) ) return false;
    return cloneObj( rcrd ) as T;
}
function alertNoRecord (
    rcrdKey: number,
    entity: string,
    rcrds: _t.EntityRecords
): false {                                                          /*perm-log*/logInDevEnv( "#########-ERROR- couldn't get record [%s] from %O", rcrdKey, rcrds );
    alertIssue( 'noRcrdFound', { id: String( rcrdKey ), entity: entity } );
    return false;
}
function ifRecordSoftDeleted( rcrd: _t.EntityRecord ) {
    const dName = rcrd.displayName && hasDeletedName( rcrd.displayName );
    const name = rcrd.name && hasDeletedName( rcrd.name );
    const deletedInt = rcrd.note && hasDeletedName( rcrd.note );    /*dbug-log*///console.log( 'ifRecordSoftDeleted?[%s] rcrd[%O]', ( dName || name || deletedInt ), rcrd )
    return dName || name || deletedInt;
}
function hasDeletedName( name: string ) {
    const deletes = [ 'DELETE', 'Delete', 'delete' ];
    return deletes.find( d => name.includes( d ) );
}