/**
 * Draws containing country polygon on data-entry form-map and displays all
 * locations within the country.
 *
 * EXPORT
 *     addParentLocDataToMap
 *
 * TOC
 *     DRAW COUNTRY POLYGON
 *         ADD POLYGON AND ZOOM
 *     ADD CHILDREN AND ZOOM
 *         ZOOM
 */
import { addPolygonToMap, clearAllMapMarkers, getMapRecords, getMapState, setMapView } from '@explore/map/manager.js';
import { buildPolygon, getCenterCoords } from '@explore/map/util';
import { addChildLocsToMap } from './add-child-locs.js';
/**
 * Draws containing country polygon on map and displays all locations within.
 */
export function addParentLocDataToMap ( id, type, skipZoom ) {      /*dbug-log*///console.log( '+--addParentLocDataToMap id[%s] skipZoom[%s] type[%s]', id, skipZoom, type );
    const loc = getMapRecords( 'location', id );
    if ( !loc ) return console.log( 'No country data matched in geocode results' );
    drawLocPolygon( loc, skipZoom );
    if ( type === 'table-map' ) return;  //Table interactions already loaded
    addChildLocsToMapAndZoom( skipZoom, type, loc );
}
/* ==================== DRAW COUNTRY POLYGON ================================ */
/** Draws polygon on map and zooms unless skipZoom is a truthy value. */
function drawLocPolygon ( loc, skipZoom ) {                         /*dbug-log*///console.log( '    --drawLocPolygon loc[%O] skipZoom[%s]', loc, skipZoom );
    const geoJson = getGeoJson( loc );
    if ( !geoJson || geoJson.type == 'Point' ) return;
    addPolyAndZoom( loc, geoJson, skipZoom );
}
function getGeoJson( loc ) {
    return loc.geoJsonId && getMapRecords( 'geoJson', loc.geoJsonId );
}
/* --------------------- ADD POLYGON AND ZOOM ------------------------------- */
function addPolyAndZoom( loc, geoJson, skipZoom ) {
    const polygon = buildPolygon( loc, geoJson );
    addPolygonToMap( polygon, skipZoom );
}
/* ===================== ADD CHILDREN AND ZOOM ============================== */
/**
 * Adds all child locations to map and zooms according to passed zoomLvl.
 * If editing location, locId will be passed to skip the child loc's marker.
 */
function addChildLocsToMapAndZoom( skipZoom, type, loc ) {          /*dbug-log*///console.log( '     --addChildLocsToMap skipZoom[%s] type[%s] loc[%s]', skipZoom, type, loc );
    const centerCoords = getCenterCoords( loc, loc.geoJsonId );
    clearAllMapMarkers();
    addChildLocsToMap( type, loc, centerCoords );
    handleMapZoom( skipZoom, loc, centerCoords );
}
/* ------------------------- ZOOM ------------------------------------------- */
function handleMapZoom( loc, skipZoom, coords ) {
    const zoomLvl = getCurrentZoom( loc , skipZoom );
    if ( zoomLvl && coords ) setMapView( coords, zoomLvl );
}
function getCurrentZoom( loc, skipZoom ) {
    if ( !getMapState( 'volatile' ).poly ) return false;
    if ( skipZoom ) return getMapState( 'map' ).getZoom() < 5 ? 5 : false;
    return loc.locationType.displayName === 'Region' ? 3 : 8;
}