/**
 * After a geocode event with valid results, this function will draw geocoded
 * location on the map. If the location is a country, all locations within are loaded.
 *
 * EXPORT
 *     onGeocode
 *
 * TOC
 *     GEOCODE COUNTRY
 *     GEOCODE SINGLE LOCATION
 */
import { getData } from '@localdata';
import { addPolygonToMap } from '@explore/map/manager.js'
import { addParentLocDataToMap, getCountryId } from '@explore/map/util'

export function onGeocode( mapId, r ) {                             /*dbug-log*///console.log( '  +--onGeocode. mapId[%s] results[%O]', mapId, r );
    if ( isCountryOrFormGeocode( mapId, r ) ) drawCountryAndLoadLocs( r, mapId );
    else drawPolygon( r.geocode.bbox );
}
/* ======================== GEOCODE COUNTRY ================================= */
function isCountryOrFormGeocode( mapId, e ) {
    return mapId == 'form-map' || isCountryResults( e.geocode.properties.address );
}
/** Returns true if the only data returned is Country data. */
function isCountryResults ( address ) {
    return Object.keys( address ).every( k => {
        return [ 'country', 'country_code' ].indexOf( k ) !== -1;
    } );
}
async function drawCountryAndLoadLocs ( r, mapId = 'map' ) {        /*dbug-log*///console.log( '    --drawCountryAndLoadLocs r[%O]', r );
    const geocode = r.geocode.properties;
    const query = r.target._lastGeocode;
    if ( !geocode ) return; //todo: show 'no results' message
    const cntryId = await getCountryId( geocode, query );
    addParentLocDataToMap( cntryId, mapId );
}
/* ===================== GEOCODE SINGLE LOCATION ============================ */
function drawPolygon ( bbox ) {                                     /*dbug-log*///console.log( '  --addNewPolygon bbox[%O]', bbox );
    const poly = buildPolygon( bbox );
    addPolygonToMap( poly );
}
function buildPolygon( bbox ) {
    return L.polygon( [
        bbox.getSouthEast(),
        bbox.getNorthEast(),
        bbox.getNorthWest(),
        bbox.getSouthWest()
    ] );
}