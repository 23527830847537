/**
 * Inits the side-panel section that will contain misc data-review UI and info.
 *
 * EXPORT
 *     getDataReviewElems
 *     initSubEntityDataReviewElems
 *     resetReviewSidePanel
 *
 * TOC
 *     INIT DATA-REVIEW SIDE-PANEL
 *         SUB-FORM
 *         RESET PANEL
 *     UTIL
 */
import { isContributorUser } from '@util';
import { state } from '@dataentry';
import initReviewSidePanel from './base-review-ui.js';
import ifManagerEditingGetForensic from './forensic-review-panel.js';
import getReviewFormSidePanelElems from './form-review-elems.js';
import addSubFormDataReviewElems from './sub-form-panel.js';
/* =================== INIT DATA-REVIEW SIDE-PANEL ========================== */
export function getDataReviewElems( fState ) {                        /*dbug-log*///console.log("-- getDataReviewElems fState[%O]", fState);
    const stage = getStage( fState.group );
    if ( ifReviewable( fState.action ) ) { return initDataReviewPanel( fState, stage ); }
    return ifManagerEditingGetForensic( fState );
}
/** Contributors and data-review processes have the data-review elems. */
function ifReviewable( action ) {
    const contribEntry = isContributorUser() && action !== "select";/*dbug-log*///console.log("  -- ifReviewable contribEntry?[%s] reviewForm?[%s]", contribEntry, action === 'review');
    return contribEntry || action === 'review';
}
function initDataReviewPanel( fState, stage ) {                       /*dbug-log*///console.log("-- initDataReviewPanel fState[%O] stage[%s]", fState, stage);
    const section = initReviewSidePanel( fState, stage );
    const fReviewElems = getReviewFormSidePanelElems( fState, stage );
    return [ ...section, fReviewElems ];
}
/* ------------------------- SUB-FORM --------------------------------------- */
export function initSubEntityDataReviewElems( fLvl ) {
    const FS = state.getFormState( fLvl );                          /*dbug-log*///console.log("-- initSubEntityDataReviewElems FS[%O]", FS);
    if ( !ifReviewable( FS.action ) ) return;
    const elems = getReviewFormSidePanelElems( FS, getStage( FS.group ) );
    addSubFormDataReviewElems( FS, elems );
}
/* ------------------------- RESET PANEL ------------------------------------ */
/** During interaction-form reset, the side-panel is reinitialized. */
export function resetReviewSidePanel() {
    const fState = state.getFormState( 'top' );
    $( '#top-rvw' ).remove();
    $( '#form-panel' ).append( getReviewFormSidePanelElems( fState, null ) );
}
/* ========================= UTIL ======================================= */
function getStage( fLvl ) {
    return state.getReviewStage( fLvl );
}