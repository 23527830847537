import { FormConfig, ModelFormFields } from '../types';
/* ============================ CONFIG ====================================== */
/**
 * Interaction form configuration.
 */
export default function( entity: string ): FormConfig {
    return {
        fields: getFieldModel(),
        layouts: {
            all: [
                [ 'Publication', 'CitationTitle' ],
                [ 'Country-Region', 'Location' ],
                [ 'Subject', 'Object' ],
                [ 'InteractionType', 'InteractionTags' ],
                [
                    { fields: [ [ 'Source', 'Pages' ], 'Date', 'Season' ] },
                    { class: 'row-field', fields: [ 'Quote', 'Note' ],  }
                ]
            ],
        },
        name: entity,
        records: {
            create: [ 'author', 'citation', 'group', 'interactionType', 'location', 'publication',
                'publisher', 'rankNames', 'source', 'tag', 'taxon', 'validInteraction' ],
            edit: [ 'author', 'citation', 'group', 'interaction', 'interactionType', 'location',
                'publication', 'publisher', 'rankNames', 'source', 'tag', 'taxon', 'validInteraction' ],
        },
        style: 'lrg-form',
    };
}
function getFieldModel(): ModelFormFields {
    return {
        CitationTitle: {
            entity: 'Source',
            name: 'CitationTitle',
            required: true,
            server: {
                entity: {
                    detail: 'citation'
                },
                prop: {
                    core: 'source'
                },
            },
            type: 'select'
        },
        'Country-Region': {
            id: 'Country-Region', //label is used for id, but '/' is an invalid selector character
            label: 'Country/Region',
            name: 'Country-Region',
            server: {
                prep: {}
            },
            type: 'select'
        },
        Date: {
            class: 'date-range-input w-9',
            id: 'int-date', //Inputs will have ID's appended with '-start' and '-end'
            name: 'Date',
            server: {
                prop: {
                    core: 'date'
                }
            },
            type: 'dateRange'
        },
        InteractionTags: {
            entity: 'Tag',
            info: {
                tooltip: 'Tags indicate the part of the object organism in the interaction'
            },
            label: 'Tags',
            misc: {
                defaultTags: [], //Always valid and available when tags load.
                tagType: 'interaction type'
            },
            name: 'InteractionTags',
            server: {
                prep: {
                    validateTags: []
                },
                prop: {
                    core: 'tags'
                },
            },
            type: 'tags'
        },
        InteractionType: {
            entity: 'InteractionType',
            info: {
                tooltip: 'Read about Interaction Types on the About->Definitions page.',
                intro: `Read about Interaction Types on the <a href="/definitions"
                    target="_blank">Definitions page</a>.`
            },
            label: 'Type',
            misc: {
                customValueStore: true
            },
            name: 'InteractionType',
            required: true,
            server: {
                prop: {
                    core: 'interactionType'
                },
            },
            type: 'select'
        },
        Location: {
            entity: 'Location',
            name: 'Location',
            required: true,
            server: {
                prop: {
                    core: 'location'
                },
            },
            type: 'select',
        },
        Note: {
            class: 'flex-grow',
            label: 'Notes',
            name: 'Note',
            server: {
                prop: {
                    core: 'note'
                },
            },
            type: 'fullTextArea'
        },
        Object: {
            entity: 'Taxon',
            info: {
                tooltip: 'Check bat taxonomy and names at www.batnames.org. ' +
                'If the name in the publication is no longer accepted, use the currently' +
                'accepted name and note the name used in the publication in the Notes Field.',
                intro: 'Check bat taxonomy and names at <a href="www.batnames.org" target="_blank">www.batnames.org</a>. ' +
                'If the name in the publication is no longer accepted, use the currently' +
                'accepted name and note the name used in the publication in the Notes Field.',
            },
            name: 'Object',
            required: true,
            server: {
                prop:{
                    core: 'object'
                },
            },
            type: 'select'
        },
        Pages: {
            class: 'w-6',
            name: 'Pages',
            server: {
                prop: {
                    core: 'pages'
                },
            },
            type: 'page'
        },
        Publication: {
            entity: 'Source',
            name: 'Publication',
            server: {
                entity: {
                    detail: 'publication'
                },
                prep: {}
            },
            type: 'select'
        },
        Quote: {
            class: 'flex-grow',
            info: {
                tooltip: 'Source text citing this specific interaction.'
            },
            name: 'Quote',
            server: {
                prop: {
                    core: 'quote'
                },
            },
            type: 'fullTextArea'
        },
        Season: {
            name: 'Season',
            misc: {
                tagType: 'season'
            },
            server: {
                prep: {
                    handleSeasonTags: []
                },
                prop: {
                    core: 'tags'
                },
            },
            type: 'select'
        },
        Source: {
            class: 'w-7',
            name: 'Source',
            misc: {
                opts: [ 'Primary', 'Secondary' ],
                tagType: 'source'
            },
            required: true,
            server: {
                prep: {
                    handleSecondaryTag: []
                },
                prop: {
                    core: 'tags'  //If secondary, its tag will be added
                },
            },
            type: 'select'
        },
        Subject: {
            entity: 'Taxon',
            info: {
                tooltip: 'The active taxon in the interaction. Check bat taxonomy and names ' +
                'at www.batnames.org. ' +
                'If the name in the publication is no longer accepted, use the currently' +
                'accepted name and note the name used in the publication in the Notes Field.',
                intro: 'The active taxon in the interaction. Check bat taxonomy and names ' +
                'at <a href="www.batnames.org" target="_blank">www.batnames.org</a>. ' +
                'If the name in the publication is no longer accepted, use the currently' +
                'accepted name and note the name used in the publication in the Notes Field.',
            },
            name: 'Subject',
            required: true,
            server: {
                prop: {
                    core: 'subject'
                },
            },
            type: 'select'
        },
    };
}