/**
 * Initializes the map and legends used for the Explore page map.
 *
 * EXPORT
 *     fillIntCntLegend
 *     initExploreMapView
 *
 * TOC
 *     INIT MAP
 *     DENSITY LEGEND
 *     INTERACTION COUNT LEGEND
 */
import { getElem } from '@elems';
import { ui } from '@explore';
import { handleMapInit } from '@explore/map/init';
import { getMapState } from '@explore/map/manager.js'

/* ========================= INIT MAP ======================================= */
export function initExploreMapView( loadMapView, type = 'map' ) {
    ui.updateUiForMapView();
    handleMapInit( () => onMapLoadInitMapView( loadMapView ), 'map' );
}
function onMapLoadInitMapView( loadMapView ) {
    initExploreMapElems();
    loadMapView();
    ui.hideExplorePopupMsg(); //'Loading...'
}
/** Initializes the legends used for the Explore page map. */
function initExploreMapElems () {
    const map = getMapState( 'map' );
    addMarkerDensityLegend( map );
    addIntCountLegend( map );
}
/* ======================= DENSITY LEGEND =================================== */
function addMarkerDensityLegend ( map ) {                        /*dbug-log*///console.log( '       +--addMarkerDensityLegend map[%O]', map );
    const legend = L.control( { position: 'bottomright' } );
    legend.onAdd = addMarkerLegendHtml;
    legend.addTo( map );
}
function addMarkerLegendHtml () {
    const div = getElem( 'div', { class: 'info legend flex-col' } );
    div.innerHTML += `<h4> Interaction Density </h4>`;
    div.innerHTML += getDensityHtml();
    return div;
}
function getDensityHtml () {
    const density = [ 'Light', 'Medium', 'Heavy' ];
    const colors = [ '110, 204, 57', '240, 194, 12', '241, 128, 23' ];
    return density.reduce( ( html, d, i ) => html += buildDensityHtml( d, colors[ i ] ), '' );
}
function buildDensityHtml ( density, color ) {
    return `<span><i style="background: rgba(${ color }, .9);"></i>
        ${ density }</span>`;
}
/* ======================= INTERACTION COUNT LEGEND ========================= */
function addIntCountLegend ( map ) {
    const legend = L.control( { position: 'topright' } );
    legend.onAdd = addIntCntLegendHtml;
    legend.addTo( map );
}
function addIntCntLegendHtml () {                                       /*dbug-log*///console.log( '       +--addIntCntLegendHtml' );
    const attr = { id: 'int-legend', class: 'info legend flex-col' };
    const div = getElem( 'div', attr );
    return div;
}
export function fillIntCntLegend ( shown, notShown ) {
    const legend = $( '#int-legend' )[ 0 ];
    legend.innerHTML = `<h4>${ shown + notShown } Interactions Total </h4>`;
    legend.innerHTML += `<span><b>${ shown } shown on map</b></span><span>
        ${ notShown } without GPS data</span>`;
}