/**
 * Builds field-data necessary for the data-review process. Tracks changes to data
 * during the review.
 *
 * Export
 *     getFieldReviewData
 *
 * TOC
 *     FIELD DATA
 *         FIELD VALUE
 *         TRACK CHANGES
 *     FIELD REVIEW-ENTRY
 *         GET REVIEW-ENTRY
 *         REPLACED REVIEW-ENTRY
 */
import { lcfirst } from "@util";
import { state } from '@dataentry';
/**
 * @param  {object} FS   State object for the form being submitted
 * @return {object}             Field-data necessary for the data-review process.
 */
export function getFieldReviewData(FS) {                            /*dbug-log*///console.log('+-- getFieldReviewData FS[%O]', FS);
    const formReview = getFormReviewEntry( FS.review );
    const data = {};
    Object.values( FS.fields ).forEach( setFieldReviewData );
    return data;
    /**
     * Gets the data relevant the field's review process.
     * Note: It is important to include all fields, whether active or not.
     */
    function setFieldReviewData( field ) {
        data[ field.name ] = buildReviewField( field, formReview );   /*dbug-log*///console.log( '           -- setFieldReviewData data[%s][%O]', field.name, data[ field.name ] );
    }
}
function getFormReviewEntry( rEntry ) {
    if ( !rEntry ) return false; // Contributor is submitting the initial entry
    return state.getRecords( 'reviewEntry', rEntry.id, false );
}
/* ========================== FIELD DATA ==================================== */
function buildReviewField( field, formReview ) {                     /*dbug-log*///console.log( '               -- buildReviewField field[%s][%O] formReview?[%O]', field.name, field, formReview );
    const data = getCurrentFieldData( field );
    data.review = getFieldReviewEntryData( field );
    data.replacedReview = ifReviewEntryWasReplacedAddTrackData( field, formReview );
    return data;
}
function getCurrentFieldData( field ) {
    const data = {
        value: getFieldReviewValue( field )
    };
    if ( field.notes ) data.notes = field.notes;
    if ( field.server?.prop ) data.prop = field.server.prop;
    return data;
}
/**
 * Replaced data will still have a final review by the contributor.
 * @param {object} field
 * @param {object|undefined} formReview  Not present when contributor is submitting the initial entry.
 */
function ifReviewEntryWasReplacedAddTrackData( field, formReview ) {
    if ( !formReview ) return; // Contributor is submitting the initial entry
    return formReview && getReplacedReviewEntry( field, formReview );
}
/* ------------------------ FIELD VALUE ------------------------------------- */
/**
 * Resets the field value to the quarantined values used by the Contributor unless
 * they have been replaced with approved data.
 */
function getFieldReviewValue( field ) {                             /*dbug-log*///console.log( '   -- getFieldReviewValue field[%s][%O]', field.name, field );
    const value = getFieldValue( field );
    if ( field.required && !value ) console.error( 'Required field missing value[%O]', field );
    return value;
}
function getFieldValue( field ) {
    return field.count
        ? getMultiFieldReviewValue( field )
        : field.value || getQuarantinedId( field );
}
function getQuarantinedId( field, entity, ord ) {
    const rEntry = getReviewEntryBones( field, entity, ord )
    return rEntry ? rEntry.qId : null;
}
function getMultiFieldReviewValue( field ) {
    return field.value ? getMultiFieldValue( field ) : null;
}
function getMultiFieldValue( field ) {
    const data = {};
    for (let ord = field.count; ord >= 1; ord--) { //count is usually one higher than the fields with values (the trailing combo)
        if (!field.value[ord]) continue;
        data[ord] = getFieldOrdValue( field, ord );
    }
    return data;
}
function getFieldOrdValue( field, ord ) {                          /*dbug-log*///console.log( '   -- getFieldOrdValue field[%s][%O] ord[%s]', field.name, field, ord );
    return field.value[ ord ] || getQuarantinedId( field, 'source', ord );
}
/* ====================== FIELD REVIEW-ENTRY ================================ */
function getFieldReviewEntryData( field ) {
    if ( ifNoReviewEntry( field ) ) return false;                   /*dbug-log*///console.log( '   -- getFieldReviewEntry field[%s][%O]', field.name, field );
    return field.count ? getMultiEntry( field ) : getReviewEntryBones( field );
}
function ifNoReviewEntry( field ) {
    return !field.review || field.review.completed;
}
function getMultiEntry(field) {
    const data = {};                                                /*dbug-log*///console.log('              -- getMultiEntry field[%s][%O] data[%O]', field.name, field, data);
    for (let ord = field.count; ord >= 1; ord--) { //count is usually one higher than the fields with values (the trailing combo)
        const rEntry = getReviewEntryBones(field, 'source', ord);
        if (!rEntry) continue;
        data[ord] = rEntry;
    }
    return data;
}
/* ------------------------ GET REVIEW-ENTRY ------------------------------ */
/**
 * Returns the field's normalized review-entry.
 * @param  {object} field  Field configuration
 * @param  {string} ent    Entity classname
 * @param  {string} ord    Ordinal for fields with multiple values
 * @return {object}        Normalized data
 */
//todo: combine with similar review entry
function getReviewEntryBones( field, ent, ord ) {                    /*dbug-log*///console.log( '   -- getReviewEntryBones field[%O] ent?[%s] ord?[%s]', field, ent, ord );
    const rEntry = getFieldReviewEntry( field.review, ord );
    if (!rEntry) return;
    const qId = getFieldQuarantinedId( field, ord, rEntry );
    const entity = ent || lcfirst( field.entity );
    return { id: rEntry.id, entity: entity, qId: qId };
}
function getFieldReviewEntry( rEntry, ord ) {
    return ord && rEntry ? rEntry[ ord ] : rEntry;
}
function getFieldQuarantinedId( field, ord, rEntry ) {
    if ( !rEntry.form ) return getQuarantinedValue( field, ord ); //quarantine selected
    const qId = rEntry?.payload.coreId    //getRecordQuarantinedId( rEntry.id );
    return qId || getQuarantinedValue( field, ord );
}
// function getRecordQuarantinedId( rId ) {
//     const storedRcrd = state.getRecords( 'reviewEntry', rId, false ); // Get latest updates
//     return storedRcrd?.payload.coreId;
// }
function getQuarantinedValue( field, ord ) {
    return ord ? field.value[ ord ] : field.value;
}
/* ------------------------ REPLACED REVIEW-ENTRY --------------------------- */
/**
 * If the field had ReviewEntry replaced, it will be stored for the contributor review,
 * @param  {object} reviewField   Data related to the review field.
 * @param  {object} formReview    ReviewEntry entity for the containing form.
 * @return {object}               Replaced ReviewEntry { id (ReviewEntry), entity }
 */
function getReplacedReviewEntry( field, formReview ) {             /*dbug-log*///console.log( '   -- getReplacedReviewEntry field[%O] formReview?[%O]', field, formReview );
    const initEntry = getInitialReviewEntry( field, formReview );   /*dbug-log*///console.log( '        -- initEntry[%O]', initEntry );
    if ( !initEntry ) return false;
    const handler = field.count ? getReplacedMultiEntry : getReplacedEntry;
    return handler( initEntry, field );
}
function getInitialReviewEntry( field, formReview ) {
    const initEntry = formReview?.form.fields[ field.name ];
    return initEntry.review;
}
function getReplacedEntry( initEntry, field ) {                    /*dbug-log*///console.log('       -- getReplacedEntry current[%O] previous[%O]', field, initEntry);
    return hasReplacedEntry( field.review, initEntry ) ? initEntry : false;
}
function getReplacedMultiEntry( initEntry, field ) {               /*dbug-log*///console.log( '       -- getReplacedMultiEntry current[%O] previous[%O]', field, initEntry );
    const replaced = {};
    for ( let ord in initEntry ) addIfReplaced( ord );
    return Object.keys( replaced ).length ? replaced : false;

    function addIfReplaced( ord ) {
        const current = field.review?.[ ord ];
        if ( !hasReplacedEntry( current, initEntry[ ord ] ) ) return;
        replaced[ ord ] = initEntry[ ord ];                         /*dbug-log*///console.log('           -- addIfReplaced current[%O] previous[%O]', current, initEntry[ ord ]);
    }
}
function hasReplacedEntry( current, previous ) {
    return !current || current.id !== previous.id;
}