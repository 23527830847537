import { FormConfig, FormFieldModel, ModelFormFields, LayoutSection } from '../types';
import { cloneObj } from '@util';
import { state } from '@dataentry';
import { EntityRecord } from '@types';
/* ============================ TYPES ======================================= */
interface GroupField extends Omit<FormFieldModel, 'misc'> {
    misc?: {
        rcrd?: EntityRecord
    } & FormFieldModel['misc'];
}
/* ============================ CONFIG ====================================== */
/**
 * Interaction subject/object role- or Taxon parent-selection form configuration.
 * @param field - 'Subject', 'Object', or 'Parent'
 * @returns FormConfig
 */
export default function( field: string ): FormConfig {
    const fields = getCoreGroupAndRankFieldConfig();
    return {
        records: {
            edit: [ 'group', 'rankNames', 'taxon' ]
        },
        fields: fields,
        name: field,
        layouts: {
            all: getGroupFieldViewOrder()
        }
    };
}
function getGroupFieldViewOrder(): LayoutSection[] {
    const gFields: string[] = [ 'Group', 'Group-Root' ];
    const gRootField = state.getFieldState( 'sub', 'Group-Root' );/*dbug-log*///console.log( 'getGroupFieldViewOrder: gRootField: %O', cloneObj( gRootField ) );
    if ( !gRootField ) return [ [ 'Group' ] ]; //Before form-init completed
    if ( !gRootField.shown ) gFields.pop();
    return [ ...gFields, ...getRootRanks( gRootField ) ].map( f => [ f ] );
}
/* --------------------------- RANK FIELDS ---------------------------------- */
function getRootRanks( gRootField: GroupField ): string[] {
    if ( !gRootField.misc?.rcrd?.subRanks ) return [];
    const ranks = gRootField.misc.rcrd.subRanks as string[];
    return ranks.map( f => f ).reverse();
}
/* ------------------------ FIELD DEFINITIONS ------------------------------- */
function getCoreGroupAndRankFieldConfig(): ModelFormFields<GroupField> {
    return {
        Group: {
            entity: 'Taxon',
            misc: {
                customValueStore: true
            },
            name: 'Group',
            type: 'select'
        },
        'Group-Root': {
            entity: 'Taxon',
            misc: {
                customValueStore: true
            },
            name: 'Group-Root',
            type: 'select'
        },
        Class: {
            entity: 'Taxon',
            name: 'Class',
            type: 'select'
        },
        Order: {
            entity: 'Taxon',
            name: 'Order',
            type: 'select'
        },
        Family: {
            entity: 'Taxon',
            name: 'Family',
            type: 'select'
        },
        Genus: {
            entity: 'Taxon',
            name: 'Genus',
            type: 'select'
        },
        Species: {
            entity: 'Taxon',
            name: 'Species',
            type: 'select'
        }
    };
}
