/**
 * Modifies location-data for local storage:
 * - [entity]Names - an object with each entity's displayName(k) and id.
 * * location - resaved locations with an additional data point for countries.
 *
 * TOC
 *     REGION
 *     COUNTRY
 *     TYPES
 *     MODIFY RECORDS
 *
 * Export
 *     modifyLocDataForLocalDb
 */
import { removeData, storeData } from '@localdata/util';
import * as _t from '@types';
import { getNameObj, getRcrds, getType, getTypeObj } from '../init-helpers';

type LocationData = {
    location: _t.EntityRecords;
    locationType: _t.EntityRecords;
    habitatType: _t.EntityRecords;
};
export function modifyLocDataForLocalDb ( data: LocationData ) {    /*dbug-log*///console.log("modifyLocDataForLocalDb called. data = %O", data);
    handleRegionData( data.location, data.locationType );
    handleCountryData( data.location, data.locationType );
    handleTypeData( data.habitatType, data.locationType );
    modifyLocationRecords( data.location );
}
/* =================== REGION =============================================== */
function handleRegionData ( locs: _t.EntityRecords, locTypes: _t.EntityRecords ) {
    const regions = getType( locTypes, 'region', 'locations' );     /*dbug-log*///console.log('--handleRegionData[%O]', regns);
    storeData( 'regionNames', getNameObj( regions, locs ) );
    storeData( 'topRegionNames', getTopRegionNameData( locs, regions ) );
}
function getTopRegionNameData ( locs: _t.EntityRecords, regions: number[] ): _t.IdsByName {
    const data: _t.IdsByName = {};
    _t.objectValues( getRcrds( regions, locs ) ).forEach( ifRegionAddData );
    return data;

    function ifRegionAddData ( loc: _t.EntityRecord ) {
        const name = loc?.displayName;
        if ( name ) {
            if ( !loc.parent ) data[ name ] = loc.id;
        } else {
            console.error( `Location name ${ name } not found` );
        }
    }
}
/* ================== COUNTRY =============================================== */
function handleCountryData ( locs: _t.EntityRecords, locTypes: _t.EntityRecords ) {
    const cntrys = getType( locTypes, 'country', 'locations' );     /*dbug-log*///console.log('--handleCountryData[%O]', cntrys);
    storeData( 'countryNames', getNameObj( cntrys, locs ) );
    storeData( 'countryCodes', getCodeNameDataObj( cntrys, locs ) );
}
/** Return an obj with the 2-letter ISO-country-code (k) and the country id (v).*/
function getCodeNameDataObj ( ids: number[], rcrds: _t.EntityRecords ): _t.IdsByName {
    const data: _t.IdsByName = {};
    ids.forEach( id => data[ rcrds[ id ]?.isoCode ] = id );
    return data;
}
/* ==================== TYPES =============================================== */
function handleTypeData ( habTypes: _t.EntityRecords, locTypes: _t.EntityRecords ) {
    storeData( 'habTypeNames', getTypeObj( habTypes ) );
    storeData( 'locTypeNames', getTypeObj( locTypes ) );
    [ 'locationType', 'habitatType' ].forEach( k => removeData( k ) );
}
/* ==================== MODIFY RECORDS ====================================== */
function modifyLocationRecords ( locations: _t.EntityRecords ) {
    storeData( 'location', addInteractionTotalsToLocs( locations ) );
}
/** Adds the total interaction count of the location and it's children. */
function addInteractionTotalsToLocs ( locs: _t.EntityRecords ) {
    for ( let id in locs ) {
        const loc = locs[ id ];
        if ( loc ) {
            loc.totalInts = getTotalInteractionCount( loc, locs );  /*dbug-log*///console.log('[%s] total = [%s]', locs[id].displayName, locs[id].totalInts);
        } else {
            console.error( `Location [${ id }] not found` );
        }
    }
    return locs;
}
function getTotalInteractionCount ( loc: _t.EntityRecord, locs: _t.EntityRecords ) {
    let ttl = loc.interactions.length;
    loc.children.forEach( ( id: number ) => {
        let child = locs[ id ];
        if ( child ) {
            ttl += getTotalInteractionCount( child, locs );
        } else {
            console.error( `Location [ ${ id } ] not found` );
        }
    } );
    return ttl;
}