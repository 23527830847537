/**
 * Handles the retrieval of form state-data.
 *
 * TOC
 *     STATE|PROP
 *         ACTION
 *         HANDLER
 *         PARENT
 *     PREDICATES
 */
import { FM } from '../core/state-manager.ts';
import { getFormLevel } from './get-core-state.js';
import { isApprovedOrRejected } from './get-review-state.js';
/* ======================== STATE|PROP ====================================== */
export function getFormState( lvl, prop ) {                         /*dbug-log*///console.log( '   --getFormState lvl[%s] prop[%s]', lvl, prop );
    const FS = FM.getLevelState( lvl );
    if ( !FS ) return null;
    if ( !prop ) return FS;
    return Array.isArray( prop ) ? getStateMap( prop, FS ) : FS[ prop ];
}
function getStateMap( props, FS ) {
    const data = {};
    props.forEach( prop => {
        data[ prop ] = FS[ prop ]
    } );
    return data;
}
/* -------------------------- ACTION ---------------------------------------- */
/* Gets the entry action of the form. */
export function getEntryAction( lvl ) {
    const FS = getFormState( lvl );
    return FS.review ? FS.review.form.action : FS.action;
}
function getFormAction( lvl ) {
    const action = getFormState( lvl, 'action' );
    if (action !== 'select') return action;
    const parentForm = getParentFormState( lvl );
    return parentForm.action;
}
/* ------------------------- HANDLER ---------------------------------------- */
/**
 * @param  {object} fState       Form-group state
 * @param  {string} type         Handler type (eg, onFormClose, afterFormClose, etc.)
 * @param  {boolean} returnFunc  Flag to return stub-function if no handler available
 * @return {function|null}
 */
export function getFormHandler( lvl, type, returnFunc = false ) {
    const FS = getFormState( lvl );                                 /*dbug-log*///console.log( '   --getFormHandler type[%s] returnFunc?[%s] FS[%O]', type, returnFunc, FS );
    const handler = FS.handlers[ type ];
    return handler ? handler : ( returnFunc ? () => {} : null );
}
/* -------------------------- PARENT ---------------------------------------- */
export function getParentFormState( lvl ) {
    const pLvl = getFormLevel( 'parent', lvl );
    return FM.getLevelState( pLvl );
}
/* ======================== PREDICATES ====================================== */
export function isFormActive( lvl = 'top' ) {
    return !!getFormState( lvl );
}
export function isEditForm( lvl ) {
    return getFormAction( lvl ) === 'edit';
}
export function isReviewForm( lvl ) {
    return getFormAction( lvl ) === 'review';
}
export function isDisplayFormOnly( lvl ) {
    const FS = getFormState( lvl );
    if ( !FS ) return false; // Form closed
    return FS.review && isApprovedOrRejected( FS.review );
}
