/**
 * Validates that the new parent is:
 * - a higher rank than the taxon
 * - its rank is available in the selected taxon-group
 *
 * Export
 *     onParentChange
 *     ifMissingParentTaxon
 *
 * TOC
 *    ON CHANGE
 *    PARENT VALIDATION
 *        MISSING PARENT RANK
 */
import { alert, components, form, state } from '@dataentry';
import { getSelTxt } from '@elems/combo';
import { isNotNumber } from '@util';
import { ifRankTooLow } from './rank.js';

/* ======================= ON CHANGE ======================================== */
/** @param  {int|obj} val   Either an ID or a jQuery event obj. */
export function onParentChange ( val ) {
    const pTxn = getNewParentRecord( val );                         /*dbug-log*///console.log("--onParentChange pTxn[%O]", pTxn);
    const valData = buildParentValData( pTxn );
    if ( !validateParent( valData ) ) return;  //Issue alert shown
    form.buildOptAndUpdateCombo( 'Parent', pTxn.id, 'silent' );
    form.onTaxonFieldSelection( 'Parent', pTxn.id );
    updateGroupState( pTxn );
    components.ifFormValidClearAlertsAndEnableSubmit( 'top' );
}
function getNewParentRecord ( val ) {
    return isNotNumber( val ) ? form.getSelectedTaxon() : state.getRecords( 'taxon', val );
}
function updateGroupState ( pTxn ) {
    state.setFieldValue( 'top', 'Parent', pTxn.id );
    state.setFieldState( 'top', 'Parent', { misc: pTxn.rank.displayName } );
    state.setFieldValue( 'top', 'Group', pTxn.group.id );
    state.setFieldValue( 'top', 'Group-Root', pTxn.group.root.id );
}
/* ======================= PARENT VALIDATION ================================ */
function buildParentValData ( pTxn ) {
    const data = {};
    data.orderedRanks = state.getRecords( 'orderedRanks' );         /*dbug-log*///console.log('    --buildParentValData data[%O]', data);
    data.rcrd = pTxn;
    data.newRank = pTxn.rank.displayName;
    data.childRank = getSelTxt( 'Rank' );
    return data;
}
function validateParent ( data ) {                                  /*dbug-log*///console.log("--validateParent data[%O]", data);
    const issues = {
        rankNotAvailableInNewGroup: ifInvalidGroupRank( data.childRank ),
        needsHigherRank: ifRankTooLow( data.newRank, data.childRank, data.orderedRanks ),
        needsGenusPrnt: ifSpeciesParentMustBeGenus( data.newRank, data.childRank )
    };                                                              /*dbug-log*///console.log('   --issues[%O]', issues);
    for ( let tag in issues ) {
        if ( issues[ tag ] ) return alert.showFormValAlert( 'Parent', tag, 'top' );
    }
    alert.clearActiveAlert( 'top', 'Parent' );
    return true;
}
function ifSpeciesParentMustBeGenus ( pRank, txnRank ) {
    return txnRank === 'Species' && pRank !== 'Genus';
}
function ifInvalidGroupRank ( txnRank ) {
    const sgField = state.getFieldState( 'sub', 'Group-Root', 'misc' );/*dbug-log*///console.log('--ifInvalidGroupRank? txnRank[%s] sgField[%O]', txnRank, sgField);
    return sgField.rcrd.subRanks.indexOf( txnRank ) === -1;
}
/* -------------------- MISSING PARENT RANK --------------------------------- */
/** Validates that the specified parent-rank is filled, */
export function ifMissingParentTaxon ( rankName ) {
    const ranks = [ 'Family', 'Genus', 'Species' ];
    const parentRank = getParentRank();                             /*dbug-log*///console.log( "--ifMissiongParentTaxon rankName[%s] parentRank[%s]", rankName, parentRank );
    if ( !parentRank || $( `#sel-${ parentRank }` ).val() ) return;
    alert.showFormValAlert( rankName, `no${ parentRank }`, 'sub2' );
    return true;

    function getParentRank() {
        const idx = ranks.indexOf( rankName );
        return idx > 0 ? ranks[ idx-1 ] : false;
    }
}
