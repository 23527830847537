/**
 * Returns the html of a checkbox labeled 'Show all fields' that toggles the
 * form fields displayed between the default fields and all available.
 * If there are no additional fields for the form, no checkbox is returned.
 * @return {elem} Checkbox and label that will 'Show all fields'
 *
 * Export
 *     ifMultipleDisplaysGetToggle
 *
 * TOC
 *     SHOW ALL FIELDS CHECKBOX
 *         LABEL
 *         CHECKBOX
 *     TOGGLE FIELD-DISPLAY
 */
import { addEnterKeypressClick, getElem } from '@elems';
import { components, model, state } from '@dataentry';
/* ================== SHOW ALL FIELDS CHECKBOX ============================== */
export function ifMultipleDisplaysGetToggle ( entity, fLvl ) {      /*dbug-log*///console.log( '+--ifMultipleDisplaysGetToggle [%s][%s]', entity, fLvl );
    if ( !ifFormHasMultipleFieldDisplays( fLvl ) ) return null;
    const cntnr = buildToggleFieldsContainer();
    $( cntnr ).append( [ getCheckbox( fLvl, entity ), getLabel( fLvl ) ] );
    return cntnr;
}
/** On create-form init, if the 'simple' display is availble it is the default set. */
function ifFormHasMultipleFieldDisplays ( fLvl ) {
    const defaultDisplay = state.getFormState( fLvl, 'display' );   /*dbug-log*///console.log( '--ifFormHasMultipleFieldDisplays default[%s]', defaultDisplay );
    return defaultDisplay !== 'all';
}
function buildToggleFieldsContainer () {
    return getElem( 'div', { class: 'all-fields-cntnr flex-row' } );
}
/* ----------------------------- LABEL -------------------------------------- */
function getLabel ( fLvl ) {
    const attr = { for: fLvl + '-all-fields', text: 'Show All' };
    return getElem( 'label', attr );
}
/*---------------------------- CHECKBOX ------------------------------------- */
function getCheckbox ( fLvl, entity ) {
    const chkbx = buildChkbxInput( fLvl );
    $( chkbx ).on( 'click', handleToggleFields.bind( null, fLvl, entity ) );
    addEnterKeypressClick( chkbx );
    return chkbx;
}
function buildChkbxInput ( fLvl ) {
    const attr = { id: fLvl + '-all-fields', type: 'checkbox', value: 'Show all fields' };
    const input = getElem( 'input', attr );
    return input;
}
/* ===================== TOGGLE FIELD-DISPLAY =============================== */
function handleToggleFields ( fLvl, entity ) {                         /*dbug-log*///console.log('@--handleToggleFields [%s][%s]', fLvl, entity);
    model.onToggleFieldsUpdateModel( fLvl );
    components.onFormConfigChanged( fLvl, entity );
}
