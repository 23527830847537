/**
 * Get the center coordinates of a location from the GeoJSON data. Build a polygon
 * to be added to the map from the GeoJSON data, if it exists.
 *
 * EXPORT
 *     getCenterCoords
 *     getLatLngObj
 *     buildPolygon
 *
 * TOC
 *     GEOJSON POLYGON
 *         GET CENTER COORDINATES
 *         BUILD POLYGON
 */
import { getMapRecords } from '../manager.js';
/* ======================= GEOJSON POLYGON ================================== */
export function getGeoJsonPolygon( loc ) {
    if ( !loc.geoJsonId ) return false;
    return getLocPolygon( loc, getMapRecords( 'geoJson', loc.geoJsonId ) );
}
/* ------------------- GET CENTER COORDINATES ------------------------------- */
export function getCenterCoords ( loc, geoId ) {
    if ( !geoId ) return false;                                     /*dbug-log*///console.log( ' +-- getCenterCoords [%O]', loc );
    return getLatLngObj( loc, getMapRecords( 'geoJson', geoId ) );
}
/** Return a leaflet LatLng object from the GeoJSON Long, Lat point */
export function getLatLngObj ( loc, locGeoJson ) {                  /*dbug-log*///console.log( '  +-- getLatLngObj loc[%O] geoJson[%O]', loc, locGeoJson )
    if ( !locGeoJson.displayPoint ) return getLocCenterCoords( loc, locGeoJson );
    let array = JSON.parse( locGeoJson.displayPoint );
    return L.latLng( array[ 1 ], array[ 0 ] );
}
function getLocCenterCoords( loc, locGeoJson ) {
    const { polygon } = getLocPolygon( loc, locGeoJson );
    return polygon.getBounds().getCenter();
}
function getLocPolygon ( loc, locGeoJson ) {                        /*dbug-log*///console.log( '       --getLocPolygon. loc[%O] locGeoJson[%O]', loc, locGeoJson );
    if ( !loc || !locGeoJson ) return false;
    const polygon = buildPolygon( loc, locGeoJson );
    console.log( '### New Center Coordinates ### "%s" => ', loc.displayName, polygon.getBounds().getCenter() );
    return { polygon, type: locGeoJson.type };
}
/* ------------------- BUILD POLYGON ---------------------------------------- */
export function buildPolygon ( loc, geoData ) {                     /*dbug-log*///console.log( '          +--buildPolygon loc[%O] geoData[%O]', loc, geoData );
    const feature = {
        "type": "Feature",
        "geometry": {
            "type": geoData.type,
            "coordinates": JSON.parse( geoData.coordinates )
        },
        "properties": {
            "name": loc.displayName
        }
    };
    return L.geoJson( feature );
}