/**
 * Apply filters that update the table row-data directly.
 */
import * as table from '@explore/table';
import { enableClearFiltersButton, updateFilterStatusMsg } from '@explore/ui';
import { getRowDataFilters, updateTaxonComboboxes } from '@explore/table/filter';
import { getFilteredRowData } from './filter-rows.js';

export function getRowDataForCurrentFilters( rowData ) {                          //console.log('getRowDataForCurrentFilters. rowData = %O', rowData);
    const filters = getRowDataFilters();
    if ( !Object.keys( filters ).length ) { return rowData; }                       //console.log('getRowDataForCurrentFilters = %O', filters);
    return getFilteredRowData( filters, rowData );
}

/** If filter cleared, filters all table rows, else applies on top of current filters. */
export function onFilterChangeUpdateRowData() {                                 //console.log('onFilterChangeUpdateRowData')
    if ( !Object.keys( getRowDataFilters() ).length ) return table.resetDataTable();
    const rowData = getRowDataForCurrentFilters( table.getState( 'rowData' ) );
    enableClearFiltersButton();
    setCurrentRowData( rowData );
}
function setCurrentRowData( rowData ) {
    const tblState = table.getState( [ 'api', 'curFocus' ] );
    tblState.api.setRowData( rowData );
    updateFilterStatusMsg();
    table.setTreeToggleData( false );
    if ( tblState.curFocus === 'taxa' ) { updateTaxonComboboxes( rowData ); }
}