/**
 * Initializes field values during review form-model build.
 */
import { cloneObj } from '@util';
export function setReviewFieldValues ( model ) {
    const pFields = model.review.form.fields;                       /*dbug-log*///console.log( '-- setReviewFieldValues pFields[%O] fields[%O]', pFields, model.fields );
    for ( let key in pFields ) addDataToField( key );
    return model;

    function addDataToField ( key ) {                             /*dbug-log*///console.log( '-- addDataToField key[%s]', key );
        const { prop, ...rest } = pFields[ key ];
        const field = { ...model.fields[ key ], ...rest };        /*dbug-log*///console.log( '      --after [%s][%O]', key, cloneObj( field ) );
        model.fields[ key ] = field ;
        ifSourceTypeFieldSetFormType( field, model );
    }
}
function ifSourceTypeFieldSetFormType ( field, model ) {
    if ( model.core !== 'Source' || field.name !== model.name + 'Type' ) return;
    model.type = field.value.text;
}