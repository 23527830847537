/**
 * Misc utility methods for the Explore page UI.
 *
 * Export
 *     updateUiForMapView
 *     updateUiForTableView
 *     fadeTable
 *     showTable
 *     showExplorePopupMsg
 *     hideExplorePopupMsg
 *
 * TOC
 *     SWITCH BETWEEN MAP AND TABLE UI
 *     UTILITY
 */
import { getSelVal, setSelVal } from '@elems/combo';
import { map } from '@explore';
import { closeOpenPanels, toggleTableButtons } from '..';
/* ====================== SWITCH BETWEEN MAP AND TABLE UI =================== */
export function updateUiForMapView ( noPopup ) {
    toggleTableButtons( false );
    updateMapUiForMapView();
    closeOpenPanels();
    if ( noPopup ) return;
    showExplorePopupMsg();
}
export function updateUiForTableView () {
    toggleTableButtons();
    updateMapUiForTableView();
}
function updateMapUiForMapView () {
    updateBttnToReturnRcrdsToTable();
    $( '#tool-bar' ).fadeTo( 'fast', 1 );
    $( '#explore-table' ).hide();
    $( '#map' ).show();
}
function updateMapUiForTableView () {
    $( '#explore-table' ).fadeTo( 'fast', 1 );
    $( '#map, #filter-in-tbl-msg' ).hide();
    updateBttnToShowRcrdsOnMap();
}
function updateBttnToReturnRcrdsToTable () {
    $( '#shw-map' ).text( 'Return to Table' );
    $( '#shw-map' ).off( 'click' ).on( 'click', returnRcrdsToTable )
        .prop( 'title', 'Close map and reopen records in table.' );
}
function updateBttnToShowRcrdsOnMap () {
    $( '#shw-map' ).text( 'Map Interactions' );
    $( '#shw-map' )
        .off( 'click' )
        .on( 'click', map.showIntsOnMap )
        .prop( 'title', 'Show interactions on a map.' );
}
function returnRcrdsToTable () {                                     /*dbug-log*///console.log('       +--returnRcrdsToTable');
    updateUiForTableView();
    if ( getSelVal( 'View' ) === 'map' ) setSelVal( 'View', 'tree' );
}
/* ==================== UTILITY ============================================= */
export function fadeTable () {
    $( '#borderLayout_eRootPanel, #tool-bar' ).fadeTo( 100, .2 );
}
export function showTable () {
    $( '#borderLayout_eRootPanel, #tool-bar' ).fadeTo( 100, 1 );
}
/** Adds overlay over data-base page content and displays a "Loading..." popup */
export function showExplorePopupMsg ( msg ) {                         /*dbug-log*///console.log("showExplorePopupMsg. msg = ", msg);
    const popUpMsg = msg || 'Loading...';
    $( '#explore-popup' ).text( popUpMsg );
    $( '#explore-popup' ).addClass( 'loading' ); //used in testing
    $( '#explore-popup, #explore-overlay' ).show();
    fadeTable();
}
export function hideExplorePopupMsg () {                            /*dbug-log*///console.log("hideExplorePopupMsg")
    $( '#explore-popup, #explore-overlay' ).hide();
    $( '#explore-popup' ).removeClass( 'loading' ); //used in testing
    showTable();
}