/**
 * Author/editor fields must have all fields filled continuously. There can
 * be no blanks in the selected order. If found, an alert is shown to the user.
 *
 * Export
 *     handleBlanks
 *     alertBlank
 */
/* UTIL */
import * as _t from '@types';
/* DATA-ENTRY */
import { alert, form, model, state } from '@dataentry';
/* ========================== HANDLE BLANKS ================================= */
/**
 * Author/editor fields must have all fields filled continuously. There can
 * be no blanks in the selected order. If found, an alert is shown to the user.
 */
export function handleBlanks(
    fLvl: model.FormGroup,
    type: _t.AuthorType,
    clearedOrd?: number
): void {
    if ( state.isReviewForm( fLvl ) ) return; //Blanks are handled elsewhere in review mode.
    const vals = state.getFieldValue( fLvl, type );                 /*dbug-log*///console.log( '--handleBlanks [%s][%O] cleared?[%s]', type, vals, clearedOrd );
    const invalidBlank = findInvalidBlanks( vals, clearedOrd );
    if ( invalidBlank ) return alertBlank( type, fLvl );
    ifPreviousAlertClearIt( type, fLvl );
}
function findInvalidBlanks(
    vals: model.MultiFieldModel['value'],
    cleared?: number
): boolean {
    let blanks = _t.objectKeys( vals ).reduce( checkValsForBlanks, '' );
    return blanks === 'found' || false;

    function checkValsForBlanks( blanks: string, ord: number ) {    /*dbug-log*///console.log( '    --ord[%s] val[%s] blanks[%s]?', ord, vals[ ord ], blanks )
        return checkOrdForInvalidBlank( blanks, ord, vals, cleared );
    }
}
function checkOrdForInvalidBlank(
    blanks: string,
    ord: number,
    vals: model.MultiFieldModel['value'],
    cleared?: number
): 'found' | 'maybe' | '' {
    return isBlank( vals, ord, cleared )
        ? validateBlank( blanks, ord )
        : blanks.length ? 'found' : '';  //There can be no selections after a blank is found
}
/**
 * True if the stored value is blank or if the ordinal is the same as a cleared
 * field's that initiated this blank validation check.
 */
function isBlank(
    vals: model.MultiFieldModel['value'],
    ord: number,
    cleared?: number
): boolean {
    return !vals[ ord ] || ord == cleared
}
/** The first blank found is valid if no other selections follow. */
function validateBlank( blanks: string, ord: number ): 'found' | 'maybe' {
    return ord > 1 && blanks.length ? 'found' : 'maybe';
}
/* ========================== BLANK ALERTS ================================== */
export function alertBlank( type: _t.AuthorType, fLvl: model.FormGroup ) {
    if ( state.isReviewForm( fLvl ) ) return; //Blanks are handled elsewhere in review mode.
    alert.showFormValAlert( type, 'fillAuthBlanks', fLvl );
}
function ifPreviousAlertClearIt( type: _t.AuthorType, fLvl: model.FormGroup ) {
    if ( !$( `#${ type }_alert.${ fLvl }-active-alert` ) ) return;
    alert.clrContribFieldAlert( type, fLvl );
    form.onCitationFieldChange( fLvl );
}