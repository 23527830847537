/**
 * Contains code specific to form maps.
 *
 * Export
 *     afterMapLoads
 *     addListenerToGpsFields
 *     addMapToLocForm
 *     autofillCoordinateFields
 *     focusParentAndShowChildLocs
 *     ifCoordsAddMapPin
 *
 * TOC
 *     ADD MAP TO FORM
 *         ON MAP LOADED
 *         LOAD COUNTRY LOCATIONS
 *     COMBO FIELDS
 *         LISTENERS
 *         AUTOFILL
 *         ADD MAP PIN
 */
import { getElem, getSelVal } from '@elems';
import { map } from '@explore';
import { components, model, state } from '@dataentry';
/* ==================== ADD MAP TO FORM ===================================== */
export function addMapToLocForm( type, $form = $( '#Location_fields' ) ) {/*dbug-log*///console.log('--addMapToLocForm type[%s] form[%O]', type, $form);
    addFormContainer( type, $form );
    if ( navigator.onLine === false ) return showNoInternetMsg();
    initLocFormMap( type );
}
function addFormContainer( type, $form ) {
    const cntnr = getElem( 'div', { id: 'form-map' } );
    $( cntnr ).data( 'fLvl', state.getEntityFormLevel( 'location' ) );
    $form.after( cntnr );
}
function showNoInternetMsg () {
    $( '#form-map' ).html( '<div>No Internet Connection</div>' );
}
function initLocFormMap( type ) {
    const pId = $( '#sel-Country-Region' ).val() || $( '#sel-Country' ).val();
    const mData = {
        pId: pId,
        // locRcrds:  state.getRecords( 'location' ),
        formConfig: model.getEntityFormConfig( 'Location' ).misc,
        type: type
    };
    map.initFormMap( mData );
}
/* ---------------------------- ON MAP LOADED ------------------------------- */
export function afterMapLoads( onLoadFunc, id ) {
    if ( $( '#form-map' ).data( 'loaded' ) ) {
        onLoadFunc( id );
        $( '#form-map' ).removeData( 'loaded' );
    } else {
        window.setTimeout( () => afterMapLoads( onLoadFunc, id ), 500 );
    }
}
/* ------------------------- LOAD COUNTRY LOCATIONS ------------------------- */
export function focusParentAndShowChildLocs( type, val ) {
    if ( !val || !navigator.onLine ) return;
    initLocFormMap( type, val );
}
/** ====================== COORD FIELDS ===================================== */
/* ------------------------------ LISTENERS --------------------------------- */
export function addListenerToGpsFields( fLvl, params = [ true ] ) { /*dbug-log*///console.log('--addListenerToGpsFields fLvl[%s] params[%O]', fLvl, params);
    $( '#Latitude_f input, #Longitude_f input' ).change( validateLocFields );

    function validateLocFields() {                                  /*dbug-log*///console.log('   @--validateLocFields', );
        const coords = getCoordVals().filter( c => c );
        components.ifFormValidClearAlertsAndEnableSubmit( fLvl );
        if ( coords.length === 1 ) components.toggleSubmitBttn( fLvl, false );
        if ( coords.length !== 2 ) map.addFormMapPin( false );
        if ( coords.length === 2 ) map.addFormMapPin( params );
    }
}
function getCoordVals() {
    return [ 'Lat', 'Long' ].map( l => lintCoord( l ) ).filter( v => v );
}
function lintCoord( prefix ) {
    const field = prefix+'itude';
    const input = $( `#${ field }_f input` )[ 0 ];
    return input.validity.valid ? input.value : null;
}
/* -------------------------------- AUTOFILL -------------------------------- */
export function autofillCoordinateFields( lat, lng, cntryId ) {
    const fLvl = state.getEntityFormLevel( 'location' );
    setCoordinateFieldData( fLvl, 'Latitude', lat.toFixed( 7 ) );
    setCoordinateFieldData( fLvl, 'Longitude', lng.toFixed( 7 ) );
    components.setSilentVal( fLvl, 'Country', cntryId );
    components.ifFormValidClearAlertsAndEnableSubmit( fLvl );
}
function setCoordinateFieldData( fLvl, field, coord ) {
    $( `#${ field }_f input` ).val( coord );
    state.setFieldValue( fLvl, field, coord );
}
/* --------------------------- ADD MAP PIN ---------------------------------- */
export function ifCoordsAddMapPin ( id ) {                          /*dbug-log*///console.log(' --ifCoordsAddMapPin lat?[%s] id[%s]', $('#Latitude_f input').val(), id);
    if ( !$( '#Latitude_f input' ).val() ) return;
    map.addFormMapPin( id, 'edit', getSelVal( 'Country' ) );
}