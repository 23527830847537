/**
 * Prepares review entities for local storage:
 * + DATA-MANAGER
 *     - rvwContribNames: Contributors with ReviewEntries {displayName(k), id(v)}.
 *     - reviewEntry: all ReviewEntries
 * + CONTRIBUTOR
 *     - reviewEntry: ReviewEntry records, quarantined and only available to their contributor
 * + BOTH
 *     - activeStageNames: ReviewEntry stage active forms(k)
 *     - availableReview: ReviewEntry records available for current user to review
 *     - rvwStages: ReviewEntry stages.
 *     - reviewCounts: ReviewEntry counts by entity - create, edit, and delete.
 *
 * Export
 *     modifyRvwDataForLocalDb
 *     isAvailableToCurrentUser
 *
 * TOC
 *     SORT AVAILABLE REVIEW-ENTRIES
 *         SORT STAGE DATA
 *     RESTORE CONTRIBUTIONS
 *     IS AVAILABLE TO USER
 *         CONTRIBUTOR
 *         MANAGER
 *         HELPERS
 */
import * as _t from '@types';
import { syncReviewEntry } from '@localdata/sync/review';
import * as db from '@localdata/util';
import { cloneObj, getUserRole } from '@util';

type ReviewData = {
    contributions: Record<number, _t.ReviewEntry>;
    reviewEntry: Record<number, _t.ReviewEntry>;
    reviewStages: Record<number, _t.ReviewStage>;
};
export function modifyRvwDataForLocalDb ( data: ReviewData ): void | Promise<void> {/*dbug-log*///console.log( "--modifyRvwDataForLocalDb [%O]", data );
    if ( [ 'visitor', 'user' ].indexOf( getUserRole() ) !== -1 ) return deleteRvwData();
    parseAndUpdateReviewEntry( data.reviewEntry, data.contributions );
    sortAndStoreStageNames( data.reviewStages );
    return addContributorReviewEntries( data.contributions );
}
/** For user roles without the data-entry features   */
function deleteRvwData (): void {
    [ 'contributions', 'reviewEntry', 'stages' ].forEach( k => db.removeData( k ) );
}
/** Parses nested json data */
function parseAndUpdateReviewEntry ( ReviewEntries: ReviewData["reviewEntry"], contributions: ReviewData["contributions"] ): void {
    const records = Object.keys( ReviewEntries ).length ? ReviewEntries : contributions;
    const parsed = getParsedReviewEntries( records );
    db.storeData( 'reviewEntry', parsed );
    setAvailableReviewEntryAndStats( parsed, db.getValue( 'user' ) as _t.User );
}
function getParsedReviewEntries( ReviewEntries: ReviewData["reviewEntry"] ): Record<number, _t.ReviewEntry> {
    return _t.objectValues( ReviewEntries ).reduce( addParsedReviewEntry, {} );
}
function addParsedReviewEntry (
    all: Record<number, _t.ReviewEntry>,
    record: _t.ReviewEntry
): Record<number, _t.ReviewEntry> {
    const rEntry = cloneAndParseReviewEntry( record );
    all[ rEntry.id ] = rEntry;
    return all as Record<number, _t.ReviewEntry>;
}
export function cloneAndParseReviewEntry( record: _t.EntityRecord | string ): _t.ReviewEntry {
    const rEntry = typeof record === 'string' ? JSON.parse( record ) : cloneObj( record );
    rEntry.form = JSON.parse( rEntry.form );
    rEntry.payload = JSON.parse( rEntry.payload );
    delete rEntry.form.submitted; //Used server-side during data-approval
    return rEntry;
}
/* ===================== SORT AVAILABLE REVIEW-ENTRIES ====================== */
/**
 * ReviewEntry create, edit, and delete counts for entries available to review
 * for the current user.
 * @return {promise}      Unused
 */
function setAvailableReviewEntryAndStats (
    ReviewEntries: ReviewData["reviewEntry"] | null,
    user: _t.User | null
): void | Promise<void> {                                           /*dbug-log*///console.log( " --setAvailableReviewEntryAndStats ReviewEntries?[%O] user?[%O]", ReviewEntries, user );
    return Promise.resolve( handleReviewEntryStatUpdates( ReviewEntries, user ) )
}
function handleReviewEntryStatUpdates(
    ReviewEntries: ReviewData["reviewEntry"] | null,
    user: _t.User | null
): void | Promise<void> {
    return user && ReviewEntries
        ? sortAllReviewEntry( ReviewEntries, user )
        : getDataThenSort()
}
function getDataThenSort (): Promise<void> {
    return db.getData( [ 'reviewEntry', 'user' ] )
        .then( data => sortAllReviewEntry( data.reviewEntry as Record<number, _t.ReviewEntry>, data.user as _t.User ) );
}
type ReviewEntryStats = {
    counts: Record<string, {
        create: number;
        edit: number;
        delete: number;
    }>;
    editors: Record<string, number>;
    stages: Record<string, number>;
};
function sortAllReviewEntry ( ReviewEntries: ReviewData["reviewEntry"], user: _t.User ): void {/*dbug-log*///console.log( " --sortAllReviewEntry ReviewEntries[%O] user[%O]", ReviewEntries, user );
    const t: ReviewEntryStats = { counts: {}, stages: {}, editors: {} };
    sortAndStoreRelevantReviewEntry();
    storeTrackedData( t );

    function sortAndStoreRelevantReviewEntry (): void {
        const rcrds: _t.ReviewEntry[] = [];
        _t.objectValues( ReviewEntries ).forEach( sortReviewEntry );
        db.storeData( 'availableReview', rcrds );                  /*dbug-log*///console.log( " -- sortAllReviewEntry tracked[%O] rcrds[%O]", t, rcrds );

        function sortReviewEntry ( rEntry: _t.ReviewEntry ): void {  /*dbug-log*///console.log( " --sortReviewEntry rEntry[%O] user[%O]", rEntry, user );
            if ( !isAvailableToCurrentUser( rEntry, user ) ) return;
            trackReviewEntry( rEntry );
            rcrds.push( rEntry );
        }
    }
    function trackReviewEntry ( rEntry: _t.ReviewEntry ): void {     /*dbug-log*///console.log( " --trackReviewEntry rEntry[%O]", rEntry );
        if ( !t.counts[ rEntry.entity ] ) initEntityCountObj( rEntry.entity );
        ++t.counts[ rEntry.entity ]![ rEntry.form.action as ( 'create' | 'edit' | 'delete' ) ];
        t.stages[ rEntry.stage.name ] = rEntry.stage.id;
        t.editors[ rEntry.createdBy.displayName ] = rEntry.createdBy.id;
    }
    function initEntityCountObj ( entity: string ): void {
        t.counts[ entity ] = { create: 0, edit: 0, delete: 0 };
    }
}
/**
 * Stores the counts and contributor names of available ReviewEntries.
 * @param  {object} tracked Object with sorted ReviewEntry stats
 */
function storeTrackedData ( tracked: ReviewEntryStats ): void {
    db.storeData( 'rvwContribNames', tracked.editors );
    db.storeData( 'rvwStages', tracked.stages );
    db.storeData( 'reviewCounts', tracked.counts );                /*dbug-log*///console.log( " -- storeTrackedData tracked[%O]", tracked );
}
/* ------------------------ SORT STAGE DATA --------------------------------- */
function sortAndStoreStageNames ( stages: ReviewData['reviewStages'] ): void {
    const active: _t.IdsByName = {};
    _t.objectKeys( stages ).forEach( storeStageName );                 /*dbug-log*///console.log(" -- sortAndStoreStageNames active[%O] passive[%O]", active, passive);
    active.Skip = 'release';        //todo2: what is this doing?
    db.storeData( 'activeStageNames', active );

    function storeStageName ( id: number ): void {
        const stage = db.getEntity<_t.ReviewStage>( stages, id );
        if ( ifStageWithoutActiveIntentionalAction( stage ) ) return;
        active[ stage.activeForm ] = id;
    }
    function ifStageWithoutActiveIntentionalAction ( stage: _t.ReviewStage ): boolean {
        const skip = [ 'Complete', 'Lock', 'Quarantine' ];
        return skip.indexOf( stage.activeForm ) !== -1;
    }
}
/* ==================== RESTORE CONTRIBUTIONS =============================== */
/** Readds the Contributor's ReviewEntries to local storage. */
function addContributorReviewEntries ( cData: Record<number, _t.ReviewEntry> ): Promise<void> {/*dbug-log*///console.log("addContributorReviewEntries cData[%O]", cData);
    return storeReviewEntries( cData )
        .then( () => db.removeData( 'contributions' ) );
}
function storeReviewEntries ( cData: Record<number, _t.ReviewEntry> ): Promise<void> {
    return _t.objectValues( cData ).reduce( ( p, rEntry ) => { //p(romise), reviewEntriesData
        return p.then( () => syncReviewEntry( rEntry ) );
    }, Promise.resolve() );
}
/* =================== IS AVAILABLE TO USER ================================= */
export function isAvailableToCurrentUser (
    rEntry: _t.ReviewEntry,
    user: _t.User
): boolean {
    const isAvailable = {
        super: isReadyForManagerReview,
        admin: isReadyForManagerReview,
        manager: isReadyForManagerReview,
        editor: Function.prototype,
        contributor: isReadyForContributorReview,
        user: Function.prototype,
        visitor: Function.prototype,
    } as const;                                                     /*dbug-log*///console.log( " --isAvailableToCurrentUser? rEntry[%O] user[%O] isAvailable?[%s]", rEntry, user, isAvailable[ user.role ]( rEntry, user ) );
    return isAvailable[ user.role ]( rEntry, user );
}
/* ------------------------ CONTRIBUTOR ------------------------------------- */
function isReadyForContributorReview ( rEntry: _t.ReviewEntry, user: _t.User ): boolean {/*dbug-log*///console.log( " --isReadyForContributorReview? id[%s] rEntry[%O] user[%O]", rEntry.id, rEntry, user );
    const stages = [ 'Approve', 'Approved', 'Return', 'Returned', 'Reject', 'Rejected' ];
    if ( !ifUserMatchAndDataReady( rEntry, user ) ) return false;   /*dbug-log*///console.log( ' -- stages[%O]', stages );
    return isReviewComplete( rEntry ) ? false : isValidStageForUser( rEntry, stages );
}
function isReviewComplete ( rEntry: _t.ReviewEntry ): boolean {
    return rEntry.stage.name === 'Completed' || !!rEntry.completed; //Before local processing || after
}
/** Created by current contributor and not in active review with a data-manager. */
function ifUserMatchAndDataReady ( rEntry: _t.ReviewEntry, user: _t.User ): boolean {
    return ifUserMatches( rEntry.createdBy, user ); //!rEntry.managedBy &&
}
/* -------------------------- MANAGER --------------------------------------- */
function isReadyForManagerReview ( rEntry: _t.ReviewEntry, user: _t.User ): boolean {/*dbug-log*///console.log( " --isReadyForManagerReview? rEntry[%O] user[%O]", rEntry, user );
    const stages = [ 'Pause', 'Held', 'Quarantine', 'Pending' ];    /*dbug-log*///console.log( ' -- isReadyForManagerReview rEntry[%O] mngrId[%s]', rEntry, user.id );
    if ( !ifUserMatches( rEntry.managedBy, user ) ) return false;
    return isValidStageForUser( rEntry, stages );
}
/* --------------------------- HELPERS -------------------------------------- */
function ifUserMatches ( dataUser: _t.User | _t.EntityBones, curUser: _t.User ): boolean { /*dbug-log*///console.log( "ifUserMatches dataUser?[%O] curUser[%O] matches?[%s]", dataUser, curUser, dataUser?.id === curUser.id );
    return dataUser ? dataUser.id === curUser.id : true;
}
function isValidStageForUser ( rEntry: _t.ReviewEntry, validStages: string[] ): boolean {/*dbug-log*///console.log( " --isValidStageForUser? id[%s] rEntry[%O] validStages[%O]", rEntry.id, rEntry, validStages );
    const stage = [ rEntry.stage.name, rEntry.form.stage.name ]; //Handles 'Locked' data
    const isValid = stage.some( s => validStages.indexOf( s ) !== -1 );
    return isValid;
}
