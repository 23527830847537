/**
 * Modifies source-data for local storage.
 * - [entity]Names - an object with each entity's displayName(k) and id.
 * - [entity]Sources - an array with of all source records for the entity type.
 *
 * Export
 *     modifySrcDataForLocalDb
 */
import { removeData, storeData } from '@localdata/util';
import * as _t from '@types';
import { getType, getTypeObj } from '../init-helpers';

type SourceServerData = {
    sourceType: _t.EntityRecords;
    citationType: _t.EntityRecords;
    publicationType: _t.EntityRecords;
    author: _t.EntityRecords;
    citation: _t.EntityRecords;
    publication: _t.EntityRecords;
    publisher: _t.EntityRecords;
};
export function modifySrcDataForLocalDb ( data: SourceServerData ) {/*dbug-log*///console.log("modifySrcDataForLocalDb called. data = %O", data);
    const authSrcs = getType( data.sourceType, 'author', 'sources' );
    const citSrcs = getType( data.sourceType, 'citation', 'sources' );
    const pubSrcs = getType( data.sourceType, 'publication', 'sources' );
    const publSrcs = getType( data.sourceType, 'publisher', 'sources' );
    storeData( 'authSrcs', authSrcs );
    storeData( 'citSrcs', citSrcs );
    storeData( 'pubSrcs', pubSrcs );
    storeData( 'publSrcs', publSrcs );
    storeData( 'citTypeNames', getTypeObj( data.citationType ) );
    storeData( 'pubTypeNames', getTypeObj( data.publicationType ) );
    [ 'sourceType' ].forEach( k => removeData( k ) );
    // [ 'citationType', 'publicationType', 'sourceType' ].forEach( k => removeData( k ) );
}