import { lcfirst } from '@util';
import * as _t from '@types';

export function getElem <T extends HTMLElement>( ...args: ConstructorParameters<typeof Element> ): T {
    return new Element( ...args ).element as T;
}
/**
 * Builds the HTML element with the given attributes, properties, and, optionally,
 * appends the given option to select elements.
 *
 * @export
 * @class Element
 */
class Element<K extends keyof HTMLElementTagNameMap> {
    private _element: HTMLElementTagNameMap[K];
    constructor (
        private _tag: K,
        _attrs?: Partial<ElementCreationOptions> & Partial<HTMLElementTagNameMap[K]> & { class?: string },
        _children?: _t.OptionObject[]
    ) {
        this._element = document.createElement( _tag );
        if ( _children ) this.#setOptions( _attrs, _children );
        if ( _attrs ) this.#setProperties( _attrs );
    }
    get element () { return this._element; }
    #setElementProperty ( key: string, value: any ): void {
        const properties = [ 'id', 'class', 'for', 'html', 'text', 'title', 'value' ];
        if ( properties.includes( key ) ) {
            const property = getPropertyName( key ) as keyof HTMLElementTagNameMap[K];
            this._element[ property ] = value;
        } else {
            this._element.setAttribute( key, value as string );
        }
    }
    #setEventListener ( type: string, listener: EventListener ): void {
        const event = ( type.startsWith( "on" ) ?
            lcfirst( type.substring( 2 ) ) : type ) as keyof HTMLElementEventMap;
        this._element.addEventListener( event, listener );
    }
    #setOptions ( _attrs: unknown, options: _t.OptionObject[] ): void {
        const v = ( _attrs as HTMLInputElement ).value;
        options.forEach( o => {
            const option = new Option( o.text, o.value, v === o.value, v === o.value );
            this._element.appendChild( option );
        } );
        ( this._element as HTMLSelectElement ).value = v;  //Clears any unintended value set during appendChild above
    }
    #setProperties ( props: Partial<HTMLElementTagNameMap[K]> ): void {
        for ( const key in props ) {
            if ( key === 'styles' ) {
                this.#setStyle( props[ key ] as Partial<CSSStyleDeclaration> );
            } else if ( isEvent( key, typeof props[ key ] ) ) {
                this.#setEventListener( key, props[ key ] as EventListener );
            } else if ( typeof props[ key ] !== "boolean" ) {     // Skips "false" attributes
                this.#setElementProperty( key, props[ key ] );
            }
        }
    }
    #setStyle ( styles: Partial<CSSStyleDeclaration> ): void {
        _t.objectKeys( styles ).forEach( key => {
            this._element.style.setProperty( key, styles[ key ] as string );
        } );
    }
}
const isEvent = ( key: string, valueType: string ) => key.startsWith( "on" ) && valueType === "function";

/** Returns the HTML element property name. */
function getPropertyName ( key: string ) {
    const aliased = getAliasedPropertyName( key );
    return aliased || key;
}
function getAliasedPropertyName ( key: string ): string | false {
    const aliased = {
        class: 'className',
        for: 'htmlFor',
        html: 'innerHTML',
        text: 'textContent'
    } as const;
    return key in aliased ? aliased[ key as keyof typeof aliased ] : false;
}