 /*
 * Filters interactions by publication type when in Source->publication view .
 * Synchronizes the tree-text filter and the combobox filter.
 *
 * Export
 *      loadSrcFilters
 *
 * TOC
 *      UI
 *          NAME TEXT
 *          PUBLICATION
 *      FILTER
 */
import { getElem, getOptsFromStoredData, initCombobox } from "@elems";
import * as f from '@explore/table/filter';
/* ========================= UI ============================================ */
export function loadSrcFilters( type ) {                              /*dbug-log*///console.log( "       --Loading source [%s] filters.", type );
    if ( $( '#focus-filters label' ).length ) { return clearPanelCombos( type ); }
    const buildUi = {
        'auths': loadNameSearchHtml.bind( null, 'Author', true ),
        'pubs': loadPubSearchHtml,
        'publ': loadNameSearchHtml.bind( null, 'Publisher', true ) };
    return buildUi[ type ]();
}
function clearPanelCombos( type ) {
    if ( type !== 'pubs' ) { return Promise.resolve(); }
    const pubTypeCombo = $( '#sel-PublicationTypeFilter' )[ 0 ];
    if ( pubTypeCombo ) { pubTypeCombo.selectize.clear( 'silent' ); }
    return Promise.resolve();
}
/* ------------------------- NAME TEXT -------------------------------------- */
/** Builds a text input for searching tree-column names. */
function loadNameSearchHtml( entity, fWidth = false ) {
    const searchTreeElem = f.getTreeTextFilterElem( entity );
    if ( fWidth ) { $( searchTreeElem ).addClass( 'fWidthRow' ); }
    $( '#focus-filters' ).append( searchTreeElem );
    return Promise.resolve();
}
/* ----------------------- PUBLICATION -------------------------------------- */
function loadPubSearchHtml() {
    return getOptsFromStoredData( 'pubTypeNames' )
        .then( loadPubSearchElems );
}
function loadPubSearchElems( pubTypeOpts ) {
    const row = getElem( 'div', { class: 'flex-row' } );
    $( row ).append( getPubFilters( pubTypeOpts ) );
    $( '#focus-filters' ).append( row );
    initCombobox( { name: 'Publication Type Filter', onBlur: true, onChange: applyPubFilter } );
    $( '#sel-PublicationTypeFilter' )[ 0 ].selectize.clear( 'silent' );
}
function getPubFilters( pubTypeOpts ) {
    const pubTypeElem = getPubTypeFilter( pubTypeOpts );
    const searchTreeElem = f.getTreeTextFilterElem( 'Publication' );
    return [ searchTreeElem, pubTypeElem ]
}
/** Builds the publication type dropdown */
function getPubTypeFilter( opts ) {                                              //console.log("buildPubSelects pubTypeOpts = %O", pubTypeOpts)
    const sel = f.newSel( addAllOpt( opts ), 'field-input', 'sel-PublicationTypeFilter', 'Publication Type' );
    return f.getFilterField( 'Type', sel );
}
function addAllOpt( opts ) {
    opts.unshift( { value: 'all', text: '- All -' } );
    return opts;
}
 /* ===================== FILTER ============================================ */
/**
 * When viewing by publication, interactions can be filtered by the publication type.
 */
function applyPubFilter( typeId ) {                                   /*dbug-log*///console.log("       --applyPubFilter typeId[%s]", typeId);
    if ( !typeId ) return;
    const type = $( `#sel-PublicationTypeFilter option[value="${ typeId }"]` ).text();
    const filter = type === '- All -' ? false : buildPubFilterObj( typeId );
    f.setFilterState( 'combo', filter, 'direct' );                   /*dbug-log*///console.log("       --applyPubFilter type[%s] filter[%O]", type, filter);
    f.onFilterChangeUpdateRowData();
}
function buildPubFilterObj( id ) {
    return { 'PublicationType': id };
}