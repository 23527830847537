/**
 * Manages the location fields in the interaction form, Country/Region and Location.
 *
 * Export
 *     addLocationSelectionMethodsNote
 *     enableCountryRegionField
 *     resetLocCombo
 *     selectLoc
 *     onCntryRegSelection
 *     onLocSelection
 *
 * TOC
 *     COUNTRY/REGION
 *     LOCATION
 *         FILL COMBOBOX
 *         SELECT LOCATION
 *     WAYS TO SELECT LOCATION NOTE
 */

import { components, form, model, state } from '@dataentry';
import * as el from '@elems';
import { getData } from '@localdata';
import { isNotNumber } from '@util';
import { focusPinAndEnableSubmitIfFormValid } from '../util/focus-pin';
/* ======================= COUNTRY/REGION =================================== */
/**
 * When a country or region is selected, the location dropdown is repopulated
 * with it's child-locations and, for regions, all habitat types. When cleared,
 * the combobox is repopulated with all locations.
 * If the map is open, the country is outlined and all existing locations within
 * are displayed @focusParentAndShowChildLocs
 */
export function onCntryRegSelection ( val ) {                       /*temp-log*///console.log( "       +--onCntryRegSelection [%s]", val );
    if ( val === "" || isNotNumber( val ) ) { return resetLocCombo( null ); }
    const loc = state.getRecords( 'location', val );
    resetLocCombo( loc );
    focusPinAndEnableSubmitIfFormValid( 'Country-Region' );
    if ( $( '#form-map' ).length ) { showCountryDataOnMap( val ); }
}
function showCountryDataOnMap ( val ) {
    form.focusParentAndShowChildLocs( 'int', val );
}
/** When the Location sub-form is exited, the Country/Region combo is reenabled. */
export function enableCountryRegionField () {
    el.enableCombobox( 'Country-Region' );
    $( '#loc-note' ).fadeTo( 400, 1 );
}
/* ========================== LOCATION ====================================== */
/* ---------------------- FILL COMBOBOX ------------------------------------- */
/**
 * When a country/region is selected, the location combobox is repopulated with its
 * child-locations and all habitat types. When cleared, the combobox is
 * repopulated with all locations.
 */
export function resetLocCombo ( loc ) {
    asyncComboOptionsReset( loc );
    if ( loc ) { return state.setFieldValue( 'top', 'Location', loc.id ); }
    handleClearedLoc();
}
function asyncComboOptionsReset( loc ) {
    const subSet = loc ? getLocComboIds( loc ) : false;             /*dbug-log*///console.log( '-- asyncComboOptionsReset subSet?[%O]', subSet );
    el.getRcrdOpts( 'location', subSet )
    .then( opts => el.replaceSelOpts( 'Location', opts ) );
}
function getLocComboIds ( loc ) {
    const tData = model.getEntityFormConfig( 'Location' ).misc.territories;
    const tNames = tData[ loc.displayName ];
    if ( !tNames ) { return loc.children; }
    return getChildAndTerritoryLocIds( loc, state.getRecords( 'location' ) );

    function getChildAndTerritoryLocIds ( loc, rcrds ) {
        const tIds = Object.keys( rcrds ).filter( i => ifTerritory( rcrds[ i ], tNames ) );
        return loc.children.concat( ...tIds.map( i => rcrds[ i ].children ) );
    }
    function ifTerritory ( loc, territories ) {
        return territories.indexOf( loc.displayName ) !== -1;
    }
}
/* ---------------------- LOCATION SELECTION -------------------------------- */
export function selectLoc ( id ) {
    components.exitFormElemAndClearState( 'sub' );
    el.setSelVal( 'Location', id );
    enableCountryRegionField();
    el.enableCombobox( 'Location' );
}
/**
 * When a location is selected, its country/region is selected in the top-form
 * combobox and the location record's data is added to the side-panel details. If
 * the location was cleared, the side-panel details are cleared and the default
 * "unspecified" location is added to the field's configuration.
 */
export function onLocSelection ( val ) {                             /*temp-log*///console.log( '       +--onLocSelection [%s]', val );
    if ( val === 'create' ) { return form.createEntity( 'location' ); }
    if ( ifNoLocSelected( val ) ) { return handleClearedLoc(); }
    if ( $( '#form-map' ).length ) { removeLocMap(); }
    const locRcrd = state.getRecords( 'location', val );
    if ( !locRcrd ) return; //error alerted to developer and editor
    selectParentCountryRegion( locRcrd );
    components.setSubEntityDetails( 'loc', locRcrd );
    focusPinAndEnableSubmitIfFormValid( 'Location' );
}
function removeLocMap () {
    $( '#form-map' ).fadeTo( 400, 0, () => $( '#form-map' ).remove() );
}
function selectParentCountryRegion ( locRcrd ) {
    const prntVal = locRcrd.parent ? locRcrd.parent : locRcrd.id;
    components.setSilentVal( 'top', 'Country-Region', prntVal );
}
/* ----------------------- CLEARED LOCATION --------------------------------- */
function ifNoLocSelected ( val ) {
    return val === 'new' || val === '' || isNotNumber( val );
}
/**
 * When the location is cleared, the side-panel details are cleared and the
 * default "unspecified" location is added to the field's configuration.
 */
function handleClearedLoc () {                                      /*dbug-log*///console.log( '   -- handleClearedLoc' );
    components.clearSidePanelDetails( 'loc' );
    const locField = state.getFieldState( 'top', 'Location' );
    ensureLocHasValue( el.getSelVal( 'Country-Region' ), locField );
    $( `#Location_pin` ).prop( 'checked', false );
    delete locField.review;
}
function ensureLocHasValue ( cntry, locField ) {                    /*dbug-log*///console.log( '   -- ensureLocHasValue cntry?[%s] locField[%O]', cntry, locField )
    if ( cntry ) return;
    setUnspecifiedLocation( locField );
}
/** Async: Sets the default "unspecified" location.  */
export function setUnspecifiedLocation ( locField ) {
    getData( 'topRegionNames' )
        .then( findAndSetUnspecifiedLocation );

    function findAndSetUnspecifiedLocation ( regions ) {           /*dbug-log*///console.log( '               --findAndSetUnspecifiedLocation regions[%O]', regions );
        const unspecifiedLoc = Object.keys( regions ).find( r => r === 'Unspecified' );
        locField.value = regions[ unspecifiedLoc ];
    }
}

/* =================== WAYS TO SELECT LOCATION NOTE ========================= */
/** Adds a message above the location fields in interaction forms. */
export function addLocationSelectionMethodsNote () {
    const cntnr = el.getElem( 'div', { id: 'loc-note', class: 'a-mrg g' } );
    const mapInfo = getMapInfoText();
    $( cntnr ).append( mapInfo );
    $( '#Country-Region_f' )[ 0 ].parentNode.before( cntnr );
}
function getMapInfoText () {
    const text = `<span>Select a location using the fields below or </span>`;
    const link = getInfoLinkTextToOpenMap();
    return [ text, link ];
}
function getInfoLinkTextToOpenMap () {
    const attr = { class: 'map-link', text: 'click here to use the map interface.' };
    const span = el.getElem( 'span', attr );
    $( span ).on( 'click', showInteractionFormMap );
    return span;
}
/** Open popup with the map interface for location selection. */
function showInteractionFormMap () {                                /*dbug-log*///console.log( 'showInteractionFormMap' )
    if ( $( '#form-map' ).length ) return;
    const pElem = $( '#Location_f' )[ 0 ].parentNode;
    form.addMapToLocForm( 'int', $( pElem ) );
    if ( el.getSelVal( 'Country-Region' ) ) return;
    el.focusCombobox( 'Country-Region', true );
}