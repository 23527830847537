/**
 * Modifies interaction-data for local storage:
 * - [entity]Names - an object with each entity's displayName(k) and id.
 * - subjectNames - Array of valid subject taxon-group ids.
 * * interaction - Adds the Taxon Group's ids to each record to filter by groups.
 *
 * Export
 *     modifyIntDataForLocalDb
 */
import { getValue, storeData } from '@localdata/util';
import * as _t from '@types';
import { getNameObj } from '../init-helpers';

type InteractionData = {
    interaction: _t.EntityRecords;
    validInteraction: _t.EntityRecords;
    tag: _t.EntityRecords;
};
export function modifyIntDataForLocalDb ( data: InteractionData ): void {  /*dbug-log*///console.log("modifyIntDataForLocalDb called. data = %O", data);
    storeData( 'tagNames', getNameObj( _t.objectKeys( data.tag ), data.tag ) );
    addGroupIdProps( data.interaction );
    addValidSubjectGroups( data.validInteraction );
    fixValidIntTagFormat( data.validInteraction );
}
/* =================== FIX TAG KEYS ========================================= */
/**
 * Due to a bug with the serializer (that seems to only affect this entity and
 * this property), tags must be restored as an object keyed by tag ID,
 */
function fixValidIntTagFormat ( validInts: _t.EntityRecords ): void {
    _t.objectValues( validInts ).forEach( fixTagIdKeys );
    storeData( 'validInteaction', validInts );
}
function fixTagIdKeys ( validInt: _t.EntityRecord ): void {
    const fixed: _t.EntityRecords = {};
    validInt.tags.forEach( ( t: _t.EntityRecord ) => fixed[ t.id ] = t );
    validInt.tags = fixed;
}
/* =================== GROUP ID ============================================= */
function addGroupIdProps ( ints: _t.EntityRecords ): void {
    const taxa = getValue( 'taxon' ) as _t.EntityRecords;
    _t.objectValues( ints ).forEach( addGroupIds );
    storeData( 'interaction', ints );                           /*dbug-log*///console.log('taxa[%O]', taxa)

    function addGroupIds ( int: _t.EntityRecord ): void {                 /*dbug-log*///console.log('addGroupIds intRcrd[%O]', int)
        int.objGroupId = getGroupId( taxa, int.object );
        int.subjGroupId = getGroupId( taxa, int.subject );
    }
}
function getGroupId ( taxa: _t.EntityRecords, id: number ): string {
    const txn = taxa[ id ];                                           /*dbug-log*///console.log('getGroupId txn[%O]', txn)
    return txn?.group.id.toString();
}
/* =================== SUBJECT GROUPS ======================================= */
function addValidSubjectGroups ( vInts: _t.EntityRecords ): void {
    storeData( 'subjectNames', getSubjectGroupNames( vInts ) );
}
function getSubjectGroupNames ( vInts: _t.EntityRecords ): _t.IdsByName {
    const names: _t.IdsByName = {};
    const groups = getValue( 'group' ) as _t.EntityRecords;
    const subjects = _t.objectValues( vInts ).map( v => v.subjectGroupRoot );
    _t.objectValues( groups ).forEach( addIfValidInteractionSubject ); /*dbug-log*///console.log('--getSubjectGroupNames data[%O]', names);
    return names;

    function addIfValidInteractionSubject ( group: _t.EntityRecord ): void {/*dbug-log*///console.log('   --addIfValidInteractionSubject group[%O]', group);
        const isValid = _t.objectKeys( group.roots ).find( ifValidSubject );
        if ( !isValid ) return;
        names[ group.displayName! ] = group.id;
    }
    function ifValidSubject ( id: string ): boolean {
        return subjects.indexOf( parseInt( id ) ) !== -1;
    }
}