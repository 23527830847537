/**
 * Prepares the layout model that will be used to build the form-layout UI.
 * TOC
 *     GET CONFIG
 *     BUILD MODEL
 *         ROW
 *         BLOCK
 *         FIELD
 */
import { addSpaceBetweenCamelCaseUnlessHyphen, cloneObj } from "@util";

export function prepareLayoutModel( formModel, mergeLayouts ) {
    const layouts = mergeLayouts || formModel.layouts;
    return buildLayoutModel( formModel, layouts );
}
/* ======================= BUILD MODEL ====================================== */
function buildLayoutModel( formModel, layouts ) {                   /*dbug-log*///console.log( "--buildLayoutModel config[%O] layouts[%O]", cloneObj( formModel ), cloneObj( layouts ) );
    return layouts[ formModel.display ].map( row => getRowModel( formModel.fields, row ) );
}
/* --------------------------- ROW ------------------------------------------ */
function getRowModel( fieldModels, row ) {                            /*dbug-log*///console.log( '   --getRowModel row[%O]', row );
    return row.map( g => getBlockModel( fieldModels, g ) );
}
/* ------------------------- BLOCK ------------------------------------------ */
/**
 * @param {object} FormState
 * @param {string | array | object} Single-field config or multi-field config.
 * @returns Completed field-block model.
 */
function getBlockModel( fieldModels, g ) {
    if ( typeof g === 'string' ) return getSingleFieldModel( fieldModels, g );
    return Array.isArray( g ) ? getHorzBlock( fieldModels, g ) : getVertBlock( fieldModels, g );
}
/** Horizontal row-group. */
function getHorzBlock( fieldModels, g ) {
    return {
        configs: g.map( f => getBlockModel( fieldModels, f ) ),
        dir: 'row'
    };
}
/** Vertical row-group. */
function getVertBlock( fieldModels, g ) {
    return {
        class: g.class,
        configs: g.fields.map( f => getBlockModel( fieldModels, f ) ),
        dir: 'col'
    };
}
/* ---------------------------- FIELD --------------------------------------- */
function getSingleFieldModel( fieldModels, f ) {
    return f === '' ? { emptyField: true } : getFieldLayoutConfig( fieldModels, f );
}
function getFieldLayoutConfig( fieldModels, name ) {
    const field = getFieldModel( fieldModels, name );
    return {
        class: field.class || field.group+'-field',
        group: field.group,    // Used to get field-state during layout UI init
        label: getFieldLabel( field ),
        name: field.name        // Used to get field-state during layout UI init
    };
}
function getFieldModel( fieldModels, name ) {
    return fieldModels[ getFieldStateKey( fieldModels, name ) ];
}
function getFieldStateKey( fieldModels, name ) {
    if ( name in fieldModels ) return name;
    return Object.keys( fieldModels ).find( k => fieldModels[ k ].label === name );
}
function getFieldLabel( field ) {
    if ( field.label === false ) return false;
    return field.label || addSpaceBetweenCamelCaseUnlessHyphen( field.name );
}