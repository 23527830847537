/**
 * Handles accessing the review state of a form and related predicates.
 *
 *
 */
/* UTILS */
import { isMultiField } from '@elems';
import { isAvailableToCurrentUser } from '@localdata';
import { isContributorUser } from '@util';
/* DATA-ENTRY */
import { getRecords } from '../core/records.ts';
import { getFormState } from './get-form-state.js';
/** Current user set during data-review init. */
let user;
/* =========================== STAGE ======================================== */
export function getReviewStage( fLvl, rcrd = false ) {              /*dbug-log*///console.log( '   -- getReviewStage  fLvl[%s] FM[%O]', fLvl, this );
    const rEntry = rcrd || getFormState( fLvl, 'review' );
    const stageName = rEntry?.form.stage.name;
    return !stageName ? 'Pending' : getStagePassiveForm( stageName );
}
function getStagePassiveForm( activeForm ) {
    const stages = Object.values( getRecords( 'reviewStages' ) );   /*dbug-log*///console.log( '   -- getStagePassiveForm  activeForm[%s] stages[%O]', activeForm, stages );
    const stage = stages.find( s => s.activeForm === activeForm );
    return stage.passiveForm;
}
/* ======================= PREDICATES ======================================= */
export function isEntryAction( rEntry, action ) {
    return rEntry.form.action === action;
}
/**
 * Contributors have access to their data during the review process. Entity data
 * are quarantined with temporary IDs. During manager review, these are removed.
 * @param  {object}  rEntry ReviewEntry entity
 * @return {boolean}       True: User is the contributor and the data is quarantined
 */
export function isValueQuarantined( rEntry ) {                      /*dbug-log*///console.log( '-- isValueQuarantined isContribtor?[%s] isQuarantined?[%s]', isContributorUser(), isQuarantined( rEntry ) );
    return isContributorUser() && isQuarantined( rEntry );
}
/** True if the entry is quarantined, pending approval/rejection. */
function isQuarantined( rEntry ) {                                     /*dbug-log*///console.log( '-- isQuarantined rEntry[%O]', rEntry );
    return rEntry && !isApprovedOrRejected( rEntry );
}
/** True if the field has an entity awaiting final review. */
export function fieldHasReviewEntry( fConfig ) {                    /*dbug-log*///console.log( '-- fieldHasReviewEntry fConfig[%O] rEntry?[%O]', fConfig, fConfig.review ); 
    if ( !isMultiField( fConfig ) ) return hasDataToReview( fConfig.review );
    return fConfig.review && Object.values( fConfig.review ).some( hasDataToReview );
}
function hasDataToReview( rEntry ) {     
    return rEntry && !rEntry.completed;  
}
export function isReadyToReview( rEntry, userData ) {               /*dbug-log*///console.log( '-- isReadyToReview rEntry[%O] userData?[%O] user?[%O]', rEntry, userData, user );
    if ( userData ) user = userData;
    return rEntry ? isAvailableToCurrentUser( rEntry, user ) : false;
}
/**
 * True if data has been approved or rejected by a data-manager.
 * @param  {object}  rEntry  ReviewEntry entity
 * @param  {string}  fLvl   Form group to check the ReviewEntry
 * @return {boolean}
 */
export function isApprovedOrRejected( rEntry, fLvl ) {
    if ( fLvl ) rEntry = getFormState( fLvl, 'review' );
    return rEntry && ( isStage( rEntry, 'Approve' ) || isStage( rEntry, 'Reject' ) );
}
function isStage( rEntry, stageName ) {
    const stages = [ rEntry.stage.name, rEntry.form.stage.name ]; //Handles 'Locked' data
    return stages.some( s => stageName.includes( s ) );
}
/** ------------------ IS QUARNTINE NOT POSSIBLE ---------------------------- */
/**
 * All entity fields must be processed for potential quarantined data, except for
 * certain fields which can not possibly refer to quarantined data.
 */
export function isPossiblyQuarantined( fConfig ) {                   /*dbug-log*///console.log( '-- isPossiblyQuarantined fConfig[%O]', fConfig );
    if ( !fConfig.value || !fConfig.entity ) return false;
    return ![ 'Type', 'Rank' ].find( s => fConfig.entity.includes( s ) );
}