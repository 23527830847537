/** Handles filter init and clearing that isn't automatically handled on table rebuild.
 */
import * as filters from '../filters';
import { initFilterSetsFeature } from '../set';

export function initDefaultFilters() {
    filters.initDateFilterUi();
    filters.initSpecialFilterCombobox();
    filters.initGroupFilterCombobox();
    initFilterSetsFeature();
}
export function onTableLoadCompleteClearFilterUi() {
    filters.resetGroupFilter();
    filters.resetSpecialFilter();
    $( '#focus-filters input[type="text"]' ).val( '' );

}