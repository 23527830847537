/**
 * Adds child locations to the map, those with GPS data as markers and those
 * without as a single marker cluster.
 *
 * EXPORT
 *     addChildLocsToMap
 *
 * TOC
 *     GET CHILD DATA
 *     ADD LOCS WITH GPS DATA
 *         ADD MARKER TO MAP
 *     ADD NO GPS MARKER
 */
import { addMarkersToMap, getMapRecords, getMapState } from '@explore/map/manager.js';
import { buildLocMarker, getCenterCoords, getTerrId } from '@explore/map/util';
import { addCountToLegend } from '@explore/map/actions';

let type;
let noGpsLocs = [];

export function addChildLocsToMap ( t, pLoc, coords ) {             /*dbug-log*///console.log( '--showChildLocs type[%s] pLoc[%O] coords[%O]', t, pLoc, coords );
    type = t;
    const markers = buildChildMarkers( pLoc, coords );
    addMarkersToMap( markers );
}
function buildChildMarkers( pLoc, coords ) {
    const locs = getChildLocData( pLoc );
    const markers = addLocsWithGpsDataToMap( pLoc, locs );
    addCountToLegend( locs.length, noGpsLocs.length, pLoc );
    if ( !coords || !noGpsLocs.length ) return markers;
    markers.push( getNoGpsMarker( coords, noGpsLocs ) );
    return markers;
}
/* ====================== GET CHILD DATA ==================================== */
/** Return all sub-locs, except country-habitat locations with no interactions.*/
function getChildLocData ( pLoc ) {                                 /*dbug-log*///console.log( '--getChildLocData pLoc[%O]', pLoc );
    const childLocs = getChildLocRcrds( pLoc );                     /*dbug-log*///console.log( '   -- childLocs[%O]', childLocs );
    const tLocs = getCountryTerritories( pLoc.displayName );
    return tLocs ? childLocs.concat( tLocs ) : childLocs;
}
function getChildLocRcrds ( pLoc ) {
    const rcrds = getMapRecords( 'location' );
    const cLocs = pLoc.children.map( ( id ) => getLocsWithInts( rcrds[ id ] ) );
    return cLocs.filter( l => l );
}
function getLocsWithInts ( loc ) {
    const isHab = loc.locationType.displayName === 'Habitat';
    return isHab || !loc.totalInts ? null : loc;
}
function getCountryTerritories ( name ) {                           /*dbug-log*///console.log( '--getCountryTerritories name[%s]', name );
    const formConfig = getMapState( 'formConfig' );
    const tData = formConfig?.territories;                          /*dbug-log*///console.log( '       -- territories[%O]', tData[ name ] );
    if ( !tData?.[ name ] ) return;
    return tData[ name ].map( getTerrId );
}
/* ====================== ADD LOCS WITH GPS DATA ============================ */
function addLocsWithGpsDataToMap ( pLoc, locs ) {                   /*dbug-log*///console.log( '--addLocsWithGpsDataToMap locs[%O]', locs );
    return locs.map( processChildLoc ).filter( l => l );

    function processChildLoc( loc ) {
        if ( loc.id === pLoc.id ) return; // Skip location being edited
        const latlng = getGpsData( pLoc, loc );
        if ( !latlng ) noGpsLocs.push( loc );
        return latlng && getLocMarker( loc, latlng );
    }
}
function getGpsData ( pLoc, loc ) {
    if ( pLoc.geoJsonId == loc.geoJsonId ) return false;
    return getCenterCoords( loc, loc.geoJsonId );
}
/* ----------------------- ADD MARKER TO MAP -------------------------------- */
function getLocMarker( loc, latlng ) {
    const type = getMarkerType( loc );
    return buildLocMarker( latlng, loc.totalInts, loc, type );
}
function getMarkerType ( loc ) {
    const edit = type === 'edit' ? 'edit' : '';
    const cntry = loc.locationType.displayName === 'Country' ? '-c' : '';
    return `${ edit }form${ cntry }`;
}
/* ====================== ADD NO GPS MARKER ================================= */
function getNoGpsMarker ( coords, cnt ) {
    return buildLocMarker( coords, cnt, noGpsLocs, 'form-noGps' );
}