/**
 * Toggles the form submit and cancel button's styling and availablility.
 * Note, submit will only enable if form html-validation passes.
 *
 * Export
 *     toggleCancelBttn
 *     toggleSubmitBttn
 */
/* ======================== TOGGLE CANCEL =================================== */
export function toggleCancelBttn( fLvl, enable = true ) {           /*dbug-log*///console.log( '-- toggleCancelBttn [%s] enable?[%s]', fLvl, enable );
    if ( enable && !isFormValid( fLvl ) ) return;
    const cursor = enable ? 'pointer' : 'initial';
    const opac = enable ? 1 : .6;
    $( `#${ fLvl }-cancel` ).attr( 'disabled', !enable ).css( {opacity: opac, cursor: cursor} );
}
/* ======================== TOGGLE SUBMIT =================================== */
export function toggleSubmitBttn( fLvl, enable = true ) {           /*dbug-log*///console.log( '-- toggleSubmitBttn [%s] enable?[%s]', fLvl, enable );
    if ( enable && ifFormOpen( fLvl ) && !isFormValid( fLvl ) ) return;
    const cursor = enable ? 'pointer' : 'initial';
    const opac = enable ? 1 : .6;
    $( `#${ fLvl }-submit` ).attr( 'disabled', !enable ).css( {opacity: opac, cursor: cursor} );
}
function ifFormOpen( fLvl ) {
    return !!$( `#${ fLvl }-submit` ).length; //Select-form is closed early in review forms
}
/* ======================== VALIDATE FORM =================================== */
/** Checks HTML5 form-validity. */
function isFormValid( fLvl ) {
    if ( ifSelectForm( fLvl ) ) { return true; }
    const valid = $( `#${ fLvl }-form` )[0].checkValidity();
    return valid || $( `#${ fLvl }-form` )[0].reportValidity();
}
function ifSelectForm( fLvl ) {
    return $( `#${ fLvl }-hdr` )[0].innerText.includes( 'Select' );
}