import { lcfirst } from "@util";
import { FormConfig } from './types';
/**
 * Return the base form-configuration for the given entity.
 */
export function getEntityFormConfig( entity: string ): FormConfig { /*dbug-log*///console.log( '   +--getEntityFormConfig [%s]', entity );
    const config = getConfig( entity );
    return config;
}
function getConfig( entity: string ) {
    const path = getConfigPath( entity );
    /* eslint-disable @typescript-eslint/no-var-requires */
    return require( `./entity/${ path }-config.ts` ).default( entity );
}
function getConfigPath( entity: string ): string {
    const entityMap = {
        Editor: 'author',
        Object: 'group',
        Parent: 'group',
        Subject: 'group',
    } as const;
    const trans = entity in entityMap ? entityMap[ entity as keyof typeof entityMap ] : null;
    return  trans || lcfirst( entity );
}
