/**
 * Manages the source fields in the interaction form, Publication and Citation.
 *
 * Export
 *     clearCitationCombo
 *     fillCitationCombo
 *     onCitSelection
 *     onPubSelection
 *
 * TOC
 *     PUBLICATION
 *     CITATION
 *         FILL COMBOBOX
 *         SELECT CITATION
 */
import { clearSidePanelDetails, setSubEntityDetails } from '@dataentry/components';
import { createEntityType } from '@dataentry/form';
import * as state from '@dataentry/state';
import * as el from '@elems';
import { isNotNumber } from '@util';
import { focusPinAndEnableSubmitIfFormValid } from '../util/focus-pin.js';
/* ======================= PUBLICATION ====================================== */
/**
 * When an existing publication is selected, the citation field is filled with
 * all current citations for the publication. When a publication is created,
 * the citation form is automatically opened.
 */
export function onPubSelection( val ) {                             /*temp-log*///console.log( '       +--onPubSelection[%s]', val );
    if ( val === 'create' ) { return createEntityType( 'Publication', 'source' ); }
    if ( val === '' || isNotNumber( val ) ) return clearCitationCombo();
    fillCitationCombo( val );
    setSubEntityDetails( 'src', 'pub' );
    const pub = state.getRecords( 'source', val );             /*dbug-log*///console.log( '       +--newPubCreated? (if no children...)[%O]', pub );
    if ( newPubCreated( pub ) ) return createEntityType( 'Citation', 'source' );
    focusPinAndEnableSubmitIfFormValid( 'Publication' );
}
function newPubCreated( pub ) {
    const action = state.getFormState( 'top', 'action' );
    if ( action === 'review' ) return;  // Loaded via review module
    return pub ? !pub.children.length : null; //If no pub found, the issue was alerted to developer and editor
}
/** Note: clearAllSourceFields when Publication is cleared. */
export function clearCitationCombo( clearAllSourceFields = true ) {
    el.enableCombobox( 'CitationTitle', false );
    clearSidePanelDetails( 'cit' );
    $( `#CitationTitle_pin` ).prop( 'checked', false );
    if ( !clearAllSourceFields ) return;
    el.resetCombobox( 'CitationTitle' );
    $( `#Publication_pin` ).prop( 'checked', false );
    // state.setFieldReviewEntry( 'top', 'Publication', null );
}
/* ======================== CITATION ======================================== */
/* ---------------------- FILL COMBOBOX ------------------------------------- */
/** Fills the citation combobox with all citations for the selected publication. */
export function fillCitationCombo( pubId ) {                        /*dbug-log*///console.log( '-- fillCitationCombo pubId[%s]', pubId );
    return asyncComboOptionsReset( pubId )
        .then( () => el.enableCombobox( 'CitationTitle' ) );
}
function asyncComboOptionsReset( pubId ) {
    const pubRcrd = state.getRecords( 'source', pubId );             /*dbug-log*///console.log( '   -- asyncComboOptionsReset pubRcrd[%O]', pubRcrd );
    return el.getDetailRecordOpts( 'source', 'Citation', pubRcrd.children )
        .then( ids => el.replaceSelOpts( 'CitationTitle', ids ) );
}
/* ---------------------- SELECT CITATION ----------------------------------- */
/**
 * When a Citation is selected, both 'top' location fields are initialized
 * and the publication combobox is reenabled.
 */
export function onCitSelection( val ) {                             /*temp-log*///console.log( '       +--onCitSelection [%s]', val );
    if ( val === 'create' ) return createEntityType( 'Citation', 'source' );
    if ( val === '' || isNotNumber( val ) ) { return clearCitationCombo( false ); }
    setSubEntityDetails( 'src', 'cit' );
    el.enableCombobox( 'Publication' );
    focusPinAndEnableSubmitIfFormValid( 'CitationTitle' );
}
