/**
 * The Taxon Group and Group-Root combobox fields.
 *
 * Export
 *     onGroupSelection
 *     onRootSelection
 *
 * TOC
 *     BUILD (SUB)GROUP FIELDS
 *         CLEAR PREVIOUS GROUP COMBOS
 *         BUILD GROUP FIELDS
 */
import { getGroupRootOpts, getTaxonGroupFieldOpts, replaceSelOpts, setSelVal } from '@elems/combo';
import { cloneObj, isNotNumber } from '@util';
import { components, state } from '@dataentry';
import { finishRankComboInit } from '../init/build-form.js';
/**
 * Removes any previous group comboboxes. Shows a combobox for each rank present
 * in the selected Taxon group filled with the taxa at that rank and Group-Root
 * combobox if the group has mutliple root-taxa.
 */
export function onGroupSelection ( val ) {                          /*temp-log*///console.log( "               +--onGroupSelection. [%s]", val );
    if ( val === '' || isNotNumber( val ) ) return;
    updateFieldValues( { Group: val, 'Group-Root': null } );
    return rebuildTaxonSelectForm();
}
function updateFieldValues ( vals ) {
    for ( let f in vals ) updateGroupFieldState( f, vals[ f ] );
}
function updateGroupFieldState( f, val ) {
    const field = state.getFieldState( 'sub', f );
    field.value = val;
    field.misc = null;
    state.replaceFieldState( 'sub', field );
}
/**
 * Reset will trigger selecting previously selected values. Skip reset allows for
 * the group and Group-Root to be set without triggering further selection.
 * @param {*} field
 * @param {*} skipReset
 * @returns
 */
export function fillGroupAndRankCombos( field, skipReset = false ) {/*dbug-log*///console.log( "--fillGroupAndRankCombos field[%s] skipReset?[%s]", field, skipReset );
    return fillGroupCombo( field )
        .then( () => fillGroupRootAndRankCombos( field, skipReset ) );
}
function fillGroupCombo( field ) {
    return getTaxonGroupFieldOpts( field )
        .then( opts => fillAndSetCombo( 'Group', opts ) );
}
function fillAndSetCombo( fieldName, opts ) {             /*dbug-log*///console.log( "   --fillAndSetCombo fieldName[%s] opts[%O]", fieldName, opts );
    const id = state.getFieldValue( 'sub', fieldName );
    replaceSelOpts( fieldName, opts );
    setSelVal( fieldName, id, 'silent' );
}
/* ------------------ SELECT Group-Root -------------------------------------- */
export function onRootSelection ( val ) {                       /*temp-log*///console.log( "               +--onRootSelection. [%s]", val )
    updateFieldValues( { 'Group-Root': val } );
    return rebuildTaxonSelectForm();
}
/* ================== BUILD (SUB)GROUP FIELDS =============================== */
function rebuildTaxonSelectForm() {
    const field = $( '#select-group' ).data( 'field' );
    state.updateTaxonGroupState( 'sub' );
    return components.onFormConfigChanged( 'sub', field )
        .then( () => fillGroupAndRankCombos( field, true ) )
        .then( () => ifParentSelectRemoveSpecies( field ) );
}
function fillGroupRootAndRankCombos( field, skipReset ) {
    return fillGroupRootCombo()
        .then( () => finishRankComboInit( field, skipReset ) );
}
function fillGroupRootCombo() {
    const root = state.getFieldState( 'sub', 'Group-Root' );/*dbug-log*///console.log( "  -- fillGroupRootCombo root[%O]", cloneObj( root ) );
    if ( !root.shown ) return Promise.resolve();
    const groupName = root.misc.taxon.group.displayName;
    return getGroupRootOpts( groupName )
        .then( opts => fillAndSetCombo( 'Group-Root', opts ) );
}
export function ifParentSelectRemoveSpecies ( field ) {              /*dbug-log*///console.log( "--ifParentSelectRemoveSpecies field[%s]", field );
    if ( field === 'Parent' ) $( '#Species_f' ).hide();
}