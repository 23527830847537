/**
 * Handles checking for and adding ReviewEntry data to field state.
 *
 * TOC
 *     ON FIELD SELECTION
 *         MULTI-FIELD
 *         SINGLE FIELD
 *     SET IF FIELD HAS REVIEW-ENTRY
 *         MULTI-FIELD
 *         SINGLE FIELD
 *         SHARED
 *     UTIL
 */
/* UTILS */
import { getData } from '@localdata';
import { cloneObj, getUserRole, isObj } from '@util';
/* DATA-ENTRY */
import { getRecords } from '../core';
import { fieldHasReviewEntry, isPossiblyQuarantined, isReviewForm } from '../get';
import { setFieldState } from './set-field-state.js';
/* ========================= ON FIELD SELECTION ============================= */
/**
 * If the value selected is a ReviewEntity, the 'review' property is added to the
 * field with the ReviewEntry record. This triggers special handling elsewhere.
 */
export function ifReviewEntryAddToField ( field ) {
    if ( isReviewNotPossible( field ) ) return;                     /*dbug-log*///console.log( '    --ifReviewEntryAddToField field[%O]', cloneObj( field ) );
    return field.count
        ? handleMultiEntryField( field )
        : handleSingleEntryField( field );
}
/* ---------------------- MULTI-FIELD --------------------------------------- */
function handleMultiEntryField( field ) {
    return Object.keys( field.value ).forEach( handleMultiFieldReviewEntry );

    function handleMultiFieldReviewEntry ( ord ) {
        const id = field.value[ ord ];
        if ( !id ) return; // Quarantined-data during manager review
        const rcrd = getRecords( 'source', id );                    /*dbug-log*///console.log( '       --handleMultiFieldReviewEntry field[%s %O] ord[%s] id[%s] rcrd[%O]', field.name, cloneObj( field ), ord, id, rcrd );
        setFieldReviewEntry( field, rcrd, ord );
    }
}
/* ---------------------- SINGLE FIELD -------------------------------------- */
function handleSingleEntryField( field ) {
    const rcrd = getFieldEntityRecord( field );                     /*dbug-log*///console.log( '       -- Selected entity record [%O]', rcrd )
    setFieldReviewEntry( field, rcrd );
}
function getFieldEntityRecord( field ) {
    const id = isObj( field.value ) ? field.value.value : field.value;
    return getRecords( field.entity, id );
}
/* ==================== SET IF FIELD HAS REVIEW-ENTRY ======================= */
/** If a field has ReviewEntry data, the ReviewEntry record is added to the field. */
async function setFieldReviewEntry ( field, rcrd, ord ) {
    if ( isReviewNotPossible( field ) ) return;                     /*dbug-log*///console.log( '    --setFieldReviewEntry fieldKey[%s] rcrd[%O] ord?[%O] field[%O]', field.name, rcrd, ord, field );
    if ( !hasReviewEntry( field, rcrd ) ) return clearReviewEntry( field, ord );
    await handleSetReviewEntry( field, rcrd, ord );
}
function clearReviewEntry( field, ord ) {
    if ( !field.review ) return;
    return ord ? clearMultiReviewEntry( field, ord ) : setReviewEntry( field, null );
}
function handleSetReviewEntry( field, rcrd, ord ) {                 /*dbug-log*///console.log( '    --handleSetReviewEntry field[%O] rcrd[%O] ord?[%s]', cloneObj( field ), rcrd, ord );
    if ( !rcrd?.review ) return; //Contributor reviewing final result
    return ord
        ? setMultFieldReviewEntry( field, rcrd, ord )
        : handleSettingReviewEntry( field, rcrd );
}
/* ----------------------- MULTI-FIELD -------------------------------------- */
async function setMultFieldReviewEntry ( field, rcrd, ord ) {       /*dbug-log*///console.log( '           --setMultFieldReviewEntry field[%O] rcrd[%O] ord[%s]', cloneObj( field ), rcrd, ord );
    if ( !field.review ) field.review = {};
    await getAndSetReviewEntry( rcrd, field, ord );
}
function clearMultiReviewEntry ( field, ord ) {                     /*dbug-log*///console.log( '    --clearMultiReviewEntry field[%s][%O] ord?[%s]', field.name, field, ord );
    delete field.review[ ord ];
    const reviewVal = Object.keys( field.review ).length ? field.review : null;
    setReviewEntry( field, reviewVal );
}
/* ------------------------ SINGLE FIELD ------------------------------------ */
async function handleSettingReviewEntry ( field, rcrd ) {
    await getAndSetReviewEntry( rcrd, field );
}
/* ------------------------- SHARED ----------------------------------------- */
function getAndSetReviewEntry ( rcrd, field, ord ) {                /*dbug-log*///console.log( '           -- getAndSetReviewEntry field[%O] rcrd[%O] ord[%s]', cloneObj( field ), cloneObj( rcrd ), ord );
    if ( !isReviewForm(field.group) ) return asyncSetReviewEntry( field, rcrd, ord );
    addReviewEntry( field, getRecords( 'reviewEntry', rcrd.review.id ), ord );
}
/** Handles initial contributor-forms without ReviewEntry records in memory. */
async function asyncSetReviewEntry ( field, rcrd, ord ) {           /*dbug-log*///console.log( '           -- asyncSetReviewEntry field[%O] rcrd[%O] ord[%s]', cloneObj( field ), rcrd, ord );
    const ReviewEntries = await getData( 'reviewEntry' );
    const rEntry = ReviewEntries[ rcrd.review.id ];
    addReviewEntry( field, rEntry, ord );
}
/** Sets field ReviewEntry within standard form-processes. */
function addReviewEntry ( field, rEntry, ord ) {                    /*dbug-log*///console.log( '           -- addReviewEntry field[%O] rEntry[%O] ord[%s]', cloneObj( field ), rEntry, ord );
    const val = ord ? getMultiEntryVal( field, rEntry, ord ) : rEntry;
    setReviewEntry( field, val );
}
function getMultiEntryVal( field, rEntry, ord ) {
    if ( !field.review ) field.review = {};
    field.review[ ord ] = rEntry;
    return field.review;
}
/* ========================= UTIL =========================================== */
function isReviewNotPossible( field ) {
    if ( !field.entity || !field.value ) return true;
    return getUserRole() !== 'contributor'
        && !isReviewForm( field.group )
        && !isPossiblyQuarantined( field );
}
/** True if field or the selected record have a ReviewEntry that is not completed. */
function hasReviewEntry ( field, rcrd, ord ) {                      /*dbug-log*///console.log( '           --hasReviewEntry field[%s %O] rcrd[%O] ord?[%s]', field.name, cloneObj( field ), rcrd, ord );
    return ( rcrd && rcrd.review ) || fieldHasReviewEntry( field );
}
function setReviewEntry( field, review ) {
    setFieldState( field.group, field.name, { review } );
}