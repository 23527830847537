/**
 * In edit-forms for Data Managers the entity record's forensic data are displayed.
 *
 * Export
 *     ifManagerEditingGetForensic
 *
 * TOC
 *     INIT FORENSIC-ELEMS
 *         HEADER & FOOTER
 *         FORENSIC GROUPS
 *     FORENSIC DATA
 *         DISPLAY
 */
import { formInitAlert } from '@dataentry/alerts/form-alerts.js';
import { getElem } from '@elems';
import { parseData } from '@localdata';
import { alertIssue, isManagerUser, sendAjaxQuery, ucfirst } from '@util';

export default function ifManagerEditingGetForensic( fState ) {       /*dbug-log*///console.log("   -- ifManagerEditingGetForensic fState[%O]", fState);
    return isManagerEditing( fState.action ) ? getForensicElems( fState ) : [];
}
function isManagerEditing( action ) {
    return action === 'edit' && isManagerUser();
}
/* ===================== INIT FORENSIC-ELEMS ================================ */
function getForensicElems( fState ) {                                 /*dbug-log*///console.log("       -- getForensicElems fState[%O]", fState);
    const hdr = getForensicDataHeader();
    const forensics = initRecordForensics( fState );
    const ftr = getEntityIdFooter( fState.editing );
    return [ hdr, ...forensics, ftr ];
}
/* ----------------------- HEADER & FOOTER ---------------------------------- */
function getForensicDataHeader() {
    const attr = { class: 'c', text: 'Record Forensics' };
    return getElem( 'h5', attr );
}
function getEntityIdFooter( eIds ) {
    const txt = eIds ? `Id: ${ eIds.core }` : '';
    const attr = { class: 'g', id: 'ent-id', text: txt }
    return getElem( 'p', attr );
}
/* ----------------------- FORENSIC GROUPS ----------------------------------- */
/** Inits the elements that will display the record's forensic data. */
function initRecordForensics( fState ) {                              /*dbug-log*///console.log("       -- initDataCreateForensics fState[%O]", fState);
    ajaxAndDisplayEntityForensics( fState );
    const created = buildForensicContainer( 'created' );
    const updated = buildForensicContainer( 'updated' );
    const reviewed = initReviewedForensicElems();
    return [ created, updated, reviewed ];
}
function buildForensicContainer( type ) {
    const attr = { class: 'input-border flex-col', id: `${ type }-forensics` };
    return getElem( 'div', attr );
}
function initReviewedForensicElems() {
    const cntnr = buildForensicContainer( 'reviewed' );
    $( cntnr ).append( buildForensicElem( 'reviewed', '----' ) );
    return cntnr;
}
/* ======================== FORENSIC DATA =================================== */
function ajaxAndDisplayEntityForensics( fState ) {
    const data = {
        classname: fState.core ? fState.core : fState.name,
        id: fState.editing.core
    };
    sendAjaxQuery( data, 'crud/forensics', forensicSuccess, forensicError.bind( null, data ) );
}
function forensicSuccess( results ) {                                 /*dbug-log*///console.log('       --[R] forensicSuccess results[%O]', results);
    const data = parseData( results );
    for ( let label in data ) displayForensicData( label, data[ label ] );
}
function forensicError( data, results ) {
    formInitAlert( null, 'forensicError', 'top' );
    data.results = parseData( results );                     /*dbug-log*///console.log('       --[R] forensicError errData[%O]', data);
    return alertIssue( 'forensicError', data );
}
/* ---------------------------- DISPLAY ------------------------------------- */
function displayForensicData( label, data ) {
    if ( label === 'reviewed' ) { return updateReviewedData( data ); }
    const $cntnr = $( `#${ getForensicType( label ) }-forensics` );
    $cntnr.append( buildForensicElem( label, data ) );
}
function updateReviewedData( data ) {
    const $reviewed = $( '#forensic-reviewed' );
    $reviewed.empty().append( buildForensicElem( 'reviewed', data ) );
}
function getForensicType( label ) {
    return label.indexOf( 'created' ) === -1 ? 'updated' : 'created';
}
function buildForensicElem( label, data, classes = 'qrtr-em-mrg' ) {
    const id = `forensic-${ label }`
    const text = `${ ucfirst( label ) }: ${ data }`;
    const attr = { class: classes, id: id, text: text };
    return getElem( 'p', attr );
}