/**
 * Adds an element to the end of the field container to handle an action, either
 *  - a check box that allows the field data to be persisted through form-submit, or
 *  - an icon that inits, opens, and saves notes relevant for a data-review process
 */
import { isManagerUser } from '@util';
import { state } from '@dataentry';
import { initFieldNotesElem } from './field-notes-elem.js';
import initPersistFieldPin from './field-persist-elem.js';
/* ============================ BUILD ======================================= */
export function addFieldUtilElem( f, fieldElem )  {                 /*dbug-log*///console.log( '   -- addFieldUtilElem f[%O] fieldElem[%O]', f, fieldElem );
    const fState = state.getFormState( f.group );
    if ( !hasUtilElem( fState ) ) return;
    const el = getFieldUtilElem( fState.action === 'review', f );
    $( getFieldInputCntnr( fieldElem ) ).append( el );
}
/**
 * Possible util-elems:
 * > Interaction Create: Field checkbox to persist value through the form reset.
 * > DataManager Review: Icon to toggle the notes input for the field.
 */
function hasUtilElem( fState ) {
    const isReviewForm = fState.action === 'review';
    return isReviewForm ? isManagerUser() : fState.pinnable;
}
function getFieldUtilElem( isReviewForm, f ) {
    return  isReviewForm ? initFieldNotesElem( f ) : initPersistFieldPin( f.name );
}
function getFieldInputCntnr( fieldElem ) {
    const el = fieldElem.lastChild
    return $( el ).hasClass( 'cntnr' ) ? el.firstChild.lastChild : el;
}