/**
 * Functions that remove data from local-storage.
 *
 * Export
 *     rmvContrib
 *     rmvFromNameProp
 *     rmvFromParent
 *     rmvIntAndAdjustTotalCnts
 *     rmvIntFromEntity
 *     rmvIntFromTaxon
 */
import { lcfirst } from '@util';
import * as _t from '@types';
import * as util from '@localdata/util';
import { EditObj, EditedIds } from '@localdata/sync/data-entry';
/* ======================== HELPERS ========================================= */
function getDataEdits ( edits: EditObj, prop: string, nullOk: true, type?: 'old' | 'new' ): number | false;
function getDataEdits ( edits: EditObj, prop: string, nullOk?: false, type?: 'old' | 'new' ): number;
function getDataEdits ( edits: EditObj, prop: string, nullOk?: boolean, type: 'old' | 'new' = 'old' ): number | false {
    if ( !edits[ prop ] ) console.error( 'Edited data missing for [%s]', prop );
    if ( !edits[ prop ]![ type ] && !nullOk ) console.error( 'Previous entity ID missing for prop[%O]', edits[ prop ] );
    return edits[ prop ]![ type ] ?? false;
}
/** Removes the id from the ary. */
function rmvIdFromAry ( ary: number[], id: number ): void {
    ary.splice( ary.indexOf( id ), 1 );
}
/* ======================== REMOVERS ======================================== */
/* ------------------------- PARENT ----------------------------------------- */
/** Removes a record's id from the previous parent's 'children' array. */
export function rmvFromParent (
    prop: 'ParentLocation' | 'ParentSource' | 'ParentTaxon',
    rcrd: _t.EntityRecord,
    entity: 'location' | 'source' | 'taxon',
    edits: EditObj
): void {
    const oldId = getDataEdits( edits, prop, true );
    if ( !oldId ) return;
    const entityClass = lcfirst( entity );
    const [ rcrds, parent ] = util.getEntityData( entityClass, oldId );
    rmvIdFromAry( parent.children, rcrd.id );
    util.storeData( entityClass, rcrds );
}
/* -------------------------- INTERACTION ----------------------------------- */
/** Removes the Interaction from the stored entity's collection. */
export function rmvIntFromEntity (
    prop: 'Source',
    rcrd: _t.EntityRecord,
    _1: 'interaction',
    edits: EditObj
): void {
    const oldId = getDataEdits( edits, prop );
    const [ rcrds, source ] = util.getEntityData( 'source', oldId );
    rmvIdFromAry( source.interactions, rcrd.id );
    util.storeData( 'source', rcrds );
}
/** Removes the Interaction and updates parent location total counts.  */
export function rmvIntAndAdjustTotalCnts (
    prop: 'Location',
    rcrd: _t.EntityRecord,
    _1: string,
    edits: EditObj
): void {
    const [ rcrds, oldLoc ] = util.getEntityData( 'location', getDataEdits( edits, prop ) );
    const newLoc = util.getEntity( rcrds, getDataEdits( edits, prop, false, 'new' ), 'location' );
    rmvIdFromAry( oldLoc.interactions, rcrd.id );
    adjustLocCnts( oldLoc, newLoc, rcrds );
    util.storeData( 'location', rcrds );
}
function adjustLocCnts (
    oldLoc: _t.EntityRecord,
    newLoc: _t.EntityRecord,
    rcrds: _t.EntityRecords
): void {
    adjustLocAndParentCnts( oldLoc, false );
    adjustLocAndParentCnts( newLoc, true );

    function adjustLocAndParentCnts ( loc: _t.EntityRecord, addTo: boolean ): void {
        addTo ? ++loc.totalInts : --loc.totalInts;
        const parent = loc.parent ? util.getEntity( rcrds, loc.parent, 'location' ) : null;
        if ( parent ) adjustLocAndParentCnts( parent, addTo );
    }
}
/** Removes the Interaction from the taxon's subject/objectRole collection. */
export function rmvIntFromTaxon (
    prop: 'Object' | 'Subject',
    rcrd: _t.EntityRecord,
    _1: 'interaction',
    edits: EditObj
): void {
    const [ rcrds, taxon ] = util.getEntityData( 'taxon', getDataEdits( edits, prop ) );
    rmvIdFromAry( taxon[ `${ lcfirst( prop ) }Roles` ], rcrd.id );
    util.storeData( 'taxon', rcrds );
}
/* ---------------------- SOURCE CONTRIBUTOR -------------------------------- */
export function rmvContrib (
    _1: 'Contributor',
    rcrd: _t.EntityRecord,
    _2: 'source',
    edits: EditObj
): void {
    const rcrds = util.getEntities( 'source' );
    const contributorEdits = edits?.Contributor as unknown as EditObj[];
    if ( !contributorEdits ) return console.error( 'Contributor edit data not found.' );
    processContributorEdits( contributorEdits, rcrd.id, rcrds );
    util.storeData( 'source', rcrds );
}
function processContributorEdits (
    contributorEdits: EditObj[],
    contribId: number,
    rcrds: _t.EntityRecords
): void {
    contributorEdits.forEach( edit => {
        const oldId = getDataEdits( edit, 'authId', true );
        if ( !oldId ) return;
        const oldAuthor = util.getEntity( rcrds, oldId, 'source' );
        rmvIdFromAry( oldAuthor.contributions, contribId );
    } );
}
/* ---------------------- TAXON NAMES --------------------------------------- */
/** Note: Edits to taxon-groups with one root call this for GroupRoot redundantly. */
export function rmvFromNameProp (
    _1: string,
    rcrd: _t.EntityRecord,
    _2: 'taxon',
    edits: EditObj
): void {
    const taxonName = getTaxonName( edits, rcrd );
    const nameProp = getNameProp( edits, rcrd );
    const nameObj = util.getValue( nameProp ) as _t.IdsByName;
    if ( !nameObj ) return console.error( 'Name object not found' );
    delete nameObj[ taxonName ];
    util.storeData( nameProp, nameObj );
}
function getTaxonName ( edits: EditObj, rcrd: _t.EntityRecord ): string {
    return edits.Name ? edits.Name.old : rcrd.name;
}
function getNameProp ( edits: EditObj, rcrd: _t.EntityRecord ): string {
    const group = getGroup( edits.Group, rcrd );
    const root = getGroupRoot( edits.GroupRoot, rcrd );
    const rank = getRank( edits.Rank, rcrd );
    return group + root + rank + 'Names';
}
function getGroup ( groupEdits: EditedIds | undefined, rcrd: _t.EntityRecord ): string {
    return !groupEdits ? rcrd.group.displayName : groupEdits.old;
}
function getGroupRoot ( GroupRootEdits: EditedIds | undefined, rcrd: _t.EntityRecord ): string {
    return !GroupRootEdits ? rcrd.group.root.name : GroupRootEdits.old;
}
function getRank ( rankEdits: EditedIds | undefined, rcrd: _t.EntityRecord ): string {
    if ( !rankEdits ) return rcrd.rank.displayName;
    const ranks = util.getEntities( 'rank' );
    const oldRankName = ranks[ rankEdits.old ]?.displayName;
    if ( !oldRankName ) console.error( 'Previous taxon-rank name not found' );
    return oldRankName || 'MISSING RANK';
}