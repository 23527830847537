/**
 * If field is pending review, a generic option is set in the field's combo.
 * If edited, the field is highlighted and the previous field-value displayed.
 *
 * Export
 *     finishReviewFieldInit
 *     reinitReviewFormatting
 *
 * TOCh
 *     INIT
 *         DISABLE
 *         REINIT FORMATTING
 */
/* UTILS */
import { enableCombobox, isMultiField } from '@elems';
import { cloneObj } from '@util';
/* DATA-ENTRY */
import { components, form, state } from '@dataentry';
export { setReviewStageOption } from './fill-field.js';
import highlightEditsForReview from './edited-fields.js';
import { fillReviewField } from './fill-field.js';

let isDisplayFormOnly = null;
/* ============================ INIT ======================================== */
export function finishReviewFieldInit ( rEntry ) {
    isDisplayFormOnly = state.isDisplayFormOnly( rEntry.inst.group );
    return initReviewFields( rEntry.inst.group, rEntry );
}
function initReviewFields ( fLvl, rEntry ) {
    const fields = state.getDisplayedFields( fLvl );                /*dbug-log*///console.log( '   +--initReviewFields fLvl[%s] isDisplayFormOnly?[%s] rEntry[%O] fields[%O]', fLvl, isDisplayFormOnly, rEntry, fields );
    return handleInitReviewFields( fields, rEntry );
}
function handleInitReviewFields( fields, rEntry ) {
    return Object.values( fields ).reduce( handleFill, Promise.resolve() );

    function handleFill( p, field ) {
        return p.then( () => initReviewFieldUi( field, rEntry ) );
    }
}
function initReviewFieldUi ( field, rEntry ) {                       /*dbug-log*///console.log( '       -- initReviewFieldUi field[%s %O] rEntry[%O]', field.name, field, rEntry );
    return Promise.resolve( fillReviewField( field, isDisplayFormOnly ) )
        .then( () => finishFieldUiInit( field, rEntry ) );
}
function finishFieldUiInit( field, rEntry ) {
    ifEditFormHighlightEdits( field, rEntry );
    if ( field.notes ) addFieldReviewNotes( field );
    if ( isDisplayFormOnly ) disableFieldInput( rEntry.inst.group, field );
}
function ifEditFormHighlightEdits ( field, rEntry ) {
    if ( rEntry.form.action !== 'edit' ) return;
    highlightEditsForReview( field, rEntry.payload );
}
/* --------------------------- NOTES ---------------------------------------- */
function addFieldReviewNotes ( field ) {
    if ( !field.notes ) return;
    components.showFieldReviewNotes( field );
}
/* -------------------------- DISABLE --------------------------------------- */
function disableFieldInput ( fLvl, field ) {                        /*dbug-log*///console.log( '       --disableFieldInput fLvl[%s] field[%s][%O]', fLvl, field.name, cloneObj( field ) );
    if ( isMultiField( field ) ) return disableEachSubField( fLvl, field );
    if ( field.combo ) return enableCombobox( field.combo, false );
    $( `#${ fLvl }-form #${ field.id }_f .f-input` ).attr( { 'disabled': true } );
}
function disableEachSubField ( fLvl, field ) {
    for ( let i = field.count; i > 0; i-- ) {
        enableCombobox( field.name + i, false );
    }
}
/* ---------------------- REINIT FORMATTING --------------------------------- */
export function reinitReviewFormatting ( fLvl ) {                   /*dbug-log*///console.log( '+-- reinitReviewFormatting fLvl[%s]', fLvl );
    if ( !state.isDisplayFormOnly( fLvl ) ) return;
    const comboFields = state.getComboFields( fLvl );
    comboFields.forEach( reformatReviewField );
}
function reformatReviewField ( field ) {                            /*dbug-log*///console.log( '   -- reformatReviewField field[%s][%O]', field.name, field );
    if ( field.count ) return reformatMultiFields( field );
    enableCombobox( field.combo, false );
}
/**
 * Note: Extra fields must be removed before fields disabled to prevent errors
 * due to the dynamic field build after author selected.
 */
function reformatMultiFields ( field ) {
    disableFieldInput( field.group, field );
    form.rmvExtraMultiFields( field.name, field.count, field.group, false );
}