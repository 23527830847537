/**
 * Manages toggling expandable-content.
 *
 * <DIV.expandable>
 *     <H#.toggle-trigger>
 *     H#.toggle-trigger.next()
 *
 * EXPORT
 *     setExpandableContentListeners
 *     setElemExpandableListeners
 *
 * TOC
 *     TOGGLE LISTENER
 *     TOGGLE EXPAND/COLLAPSE
 *         EXPAND CONTENT
 *         COLLAPSE CONTENT
 *         UPDATE HEADER TEXT
 */
/* ====================== TOGGLE LISTENER =================================== */
export function setExpandableContentListeners () {
    $( ".toggle-trigger" ).on( 'click', toggleExpandableContent );
}
export function setElemExpandableListeners ( $elem: JQuery<Element> ) {
    $elem.find( ".toggle-trigger" ).on( 'click', toggleExpandableContent );
}
/* ==================== TOGGLE EXPAND/COLLAPSE ============================== */
type toggleState = 'expanded' | 'collapsed';
function toggleExpandableContent ( ev: JQuery.TriggeredEvent ) {
    const $header = $( ev.target );
    const $content = $header.next();
    const toggleTo = $content.hasClass( 'closed' ) ? 'expanded' : 'collapsed';
    toggleElemContent( $header, $content, toggleTo );
    updateContentHeader( $header, toggleTo );
}
function toggleElemContent ( $header: JQuery<Element>, $content: JQuery<Element>, toggleTo: toggleState ) {
    if ( toggleTo === 'expanded' ) expandContent( $header, $content );
    else { collapseContent( $header, $content ); }
}
/**
 * Toggles expandable content.
 * @param  {string}  selector  Elem selector
 * @param  {string}  tKey      Toggle to state: "expanded" or "collapsed"
 * @param  {boolean} tBool     True: expand, False: collapse
 */
export function toggleContent ( selector: string, tKey?: toggleState, tBool?: boolean ) {
    const $header = $( `${ selector }.toggle-trigger` );
    const toggleTo = tKey ? tKey : getNextToggleState( $header, tBool );
    toggleElemContent( $header, $header.next(), toggleTo );
}
function getNextToggleState ( $elem: JQuery<Element>, tBool?: boolean ) {
    if ( tBool !== null ) return tBool ? 'expanded' : 'collapsed';
    return $elem.hasClass( 'closed' ) ? 'expanded' : 'collapsed';
}
/* ---------------------- EXPAND CONTENT ------------------------------------ */
function expandContent ( $header: JQuery<Element>, $content: JQuery<Element> ) {
    $header.removeClass( 'closed' );
    $content.removeClass( 'closed' );
}
/* --------------------- COLLAPSE CONTENT ----------------------------------- */
function collapseContent ( $header: JQuery<Element>, $content: JQuery<Element> ) {
    $header.addClass( 'closed' );
    $content.addClass( 'closed' );
}
/* --------------------- UPDATE HEADER TEXT --------------------------------- */
function updateContentHeader ( $header: JQuery<Element>, toggleTo: toggleState ) {
    let newTxt: 'Collapse' | 'Expand' | false = $header.data( toggleTo );
    if ( newTxt === false ) return;
    if ( !newTxt ) newTxt = toggleTo == 'expanded' ? 'Collapse' : 'Expand';
    $header.text( newTxt );
}