/** Custom utility methods for working with the fp-ts library. */
import * as T from 'fp-ts/Task';
import * as TE from 'fp-ts/TaskEither';
/* =========================== FP-TS ========================================== */
export function promiseToTask <T>( p: Promise<T> ): T.Task<T> {
    return () => p;
}
export function promiseToTaskEither <T>( p: Promise<T> ): TE.TaskEither<string, T> {
    return TE.tryCatch(
        () => p,
        reason => String( reason )
    );
}

export function taskEitherToPromise<T, U>( te: TE.TaskEither<T, U> ): Promise<U> {
    return te().then( e => {
        if ( e._tag === 'Left' ) {
            return Promise.reject( e.left );
        }
        return Promise.resolve( e.right );
    } )
    .catch( e => handlePromiseError( e ) as U );
}
function handlePromiseError( e: Error | string ): void {
    if ( e instanceof Error ) console.error( e.message )
    if ( typeof e === 'string' && e.includes( 'Alert:' ) ) console.info( e )
    console.error( e )
}