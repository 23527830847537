/**
 * Handles miscellaneous fields and elements in the interaction forms.
 *
 * date range
 * season
 * secondary
 * interaction text
 *
 *
 * Export
 *     clearInteractionDate
 *     initMiscFields
 *     onSourceTypeChange
 *
 * TOC
 *     INTERACTION-DETAILS NOTE
 *     REFERENCE-GUIDE BUTTON
 *     SOURCE-TYPE CHANGE
 */
import referenceGuide from '@files/form-reference-guide.pdf';
import { getFieldState, setFieldState, setFieldValue } from '@dataentry/state';
import * as el from '@elems';
import { focusPinAndEnableSubmitIfFormValid } from '../util/focus-pin.js';

const app = { cal: false };

export function initMiscFields () {                                  /*dbug-log*///console.log('        -- initMiscFields');
    handleInitSourceType();
    addInteractionDetailsNote();
    addReferenceGuideButton();
    initDateRangeCalendar();
    $( '#txt-Note' ).change( focusPinAndEnableSubmitIfFormValid.bind( null, 'Note' ) );
}
function handleInitSourceType () {
    const selected = el.getSelTxt( 'Source' );                /*dbug-log*///console.log(' --selected source-type [%s]', selected);
    if ( !selected ) el.setSelVal( 'Source', 'Primary' );
    onSourceTypeChange( selected );
}
/* --------------------- INTERACTION-DETAILS NOTE --------------------------- */
function addInteractionDetailsNote () {
    const $typeRow = $( '#InteractionType_f' )[ 0 ].parentNode;
    const cntnr = el.getElem( 'div', { id: 'form-note', class: 'a-mrg g' } );
    $( cntnr ).append( 'PLEASE INCLUDE ALL AVAILABLE INTERACTION DATA' );
    $typeRow.after( cntnr );
}
/* ----------------------- REFERENCE-GUIDE BUTTON --------------------------- */
function addReferenceGuideButton () {
    const attr = { type: 'button', value: 'Reference Guide' };
    const bttn = el.getElem( 'input', attr );
    $( bttn ).on( 'click', openReferenceGuideInNewTab );
    $( '.exit-row' ).prepend( bttn );
}
function openReferenceGuideInNewTab () {
    return window.open( referenceGuide, '_blank' );
}
/* ----------------------- SOURCE-TYPE CHANGE ------------------------------- */
/** Defaults to Primary. If Secondary, the source-text must be quoted. */
export function onSourceTypeChange ( val ) {
    const required = val === 'Secondary';
    setFieldState( 'top', 'Quote', { required } );
}
/* ============================ DATE RANGE ================================== */
function initDateRangeCalendar () {
    app.cal = el.getNewCalendar( getDateRangeCalConfig() );
    setDateInitValue( $( app.cal.input ).data( 'init-val' ) );
}
export function clearInteractionDate () {
    app.cal.clear();
}
function getDateRangeCalConfig () {
    return {
        elemId: '#int-date-start',
        enableTime: false,
        onChange: storeDateValue,
        plugins: { range: { input: '#int-date-end' } },
    };
}
function storeDateValue ( dates, dateTime, instance ) {             /*dbug-log*///console.log('storeDateValue dates[%O] dateTime[%O] instance[%O]', dates, dateTime, instance);   console.trace();
    setFieldValue( 'top', 'Date', dateTime );
}
/* ---------------------- SET INIT-VAL -------------------------------------- */
function setDateInitValue ( initVal ) {                                /*dbug-log*///console.log('setDateInitValue initVal?[%s]', initVal); console.trace();
    if ( !initVal ) return;
    const dField = getFieldState( 'top', 'Date' );
    const dates = getNormalizedDates( initVal.split( ' to ' ) );
    app.cal.setDate( dates, false );
    window.setTimeout( () => setDateInputs( dField.id, dates ), 500 );  //set last so flatpikr setDate has more time to complete
}
function getNormalizedDates ( dateStrings ) {
    const dates = dateStrings.map( d => new Date( d ) );
    return dates.map( d => d.convertToUTC( d ) );
}
function setDateInputs ( id, dates ) {                                 /*dbug-log*///console.log('setDateInputs id[%s] dates[%O]', id, dates);
    if ( dates.length < 2 ) return;
    const format = app.cal.config.altFormat;
    $( `#${ id }-end` ).val( app.cal.formatDate( dates[ 1 ], format ) );
    $( `#${ id }-start` )[ 0 ].nextSibling.value = app.cal.formatDate( dates[ 0 ], format ); //Necessary to work around flatpikr
}