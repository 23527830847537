/**
 * Handles the saving, editing, and display of saved lists of interactions.
 *
 * Export
 *     addListPanelEvents
 *     newIntList
 *     sel-InteractionList
 *     toggleListPanelOrientation
 *     enableListResetBttn
 *
 * TOC
 *     SHOW/HIDE LIST PANEL
 *         Toggle Panel Vertically or Horizontally
 *     CREATE/OPEN INTERACTION LIST
 *     EDIT INTERACTION LIST
 *     DELETE INTERACTION LIST
 *     LOAD INTERACTION LIST IN TABLE
 *     MODIFY LIST RADIOS
 *     UTILITY
 *         SUBMIT AND SUCCESS METHODS
 *         UI
 *             PSEUDO TAB INVISIBLE BOTTOM BORDER
 *             Reset & Enable/Disable UI
 *             Table Methods
 */
import * as combo from '@elems/combo';
import { getData, updateUserNamedList } from '@localdata';
import { getUserRole, sendAjaxQuery, ucfirst } from '@util';
import { table, ui } from '@explore';
import * as panels from '..';
/**
 * list - List open in panel
 * listLoaded - List loaded in table
 * modMode - List modificiation state: 'add' or Remove ('rmv')
 * submitting - True when updates are in submit process
 * tableRowSelect - Feature class handling table-row selection
 * tblState - state data for table and search page
 * timeout - present when window is being resized.
 */
let app = {};

export function initListPanel () {
    if ( getUserRole() === 'visitor' ) return;
    initListPanelUtil();
    addListPanelEvents();
    disableInputs();
}
function initListPanelUtil () {
    app.tableRowSelect = panels.newTableRowSelect( 'list' );
    app.tableRowSelect.disable();
}
function addListPanelEvents () {
    window.addEventListener( 'resize', resizeIntPanelTab );
    $( '#lists' ).on( 'click', toggleSaveIntsPanel );
    $( 'button[name="clear-list"]' ).on( 'click', resetTable );
    $( '#list-details input, #list-details textarea, input[name="list-radios"]' ).change( enableSubmitBttn );
    $( '#load-list' ).on( 'click', loadListInTable );
    $( '#delete-list' ).on( 'click', deleteInteractionList );
    $( '#confm-list-delete' ).on( 'click', confmDelete );
    $( '#cncl-list-delete' ).on( 'click', cancelDelete );
}
/* ====================== SHOW/HIDE LIST PANEL ============================== */
function toggleSaveIntsPanel () {
    if ( $( '#list-pnl' ).hasClass( 'closed' ) ) {
        buildAndShowIntPanel();
        sizeIntPanelTab();
    } else { panels.togglePanel( 'lists', 'close' ); }
}
function buildAndShowIntPanel () {                                   /*dbug-log*///console.log( '           +--buildAndShowIntPanel' );
    panels.togglePanel( 'lists', 'open' );
    initListCombobox();
}
export function enableListResetBttn () {
    const $bttn = $( 'button[name="clear-list"]' );
    const cursor = !app.listLoaded ? 'inherit' : 'pointer';
    const opac = !app.listLoaded ? .5 : 1;
    $bttn.attr( 'disabled', !app.listLoaded ).css( { cursor: cursor } ).fadeTo( 'fast', opac );
}
/* --------------- Toggle Panel Vertically or Horizontally ------------------ */
export function toggleListPanelOrientation ( style ) {
    if ( style == 'vert' ) {
        stackIntListPanel();
    } else { spreadIntListPanel(); }
}
/* --- Vertical Stacking --- */
function stackIntListPanel () {
    $( `#sel-list-cntnr` ).removeClass( 'flex-col' ).addClass( 'flex-row' );
    $( `#list-pnl, #int-lists, #list-details, #mod-list-pnl, #sel-list-cntnr,
        #load-list, #list-count` ).addClass( 'vert' );
    stackListElems();
}
function stackListElems () {
    $( '#top-details' ).append( $( '#list-count' ).detach() );
}
/* --- Horizontal Spreading --- */
function spreadIntListPanel () {
    $( `#sel-list-cntnr` ).removeClass( 'flex-row' ).addClass( 'flex-col' );
    $( `#list-pnl, #int-lists, #list-details, #mod-list-pnl, #load-list,
        #sel-list-cntnr, #list-count` ).removeClass( 'vert' );
    $( '#list-details' ).append( $( '#list-count' ).detach() );
}
/* ============== CREATE/OPEN INTERACTION LIST ============================== */
/* ------ CREATE LIST ------- */
/** Creates a new list of saved interactions. */
export function newIntList ( val ) {                                   /*dbug-log*///console.log('           --New Interaction List');
    $( '#submit-list' ).off( 'click' ).on( 'click', createDataList );
    updateUiForListCreate();
    fillListDataFields( val, '', 0 );
    addActiveListToMemory();
}
function updateUiForListCreate () {
    enableInputs( 'create' );
    enableModUi( 'add' );
    hideSavedMsg();
    updateDetailHdr( 'New' );
}
function createDataList () {
    if ( !$( '#top-details input' ).val() ) { return $( '#top-details input' ).focus(); }
    $( '#submit-list' ).data( 'submitting', true ); //Prevents selMode from being overwritten
    const data = buildListData();
    if ( !data ) return; //No interactions selected; Alert shown to the user
    submitDataList( data, 'create', onListSubmitComplete.bind( null, 'create' ) );
}
/* ------ OPEN LIST ------- */
/** Opens a saved list of interactions. */
export function selIntList ( val ) {
    if ( val === 'create' ) { return newIntList( '' ); }
    if ( !val && !app.submitting ) { return resetListUi(); }
    if ( val === 'new' || ( !val && app.submitting ) ) return; // New list typed into combobox or mid edit-submit
    fillListData( val );
    resetPrevListUiState();
    $( '#submit-list' ).off( 'click' ).on( 'click', editDataList );
    enableInputs();
    enableModUi( 'add' );
    updateDetailHdr( 'Selected' );
}
function editDataList () {
    if ( !$( '#top-details input' ).val() ) { return $( '#top-details input' ).focus(); }
    $( '#submit-list' ).data( 'submitting', true ); //Prevents selMode from being overwritten
    const data = buildListData();
    if ( !data ) return;//No interactions selected; Alert shown to the user
    data.id = combo.getSelVal( 'InteractionList' );
    submitDataList( data, 'edit', onListSubmitComplete.bind( null, 'edit' ) );
}
function fillListData ( id ) {
    getData( 'dataLists' ).then( lists => {
        const list = addActiveListToMemory( lists[ id ] );              /*dbug-log*///console.log('activeList = %O', list);
        fillListDataFields( list.displayName, list.description, list.details.length );
    } );
}
/* ====================== EDIT INTERACTION LIST ============================= */
function buildListData () {
    const ints = getNewIntListIds( app.tableRowSelect.getSelectedInteractions() );
    if ( !ints ) { return false; }
    return {
        displayName: ucfirst( $( '#list-details input' ).val() ),
        type: 'interaction',
        description: $( '#list-details textarea' ).val(),
        details: JSON.stringify( ints ),
    };
}
function getNewIntListIds ( ids ) {                                    /*dbug-log*///console.log('-- getNewIntListIds modMode[%s] ids[%O]', app.modMode, ids)
    return app.modMode === 'add' ? getIntsToAdd( ids ) :
        app.list.details.filter( id => ids.indexOf( id ) === -1 );
}
function getIntsToAdd ( ids ) {
    return [ ...new Set( ids.concat( app.list.details ).filter( id => id ) ) ];
}
/* ====================== DELETE INTERACTION LIST =========================== */
function deleteInteractionList () {
    $( '#delete-list' ).hide();
    $( '#list-confm-cntnr' ).show();
}
function confmDelete () {                                            /*dbug-log*///console.log( '           --Deleted Interaction List' );
    resetDeleteButton();
    sendAjaxQuery( { id: app.list.id }, 'lists/remove', onListDeleteComplete );
    resetListUi();
}
function cancelDelete () {
    resetDeleteButton();
}
function resetDeleteButton () {
    $( '#list-confm-cntnr' ).hide();
    $( '#delete-list' ).show();
}
/* ================== LOAD INTERACTION LIST IN TABLE ======================== */
/**
 * Loads the interaction set in the table, where it can be explored and filtered
 * with the standard UI options
 */
function loadListInTable () {                                        /*dbug-log*///console.log( '           +--Loading Interaction List in Table. %O', app.list );
    prepareMemoryForTableLoad();
    table.onFilterChangeUpdateRowData();
    updateRelatedListUi();
}
function prepareMemoryForTableLoad () {
    table.setFilterState( 'list', app.list.details, 'direct' );
    app.tblState = table.getState();
    app.listLoaded = true;
}
function updateRelatedListUi () {
    table.expandAllTableRows();
    syncFilterUi();
    updateListLoadButton( 'Reset to All Interactions', resetTable );
    hideSavedMsg();
    enableModUi( 'rmv' );
    enableListResetBttn();
    updateDetailHdr( 'Loaded' );
    delete app.tblState;
}
function syncFilterUi () {
    ui.updateFilterStatusMsg();
    if ( $( '#sel-FilterSet' )[ 0 ].selectize ) {
        $( '#sel-FilterSet' )[ 0 ].selectize.clear( 'silent' );
    }
}
function updateListLoadButton ( text, clickFunc ) {
    $( '#load-list' ).html( text );
    $( '#load-list' ).off( 'click' ).on( 'click', clickFunc );
}
/* ====================== UTILITY =========================================== */
function addActiveListToMemory ( list ) {
    app.list = parseUserNamed( list );
    return app.list;
}
function parseUserNamed ( entity ) {
    return entity ? parseEntity( entity ) : { details: [] };
}
function parseEntity ( entity ) {
    entity.details = typeof entity.details == 'string' ?
        JSON.parse( entity.details ) : entity.details;
    return entity;
}
/* ---------------- SUBMIT AND SUCCESS METHODS -------------------------------*/
/** Submit new or edited interaction list. */
function submitDataList ( data, action, hndlr ) {
    app.submitting = app.modMode; //Flag tells various event handlers how to handle submit
    sendAjaxQuery( data, 'lists/' + action, hndlr );
}
function onListSubmitComplete ( action, results ) {
    const list = JSON.parse( results.list.entity );                   /*dbug-log*///console.log('listSubmitComplete results = %O, list = %O', results, list)
    updateUserNamedList( results.list, action )
        .then( updateListComboboxOptions )
        .then( updateUiAfterListSubmit.bind( null, list ) );
}
function updateUiAfterListSubmit ( list ) {
    $( '#sel-InteractionList' )[ 0 ].selectize.addItem( list.id );
    showSavedMsg();
    if ( app.submitting === 'rmv' ) { loadListInTable(); }
    delete app.submitting;
    $( '#submit-list' ).data( 'submitting', false );
}
function onListDeleteComplete ( results ) {                            /*dbug-log*///console.log('listDeleteComplete results = %O', results)
    updateUserNamedList( results.list, 'delete' )
        .then( updateListComboboxOptions )
        .then( () => $( '#sel-InteractionList' )[ 0 ].selectize.open() );
}
function showSavedMsg () {
    $( '#list-submit-msg' ).fadeTo( 'slow', 1 );
    window.setTimeout( hideSavedMsg, 3000 );
}
function hideSavedMsg () {
    $( '#list-submit-msg' ).fadeTo( 'slow', 0 );
}
/* =============================== UI ======================================= */
function initListCombobox () {
    if ( $( '#list-details>span' ).text() !== 'List Details' ) return;
    combo.initCombobox( { name: 'Interaction List', onChange: selIntList, create: newIntList } );
    updateListComboboxOptions().then( () => {
        window.setTimeout( () => $( '#sel-InteractionList' )[ 0 ].selectize.focus(), 500 );
        disableInputs();
    } );
}
function fillListDataFields ( nameVal, descVal, intCnt ) {
    $( '#list-details input' ).val( nameVal ).focus();
    $( '#list-details textarea' ).val( descVal );
    $( '#int-list-cnt' )[ 0 ].innerHTML = '<b>' + intCnt + '</b>';
    if ( intCnt > 0 ) {
        $( '#load-list, #load-list+div' ).attr( { disabled: false } ).css( { opacity: 1 } );
    }
}
/* --- PSEUDO TAB INVISIBLE BOTTOM BORDER -------- */
function resizeIntPanelTab () {
    if ( $( '#list-opts' ).hasClass( 'closed' ) ) return;
    if ( app.timeout ) return;
    app.timeout = window.setTimeout( () => {
        sizeIntPanelTab();
        app.timeout = false;
    }, 500 );
}
function sizeIntPanelTab () {
    const pseudo = getPseudoStyle();
    $( '.hide-int-bttm-border:before' ).remove();
    $( '.hide-int-bttm-border' ).append( pseudo );
}
function getPseudoStyle () {
    const panelT = $( '#list-pnl' ).position().top;
    const tabW = $( '#list-opts' ).innerWidth();
    const tabL = $( '#list-opts' ).position().left + 1;               /*dbug-log*///console.log('sizeIntPanelTab. T = [%s], W = [%s], L = [%s]', panelT, tabW, tabL); //1px border
    return `<style>.hide-int-bttm-border:before {
        position: absolute;
        content: '';
        height: 3px;
        z-index: 10;
        width: ${ tabW }px;
        top: ${ panelT }px;
        left: ${ tabL }px;
        background: #e2f2f3;
        }</style>`;
}
/* ---- Reset & Enable/Disable UI --- */
function resetListUi () {
    clearAndDisableInputs();
    hideSavedMsg();
    resetPrevListUiState();
}
function clearAndDisableInputs () {
    $( '#list-details input, #list-details textarea' ).val( '' );
    $( '#int-list-cnt' ).html( '' );
    updateDetailHdr( '' );
    disableModUi();
    disableInputs();
}
function enableInputs ( creating ) {
    $( `#list-details input, #list-details textarea, #list-details span,
        #mod-list-pnl > span:first-child, #list-pnl button, #list-mod-mode` )
        .attr( { 'disabled': false } ).css( { 'opacity': '1' } );
    if ( creating ) { $( '#delete-list' ).attr( { 'disabled': 'disabled' } ).css( { 'opacity': '.5' } ); }
}
function disableInputs () {
    $( `#list-details input, #list-details textarea, #list-details span, #sel-list-cntnr button,
        #mod-list-pnl button, #mod-list-pnl > span:first-child, #load-list+div, #list-mod-mode` )
        .attr( { 'disabled': 'disabled' } ).css( { 'opacity': '.5' } );
    $( '#mod-rmv-list, label[for="mod-rmv-list"]' ).css( { display: 'none' } );
}
function enableModUi ( m ) {
    const mode = app.submitting || m;                               /*dbug-log*///console.log('-- enableModUi m[%s] mode[%s]', m, mode)
    const label = mode === 'add' ? 'Add to List:' : 'Remove from List:';
    $( '#list-mod-mode' ).html( label ).css( { 'font-weight': 600, 'font-size': '.9em' } );
    app.tableRowSelect.clearMode();
    app.tableRowSelect.setMode( 'some' );
    app.modMode = mode;
}
function disableModUi () {
    $( '#list-mod-mode' ).css( { 'font-weight': 400 } );
    app.tableRowSelect.clearMode();
}
function enableSubmitBttn () {
    $( '#submit-list' ).attr( { 'disabled': false } ).css( { 'opacity': '1' } );
    hideSavedMsg();
}
function updateDetailHdr ( type ) {
    $( '#list-details>span' ).html( type + ' List Details' );
}
function updateListComboboxOptions () {
    return Promise.resolve( combo.getOptsFromStoredData( 'dataListNames' )
        .then( opts => {
            opts.unshift( { value: 'create', text: '...Add New Interaction List' } );
            combo.replaceSelOpts( 'InteractionList', opts );
        } ) );
}
function resetPrevListUiState () {
    if ( !app.listLoaded || app.submitting ) return;
    resetTable();
    updateListLoadButton( 'View Interaction List in Table', loadListInTable );
    delete app.listLoaded;
}
/* --- Table Methods --- */
/** Resets interactions displayed to the full default set of the current focus. */
function resetTable () {
    table.setFilterState( 'list', false, 'direct' );
    delete app.listLoaded;
    table.onFilterChangeUpdateRowData();
    updateUiAfterTableReset();
}
function updateUiAfterTableReset () {
    enableModUi( 'add' );
    $( '#load-list' ).html( 'Load Interaction List in Table' );
    $( '#load-list' ).off( 'click' ).on( 'click', loadListInTable );
    if ( !$( '#list-pnl' ).hasClass( 'closed' ) ) table.expandAllTableRows();
    updateDetailHdr( 'Selected' );
}