/**
 * Prepares, validates and formats, form data before it is pushed to the server.
 */
import * as util from './util.js';
import { prepareDataAndSetEntityProperty } from './set-property.js';
import { getFieldValue } from './get-field-value.js';
/**
 * @param  {object} config   Configuration and state for the form being submitted
 * @return {promise}         Data prepared to be pushed to the server
 */
export function prepareFormData( config ) {                         /*dbug-log*///console.log('       --prepareFormData [%s][%O]', config.group, config);
    util.prepProcessMemory( config );
    return Promise.all( processFormFields( config ) )
        .then( () => util.handleFailuresAndReturnPreparedData( config.group ) );
}
/* ========================= PREPARE DATA =================================== */
function processFormFields( config ) {
    return Object.values( config.fields ).map( f => processField( f, config ) );
}
/* ------------------------- PREPARE FIELD ---------------------------------- */
/** Wrangles the field's data and adds to the server JSON. */
function processField( field, config ) {                            /*dbug-log*///console.log('       --processField [%s][%O]', field.name, field);
    if ( hasNoDataToProcess( field, config ) ) return;  //Field not active.
    if ( field.value === undefined ) { return handleEmptyFieldData( field ); } //Field never set
    return processFieldValue( field );
}
function hasNoDataToProcess( field, config ) {
    return !field.active && config.action !== 'edit';
}
function handleEmptyFieldData( field ) {
    if ( !field.required ) return;
    util.trackFailure( field.name, field.value );
}
function processFieldValue( field ) {
    const fKey = field.entity ? 'rel' : 'flat';
    const v = getFieldValue( field );                               /*dbug-log*///console.log('           --handleFieldValue [%s][%s][%O] v[%O]', field.name, fKey, field, v);
    if ( !v ) { handleEmptyFieldData( field ); }
    return prepareDataAndSetEntityProperty( v, fKey, field, util );
}