/**
 * Generalized Ajax Methods.
 *
 * Export
 *     sendAjaxQuery
 *     logAjaxData
 */
import { alertIssue, reportErr } from "./alert-issue";
/* ======================= DATA UTIL ======================================== */
type Requests = "GET" | "POST" | "PUT" | "DELETE" | "OPTIONS" | "HEAD" | "PATCH";

export async function fetchServerData<T extends object | string | null>(
    url: string,
    options: RequestInit = {},
    n = 9
): Promise<T|Error> {                                               /*dbug-log*///console.log('*-fetchServerData [%s] with params = %O', url, options);
    try {
        url = $('body').data('base-url') + url;
        return sendRequest<T>(url, options, n);
    } catch (error) {
        if (error instanceof Error) {
            console.error(error.message);
            reportErr( error );
        }
        return error as Error;
    }
}
function alertFetchIssue ( url: string, responseText: string ) {
    alertIssue( 'fetchIssue', { url: url, response: responseText } );
    return Promise.reject();
}
export async function sendRequest<T extends object | string | null> (
    url: string,
    options: RequestInit = {},
    n = 9
): Promise<T> {                                                     /*dbug-log*///console.log('*-sendRequest [%s] with params[%O] (tries remaining[%s])', url, options, n);
    const response = await fetch( url, prepareOptions(options) );
    if ( response.ok ) return await response.json();
    if ( n === 1 ) return alertFetchIssue( url, response.json() as any as string );
    return sendRequest<T>( url, options, n - 1 );
}
function prepareOptions(options?: RequestInit): RequestInit {
    let headers = {"X-Requested-With": "XMLHttpRequest", 'Content-Type': 'application/json'};
    if (options && options.headers) {
        headers = {...options.headers, ...headers};
        delete options.headers;
    }
    return Object.assign({
        headers: headers,
    }, options);
}
export function sendAjaxQuery (
    dataPkg: object | string | null,
    url: string,
    successCb?: Function,
    errCb?: Function,
    method?: Requests
):  JQuery.Promise<any> {
    logAjaxMsgToConsole( dataPkg, true, arguments );
    const envUrl = $( 'body' ).data( "base-url" );
    const ajaxConfig: JQuery.AjaxSettings = {
        method: method || "POST",
        url: envUrl + url,
        success: ( successCb || logAjaxData.bind( null, false ) ) as JQuery.Ajax.SuccessCallback<void>,
        error: ( errCb || ajaxError ) as JQuery.Ajax.ErrorCallback<any>
    };
    if (dataPkg) ajaxConfig.data = JSON.stringify( dataPkg );
    return $.ajax( ajaxConfig ) as JQuery.Promise<any>;
}

export function logAjaxData (
    sending: boolean,
    dataPkg: string,
    _: JQuery.Ajax.SuccessTextStatus,
    _2: JQuery.jqXHR
): void {
    const data = typeof dataPkg === 'string' ? JSON.parse( dataPkg ) : dataPkg;
    logAjaxMsgToConsole( data, sending );
}
function logAjaxMsgToConsole ( data: object | string | null, sending: boolean, args: object = {} ): void {
    const action = sending ? 'S' : 'R';
    const isProd = $( 'body' ).data( 'env' ) === 'prod';
    const params = [ getDefaultAjaxMsg( isProd ), action, data, args ];
    if ( isProd ) params.pop();
    console.log( ...params );
}
function getDefaultAjaxMsg ( isProd: boolean ): string {
    const base = "           --[%s] Ajax data [%O]";
    return isProd ? base : base + " arguments = %O";
}
function ajaxError (
    jqXHR: JQuery.jqXHR,
    _1: JQuery.Ajax.ErrorTextStatus,
    _2: string
): void {
    console.log( "ajaxError. responseText = [%O] - jqXHR:%O", jqXHR.responseText, jqXHR );
}