/**
 * A ReviewEntry is loaded in its originating form with additional elements that
 * facilitate the data-review process. This module manages these elements.

 * todo: Create a readme with this.
 * Data-Review Process:
 * --Data entered by Contributors are quarantined, available only to that user,
 * pending review by a Data Manager.
 * --Managers review data in sets, each root form and its sub-form data.
 * ----During review, root ReviewEntry is "locked" to prevent simultaneous review.
 * ----The root-form loads opened to the first leaf sub-form and filled with data.
 * ----Root-data can only be approved once sub-data are either approved or replaced.
 * ------The manager can edit, reject, or return invalid-data.
 * --------Notes can be added to fields and/or form for the Contributor|Manager.
 * --Once reviewed, results are sent to their contributor.
 *
 * MANAGER ACTIONS
 * --APPROVE
 * ----Data is fully entered in the site database.
 * ------UI:
 * --------Root:
 * ----------Root-form closes.
 * ----------The next available ReviewEntry is loaded.
 * --------Sub:
 * ----------Sub-form closes.
 * ----------The data is set in the parent-field's combobox
 * --REJECT
 * ----Data is deleted once Contributor receives the results and Manager notes.
 * ------UI:
 * --------Root:
 * ----------Root-form closes.
 * ----------The next available ReviewEntry is loaded.
 * --------Sub:
 * ----------Sub-form closes.
 * ----------'Data REJECTED. Click to edit field...' set in the parent-field's combobox
 * ----------The action is logged in the parent'form's data-review notes
 * --RETURN
 * ---Data is not valid or complete in its current state.
 * ---Manager should include review/field notes
 * ------UI:
 * --------Root:
 * ----------Root-form closes.
 * ----------The next available ReviewEntry is loaded.
 * --------Sub:
 * ----------Sub-form closes.
 * ----------'Data RETURNED. Click to edit field...' set in its parent-field's combobox
 * ----------The action is logged in the parent'form's data-review notes
 * --PAUSE
 * ---Data set is held until the manager continues sits review.
 * ---Functions the same as when the data are "locked" to the manager during review
 * ------UI:
 * --------Root/Sub:
 * ----------Root-form closes.
 * ----------The next available ReviewEntry is loaded.
 * --SKIP
 * ---Data set is closed and will not be reopened during the current data-review.
 * ---Data set is unlocked and remains pending for future review.
 * ------UI:
 * --------Root/Sub:
 * ----------Root-form closes.
 * ----------The next available ReviewEntry is loaded.
 */
import { showPagePopup } from '@elems';
import { components, state, trans } from '@dataentry';
import { initRvwForm, loadRvwSubForm } from './forms.js';
/* ======================= ROOT-FORM ======================================== */
/**
 * Opens a ReviewEntry's originating form. Fields with ReviewEntry records will
 * be reviewed first.
 */
export function initDataReviewWizard ( skipId ) {                   /*dbug-log*///console.log( '   -- initDataReviewWizard skipId?[%s]', skipId );
    showPagePopup();
    return trans.getPendingRootReviewEntry( skipId )
        .then( ifDataToReviewLoadForm );
}
function ifDataToReviewLoadForm ( rEntry ) {
    return rEntry ? review( rEntry ) : endReview();
}
function review ( rEntry ) {
    components.toggleWaitOverlay( true );
    return initRvwForm( rEntry )
        .then( () => components.toggleWaitOverlay( false ) );
}
export function endReview () {                                      /*dbug-log*///console.log( '       -- endReview' );
    state.handleReviewFormClear();
    components.exitRootForm();
    components.toggleWaitOverlay( false );
    state.removeFormGroupState( 'top' );
    trans.clearSkippedRecordsMemory();
}
/* ========================= SUB-FORM ======================================= */
/**
 * After a form-init, the first field with a ReviewEntry is loaded. If there are
 * none, the form review-ui is enabled.
 * @param  {string} fLvl Initialized form-level
 * @return {promise}
 */
export function ifSubReviewEntryLoadForm ( fLvl ) {                     /*dbug-log*///console.log('   -- ifSubReviewEntryLoadForm fLvl[%s]', fLvl);
    const subEntry = trans.getNextSubReviewEntry( fLvl, null, true );
    return loadRvwSubForm( subEntry, fLvl );
}
/* ------------------------ LOAD NEXT SET ----------------------------------- */
/**
 * After a sub-form-submit, the parent-form's next sub-ReviewEntry loads. If
 * there are none, the parent-form's review-ui is enabled.
 */
export function continueReview ( fLvl, skipId ) {                   /*temp-log*///console.log( '   -- continueReview fLvl[%s] skipId[%s]', fLvl, skipId );
    if ( ifSubFormOpen( fLvl ) ) return enableReviewUi( fLvl ); // Form-level already opened
    const subEntry = trans.getNextSubReviewEntry( fLvl, skipId );
    return handleContinueReview( fLvl, subEntry );
}
function ifSubFormOpen ( fLvl ) {
    return fLvl !== 'top' && components.ifFormInUse( fLvl );
}
/**
 * Loads the next ReviewEntry, if it is for a group level not currently in use.
 * Otherwise, the parent-form's next sub-ReviewEntry loads. If this is the root
 * level or there are no valid ReviewEntry records to load, the form's review-ui
 * is enabled.
 */
function handleContinueReview ( fLvl, subEntry ) {              /*dbug-log*///console.log( '   -- handleContinueReview fLvl[%s] subEntry?[%O]', fLvl, subEntry );
    if ( ifValidReviewEntry( subEntry ) ) { return loadRvwSubForm( subEntry, fLvl ); }
    const pLvl = state.getFormLevel( 'parent', fLvl );
    return pLvl ? continueReview( pLvl ) : enableReviewUi( fLvl );
}
/** If next ReviewEntry exists and is for a group level not currently loaded for review. */
function ifValidReviewEntry ( subEntry ) {
    return subEntry && !components.ifFormInUse( subEntry.inst.group );
}
function enableReviewUi ( fLvl ) {                                  /*dbug-log*///console.log('   -- enableReviewUi fLvl[%s]', fLvl);
    loadRvwSubForm( false, fLvl );
    components.toggleWaitOverlay( false );
}