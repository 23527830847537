/**
 * Handles errors that occur after the form is submitted during server processing.
 *
 * Export
 *     onSubmitError
 *     onViolation
 *
 * TOC
 *     GENERAL ERROR
 *     ENTITY CONSTRAINT VIOLATION
 *         DUPLICATE ENTITY
 */
import { alert, components, state } from '@dataentry';
/* ==================== GENERAL ERROR ======================================= */
export function onSubmitError( fLvl, jqXHR, textStatus ) {          /*temp-log*///console.error( '+-- onSubmitError fLvl[%s] jqXHR[%O] textStatus[%s] args[%O]', fLvl, jqXHR, textStatus, arguments );
    handleError( fLvl, jqXHR.responseJSON, textStatus );
    components.toggleWaitOverlay( false );
}
function handleError( fLvl, errData, textStatus ) {                 /*temp-log*///console.log( '-- handleError errData[%O] textStatus[%s]', errData, textStatus );
    alert.formSubmitError( fLvl );
}
/* ============= ENTITY CONSTRAINT VIOLATION ================================ */
/**
 * The server process has detected a constraint violation.
 */
export function onViolation( fLvl, results ) {                      /*temp-log*///console.log( '-- handleViolation results[%O]', results );
    const fails = results.fail;
    for ( let type in fails ) handleFail( type, fails[ type ], fLvl );
    components.toggleWaitOverlay( false );
}
function handleFail( fail, data, fLvl ) {                           /*dbug-log*///console.log( '-- handleFail fLvl[%s] fail[%s] data[%O] ', fLvl, fail, data );
    const handlers = {
        Duplicate: handleDuplicateEntity,
        noChanges: showSubmitAlert,
        Delete: showSubmitAlert,
    };
    const type = Object.keys( handlers ).find( t => fail.includes( t ) ); /*dbug-log*///console.log( '   -- type[%s]', type );
    handlers[ type ]( fail, data, fLvl );
}
function showSubmitAlert( fail, data, fLvl ) {
    alert.formSubmitError( fLvl, fail );
}
/* ----------------- DUPLICATE ENTITY --------------------------------------- */
function handleDuplicateEntity( msg, id, fLvl ) {                   /*dbug-log*///console.log( '-- handleDuplicateEntity fLvl[%s] msg[%s] id[%s]', fLvl, msg, id );
    const duplicateEntity = getDuplicateEntity( msg, id );          /*dbug-log*///console.log( '   --duplicateEntity[%O]', duplicateEntity );
    const alertTag = getDuplicateAlertTag( fLvl, duplicateEntity );
    updateStateAsNeeded( fLvl, duplicateEntity, alertTag )
    showSubmitAlert( alertTag, null, fLvl );
}
function getDuplicateEntity( msg, id ) {
    return state.getRecords( msg.split( 'Duplicate ' )[ 1 ], id );
}
function getDuplicateAlertTag( fLvl, duplicateEntity ) {
    const isNameOnly = isDuplicateNameOnly( fLvl, duplicateEntity );
    return isNameOnly ? 'duplicateName' : 'duplicateEntity';
}
function isDuplicateNameOnly( fLvl, duplicateEntity ) {
    const fState = state.getFormState( fLvl );                      /*dbug-log*///console.log( '   --fState[%O]', fState );
    return fState.core && fState.name !== duplicateEntity.sourceType.displayName;
}
function updateStateAsNeeded( fLvl, duplicateEntity, alertTag ) {
    if ( alertTag === 'duplicateEntity' ) addDuplicateToState( fLvl, duplicateEntity );
}
function addDuplicateToState( fLvl, duplicateEntity ) {            /*dbug-log*///console.log( '   --duplicateEntity[%O]', duplicateEntity );
    const duplicate = { duplicateEntity };
    state.setFormState( fLvl, { misc: duplicate } );
}