/**
 * Builds and returns the form-data necessary for the data-review process.
 *
 * Export
 *     buildFormReviewData
 */
import { state } from '@dataentry';
import { getFieldReviewData } from './field.js';
import { prepareAndReturnReviewLog } from './logs.js';
/**
 * @param  {obj} FS     The submiting-form's state
 *
 * @return {obj}
 *           {int}  existingId   Used when rejecting entry as duplicate.
 *           {obj}  *form   Form-data relevant to the data-review process
 *               {str}  *action   Entry action [Create, Edit, Delete]
 *               {int}  count     Ordinal of the ReviewEntry field
 *               {int}  editId    Edit-form entity id
 *               {str}  *group    Originating form-group: top, sub#
 *               {obj}  *fields   Field-data relevant to the data-review process
 *                   {str}  *.notes    Notes attached to the field
 *                   {bol}  *.prop     Field entity-class properties
 *                   {obj}  .replacedReview   If ReviewEntry replaced { id (ReviewEntry), entity, qId (quarantined ID) }
 *                   {obj}  .review    ReviewEntry data: { id (ReviewEntry), entity, qId }
 *                   {misc} *.value   Field value
 *               {obj}  *stage    { name: [<SELECTED>||DEFAULT=QUARANTINE] }
 *           {int}  id        ReviewEntry ID
 *           {str}  *log      Form-level actions and notes between contributor and manager
 *           {obj}  *payload  Quarantined entry (only available to contributor until approved.)
 *           {obj}  *stage    { name: [<SELECTED>||DEFAULT=QUARANTINE] }
 */
export function buildFormReviewData ( FS ) {                        /*dbug-log*///console.log('-- buildFormReviewData FS[%O]', FS);
    const stage = getStageData( FS.review );    //active-form
    return {
        existingId: FS.review?.existingId || false,  //Set when rejecting ReviewEntry as duplicate of existing entity.
        id: FS.review?.id || false,
        form: getFormReviewData( FS, stage ),
        log: prepareAndReturnReviewLog( FS.group, stage.name ),
        stage: stage       //Used by the server to direct the review process.
    };
}
function getFormReviewData ( FS, stage ) {
    const form = {
        action: state.getEntryAction( FS.group ),
        fields: getFieldReviewData( FS ),
        group: FS.group,
        stage: stage    //Stored with the form data to track the last review-stage submitted.
    };
    if ( FS.count ) form.count = FS.count;  //Forms for a multi-field entity
    if ( FS.editing ) form.editId = FS.editing.core;
    return form;
}
function getStageData ( rEntry ) {
    return rEntry?.inst.rvwAction || { name: 'Quarantine' }; //default
}
