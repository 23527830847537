/**
 * Filter util methods.
 */
import { getElem } from '@elems';
/* ---------------------------- BUILD --------------------------------------- */
export function newSel( opts, c, i, field ) {                         /*dbug-log*///console.log('--newSel opts[%O], c[%s], i[%s], field[%s]', opts, c, i, field);
    const elem = getElem( 'select', { class: c, id: i }, opts );
    return elem;
}
export function getFilterField( lblTxt, input ) {
    const classes = lblTxt ? 'flex-row field-cntnr' : 'row-field';
    const lbl = getElem( 'label', { class: classes } );
    const span = lblTxt ? getElem( 'span', { text: lblTxt + ': ' } ) : null;
    $( lbl ).append( [ span, input ].filter( e=>e ) );
    return lbl;
}
/* ------------------------------- APPEND ----------------------------------- */
export function appendDynamicFilter( filterEl ) {
    const $el = ifRowIsFull() ?
        $( '#focus-filters' ) : $( $( '#focus-filters' )[ 0 ].lastChild );
    $el.append( filterEl );
}
function ifRowIsFull() {
    return $( '#focus-filters' )[ 0 ].lastChild.children.length % 2 === 0;
}