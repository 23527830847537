/**
 * Utility methods for ReviewEntry Local Storage.
 *
 * TOC
 *     PARSE REVIEW-ENTRY
 *     STORE REVIEW-ENTRY
 *     SYNC REVIEW-ENTRY
 */
import * as _t from '@types';
import { cloneAndParseReviewEntry, getAndSetData } from '@localdata/init';
import * as util from '@localdata/util';
import { DataEntryResults } from '@localdata/sync';
/* ======================== PARSE REVIEW-ENTRY ============================== */
export function getParsedReviewEntry ( data: DataEntryResults | _t.ReviewEntry ): _t.ReviewEntry {
    const rEntry = ( 'stage' in data ? data : data.review );
    return cloneAndParseReviewEntry( rEntry! );
}
/* ======================== STORE REVIEW-ENTRY ============================== */
/**
 * Sets flag tracking presence of quarantined-data in local storage. Updates the
 * ReviewEntry records in local storage. Tempt memory is used during page-load sync.
 */
export function updateReviewEntryStorage (
    rEntry: _t.ReviewEntry,
    ReviewEntries: _t.EntityRecords<_t.ReviewEntry>,
    skipTempMemory = true
): Promise<_t.EntityRecords<_t.ReviewEntry>> {
    updateQuarantinedDataFlag( rEntry.stage.name, skipTempMemory );
    return storeReviewEntryRecord( rEntry, ReviewEntries, skipTempMemory );
}
/**
 * After data-entry or during updates to contributor quarantined data, stored
 * ReviewEntry is updated.
 */
function storeReviewEntryRecord (
    rEntry: _t.ReviewEntry,
    ReviewEntries: _t.EntityRecords<_t.ReviewEntry>,
    skipTempMemory: boolean
): Promise<_t.EntityRecords<_t.ReviewEntry>> {
    ReviewEntries[ rEntry.id ] = rEntry;                            /*dbug-log*///console.log( '--storeReviewEntryRecord rEntry[%s][%O] ReviewEntries[%O]', rEntry.stage.name, rEntry, ReviewEntries );
    const handler = getStorageHandler( skipTempMemory );
    return Promise.resolve( handler( 'reviewEntry', ReviewEntries ) )
        .then( () => ReviewEntries );
}
function getStorageHandler( skipTempMemory: boolean ) {
    return skipTempMemory ? util.setData : util.storeData;
}
/**
 * Flag indicates user-specific quarantined-data has been added to local storage.
 * If the user changes, the database is redownloded to a fresh state.
 */
function updateQuarantinedDataFlag ( stage: string, skipTempMemory: boolean ): void {
    if ( stage !== 'Pending' ) return;
    const handler = getStorageHandler( skipTempMemory );
    handler( 'hasQuarantined', true );
}
/* ====================== SYNC REVIEW-ENTRY ================================= */
/**
 * Syncs ReviewEntry records with the server and returns the requested data props.
 * Note: See file that initializes review data in local storage for prop descriptions.
*/
export function syncReviewEntries ( props?: string[] ) {
    return syncReviewEntryRecords()
    .then( () => props && util.getData( props, true ) );
}
/** Gets updated ReviewEntries and stores locally. */
function syncReviewEntryRecords () {
    const processId = 'syncReviewEntry';
    return util.initMemoryDataObj( processId )
        .then( () => getAndSetData( 'review' ) )
        .then( () => util.setUpdatedDataInLocalDbAndClearTempMemory( processId ) );
}