/**
 * Handles initializing the Citation review-form.
 *
 * TOC
 *     SKIP REVIEW
 *     HANDLE INIT
 */
import { components, form, model, submit } from '@dataentry';
import { getSelVal } from '@elems/combo';
import { taskEitherToPromise } from '@util';
import { finishCitationFormInit } from '..';
import { getReviewFormParams } from './build-params';
/* FP-TS */
import * as E from 'fp-ts/Either';
import * as O from 'fp-ts/Option';
import * as TE from 'fp-ts/TaskEither';
import { pipe } from 'fp-ts/function';

export function initReviewForm( rEntry: model.FormReviewEntry ): Promise<string | void> {
    return pipe(
        rEntry,
        ifPubInvalid,
        E.fold(
            id => skipCitationReview( id ),
            () => handleReviewFormInit( rEntry )
        )
    );
}
/* ======================== SKIP REVIEW ===================================== */
function ifPubInvalid( rEntry: model.FormReviewEntry ): E.Either<number, void> {
    if ( rEntry.inst.group === 'top' ) return E.right( undefined );
    return isInvalidStage()
        ? E.left( rEntry.id )
        : E.right( undefined );
}
function isInvalidStage(): boolean {
    return pipe(
        O.fromNullable( getSelVal( 'Publication' ) ),
        O.exists( pubStage => [ 'rejected', 'returned', 'skipped' ].includes( pubStage ) )
    );
}
function skipCitationReview( id: number ): Promise<'skipReview'> {
    submit.pushReviewEntry( id, 'Skip', null, true );
    components.setReviewStageOption( 'CitationTitle', 'skipped' );
    return Promise.resolve( 'skipReview' );
}
/* ======================= HANDLE INIT ====================================== */
function handleReviewFormInit( rEntry: model.FormReviewEntry ): Promise<void |string> {
    return pipe(
        rEntry,
        getReviewFormParams,
        form.initEntityForm,
        TE.chain( _ => finishCitationFormInit( rEntry.inst.group ) ),
        taskEitherToPromise
    );
}