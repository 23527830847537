/**
 * On form submit success, the new and edited data is updated in the local database.
 *
 * Export
 *     DataEntryResults
 *     EditObj
 *     EditedIds
 *     FormReturnData
 *     handleLocalDataUpdate
 *     syncLocalDataAfterDataEntry
 *     updateLocalData
 *
 * TOC
 *     TYPES
 *     UPDATE DATA AFTER FORM-SUBMIT
 *         HANDLE UPDATE
 *         UPDATE ENTITY DATA
 */
import * as _t from '@types';
import * as util from '@localdata/util';
import { cloneObj } from '@util';
import * as update from '../update';
import { trackEditsToLocalProps } from './edge-case-sync';
/* ============================ TYPES ======================================= */
/**
 * @param  core          Entity classname
 * @param  CoreEntity    JSON Serialized record
 * @param  coreId        Record ID
 * @param  detail        Entity classname
 * @param  DetailEntity  JSON Serialized record
 * @param  detailId      Record ID
 * @param  name          Entity display-name, if property exists
 * @param  review        JSON Serialized ReviewEntry
 */
export interface DataEntryResults {
    core: _t.CoreEntity;
    coreEdits?: EditObj;
    coreEntity: _t.EntityRecord,
    coreId?: number;        //Added during quarantined-data init
    detail?: _t.DetailEntity;
    detailEdits?: EditObj;
    detailEntity: _t.EntityRecord | false;
    detailId?: number;      //Added during quarantined-data init
    name?: string;
    review?: _t.ReviewEntry | _t.ReviewEntryBones;
}
export type EditObj = {
    [prop: string]: EditedIds;
};
export type EditedIds = {
    new: number,  //This describes the relational-data. Flat-data updated is also stored in this format.
    old: number;
};
export type FormReturnData = DataEntryResults & { fails?: object; };
/* ===================== UPDATE DATA AFTER FORM-SUBMIT ====================== */
/** After data-entry (create, delete, edit, quarantine), Local Storage is updated. */
export async function syncLocalDataAfterDataEntry ( data: DataEntryResults ): Promise<FormReturnData> {
    /*perm-log*/console.log( "   /--syncLocalDataAfterDataEntry data recieved[%O]", cloneObj( data ) );
    const processId = 'dataEntry';
    const storedData = await util.initMemoryDataObj( processId )
        .then( () => handleLocalDataUpdate( data ) );
    util.clearTempMemory( processId );
    return storedData;
}
/* ----------------------- HANDLE UPDATE ------------------------------------ */
/** Parses data returned from server and updates local data-storage. */
export function handleLocalDataUpdate ( data: DataEntryResults ): Promise<FormReturnData | DataEntryResults> {
    util.parseData( data );
    return updateLocalData( data );
}
export function updateLocalData ( data: DataEntryResults ): Promise<FormReturnData> {/*temp-log*///console.log( '   -- updateLocalData data[%O]', cloneObj( data ) );
    trackEditsToLocalProps( data );
    updateEntityData( data );
    return util.setNewDataInLocalStorage()
        .then( () => update.ifFailuresReportAndAddToReturnData( data ) );
}
/* ------------------- UPDATE ENTITY DATA ----------------------------------- */
function updateEntityData ( data: DataEntryResults ): void {        /*dbug-log*///console.log( 'updateEntityData[%O]', data );
    update.addCoreEntityData( data.core, data.coreEntity );
    updateDetailEntityData( data );
    update.removeInvalidatedData( data );
    update.retryFailedUpdates();
}
function updateDetailEntityData ( data: DataEntryResults ): void {
    if ( !data.detailEntity ) return;
    if ( data.detail ) return update.addDetailEntityData( data.detail, data.detailEntity );
    console.error( 'Detail Entity name not found' );
}