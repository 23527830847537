/**
 *
 *
 *
 */
import { lcfirst } from "@util";
import { FM } from '../core/state-manager.ts';
/* ========================= LEVELS ========================================= */
export function getEntityFormLevel( entity ) {
    return FM.getEntityLevel( lcfirst( entity ) );
}
export function getFormLevel( next, current ) {
    const levels = FM.getLevelKeys();
    let curIdx = levels.indexOf( current );
    const getIdx = next === 'parent' ? --curIdx : ++curIdx;
    return levels[ getIdx ];
}
/**
 * Returns the sub form's lvl. If the top form is not the interaction form,
 * the passed form lvl is reduced by one and returned.
 * Note: Especially useful before the sub-form init, otherwise the sub-form level
 * can be looked up using the form entity-name.
 */
export function getSubFormLvl( lvl ) {
    if ( FM.getEntityLevel( 'interaction' ) === 'top' ) return lvl;
    return getFormLevel( 'parent', lvl );
}
