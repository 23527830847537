/*
 * Misc. filter-set utility methods.
 *
 * Export
 *     addSetToFilterStatus
 *     hideSavedMsg
 *     disableFilterSetInputs
 *     enableFilterSetInputs
 *     fillFilterDetailFields
 *     initFilterSetsFeature
 *     resetFilterUi
 *     showSavedMsg
 *
 * TOC
 *     INIT
 *     MISC
 *         SAVED ICON
 *         UPDATE FILTER-FIELDS
 *         UPDATE FILTER-STATUS
 *         RESET & ENABLE/DISABLE UI
 */
import { ui } from '@explore';
import { getSetState, setSetState } from './state-filter-set.js';
import * as actions from './actions-filter-set.js';
/* ============================ INIT ======================================== */
export function initFilterSetsFeature() {
    actions.setFilterSetEventListeners();
    actions.updateFilterSetCombo();
    disableFilterSetInputs();
}
/* ============================ MISC ======================================== */
/* ----------------------- SAVED ICON --------------------------------------- */
export function showSavedMsg() {
    $( '#set-submit-msg' ).fadeTo( 'slow', 1 );
    window.setTimeout( hideSavedMsg, 3000 );
}
export function hideSavedMsg() {
    $( '#set-submit-msg' ).fadeTo( 'slow', 0 );
}
/* ------------------- UPDATE FILTER-FIELDS --------------------------------- */
export function fillFilterDetailFields( name, description ) {
    $( '#filter-set-name + input' ).val( name ).focus();
    $( '.filter-set-details textarea' ).val( description );
}
/* ------------------- UPDATE FILTER-STATUS --------------------------------- */
export function addSetToFilterStatus( set ) {
    set = set || getSetState( 'all' );
    if ( !dataFiltersSaved( set ) ) return;
    const status = $( '#filter-status' ).text();
    $( '#filter-status' ).text( '(SET) '+status );
}
function dataFiltersSaved( filter ) {                                 /*dbug-log*///console.log('-- dataFiltersSaved filter?[%O]', filter);
    const panleFilters = ifSetHasPanelFilters( filter.details );
    const tableFilters = Object.keys( filter.details.table ).length > 0;
    return panleFilters || tableFilters;
}
function ifSetHasPanelFilters( filters ) {
    return Object.keys( filters.direct ).length || Object.keys( filters.rebuild ).length;
}
/* ------------------- RESET & ENABLE/DISABLE UI ---------------------------- */
export function resetFilterUi( newId ) {
    setSetState( 'clear', newId );
    hideSavedMsg();
    clearFilterDetailFields();
    disableFilterSetInputs();
    ui.updateFilterStatusMsg();
}
function clearFilterDetailFields() {
    $( '#filter-set-name + input' ).val( '' );
    $( '.filter-set-details textarea' ).val( '' );
}
export function disableFilterSetInputs() {
    $( '.filter-set-details input, .filter-set-details textarea' ).val( '' );
    $( `.filter-set-details input, .filter-set-details span, #delete-filter,
        .filter-set-details textarea, #save-filter, #apply-filter` )
        .attr( 'disabled', true ).css( 'opacity', '.5' );
    $( '#save-filter' ).html( 'Save' );
}
export function enableFilterSetInputs( create ) {                     /*dbug-log*///console.log('-- enableFilterSetInputs create?[%s]', !!create);
    $( `.filter-set-details input, .filter-set-details span, #save-filter,
        .filter-set-details textarea` ).attr( 'disabled', false ).css( 'opacity', '1' );
    if ( !create ) {
        $( '#delete-filter' ).attr( 'disabled', false ).css( 'opacity', '1' );
        $( '#save-filter' ).html( 'Update' );
        $( '#apply-filter' ).attr( 'disabled', false ).css( 'opacity', '1' );
    } else {
        $( '#save-filter' ).html( 'Save' );
    }
}