/**
 * @desc Returns the next field with a valid ReviewEntry to review.
 *
 * Export
 *     getFirstFieldWithReviewEntry
 *
 * TOC
 *    SEARCH AND PREPARE FIELD
 *        HANDLE SKIPPED
 *        AUTHOR|EDITOR FIELDS
 *        TAXON FIELDS
 *        CHECK FOR REPLACED
 */
import { components, form, state } from '@dataentry';
import { getSelVal } from '@elems/combo';
import { cloneObj, isContributorUser } from '@util';
import { isSkipped } from './review-main.js';

let ReviewEntries;

/** Returns the next valid field to review with its ReviewEntry attached. */
export default function getFirstFieldWithReviewEntry( fLvl, rvwEntries ) {
    ReviewEntries = rvwEntries;
    const fields = state.getDisplayedFields( fLvl );                /*temp-log*///console.log( '+-- getFirstFieldWithReviewEntry fLvl[%s] fields[%O]', fLvl, fields );
    return fields ? findAndPrepareFieldReviewEntry( fields ) : null;
}
function findAndPrepareFieldReviewEntry ( fields ) {                   /*dbug-log*///console.log( '  -- findAndPrepareFieldReviewEntry fields[%O]', cloneObj( fields ) );
    return fields.find( f => ifValidPrepareField( f ) );
}
function ifValidPrepareField ( f ) {                           /*dbug-log*///console.log( '       -- ifValidPrepareField field[%s %O]', f.name, cloneObj( f ) );
    const map = {
        Contributor: ifValidContributor,
        Taxon: ifValidTaxon
    };
    if ( map[ f.entity ] ) return map[ f.entity ]( f );
    if ( !f.review ) return ifValidReplacedReviewEntry( null, f );
    return ifReviewEntryValid( f.review.id, f );
}
/* ====================SEARCH AND PREPARE FIELD ============================= */
/**
 * True if field ReviewEntry is ready for review by the current user. Adds the
 * record to the review-field data to simplify later stages of the data preparation.
 * If the ReviewEntry was skipped previously, the UI is updated as needed.
 */
function ifReviewEntryValid ( id, f, ord = false ) {          /*dbug-log*///console.log( '          -- ifReviewEntryValid field[%O] ord?[%s] rEntry[%s][%O]', f, ord, id, ReviewEntries[ id ] );
    if ( isSkipped( id ) ) return handleSkipped( id, f, ord );
    const isReady = state.isReadyToReview( ReviewEntries[ id ] );
    if ( !isReady ) return ifValidReplacedReviewEntry( id, f, ord );
    f.record = ReviewEntries[ id ];  //Added here to simplify later stages
    if ( ord ) f.ord = ord;
    return true;
}
/* ------------------------- HANDLE SKIPPED --------------------------------- */
function handleSkipped ( id, f, ord ) {                       /*dbug-log*///console.log( '               -- handleSkipped field[%O] ord?[%s] rEntry[%s %O]', cloneObj( f ), ord, id, ReviewEntries[ id ] );
    const hasReplaced = ifValidReplacedReviewEntry( id, f, ord );
    return hasReplaced ? hasReplaced : addOptIfComboEmpty( f, f.review );
}
/** If not handled elsewhere, adds 'skipped' to the field's combo. */
function addOptIfComboEmpty( field, rEntry ) {
    if ( getSelVal( field.combo ) || rEntry.completed ) return;     /*dbug-log*///console.log( '               -- addOptIfComboEmpty field[%O] rEntry[%O]', cloneObj( field ), rEntry );
    components.setReviewStageOption( field.combo, 'skipped' );
}
/* ----------------------- AUTHOR|EDITOR FIELDS ----------------------------- */
/**
 * Contributors are a multi-select field with a value object keyed by their
 * ordinal. The first valid ReviewEntry will be returned.
 * @return {number} The ordinal of the next valid Contributor ReviewEntry.
 */
function ifValidContributor ( f ) {                    /*dbug-log*///console.log( '               -- ifValidContributor field[%O]', cloneObj( f ) );
    let next = findAndPrepareValidContrib( f.review );          /*dbug-log*///console.log( '                      -- next?[%s]', next );
    if ( !next ) next = findAndPrepareValidContrib( f.replacedReview );
    return next;

    function findAndPrepareValidContrib( contribs ) {            /*dbug-log*///console.log( '                   -- findAndPrepareValidContrib contribs[%O]', contribs );
        if ( !contribs ) return;
        return Object.keys( contribs ).find( ord => ifMultiFieldReady( ord, contribs[ ord ].id ) )
    }
    function ifMultiFieldReady ( ord, id ) {                        /*dbug-log*///console.log( '                   -- ifMultiFieldReady id[%s] ord[%s] field[%O]', id, ord, f );
        return ifReviewEntryValid( id, f, ord );
    }
}
/* ------------------------- TAXON FIELDS ----------------------------------- */
/**
 * Contributor: True if any parent is ready for review.
 * Manager: True if taxonomy has no rejected or returned parent-taxa.
 */
function ifValidTaxon( f ) {
    const ifTaxonomyCompatible = isContributorUser() ?
        ifTaxonReadyForContributorReview : ifTaxonReadyForManagerReview;
    return ifTaxonomyCompatible( f );
}
/** Manager: True if taxonomy has no rejected or returned parent-taxa. */
function ifTaxonReadyForManagerReview( f ) {
    if ( !f.review || isNotCompatibleWithReview( f.review ) ) return;
    return ifReviewEntryValid( f.review.id, f );

    function isNotCompatibleWithReview( rEntry ) {
        const stage = state.getReviewStage( null, rEntry );          /*dbug-log*///console.log( '               -- isNotCompatibleWithReview stage[%s] rEntry[%O]', stage, rEntry );
        if ( rEntry.entityId ) return false;  // Has approved data.
        if ( stage !== 'Pending' ) return true; // Rejected or returned.
        const pParent = getParentReviewEntry( rEntry );
        if ( !pParent ) return false;
        return isNotCompatibleWithReview( pParent );
    }
}
/**
 * Contributor: True if any parent is ready for review. That parent record is
 * added to the field to be reviewed next.
 */
function ifTaxonReadyForContributorReview ( f ) {             /*dbug-log*///console.log( '               -- ifTaxonReadyForContributorReview field[%s %O]', f.name, f );
    let next = false;
    checkTaxonomyForNextRecord();                                   /*dbug-log*///console.log( '                   -- ifTaxonReadyForContributorReview field[%O] next?[%O]', f, next );
    return next || setTaxonFieldCombo( f );

    function checkTaxonomyForNextRecord() {
        if ( f.review ) findHighestTaxonReadyForReview( f.review );
        if ( next || !f.replacedReview ) return;
        findHighestTaxonReadyForReview( ReviewEntries[ f.replacedReview.id ] );
    }

    function findHighestTaxonReadyForReview ( rEntry ) {              /*dbug-log*///console.log( '                   -- findHighestTaxonReadyForReview rEntry[%O]', rEntry );
        const pParent = getParentReviewEntry( rEntry );
        if ( pParent ) findHighestTaxonReadyForReview( pParent );   /*dbug-log*///console.log( '                        -- found?[%O] rEntry[%O]', next, rEntry );
        if ( next ) return;
        next = ifReviewEntryValid( rEntry.id, f );
    }
}
function getParentReviewEntry( rEntry ) {
    const pParent = rEntry.form.fields.Parent.review;        /*dbug-log*///console.log( '                       -- getParentReviewEntry rEntry[%O] pParent?[%O]', rEntry, pParent );
    return pParent ? ReviewEntries[ pParent.id ] : false;
}
/**
 * Resets quarantined or approved value.
 * Note: Edit-form field-change-event is silenced to skip redundant parent validation.
 */
function setTaxonFieldCombo( f ) {
    form.buildOptAndUpdateCombo( f.combo, f.value, f.combo === 'Parent' );
}
/* ----------------------- CHECK FOR REPLACED ------------------------------- */
function ifValidReplacedReviewEntry ( id, f, ord ) {                /*dbug-log*///console.log( '          -- ifValidReplacedReviewEntry field[%O] ord?[%s]', cloneObj( f ), ord );
    if ( !f.replacedReview?.id || !f.replacedReview?.[ ord ]?.id ) return;
    const replacedId = f.replacedReview[ ord ].id;
    if ( replacedId == id ) return; // Already processed
    return ifReviewEntryValid( replacedId, f, ord );
}