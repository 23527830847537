import { DetailEntityFormConfig,ModelFormFields } from '../types';

/* ============================ CONFIG ====================================== */
/**
 * Publisher form configuration.
 */
export default function( entity: string ): DetailEntityFormConfig {
	return {
        core: 'Source',
        records: {
            edit: [ 'source', 'publisher' ]
        },
        fields: getFieldConfig(),
        name: entity,
        layouts: {
            all: [
                [ 'DisplayName' ],
                [ 'City' ],
                [ 'Country' ],
                [ 'Website' ],
                [ 'Description' ]
            ],
            simple:[
                [ 'DisplayName' ],
                [ 'City' ],
                [ 'Country' ],
            ],
        }
    };
}
function getFieldConfig(): ModelFormFields {
    return {
        City: {
            name: 'City',
            required: true,
            server: {
                prop: {
                    detail: 'city'
                },
            },
            type: 'text'
        },
        Description: {
            server: {
                entity: {
                    detail: 'publisher'
                },
                prop: {
                    detail: 'description'
                },
            },
        },
        DisplayName: {
            label: 'Name',
            required: true,
            server: {
                prep: {
                    setCoreAndDetail: []
                }
            },
        },
        Country: {
            name: 'Country',
            required: true,
            server: {
                prop: {
                    detail: 'country'
                },
            },
            type: 'text'
        },
        SourceType: {
            value: 'Publisher'
        }
    };
}