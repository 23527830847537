/**
 * Utility methods used during the preparation, validation, and formatting of data
 * before being pushed to the server.
 *
 * Export
 *     getEntityProp
 *     handleFailuresAndReturnPreparedData
 *     prepProcessMemory
 *     setEntityProp
 *     trackFailure
 *
 * TOC
 *     ENTITY DATA
 *         INIT
 *         ACCESS/MODIFY
 *         RETURN
 *     TRACK FAILURES
 *         ALERT
 */
import { alertIssue, cloneObj, lcfirst } from "@util";
import { alert } from '@dataentry';

/** Data prepared and formatted to be pushed to the server. */
let entityData = {};
/* ========================= ENTITY DATA ==================================== */
export function prepProcessMemory( config ) {
    if ( config.editing ) { entityData.ids = config.editing; }
    initEntityDataObj( config );
}
/* ------------------------- INIT ------------------------------------------- */
/** Data prepped and formatted to be pushed to the server for entry handling. */
function initEntityDataObj( c ) {                                     /*dbug-log*///console.log('   --initEntityDataObj c[%O]', c);
    const entity = c.core ? c.core : c.name;
    initDataObj( 'core', entity );
    if ( !c.core ) return;
    initDataObj( 'detail', c.name );
}
function initDataObj( dKey, name ) {
    if ( entityData[ dKey ] ) return;
    entityData[ dKey ] = { flat: {}, rel:{} };
    entityData[ dKey+'Entity' ] = lcfirst( name );
}
/* ------------------------- ACCESS/MODIFY ---------------------------------- */
/**
 * Sets formatted field/property values in the server-data object.
 * @param {str} g   Data type: rel(ation) or flat
 * @param {str} p   Entity property
 * @param {msc} v   Data value
 * @param {str} k   Entity type: core or detail
 */
export function setEntityProp( g, p, v, k = 'core' ) {
    if ( !entityData[ k ] ) initDataObj( 'detail', 'GeoJson' );
    if ( ifNullValueInCreateForm( v ) ) return;                     /*dbug-log*///console.log('           --setEntityProp [%s][%s][%s] = [%O]', k, g, p, v);
    const value = v === '' ? null : v;
    entityData[ k ][ g ][ p ] = value;
}
function ifNullValueInCreateForm( v ) {
    return !v && !entityData.ids;
}
/**
 * Gets formatted field/property values from the server-data object.
 * @param {str} g   Data type: rel(ation) or flat
 * @param {str} p   Entity property
 * @param {str} k   Entity type: core or detail
 */
export function getEntityProp( g, p, k = 'core' ) {
    return entityData[ k ][ g ][ p ];
}
/* ------------------------- RETURN ----------------------------------------- */
export function handleFailuresAndReturnPreparedData( fLvl ) {
    alertIfFailures( fLvl );
    return returnPreparedData();
}
function returnPreparedData() {
    const data = cloneObj( entityData );
    entityData = {};
    return data;
}
/* ====================== TRACK FAILUTES ==================================== */
export function trackFailure ( prop, value ) {                       /*perm-log*/console.log( '--trackOutputTransformFailure  prop[%s] val[%O]', prop, value );
    if ( !entityData.fails ) { entityData.fails = {}; }
    entityData.fails[ prop ] = value;
}
/* --------------------------- ALERT ---------------------------------------- */
/** Handles field-failure alerts. */
function alertIfFailures( fLvl ) {
    if ( !entityData.fails ) return;                              /*dbug-log*///console.log('--alertIfFailures allFails[%O]', cloneObj(entityData.fails));
    alertAndClearSimpleFailures( fLvl, entityData.fails );
    if ( entityData.fails === 'handled' ) return;                 /*perm-log*/console.log( '   !! unhandled failures[%O]', cloneObj( entityData.fails ) );
    alertIssue( 'dataPrepFail', JSON.stringify( entityData.fails ) );
}
function alertAndClearSimpleFailures( fLvl, fails ) {
    const alerts = getFieldAlerts( fLvl );
    const unhandled = Object.keys( fails ).map( alertFailure ).filter( f=>f );
    entityData.fails = unhandled.length ? unhandled : 'handled';

    function alertFailure( field ) {
        return alerts[ field ] ? alerts[ field ]() : field;
    }
}
function getFieldAlerts( fLvl ) {
    return {
        Elevation: () => showFormFieldAlert( 'ElevationMax', 'invalidRange', fLvl ),
        Pages: () => showFormFieldAlert( 'Pages', 'invalidRange', fLvl ),
        InteractionTags: () => showFormFieldAlert( 'InteractionTags', 'needsTypeTag', fLvl )
    };
}
function showFormFieldAlert( field, tag, fLvl ) {
    alert.showFormValAlert( field, tag, fLvl );
}