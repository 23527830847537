/**
 * Handles the destruction of entry and review state and memory.
 *
 *
 */
import { lcfirst } from "@util";
import { map } from '@explore';
import { submit } from '@dataentry';
import { clearEntityFormMemory } from '@dataentry/form/init';
import { FM } from '../state-manager.ts';
/* ========================= ENTRY ========================================== */
export function removeFormGroupState( lvl, clearAll ) {             /*dbug-log*///console.log( '   --removeFormGroupState lvl[%s] clearAll?[%s]', lvl, clearAll );
    if ( lvl === 'top' ) return clearFormState( clearAll );
    const FS = FM.getLevelState( lvl );                             /*dbug-log*///console.log( '        --FS[%O]', FS );
    clearEntityFormMemory( lcfirst( FS.name ) );
    FM.clearLevelState( lvl );
}
function clearFormState ( clearAll ) {
    map.clearMemory();
    handleReviewFormClear();
    if ( clearAll ) clearAll();
    FM.clear();
}
/* ========================= REVIEW ========================================= */
/** Handle unlocking open ReviewEntry records, if the review was not submitted. */
export function handleReviewFormClear () {
    const rootForm = FM.getLevelState( 'top' );
    if ( !rootForm ) return; //form closed
    const pData = rootForm.review;
    if ( rootForm.submitted || !pData ) return;
    submit.pushReviewEntry( pData.id, 'Skip', null, true );
}