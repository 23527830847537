/**
 * If another existing location captures all entered data, prompts editor to select.
 *
 * Export
 *     checkIfLocExists
 *
 * TOC
 *     CHECK FOR UNIQUE DATA
 *     SELECT EXISTING LOCATION
 */
import { getSelTxt } from '@elems/combo';
import { alert, state, submit } from '@dataentry';

export default function checkIfLocExists ( fLvl ) {
    const fields = state.getFormState( 'sub', 'fields' );           /*dbug-log*///console.log('-- checkIfLocExists [%s]fields[%O]', fLvl, fields);
    if ( ifUniqueLocData( fields ) ) { return submit.submitForm( 'sub' ); }
    setDuplicateEntityData( fLvl, fields );
    alert.formSubmitError( 'sub', 'duplicateEntity' );
}
/* ================= CHECK FOR UNIQUE DATA ================================== */
/**
 * Checks to ensure there is specific data for the new location. Otherwise, the
 * existing location is found and the editor is alerted to select it instead.
 */
function ifUniqueLocData ( fields ) {
    return Object.values( fields ).find( isSpecificData );
}
function isSpecificData ( field ) {
    if ( !field.shown ) { return false; }
    const habData = [ 'DisplayName', 'HabitatType', 'Country' ];    /*dbug-log*///console.log('-- isSpecificData? field[%s]?[%s]', field.name, (habData.indexOf(field.name) === -1 ? !!field.value : false));
    return habData.indexOf( field.name ) === -1 ? !!field.value : false;
}
/* ================= SELECT EXISTING LOCATION =============================== */
function setDuplicateEntityData ( fLvl, fields ) {
    const name = buildExistingLocName();
    const loc = getExistngLocRcrd( name, fields.Country.value );
    state.setFormState( fLvl, { misc: { duplicateEntity: loc } } );
}
function buildExistingLocName() {
    const name = getSelTxt( 'Country' );
    const habName = getSelTxt( 'HabitatType' );               /*dbug-log*///console.log('-- buildExistingLocName parent[%s] habitat?[%s]', name, habName);
    return habName ? ( name + '-' + habName ) : name;
}
function getExistngLocRcrd ( name, id ) {
    const locs = state.getRecords( 'location' );
    const pLoc = locs[ id ];                                        /*dbug-log*///console.log('-- getExistngLocRcrd name[%s] pLoc[%O]', name, pLoc);
    return pLoc.displayName === name ? pLoc : getLocHabRcrd( name, pLoc, locs );
}
function getLocHabRcrd ( name, pLoc, locs ) {
    const id = pLoc.children.find( c => locs[ c ].displayName == name );
    return locs[ id ];
}