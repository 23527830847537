/**
 * Builds and manages radios that select records in the data-table: all, some, by id.
 *
 * Export
 *     newTableRowSelect
 *
 * TOC
 *     CLASS DEF
 *         EN(DIS)ABLE
 *         MODE
 *     INIT UI
 *         SELECTION RADIOS
 *         SET EVENTS
 *     SELECT-MODE CHANGE
 *         SYNC UI
 *         SHOW INSTRUCTIONS
 *     ROW SELECTION
 *     GET SELECTED-DATA
 *         IDS
 *         INTERACTIONS
 *         NONE-SELECTED ALERT
 */
import { table } from '@explore';
import { getElem } from '@elems';

export function newTableRowSelect ( type ) {
    return new TableRowSelect( type );
}
/* ===================== CLASS DEF ========================================== */
class TableRowSelect {
    constructor ( type ) {                                             /*dbug-log*///console.log('-- [TableRowSelect] type[%s]', type)
        this.defaultMode = 'some';
        this.type = type;
        this.cntnr = `#${ type }-select-records`;
        this.mode = this.defaultMode;
        initTableRowSelectUi.bind( this )();
    }
    /*------------------- EN(DIS)ABLE --------------------------------------- */
    disable () {
        this.clearMode();
    }
    toggleUi ( enable = true ) {                                       /*dbug-log*///console.log('-- [TableRowSelect] toggleUi enable?[%s]', enable)
        const opac = enable ? 1 : .5;
        $( `#${ this.type }-select-records, #${ this.type }-radios input,
            #${ this.type }-radios label, #${ this.type }-radios button` )
            .attr( { 'disabled': !enable } ).css( { 'opacity': opac } );
    }
    /* --------------------- MODE ------------------------------------------- */
    getMode () {
        return this.mode;
    }
    /**
     * Select-mode: all, some, or id. NULL clears mode-ui
     * @param {String} mode
     */
    setMode ( mode ) {                                                 /*dbug-log*///console.log('-- [TableRowSelect] setMode mode[%s]', mode, arguments)
        this.mode = mode ? mode : this.defaultMode;
        this.toggleUi( !!mode );
        syncModeUi.bind( this )( mode );
        updateTableRowSelection.bind( this )( mode );
    }
    clearMode () {                                                   /*dbug-log*///console.log('-- [TableRowSelect] clearMode')
        this.setMode( null );
    }
    /* --------------------- SELECTED --------------------------------------- */
    getSelectedInteractions () {
        return getInteractions.bind( this )();
    }
}
/* ====================== INIT UI =========================================== */
/**
 * Builds radio elems to handle table-record selection.
 * Note: 'this' is set to TableRowSelect.
 */
function initTableRowSelectUi () {                                   /*dbug-log*///console.log('-- initTableRowSelectUi this[%O]', this);
    const elems = buildSelectionElems( this.type );
    $( this.cntnr ).append( elems );
    setElemEvents.bind( this )();
}
function buildSelectionElems ( type ) {
    const cntnr = buildSelectionContainer( type );
    $( cntnr ).append( buildSelectionRadioElems( type ) );
    $( cntnr ).append( buildSelectIdElem( type ) );
    $( cntnr ).append( buildModInfoElem( type ) );
    return cntnr;
}
function buildSelectionContainer ( type ) {
    const attr = { class: 'flex-col', id: type + '-row-select' };
    return getElem( 'div', attr );
}
function buildSelectIdElem ( type ) {
    const attr = {
        class: 'field-input opac0 input-border', id: `${ type }-select-ids`,
        placeholder: 'Enter IDs', type: 'text'
    };
    return getElem( 'input', attr );
}
function buildModInfoElem ( type ) {
    const attr = { id: `${ type }-select-info` };
    return getElem( 'span', attr );
}
/* -------------------- SELECTION RADIOS ------------------------------------ */
function buildSelectionRadioElems ( type ) {
    const cntnr = buildRadioContainer( type );
    $( cntnr ).append( buildRadios( type ) );
    $( cntnr ).append( getUnselectButton( type ) );                       /*dbug-log*///console.log('-- buildSelectionRadioElems cntnr[%O]', cntnr);
    return cntnr;
}
function buildRadioContainer ( type ) {
    const attr = { class: 'flex-row', id: type + '-radios' };
    return getElem( 'div', attr );
}
function buildRadios ( type ) {
    const elems = [];
    const map = {
        all: 'All Shown',
        some: 'Select Rows',
        id: 'Enter IDs'
    };
    Object.keys( map ).forEach( k => elems.push( ...buildRadioElems( k, map[ k ], type ) ) );
    return elems;
}
function buildRadioElems ( mod, text, type ) {
    return [ getRadioInput( mod, type ), getRadioLabel( mod, type, text ) ];
}
function getRadioInput ( mod, type ) {
    const attr = { id: `${ type }-${ mod }-radio`, name: type + '-radios', type: 'radio' };
    return getElem( 'input', attr );
}
function getRadioLabel ( mod, type, text ) {
    const attr = { for: `${ type }-${ mod }-radio`, text: text };
    return getElem( 'label', attr );
}
function getUnselectButton ( type ) {
    const attr = { class: 'tbl-bttn', id: type + '-unsel-rows', text: 'Unselect All' };
    return getElem( 'button', attr );
}
/* ------------------------- SET EVENTS ------------------------------------- */
/**
 * Sets events for this fetaure.
 * Note: 'this' is set to TableRowSelect.
 */
function setElemEvents () {
    $( `#${ this.type }-unsel-rows` ).on( 'click', deselectRows.bind( this ) );
    $( `input[name="${ this.type }-radios"]` ).on( 'change', onSelectModeChange.bind( this ) );
}
/* ===================== SELECT-MODE CHANGE ================================= */
/**
 * Row select-mode change handler.
 * Note: 'this' is set to TableRowSelect.
 */
function onSelectModeChange ( e ) {
    const mode = e.currentTarget.id.split( '-' )[ 1 ];
    this.setMode( mode );
}
/* -------------------------- SYNC UI --------------------------------------- */
/**
 * Handles basic UI update on mode change - radios, instructions, inputs.
 * Note: 'this' is set to TableRowSelect.
 */
function syncModeUi ( newMode ) {
    syncSelectModeRadios( this.type, this.mode, newMode );
    toggleInstructions( this.type, newMode );
    toggleIdInput( this.type, newMode === 'id' );
}
/** Syncs the mode-radios and returns the selected mode. */
function syncSelectModeRadios ( type, classMode, enable = true ) {
    const selected = getSelectedRadioVal( type );                     /*dbug-log*///console.log('--syncSelectModeRadios classMode[%s] selectedMode?[%s] ', classMode, selected);
    const mode = classMode === 'some' && selected ? selected : classMode;
    $( `#${ type }-${ mode }-radio` ).prop( 'checked', enable );
    return mode;
}
function getSelectedRadioVal ( type ) {
    const map = getRadioState( type );
    return Object.keys( map ).find( k => map[ k ] );
}
function getRadioState ( type ) {
    return {
        all: $( `#${ type }-all-radio` ).prop( 'checked' ),
        id: $( `#${ type }-id-radio` ).prop( 'checked' ),
        some: $( `#${ type }-some-radio` ).prop( 'checked' ),
    };
}
function toggleIdInput ( type, show = true ) {                         /*dbug-log*///console.log('-- toggleIdInput type[%s] show?[%s]', type, show);
    const opac = show ? 1 : 0;
    const $elem = $( `#${ type }-select-ids` );
    if ( !$elem.is( ":visible" ) && !show ) return;
    $elem.fadeTo( 'fast', opac, updateIdInput );

    function updateIdInput () {
        const display = show ? 'block' : 'none';
        $elem.css( { display: display } ).attr( 'disabled', !show );
    }
}
/* _____________________ SHOW INSTRUCTIONS __________________________________ */
function toggleInstructions ( type, mode ) {                           /*dbug-log*///console.log('--toggleInstructions type[%s] mode[%s]', type, mode)
    const opac = !mode ? 0 : 1;
    const $elem = $( `#${ type }-select-info` );
    addInfoMsgAndUpdateTableSelection( $elem, type, mode );
    $elem.fadeTo( 'slow', opac );
}
function addInfoMsgAndUpdateTableSelection ( $elem, type, mode ) {
    const info = getRowSelectInfo( mode );
    $elem[ 0 ].innerHTML = info;
}
function getRowSelectInfo ( mode ) {
    const map = {
        all: 'Click "Save List" to add/remove all *interactions in the table. *Interaction rows are the colored base-level rows.',
        id: 'Enter the ID numbers, separated by commas, in the field above. Then, click "Save List".',
        some: 'Click on an *interaction row to select. Hold ctrl/cmd to select multiple rows. Hold shift and click a 2nd row to select a range. Click "Save List" to add/remove selection. *Interaction rows are the colored base-level rows.',
    };
    return !mode ? '' : map[ mode ];
}
/* ---------------------- UPDATE TABLE -------------------------------------- */
/**
 * Handles table updates on select-mode change,
 * Note: 'this' is set to TableRowSelect.
 */
function updateTableRowSelection ( newMode ) {
    const map = {
        all: selectAllInteractionRows,
        id: handleRowSelection.bind( null, false )
    };
    if ( !map[ newMode ] ) return;
    map[ newMode ]();
}
/* ======================== ROW SELECTION =================================== */
/** Selects or deselects all rows currently displayed in the explore table. */
function handleRowSelection ( select = true ) {
    const rows = table.getState( 'api' ).getModel().rowsToDisplay;
    rows.forEach( r => selectInteractions( select, r ) );
}
/** Note: An interaction-row is identified by its 'interactionType'. */
function selectInteractions ( select, rowNode ) {
    if ( rowNode.data.interactionType !== undefined ) { rowNode.setSelected( select ); }
}
/**
 * Deselects all rows in the explore table and disables the 'unselect' button.
 * Note: 'this' is set to TableRowSelect.
 */
function deselectRows () {
    handleRowSelection( false );
    $( `#${ this.type }-unsel-rows` ).attr( { 'disabled': true } ).fadeTo( 'slow', .6 );
}
/* ======================== GET SELECTED-DATA =============================== */
/* --------------------------- IDS ------------------------------------------ */
/**
 * Returns an array of all comma-separated IDs from the ID input.
 * Note: 'this' is set to TableRowSelect.
 * @param  {obj} tblApi Current ag-grid API (NOT USED HERE)
 * @return {ary}        IDs from the ID input.
 */
function getIds ( tblApi ) {
    const inputVal = $( `#${ this.type }-select-ids` ).val();
    return inputVal.split( ',' ).map( i => parseInt( i.trim() ) );
}
/* ----------------------- INTERACTIONS ------------------------------------- */
/**
 * Returns the selected interaction ids.
 * Note: 'this' is set to TableRowSelect.
 */
function getInteractions () {
    const tblApi = table.getState( 'api' );
    const map = {
        all: getAllIntsInTable,
        id: getIds,
        some: getSelectedIntSet.bind( this )
    };
    const selected = map[ this.mode ].bind( this )( tblApi );
    return selected.length ? selected : showNoneSelectedAlert( this.type );
}
/**
 * Returns an array of all interaction records (ids) in the current table.
 * Note: 'this' is set to TableRowSelect.
 * @param  {obj} tblApi Current ag-grid API
 * @return {ary}        All interaction records in the current table.
 */
function getAllIntsInTable ( tblApi ) {
    selectAllInteractionRows();
    return getSelectedIntSet( tblApi );
}
function selectAllInteractionRows () {
    table.expandAllTableRows();
    handleRowSelection();
}
/**
 * Returns an array of all selected interaction-records (ids) in the current table.
 * Note: 'this' is set to TableRowSelect.
 * @param  {obj} tblApi Current ag-grid API
 * @return {ary}        All selected interaction-records (ids) in the current table.
 */
function getSelectedIntSet ( tblApi ) {
    return tblApi.getSelectedNodes().map( r => { return r.data.id; } );
}
/* ------------------- NONE-SELECTED ALERT ---------------------------------- */
function showNoneSelectedAlert ( type ) {
    const attr = { id: `${ type }_alert`, text: 'None selected.' };
    const alert = getElem( 'div', attr );
    $( `#${ type }-radios` ).before( alert );
    window.setTimeout( removeAlert, 2000 );

    function removeAlert () {
        const $alert = $( `#${ type }_alert` );
        $alert.fadeTo( 'slow', 0, () => $alert.remove() );
    }
}