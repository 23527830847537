/**
 * Handles showing the interactions displayed in the data-table on the map.
 *
 * EXPORT
 *     showIntsOnMap
 *
 * TOC
 *     LOAD MAP WITH TABLE DATA
 *         ADD COUNTS TO LEGEND
 *         ADD INTERACTION MARKERS
 *     ZOOM IF ALL IN SAME REGION
 */
import { table } from '@explore';
/* MAP */
import { addMarkersToMap, getMapRecords, setMapView } from '@explore/map/manager.js';
import * as mapUtil from '@explore/map/util';
import { fillIntCntLegend, initExploreMapView } from '../on-init.js';
import buildMapDataObj from './map-data.js';

export function showIntsOnMap () {                                  /*dbug-log*///console.log( '       +--showIntsOnMap' );
    $( '#explore-table' ).fadeTo( 'fast', 0.3, loadMapWithTableData );
}
function loadMapWithTableData() {
    initExploreMapView( displayIntsOnMap, 'table-map' );
}
/* =================== LOAD MAP WITH TABLE DATA ============================= */
/** Shows the interactions displayed in the data-table on the map. */
function displayIntsOnMap () {
    const { tableData, focus } = getDataToDisplayInts();             /*dbug-log*///console.log( '----------- displayIntsOnMap focus[%s] tableData[%O]', focus, tableData );
    addIntCntsToLegend( tableData );
    addIntMarkersToMap( focus, tableData );
    zoomIfAllInSameRegion( tableData );
}
function getDataToDisplayInts() {
    const { curFocus, data } = table.getState( [ 'curFocus', 'data' ] );
    const tableData = buildMapDataObj( data.rcrds, getMapRecords() );
    return { tableData, focus: curFocus };
}
/* -------------------- ADD COUNTS TO LEGEND -------------------------------- */
function addIntCntsToLegend ( data ) {
    let shwn = 0, notShwn = 0;
    Object.keys( data ).forEach( trackIntCnts );
    fillIntCntLegend( shwn, notShwn );

    function trackIntCnts ( geoId ) {
        if ( geoId === 'none' ) {
            notShwn += data[ geoId ].ttl;
        } else { shwn += data[ geoId ].ttl; }
    }
}
/* -------------------- ADD INTERACTION MARKERS ----------------------------- */
function addIntMarkersToMap ( focus, data ) {                       /*dbug-log*///console.log( '      --addMarkersToMap. data = %O', data );
    const markers = buildAllIntMarkers( focus, data );
    addMarkersToMap( markers );
}
function buildAllIntMarkers( focus, data ) {
    const markers = [];
    for ( let geoId in data ) {
        if ( geoId === 'none' ) continue;
        markers.push( buildIntMarker( focus, geoId, data[ geoId ] ) );
    }
    return markers;
}
function buildIntMarker ( focus, geoId, data ) {
    const latlng = getCoords( geoId, data );
    const params = {
        focus: focus, intData: data, latlng: latlng, rcrds: getMapRecords()
    };
    return data.ttl > 1
        ? new mapUtil.IntCluster( data.ttl, params )
        : new mapUtil.IntMarker( params );
}
function getCoords ( geoId, intData ) {
    const geoJson = getMapRecords( 'geoJson', geoId );
    return mapUtil.getLatLngObj( intData.locs[ 0 ], geoJson );
}
/* ===================== ZOOM IF ALL IN SAME REGION ========================= */
function zoomIfAllInSameRegion ( tableData ) {
    const { region, coords } = getSharedRegionData( tableData );
    if ( region ) zoomToSharedRegion( coords );
}
function getSharedRegionData( tableData ) {
    let data = { region: '', coords: '' };

    for ( let geoId in tableData ) {
        if ( data.region === false ) return;
        if ( geoId === 'none' ) continue;
        data = getRegionData( geoId, tableData[ geoId ] );
    }

    return data;
}
function getRegionData ( geoId, geoData ) {
    let coords;
    let region;

    geoData.locs.forEach( loc => {
        if ( !coords ) coords = mapUtil.getCenterCoords( loc, geoId );
        const regionName = getRegionName( loc );
        region = regionName == region || !region ? regionName : false;
    } );

    return { region, coords };
}
function getRegionName ( loc ) {
    return loc.region ? loc.region.displayName : loc.displayName;
}
function zoomToSharedRegion ( coords ) {
    setMapView( coords, 3 );
}