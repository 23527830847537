/**
 * Focuses on the field's pin-checkbox (which will persist the value through the
 * form's reset after submitting) and enables the submit button if the form is valid.
 * After the interaction form is submitted and reset, the submit button is disabled to
 * eliminate accidentally creating duplicate interactions. As soon as any change
 * happens in the form, the success message is removed and the 'unchanged' flag removed.
 */
import { ifFormValidClearAlertsAndEnableSubmit, toggleFormStatusMsg } from '@dataentry/components';
import { getFormState, isEditForm, setFormState } from '@dataentry/state';

export function focusPinAndEnableSubmitIfFormValid( field ) {
    checkIntFieldsAndEnableSubmit();
    if ( isEditForm( 'top' ) ) return;
    $( '#'+field+'_pin' ).focus();
}
function checkIntFieldsAndEnableSubmit() {                          /*dbug-log*///console.log('--checkIntFieldsAndEnableSubmit')
    ifFormValidClearAlertsAndEnableSubmit( 'top' );
    resetIfFormWaitingOnChanges();
}
function resetIfFormWaitingOnChanges() {                            /*dbug-log*///console.log('--resetIfFormWaitingOnChanges')
    if ( !getFormState( 'top', 'unchanged' ) ) return;        /*dbug-log*///console.log('--resetting')
    toggleFormStatusMsg( false );
    setFormState( 'top', { unchanged: false } );
}