/**
 * Finalizes the citation form UI during init and when rebuilding the form.
 */
import * as combo from '@elems/combo';
import { PublicationType } from '@types';
import { isOptionObject, promiseToTaskEither } from '@util';
import { components, model, state } from '@dataentry';
import { onCitationFieldChange } from '../../util/util-main';
import { onTypeChangeUpdateFieldStateAndUi } from '../sub-type/field-updates';
/* FP-TS */
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
/* ======================== FINISH INIT ===================================== */
export function finishCitationFormInit (
    fLvl: model.FormGroup,
    action?: model.FormAction
): TE.TaskEither<string, void> {                                     /*dbug-log*///console.log( '           --finishCitationFormInit status[%s]', status );
    return pipe(
        fillCitationTypeAndFinishFieldInit( fLvl, action ),
        promiseToTaskEither
    );
}
function fillCitationTypeAndFinishFieldInit(
    fLvl: model.FormGroup,
    action?: model.FormAction
): Promise<void> {
    return fillCitationTypeCombo( fLvl )
        .then( () => finishFieldInit( fLvl, action ) );
}
/* --------------------------- CITATION TYPE -------------------------------- */
/**
 * Finds the publication-type for the selected publication, gets the valid
 * citation-types for that publication-type, and fills the citation-type combo.
 * Selects the current citation-type and disables the combo if only one available.
 * todo: refactor to have this run only once during the form init, rather than during rebuilds.
 */
function fillCitationTypeCombo( fLvl: model.FormGroup ): Promise<void> {
    const pubType = getFormPublicationType( fLvl );
    return combo.getCitationTypeOptsForPublicationType( pubType )
        .then( opts => combo.replaceSelOpts( 'CitationType', opts ) )
        .then ( () => selectFormCitationType( fLvl ) )
        .then( disableCitationTypeFieldIfOnlyOneTypeAvailable );
    }
function getFormPublicationType ( fLvl: model.FormGroup ): PublicationType {
    const id = state.getFieldValue( fLvl, 'ParentSource' );
    const pubSrc = state.getRecords( 'source', id );
    const pub = state.getRecords( 'publication', pubSrc.publication );
    return pub.publicationType.displayName;
}
function selectFormCitationType( fLvl: model.FormGroup ) {
    const value = state.getFieldValue( fLvl, 'CitationType' );
    const id = isOptionObject( value ) ? value.value : value;       /*dbug-log*///console.log( '           --selectFormCitationType id[%s]', id );
    combo.setSelVal( 'CitationType', id, 'silent' );
}
function disableCitationTypeFieldIfOnlyOneTypeAvailable() {
    const typeCnt = combo.getOptionTotal( 'CitationType' );
    if ( typeCnt === 1 ) combo.enableCombobox( 'CitationType', false );
}
/* --------------------------- FINISH INIT ---------------------------------- */
function finishFieldInit( fLvl: model.FormGroup, action?: model.FormAction ): void {
    components.focusField( fLvl, 'Abstract' );
    disableFields( action );
}
function disableFields( action?: model.FormAction ) {
    $( '#Description_f textarea' ).attr( 'disabled', 'true' );
    if ( action === 'create' ) combo.enableCombobox( 'Publication', false );
}
/* ======================= FINISH REBUILD =================================== */
export function finishFieldLoad ( fLvl: model.FormGroup, isRebuild = true ) {
    return fillCitationTypeCombo( fLvl )
        .then( () => onTypeChangeUpdateFieldStateAndUi( state.getFormState( fLvl ), isRebuild ) )
        .then( () => onCitationFieldChange( fLvl ) );
}

export function finishFieldLoadTE( fLvl: model.FormGroup, isRebuild = true ): TE.TaskEither<string, void> {
    return pipe(
        fLvl,
        state.getFormState,
        FS => onTypeChangeUpdateFieldStateAndUi( FS, isRebuild ),
        promiseToTaskEither,
        TE.map( () => onCitationFieldChange( fLvl ) )
    );
}