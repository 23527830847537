/**
 * Updates and rebuilds the form-model.
 *
 * TOC
 *     ON FORM-MODEL CHANGE
 *         FORM-TYPE CHANGED
 *         FIELD-VIEW CHANGED
 *     REBUILD FORM-MODEL
 *         RESET VOLATILE PROPS
 */
import { cloneObj } from '@util';
import { state } from '@dataentry';
import { getConfigAction, getConfigModel } from './init-model.js';
import { mergeFieldModels } from './merge-models.js';
import { prepareLayoutModel } from './prepare-layout.js';
/* ====================== ON FORM-MODEL CHANGE ============================== */
/* ------------------------ FORM-TYPE CHANGED ------------------------------- */
/**
 * On entity-type change (eg, publication: book to journal, taxon-select: bat to worm),
 * the layout is updated to show the fields in the updated model.
 */
export function onLayoutChangeUpdateModel( fLvl ) {
    const FS = state.getFormState( fLvl );                          /*dbug-log*///console.log('--onLayoutChangeUpdateModel FS[%O]', FS)
    resetModelDefaults( FS );
    return updateModel( FS );
}
/* ----------------------- FIELD-VIEW CHANGED ------------------------------- */
/** Toggles between showing a simple set of fields and all available. */
export function onToggleFieldsUpdateModel( fLvl ) {
    const FS = state.getFormState( fLvl );
    FS.display = FS.display === 'all' ? 'simple' : 'all';
    resetModelDefaults( FS );
    updateModel( FS );
}
/* ====================== REBUILD FORM-MODEL ================================ */
function updateModel( FS ) {                                        /*dbug-log*///console.log( '   --updateModel[%s] before[%O] current[%O]', FS.name, cloneObj( FS ), FS );
    const mergeModel = getModel( FS );
    mergeFieldModels( FS.fields, mergeModel.fields );
    updateLayout( FS, mergeModel.layouts );
    state.replaceFormState( FS );
    return FS;
}
function getModel( FS ) {
    const configAction = getConfigAction( FS.action, FS.review );
    return getConfigModel( configAction, FS.group, FS.name, FS.type );
}
function updateLayout( FS, mergeLayouts ) {
    FS.layout = prepareLayoutModel( cloneObj( FS ), mergeLayouts  );
    delete FS.layouts;
}
/* ---------------------- RESET VOLATILE PROPS ------------------------------ */
function resetModelDefaults( FS ) {
    Object.values( FS.fields ).forEach( resetFieldDefaults );
    delete FS.layout;
}
function resetFieldDefaults( field ) {                              /*dbug-log*///console.log('     --resetFieldDefaults [%O]', field);
    const props = getPropsToReset( field );
    props.forEach( p => delete field[ p ] );                        /*dbug-log*///console.log('     --after reset [%O]', cloneObj(field));
    clearFieldCount( field );
}
/**
 * Note: Resetting 'shown' is necessary due to this being the reset when the
 * source-type changes, but it messes with the Group-Root-init for taxon forms.
*/
function getPropsToReset( field ) {
    const props = [ 'active', 'combo', 'input', 'shown' ];
    if ( field.name === 'Group-Root' ) props.pop();
    return props;
}
/** Clears so the count remains accurate after the fields are rebuilt. */
function clearFieldCount( field ) {
    if ( field.count ) field.count = 1;
}