/**
 * Returns the country or territory ID found in the results of the query.
 */
import { getData } from '@localdata';
import { getMapRecords, getMapState } from '../manager.js';
/** Returns the country or territory ID found in the results of the query. */
export async function getCountryId ( r, query ) {
    const cntrys = await getData( 'countryCodes' );
    const tData = getTerritoryData();                               /*dbug-log*///console.log( '--getCountryId cntrys[%O] territories?[%O] address[%O]', cntrys, tData, r.address );
    if ( tData?.[ r.address.country ] ) return getTerritoryId( cntrys, tData, r, query );
    return getCntryFromCode( cntrys, r.address.country_code );
}
function getTerritoryData() {
    const formConfig = getMapState( 'formConfig' );
    return formConfig ? formConfig.territories : null;
}
function getCntryFromCode ( cntrys, cntryCode ) {                 /*dbug-log*///console.log( '--getCntryFromCode cntrys[%O] cntryCode[%s]', cntrys, cntryCode );
    return cntrys?.[ cntryCode.toUpperCase() ];
}
function getTerritoryId ( cntrys, tData, r, query ) {
    const tNames = tData[ r.address.country ];                      /*dbug-log*///console.log( '--getTerritoryId tNames[%O] query?[%s]', tNames, query );
    const name = tNames.find( n => isLocTerritory( n, r.address, r.display_name, query ) );
    return name ? getTerrId( name ).id : getCntryFromCode( cntrys, r.address.country_code );
}
function isLocTerritory ( tName, address, resultName, query ) {        /*dbug-log*///console.log( '--isLocTerritory tName[%s] query?[%s] address[%O]', tName, query, address );
    const props = [ 'territory', 'state', 'region', 'village', 'county', 'boundary', 'place' ];
    if ( isNameInResult( tName, resultName, query ) ) return true;
    return props.find( p => isTerritory( tName, address[ p ] ) );
}
function isNameInResult ( tName, resultName, query ) {
    return tName.indexOf( query ) !== -1 || resultName.indexOf( tName ) !== -1;
}
function isTerritory ( tName, rData ) {
    if ( !rData ) return;
    return tName.indexOf( stripNonCriticalWords( rData ) ) !== -1;
}
function stripNonCriticalWords ( name ) {
    // const rmv = ['Island', 'Islands', 'and', 'the', 'of', 'includes', 'groups'];
    return name.split( 'Island' )[ 0 ];
}
export function getTerrId ( tName ) {                               /*dbug-log*///console.log( '--getTerrId [%s]', tName );
    const locs = getMapRecords( 'location' );
    return Object.values( locs ).find( l => l.displayName === tName );
}