/**
 * Downloads all data for each entity updated since the last local-data update.
 * TODO: Add 'fail' callback for server errors. Send back any errors and
 * describe them to the user.
 *
 * Export
 *     downloadAndStoreUpdatedData
 *
 * TOC
 *     ENTITY DATA
 *         SIMPLE ENTITY
 *         INTERACTION
 *     DOWNLOAD DATA
 *         SYNC LOCAL-DATA
 *     REVIEW ENTRY
 */
import * as util from '@localdata/util';
import { cloneObj, isContributorUser, isManagerUser, lcfirst } from '@util';
import * as _t from '@types';
import { syncReviewEntries } from '../review';
import * as update from '../update';
import { EntitySyncData } from './db-pg-load-main';

export function downloadAndStoreUpdatedData ( entities: EntitySyncData[] ): Promise<void> {
    /*perm-log*/console.log( '   --downloadAndStoreNewData[%O]', cloneObj( entities ) );
    return syncEntityData( entities )
        .then( () => handleReviewEntryUpdates( entities ) )
        .then( update.retryIssuesAndReportFailures );
}
/* ====================== ENTITY DATA ======================================= */
function syncEntityData ( entities: EntitySyncData[] ): Promise<void> {
    return getAllSimpleEntityUpdates( entities )
        .then( () => downloadIntUpdates( entities ) )
        .then( update.retryFailedUpdates )
        .then( util.setNewDataInLocalStorage );
}
/* ----------------------- SIMPLE ENTITY ------------------------------------ */
/** Before interactions can be synced, all related data must be available. */
function getAllSimpleEntityUpdates ( entities: EntitySyncData[] ): Promise<void[]> {
    const updateFirst = getSimpleEntities( entities );
    const promises = updateFirst.map( handleEntityDataSync );
    return Promise.all( promises );
}
/**
 * Returns all entities that can be synced first. The following will sync after:
 * Interaction - All sub-entity data must be downloaded first.
 * ReviewEntry - Downloaded for data-managers after database download complete.
 */
function getSimpleEntities ( entities: EntitySyncData[] ): EntitySyncData[] {
    const skip = [ 'Interaction', 'ReviewEntry' ];
    return entities.filter( sync => skip.indexOf( sync.name ) === -1 );
}
function handleEntityDataSync ( syncData: EntitySyncData ): Promise<void> {
    return getNewData( syncData )
        .then( processUpdatedEntityData );
}
function ifUpdatesGetSyncData ( entities: EntitySyncData[], name: string ): EntitySyncData | undefined {
    return entities.find( d => d.name === name );
}
/* ----------------------- INTERACTION -------------------------------------- */
function downloadIntUpdates ( entities: EntitySyncData[] ): void | Promise<void> {
    const syncData = ifUpdatesGetSyncData( entities, 'Interaction' );
    if ( !syncData ) return;
    return getNewData( syncData )
        .then( processUpdatedEntityData );
}
/* ====================== DOWNLOAD DATA ===================================== */
type Updated = { [entity: string]: { [key: number]: string; }; };
function getNewData ( syncData: EntitySyncData ): Promise<Updated> {/*dbug-log*///console.log('getting new data for %O', syncData);
    return util.fetchDataForLocalStorage( 'sync-data', getPushParams( syncData ) );
}
function getPushParams ( syncData: EntitySyncData ): { method: 'POST', body: string; } {
    const data = { entity: syncData.name, updatedAt: syncData.updated };
    return { method: 'POST', body: JSON.stringify( data ) };
}
function processUpdatedEntityData ( data: Updated ): void {
    const entity = Object.keys( data )[ 0 ];
    if ( entity ) {
        return storeUpdatedData( parseEntityData( data[ entity ]! ), entity );
    } else {
        console.error( 'Entity name not found' );
    }
}
function parseEntityData ( dataObj: { [key: number]: string; } ): _t.EntityRecords {
    const rcrds = util.parseData( dataObj );
    return rcrds as unknown as _t.EntityRecords;
}
/* ---------------------- SYNC LOCAL-DATA ----------------------------------- */
/** Sends the each updated record to the update handler for the entity. */
function storeUpdatedData ( rcrds: _t.EntityRecords, entity: string ) {
    /*perm-log*/logBasedOnEnv( entity, rcrds );
    _t.objectKeys( rcrds ).forEach( id => storeUpdatedDataRecord( id, rcrds, entity ) );
}
function logBasedOnEnv ( entity: string, entityData: _t.EntityRecords ): void {
    const env = $( 'body' ).data( 'env' );
    if ( env === 'prod' ) {
        console.log( "       --processUpdatedEntityData [%s][%s]", Object.keys( entityData ).length, entity );
    } else {
        console.log( "       --processUpdatedEntityData [%s][%s] = %O", Object.keys( entityData ).length, entity, entityData );
    }
}
function storeUpdatedDataRecord ( id: number, rcrds: _t.EntityRecords, entity: string ): void {
    const syncRecordData = getEntityUpdateFunc( entity );
    syncRecordData( lcfirst( entity ), rcrds[ id ] );
}
function getEntityUpdateFunc ( entity: string ): ( ...args: any[] ) => any {
    const coreEntities = [ 'Interaction', 'Location', 'Source', 'Taxon' ];
    return coreEntities.indexOf( entity ) !== -1 ?
        update.addCoreEntityData : update.addDetailEntityData;
}
/* ======================= REVIEW ENTRY ===================================== */
/** Note: Redownloading all ReviewEntry records when there are any updates. */
function handleReviewEntryUpdates ( entities: EntitySyncData[] ): null | Promise<any> {
    if ( !isContributorUser() || isManagerUser() ) return null;     /*dbug-log*///console.log(' -- handleReviewEntryUpdates delayed?[%O]', delayed);
    const syncData = ifUpdatesGetSyncData( entities, 'ReviewEntry' );
    return syncData ? syncReviewEntries() : null;
}