/**
 * If a URL is entered in the form, a modal is shown prompting the editor to
 * double check the links work before submitting.
 */
import { model, state, submit } from '@dataentry';
import { showSaveModal } from '@elems';

export function showSrcSubmitModal( fLvl: model.FormGroup ): Promise<void> | void {
    const linkHtml = buildConfirmationModalHtml( fLvl );
    if ( !linkHtml ) return submit.submitForm( fLvl );
    showSaveModal( buildModalConfig( fLvl, linkHtml ), getButtonConfig( fLvl ) );
    $( `#${ fLvl }-submit` ).css( { opacity: .5, cursor: 'not-allowed' } )
}
/* ---------------------- INIT MODAL ---------------------------------------- */
function buildConfirmationModalHtml( fLvl: model.FormGroup ): string {
    return [ 'Doi', 'Website' ].map( buildUrlLink ).filter( l=>l ).join( '<br><br>' );

    function buildUrlLink( field: string ): string | null {
        const url = state.getFieldValue( fLvl, field );
        return url ? `<b>${ field }:</b> <a href="${ url }"" target="_blank">${ url }</a>` : null;
    }
}
function buildModalConfig( fLvl: model.FormGroup, linkHtml: string ) {
    return {
        html: linkHtml,
        position: 'left',
        selector: `#${ fLvl }-submit`,
        title: 'Please double-check links'
    } as const;
}
/* ------------------------- SUBMIT BUTTON ---------------------------------- */
function getButtonConfig( fLvl: model.FormGroup ) {
    return {
        button: cloneSubmitButtonForModal( fLvl ),
        onConfirm: () => submit.submitForm( fLvl )
    } as const;
}
function cloneSubmitButtonForModal( fLvl: model.FormGroup ): HTMLElement | undefined {
    const button = $( `#${ fLvl }-submit` ).clone()[ 0 ];
    if ( !button ) return undefined;
    button.id += '_modal';
    $( button ).val( `Confirm & ${ $( button ).val() }` );
    return button;
}