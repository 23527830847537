/**
 * Builds a standard form-field.
 *
 * Export
 *     getFieldElems
 *
 * TOC
 *     CONTAINER
 *     FIELD
 *         LABEL
 *     VALIDATION
 *         INPUT CHAR-COUNT
 */
import { isNotNumber, isObj } from '@util';
import { getElem } from './build-elem';
import { InputConfig } from './input/build-input';
/**
 * @typedef {Object} FieldConfig  - Field configuration and input element.
 * @prop  {String}   [class] - Field style-class
 * @prop  {String}   [count] - Present for fields with multiple inputs
 * @prop  {Object}   [flow] - Flex-direction class suffix.
 * @prop  {String}   [group] - Used for styling and intro-tutorials
 * @prop  {Str}      [id] - Set in config or to name in elem-build-main
 * @prop  {Str|Obj}  [info] - Text used for tooltip and|or intro-tutorial.
 * @prop  {String}   label - Text to use for label. If false, no label is built.
 * @prop  {String}   name - Field name [required] Will be used for IDs
 * @prop  {Boolean}  [required] - True if field is required in a containing form.
 * @prop  {String}   [type] - Flags edge-case field types: 'multiSelect'
 * @prop  {Object}   [val] - Contains field-validation params
 * @prop  {Object}   [val.charLimits] - If present, shows user mix/max char limitations.
 * @prop  {Object}   [val.charLimits.max] - Max-character count for an input field.
 * @prop  {Object}   [val.charLimits.min] - Min-character count for an input field.
 * @prop {string|object} [value] - Field value.
 */
export interface FieldConfig {
    class?: string;
    count?: number;
    flow?: string,
    group?: 'top' | 'sub' | 'sub2';
    id?: string;
    info?: { intro?: string, tooltip: string; };
    label?: string | false;
    name: string;
    required?: boolean;
    type?: string;
    val?: {
        charLimits?: {
            min: number,
            max: number,
            onInvalid: () => void,
            onValid: () => void;
        };
    };
    value?: string | { [key: number]: number; } | { text: string, value: string; };
}

let f: FieldConfig;
/**
 * Builds a standard form-field.
 *
 * @export
 * @param {FieldConfig} config - Field configuration and input element.
 * @return {HTMLDivElement}   containerDiv->(alertDiv, fieldDiv->(label, input))
 */
export function getFieldElems ( fConfig: FieldConfig, input: HTMLElement ): HTMLDivElement {
    f = fConfig;
    return buildField( input );
}
function buildField ( input: HTMLElement ): HTMLDivElement {
    const container = getFieldContainer();
    const alertDiv = getElem( 'div', { id: getFieldId( f ) + '_alert' } );
    const fieldElems = getFieldLabelAndInput( input );
    $( container ).append( [ alertDiv, fieldElems ] );
    return container;
}
/* --------------------------- HELPERS -------------------------------------- */
export function getFieldId( f: FieldConfig | InputConfig ) {
    return f.id || f.name;
}
/* ======================== CONTAINER ======================================= */
function getFieldContainer (): HTMLDivElement {
    const sfx = isMultiField( f ) ? '_f-cntnr' : '_f';
    const attr = { class: getContainerClass( sfx ), id: getFieldId( f ) + sfx };
    return getElem<HTMLDivElement>( 'div', attr );
}
/** Returns the style classes for the field container. */
function getContainerClass ( sfx: string ): string {
    const classes: string[] = [];
    if ( f.group ) classes.push( f.group + sfx );
    if ( f.class?.includes( 'invis' ) ) classes.push( 'invis' );
    return classes.join( ' ' );
}
function getFieldLabelAndInput ( input: HTMLElement ): HTMLDivElement {
    const container = buildFieldContainer();
    const elems = [ buildFieldLabel(), ...getFormattedInputArray( input ) ].filter( el => el );
    setValidationEvents( input );
    $( container ).append( elems as HTMLElement[] );
    return container;
}
function getFormattedInputArray ( input: HTMLElement ): HTMLElement[] {
    if ( !Array.isArray( input ) ) return [ input ];
    const dash = getElem( 'span', { text: ' – ', styles: { margin: '0 .5em' } } );
    input.splice( 1, 0, dash );
    return input;
}
/* ========================= FIELD ========================================== */
function buildFieldContainer () {
    const c = f.type && f.type.includes( 'multi' ) ?
        'cntnr flex-col' : `field-elems flex-${ f.flow ?? 'row' }`;
    const attr = { class: c, title: getInfoTxt() };
    const container = getElem<HTMLDivElement>( 'div', attr );
    if ( f.info ) addTutorialDataAttr( container );
    return container;
}
function addTutorialDataAttr ( container: HTMLDivElement ): void {
    $( container )
        .addClass( f.group + '-intro' )
        .attr( {
            'data-intro': getInfoTxt( 'intro' ),
            'data-title': f.name
        } );
}
function getInfoTxt ( key = 'tooltip' ): string {
    if ( !f.info ) return '';
    return key === 'intro' && f.info.intro ? f.info.intro : ( f.info.tooltip ?? 'Error' );
}
/* -------------------------- LABEL ----------------------------------------- */
//todo2: add "for" attribute to connect with input elems
function buildFieldLabel (): HTMLLabelElement | void {
    if ( f.label === false ) return;
    const attr = {
        class: getLabelClass(),
        id: getFieldId( f ) + '_lbl',
        text: f.label || f.name,
    };
    return getElem<HTMLLabelElement>( 'label', attr );
}
function getLabelClass (): string {
    const group = f.group ? `${ f.group }_lbl` : '';
    return group + ( f.required ? ' required' : '' );
}
/* =========================== VALIDATION =================================== */
// Data-entry form validation handled in form module. TODO3: MERGE
function setValidationEvents ( input: HTMLElement ): void {
    if ( !f.val ) return;
    const map = {
        charLimits: setCharLimitsAlertEvent
    };
    Object.keys( f.val ).forEach( type => map[ type as keyof typeof map ]( input ) );
}
/* --------------------- INPUT CHAR-COUNT ----------------------------------- */
type CharValAlertParams = {
    field: string;
    max: number;
    min: number;
    onInvalid: () => void;
    onValid: () => void;
};
function setCharLimitsAlertEvent ( input: HTMLElement ): void {
    const val: CharValAlertParams = {
        field: f.name,
        min: f.val?.charLimits?.min ?? 0,
        max: f.val?.charLimits?.max ?? 250,
        onInvalid: f.val?.charLimits?.onInvalid ?? ( () => {} ),
        onValid: f.val?.charLimits?.onValid ?? ( () => {} )
    };
    addKeyUpEventListener( val, input as HTMLInputElement );
}
function addKeyUpEventListener ( val: CharValAlertParams, input: HTMLInputElement ) {
    $( input ).on( 'keyup', updateCharLimits.bind( null, val ) );
}
/**
 * Handles input char-count validation on keyup events.
 * @param {CharValAlertParams} val
 * @param {KeyboardEvent} e
 */
function updateCharLimits ( val: CharValAlertParams, e: Event ): void {
    const inputLength = ( e.target as HTMLInputElement ).value.length;
    $( `#${ val.field }_alert` ).text( getCharAlert( inputLength, val.min, val.max ) );
    callValidationHandlers( val, inputLength );
}
function getCharAlert ( inputLength: number, min: number, max: number ): string {
    if ( inputLength < min ) {
        return `${ inputLength } characters (${ min } min)`;
    } else {
        return `${ inputLength } characters (${ max } max)`;
    }
}
function callValidationHandlers ( val: CharValAlertParams, inputLength: number ): void {
    if ( inputLength < val.min ) {
        $( `#${ val.field }_alert` ).addClass( 'alert-active' ); //Flags the element as (in)valid
        val.onInvalid();
    } else {
        $( `#${ val.field }_alert` ).removeClass( 'alert-active' ); //Flags the element as valid
        val.onValid();
    }
}
/* =========================== PREDICATES =================================== */
/**
 * True if field has dynamic field-inputs. Can be called from multiple places
 * with various field states.
 * @param  {object}  field  Field-config
 * @return {Boolean}
 */
export function isMultiField <T>( field: any ): field is T {
    if ( !field.value ) return isMultiFieldType( field );
    return isObj( field.value ) && hasNumberKeys( field.value );
}
function isMultiFieldType ( field: FieldConfig ): boolean {
    return !!( field.type && field.type.includes( 'multi' ) );
}
/** Multi-field values are keyed by field order. */
function hasNumberKeys ( valObj: object ): boolean {
    const keys = Object.keys( valObj );
    return !keys.length || Object.keys( valObj ).every( k => !isNotNumber( k ) );
}