/**
 * Interaction create/edit forms display selected source and location details.
 *
 * EXPORT
 *     clearSidePanelDetails
 *     clearFieldDetails
 *     getInteractionDetailElems
 *     setSubEntityDetails
 *
 * TOC
 *     INIT DETAIL-ELEMS
 *     SET DETAIL-DATA
 *         LOCATION DETAILS
 *         SOURCE DETAILS
 *     CLEAR DETAILS
 */
import { state } from '@dataentry';
import { getElem } from '@elems';
/* ====================== INIT DETAIL-ELEMS ================================= */
export function getInteractionDetailElems( entity ) {
    return [ 'src','loc' ].map( en => initSubEntityDetails( en ) );
}
function initSubEntityDetails( ent ) {
    const div = getDetailContainer( ent );
    $( div ).append( [ getDetailHeader( ent ), getInitDetailData() ] );
    return div;
}
function getDetailContainer( ent ) {
    const attr = { class: 'det-div expandable', id: ent+'-det' };
    return  getElem( 'div', attr );
}
function getDetailHeader( ent ) {
    const entities = { loc: 'Location', src: 'Source' };
    const attr = { class: 'toggle-trigger closed', text: `Show ${ entities[ ent ] } Details` };
    const hdr = getElem( 'h4', attr );
    $( hdr ).data( 'expanded', `Hide ${ entities[ ent ] } Details` );
    $( hdr ).data( 'collapsed', `Show ${ entities[ ent ] } Details` );
    return hdr;
}
function getInitDetailData() {
    const attr = { class: 'expandable-content closed', text: 'None selected.' }
    return getElem( 'div', attr );
}
/* ======================== SET DETAIL-DATA ================================= */
export function setSubEntityDetails( ent, data ) {
    const map = {
        loc: setLocDetails,
        src: setSrcDetails
    };
    map[ ent ]( data );
}
/**
 * When the Publication, Citation, or Location fields are selected, their data
 * is added to the form side-panel.
 */
function addDataToIntDetailPanel( ent, propObj ) {                    /*dbug-log*///console.log('addDataToIntDetailPanel ent[%s], propObj[%O]', ent, propObj);
    const html = getDataHtmlString( propObj );
    clearSidePanelDetails( ent, true, html )
}
function getDataHtmlString( props ) {
    const html = [];
    for ( let prop in props ) {                                       /*dbug-log*///console.log('data [%s]', props[prop]);
        let data = escapeQuotes( props[ prop ] );
        html.push( `<li title='${ data }'><b>${ prop }</b>: ${ data }</li>` );
    }
    return `<ul class="ul-reg">${ html.join( '\n' ) }</ul>`;
}
function escapeQuotes( details ) {
    const escape = [ '"' ];
    if ( typeof details !== 'string' ) { return details; }
    return details.split().map( c => escape.indexOf( c ) !== -1 ? `\${c}` : c ).join( '' );
}
/* ------------------------- LOCATION DETAILS ------------------------------- */
/** Displays the selected location's data in the form side-panel. */
function setLocDetails( locRcrd ) {
    addDataToIntDetailPanel( 'loc', getLocDetailDataObj( locRcrd ) );
}
/** Returns an object with selected location's data. */
function getLocDetailDataObj( locRcrd ) {
    const data = {};
    const allData = getAllLocData( locRcrd );

    for ( let field in allData ) {
        if ( !allData[ field ] ) { continue; }
        data[ field ] = allData[ field ];
    }
    return data;
}
function getAllLocData( locRcrd ) {
    return {
        'Name': locRcrd.displayName,
        'Description': locRcrd.description || '',
        'Habitat Type': locRcrd.habitatType ? locRcrd.habitatType.displayName : '',
        'Latitude': locRcrd.latitude || '',
        'Longitude': locRcrd.longitude || '',
        'Elevation': locRcrd.elevation || '',
        'Elevation Max': locRcrd.elevationMax || '',
    };
}
/* -------------------------- SOURCE DETAILS -------------------------------- */
/** Adds source data to the interaction form side-panel. */
function setSrcDetails() {                                          /*dbug-log*///console.log('--setSrcDetails', );
    const data = {};
    const srcRcrds = state.getRecords( 'source' );
    buildSourceData();
    addDataToIntDetailPanel( 'src', data );

    function buildSourceData() {
        const citId = $( '#sel-CitationTitle' ).val();
        const citSrc = citId ? srcRcrds[ citId ] : false;
        const cit = !citSrc ? false :  state.getRecords( 'citation', citSrc.citation );
        const pubSrc = srcRcrds[ $( '#sel-Publication' ).val() ];                   //console.log('citation src [%s] = %O, details = %O', citId, citSrc, cit);

        addCitationText();
        if ( !pubSrc ) return;
        addAuths();
        addEds();
        addAbstract();

        function addCitationText() {
            data.Citation = cit ? cit.fullText : '(Select Citation)';        //console.log('cit full text', cit.fullText)
        }
        function addAuths() {
            const rcrdWithAuths = pubSrc.authors ? pubSrc :
                citSrc && citSrc.authors ? citSrc : false;
            if ( !rcrdWithAuths ) return;
            const cnt = Object.keys( rcrdWithAuths.authors ).length;
            if ( cnt <= 3 ) return;
            const prop = 'Author' + ( cnt === 1 ? '' : 's' );
            data[ prop ] = getAuthorNames( rcrdWithAuths );
        }
        function addEds() {
            if ( !pubSrc.editors ) return;
            const cnt = Object.keys( pubSrc.editors ).length;
            if ( cnt <= 3 ) return;
            const prop = 'Editor' + ( cnt === 1 ? '' : 's' );
            data[ prop ] =  getAuthorNames( pubSrc, true );
        }
        function addAbstract() {
            if ( !cit || !cit.abstract ) return;
            data.Abstract = cit.abstract;
        }
    } /* End buildSourceData */
    /** Returns a comma seperated sting of all authors attributed to the source. */
    function getAuthorNames( srcRcrd, editors ) {
        const authStr = [];
        const prop = editors ? 'editors' : 'authors';
        for ( let ord in srcRcrd[ prop ] ) {
            let authId = srcRcrd[ prop ][ ord ];
            authStr.push( srcRcrds[ authId ].displayName );
        }
        return authStr.length ? authStr.join( ' - ' ) : authStr+'.';
    }
}
/* ========================== CLEAR DETAILS ================================= */
/**
 * @param  {str} ent   ("cit" or "loc")
 */
export function clearSidePanelDetails( ent, reset, html ) {           /*dbug-log*///console.log('clearSidePanelDetails for [%s]', ent);
    if ( ent === 'cit' ) { return setSrcDetails( 'cit' ); }             /*dbug-log*///console.log('   html[%O]', html);
    const newDetails = reset ? html : 'None selected.';
    $( '#'+ent+'-det div.expandable-content' ).empty();
    $( '#'+ent+'-det div' ).append( newDetails );
    return Promise.resolve();
}