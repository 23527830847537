/**
 * Handles initlization of the Explore page based on the state of local storage.
 * During local-storage init, the taxon table is loaded as soon as it is available.
 * The user is shown the tutorial/walkthrough on their first visit.
 *
 * EXPORT
 *     showIntroAndLoadingMsg
 *     initExploreStateAndTable
 *
 * TOC
 *     EXPLORE-STATE INIT
 *         DURING STORAGE-INIT
 *     TABLE|STATE INIT
 *     FOCUS|VIEW INIT
 */
import * as combo from '@elems/combo';
import { getData } from '@localdata';
import { table, tutorial, ui } from '@explore';
/**
 * The first time a browser visits the Explore page, or when local data is reset,
 * all data is downloaded and stored from the server. The intro-walkthrough is
 * shown on first visit (when resettingData is false).
*/
export function showIntroAndLoadingMsg( curFocus = 'taxa', resettingData = false ) { /*dbug-log*///console.log( 'showIntroAndLoadingMsg resetting? [%s]', resettingData );
    ui.updateUiForDatabaseInit();
    selectInitialExploreFocus( curFocus );
    if ( resettingData ) return combo.resetCombobox( 'View' );
    tutorial.startWalkthrough( 'taxa' );
}
/* ===================== TABLE|STATE INIT =================================== */
/** On page load, after local storage is fully downloaded. */
export function afterLocalStorageReadyInitExploreTable() {          /*dbug-log*///console.log( 'afterLocalStorageReadyInitExploreTable' );
    ui.initDataInReviewPanel();
    getData( 'curFocus', true )
    .then( initExploreStateAndTable );
}
/**
 * The Explore state is initialized during page load and after local-data sync,
 * when all data is available.
 */
export function initExploreStateAndTable( focus = 'taxa', isAllDataAvailable = true ) {
    /*perm-log*/console.log( '   *//initExploreStateAndTable. focus? [%s], allDataAvailable ? [%s]', focus, isAllDataAvailable );
    setTableInitState( focus, isAllDataAvailable );
    if ( isTestEnvBeforeDataAvailable( isAllDataAvailable ) ) return;
    table.buildTable( focus )
    .then( table.initDefaultFilters );
}
function setTableInitState( focus, isAllDataAvailable ) {
    ui.resetFilterPanelOnFocusChange( focus );
    table.resetFocusState( focus, isAllDataAvailable );
    selectInitialExploreFocus( focus );
}
function isTestEnvBeforeDataAvailable( isAllDataAvailable ) {
    return $( 'body' ).data( 'env' ) === 'test' && isAllDataAvailable === false;
}
/* ===================== FOCUS|VIEW INIT ==================================== */
/** Selects either Taxon, Location or Source in the table-focus dropdown. */
function selectInitialExploreFocus ( f ) {                          /*dbug-log*///console.log('--------------selectInitialExploreFocus [%s]', f);
    const focus = f || 'taxa';
    combo.replaceSelOpts( 'Focus', getFocusOpts() );
    combo.setSelVal( 'Focus', focus, 'silent' );
}
function getFocusOpts () {
    return [
        { text: 'Location', value: 'locs' },
        { text: 'Source', value: 'srcs' },
        { text: 'Taxon', value: 'taxa' }
    ];
}