/**
 * Shows alerts related to entity-data validation.
 *
 * TOC
 *     INTERACTION
 *     SOURCE
 *     TAXON
 *         MUST STAY GENUS RANK
 *         INCORRECT BINOMIAL
 *         MUST HAVE GENUS PARENT
 *         PARENT MUST BE HIGHER RANK
 *         TAXON MUST BE HIGHER RANK
 *         NEEDS FAMILY
 *         NEEDS GENUS
 */
import { components, form, state } from '@dataentry';
import { getSelTxt, resetCombobox } from '@elems/combo';
import * as alert from './alerts-main.js';
/* =========================== INTERACTION ================================== */
export function handleNoValidIntsAndReturnAlertMsg ( elem, fLvl ) {
    return '<span>There are no valid interaction types</span>';
}
export function clearAnyGroupAlerts() {                             /*dbug-log*///console.log( 'clearAnyGroupAlerts' )
    alert.clearAlert( $( '#InteractionType_alert' )[ 0 ], 'top' );
}
export function handleNeedsTypeTagAndReturnAlertMsg ( elem, fLvl ) {
    const type = getSelTxt( 'InteractionType' );
    alert.setOnChangeClearAlert( 'InteractionTags', elem, fLvl );
    return `<span>Please select a tag for the type: ${ type }.</span>`;
}
/* ============================= SOURCE ===================================== */
export function handleAuthBlanksAndReturnAlertMsg ( elem, fLvl ) {
    alert.setOnCloseClearAlert( elem, fLvl );
    return '<p>Please fill the blank in the order.</p>';
}
/** Called when the blank is filled in the author|editor order. */
export function clrContribFieldAlert ( field, fLvl ) {                 /*dbug-log*///console.log( 'clrContribFieldAlert.' )
    const elem = $( `#${ field }_alert` )[ 0 ];
    alert.clearAlert( elem, fLvl );
}
/* ============================= TAXON ====================================== */
/* ---------------- MUST STAY GENUS RANK ------------------------------------ */
export function handleIsGenusPrntAndReturnAlertMsg ( elem, fLvl ) {
    return "<span>Genus' with species children must remain at genus.</span>";
}
/* ------------- INCORRECT BINOMIAL ----------------------------------------- */
export function handleNeedsGenusNameAndReturnAlertMsg ( elem, fLvl ) {
    const genus = getGenusName( fLvl );
    alert.setOnChangeClearAlert( 'Name', elem, fLvl );
    return `<span>Species must begin with the Genus name "${ genus }".</span>`;
}
function getGenusName( fLvl ) {
    const parentId = state.getFieldValue( fLvl, 'Parent' );
    return state.getRecords( 'taxon', parentId ).name;
}
/* ---------------- MUST HAVE GENUS PARENT ---------------------------------- */
/** Note: Alert generated in the sub-form and the msg is added to the 'top' form. */
export function handleNeedsGenusParentAndReturnAlertMsg ( elem, fLvl ) {
    return '<span>Please select a genus parent for the species taxon.</span>';
}
export function clrTaxonParentAlert ( elem, fLvl, e ) {             /*dbug-log*///console.log( 'clrTaxonParentAlert args[%O]', arguments );
    alert.clearAlert( $( '#Parent_alert' )[ 0 ], 'top' );
    const pId = state.getFormState( 'top', 'rcrd' ).parent;         /*dbug-log*///console.log( '--pId [%s]', pId );
    components.exitFormElemAndClearState( 'sub' );
    form.buildOptAndUpdateCombo( 'Parent', pId, 'silent' );
}
/* -------------- PARENT MUST BE HIGHER RANK -------------------------------- */
export function handleNeedsHigherRankPrntAndReturnAlertMsg ( elem, fLvl ) {
    return '<span>The parent taxon must be at a higher taxonomic rank.</span>';
}
/* -------------- TAXON MUST BE DIFFERENT RANK ------------------------------ */
export function handleNeedsHigherRankAndReturnAlertMsg ( elem, fLvl ) {
    return '<div>Taxon rank must be higher than that of child taxa.</div>';
}
/* Note: Alert is cleared after taxon validation on parent change. */
export function handleNeedsLowerRankAndReturnAlertMsg ( elem, fLvl ) {
    return "<div>Taxon rank must be lower than the parent rank.</div>";
}
/** Resets the taxon's rank. */
export function clrTaxonRankAlert ( elem, fLvl, e ) {
    const txnRank = state.getFieldState( 'top', 'Group-Root', 'misc' ).taxon.rank.id;
    components.setSilentVal( 'top', 'Rank', txnRank );
    alert.clearAlert( elem, fLvl );
}
export function handleRankNotAvailableInNewGroupAndReturnAlertMsg ( elem, fLvl ) {
    const groupName = state.getFieldValue( fLvl, 'Group' ).text;
    const vRanks = state.getFieldState( fLvl, 'Group-Root', 'misc' ).rcrd.subRanks;
    return `<span>Valid ${ groupName } ranks: \n${ vRanks }</span>`;
}
/* ------------------- NEEDS FAMILY ----------------------------------------- */
export function handleNoFamilyAndReturnAlertMsg ( elem, fLvl ) {
    window.setTimeout( () => alert.clearAlert( elem, fLvl, false ), 2000 );
    resetCombobox( 'Genus' );
    return '<span>Please select a family before creating a genus.</span>';
}
/* ----------------------- NEEDS GENUS -------------------------------------- */
export function handleNoGenusAndReturnAlertMsg ( elem, fLvl ) {
    window.setTimeout( () => alert.clearAlert( elem, fLvl, false ), 2000 );
    resetCombobox( 'Species' );
    return '<span>Please select a genus before creating a species.</span>';
}