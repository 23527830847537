/**
 * Checks all required citation fields, generates the citation, and sets the
 * disabled textarea field. Default text displays Until required fields are filled.
 *
 * Export
 *     buildCitTextAndUpdateField
 *     setFinalCitation
 *
 * TOC
 *     GENERATE FINAL CITATION
 *     UPDATE FORM CITATION
 *         FIELD ELEM
 *         GET CITATION
 *         GET RELEVANT DATA
 *         UPDATE FIELD
 */
import { components, model, state } from '@dataentry'; //todo2: decouple
import { getSelTxt } from '@elems/combo';
import * as _t from '@types';
import { cloneObj, GenerateCitationParams, generateCitationText, isObj } from '@util';
/* FP-TS */
import { pipe } from 'fp-ts/lib/function';
import * as O from 'fp-ts/lib/Option';
/* ====================== UPDATE FORM CITATION ============================== */
export function buildCitTextAndUpdateField( fLvl: model.FormGroup ): void {/*dbug-log*///console.log('--buildCitTextAndUpdateField [%s]', fLvl);
    const $elem = getCitationFieldElem( fLvl );
    pipe(
        getTextForCitationField( $elem, fLvl ),
        O.map( ( text ) => updateCitField( fLvl, text, $elem ) )
    );
}
/* ------------------------ FIELD ELEM -------------------------------------- */
function getCitationFieldElem( fLvl: model.FormGroup ): JQuery<HTMLElement> {
    const $elem = $( `#Description_f textarea.${ fLvl }-field` );
    if ( !$elem.val() ) initializeCitField( $elem );
    return $elem;
}
function initializeCitField ( $elem: JQuery<HTMLElement> ): void {
    $elem.prop( 'disabled', true ).unbind( 'change' ).css( { height: '6.6em' } );
}
/* ------------------------ GET CITATION ------------------------------------ */
function getTextForCitationField( $elem: JQuery<HTMLElement>, fLvl: model.FormGroup ): O.Option<string> {
    return pipe(
        components.isFormValidForSubmit( fLvl ),
        ( isValid ) => isValid ? buildCitationText( fLvl ) : getDefaultText( $elem ),
        O.fromNullable
    );
}
function buildCitationText( fLvl: model.FormGroup ): string {           /*dbug-log*///console.log('--buildCitationText [%s]', fLvl);
    return generateCitationText( getDataForCitation( fLvl ) );
}
function getDefaultText( $elem: JQuery<HTMLElement> ): string | null {
    const DEFAULT_TEXT = 'The citation will display here once all required fields are filled.';
    return $elem.val() === DEFAULT_TEXT ? null : DEFAULT_TEXT;
}
/* ---------------------- GET RELEVANT DATA --------------------------------- */
export function getDataForCitation(
    fLvl: model.FormGroup,
    warn = true
): GenerateCitationParams {                                      /*dbug-log*///console.log('--getDataForCitation [%s]', fLvl);
    return { ...getFormData( fLvl, warn ), ...addEntityRecords() };
}
function getFormData( fLvl: model.FormGroup, warn: boolean ) {
    const fields: model.CitationFormFields = state.getFormState( fLvl, 'fields' );
    return {
        pubSrc: state.getRecords<_t.PublicationSource>( 'source', fields.ParentSource.value ),
        citSrc: { authors: fields.Author.value, year: fields.Year.value },
        cit: buildCitData( fields ),
        showWarnings: warn
    };
}
function buildCitData( fields: model.CitationFormFields ) {           /*dbug-log*///console.log( '--buildCitData [%O]', cloneObj( fields ) );
    return {
        citationType: { displayName: getCitationType( fields.CitationType.value ) },
        title: fields.DisplayName.value,
        publicationPages: fields.Pages.shown ? fields.Pages.value : null,
        publicationIssue: fields.Issue.value,
        publicationVolume: fields.Volume.value || fields.Edition.value,
    };
}
function getCitationType( val?: _t.OptionObject ): string {
    return isObj( val ) && val.text ? val.text : getSelTxt( 'CitationType' ) as string;
}
function addEntityRecords() {
    const entities = [ 'author', 'citation', 'publisher', 'source' ] as const;
    type EntityMap = {
        [K in typeof entities[number]]: _t.EntityRecords;
    };
    return { rcrds: state.getRecords( entities ) as EntityMap };
}
/* --------------------------- UPDATE FIELD --------------------------------- */
function updateCitField(
    fLvl: model.FormGroup,
    citText: string,
    $elem: JQuery<HTMLElement>
): void {
    $elem.val( citText );
    state.setFieldValue( fLvl, 'Description', citText );
}