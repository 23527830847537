import { DetailEntityFormConfig, ModelFormFields, ViewTypeConfig } from '../types';
/* ============================ TYPES ======================================= */

/* ============================ CONFIG ====================================== */
/**
 * Publication form configuration.
 */
export default function( entity: string ): DetailEntityFormConfig {
	return {
        core: 'Source',
        records: {
            edit: [ 'author', 'publication', 'publicationType', 'publisher', 'source' ]
        },
        fields: getPublicationFieldConfig(),
        name: entity,
        type: null,
        types: getPublicationTypeConfig(),
        layouts: {
            all: [
                [ 'Title', 'Year', 'PublicationType' ],
                [ { fields: [ 'Doi', 'Website' ] }, 'Description' ],
            ],
            simple: [
                [ 'Title', 'Year', 'PublicationType' ]
            ]
        },
    };
}
function getPublicationFieldConfig(): ModelFormFields {
    return {
        Author: {
            required: true
        },
        Description: {
            server: {
                prop: {
                    detail: 'description'
                },
            },
        },
        DisplayName: {
            label: 'Title',
            required: true,
            server: {
                prep: {
                    setPublicationTitle: [],
                }
            },
        },
        Doi: {
            info: {
                tooltip: 'Digital Object Identifier provided by the Publisher',
            }
        },
        ParentSource:  {
            server: {
                entity: {
                    detail: 'publisher'
                },
            },
        },
        PublicationType: {
            class: 'no-grow w-12',
            entity: 'PublicationType',
            label: 'Type',
            name: 'PublicationType',
            required: true,
            server: {
                prep: {
                    setDetailEntity: []
                },
                prop: {
                    detail: 'publicationType'
                },
            },
            type: 'select',
        },
        Publisher: {
            entity: 'Source',
            name: 'Publisher',
            server: {
                entity: {
                    detail: 'publisher'
                },
                prep: {
                    setParent: [ 'Source' ]
                },
                prop: {
                    core: 'parent'
                },
            },
            type: 'select',
        },
        SourceType: {
            value: 'Publication'
        },
        Website: {
            info: {
                tooltip: 'Copy and paste link to publication, if available',
            }
        }
    };
}
function getPublicationTypeConfig():ViewTypeConfig {
    return  {
        Book: {
            fields: {
                Editor: {
                    required: true
                },
                Publisher: {
                    required: true
                },
                Year: {
                    required: true
                }
            },
            layouts: {
                all: [
                    [ 'Author', 'Editor', 'Publisher' ]
                ]
            }
        },
        Journal: {
            fields: {
                'Author': {
                    required: false
                }
            },
            layouts: {
                all: [
                    [ 'Publisher', '' ]
                ],
                simple: [] // No fields added unless 'show all fields' selected
            }
        },
        Other: { //Most broad options available
            fields: {
                Year: {
                    required: true
                }
            },
            layouts:  {
                all: [
                    [ 'Author', 'Publisher' ]
                ]
            }
        },
        'Thesis/Dissertation': {
            fields:{
                Publisher: {
                    required: true
                },
                Year: {
                    required: true
                }
            },
            layouts:  {
                all: [
                    [ 'Author', 'Publisher' ]
                ]
            }
        }
    };
}