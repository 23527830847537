/**
 * Initializes the Publication review-form.
 *
 * Export
 *     initReviewForm
 *
 * TOC
 *     REVIEW
 *         PARAMS
 */
import { components, form, model } from '@dataentry';
import * as _t from '@types';
import { taskEitherToPromise } from '@util';
import { buildEditParams, getCreateParams } from './entry-forms';
/* FP-TS */
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
/* ============================= REVIEW ===================================== */
export function initReviewForm( rEntry:  model.FormReviewEntry ): Promise<string | void> {                           /*dbug-log*///console.log('           >--Publisher REVIEW Form rEntry[%O]', rEntry);
    return pipe(
        rEntry,
        getReviewFormParams,
        form.initEntityForm,
        TE.map( _ => components.focusField( rEntry.inst.group, 'DisplayName' ) ),
        taskEitherToPromise
    );
}
/* ---------------------------- PARAMS -------------------------------------- */
function getReviewFormParams( rEntry:  model.FormReviewEntry ):  model.InitReviewParams {
    return pipe(
        rEntry,
        getBaseParams,
        form.prepReviewParams( rEntry )
    );
}
function getBaseParams( rEntry:  model.FormReviewEntry ):  model.InitEntryParams {
    return pipe(
        rEntry,
        getQuarantinedPubType,
        getEntryParams( rEntry.form.action, rEntry )
    );
}
function getQuarantinedPubType( rEntry:  model.FormReviewEntry ): _t.PublicationType {
    const pubRcrd = rEntry.payload.detailEntity as any as _t.Publication;
    return pubRcrd.publicationType.displayName;
}
function getEntryParams( action: string, rEntry:  model.FormReviewEntry ): ( type: _t.PublicationType ) =>  model.InitEntryParams {
    return ( type ) => action === 'create'
        ? getCreateParams( type )
        : buildEditParams( rEntry.form.editId!, type );
}