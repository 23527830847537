/**
 * Loops through object and adds each author|editor to the form.
 *
 * Export
 *     selectExistingAuthsOrEds
 *
 * TOC
 *     SELECT AUTHORS|EDITORS
 */
import { setSelVal } from '@elems/combo';
import { state } from '@dataentry';
import * as aForm from '..';
/* ======================= SELECT AUTHORS|EDITORS =========================== */
export function selectExistingAuthsOrEds ( aType, authObj, fLvl ) { /*dbug-log*///console.log( '--selectExistingAuthsOrEds fLvl[%s] aType[%s] authObj[%O] args[%O]', fLvl, aType, authObj, arguments );
    if ( !Object.keys( authObj ).length ) return Promise.resolve();
    aForm.toggleOtherAuthorType( fLvl, aType, false );
    state.setFieldValue( fLvl, aType, authObj );
    return selectAuthors( aType, authObj, fLvl );
}
function selectAuthors ( aType, authObj, fLvl ) {
    return Object.keys( authObj ).reduce( ( p, ord ) => { //p(romise), (author-)ord(er)
        return p.then( () => selectAuthor( ord, authObj[ ord ], aType, fLvl ) );
    }, Promise.resolve() );
}
/** Selects the passed author and builds a new, empty author combobox. */
function selectAuthor ( ord, authId, aType, fLvl ) {
    if ( !state.isFieldShown( fLvl, aType ) ) return Promise.resolve();/*dbug-log*///console.log( '   --selectAuthor [%s][%s][%s] id[%s]', fLvl, aType, ord, authId );
    if ( !authId ) aForm.alertBlank( aType, fLvl );
    setSelVal( aType + ord, authId, 'silent' );
    return aForm.buildNewAuthorSelect( fLvl, aType, parseInt( ord ) + 1 );
}