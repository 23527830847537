/**
 * Returns select options for the rank combos for all taxa related to the
 * selected taxon. The ancestors of the selected taxon will be selected as well.
 *
 * Export
 *     getAllRankAndSelectedOpts
 *     getChildRankOpts
 *
 * TOC
 *     GET ALL OPTS FOR RELATED TAXA
 *     CHILD RANKS
 *     SIBLING TAXA
 *     ANCESTOR RANKS
 *     CREATE OPTION
 */
import { getTaxonOpts, initOptsWithCreate } from '@elems/combo';
import { state } from '@dataentry';
/**
 * All data needed to build the rank options.
 * {obj} opts     Arrays of options (v) for each rank's (k) taxa {value: id, text: name}
 * {obj} selected Rank name (k) and taxon id (v) to be selected in comboboxes.
 * {obj} taxon    The taxon selected in the form combos
 * {obj} taxa     Taxon records
 * {obj} group    The group of the selected taxon.
 * {obj} root     The GroupRoot, the root taxon, name.
 */
let d = {
    opts: {},
    ranks: [],
    taxa: {},
    selected: {},
};
function resetOptMemory() {
    d = { opts: {}, ranks: [], selected: {}, taxa: {} };
}
/* ------------------- GET ALL OPTS FOR RELATED TAXA ------------------------ */
export function getAllRankAndSelectedOpts( selId, selTaxon = null ) {
    initModuleCache( selId, selTaxon );
    if ( !d.taxon ) return Promise.reject(); /* issue alerted */    /*dbug-log*///console.log( "   +-- getAllRankAndSelectedOpts. taxon[%O] opts[%O] selected[%O]", d.taxon, d.opts, d.selected );
    return buildRankTaxonOpts()
        .then( clearMemoryAndReturnOpts )
}
function initModuleCache( selId, selTaxon ) {
    d.taxa = state.getRecords( 'taxon' );
    d.taxon = selTaxon || d.taxa[ selId ];
    if ( !d.taxon ) return state.getRecords( 'taxon', selId );//todo: issue alert to developer and editor more directly
    d.group = d.taxon.group.displayName;
    d.root = d.taxon.group.root.name;
    d.ranks = state.getFieldState( 'sub', 'Group-Root', 'misc' ).rcrd.subRanks;
}
function clearMemoryAndReturnOpts() {
    window.setTimeout( () => resetOptMemory(), 500 );
    return d;
}
function buildRankTaxonOpts() {
    buildChildRankOpts( d.taxon.rank.displayName, d.taxon.children );
    return buildUpdatedTaxonOpts();
}
function buildUpdatedTaxonOpts() {
    return Promise.all( [ getSiblingOpts( d.taxon ), getAncestorOpts( d.taxon ) ] )
        .then( buildOptsForEmptyRanks );
}
/* -------------------------- CHILD RANKS ----------------------------------- */
export function getChildRankOpts( pRank, children, taxaRecords ) {
    if ( taxaRecords ) d.taxa = taxaRecords;
    buildChildRankOpts( pRank, children );
    return clearMemoryAndReturnOpts();
}
function buildChildRankOpts( pRank, children ) {
    const childRanks = getChildRanks( pRank );
    children.forEach( addRelatedChild );
    handleEmptyChildRanks( childRanks );
}
function getChildRanks( rankName ) {
    return d.ranks.slice( 0, d.ranks.indexOf( rankName ) );
}
function addRelatedChild( id ) {
    const childTxn = d.taxa[ id ];
    if ( !childTxn ) return state.getRecords( 'taxon', id ); //todo: issue alert to developer and editor more directly
    const rank = childTxn.rank.displayName;
    addOptToRankAry( childTxn, rank );
    childTxn.children.forEach( addRelatedChild );
}
function addOptToRankAry( childTxn, rank ) {
    if ( !d.opts[ rank ] ) { d.opts[ rank ] = initOptsWithCreate( rank ); }
    d.opts[ rank ].push( { text: childTxn.name, value: childTxn.id } );
}
function handleEmptyChildRanks( childRanks ) {
    childRanks.forEach( r => d.opts[ r ] ? null : addEmptyChildRankOptAry( r ) );
}
function addEmptyChildRankOptAry( rank ) {
    d.opts[ rank ] = initOptsWithCreate( rank );
}
/* ------------------------- SIBLING TAXA ----------------------------------- */
function getSiblingOpts( taxon ) {
    if ( taxon.isRoot ) return Promise.resolve();
    const rank = taxon.rank.displayName;
    return getTaxonOpts( rank, d.group, d.root )
        .then( o => {                                                /*dbug-log*///console.log( 'getSiblingOpts = %O. taxon = %O ', o, taxon );
            d.opts[ taxon.rank.displayName ] = o;
            d.selected[ taxon.rank.displayName ] = taxon.id;
        } );
}
/* ----------------------- ANCESTOR RANKS ----------------------------------- */
function getAncestorOpts( txn ) {
    if ( txn.isRoot || !txn.parent ) return Promise.resolve(); //Group-Root Taxon
    const pTaxon = d.taxa[ txn.parent ];                            /*dbug-log*///console.log( '   -- getAncestorOpts. parent [%s][%O]', txn.parent, pTaxon );
    if ( pTaxon.isRoot ) { return Promise.resolve();} //Group-Root Taxon
    d.selected[ pTaxon.rank.displayName ] = pTaxon.id;
    return buildAncestorOpts( pTaxon );
}
function buildAncestorOpts( pTaxon ) {
    const rank = pTaxon.rank.displayName;
    return getAndSetRankOpts( rank )
        .then( () => getAncestorOpts( pTaxon ) );
}
/**
 * Builds the opts for each rank without taxa related to the selected taxon.
 * Ancestor ranks are populated with all taxa at the rank and will have
 * the 'none' value selected.
 */
function buildOptsForEmptyRanks() {
    const proms = [];
    fillOptsForEmptyRanks();
    return Promise.all( proms );

    function fillOptsForEmptyRanks() {
        d.ranks.forEach( rank => {
            if ( d.opts[ rank ] || rank == d.taxon.rank.displayName ) return;/*dbug-log*///console.log( "--fillOptsForEmptyRank [%s]", rank )
            proms.push( getAndSetRankOpts( rank ) );
        } );
    }
}
function getAndSetRankOpts( rank ) {                                /*dbug-log*///console.log( "       -- getAndSetRankOpts rank[%s]", rank )
    return getTaxonOpts( rank, d.group, d.root )
        .then( o => d.opts[ rank ] = o );
}
