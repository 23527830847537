/**
 * Modifies taxon-data for local storage:
 * - rankNames - an object with each rank name (k) and it's id and ordinal (v).
 * - groupNames - an object with each group name (k) and it's id.
 * - orderedRanks - an array of rank names from most to least specific. ie, Species, Genus, ...etc.
 * - [group][groupRoot][rank]Names - object with all taxa in groupRoot at the rank: name (k) id (v)
 * - [group]GroupRootNames - object with groupRoot taxa root displayNames(k) and id(v)
 *
 * Export
 *     modifyTxnDataForLocalDb
 *
 * TOC
 *     RANKS
 *     TAXA BY GROUP AND RANK
 *         GROUP-ROOTS
 *     MODIFY GROUP DATA
 */
import { removeData, storeData } from '@localdata/util';
import * as _t from '@types';
import { getNameObj } from '../init-helpers';

type TaxonData = {
    group: _t.EntityRecords;
    groupRoot: _t.EntityRecords;
    rank: _t.EntityRecords;
    taxon: _t.EntityRecords;
};
export function modifyTxnDataForLocalDb ( data: TaxonData ): void {
    const rankData = storeRankData( data.rank );
    storeGroupNames( data.group );
    modifyGroupData( data.group, rankData );
    storeTaxaByRankAndGroup( data.taxon, data.group );
    removeData( 'groupRoot' );
}
/* ========================= RANKS ========================================== */
type RankData = {
    [name: string]: { id: number, order: number; };
};
function storeRankData ( rankData: _t.EntityRecords ): RankData {
    const ranks: RankData = {};
    let order = _t.objectValues( rankData ).sort( orderRanks );
    $.each( order, addRankData );
    storeData( 'rankNames', ranks );
    storeData( 'orderedRanks', order.map( rank => rank.displayName ).reverse() );
    return ranks;

    function addRankData ( i: number, rank: _t.EntityRecord ): void {
        const rankName = rank?.displayName;
        if ( rankName ) {
            ranks[ rankName ] = { id: rank.id, order: i };
        } else {
            console.error( 'Rank name not found' );
        }
    }
}
/** Orders ranks from least to most specific. */
function orderRanks ( a: _t.EntityRecord, b: _t.EntityRecord ): -1 | 0 | 1 {
    const x = a?.ordinal;
    const y = b?.ordinal;
    if ( x && y ) return x > y ? 1 : x < y ? -1 : 0;
    console.error( `Unable to sort rank (x[${ x }] y[${ y }]) data` );
    return -1;
}
/* ========================= GROUP DATA ===================================== */
function storeGroupNames ( groups: _t.EntityRecords ): void {
    const groupNames = getNameObj( _t.objectKeys( groups ), groups );
    storeData( 'groupNames', groupNames );
}
/* --------------------------- MODIFY --------------------------------------- */
function modifyGroupData ( groups: _t.EntityRecords, ranks: RankData ): void {
    _t.objectValues( groups ).forEach( g => modifyGroup( g, ranks ) );
    storeData( 'group', groups );
}
function modifyGroup ( group: _t.EntityRecord, ranks: RankData ): void {
    buildGroupRootObject( group );
    flattenGroupSubRanks( group, ranks );
}
function buildGroupRootObject ( group: _t.EntityRecord ): void {
    const groupRoots: _t.EntityRecords = {};
    group.roots.forEach( ( g: _t.EntityRecord ) => groupRoots[ g.id ] = g );
    group.roots = groupRoots;
}
function flattenGroupSubRanks ( group: _t.EntityRecord, ranks: RankData ): void {
    _t.objectValues( group.roots ).forEach( sg => flattenGroupRootRanks( sg, ranks ) );
}
function flattenGroupRootRanks ( groupRoot: _t.EntityRecord, ranks: RankData ): void {
    groupRoot.subRanks = fillRankNames( JSON.parse( groupRoot.subRanks ), ranks );
}
function fillRankNames ( rankAry: number[], ranks: RankData ): string[] {
    return rankAry.map( ord => {
        const name = Object.keys( ranks ).find( name => ranks[ name ]!.order === ord);
        if ( !name ) console.error( `Rank at ord[${ ord }] not found` );
        return String( name );
    } );
}
/* ================= TAXA BY GROUP AND RANK ================================= */
function storeTaxaByRankAndGroup ( taxa: _t.EntityRecords, groups: _t.EntityRecords ): void {
    for ( let groupId in groups ) {
        const group = groups[ groupId ];
        if ( group ) {
            sortTaxonGroupData( group, taxa );
        } else {
            console.error( `Group [${ groupId }] not found ` );
        }
    }
    storeData( 'group', groups );
    storeData( 'taxon', taxa );
}
function sortTaxonGroupData ( group: _t.EntityRecord, taxa: _t.EntityRecords ): void {
    sortTaxaByGroupRootRoot( group, group.roots, taxa );
    storeGroupSubRootNames( group, taxa );
}
function sortTaxaByGroupRootRoot (
    group: _t.EntityRecord,
    gRoots: _t.EntityRecords,
    taxa: _t.EntityRecords
): void {
    for ( let id in gRoots ) {
        const gRoot = gRoots[ id ];
        const gTaxon = taxa[ gRoot?.taxon ];
        if ( gRoot && gTaxon ) {
            separateAndStoreGroupTaxa( gTaxon, gRoot, group, taxa );
        } else {
            console.error( `Group root-taxon [${ id }] not found` );
        }
    }
}
function separateAndStoreGroupTaxa (
    taxon: _t.EntityRecord,
    root: _t.EntityRecord,
    group: _t.EntityRecord,
    taxa: _t.EntityRecords
): void {
    const data = separateGroupTaxaByRank( taxon.children, taxa );
    storeTaxaByGroupAndRank( data, root, group );
}
type TaxaIdsByRankAndName = { [name: string]: _t.IdsByName; };
function separateGroupTaxaByRank (
    cTaxa: number[],
    taxa: _t.EntityRecords
): TaxaIdsByRankAndName {
    const data: TaxaIdsByRankAndName = {};
    cTaxa.forEach( separateTaxonAndChildren );
    return data;

    function separateTaxonAndChildren ( id: number ): void {
        const taxon = taxa[ id ];
        if ( taxon ) {
            addTaxonData( taxon );
        } else {
            console.log( `Taxon [${ id }] not found` );
        }
    }
    function addTaxonData( taxon: _t.EntityRecord ): void {
        addToGroupRank( taxon, taxon.rank.displayName );
        taxon.children.forEach( separateTaxonAndChildren );

        function addToGroupRank ( taxon: _t.EntityRecord, rank: string ): void {
            if ( !data[ rank ] ) data[ rank ] = {};
            data[ rank ]![ taxon.name ] = taxon.id;
        }
    }
}
function storeTaxaByGroupAndRank (
    taxonObj: TaxaIdsByRankAndName,
    root: _t.EntityRecord,
    group: _t.EntityRecord
): void {
    for ( let rank in taxonObj ) {
        const prop = group.displayName + root.name + rank + 'Names';
        storeData( prop, taxonObj[ rank ]! );
    }
}
/* -------------------------- GROUP-ROOTS ----------------------------------- */
function storeGroupSubRootNames ( group: _t.EntityRecord, taxa: _t.EntityRecords ): void {
    const prop = group.displayName + 'GroupRootNames';
    const data = buildGroupRootOpts( _t.objectValues( group.roots ), taxa );
    storeData( prop, data );
}
function buildGroupRootOpts ( groupRoots: _t.EntityRecord[], taxa: _t.EntityRecords ): _t.IdsByName {
    const data: _t.IdsByName = {};
    groupRoots.forEach( addGroupRoot );
    return data;

    function addGroupRoot ( sGroup: _t.EntityRecord ): void {
        const groupRootName = taxa[ sGroup.taxon ]?.displayName;
        if ( groupRootName ) {
            data[ groupRootName ] = sGroup.id;
        } else {
            console.error( `GroupRoot taxon [${ sGroup.taxon }] name not found` );
        }
    }
}