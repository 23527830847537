/**
 * Initializes field values during form-model build.
 */
import { cloneObj } from '@util';
import { model, state } from '@dataentry';
import { setEditFieldValues } from './edit-fields.js';
import { setReviewFieldValues } from '../review';

export function setFieldModelInitValues ( records, initModel ) {        /*dbug-log*///console.log( '--setFieldModelInitValues initModel[%O]', cloneObj( initModel ) );
    if ( initModel.review ) return setReviewFieldValues( initModel );
    if ( initModel.editing ) return setEditFieldValues( records, initModel );
    if ( initModel.vals ) return setCreateFieldValues( initModel, initModel.fields );
    return initModel;
}
/* ========================= CREATE FIELDS ================================== */
function setCreateFieldValues ( initModel, fields ) {                   /*dbug-log*///console.log( '--setCreateFieldValues fields[%O] vals?[%O]', fields, initModel.vals );
    for ( let key in initModel.vals ) setFieldInitData( key );
    delete initModel.vals;
    return initModel;

    function setFieldInitData ( key ) {
        state.setFieldValueProp( fields[ key ], initModel.vals[ key ] );/*dbug-log*///console.log( '       --setFieldInitData field[%s][%O] vals?[%O]', key, fields[ key ], initModel.vals[ key ] );
        if ( !state.isPossiblyQuarantined( fields[ key ] ) ) return;
        ifQuarantinedSetReviewEntry( fields[ key ] );
    }
}
//todo0: handle review entry with new state manager
function ifQuarantinedSetReviewEntry ( field ) {
    const rcrd = getFieldEntityRcrd( field );
    if ( !rcrd.review ) return;
    addFieldReviewEntry( field, rcrd.review );
}
function getFieldEntityRcrd ( field ) {                             /*dbug-log*///console.log('-- getFieldEntityRcrd[%O]', field);
    const coreClass = model.getFieldClassData( field )[ 0 ];
    return state.getRecords( coreClass, field.value );
}
function addFieldReviewEntry ( field, rEntry, ord ) {             /*dbug-log*///console.log( '           -- addFieldReviewEntry field[%O] rEntry[%O] ord[%s]', cloneObj( field ), rEntry, ord );  //console.trace()
    if ( ord ) return field.review[ ord ] = rEntry;
    field.review = rEntry;
}