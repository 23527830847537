/**
 * After form-submit-success, local data is updated and the form-process continues.
 *
 * Export
 *     onDataSynced
 *     onSubmitSuccess
 *
 * TOC
 *     UPDATE LOCAL-STORAGE
 *     CONTINUE FORM-PROCESS
 *         UPDATE FORM ENTITY-RECORDS
 */
import { syncLocalDataAfterDataEntry } from '@localdata';
import { cloneObj } from '@util';
import { alert, components, state } from '@dataentry';
import { ifReviewSubmittedHandleUpdates } from '../../review/after-submit.js';
import { ifSourceDataEditedUpdatedCitations } from './citation-sync.ts';
import { onViolation } from './on-error.js';
export function onSubmitSuccess( fLvl, results ) {                  /*temp-log*///console.log( '       +-- onSubmitSuccess fLvl[%s] results[%O] args[%O]', fLvl, cloneObj( results ), arguments );
    return results.fail ?
        onViolation( fLvl, results ) :
        syncLocalDataAndUpdateForm( fLvl, results );
}
/* =================== UPDATE LOCAL-STORAGE ================================= */
function syncLocalDataAndUpdateForm( fLvl, data ) {
    return syncLocalDataAfterDataEntry( data )
        .then( ifSourceDataEditedUpdatedCitations )
        .then( data => onDataSynced( fLvl, data ) );
}
/* =================== CONTINUE FORM-PROCESS ================================ */
export function onDataSynced ( fLvl, data ) {                       /*temp-log*///console.log( '       --onDataSynced [%s][%O]', fLvl, cloneObj( data ) );
    if ( !state.isFormActive() ) return; //form closed.
    return data.fails ? alertFails( data ) : continueFormProcess( fLvl, data );
}
function alertFails( data ) {
    alert.errUpdatingData( 'dataSyncFailures', data.fails );
}
function continueFormProcess( fLvl, data ) {
    flagFormSubmitted();
    return Promise.resolve( updateDataStoredInFormMemory( fLvl, data ) )
        .then( skipClose => closeOrResetForm( fLvl, data, skipClose ) )
}
function flagFormSubmitted() { //Triggers special-handling on root-form exit
    state.setFormState( 'top', { submitted: true } );
}
function closeOrResetForm ( fLvl, data, skipClose ) {
    const afterSubmit = state.getFormHandler( fLvl, 'afterSubmitAndPreventClose' );/*dbug-log*///console.log( '       --closeOrResetForm [%s][%O] afterSubmit?[%O]', fLvl, cloneObj( data ), afterSubmit );
    return afterSubmit ? afterSubmit() : components.handleFormClose( fLvl, null, data, skipClose );
}
/* ------------- UPDATE FORM ENTITY-RECORDS --------------------------------- */
function updateDataStoredInFormMemory ( fLvl, data ) {
    return updateEntityRecords( data )
        .then( () => ifReviewSubmittedHandleUpdates( fLvl, data ) );
}
function updateEntityRecords ( data ) {
    return data.core ? addDataToStoredRcrds( data.core, data.detail ) : Promise.resolve();
}
function addDataToStoredRcrds ( entity, detailEntity ) {            /*dbug-log*///console.log('--addDataToStoredRcrds. [%s] (detail ? [%s])', entity, detailEntity);
    return state.syncEntityRecords( entity )
        .then( syncDetailEntityRecords );
    /** Source & Location's detail entities: publications, citations, authors, geojson */
    function syncDetailEntityRecords() {                            /*dbug-log*///console.log('   --syncDetailEntityRecords detailEntity?[%s]', detailEntity);
        return detailEntity ? addDataToStoredRcrds( detailEntity ) : null;
    }
}
