
/**
 * Quarantined data is prepared for local storage and patched in the ReviewEntry
 * entity to facilitate redownload to local storage.
 *
 * Export
 *     prepareDataForLocalStorage
 *     prepareQuarantined
 *     addReviewEntryToEntity
 *
 * TOC
 *     PREPARE QUARANTINED DATA
 *         CORE ENTITY
 *         DETAIL ENTITY
 *         ADD ASTERISK TO NAME
 *         SET QUARANTINED RELATIONSHIPS
 *     PATCH REVIEW-ENTRY PAYLOAD
 */
import { DataEntryResults, updateReviewEntryStorage } from '@localdata/sync';
import { cloneAndParseReviewEntry } from '@localdata/init';
import { cloneObj, getRandomInt, sendAjaxQuery } from '@util';
import * as _t from '@types';
import * as model from '@dataentry/model';
import { relinkQuarantinedRelationships } from './relink-quarantined';
/* FP-TS */
import { pipe } from 'fp-ts/lib/function';

let fLvl: model.FormGroup;
/**
 * Quarantined data is prepared for local storage and patched in the ReviewEntry
 * entity to facilitate redownload to local storage.
 */
export function prepareQuarantined (
    data: DataEntryResults,
    lvl: model.FormGroup,
    ReviewEntries: _t.EntityRecords<_t.ReviewEntry>
): JQuery.Promise<DataEntryResults > {                               /*dbug-log*///console.log( '--prepareQuarantined data[%O] ReviewEntries[%O]', data, ReviewEntries );
    fLvl = lvl;
    const payload = prepareQuarantinedPayload( data );
    return patchReviewEntryPayload( payload, ReviewEntries )
        .then( () => payload );
}
/* =================== PREPARE QUARANTINED DATA ============================= */
function prepareQuarantinedPayload ( data: DataEntryResults ): DataEntryResults {
    return pipe(
        handleQuarantinedCoreEntity( data ),
        handleQuarantinedDetailEntity,
        finishReviewEntryPayload
    )
}
function finishReviewEntryPayload( payload: DataEntryResults ): DataEntryResults {
    const { review, ...rest } = payload;
    return { review: payload.coreEntity.review, ...rest };
}
export function getReviewEntryBones ( rEntry: _t.ReviewEntry ): _t.ReviewEntryBones {
    return { id: rEntry.id, stage: rEntry.stage.name };
}
/* ----------------------- CORE ENTITY -------------------------------------- */
function handleQuarantinedCoreEntity ( data: DataEntryResults ): DataEntryResults {
    let { coreEntity, coreId, name, ...rest } = addReviewEntryToEntity( data );
    coreEntity = modifyQuarantinedEntity( coreEntity, data );
    coreEntity = relinkQuarantinedRelationships( fLvl, coreEntity );
    name = coreEntity.displayName;
    return { coreId: coreEntity.id, coreEntity, name, ...rest };
}
export function addReviewEntryToEntity( data: DataEntryResults ): DataEntryResults {
    const { coreEntity, review, ...rest } = cloneObj( data );
    const rEntry = review as _t.ReviewEntry;
    coreEntity.review = getReviewEntryBones( rEntry );
    return { coreEntity, review, ...rest };
}
/* ---------------------- DETAIL ENTITY ------------------------------------- */
function handleQuarantinedDetailEntity ( data: DataEntryResults ): DataEntryResults {
    if ( !data.detailEntity ) return data;
    let { coreEntity, detailEntity, detailId, ...rest } = data;
    detailEntity = prepareDetailEntity( data, detailEntity );
    coreEntity = addNewDetailIdToCoreEntity( data, coreEntity, detailEntity );
    return { detailId: detailEntity.id, coreEntity, detailEntity, ...rest  };
}
function prepareDetailEntity( data: DataEntryResults, detailEntity: _t.EntityRecord ) {
    const detail = modifyQuarantinedEntity( detailEntity, data );
    detail[ data.core ] = data.coreId;
    return detail;
}
function addNewDetailIdToCoreEntity(
    data: DataEntryResults,
    coreEntity: _t.EntityRecord,
    detailEntity: _t.EntityRecord
): _t.EntityRecord {
    coreEntity[ data.detail! ] = detailEntity.id;
    return coreEntity;
}
/* =================== MODIFY QUARANTINED ENTITY ============================ */
function modifyQuarantinedEntity (
    entity: _t.EntityRecord,
    data: DataEntryResults
): _t.EntityRecord {                                            /*dbug-log*///console.log( '--modifyQuarantinedEntity entity[%O] data[%O]', entity, data );
    const modified = addNameAsterik( cloneObj( entity ) );
    modified.id = isCreateEntry( data ) ? randomizeId( modified.id ) : modified.id;
    return modified;
}
function isCreateEntry( data: DataEntryResults ): boolean {
    return ( data.review as _t.ReviewEntry ).form.action === 'create';
}
function randomizeId( id: number ) {
    return parseInt( `${ id + getRandomInt( 222222, 9999999 ) }000000` );
}
/* --------------------- ADD ASTERIK TO NAME -------------------------------- */
function addNameAsterik ( entity: _t.EntityRecord ) {
    [ 'displayName', 'name' ].forEach( prop => addAsterik( entity, prop ) );
    return entity;
}
function addAsterik ( entity: _t.EntityRecord, prop: keyof _t.EntityRecord ): void {
    if ( !entity[ prop ] || entity[ prop ][ 0 ] === '*' ) return;
    entity[ prop ] = '*' + entity[ prop ];
}
/* =================== PATCH REVIEW-ENTRY PAYLOAD =========================== */
/**
 * The ReviewEntry is returned from the server with temporary data used for the
 * serialization. It is modified to match the locally quarantined-data before
 * being stored in the contributor's local-database. The prepared entry is then
 * patched in the ReviewEntry entity.
 */
function patchReviewEntryPayload (
    data: DataEntryResults,
    ReviewEntries: _t.EntityRecords<_t.ReviewEntry>
): JQuery.Promise<any> {                                            /*dbug-log*///console.log( '--patchReviewEntryPayload data[%O] ReviewEntries[%O]', cloneObj( data ), ReviewEntries );
    return sendAjaxQuery( data, 'crud/review/entry', handleUpdateRecords );

    function handleUpdateRecords ( results: { review: string; } ): void {
        const rEntry = cloneAndParseReviewEntry( results.review );
        updateReviewEntryStorage( rEntry, ReviewEntries );
    }
}