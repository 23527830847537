/**
 * Handles the retrieval of field state-data from the form state.
 *
 * TOC
 *     STATE|PROP
 *         VALUE
 *     COMBOBOX
 *     DISPLAYED
 *     PREDICATES
 */
import { getFormState, isFormActive } from "./get-form-state.js";
/* ========================= STATE|PROP ===================================== */
export function getFieldState( lvl, key, prop ) {
    const fields = getFormState( lvl, 'fields' );
    if ( !fields ) return null; /* form-initializing */             /*dbug-log*///console.log( '   --getFieldState key[%s] prop[%s] fConfig[%O] fields[%O]', key, prop, fields[ key ], fields );
    return prop ? fields[ key ][ prop ] : fields[ key ];
}
/** Used when the only ID of the field is known, */
export function getFieldStateById( lvl, id ) {
    const fields = getFormState( lvl, 'fields' );                   /*dbug-log*///console.log( '   --getFieldStateById id[%s] fields[%O]', id, fields );
    return Object.values( fields ).find( f => f.id === id );
}
/* -------------------------- VALUE ----------------------------------------- */
export function getFieldValue( lvl, key ) {
    return getFieldState( lvl, key, 'value' );
}
/** Returns an object with field names(k) and values(v) of all form fields */
export function getFieldValues( lvl ) {
    const fields = getFormState( lvl, 'fields' );
    const vals = {};
    for ( let key in fields ) {
        const val = fields[ key ].value;
        if ( val ) vals[ key ] = val;
    }                                                               /*dbug-log*///console.log( '   --getFieldValues fields[%O] vals[%O]', fields, vals );
    return vals;
}
/* ========================= COMBOBOX ======================================= */
export function getComboFields( lvl ) {
    const fields = getFormState( lvl, 'fields' );                   /*dbug-log*///console.log( 'getComboFields [%O]', fields );
    return Object.values( fields ).filter( f => f.combo && f.shown );
}
/* ========================= DISPLAYED ====================================== */
/** Returns an array of the form fields in the order they are displayed. */
export function getDisplayedFields( lvl ) {
    if ( !isFormActive( lvl ) ) return null;  // Form closed
    const { fields, layout } = getFormState( lvl, [ 'fields', 'layout' ] );/*dbug-log*///console.log( 'getDisplayedFields layout[%O] fields[%O]', layout, fields );
    return layout.flatMap( row => row.flatMap( addField ) );

    function addField( config ) {
        if ( config.configs ) return Object.values( config.configs ).flatMap( addField );/*dbug-log*///console.log( '   --addField name[%s] config[%O] config[%O]', config.name, config, fields[ config.name ] );
        return fields[ config.name ];
    }
}
/* ======================= PREDICATES ======================================= */
export function isFieldShown( lvl, key ) {
    const fields = getFormState( lvl, 'fields' );                   /*dbug-log*///console.log( '   --isFieldShown [%s][%O]', key, fields );
    return fields[ key ].shown || false;
}
export function areFieldsShown( lvl, names ) {
    return names.map( n => isFieldShown( lvl, n ) ).every( b => b );
}