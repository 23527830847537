/**
 * Handles initializing the Explore panels: Filters, Interaction Lists, and
 * Data Review.
 */
import { showInfoModal } from '@elems';
import { tutorial } from '@explore';
import * as panels from '..';
import { getPanelConfigs } from './panel-confgs';

const configs = getPanelConfigs();
export function addPanelEventsAndStyles ( userRole ) {
    setInfoButtonClickEvents();
    panels.initFilterPanel();
    panels.initListPanel();
    if ( [ 'visitor', 'user' ].indexOf( userRole ) !== -1 ) return;
    initReviewPanel( userRole );
}
function setInfoButtonClickEvents () {
    $( '#svd-list-hlp' ).on( 'click', () => showTutorialSection( 'data-list' ) );
    $( '#svd-fltr-hlp' ).on( 'click', () => showTutorialSection( 'filter-set' ) );
    $( '#fltr-pnl-hlp' ).on( 'click', () => showTutorialSection( 'filter-panel' ) );
}
function showTutorialSection( key ) {
    showInfoModal( getHelpSteps( key ) )
}
function getHelpSteps ( key ) {
    const getSteps = {
        'filter-panel': 'getFilterSteps',
        'filter-set': 'getSavedFilterSteps',
        'data-list': 'getSavedListSteps'
    };
    return tutorial[ getSteps[ key ] ]();
}
function initReviewPanel ( userRole ) {
    panels.initReviewPanel( userRole );
    configs[ 'review' ].onClose = panels.getOnCloseHandler();
}