/**
 * Manages a simple, spinning loading-animation.
 */
import { getElem } from "../build";

export function startNewLoadingAnimation ( ...args: ConstructorParameters<typeof LoadingAnimation> ) {
    return new LoadingAnimation( ...args ).start();
}
class LoadingAnimation {
    constructor ( private readonly container: string, private readonly message?: string ) {
    }
    public start () {
        $( this.container ).append( getLoadingHTML( this.message ) );
        return this;
    }
    public stop () {
        $( this.container + ' .loading-spinner' ).remove();
    }
}
function getLoadingHTML ( message?: string ): HTMLDivElement {
    const container = getElem<HTMLDivElement>( 'div', { class: 'loading-spinner flex-row' } );
    $( container ).append( getLoadingSVG() );
    if ( message ) { $( container ).append( getLoadingMessage( message ) ); }
    return container;
}
function getLoadingSVG (): string {
    return `<svg class="spinner" viewBox="0 0 50 50">
        <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
        </svg>`;
}
function getLoadingMessage ( message: string ): HTMLParagraphElement {
    return getElem<HTMLParagraphElement>( 'p', { class: 'loading-message', text: message } );
}